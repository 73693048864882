import { appRoot, AppRootModule_getSelectedOrgId } from "../../../Infrastructure/AppRoot.fs.js";
import { searchInvoiceRuns } from "../../../AppDomain/UseCase/Invoice.fs.js";
import { map, singleton, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { uncurry2, createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { iconButton, showModalDialog, ButtonType, showModalEx } from "../../Component/ModalDialog.fs.js";
import { alignIconCenter, alignCellTextTop, Daisy_h5, Daisy_DialogWidth } from "../../Component/Component.fs.js";
import { IndexView as IndexView_1 } from "./InvoiceRun.fs.js";
import { IndexView as IndexView_3, Msg$1 } from "../BaseSearch.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { ofArray, singleton as singleton_1 } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { IndexView as IndexView_2 } from "./InvoiceRunEdit.fs.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { DateTime_dateTimeToString } from "../../../AppDomain/Util.fs.js";
import { fromDateTimeOffset } from "../../../fable_modules/fable-library-js.4.19.3/Date.js";
import { InvoiceGenerationMethodModule_toString } from "../../../../Logos.Shared/Domain/Entity/Invoice.fs.js";

export function searchFunc() {
    const selectedOrgId = AppRootModule_getSelectedOrgId();
    return (searchText) => ((pagination) => searchInvoiceRuns(appRoot.InvoiceService, selectedOrgId, searchText, pagination));
}

export function invoiceRunTableSearchView(props) {
    const children_8 = toList(delay(() => {
        let elems;
        return append(singleton(createElement("div", createObj(Helpers_combineClasses("navbar", singleton_1((elems = [createElement(showModalEx, {
            dialogId: "id-invoice-run-search-add-invoice-run",
            header: "",
            buttonType: new ButtonType(0, ["Generate Invoices"]),
            width: new Daisy_DialogWidth(3, []),
            heightPercent: "90",
            body: () => createElement(IndexView_1, null),
            onShow: () => {
                props.dispatch(new Msg$1(4, [undefined]));
            },
            onClose: () => {
                props.dispatch(new Msg$1(1, []));
            },
        })], ["children", reactApi.Children.toArray(Array.from(elems))])))))), delay(() => {
            let matchValue_1, x_1;
            return append((matchValue_1 = props.state.EntityToEdit, (matchValue_1 == null) ? singleton(react.createElement(react.Fragment, {})) : ((x_1 = matchValue_1, singleton(createElement(showModalDialog, {
                dialogId: x_1,
                header: "",
                width: new Daisy_DialogWidth(5, []),
                heightPercent: "90",
                body: () => {
                    const matchValue = props.state.EntityToEdit;
                    if (matchValue == null) {
                        const xs = [Daisy_h5("No Invoice Run Selected.")];
                        return react.createElement(react.Fragment, {}, ...xs);
                    }
                    else {
                        return createElement(IndexView_2, {
                            invoiceRunId: matchValue,
                            onInvoiceUpdated: () => {
                                props.dispatch(new Msg$1(9, [true]));
                            },
                        });
                    }
                },
                onShow: () => {
                    props.dispatch(new Msg$1(4, [x_1]));
                },
                onClose: () => {
                    props.dispatch(new Msg$1(4, [undefined]));
                },
            }))))), delay(() => {
                let elems_10, children_3, children_1, children_5;
                return singleton(createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["flex", "w-full"])], (elems_10 = [(children_3 = singleton_1((children_1 = ofArray([createElement("th", {
                    width: 10 + "%",
                    children: "Run #",
                }), createElement("th", {
                    width: 12 + "%",
                    children: "Created",
                }), createElement("th", {
                    width: 10 + "%",
                    children: "Approved",
                }), createElement("th", {
                    width: 15 + "%",
                    children: "Canceled",
                }), createElement("th", {
                    width: 8 + "%",
                    children: "Method",
                }), createElement("th", {
                    width: 2 + "%",
                    children: "",
                })]), createElement("tr", {
                    children: reactApi.Children.toArray(Array.from(children_1)),
                }))), createElement("thead", {
                    children: reactApi.Children.toArray(Array.from(children_3)),
                })), (children_5 = toList(delay(() => map((invoiceRun) => {
                    let elems_9, elems_3, elems_2, elems_1, elems_8, elems_7;
                    return createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top", "hover"])], (elems_9 = [createElement("td", createObj(ofArray([alignCellTextTop, ["style", {
                        cursor: "pointer",
                    }], ["onClick", (_arg_5) => {
                        props.dispatch(new Msg$1(4, [invoiceRun.Id]));
                    }], (elems_3 = [createElement("div", createObj(singleton_1((elems_2 = [createElement("span", createObj(ofArray([["style", {
                        display: "flex",
                        alignItems: "center",
                    }], (elems_1 = [createElement("i", {
                        className: join(" ", toList(delay(() => append(singleton("fa"), delay(() => {
                            const matchValue_2 = invoiceRun.InvoiceRunStatus;
                            return (matchValue_2.tag === 3) ? singleton("fa-check") : ((matchValue_2.tag === 2) ? singleton("fa-times") : singleton("fa-hourglass"));
                        }))))),
                        style: createObj(toList(delay(() => append(singleton(["marginRight", "8px"]), delay(() => {
                            const matchValue_3 = invoiceRun.InvoiceRunStatus;
                            return (matchValue_3.tag === 3) ? singleton(["color", "green"]) : ((matchValue_3.tag === 2) ? singleton(["color", "red"]) : singleton(["color", "orange"]));
                        }))))),
                    }), createElement("div", createObj(ofArray([alignCellTextTop, ["children", invoiceRun.RunNumber]])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))]))))], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("td", createObj(toList(delay(() => append(singleton(alignCellTextTop), delay(() => append(singleton(["style", {
                        cursor: "pointer",
                    }]), delay(() => append(singleton(["onClick", (_arg_6) => {
                        props.dispatch(new Msg$1(4, [invoiceRun.Id]));
                    }]), delay(() => {
                        let elems_4;
                        let patternInput;
                        const matchValue_4 = invoiceRun.InvoiceRunStatus;
                        if (matchValue_4.tag === 1) {
                            const x_2 = matchValue_4.fields[0];
                            patternInput = [DateTime_dateTimeToString(fromDateTimeOffset(x_2.DateCreated, 0)), x_2.CreatedByName];
                        }
                        else {
                            patternInput = ["", ""];
                        }
                        return singleton((elems_4 = [createElement("div", createObj(ofArray([alignCellTextTop, ["children", patternInput[0]]]))), createElement("div", createObj(ofArray([alignCellTextTop, ["children", patternInput[1]]])))], ["children", reactApi.Children.toArray(Array.from(elems_4))]));
                    })))))))))), createElement("td", createObj(toList(delay(() => append(singleton(alignCellTextTop), delay(() => append(singleton(["style", {
                        cursor: "pointer",
                    }]), delay(() => append(singleton(["onClick", (_arg_7) => {
                        props.dispatch(new Msg$1(4, [invoiceRun.Id]));
                    }]), delay(() => {
                        let elems_5;
                        let patternInput_1;
                        const matchValue_5 = invoiceRun.InvoiceRunStatus;
                        if (matchValue_5.tag === 3) {
                            const x_3 = matchValue_5.fields[0];
                            patternInput_1 = [DateTime_dateTimeToString(fromDateTimeOffset(x_3.DateApproved, 0)), x_3.ApprovedByName];
                        }
                        else {
                            patternInput_1 = ["", ""];
                        }
                        return singleton((elems_5 = [createElement("div", createObj(ofArray([alignCellTextTop, ["children", patternInput_1[0]]]))), createElement("div", createObj(ofArray([alignCellTextTop, ["children", patternInput_1[1]]])))], ["children", reactApi.Children.toArray(Array.from(elems_5))]));
                    })))))))))), createElement("td", createObj(toList(delay(() => append(singleton(alignCellTextTop), delay(() => append(singleton(["style", {
                        cursor: "pointer",
                    }]), delay(() => append(singleton(["onClick", (_arg_8) => {
                        props.dispatch(new Msg$1(4, [invoiceRun.Id]));
                    }]), delay(() => {
                        let elems_6;
                        let patternInput_2;
                        const matchValue_6 = invoiceRun.InvoiceRunStatus;
                        if (matchValue_6.tag === 2) {
                            const x_4 = matchValue_6.fields[0];
                            patternInput_2 = [DateTime_dateTimeToString(fromDateTimeOffset(x_4.DateCanceled, 0)), x_4.CanceledByName];
                        }
                        else {
                            patternInput_2 = ["", ""];
                        }
                        return singleton((elems_6 = [createElement("div", createObj(ofArray([alignCellTextTop, ["children", patternInput_2[0]]]))), createElement("div", createObj(ofArray([alignCellTextTop, ["children", patternInput_2[1]]])))], ["children", reactApi.Children.toArray(Array.from(elems_6))]));
                    })))))))))), createElement("td", createObj(ofArray([alignCellTextTop, ["style", {
                        cursor: "pointer",
                    }], ["onClick", (_arg_9) => {
                        props.dispatch(new Msg$1(4, [invoiceRun.Id]));
                    }], ["children", InvoiceGenerationMethodModule_toString(invoiceRun.InvoiceGenerationMethod)]]))), createElement("td", createObj(singleton_1((elems_8 = [createElement("div", createObj(ofArray([alignIconCenter, (elems_7 = [iconButton("fas fa-edit", (_arg_11) => {
                        props.dispatch(new Msg$1(4, [invoiceRun.Id]));
                    })], ["children", reactApi.Children.toArray(Array.from(elems_7))])])))], ["children", reactApi.Children.toArray(Array.from(elems_8))]))))], ["children", reactApi.Children.toArray(Array.from(elems_9))])])));
                }, props.state.Entities))), createElement("tbody", {
                    children: reactApi.Children.toArray(Array.from(children_5)),
                }))], ["children", reactApi.Children.toArray(Array.from(elems_10))])])))));
            }));
        }));
    }));
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_8)),
    });
}

export function IndexView() {
    return createElement(IndexView_3, {
        entityName: "Invoice Runs",
        entityTable: invoiceRunTableSearchView,
        searchFunc: uncurry2(searchFunc()),
    });
}

