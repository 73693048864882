import { Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { unit_type, union_type, array_type, uint8_type, record_type, list_type, option_type, string_type, class_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { join, isNullOrEmpty } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { ofArray, empty, singleton as singleton_1 } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { Guid_parse } from "../../../../Logos.Shared/Util.fs.js";
import { deleteDocument, getDocumentFile } from "../../../AppDomain/UseCase/Document.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../Infrastructure/AppRoot.fs.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { HttpUtil_saveByteArray } from "../../../AppDomain/Util.fs.js";
import { defaultArg } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { Daisy_DialogWidth, Daisy_confirmIcon } from "../../Component/Component.fs.js";

export class State extends Record {
    constructor(DocumentId, Name, FileExtension, Errors) {
        super();
        this.DocumentId = DocumentId;
        this.Name = Name;
        this.FileExtension = FileExtension;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.DocumentSearchAction.State", [], State, () => [["DocumentId", class_type("System.Guid")], ["Name", string_type], ["FileExtension", option_type(string_type)], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetDocumentFile", "GetDocumentFileResponse", "DeleteDocument", "DeleteDocumentResponse", "NoAction", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.DocumentSearchAction.Msg", [], Msg, () => [[], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(uint8_type), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", array_type(uint8_type)]], [["ErrorValue", list_type(string_type)]]])]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])]], [], [["Item", class_type("System.Exception")]]]);
}

export function getDocumentFileCmd(documentId) {
    return cmdOfAsync((Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(5, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_2;
        const value_1_1 = !isNullOrEmpty(documentId) ? (new FSharpResult$2(0, [undefined])) : (new FSharpResult$2(1, [singleton_1("Document id is required.")]));
        asyncResult_2 = singleton.Return(value_1_1);
        return singleton.Bind(asyncResult_2, (input_1_1) => {
            const input_5 = input_1_1;
            if (input_5.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_5.fields[0]]));
            }
            else {
                let asyncResult_1;
                const value_1_2 = Guid_parse(documentId);
                asyncResult_1 = singleton.Return(value_1_2);
                return singleton.Bind(asyncResult_1, (input_1) => {
                    const input_2 = input_1;
                    if (input_2.tag === 1) {
                        return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                    }
                    else {
                        return getDocumentFile(appRoot.DocumentService, AppRootModule_getSelectedOrgId(), input_2.fields[0]);
                    }
                });
            }
        });
    }));
}

export function deleteDocumentCmd(documentId) {
    return cmdOfAsync((Item) => (new Msg(3, [Item])), (Item_1) => (new Msg(5, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_2;
        const value_1_1 = !isNullOrEmpty(documentId) ? (new FSharpResult$2(0, [undefined])) : (new FSharpResult$2(1, [singleton_1("Document id is required.")]));
        asyncResult_2 = singleton.Return(value_1_1);
        return singleton.Bind(asyncResult_2, (input_1_1) => {
            const input_5 = input_1_1;
            if (input_5.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_5.fields[0]]));
            }
            else {
                let asyncResult_1;
                const value_1_2 = Guid_parse(documentId);
                asyncResult_1 = singleton.Return(value_1_2);
                return singleton.Bind(asyncResult_1, (input_1) => {
                    const input_2 = input_1;
                    if (input_2.tag === 1) {
                        return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                    }
                    else {
                        return deleteDocument(appRoot.DocumentService, AppRootModule_getSelectedOrgId(), input_2.fields[0]);
                    }
                });
            }
        });
    }));
}

export function init(documentId, name, fileExtension) {
    return [new State(documentId, name, fileExtension, empty()), Cmd_none()];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            if (msg.fields[0].tag === 1) {
                return [state, Cmd_none()];
            }
            else {
                HttpUtil_saveByteArray(msg.fields[0].fields[0], `Document-${state.Name}.${defaultArg(state.FileExtension, "")}`, "application/octet-stream");
                return [state, Cmd_none()];
            }
        case 2:
            return [state, deleteDocumentCmd(state.DocumentId)];
        case 3:
            if (msg.fields[0].tag === 1) {
                return [new State(state.DocumentId, state.Name, state.FileExtension, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.DocumentId, state.Name, state.FileExtension, empty()), Cmd_none()];
            }
        case 4:
            return [state, Cmd_none()];
        case 5:
            return [new State(state.DocumentId, state.Name, state.FileExtension, singleton_1(msg.fields[0].message)), Cmd_none()];
        default:
            return [state, getDocumentFileCmd(state.DocumentId)];
    }
}

export function IndexView(props) {
    let init_1, elems_1, elems;
    const dispatch = ((init_1 = init(props.DocumentId, props.Name, props.FileExtension), React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [])))[1];
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex space-x-2"])], ["style", {
        display: "flex",
        flexDirection: "row",
    }], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["btn btn-square btn-xs text-primary"])], (elems = [createElement("i", {
        className: "fas fa-download",
    })], ["children", reactApi.Children.toArray(Array.from(elems))]), ["onClick", (_arg_2) => {
        dispatch(new Msg(0, []));
    }]]))), createElement(Daisy_confirmIcon, {
        icon: "fa-trash-alt",
        width: new Daisy_DialogWidth(1, []),
        body: createElement("p", {
            children: [`Delete document file '${props.Name}'? This action cannot be undone.`],
        }),
        isEnabled: true,
        yesAction: new Msg(2, []),
        noAction: new Msg(4, []),
        dispatch: dispatch,
    })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

