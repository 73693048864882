import { BrowserDataModule_getKeyValue } from "../AppDomain/SystemService.fs.js";
import { toString } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { singleton } from "../fable_modules/fable-library-js.4.19.3/List.js";
import { FSharpResult$2 } from "../fable_modules/fable-library-js.4.19.3/Result.js";
import { ResultCE_result, ResultCE_ResultBuilder__Zero } from "../fable_modules/FsToolkit.ErrorHandling.4.16.0/ResultCE.fs.js";
import { some } from "../fable_modules/fable-library-js.4.19.3/Option.js";
import { class_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";

export class SystemService {
    constructor() {
    }
    Save(browserData) {
        const patternInput = BrowserDataModule_getKeyValue(browserData);
        try {
            localStorage.setItem(patternInput[0], patternInput[1]);
            return ResultCE_ResultBuilder__Zero(ResultCE_result);
        }
        catch (e_1) {
            return new FSharpResult$2(1, [singleton(toString(e_1))]);
        }
    }
    Load(key) {
        let input_1, arg;
        try {
            return new FSharpResult$2(0, [(input_1 = localStorage.getItem(key), (input_1 == null) ? undefined : some((arg = input_1, JSON.parse(arg))))]);
        }
        catch (e_1) {
            return new FSharpResult$2(1, [singleton(toString(e_1))]);
        }
    }
    Delete(key) {
        try {
            return new FSharpResult$2(0, [localStorage.removeItem(key)]);
        }
        catch (e_1) {
            return new FSharpResult$2(1, [singleton(toString(e_1))]);
        }
    }
    GoBack() {
        history.go(-1);
    }
}

export function SystemService_$reflection() {
    return class_type("Infrastructure.SystemService.SystemService", undefined, SystemService);
}

export function SystemService_$ctor() {
    return new SystemService();
}

