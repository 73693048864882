import { Union } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type, string_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { isNullOrEmpty } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/String.js";
import { ofArray } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/List.js";
import { createInternal } from "./Util.fs.js";

export class Password extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["PasswordType"];
    }
}

export function Password_$reflection() {
    return union_type("Domain.Entity.Password.Password", [], Password, () => [[["Item", string_type]]]);
}

function validations(title) {
    return ofArray([[isNullOrEmpty, (_arg) => (title + " must not be empty.")], [(x) => (x.length < 5), (_arg_1) => (title + " must have at least 5 characters.")]]);
}

export function create(password) {
    return createInternal(validations("Password"), password, new Password(password));
}

export function value(_arg) {
    return _arg.fields[0];
}

