import { toString, Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type, lambda_type, unit_type, list_type, record_type, bool_type, enum_type, string_type, int32_type, option_type, class_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { defaultArg } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { SkillRatingModule_getLookupValues, SkillRatingModule_ofString, SkillRatingModule_toString, Skill_$reflection, UserSkill } from "../../../../Logos.Shared/Domain/Entity/User.fs.js";
import { ofArray, singleton, empty, tryHead } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { createObj, int32ToString } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { String_splitWord, Option_ofString, Option_toString } from "../../../../Logos.Shared/Util.fs.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { join, isNullOrEmpty } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { parse } from "../../../fable_modules/fable-library-js.4.19.3/Int32.js";
import { parse as parse_1 } from "../../../fable_modules/fable-library-js.4.19.3/Guid.js";
import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { Daisy_error } from "../../Component/Component.fs.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { map, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";

export class UserSkillVM extends Record {
    constructor(Id, SkillId, ExperienceMonths, Country, Rating, IsDeleted) {
        super();
        this.Id = Id;
        this.SkillId = SkillId;
        this.ExperienceMonths = (ExperienceMonths | 0);
        this.Country = Country;
        this.Rating = (Rating | 0);
        this.IsDeleted = IsDeleted;
    }
}

export function UserSkillVM_$reflection() {
    return record_type("Presentation.Pages.UserSkill.UserSkillVM", [], UserSkillVM, () => [["Id", option_type(class_type("System.Guid"))], ["SkillId", option_type(class_type("System.Guid"))], ["ExperienceMonths", int32_type], ["Country", option_type(string_type)], ["Rating", enum_type("Domain.Entity.User.SkillRating", int32_type, [["Beginner", 0], ["Intermediate", 1], ["Experienced", 2]])], ["IsDeleted", bool_type]]);
}

export function UserSkillVMModule_ofUserSkill(userSkill) {
    return new UserSkillVM(userSkill.Id, userSkill.SkillId, userSkill.ExperienceMonths, userSkill.Country, userSkill.Rating, false);
}

export function UserSkillVMModule_toUserSkill(userSkill) {
    return new UserSkill(defaultArg(userSkill.Id, "00000000-0000-0000-0000-000000000000"), defaultArg(userSkill.SkillId, "00000000-0000-0000-0000-000000000000"), userSkill.ExperienceMonths, userSkill.Country, userSkill.Rating);
}

export class State extends Record {
    constructor(Id, SkillId, Skills, ExperienceMonths, Country, Rating, IsDeleted, OnUserSkillModelChanged, Errors) {
        super();
        this.Id = Id;
        this.SkillId = SkillId;
        this.Skills = Skills;
        this.ExperienceMonths = ExperienceMonths;
        this.Country = Country;
        this.Rating = Rating;
        this.IsDeleted = IsDeleted;
        this.OnUserSkillModelChanged = OnUserSkillModelChanged;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.UserSkill.State", [], State, () => [["Id", option_type(class_type("System.Guid"))], ["SkillId", option_type(class_type("System.Guid"))], ["Skills", list_type(Skill_$reflection())], ["ExperienceMonths", string_type], ["Country", string_type], ["Rating", string_type], ["IsDeleted", bool_type], ["OnUserSkillModelChanged", lambda_type(UserSkillVM_$reflection(), unit_type)], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetSkill", "SetExperienceMonths", "SetCountry", "SetRating", "DeleteUserSkill", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.UserSkill.Msg", [], Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", int32_type]], [["Item", class_type("System.Exception")]]]);
}

export function init(userSkill, skills, onUserSkillModelChanged) {
    let input_3, x_1, copyOfStruct;
    let skillId;
    const input_1 = tryHead(skills);
    skillId = ((input_1 == null) ? undefined : input_1.Id);
    return [defaultArg((input_3 = userSkill, (input_3 == null) ? undefined : ((x_1 = input_3, new State(x_1.Id, (x_1.SkillId == null) ? skillId : x_1.SkillId, skills, int32ToString(x_1.ExperienceMonths), Option_toString(x_1.Country), SkillRatingModule_toString(x_1.Rating), false, onUserSkillModelChanged, empty())))), new State(undefined, skillId, skills, "0", "", (copyOfStruct = 0, int32ToString(copyOfStruct)), false, onUserSkillModelChanged, empty())), Cmd_none()];
}

export function getUserSkillVM(state) {
    return new UserSkillVM(state.Id, state.SkillId, isNullOrEmpty(state.ExperienceMonths) ? 0 : parse(state.ExperienceMonths, 511, false, 32), Option_ofString(state.Country), SkillRatingModule_ofString(state.Rating), state.IsDeleted);
}

export function update(msg, state) {
    const updateStateAndParentCmd = (updatedState) => {
        state.OnUserSkillModelChanged(getUserSkillVM(updatedState));
        return [updatedState, Cmd_none()];
    };
    switch (msg.tag) {
        case 1:
            return updateStateAndParentCmd(new State(state.Id, state.SkillId, state.Skills, msg.fields[0], state.Country, state.Rating, state.IsDeleted, state.OnUserSkillModelChanged, state.Errors));
        case 2:
            return updateStateAndParentCmd(new State(state.Id, state.SkillId, state.Skills, state.ExperienceMonths, msg.fields[0], state.Rating, state.IsDeleted, state.OnUserSkillModelChanged, state.Errors));
        case 3:
            return updateStateAndParentCmd(new State(state.Id, state.SkillId, state.Skills, state.ExperienceMonths, state.Country, msg.fields[0], state.IsDeleted, state.OnUserSkillModelChanged, state.Errors));
        case 4:
            return updateStateAndParentCmd(new State(state.Id, state.SkillId, state.Skills, state.ExperienceMonths, state.Country, state.Rating, true, state.OnUserSkillModelChanged, state.Errors));
        case 5:
            return [new State(state.Id, state.SkillId, state.Skills, state.ExperienceMonths, state.Country, state.Rating, state.IsDeleted, state.OnUserSkillModelChanged, singleton(toString(msg.fields[0]))), Cmd_none()];
        default:
            return updateStateAndParentCmd(new State(state.Id, parse_1(msg.fields[0]), state.Skills, state.ExperienceMonths, state.Country, state.Rating, state.IsDeleted, state.OnUserSkillModelChanged, state.Errors));
    }
}

export function IndexView(props) {
    let elems_2, input_1, elems, elems_1;
    let patternInput;
    const init_1 = init(props.UserSkill, props.Skills, props.OnUserSkillModelChanged);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.UserSkill]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const children_3 = ofArray([Daisy_error(state_1.Errors), createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-5", "my-2", "gap-2"])], (elems_2 = [createElement("select", createObj(Helpers_combineClasses("select", ofArray([["className", "select-bordered"], ["className", "select-sm"], ["placeholder", "Skill"], ["readOnly", props.IsSaving], ["value", defaultArg((input_1 = state_1.SkillId, (input_1 == null) ? undefined : input_1), "")], ["onChange", (ev) => {
        dispatch(new Msg(0, [ev.target.value]));
    }], (elems = toList(delay(() => map((skill) => createElement("option", {
        value: skill.Id,
        children: String_splitWord(skill.Name),
    }), state_1.Skills))), ["children", reactApi.Children.toArray(Array.from(elems))])])))), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", state_1.ExperienceMonths], ["placeholder", "Experience (months)"], ["readOnly", props.IsSaving], ["onChange", (ev_1) => {
        dispatch(new Msg(1, [ev_1.target.value]));
    }]])))), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", state_1.Country], ["placeholder", "Country"], ["readOnly", props.IsSaving], ["onChange", (ev_2) => {
        dispatch(new Msg(2, [ev_2.target.value]));
    }]])))), createElement("select", createObj(Helpers_combineClasses("select", ofArray([["className", "select-bordered"], ["className", "select-sm"], ["placeholder", "Rating"], ["readOnly", props.IsSaving], ["value", state_1.Rating], ["onChange", (ev_3) => {
        dispatch(new Msg(3, [ev_3.target.value]));
    }], (elems_1 = toList(delay(() => map((skill_1) => createElement("option", {
        value: skill_1,
        children: skill_1,
    }), SkillRatingModule_getLookupValues()))), ["children", reactApi.Children.toArray(Array.from(elems_1))])])))), createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-secondary"], ["className", "btn-sm"], ["children", "Delete"], ["onClick", (_arg_2) => {
        dispatch(new Msg(4, [props.Index]));
    }]]))))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))]);
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_3)),
    });
}

