import { Record, Union } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { option_type, record_type, bool_type, int32_type, decimal_type, string_type, class_type, union_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { singleton, ofArray } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/List.js";
import { FSharpResult$2 } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Result.js";

export class Product extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["RememberWhere", "LogMaster", "WorkMate", "WorkMateMobile"];
    }
}

export function Product_$reflection() {
    return union_type("Domain.Entity.Product.Product", [], Product, () => [[], [], [], []]);
}

export const ProductModule_allProducts = ofArray([new Product(0, []), new Product(1, []), new Product(2, [])]);

export function ProductModule_toString(_arg) {
    switch (_arg.tag) {
        case 1:
            return "LogMaster";
        case 2:
            return "WorkMate";
        case 3:
            return "WorkMateMobile";
        default:
            return "RememberWhere";
    }
}

export function ProductModule_ofString(product) {
    switch (product) {
        case "RememberWhere":
            return new FSharpResult$2(0, [new Product(0, [])]);
        case "LogMaster":
            return new FSharpResult$2(0, [new Product(1, [])]);
        case "WorkMate":
            return new FSharpResult$2(0, [new Product(2, [])]);
        case "WorkMateMobile":
            return new FSharpResult$2(0, [new Product(3, [])]);
        default:
            return new FSharpResult$2(1, [singleton("Invalid Product.")]);
    }
}

export class ProductSubscription extends Record {
    constructor(Id, OrgId, SubscriptionId, PurchaseDate, ExpiryDate, SubscriptionPrice, SubscriptionCount, IsActive) {
        super();
        this.Id = Id;
        this.OrgId = OrgId;
        this.SubscriptionId = SubscriptionId;
        this.PurchaseDate = PurchaseDate;
        this.ExpiryDate = ExpiryDate;
        this.SubscriptionPrice = SubscriptionPrice;
        this.SubscriptionCount = (SubscriptionCount | 0);
        this.IsActive = IsActive;
    }
}

export function ProductSubscription_$reflection() {
    return record_type("Domain.Entity.Product.ProductSubscription", [], ProductSubscription, () => [["Id", class_type("System.Guid")], ["OrgId", class_type("System.Guid")], ["SubscriptionId", string_type], ["PurchaseDate", class_type("System.DateTimeOffset")], ["ExpiryDate", class_type("System.DateTimeOffset")], ["SubscriptionPrice", decimal_type], ["SubscriptionCount", int32_type], ["IsActive", bool_type]]);
}

export class SubscriptionUserAllocation extends Record {
    constructor(Id, SubscriptionId, UserId, UserFullName, Product, DateAllocated, DateDeallocated) {
        super();
        this.Id = Id;
        this.SubscriptionId = SubscriptionId;
        this.UserId = UserId;
        this.UserFullName = UserFullName;
        this.Product = Product;
        this.DateAllocated = DateAllocated;
        this.DateDeallocated = DateDeallocated;
    }
}

export function SubscriptionUserAllocation_$reflection() {
    return record_type("Domain.Entity.Product.SubscriptionUserAllocation", [], SubscriptionUserAllocation, () => [["Id", class_type("System.Guid")], ["SubscriptionId", string_type], ["UserId", class_type("System.Guid")], ["UserFullName", string_type], ["Product", Product_$reflection()], ["DateAllocated", class_type("System.DateTimeOffset")], ["DateDeallocated", option_type(class_type("System.DateTimeOffset"))]]);
}

