import { Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { tuple_type, union_type, record_type, string_type, option_type, bool_type, anonRecord_type, array_type, uint8_type, list_type, class_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Busy, Busy_$reflection } from "../../SharedView.fs.js";
import { ShiftJobImageDetail_$reflection } from "../../../../Logos.Shared/Domain/Dto/Shift.fs.js";
import { Pagination_$reflection } from "../../../../Logos.Shared/Domain/Entity/Pagination.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { getShiftJobImage, getShiftJobImageThumbnail, getShiftJobImageDetails } from "../../../AppDomain/UseCase/Job.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../Infrastructure/AppRoot.fs.js";
import { dateHash, createObj, equals } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Cmd_batch, Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { paginationControl as paginationControl_1, firstPage, nextPage, previousPage, getDefaultPagination } from "../../Component/Pagination.fs.js";
import { sortByDescending, tryFind, ofArray, map, isEmpty, singleton as singleton_1, append, empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { map as map_1, collect, empty as empty_1, singleton as singleton_2, append as append_1, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { alignCellTextTop, Daisy_DialogWidth, Daisy_progressState, Daisy_error } from "../../Component/Component.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { showModalDialog } from "../../Component/ModalDialog.fs.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { join, toBase64String } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { DateTime_timeToString, DateTime_dateToStringWithDayName } from "../../../AppDomain/Util.fs.js";
import { toLocalTime } from "../../../fable_modules/fable-library-js.4.19.3/DateOffset.js";
import { equals as equals_1, date as date_1, compare } from "../../../fable_modules/fable-library-js.4.19.3/Date.js";
import { List_groupBy } from "../../../fable_modules/fable-library-js.4.19.3/Seq2.js";

export class State extends Record {
    constructor(JobId, IsBusy, ShiftJobImages, Thumbnails, IsShowingImageModalDialog, SelectedImageToShowAsModal, NextPagination, CurrentPagination, Errors) {
        super();
        this.JobId = JobId;
        this.IsBusy = IsBusy;
        this.ShiftJobImages = ShiftJobImages;
        this.Thumbnails = Thumbnails;
        this.IsShowingImageModalDialog = IsShowingImageModalDialog;
        this.SelectedImageToShowAsModal = SelectedImageToShowAsModal;
        this.NextPagination = NextPagination;
        this.CurrentPagination = CurrentPagination;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.ShiftJobImages.State", [], State, () => [["JobId", class_type("System.Guid")], ["IsBusy", Busy_$reflection()], ["ShiftJobImages", list_type(ShiftJobImageDetail_$reflection())], ["Thumbnails", list_type(anonRecord_type(["Image", array_type(uint8_type)], ["ShiftJobImageId", class_type("System.Guid")]))], ["IsShowingImageModalDialog", bool_type], ["SelectedImageToShowAsModal", option_type(array_type(uint8_type))], ["NextPagination", Pagination_$reflection()], ["CurrentPagination", Pagination_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetJobImagesResponse", "GetImageThumbnailResponse", "GetImageResponse", "GotoPreviousPage", "GotoNextPage", "GotoFirstPage", "ShowImageDialog", "HideImage", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.ShiftJobImages.Msg", [], Msg, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(ShiftJobImageDetail_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", list_type(ShiftJobImageDetail_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(class_type("System.Guid"), array_type(uint8_type)), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", tuple_type(class_type("System.Guid"), array_type(uint8_type))]], [["ErrorValue", list_type(string_type)]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(uint8_type), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", array_type(uint8_type)]], [["ErrorValue", list_type(string_type)]]])]], [], [], [], [["Item", class_type("System.Guid")]], [], [["Item", class_type("System.Exception")]]]);
}

export function getJobImagesCmd(jobId, pagination) {
    return cmdOfAsync((Item) => (new Msg(0, [Item])), (Item_1) => (new Msg(8, [Item_1])), () => singleton.Delay(() => getShiftJobImageDetails(appRoot.JobService, AppRootModule_getSelectedOrgId(), jobId, pagination)));
}

export function getJobImageThumbnailCmd(shiftJobImageId) {
    return cmdOfAsync((Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(8, [Item_1])), () => singleton.Delay(() => {
        const x = getShiftJobImageThumbnail(appRoot.JobService, AppRootModule_getSelectedOrgId(), shiftJobImageId);
        return singleton.Bind(x, (x$0027) => {
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [[shiftJobImageId, input_2.fields[0]]])));
            return singleton.Return(value);
        });
    }));
}

export function getJobImageCmd(shiftJobImageId) {
    return cmdOfAsync((Item) => (new Msg(2, [Item])), (Item_1) => (new Msg(8, [Item_1])), () => singleton.Delay(() => getShiftJobImage(appRoot.JobService, AppRootModule_getSelectedOrgId(), shiftJobImageId)));
}

function changePage(state, f) {
    const newPagination = f(state.CurrentPagination);
    if (equals(state.NextPagination, newPagination)) {
        return [state, Cmd_none()];
    }
    else {
        return [new State(state.JobId, new Busy(1, []), state.ShiftJobImages, state.Thumbnails, state.IsShowingImageModalDialog, state.SelectedImageToShowAsModal, newPagination, state.CurrentPagination, state.Errors), getJobImagesCmd(state.JobId, newPagination)];
    }
}

export function init(jobId) {
    const pagination = getDefaultPagination();
    return [new State(jobId, new Busy(0, []), empty(), empty(), false, undefined, pagination, pagination, empty()), getJobImagesCmd(jobId, pagination)];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            if (msg.fields[0].tag === 1) {
                return [new State(state.JobId, new Busy(0, []), state.ShiftJobImages, state.Thumbnails, state.IsShowingImageModalDialog, state.SelectedImageToShowAsModal, state.NextPagination, state.CurrentPagination, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.JobId, new Busy(0, []), state.ShiftJobImages, append(state.Thumbnails, singleton_1({
                    Image: msg.fields[0].fields[0][1],
                    ShiftJobImageId: msg.fields[0].fields[0][0],
                })), state.IsShowingImageModalDialog, state.SelectedImageToShowAsModal, state.NextPagination, state.CurrentPagination, state.Errors), Cmd_none()];
            }
        case 3:
            return changePage(state, previousPage);
        case 4:
            return changePage(state, nextPage);
        case 5:
            return changePage(state, firstPage);
        case 6:
            return [new State(state.JobId, state.IsBusy, state.ShiftJobImages, state.Thumbnails, true, state.SelectedImageToShowAsModal, state.NextPagination, state.CurrentPagination, state.Errors), getJobImageCmd(msg.fields[0])];
        case 2:
            if (msg.fields[0].tag === 1) {
                return [new State(state.JobId, state.IsBusy, state.ShiftJobImages, state.Thumbnails, false, state.SelectedImageToShowAsModal, state.NextPagination, state.CurrentPagination, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.JobId, state.IsBusy, state.ShiftJobImages, state.Thumbnails, state.IsShowingImageModalDialog, msg.fields[0].fields[0], state.NextPagination, state.CurrentPagination, state.Errors), Cmd_none()];
            }
        case 7:
            return [new State(state.JobId, state.IsBusy, state.ShiftJobImages, state.Thumbnails, false, undefined, state.NextPagination, state.CurrentPagination, state.Errors), Cmd_none()];
        case 8:
            return [new State(state.JobId, new Busy(0, []), state.ShiftJobImages, state.Thumbnails, state.IsShowingImageModalDialog, state.SelectedImageToShowAsModal, state.NextPagination, state.CurrentPagination, singleton_1(msg.fields[0].message)), Cmd_none()];
        default:
            if (msg.fields[0].tag === 1) {
                return [new State(state.JobId, new Busy(0, []), state.ShiftJobImages, state.Thumbnails, state.IsShowingImageModalDialog, state.SelectedImageToShowAsModal, state.NextPagination, state.CurrentPagination, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [isEmpty(msg.fields[0].fields[0]) ? (new State(state.JobId, new Busy(0, []), state.ShiftJobImages, state.Thumbnails, state.IsShowingImageModalDialog, state.SelectedImageToShowAsModal, state.CurrentPagination, state.CurrentPagination, state.Errors)) : (new State(state.JobId, new Busy(0, []), msg.fields[0].fields[0], state.Thumbnails, state.IsShowingImageModalDialog, state.SelectedImageToShowAsModal, state.NextPagination, state.NextPagination, state.Errors)), Cmd_batch(map((x) => getJobImageThumbnailCmd(x.Id), msg.fields[0].fields[0]))];
            }
    }
}

export function IndexView(indexViewInputProps) {
    const jobId = indexViewInputProps.jobId;
    let patternInput;
    const init_1 = init(jobId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const paginationControl = () => paginationControl_1(false, state_1.CurrentPagination.CurrentPage, (_arg_2) => {
        dispatch(new Msg(3, []));
    }, (_arg_3) => {
        dispatch(new Msg(4, []));
    }, (_arg_4) => {
        dispatch(new Msg(5, []));
    });
    const children_19 = toList(delay(() => append_1(singleton_2(Daisy_error(state_1.Errors)), delay(() => {
        let children;
        return append_1(singleton_2((children = singleton_1(createElement(Daisy_progressState, {
            busy: state_1.IsBusy,
        })), createElement("div", {
            className: "form-control",
            children: reactApi.Children.toArray(Array.from(children)),
        }))), delay(() => append_1(state_1.IsShowingImageModalDialog ? singleton_2(createElement(showModalDialog, {
            dialogId: `${state_1.JobId} - Image`,
            header: "",
            width: new Daisy_DialogWidth(4, []),
            heightPercent: "80",
            body: () => {
                const matchValue = state_1.SelectedImageToShowAsModal;
                if (matchValue == null) {
                    const children_4 = singleton_1(createElement("progress", createObj(Helpers_combineClasses("progress", singleton_1(["className", "progress-primary"])))));
                    return createElement("div", {
                        children: reactApi.Children.toArray(Array.from(children_4)),
                    });
                }
                else {
                    return createElement("img", {
                        src: "data:image/jpg;base64," + toBase64String(matchValue),
                        style: {
                            width: 100 + "%",
                            height: 100 + "%",
                        },
                    });
                }
            },
            onShow: () => {
            },
            onClose: () => {
                dispatch(new Msg(7, []));
            },
        })) : empty_1(), delay(() => append_1(singleton_2(paginationControl()), delay(() => {
            let elems_3, children_8, children_6, children_15;
            return append_1(singleton_2(createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["w-full"])], (elems_3 = [(children_8 = singleton_1((children_6 = ofArray([createElement("th", {
                width: 15 + "%",
                children: "Date",
            }), createElement("th", {
                width: 15 + "%",
                children: "Image",
            }), createElement("th", {
                width: 15 + "%",
                children: "User",
            }), createElement("th", {
                width: 55 + "%",
                children: "Note",
            })]), createElement("tr", {
                children: reactApi.Children.toArray(Array.from(children_6)),
            }))), createElement("thead", {
                children: reactApi.Children.toArray(Array.from(children_8)),
            })), (children_15 = toList(delay(() => collect((matchValue_1) => {
                let children_10;
                return append_1(singleton_2((children_10 = singleton_1(createElement("td", {
                    colSpan: 3,
                    children: DateTime_dateToStringWithDayName(matchValue_1[0]),
                    style: {
                        fontWeight: "bold",
                    },
                })), createElement("tr", {
                    children: reactApi.Children.toArray(Array.from(children_10)),
                }))), delay(() => map_1((image_1) => {
                    let elems_2, children_13;
                    return createElement("tr", createObj(ofArray([["className", join(" ", ["hover"])], (elems_2 = [createElement("td", createObj(ofArray([alignCellTextTop, ["children", DateTime_timeToString(toLocalTime(image_1.Created))]]))), (children_13 = toList(delay(() => {
                        let elems_1;
                        const matchValue_2 = tryFind((x_2) => (x_2.ShiftJobImageId === image_1.Id), state_1.Thumbnails);
                        if (matchValue_2 == null) {
                            return singleton_2(createElement("progress", createObj(Helpers_combineClasses("progress", singleton_1(["className", "progress-primary"])))));
                        }
                        else {
                            const x_3 = matchValue_2;
                            return singleton_2(createElement("div", createObj(ofArray([["style", {
                                position: "relative",
                                display: "inline-block",
                            }], (elems_1 = [createElement("img", {
                                src: "data:image/jpg;base64," + toBase64String(x_3.Image),
                                style: {
                                    width: 100 + "px",
                                    height: 100 + "px",
                                },
                                title: "Click to view in modal",
                            }), createElement("i", {
                                className: "fas fa-search-plus",
                                style: {
                                    position: "absolute",
                                    top: 5 + "px",
                                    right: 5 + "px",
                                    color: "white",
                                    fontSize: 12 + "px",
                                    cursor: "pointer",
                                },
                                onClick: (_arg_8) => {
                                    dispatch(new Msg(6, [x_3.ShiftJobImageId]));
                                },
                            })], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))));
                        }
                    })), createElement("td", {
                        children: reactApi.Children.toArray(Array.from(children_13)),
                    })), createElement("td", createObj(ofArray([alignCellTextTop, ["children", image_1.Username]]))), createElement("td", createObj(ofArray([alignCellTextTop, ["children", image_1.Note]])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
                }, sortByDescending((x_4) => toLocalTime(x_4.Created), matchValue_1[1], {
                    Compare: compare,
                }))));
            }, List_groupBy((x) => date_1(toLocalTime(x.Created)), state_1.ShiftJobImages, {
                Equals: equals_1,
                GetHashCode: dateHash,
            })))), createElement("tbody", {
                children: reactApi.Children.toArray(Array.from(children_15)),
            }))], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))))), delay(() => append_1(singleton_2(createElement("div", createObj(Helpers_combineClasses("divider", empty())))), delay(() => singleton_2(paginationControl())))));
        }))))));
    }))));
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_19)),
    });
}

