import { Union } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type, anonRecord_type, class_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { compare } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Date.js";
import { singleton } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/List.js";
import { createInternal } from "./Util.fs.js";

export class DateRangeOffset extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["DateRangeDateRangeOffsetType"];
    }
}

export function DateRangeOffset_$reflection() {
    return union_type("Domain.Entity.DateRangeOffset.DateRangeOffset", [], DateRangeOffset, () => [[["Item", anonRecord_type(["Finish", class_type("System.DateTimeOffset")], ["Start", class_type("System.DateTimeOffset")])]]]);
}

const validations = singleton([(x) => !(compare(x.Start, x.Finish) <= 0), (x_1) => (`Start must be less than or equal to Finish - Start: ${x_1.Start}, Finish: ${x_1.Finish}.`)]);

export function create(startDate, finishDate) {
    const dateRange = {
        Finish: finishDate,
        Start: startDate,
    };
    return createInternal(validations, dateRange, new DateRangeOffset(dateRange));
}

export function value(_arg) {
    return _arg.fields[0];
}

