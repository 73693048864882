import { Tooltip as Tooltip_1, Title as Title_1, Legend as Legend_1, ArcElement as ArcElement_1, BarElement as BarElement_1, PointElement as PointElement_1, LineElement as LineElement_1, LinearScale as LinearScale_1, CategoryScale as CategoryScale_1, Chart } from "chart.js";
import * as chart_1 from "chart.js";
import * as chartjs_plugin_datalabels from "chartjs-plugin-datalabels";
import { Bar, Doughnut, Line, Chart as Chart_1 } from "react-chartjs-2";
import * as react_chartjs_2 from "react-chartjs-2";

export const ChartJS = Chart;

export const CategoryScale = CategoryScale_1;

export const LinearScale = LinearScale_1;

export const LineElement = LineElement_1;

export const PointElement = PointElement_1;

export const BarElement = BarElement_1;

export const ArcElement = ArcElement_1;

export const Legend = Legend_1;

export const Title = Title_1;

export const Tooltip = Tooltip_1;

export const ChartDataLabels = chartjs_plugin_datalabels;

export const chart = Chart_1;

export const line = Line;

export const doughnut = Doughnut;

export const bar = Bar;

export const eventOperations = react_chartjs_2;

export const eventChartJSOperations = chart_1;

ChartJS.register(CategoryScale);

ChartJS.register(LinearScale);

ChartJS.register(PointElement);

ChartJS.register(LineElement);

ChartJS.register(BarElement);

ChartJS.register(ArcElement);

ChartJS.register(Tooltip);

ChartJS.register(Legend);

ChartJS.register(Title);

ChartJS.register(ChartDataLabels);

