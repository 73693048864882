var stripe = null;
var elements = null;
var payment = null;

export function InitStripe(stripeKey, clientSecret) {
    stripe = new Stripe(stripeKey);
    const options = {
        clientSecret: clientSecret,
        // Fully customizable with appearance API.
        appearance: {
            theme: 'stripe'
        },
        };
    elements = stripe.elements(options);
}

export function LoadStripeForm() {
    payment = elements.create('payment');
    MountCardElements();
}

export function MountCardElements() {
    payment.mount('#payment')
}

export async function ConfirmPayment() {
    let {error} = await stripe.confirmPayment({
        elements,
        redirect: 'if_required'
    });
    if (error) {
        return { isError: true, errorMessage: error.message };
    }
    else {
        return { isError: false, errorMessage: "" };
    }
}