import { Record } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { Email_$reflection } from "../Entity/Email.fs.js";
import { record_type, lambda_type, class_type, union_type, list_type, string_type, unit_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { FSharpResult$2 } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Result.js";
import { TokenResult_$reflection, ResetPasswordDto_$reflection, SignInDto_$reflection, SignupDto_$reflection } from "../Dto/User.fs.js";
import { Password_$reflection } from "../Entity/Password.fs.js";
import { printf, toText } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/String.js";

export class AuthService extends Record {
    constructor(getOneTimeSignupCode, signup, signIn, getOneTimeResetCode, resetPassword, refreshToken) {
        super();
        this.getOneTimeSignupCode = getOneTimeSignupCode;
        this.signup = signup;
        this.signIn = signIn;
        this.getOneTimeResetCode = getOneTimeResetCode;
        this.resetPassword = resetPassword;
        this.refreshToken = refreshToken;
    }
}

export function AuthService_$reflection() {
    return record_type("Logos.Shared.Domain.Remoting.AuthService", [], AuthService, () => [["getOneTimeSignupCode", lambda_type(Email_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])]))], ["signup", lambda_type(SignupDto_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])]))], ["signIn", lambda_type(Email_$reflection(), lambda_type(Password_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [SignInDto_$reflection(), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", SignInDto_$reflection()]], [["ErrorValue", list_type(string_type)]]])])))], ["getOneTimeResetCode", lambda_type(Email_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])]))], ["resetPassword", lambda_type(ResetPasswordDto_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])]))], ["refreshToken", lambda_type(string_type, lambda_type(string_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [TokenResult_$reflection(), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", TokenResult_$reflection()]], [["ErrorValue", list_type(string_type)]]])])))]]);
}

export function AuthService_RouteBuilder(s, m) {
    return toText(printf("/api/%s/%s"))(s)(m);
}

