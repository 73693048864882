import { Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { LeaveRequestTypeModule_toString, LeaveRequestStatusModule_toString, LeaveRequestStatus, LeaveRequest as LeaveRequest_2, LeaveRequest_$reflection } from "../../../../Logos.Shared/Domain/Entity/User.fs.js";
import { array_type, uint8_type, class_type, unit_type, union_type, record_type, list_type, string_type, bool_type, option_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { BusyModule_isSaving, Busy, Busy_$reflection } from "../../SharedView.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { saveLeaveRequest, getLeavePdf, rejectLeaveRequest, approveLeaveRequest, getLeaveRequest } from "../../../AppDomain/UseCase/Leaves.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../Infrastructure/AppRoot.fs.js";
import { ofArray, empty, singleton as singleton_1 } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { Option_ofString } from "../../../../Logos.Shared/Util.fs.js";
import { utcNow } from "../../../fable_modules/fable-library-js.4.19.3/Date.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { DateTime_toString, DateTime_dateTimeToStringWithDayName, HttpUtil_saveByteArray } from "../../../AppDomain/Util.fs.js";
import { defaultArg } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../../Component/ViewContainer.fs.js";
import { empty as empty_1, singleton as singleton_2, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Daisy_h6, Daisy_progressState, Daisy_error, confirmSimpleWithBody, Daisy_DialogWidth, confirmSimple, printButton, Daisy_h4 } from "../../Component/Component.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";

export class State extends Record {
    constructor(LeaveRequest, IsConfirmApproval, IsConfirmRejection, IsApprovingIP, IsRejectingIP, RejectionReason, PayrollAdminComment, IsBusy, Errors) {
        super();
        this.LeaveRequest = LeaveRequest;
        this.IsConfirmApproval = IsConfirmApproval;
        this.IsConfirmRejection = IsConfirmRejection;
        this.IsApprovingIP = IsApprovingIP;
        this.IsRejectingIP = IsRejectingIP;
        this.RejectionReason = RejectionReason;
        this.PayrollAdminComment = PayrollAdminComment;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.LeaveEdit.State", [], State, () => [["LeaveRequest", option_type(LeaveRequest_$reflection())], ["IsConfirmApproval", bool_type], ["IsConfirmRejection", bool_type], ["IsApprovingIP", bool_type], ["IsRejectingIP", bool_type], ["RejectionReason", string_type], ["PayrollAdminComment", string_type], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetLeaveRequestResponse", "ConfirmApproval", "ConfirmApprovalResponse", "ApproveResponse", "ConfirmRejection", "ConfirmRejectionResponse", "RejectResponse", "SetRejectionReason", "SetPayrollAdminComment", "GetLeavePdf", "GetLeavePdfResponse", "SaveLeaveRequest", "SaveLeaveRequestResponse", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.LeaveEdit.Msg", [], Msg, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [option_type(LeaveRequest_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", option_type(LeaveRequest_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [], [["Item", bool_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])]], [], [["Item", bool_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])]], [["Item", string_type]], [["Item", string_type]], [["Item", class_type("System.Guid")]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(uint8_type), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", array_type(uint8_type)]], [["ErrorValue", list_type(string_type)]]])]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Exception")]]]);
}

export function getLeaveRequestCmd(leaveId) {
    return cmdOfAsync((Item) => (new Msg(0, [Item])), (Item_1) => (new Msg(13, [Item_1])), () => singleton.Delay(() => getLeaveRequest(appRoot.LeavesService, AppRootModule_getSelectedOrgId(), leaveId)));
}

export function approveLeaveRequestCmd(state) {
    return cmdOfAsync((Item) => (new Msg(3, [Item])), (Item_1) => (new Msg(13, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_1;
        let value_1;
        const option_1 = state.LeaveRequest;
        value_1 = ((option_1 == null) ? (new FSharpResult$2(1, [singleton_1("Invalid leave request")])) : (new FSharpResult$2(0, [option_1])));
        asyncResult_1 = singleton.Return(value_1);
        return singleton.Bind(asyncResult_1, (input_1) => {
            const input_2 = input_1;
            if (input_2.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
            }
            else {
                const leaveRequest = input_2.fields[0];
                const leaveRequest_1 = new LeaveRequest_2(leaveRequest.Id, leaveRequest.OrgId, leaveRequest.UserId, leaveRequest.StartDate, leaveRequest.FinishDate, leaveRequest.Status, leaveRequest.LeaveRequestType, leaveRequest.Reason, Option_ofString(state.PayrollAdminComment), leaveRequest.PayrollAdminCommentUser, leaveRequest.Created, leaveRequest.Modified);
                return approveLeaveRequest(appRoot.LeavesService, AppRootModule_getSelectedOrgId(), leaveRequest_1);
            }
        });
    }));
}

export function rejectLeaveRequestCmd(state) {
    return cmdOfAsync((Item) => (new Msg(6, [Item])), (Item_1) => (new Msg(13, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_1;
        let value_1;
        const option_1 = state.LeaveRequest;
        value_1 = ((option_1 == null) ? (new FSharpResult$2(1, [singleton_1("Invalid leave request")])) : (new FSharpResult$2(0, [option_1])));
        asyncResult_1 = singleton.Return(value_1);
        return singleton.Bind(asyncResult_1, (input_1) => {
            const input_2 = input_1;
            if (input_2.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
            }
            else {
                const leaveRequest = input_2.fields[0];
                const leaveRequest_1 = new LeaveRequest_2(leaveRequest.Id, leaveRequest.OrgId, leaveRequest.UserId, leaveRequest.StartDate, leaveRequest.FinishDate, new LeaveRequestStatus(5, [{
                    DateRejected: utcNow(),
                    RejectedBy: "00000000-0000-0000-0000-000000000000",
                    RejectionReason: state.RejectionReason,
                }]), leaveRequest.LeaveRequestType, leaveRequest.Reason, Option_ofString(state.PayrollAdminComment), leaveRequest.PayrollAdminCommentUser, leaveRequest.Created, leaveRequest.Modified);
                return rejectLeaveRequest(appRoot.LeavesService, AppRootModule_getSelectedOrgId(), leaveRequest_1);
            }
        });
    }));
}

export function getLeavePdfCmd(leaveRequestId) {
    return cmdOfAsync((Item) => (new Msg(10, [Item])), (Item_1) => (new Msg(13, [Item_1])), () => singleton.Delay(() => getLeavePdf(appRoot.LeavesService, AppRootModule_getSelectedOrgId(), leaveRequestId)));
}

export function saveLeaveRequestCmd(state) {
    return cmdOfAsync((Item) => (new Msg(12, [Item])), (Item_1) => (new Msg(13, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_1;
        let value_1;
        const option_1 = state.LeaveRequest;
        value_1 = ((option_1 == null) ? (new FSharpResult$2(1, [singleton_1("Invalid leave request")])) : (new FSharpResult$2(0, [option_1])));
        asyncResult_1 = singleton.Return(value_1);
        return singleton.Bind(asyncResult_1, (input_1) => {
            const input_2 = input_1;
            if (input_2.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
            }
            else {
                const leaveRequest = input_2.fields[0];
                const leaveRequest_1 = new LeaveRequest_2(leaveRequest.Id, leaveRequest.OrgId, leaveRequest.UserId, leaveRequest.StartDate, leaveRequest.FinishDate, leaveRequest.Status, leaveRequest.LeaveRequestType, leaveRequest.Reason, Option_ofString(state.PayrollAdminComment), leaveRequest.PayrollAdminCommentUser, leaveRequest.Created, leaveRequest.Modified);
                return saveLeaveRequest(appRoot.LeavesService, AppRootModule_getSelectedOrgId(), leaveRequest_1);
            }
        });
    }));
}

export function init(leaveId) {
    return [new State(undefined, false, false, false, false, "", "", new Busy(1, []), empty()), getLeaveRequestCmd(leaveId)];
}

export function update(msg, state) {
    let input_3, x_2, input_5, x_4, input_1;
    switch (msg.tag) {
        case 1:
            return [new State(state.LeaveRequest, true, state.IsConfirmRejection, state.IsApprovingIP, state.IsRejectingIP, state.RejectionReason, state.PayrollAdminComment, state.IsBusy, state.Errors), Cmd_none()];
        case 2: {
            const updatedState = new State(state.LeaveRequest, false, state.IsConfirmRejection, true, state.IsRejectingIP, state.RejectionReason, state.PayrollAdminComment, new Busy(2, []), state.Errors);
            return [updatedState, msg.fields[0] ? approveLeaveRequestCmd(updatedState) : Cmd_none()];
        }
        case 3:
            if (msg.fields[0].tag === 1) {
                return [new State(state.LeaveRequest, state.IsConfirmApproval, state.IsConfirmRejection, false, state.IsRejectingIP, state.RejectionReason, state.PayrollAdminComment, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State((input_3 = state.LeaveRequest, (input_3 == null) ? undefined : ((x_2 = input_3, new LeaveRequest_2(x_2.Id, x_2.OrgId, x_2.UserId, x_2.StartDate, x_2.FinishDate, new LeaveRequestStatus(4, [{
                    ApprovedBy: "00000000-0000-0000-0000-000000000000",
                    DateApproved: utcNow(),
                }]), x_2.LeaveRequestType, x_2.Reason, x_2.PayrollAdminComment, x_2.PayrollAdminCommentUser, x_2.Created, x_2.Modified)))), state.IsConfirmApproval, state.IsConfirmRejection, false, state.IsRejectingIP, state.RejectionReason, state.PayrollAdminComment, new Busy(0, []), empty()), Cmd_none()];
            }
        case 4:
            return [new State(state.LeaveRequest, state.IsConfirmApproval, true, state.IsApprovingIP, state.IsRejectingIP, state.RejectionReason, state.PayrollAdminComment, state.IsBusy, state.Errors), Cmd_none()];
        case 5: {
            const updatedState_1 = new State(state.LeaveRequest, state.IsConfirmApproval, false, state.IsApprovingIP, msg.fields[0], state.RejectionReason, state.PayrollAdminComment, new Busy(2, []), state.Errors);
            return [updatedState_1, msg.fields[0] ? rejectLeaveRequestCmd(updatedState_1) : Cmd_none()];
        }
        case 6:
            if (msg.fields[0].tag === 1) {
                return [new State(state.LeaveRequest, state.IsConfirmApproval, state.IsConfirmRejection, state.IsApprovingIP, false, state.RejectionReason, state.PayrollAdminComment, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State((input_5 = state.LeaveRequest, (input_5 == null) ? undefined : ((x_4 = input_5, new LeaveRequest_2(x_4.Id, x_4.OrgId, x_4.UserId, x_4.StartDate, x_4.FinishDate, new LeaveRequestStatus(5, [{
                    DateRejected: utcNow(),
                    RejectedBy: "00000000-0000-0000-0000-000000000000",
                    RejectionReason: "",
                }]), x_4.LeaveRequestType, x_4.Reason, x_4.PayrollAdminComment, x_4.PayrollAdminCommentUser, x_4.Created, x_4.Modified)))), state.IsConfirmApproval, state.IsConfirmRejection, state.IsApprovingIP, false, state.RejectionReason, state.PayrollAdminComment, new Busy(0, []), empty()), Cmd_none()];
            }
        case 7:
            return [new State(state.LeaveRequest, state.IsConfirmApproval, state.IsConfirmRejection, state.IsApprovingIP, state.IsRejectingIP, msg.fields[0], state.PayrollAdminComment, state.IsBusy, state.Errors), Cmd_none()];
        case 8:
            return [new State(state.LeaveRequest, state.IsConfirmApproval, state.IsConfirmRejection, state.IsApprovingIP, state.IsRejectingIP, state.RejectionReason, msg.fields[0], state.IsBusy, state.Errors), Cmd_none()];
        case 9:
            return [new State(state.LeaveRequest, state.IsConfirmApproval, state.IsConfirmRejection, state.IsApprovingIP, state.IsRejectingIP, state.RejectionReason, state.PayrollAdminComment, new Busy(1, []), empty()), getLeavePdfCmd(msg.fields[0])];
        case 10:
            if (msg.fields[0].tag === 1) {
                return [new State(state.LeaveRequest, state.IsConfirmApproval, state.IsConfirmRejection, state.IsApprovingIP, state.IsRejectingIP, state.RejectionReason, state.PayrollAdminComment, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                HttpUtil_saveByteArray(msg.fields[0].fields[0], "LeaveRequest", "application/pdf");
                return [new State(state.LeaveRequest, state.IsConfirmApproval, state.IsConfirmRejection, state.IsApprovingIP, state.IsRejectingIP, state.RejectionReason, state.PayrollAdminComment, new Busy(0, []), state.Errors), Cmd_none()];
            }
        case 11:
            return [new State(state.LeaveRequest, state.IsConfirmApproval, state.IsConfirmRejection, state.IsApprovingIP, state.IsRejectingIP, state.RejectionReason, state.PayrollAdminComment, new Busy(2, []), empty()), saveLeaveRequestCmd(state)];
        case 12:
            if (msg.fields[0].tag === 1) {
                return [new State(state.LeaveRequest, state.IsConfirmApproval, state.IsConfirmRejection, state.IsApprovingIP, state.IsRejectingIP, state.RejectionReason, state.PayrollAdminComment, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.LeaveRequest, state.IsConfirmApproval, state.IsConfirmRejection, state.IsApprovingIP, state.IsRejectingIP, state.RejectionReason, state.PayrollAdminComment, new Busy(0, []), state.Errors), Cmd_none()];
            }
        case 13:
            return [new State(state.LeaveRequest, state.IsConfirmApproval, state.IsConfirmRejection, state.IsApprovingIP, state.IsRejectingIP, state.RejectionReason, state.PayrollAdminComment, new Busy(0, []), singleton_1(msg.fields[0].message)), Cmd_none()];
        default:
            if (msg.fields[0].tag === 1) {
                return [new State(state.LeaveRequest, state.IsConfirmApproval, state.IsConfirmRejection, state.IsApprovingIP, state.IsRejectingIP, state.RejectionReason, state.PayrollAdminComment, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(msg.fields[0].fields[0], state.IsConfirmApproval, state.IsConfirmRejection, state.IsApprovingIP, state.IsRejectingIP, state.RejectionReason, defaultArg((input_1 = msg.fields[0].fields[0], (input_1 == null) ? undefined : defaultArg(input_1.PayrollAdminComment, "")), ""), new Busy(0, []), state.Errors), Cmd_none()];
            }
    }
}

export function IndexView(props) {
    let input_1, matchValue, input_3, x_1, matchValue_1, input_5, matchValue_2;
    let patternInput;
    const init_1 = init(props.Id);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const leaveRequestIsReadOnly = defaultArg((input_1 = state_1.LeaveRequest, (input_1 == null) ? undefined : ((matchValue = input_1.Status, (matchValue.tag === 0) ? true : ((matchValue.tag === 4) ? true : (matchValue.tag === 5))))), false);
    const leaveRequestStatus = defaultArg((input_3 = state_1.LeaveRequest, (input_3 == null) ? undefined : ((x_1 = input_3, LeaveRequestStatusModule_toString(x_1.Status) + ((matchValue_1 = x_1.Status, (matchValue_1.tag === 4) ? (` by ${props.ApprovedByUsername} on ${DateTime_dateTimeToStringWithDayName(matchValue_1.fields[0].DateApproved)}`) : ((matchValue_1.tag === 5) ? (` by ${props.RejectedByUsername} on ${DateTime_dateTimeToStringWithDayName(matchValue_1.fields[0].DateRejected)}`) : "")))))), "");
    const statusReason = defaultArg((input_5 = state_1.LeaveRequest, (input_5 == null) ? undefined : ((matchValue_2 = input_5.Status, (matchValue_2.tag === 5) ? (`Rejection reason: ${matchValue_2.fields[0].RejectionReason}`) : ""))), "");
    return container(toList(delay(() => {
        let elems;
        return append(singleton_2(createElement("div", createObj(ofArray([["className", "flex justify-between"], (elems = [Daisy_h4(`Leave Request - ${props.User}`), printButton((_arg_3) => {
            dispatch(new Msg(9, [props.Id]));
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => append(singleton_2(createElement("div", createObj(Helpers_combineClasses("divider", empty())))), delay(() => append(state_1.IsConfirmApproval ? singleton_2(confirmSimple(new Daisy_DialogWidth(1, []), "Are you sure you want to approve this leave request?", (_arg_4) => {
            dispatch(new Msg(2, [false]));
        }, (_arg_5) => {
            dispatch(new Msg(2, [true]));
        })) : singleton_2(react.createElement(react.Fragment, {})), delay(() => {
            let elems_1;
            return append(state_1.IsConfirmRejection ? singleton_2(confirmSimpleWithBody(new Daisy_DialogWidth(1, []), createElement("div", createObj(ofArray([["className", "flex flex-col"], (elems_1 = [createElement("label", createObj(Helpers_combineClasses("label", singleton_1(["children", "Are you sure you want to reject this leave request?"])))), createElement("label", createObj(Helpers_combineClasses("label", singleton_1(["children", "Rejection Reason"])))), createElement("textarea", createObj(Helpers_combineClasses("textarea", ofArray([["className", "input-bordered"], ["rows", 3], ["value", state_1.RejectionReason], ["onChange", (ev) => {
                dispatch(new Msg(7, [ev.target.value]));
            }]]))))], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))), (_arg_6) => {
                dispatch(new Msg(5, [false]));
            }, (_arg_7) => {
                dispatch(new Msg(5, [true]));
            })) : singleton_2(react.createElement(react.Fragment, {})), delay(() => append(singleton_2(Daisy_error(state_1.Errors)), delay(() => {
                let children_4;
                return append(singleton_2((children_4 = singleton_1(createElement(Daisy_progressState, {
                    busy: state_1.IsBusy,
                })), createElement("div", {
                    className: "form-control",
                    children: reactApi.Children.toArray(Array.from(children_4)),
                }))), delay(() => {
                    let elems_3;
                    return append(singleton_2(createElement("div", createObj(Helpers_combineClasses("navbar", ofArray([["className", "space-x-2"], (elems_3 = [createElement("button", createObj(Helpers_combineClasses("btn", toList(delay(() => append(BusyModule_isSaving(state_1.IsBusy) ? singleton_2(["className", "loading"]) : empty_1(), delay(() => append(singleton_2(["className", "btn-primary"]), delay(() => append(singleton_2(["className", "btn-sm"]), delay(() => append(singleton_2(["children", "Save"]), delay(() => singleton_2(["onClick", (_arg_9) => {
                        dispatch(new Msg(11, []));
                    }]))))))))))))))], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))))), delay(() => {
                        let children_8;
                        return append(singleton_2((children_8 = singleton_1(Daisy_h6(`Status - ${leaveRequestStatus}`)), createElement("div", {
                            className: "form-control",
                            children: reactApi.Children.toArray(Array.from(children_8)),
                        }))), delay(() => {
                            let children_10;
                            return append((statusReason !== "") ? singleton_2((children_10 = singleton_1(Daisy_h6(statusReason)), createElement("div", {
                                className: "form-control",
                                children: reactApi.Children.toArray(Array.from(children_10)),
                            }))) : empty_1(), delay(() => {
                                let children_12;
                                return append(singleton_2((children_12 = singleton_1(Daisy_h6(`Created - ${DateTime_dateTimeToStringWithDayName(props.Created)}`)), createElement("div", {
                                    className: "form-control",
                                    children: reactApi.Children.toArray(Array.from(children_12)),
                                }))), delay(() => {
                                    let children_15, input_7;
                                    return append(singleton_2((children_15 = ofArray([createElement("label", createObj(Helpers_combineClasses("label", singleton_1(["children", "Leave Type"])))), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", defaultArg((input_7 = state_1.LeaveRequest, (input_7 == null) ? undefined : LeaveRequestTypeModule_toString(input_7.LeaveRequestType)), "")], ["readOnly", true]]))))]), createElement("div", {
                                        className: "form-control",
                                        children: reactApi.Children.toArray(Array.from(children_15)),
                                    }))), delay(() => {
                                        let children_18, input_9;
                                        return append(singleton_2((children_18 = ofArray([createElement("label", createObj(Helpers_combineClasses("label", singleton_1(["children", "Start Date"])))), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", defaultArg((input_9 = state_1.LeaveRequest, (input_9 == null) ? undefined : DateTime_toString(input_9.StartDate)), "")], ["readOnly", true]]))))]), createElement("div", {
                                            className: "form-control",
                                            children: reactApi.Children.toArray(Array.from(children_18)),
                                        }))), delay(() => {
                                            let children_21, input_11;
                                            return append(singleton_2((children_21 = ofArray([createElement("label", createObj(Helpers_combineClasses("label", singleton_1(["children", "Finish Date"])))), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", defaultArg((input_11 = state_1.LeaveRequest, (input_11 == null) ? undefined : DateTime_toString(input_11.FinishDate)), "")], ["readOnly", true]]))))]), createElement("div", {
                                                className: "form-control",
                                                children: reactApi.Children.toArray(Array.from(children_21)),
                                            }))), delay(() => {
                                                let children_25, input_13;
                                                return append(singleton_2((children_25 = ofArray([createElement("label", createObj(Helpers_combineClasses("label", singleton_1(["children", "Reason"])))), createElement("textarea", createObj(Helpers_combineClasses("textarea", ofArray([["className", "input-bordered"], ["className", "input-sm"], ["value", defaultArg((input_13 = state_1.LeaveRequest, (input_13 == null) ? undefined : input_13.Reason), "")], ["readOnly", true]]))))]), createElement("div", {
                                                    className: "form-control",
                                                    children: reactApi.Children.toArray(Array.from(children_25)),
                                                }))), delay(() => {
                                                    let children_29;
                                                    return append(singleton_2((children_29 = ofArray([createElement("label", createObj(Helpers_combineClasses("label", singleton_1(["children", "Payroll Admin Comment"])))), createElement("textarea", createObj(Helpers_combineClasses("textarea", ofArray([["className", "input-bordered"], ["className", "input-sm"], ["value", state_1.PayrollAdminComment], ["onChange", (ev_1) => {
                                                        dispatch(new Msg(8, [ev_1.target.value]));
                                                    }]]))))]), createElement("div", {
                                                        className: "form-control",
                                                        children: reactApi.Children.toArray(Array.from(children_29)),
                                                    }))), delay(() => {
                                                        let elems_12;
                                                        return singleton_2(createElement("div", createObj(ofArray([["className", join(" ", ["flex justify-center space-x-4"])], (elems_12 = [createElement("button", createObj(Helpers_combineClasses("btn", toList(delay(() => append(state_1.IsApprovingIP ? singleton_2(["className", "loading"]) : empty_1(), delay(() => append(singleton_2(["className", "btn-primary"]), delay(() => append(singleton_2(["className", "btn-sm"]), delay(() => append(singleton_2(["disabled", leaveRequestIsReadOnly]), delay(() => append(singleton_2(["children", "Approve"]), delay(() => singleton_2(["onClick", (_arg_11) => {
                                                            dispatch(new Msg(1, []));
                                                        }])))))))))))))))), createElement("button", createObj(Helpers_combineClasses("btn", toList(delay(() => append(state_1.IsRejectingIP ? singleton_2(["className", "loading"]) : empty_1(), delay(() => append(singleton_2(["className", "btn-secondary"]), delay(() => append(singleton_2(["disabled", leaveRequestIsReadOnly]), delay(() => append(singleton_2(["className", "btn-sm"]), delay(() => append(singleton_2(["children", "Reject"]), delay(() => singleton_2(["onClick", (_arg_12) => {
                                                            dispatch(new Msg(4, []));
                                                        }]))))))))))))))))], ["children", reactApi.Children.toArray(Array.from(elems_12))])]))));
                                                    }));
                                                }));
                                            }));
                                        }));
                                    }));
                                }));
                            }));
                        }));
                    }));
                }));
            }))));
        }))))));
    })));
}

