import { appRoot, AppRootModule_getSelectedOrgId } from "../../../Infrastructure/AppRoot.fs.js";
import { searchDocumentTags } from "../../../AppDomain/UseCase/Document.fs.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { uncurry2, createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { ofArray, singleton } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { DocumentTagSearchDetails } from "../../../../Logos.Shared/Domain/Entity/Document.fs.js";
import { IndexView as IndexView_2, Msg$2 } from "../BaseSearchAdvanced.fs.js";
import { PropHelpers_createOnKey } from "../../../fable_modules/Feliz.2.8.0/./Properties.fs.js";
import { key_enter } from "../../../fable_modules/Feliz.2.8.0/Key.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { IndexView as IndexView_1 } from "./DocumentTagEdit.fs.js";
import { map, singleton as singleton_1, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { iconButton, showModalDialog, ButtonType, showModalEx } from "../../Component/ModalDialog.fs.js";
import { alignIconCenter, Daisy_DialogWidth } from "../../Component/Component.fs.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";

export function searchFunc(documentTagSearchDetails) {
    const selectedOrgId = AppRootModule_getSelectedOrgId();
    return (pagination) => searchDocumentTags(appRoot.DocumentService, selectedOrgId, documentTagSearchDetails, pagination);
}

export function searchPanel(props) {
    let children_2, elems;
    const children_4 = singleton((children_2 = ofArray([createElement("label", createObj(Helpers_combineClasses("label", singleton(["children", "Search"])))), createElement("div", createObj(ofArray([["className", "flex space-x-2"], (elems = [createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "w-full"], ["className", "input-bordered"], ["className", "input-sm"], ["autoFocus", true], ["placeholder", "Search"], ["readOnly", false], ["onChange", (ev) => {
        props.dispatch(new Msg$2(0, [new DocumentTagSearchDetails(ev.target.value)]));
    }], ["onKeyUp", (ev_1) => {
        PropHelpers_createOnKey(key_enter, (_arg) => {
            props.dispatch(new Msg$2(1, []));
        }, ev_1);
    }]])))), createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-primary"], ["className", "btn-sm"], ["children", "Search"], ["onClick", (_arg_1) => {
        props.dispatch(new Msg$2(1, []));
    }]]))))], ["children", reactApi.Children.toArray(Array.from(elems))])])))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_2)),
    })));
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_4)),
    });
}

export function expenseTable(props) {
    const body = () => {
        const matchValue = props.state.EntityToEdit;
        if (matchValue == null) {
            return createElement(IndexView_1, {
                documentTagId: "",
            });
        }
        else {
            const x = matchValue;
            return createElement(IndexView_1, {
                documentTagId: x,
            });
        }
    };
    const children_10 = toList(delay(() => {
        let elems;
        return append(singleton_1(createElement("div", createObj(Helpers_combineClasses("navbar", singleton((elems = [createElement(showModalEx, {
            dialogId: "id-DocumentTag-search-add-DocumentTag",
            header: "",
            buttonType: new ButtonType(0, ["Add Document-Tag"]),
            width: new Daisy_DialogWidth(0, []),
            body: body,
            onShow: () => {
                props.dispatch(new Msg$2(4, [undefined]));
            },
            onClose: () => {
                props.dispatch(new Msg$2(4, [undefined]));
            },
        })], ["children", reactApi.Children.toArray(Array.from(elems))])))))), delay(() => {
            let matchValue_1, x_1;
            return append((matchValue_1 = props.state.EntityToEdit, (matchValue_1 == null) ? singleton_1(react.createElement(react.Fragment, {})) : ((x_1 = matchValue_1, singleton_1(createElement(showModalDialog, {
                dialogId: x_1,
                header: "",
                width: new Daisy_DialogWidth(0, []),
                body: body,
                onShow: () => {
                    props.dispatch(new Msg$2(4, [x_1]));
                },
                onClose: () => {
                    props.dispatch(new Msg$2(4, [undefined]));
                },
            }))))), delay(() => {
                let elems_3, children_3, children_1, children_7;
                return singleton_1(createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["flex", "w-full"])], (elems_3 = [(children_3 = singleton((children_1 = ofArray([createElement("th", {
                    width: 90 + "%",
                    children: "Document-Tag",
                }), createElement("th", {
                    width: 10 + "%",
                    children: "",
                })]), createElement("tr", {
                    children: reactApi.Children.toArray(Array.from(children_1)),
                }))), createElement("thead", {
                    children: reactApi.Children.toArray(Array.from(children_3)),
                })), (children_7 = toList(delay(() => map((documentTag) => {
                    let elems_2, children_5, elems_1;
                    return createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top", "hover"])], (elems_2 = [createElement("td", {
                        children: documentTag.Name,
                        style: {
                            cursor: "pointer",
                        },
                        onClick: (_arg_4) => {
                            props.dispatch(new Msg$2(4, [documentTag.Id]));
                        },
                    }), (children_5 = singleton(createElement("div", createObj(ofArray([alignIconCenter, (elems_1 = [iconButton("fas fa-edit", (_arg_5) => {
                        props.dispatch(new Msg$2(4, [documentTag.Id]));
                    })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))), createElement("td", {
                        children: reactApi.Children.toArray(Array.from(children_5)),
                    }))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
                }, props.state.Entities))), createElement("tbody", {
                    children: reactApi.Children.toArray(Array.from(children_7)),
                }))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))));
            }));
        }));
    }));
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_10)),
    });
}

export function IndexView() {
    return createElement(IndexView_2, {
        entityName: "Document Tag",
        entityTable: expenseTable,
        searchDetails: new DocumentTagSearchDetails(""),
        searchFunc: uncurry2(searchFunc),
        searchPanel: searchPanel,
        xPadding: "",
        yPadding: "",
    });
}

