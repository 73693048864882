import { Union, Record } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type, lambda_type, unit_type, anonRecord_type, bool_type, array_type, string_type, record_type, class_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { daysInMonth, month, year, create, addDays } from "../../fable_modules/fable-library-js.4.19.3/Date.js";
import { DateTime_getLastWeekStartEnd } from "../../AppDomain/Util.fs.js";

export class DateRange extends Record {
    constructor(startDate, endDate) {
        super();
        this.startDate = startDate;
        this.endDate = endDate;
    }
}

export function DateRange_$reflection() {
    return record_type("Presentation.Component.DatePicker.DateRange", [], DateRange, () => [["startDate", class_type("System.DateTime")], ["endDate", class_type("System.DateTime")]]);
}

export class Period extends Record {
    constructor(start, end) {
        super();
        this.start = start;
        this.end = end;
    }
}

export function Period_$reflection() {
    return record_type("Presentation.Component.DatePicker.Period", [], Period, () => [["start", class_type("System.DateTime")], ["end", class_type("System.DateTime")]]);
}

export class DatePickerCustomShortcut extends Record {
    constructor(text, period) {
        super();
        this.text = text;
        this.period = period;
    }
}

export function DatePickerCustomShortcut_$reflection() {
    return record_type("Presentation.Component.DatePicker.DatePickerCustomShortcut", [], DatePickerCustomShortcut, () => [["text", string_type], ["period", Period_$reflection()]]);
}

export class DatePickerConfig extends Record {
    constructor(shortcuts) {
        super();
        this.shortcuts = shortcuts;
    }
}

export function DatePickerConfig_$reflection() {
    return record_type("Presentation.Component.DatePicker.DatePickerConfig", [], DatePickerConfig, () => [["shortcuts", array_type(DatePickerCustomShortcut_$reflection())]]);
}

export function getDefaultConfig(now) {
    let lastWeek;
    return new DatePickerConfig([new DatePickerCustomShortcut("Today", new Period(now, now)), new DatePickerCustomShortcut("Yesterday", new Period(addDays(now, -1), addDays(now, -1))), new DatePickerCustomShortcut("Last 7 days", new Period(addDays(now, -7), now)), new DatePickerCustomShortcut("Last week", (lastWeek = DateTime_getLastWeekStartEnd(now), new Period(lastWeek.Start, lastWeek.End))), new DatePickerCustomShortcut("Last 30 days", new Period(addDays(now, -30), now)), new DatePickerCustomShortcut("This month", new Period(create(year(now), month(now), 1), create(year(now), month(now), daysInMonth(year(now), month(now))))), new DatePickerCustomShortcut("Last month", new Period(create(year(now), month(now) - 1, 1), create(year(now), month(now) - 1, daysInMonth(year(now), month(now) - 1))))]);
}

export class DatePickerProps extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["AsSingle", "UseRange", "Value", "DisplayFormat", "OnChange", "ShowShortcuts", "Configs"];
    }
}

export function DatePickerProps_$reflection() {
    return union_type("Presentation.Component.DatePicker.DatePickerProps", [], DatePickerProps, () => [[["Item", bool_type]], [["Item", bool_type]], [["Item", DateRange_$reflection()]], [["Item", string_type]], [["Item", lambda_type(anonRecord_type(["endDate", string_type], ["startDate", string_type]), unit_type)]], [["Item", bool_type]], [["Item", DatePickerConfig_$reflection()]]]);
}

