import { Union, Record } from "../../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { array_type, uint8_type, union_type, record_type, string_type, class_type, list_type, anonRecord_type, bool_type } from "../../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { JobDetail_$reflection } from "../../../../../Logos.Shared/Domain/Entity/Job.fs.js";
import { Busy, Busy_$reflection } from "../../../SharedView.fs.js";
import { FSharpResult$2 } from "../../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { getDefaultConfig, DateRange, DateRange_$reflection } from "../../../Component/ReactTailwindDatePicker.fs.js";
import { cmdOfAsync } from "../../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { getActiveJobs } from "../../../../AppDomain/UseCase/Job.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../../Infrastructure/AppRoot.fs.js";
import { append, ofArray, singleton as singleton_1, empty, filter, map } from "../../../../fable_modules/fable-library-js.4.19.3/List.js";
import { create } from "../../../../../Logos.Shared/Domain/Entity/DateRangeOffset.fs.js";
import { fromDate } from "../../../../fable_modules/fable-library-js.4.19.3/DateOffset.js";
import { parse, dayOfWeek, now as now_1, addDays, date, toUniversalTime } from "../../../../fable_modules/fable-library-js.4.19.3/Date.js";
import { getTimesheetsPdf } from "../../../../AppDomain/UseCase/Timesheet.fs.js";
import { TimesheetReportGroupedBy } from "../../../../../Logos.Shared/Domain/Entity/Shift.fs.js";
import { createObj, equals } from "../../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Cmd_none } from "../../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { HttpUtil_saveByteArray } from "../../../../AppDomain/Util.fs.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { React_useElmish_Z6C327F2E } from "../../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../../../Component/ViewContainer.fs.js";
import { Daisy_progressState, Daisy_error, Daisy_h4 } from "../../../Component/Component.fs.js";
import { reactApi } from "../../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import react_tailwindcss_datepicker from "react-tailwindcss-datepicker";
import { Helpers_combineClasses } from "../../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { join } from "../../../../fable_modules/fable-library-js.4.19.3/String.js";

export class State extends Record {
    constructor(IsSelectedAllChecked, Jobs, DateFrom, DateTo, IsBusy, Errors) {
        super();
        this.IsSelectedAllChecked = IsSelectedAllChecked;
        this.Jobs = Jobs;
        this.DateFrom = DateFrom;
        this.DateTo = DateTo;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.WorkMate.Report.TimesheetsReportByJobs.State", [], State, () => [["IsSelectedAllChecked", bool_type], ["Jobs", list_type(anonRecord_type(["IsChecked", bool_type], ["Job", JobDetail_$reflection()]))], ["DateFrom", class_type("System.DateTime")], ["DateTo", class_type("System.DateTime")], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetJobsResponse", "SelectJob", "SetSelectAll", "SetDateRange", "GetTimesheetsPdf", "GetTimesheetsPdfResponse", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.WorkMate.Report.TimesheetsReportByJobs.Msg", [], Msg, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(JobDetail_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", list_type(JobDetail_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [["Item1", bool_type], ["Item2", JobDetail_$reflection()]], [["Item", bool_type]], [["Item", DateRange_$reflection()]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(uint8_type), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", array_type(uint8_type)]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Exception")]]]);
}

export function getJobsCmd() {
    return cmdOfAsync((Item) => (new Msg(0, [Item])), (Item_1) => (new Msg(6, [Item_1])), () => singleton.Delay(() => getActiveJobs(appRoot.JobService, AppRootModule_getSelectedOrgId())));
}

export function getPdfReportCmd(state) {
    return cmdOfAsync((Item) => (new Msg(5, [Item])), (Item_1) => (new Msg(6, [Item_1])), () => singleton.Delay(() => {
        const jobIds = map((j_1) => j_1.Job.Id, filter((j) => j.IsChecked, state.Jobs));
        let asyncResult_1;
        const value_1 = create(fromDate(toUniversalTime(date(state.DateFrom))), fromDate(toUniversalTime(addDays(date(state.DateTo), 1))));
        asyncResult_1 = singleton.Return(value_1);
        return singleton.Bind(asyncResult_1, (input_1) => {
            const input_2 = input_1;
            if (input_2.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
            }
            else {
                return getTimesheetsPdf(appRoot.ShiftService, AppRootModule_getSelectedOrgId(), new TimesheetReportGroupedBy(1, []), jobIds, input_2.fields[0]);
            }
        });
    }));
}

export function init() {
    const now = now_1();
    const weekStart = addDays(now, -dayOfWeek(now));
    return [new State(false, empty(), weekStart, addDays(weekStart, 6), new Busy(0, []), empty()), getJobsCmd()];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [new State(state.IsSelectedAllChecked, map((j) => {
                if (equals(j.Job, msg.fields[1])) {
                    return {
                        IsChecked: msg.fields[0],
                        Job: msg.fields[1],
                    };
                }
                else {
                    return j;
                }
            }, state.Jobs), state.DateFrom, state.DateTo, state.IsBusy, state.Errors), Cmd_none()];
        case 2:
            return [new State(msg.fields[0], map((j_1) => ({
                IsChecked: msg.fields[0],
                Job: j_1.Job,
            }), state.Jobs), state.DateFrom, state.DateTo, state.IsBusy, state.Errors), Cmd_none()];
        case 3:
            return [new State(state.IsSelectedAllChecked, state.Jobs, msg.fields[0].startDate, msg.fields[0].endDate, state.IsBusy, state.Errors), Cmd_none()];
        case 4:
            return [new State(state.IsSelectedAllChecked, state.Jobs, state.DateFrom, state.DateTo, new Busy(1, []), empty()), getPdfReportCmd(state)];
        case 5:
            if (msg.fields[0].tag === 1) {
                return [new State(state.IsSelectedAllChecked, state.Jobs, state.DateFrom, state.DateTo, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                HttpUtil_saveByteArray(msg.fields[0].fields[0], "TimesheetsByJobsReport.pdf", "application/pdf");
                return [new State(state.IsSelectedAllChecked, state.Jobs, state.DateFrom, state.DateTo, new Busy(0, []), state.Errors), Cmd_none()];
            }
        case 6:
            return [new State(state.IsSelectedAllChecked, state.Jobs, state.DateFrom, state.DateTo, new Busy(0, []), singleton_1(msg.fields[0].message)), Cmd_none()];
        default:
            if (msg.fields[0].tag === 1) {
                return [new State(state.IsSelectedAllChecked, state.Jobs, state.DateFrom, state.DateTo, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.IsSelectedAllChecked, map((job) => ({
                    IsChecked: false,
                    Job: job,
                }), msg.fields[0].fields[0]), state.DateFrom, state.DateTo, new Busy(0, []), state.Errors), Cmd_none()];
            }
    }
}

export function IndexView() {
    let children, children_2, children_8, children_5, props_5, elems_6, elems_5, elems_3, elems_7;
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return container([(children = singleton_1(Daisy_h4("Timesheets by Jobs report")), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    })), Daisy_error(state_1.Errors), (children_2 = singleton_1(createElement(Daisy_progressState, {
        busy: state_1.IsBusy,
    })), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), (children_8 = ofArray([(children_5 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Timesheets date range",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_5)),
    })), (props_5 = {
        value: new DateRange(state_1.DateFrom, state_1.DateTo),
        onChange: (x) => {
            dispatch(new Msg(3, [new DateRange(parse(x.startDate), parse(x.endDate))]));
        },
        showShortcuts: true,
        configs: getDefaultConfig(now_1()),
    }, react.createElement(react_tailwindcss_datepicker, props_5))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_8)),
    })), createElement("div", createObj(Helpers_combineClasses("form-control", ofArray([["className", "w-full"], (elems_6 = [createElement("div", {
        children: "Manual job selection",
    }), createElement("div", createObj(ofArray([["className", "border border-gray-300 rounded-md p-2 h-60 overflow-y-auto"], (elems_5 = append(singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["mb-4", "ml-2", "mt-2", "flex", "items-center"])], (elems_3 = [createElement("input", createObj(Helpers_combineClasses("checkbox", ofArray([["type", "checkbox"], ["className", "checkbox-xs"], ["className", join(" ", ["mr-2"])], ["checked", state_1.IsSelectedAllChecked], ["onChange", (ev) => {
        dispatch(new Msg(2, [ev.target.checked]));
    }]])))), createElement("span", {
        className: "label-text",
        children: "Select All",
    })], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))), map((job) => {
        let elems_4;
        return createElement("div", createObj(ofArray([["className", join(" ", ["m-2", "flex", "items-center"])], (elems_4 = [createElement("input", createObj(Helpers_combineClasses("checkbox", ofArray([["type", "checkbox"], ["className", "checkbox-xs"], ["className", join(" ", ["mr-2"])], ["checked", job.IsChecked], ["onChange", (ev_1) => {
            let tupledArg;
            dispatch((tupledArg = [ev_1.target.checked, job.Job], new Msg(1, [tupledArg[0], tupledArg[1]])));
        }]])))), createElement("span", {
            className: "label-text",
            children: job.Job.Title,
        })], ["children", reactApi.Children.toArray(Array.from(elems_4))])])));
    }, state_1.Jobs)), ["children", reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", reactApi.Children.toArray(Array.from(elems_6))])])))), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "flex-wrap", "gap-x-2"])], (elems_7 = [createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-sm"], ["className", "btn-primary"], ["children", "Download Report"], ["onClick", (_arg_2) => {
        dispatch(new Msg(4, []));
    }]]))))], ["children", reactApi.Children.toArray(Array.from(elems_7))])])))]);
}

