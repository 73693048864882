import { Record } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, unit_type, option_type, lambda_type, union_type, list_type, string_type, class_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Pagination_$reflection } from "../Entity/Pagination.fs.js";
import { SkillDto_$reflection } from "../Dto/User.fs.js";
import { FSharpResult$2 } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Result.js";
import { printf, toText } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/String.js";

export class SkillService extends Record {
    constructor(searchSkills, getSkill, addSkill, updateSkill) {
        super();
        this.searchSkills = searchSkills;
        this.getSkill = getSkill;
        this.addSkill = addSkill;
        this.updateSkill = updateSkill;
    }
}

export function SkillService_$reflection() {
    return record_type("Logos.Shared.Domain.Remoting.SkillService", [], SkillService, () => [["searchSkills", lambda_type(class_type("System.Guid"), lambda_type(string_type, lambda_type(Pagination_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(SkillDto_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", list_type(SkillDto_$reflection())]], [["ErrorValue", list_type(string_type)]]])]))))], ["getSkill", lambda_type(class_type("System.Guid"), lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [option_type(SkillDto_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", option_type(SkillDto_$reflection())]], [["ErrorValue", list_type(string_type)]]])])))], ["addSkill", lambda_type(class_type("System.Guid"), lambda_type(SkillDto_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [class_type("System.Guid"), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", class_type("System.Guid")]], [["ErrorValue", list_type(string_type)]]])])))], ["updateSkill", lambda_type(class_type("System.Guid"), lambda_type(SkillDto_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])])))]]);
}

export function SkillService_RouteBuilder(s, m) {
    return toText(printf("/api/%s/%s"))(s)(m);
}

