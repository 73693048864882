import { toString, Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { class_type, union_type, unit_type, record_type, list_type, bool_type, string_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Product, ProductSubscription_$reflection } from "../../../../Logos.Shared/Domain/Entity/Product.fs.js";
import { BusyModule_isLoading, Busy, Busy_$reflection } from "../../SharedView.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { create } from "../../../../Logos.Shared/Domain/Entity/Email.fs.js";
import { inviteUser } from "../../../AppDomain/UseCase/User.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../Infrastructure/AppRoot.fs.js";
import { ofArray, singleton as singleton_1, empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { join, isNullOrEmpty } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { ButtonType, showModalEx } from "../../Component/ModalDialog.fs.js";
import { Daisy_error, Daisy_info, Daisy_h6, Daisy_DialogWidth } from "../../Component/Component.fs.js";
import { container } from "../../Component/ViewContainer.fs.js";
import { createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { empty as empty_1, singleton as singleton_2, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";

export class State extends Record {
    constructor(Username, InviteUserDialogVisible, InviteSentSuccessfully, ProductSubscriptions, IsBusy, Info, Errors) {
        super();
        this.Username = Username;
        this.InviteUserDialogVisible = InviteUserDialogVisible;
        this.InviteSentSuccessfully = InviteSentSuccessfully;
        this.ProductSubscriptions = ProductSubscriptions;
        this.IsBusy = IsBusy;
        this.Info = Info;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.UserInvitePage.State", [], State, () => [["Username", string_type], ["InviteUserDialogVisible", bool_type], ["InviteSentSuccessfully", bool_type], ["ProductSubscriptions", list_type(ProductSubscription_$reflection())], ["IsBusy", Busy_$reflection()], ["Info", list_type(string_type)], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetUsername", "ShowInviteUserDialog", "InviteUser", "InviteUserResponse", "GetProductSubscriptionResponse", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.UserInvitePage.Msg", [], Msg, () => [[["Item", string_type]], [["Item", bool_type]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(ProductSubscription_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", list_type(ProductSubscription_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Exception")]]]);
}

export function inviteUserCmd(state) {
    return cmdOfAsync((Item) => (new Msg(3, [Item])), (Item_1) => (new Msg(5, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_1;
        const value_1 = create(state.Username);
        asyncResult_1 = singleton.Return(value_1);
        return singleton.Bind(asyncResult_1, (input_1) => {
            const input_2 = input_1;
            if (input_2.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
            }
            else {
                return inviteUser(appRoot.UserService, AppRootModule_getSelectedOrgId(), input_2.fields[0], new Product(1, []));
            }
        });
    }));
}

export function init(username) {
    return [new State(username, false, false, empty(), new Busy(0, []), empty(), empty()), Cmd_none()];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [new State(state.Username, msg.fields[0], state.InviteSentSuccessfully, state.ProductSubscriptions, state.IsBusy, state.Info, state.Errors), Cmd_none()];
        case 2:
            return [new State(state.Username, state.InviteUserDialogVisible, state.InviteSentSuccessfully, state.ProductSubscriptions, new Busy(1, []), state.Info, state.Errors), inviteUserCmd(state)];
        case 3:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Username, state.InviteUserDialogVisible, state.InviteSentSuccessfully, state.ProductSubscriptions, new Busy(0, []), state.Info, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.Username, state.InviteUserDialogVisible, state.InviteSentSuccessfully, state.ProductSubscriptions, new Busy(0, []), singleton_1("Invitation sent successfully."), empty()), Cmd_none()];
            }
        case 4:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Username, state.InviteUserDialogVisible, state.InviteSentSuccessfully, empty(), state.IsBusy, state.Info, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.Username, state.InviteUserDialogVisible, state.InviteSentSuccessfully, msg.fields[0].fields[0], state.IsBusy, state.Info, state.Errors), Cmd_none()];
            }
        case 5:
            return [new State(state.Username, state.InviteUserDialogVisible, state.InviteSentSuccessfully, state.ProductSubscriptions, new Busy(0, []), state.Info, singleton_1(toString(msg.fields[0]))), Cmd_none()];
        default:
            return [new State(msg.fields[0], state.InviteUserDialogVisible, state.InviteSentSuccessfully, state.ProductSubscriptions, state.IsBusy, state.Info, state.Errors), Cmd_none()];
    }
}

export function IndexView(indexViewInputProps) {
    const username = indexViewInputProps.username;
    let patternInput;
    const init_1 = init(username);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [username]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const usernameIsEmpty = isNullOrEmpty(state_1.Username);
    const xs_8 = [createElement(showModalEx, {
        dialogId: "id-user-invite",
        header: "",
        buttonType: new ButtonType(0, ["Invite User"]),
        width: new Daisy_DialogWidth(1, []),
        heightPercent: "30",
        body: () => {
            let children_4, children_1;
            return container([(children_4 = ofArray([Daisy_h6(usernameIsEmpty ? "Invite a user to join your Organisation" : "Invite following user to join your Organisation"), Daisy_info(state_1.Info), Daisy_error(state_1.Errors), createElement("div", createObj(Helpers_combineClasses("divider", empty()))), (children_1 = singleton_1(createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", join(" ", ["w-full", "mb-4"])], ["className", "input-bordered"], ["className", "input-sm"], ["autoFocus", true], ["value", state_1.Username], ["placeholder", "Email of user to invite"], ["readOnly", BusyModule_isLoading(state_1.IsBusy) ? true : state_1.InviteSentSuccessfully], ["onChange", (ev) => {
                dispatch(new Msg(0, [ev.target.value]));
            }]]))))), createElement("div", {
                className: "form-control",
                children: reactApi.Children.toArray(Array.from(children_1)),
            })), createElement("button", createObj(Helpers_combineClasses("btn", toList(delay(() => append(BusyModule_isLoading(state_1.IsBusy) ? singleton_2(["className", "loading"]) : empty_1(), delay(() => append(singleton_2(["className", "btn-primary"]), delay(() => append(singleton_2(["className", "btn-xs"]), delay(() => append(singleton_2(["children", "Send"]), delay(() => singleton_2(["onClick", (_arg_4) => {
                dispatch(new Msg(2, []));
            }]))))))))))))))]), createElement("div", {
                children: reactApi.Children.toArray(Array.from(children_4)),
            }))]);
        },
        onShow: () => {
        },
        onClose: () => {
        },
    })];
    return react.createElement(react.Fragment, {}, ...xs_8);
}

