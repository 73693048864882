import { toString, Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { unit_type, union_type, tuple_type, record_type, string_type, list_type, option_type, class_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { SkillDto_$reflection } from "../../../../Logos.Shared/Domain/Dto/User.fs.js";
import { JobUserSkillDto_$reflection } from "../../../../Logos.Shared/Domain/Dto/Job.fs.js";
import { Busy, Busy_$reflection } from "../../SharedView.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { getAllSkills } from "../../../AppDomain/UseCase/Skill.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../Infrastructure/AppRoot.fs.js";
import { deleteJobUserSkill, getAllJobUserSkills } from "../../../AppDomain/UseCase/Job.fs.js";
import { tryFind, sortBy, map as map_1, ofArray, where, tryHead, empty, singleton as singleton_1 } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { unwrap, defaultArg } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { IndexView as IndexView_1 } from "./JobUserSkillEdit.fs.js";
import { Daisy_h6, Daisy_confirm, Daisy_DialogWidth, Daisy_progressState, Daisy_error } from "../../Component/Component.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { comparePrimitives, createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { map, append, empty as empty_1, singleton as singleton_2, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { ButtonType, showModalEx } from "../../Component/ModalDialog.fs.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { SkillRatingModule_toString } from "../../../../Logos.Shared/Domain/Entity/User.fs.js";
import { toString as toString_1 } from "../../../fable_modules/fable-library-js.4.19.3/Decimal.js";

export class State extends Record {
    constructor(JobId, Skills, JobUserSkillDto, IsBusy, Errors) {
        super();
        this.JobId = JobId;
        this.Skills = Skills;
        this.JobUserSkillDto = JobUserSkillDto;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.JobUserSkillList.State", [], State, () => [["JobId", option_type(class_type("System.Guid"))], ["Skills", list_type(SkillDto_$reflection())], ["JobUserSkillDto", list_type(JobUserSkillDto_$reflection())], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Edit", "GetJobUserSkillsResponse", "RefreshJobUserSkills", "DeleteUserSkill", "DeleteUserSkillResponse", "NoAction", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.JobUserSkillList.Msg", [], Msg, () => [[["Item", option_type(JobUserSkillDto_$reflection())]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(list_type(SkillDto_$reflection()), list_type(JobUserSkillDto_$reflection())), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", tuple_type(list_type(SkillDto_$reflection()), list_type(JobUserSkillDto_$reflection()))]], [["ErrorValue", list_type(string_type)]]])]], [], [["Item", class_type("System.Guid")]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])]], [], [["Item", class_type("System.Exception")]]]);
}

export function getJobUserSkillsCmd(jobId) {
    return cmdOfAsync((Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(6, [Item_1])), () => singleton.Delay(() => {
        const asyncResult = getAllSkills(appRoot.SkillService, AppRootModule_getSelectedOrgId());
        return singleton.Bind(asyncResult, (input_1_1) => {
            const input_6 = input_1_1;
            if (input_6.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_6.fields[0]]));
            }
            else {
                const x = getAllJobUserSkills(appRoot.JobService, AppRootModule_getSelectedOrgId(), jobId);
                return singleton.Bind(x, (x$0027) => {
                    let value;
                    const input_2 = x$0027;
                    value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [[input_6.fields[0], input_2.fields[0]]])));
                    return singleton.Return(value);
                });
            }
        });
    }));
}

export function deleteUserSkillCmd(jobId, jobUserSkillId) {
    return cmdOfAsync((Item) => (new Msg(4, [Item])), (Item_1) => (new Msg(6, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_1;
        const value_1_1 = (jobId != null) ? (new FSharpResult$2(0, [undefined])) : (new FSharpResult$2(1, [singleton_1("JobId not found.")]));
        asyncResult_1 = singleton.Return(value_1_1);
        return singleton.Bind(asyncResult_1, (input_1) => {
            const input_2 = input_1;
            if (input_2.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
            }
            else {
                const jobId_1 = defaultArg(jobId, "00000000-0000-0000-0000-000000000000");
                return deleteJobUserSkill(appRoot.JobService, AppRootModule_getSelectedOrgId(), jobId_1, jobUserSkillId);
            }
        });
    }));
}

export function init(jobId) {
    return [new State(jobId, empty(), empty(), (jobId != null) ? (new Busy(1, [])) : (new Busy(0, [])), empty()), (jobId == null) ? Cmd_none() : getJobUserSkillsCmd(jobId)];
}

export function update(msg, state) {
    let matchValue;
    switch (msg.tag) {
        case 1:
            if (msg.fields[0].tag === 1) {
                return [new State(state.JobId, state.Skills, state.JobUserSkillDto, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.JobId, msg.fields[0].fields[0][0], msg.fields[0].fields[0][1], new Busy(0, []), state.Errors), Cmd_none()];
            }
        case 2:
            return [state, (matchValue = state.JobId, (matchValue == null) ? Cmd_none() : getJobUserSkillsCmd(matchValue))];
        case 3:
            return [new State(state.JobId, state.Skills, state.JobUserSkillDto, new Busy(2, []), state.Errors), deleteUserSkillCmd(state.JobId, msg.fields[0])];
        case 4:
            if (msg.fields[0].tag === 1) {
                return [new State(state.JobId, state.Skills, state.JobUserSkillDto, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [state, getJobUserSkillsCmd(defaultArg(state.JobId, "00000000-0000-0000-0000-000000000000"))];
            }
        case 5:
            return [state, Cmd_none()];
        case 6:
            return [new State(state.JobId, state.Skills, state.JobUserSkillDto, state.IsBusy, singleton_1(toString(msg.fields[0]))), Cmd_none()];
        default:
            if (msg.fields[0] == null) {
                return [state, Cmd_none()];
            }
            else {
                const x = msg.fields[0];
                return [state, Cmd_none()];
            }
    }
}

export function IndexView(props) {
    let input_1, children, elems_1, elems_3, children_6, children_4, children_12;
    let patternInput;
    const init_1 = init(props.JobId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.JobId]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const isNewJob = props.JobId == null;
    const useUserSkillRatingForJob = !defaultArg((input_1 = props.OrgOptions, (input_1 == null) ? undefined : input_1.IgnoreUserSkillRatingForJob), false);
    const body = (jobUserSkillId) => {
        const matchValue = props.JobId;
        if (matchValue == null) {
            return react.createElement(react.Fragment, {});
        }
        else {
            return createElement(IndexView_1, {
                jobId: matchValue,
                jobUserSkillId: unwrap(jobUserSkillId),
                onSaved: () => {
                    dispatch(new Msg(2, []));
                },
                useUserSkillRatingForJob: useUserSkillRatingForJob,
            });
        }
    };
    const children_15 = ofArray([Daisy_error(state_1.Errors), (children = singleton_1(createElement(Daisy_progressState, {
        busy: state_1.IsBusy,
    })), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children)),
    })), createElement("div", createObj(Helpers_combineClasses("navbar", singleton_1((elems_1 = toList(delay(() => (!isNewJob ? singleton_2(createElement(showModalEx, {
        dialogId: "id-job-user-skill-add",
        header: "",
        buttonType: new ButtonType(0, ["Add User Skill"]),
        width: new Daisy_DialogWidth(3, []),
        body: () => body(undefined),
        onShow: () => {
        },
        onClose: () => {
        },
    })) : empty_1()))), ["children", reactApi.Children.toArray(Array.from(elems_1))]))))), createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["flex", "w-full", "ml-2", "mr-2"])], (elems_3 = [(children_6 = singleton_1((children_4 = toList(delay(() => append(singleton_2(createElement("th", {
        width: 20 + "%",
        children: "Skill",
    })), delay(() => append(singleton_2(createElement("th", {
        width: 10 + "%",
        children: "Count",
    })), delay(() => append(useUserSkillRatingForJob ? singleton_2(createElement("th", {
        width: 20 + "%",
        children: "Rating",
    })) : empty_1(), delay(() => append(singleton_2(createElement("th", {
        width: 20 + "%",
        children: "Estimated Price",
    })), delay(() => append(singleton_2(createElement("th", {
        width: 20 + "%",
        children: "Agreed Price",
    })), delay(() => append(singleton_2(createElement("th", {
        width: 5 + "%",
    })), delay(() => singleton_2(createElement("th", {
        width: 5 + "%",
    })))))))))))))))), createElement("tr", {
        children: reactApi.Children.toArray(Array.from(children_4)),
    }))), createElement("thead", {
        children: reactApi.Children.toArray(Array.from(children_6)),
    })), (children_12 = toList(delay(() => map((userSkill) => {
        let elems_2;
        return createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top"])], (elems_2 = toList(delay(() => {
            let input_5;
            return append(singleton_2(createElement("td", {
                children: [defaultArg((input_5 = tryHead(where((x_3) => (x_3.Id === userSkill.SkillId), state_1.Skills)), (input_5 == null) ? undefined : input_5.Name), "Skill not found")],
            })), delay(() => append(singleton_2(createElement("td", {
                children: [userSkill.UserCount],
            })), delay(() => {
                let input_7;
                return append(useUserSkillRatingForJob ? singleton_2(createElement("td", {
                    children: [defaultArg((input_7 = userSkill.Rating, (input_7 == null) ? undefined : SkillRatingModule_toString(input_7)), "")],
                })) : empty_1(), delay(() => append(singleton_2(createElement("td", {
                    children: [toString_1(userSkill.EstimatedPrice)],
                })), delay(() => append(singleton_2(createElement("td", {
                    children: [toString_1(userSkill.AgreedPrice)],
                })), delay(() => {
                    let children_8;
                    return append(singleton_2((children_8 = singleton_1(createElement(showModalEx, {
                        dialogId: userSkill.Id,
                        header: "",
                        buttonType: new ButtonType(1, ["fa-edit"]),
                        width: new Daisy_DialogWidth(3, []),
                        body: () => body(userSkill.Id),
                        onShow: () => {
                        },
                        onClose: () => {
                        },
                    })), createElement("td", {
                        children: reactApi.Children.toArray(Array.from(children_8)),
                    }))), delay(() => {
                        let children_10;
                        return singleton_2((children_10 = singleton_1(createElement(Daisy_confirm, {
                            buttonColor: ["className", "btn-error"],
                            width: new Daisy_DialogWidth(1, []),
                            buttonTitle: "Delete",
                            body: Daisy_h6("Would you like to delete user skill?"),
                            isEnabled: true,
                            yesAction: new Msg(3, [userSkill.Id]),
                            noAction: new Msg(5, []),
                            dispatch: dispatch,
                        })), createElement("td", {
                            children: reactApi.Children.toArray(Array.from(children_10)),
                        })));
                    }));
                }))))));
            }))));
        })), ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
    }, map_1((tuple) => tuple[0], sortBy((tupledArg) => tupledArg[1], map_1((x_1) => {
        let input_3;
        return [x_1, defaultArg((input_3 = tryFind((y) => (y.Id === x_1.SkillId), state_1.Skills), (input_3 == null) ? undefined : input_3.Name), "")];
    }, state_1.JobUserSkillDto), {
        Compare: comparePrimitives,
    }))))), createElement("tbody", {
        children: reactApi.Children.toArray(Array.from(children_12)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))))]);
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_15)),
    });
}

