import { Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { XeroCustomer_$reflection } from "../../../../Logos.Shared/Domain/Entity/Customer.fs.js";
import { class_type, option_type, unit_type, union_type, record_type, string_type, list_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { BusyModule_isSaving, Busy, Busy_$reflection } from "../../SharedView.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { Organisation_$reflection } from "../../../../Logos.Shared/Domain/Entity/Organisation.fs.js";
import { cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { saveXeroSettings, getXeroCustomers } from "../../../AppDomain/UseCase/Xero.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../Infrastructure/AppRoot.fs.js";
import { OrganisationDtoModule_toOrganisation } from "../../../../Logos.Shared/Domain/Dto/Organisation.fs.js";
import { ofArray, singleton as singleton_1, empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../../Component/ViewContainer.fs.js";
import { Daisy_progressState, Daisy_error, Daisy_h4 } from "../../Component/Component.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { empty as empty_1, singleton as singleton_2, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";

export class State extends Record {
    constructor(XeroCustomers, InvoiceItemAccountCode, InvoiceItemTaxType, IsBusy, Errors) {
        super();
        this.XeroCustomers = XeroCustomers;
        this.InvoiceItemAccountCode = InvoiceItemAccountCode;
        this.InvoiceItemTaxType = InvoiceItemTaxType;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.Xero.SelectDataToSyncFromXero.State", [], State, () => [["XeroCustomers", list_type(XeroCustomer_$reflection())], ["InvoiceItemAccountCode", string_type], ["InvoiceItemTaxType", string_type], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetXeroCustomers", "GetXeroCustomersResponse", "SetInvoiceItemAccountCode", "SetInvoiceItemTaxType", "SaveXeroSettings", "SaveXeroSettingsResponse", "GetOrganisationResponse", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.Xero.SelectDataToSyncFromXero.Msg", [], Msg, () => [[], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(XeroCustomer_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", list_type(XeroCustomer_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [["Item", string_type]], [["Item", string_type]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [option_type(Organisation_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", option_type(Organisation_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Exception")]]]);
}

export function getXeroCustomersCmd() {
    return cmdOfAsync((Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(7, [Item_1])), () => singleton.Delay(() => getXeroCustomers(appRoot.XeroService, AppRootModule_getSelectedOrgId())));
}

export function getOrganisationCmd() {
    return cmdOfAsync((Item) => (new Msg(6, [Item])), (Item_1) => (new Msg(7, [Item_1])), () => singleton.Delay(() => {
        const input_2 = appRoot.OrganisationService.GetOrganisation(AppRootModule_getSelectedOrgId());
        return singleton.Bind(input_2, (x$0027) => {
            let input_1;
            let value;
            const input_4 = x$0027;
            value = ((input_4.tag === 1) ? (new FSharpResult$2(1, [input_4.fields[0]])) : (new FSharpResult$2(0, [(input_1 = input_4.fields[0], (input_1 == null) ? undefined : OrganisationDtoModule_toOrganisation(input_1))])));
            return singleton.Return(value);
        });
    }));
}

export function saveXeroSettingsCmd(state) {
    return cmdOfAsync((Item) => (new Msg(5, [Item])), (Item_1) => (new Msg(7, [Item_1])), () => singleton.Delay(() => saveXeroSettings(appRoot.XeroService, AppRootModule_getSelectedOrgId(), state.InvoiceItemAccountCode, state.InvoiceItemTaxType)));
}

export function init() {
    return [new State(empty(), "", "", new Busy(0, []), empty()), getOrganisationCmd()];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            if (msg.fields[0].tag === 1) {
                return [new State(state.XeroCustomers, state.InvoiceItemAccountCode, state.InvoiceItemTaxType, state.IsBusy, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(msg.fields[0].fields[0], state.InvoiceItemAccountCode, state.InvoiceItemTaxType, state.IsBusy, state.Errors), Cmd_none()];
            }
        case 2:
            return [new State(state.XeroCustomers, msg.fields[0], state.InvoiceItemTaxType, state.IsBusy, state.Errors), Cmd_none()];
        case 3:
            return [new State(state.XeroCustomers, state.InvoiceItemAccountCode, msg.fields[0], state.IsBusy, state.Errors), Cmd_none()];
        case 4:
            return [new State(state.XeroCustomers, state.InvoiceItemAccountCode, state.InvoiceItemTaxType, new Busy(2, []), state.Errors), saveXeroSettingsCmd(state)];
        case 5:
            if (msg.fields[0].tag === 1) {
                return [new State(state.XeroCustomers, state.InvoiceItemAccountCode, state.InvoiceItemTaxType, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.XeroCustomers, state.InvoiceItemAccountCode, state.InvoiceItemTaxType, new Busy(0, []), state.Errors), Cmd_none()];
            }
        case 6:
            if (msg.fields[0].tag === 1) {
                return [new State(state.XeroCustomers, state.InvoiceItemAccountCode, state.InvoiceItemTaxType, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else if (msg.fields[0].fields[0] == null) {
                return [new State(state.XeroCustomers, state.InvoiceItemAccountCode, state.InvoiceItemTaxType, new Busy(0, []), singleton_1("Organisation not found")), Cmd_none()];
            }
            else {
                const organisation = msg.fields[0].fields[0];
                return [new State(state.XeroCustomers, organisation.OrganisationOptions.XeroInvoiceItemAccountCode, organisation.OrganisationOptions.XeroInvoiceItemTaxType, new Busy(0, []), state.Errors), Cmd_none()];
            }
        case 7:
            return [new State(state.XeroCustomers, state.InvoiceItemAccountCode, state.InvoiceItemTaxType, state.IsBusy, singleton_1(msg.fields[0].message)), Cmd_none()];
        default:
            return [state, getXeroCustomersCmd()];
    }
}

export function IndexView() {
    let children_10, children, children_5, children_8;
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return container([(children_10 = ofArray([Daisy_h4("Finished linking to Xero. Please setup following to allow creating invoices in Xero."), Daisy_error(state_1.Errors), (children = singleton_1(createElement(Daisy_progressState, {
        busy: state_1.IsBusy,
    })), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children)),
    })), createElement("button", createObj(Helpers_combineClasses("btn", toList(delay(() => append(BusyModule_isSaving(state_1.IsBusy) ? singleton_2(["className", "loading"]) : empty_1(), delay(() => append(singleton_2(["className", "btn-primary"]), delay(() => append(singleton_2(["className", "btn-sm"]), delay(() => append(singleton_2(["className", join(" ", ["mr-4"])]), delay(() => append(singleton_2(["children", "Save"]), delay(() => singleton_2(["onClick", (_arg_3) => {
        dispatch(new Msg(4, []));
    }])))))))))))))))), (children_5 = ofArray([createElement("label", createObj(Helpers_combineClasses("label", singleton_1(["children", "Xero invoice item account code"])))), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", state_1.InvoiceItemAccountCode], ["placeholder", "Xero invoice item account code"], ["onChange", (ev) => {
        dispatch(new Msg(2, [ev.target.value]));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_5)),
    })), (children_8 = ofArray([createElement("label", createObj(Helpers_combineClasses("label", singleton_1(["children", "Xero invoice item tax type"])))), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", state_1.InvoiceItemTaxType], ["placeholder", "Xero invoice item tax type"], ["onChange", (ev_1) => {
        dispatch(new Msg(3, [ev_1.target.value]));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_8)),
    }))]), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_10)),
    }))]);
}

