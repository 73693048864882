import { Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { ExpenseStatusModule_toString, ExpenseStatus, Expense as Expense_2, Expense_$reflection } from "../../../../Logos.Shared/Domain/Entity/Expense.fs.js";
import { unit_type, union_type, tuple_type, record_type, bool_type, array_type, uint8_type, list_type, anonRecord_type, class_type, string_type, option_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { BusyModule_isSaving, Busy, Busy_$reflection } from "../../SharedView.fs.js";
import { JobDetail_$reflection } from "../../../../Logos.Shared/Domain/Entity/Job.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { rejectExpense, approveExpense, updateExpense, getExpenseAttachment, getExpense } from "../../../AppDomain/UseCase/Expense.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../Infrastructure/AppRoot.fs.js";
import { ofArray, cons, map, empty, singleton as singleton_1 } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { System_Decimal__Decimal_ToDisplayCurrency, Option_toString, Option_ofString } from "../../../../Logos.Shared/Util.fs.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { defaultArg } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { fromParts } from "../../../fable_modules/fable-library-js.4.19.3/Decimal.js";
import { DateTime_dateTimeToStringWithDayName } from "../../../AppDomain/Util.fs.js";
import { toLocalTime } from "../../../fable_modules/fable-library-js.4.19.3/DateOffset.js";
import { createObj, equals } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { container } from "../../Component/ViewContainer.fs.js";
import { map as map_1, empty as empty_1, singleton as singleton_2, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { Daisy_h6, Daisy_progressState, Daisy_error, confirmSimpleWithBody, Daisy_DialogWidth, confirmSimple, Daisy_h4 } from "../../Component/Component.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { parse } from "../../../fable_modules/fable-library-js.4.19.3/Guid.js";
import { toBase64String, join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";

export class State extends Record {
    constructor(Expense, Notes, JobId, Jobs, Attachment, IsGettingAttachment, IsConfirmApproval, IsApprovingIP, IsConfirmRejection, RejectionReason, IsRejectingIP, IsBusy, Errors) {
        super();
        this.Expense = Expense;
        this.Notes = Notes;
        this.JobId = JobId;
        this.Jobs = Jobs;
        this.Attachment = Attachment;
        this.IsGettingAttachment = IsGettingAttachment;
        this.IsConfirmApproval = IsConfirmApproval;
        this.IsApprovingIP = IsApprovingIP;
        this.IsConfirmRejection = IsConfirmRejection;
        this.RejectionReason = RejectionReason;
        this.IsRejectingIP = IsRejectingIP;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.ExpenseEdit.State", [], State, () => [["Expense", option_type(Expense_$reflection())], ["Notes", string_type], ["JobId", class_type("System.Guid")], ["Jobs", list_type(anonRecord_type(["Id", class_type("System.Guid")], ["Name", string_type]))], ["Attachment", option_type(array_type(uint8_type))], ["IsGettingAttachment", bool_type], ["IsConfirmApproval", bool_type], ["IsApprovingIP", bool_type], ["IsConfirmRejection", bool_type], ["RejectionReason", string_type], ["IsRejectingIP", bool_type], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetExpenseResponse", "GetExpenseAttachmentResponse", "SetNotes", "SetJob", "SaveExpense", "SaveExpenseResponse", "ConfirmApproval", "ConfirmApprovalResponse", "ApproveResponse", "ConfirmRejection", "ConfirmRejectionResponse", "SetRejectionReason", "RejectResponse", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.ExpenseEdit.Msg", [], Msg, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(list_type(JobDetail_$reflection()), option_type(Expense_$reflection())), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", tuple_type(list_type(JobDetail_$reflection()), option_type(Expense_$reflection()))]], [["ErrorValue", list_type(string_type)]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(uint8_type), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", array_type(uint8_type)]], [["ErrorValue", list_type(string_type)]]])]], [["Item", string_type]], [["Item", class_type("System.Guid")]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])]], [], [["Item", bool_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])]], [], [["Item", bool_type]], [["Item", string_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Exception")]]]);
}

export function getExpenseCmd(expenseId) {
    return cmdOfAsync((Item) => (new Msg(0, [Item])), (Item_1) => (new Msg(13, [Item_1])), () => singleton.Delay(() => getExpense(appRoot.ExpenseService, appRoot.JobService, AppRootModule_getSelectedOrgId(), expenseId)));
}

export function getExpenseAttachmentCmd(expenseId) {
    return cmdOfAsync((Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(13, [Item_1])), () => singleton.Delay(() => getExpenseAttachment(appRoot.ExpenseService, AppRootModule_getSelectedOrgId(), expenseId)));
}

export function saveExpenseCmd(state) {
    return cmdOfAsync((Item) => (new Msg(5, [Item])), (Item_1) => (new Msg(13, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_1;
        let value_1;
        const option_1 = state.Expense;
        value_1 = ((option_1 == null) ? (new FSharpResult$2(1, [singleton_1("Expense not found.")])) : (new FSharpResult$2(0, [option_1])));
        asyncResult_1 = singleton.Return(value_1);
        return singleton.Bind(asyncResult_1, (input_1) => {
            const input_2 = input_1;
            if (input_2.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
            }
            else {
                const updatedExpense = input_2.fields[0];
                const updatedExpense_1 = new Expense_2(updatedExpense.Id, updatedExpense.Date, updatedExpense.OrgId, updatedExpense.UserId, (state.JobId === "00000000-0000-0000-0000-000000000000") ? undefined : state.JobId, updatedExpense.Description, updatedExpense.Amount, updatedExpense.IsReceiptAttached, updatedExpense.Status, Option_ofString(state.Notes), updatedExpense.CreatedAt, updatedExpense.UpdatedAt, updatedExpense.ApprovedAt, updatedExpense.ApprovedBy, updatedExpense.RejectedAt, updatedExpense.RejectedBy, updatedExpense.RejectionReason);
                return updateExpense(appRoot.ExpenseService, AppRootModule_getSelectedOrgId(), updatedExpense_1);
            }
        });
    }));
}

export function approveExpenseCmd(state) {
    return cmdOfAsync((Item) => (new Msg(8, [Item])), (Item_1) => (new Msg(13, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_1;
        let value_1;
        const option_1 = state.Expense;
        value_1 = ((option_1 == null) ? (new FSharpResult$2(1, [singleton_1("Expense not found.")])) : (new FSharpResult$2(0, [option_1])));
        asyncResult_1 = singleton.Return(value_1);
        return singleton.Bind(asyncResult_1, (input_1) => {
            const input_2 = input_1;
            if (input_2.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
            }
            else {
                const updatedExpense = input_2.fields[0];
                const updatedExpense_1 = new Expense_2(updatedExpense.Id, updatedExpense.Date, updatedExpense.OrgId, updatedExpense.UserId, updatedExpense.JobId, updatedExpense.Description, updatedExpense.Amount, updatedExpense.IsReceiptAttached, updatedExpense.Status, Option_ofString(state.Notes), updatedExpense.CreatedAt, updatedExpense.UpdatedAt, updatedExpense.ApprovedAt, updatedExpense.ApprovedBy, updatedExpense.RejectedAt, updatedExpense.RejectedBy, updatedExpense.RejectionReason);
                return approveExpense(appRoot.ExpenseService, AppRootModule_getSelectedOrgId(), updatedExpense_1);
            }
        });
    }));
}

export function rejectExpenseCmd(state) {
    return cmdOfAsync((Item) => (new Msg(12, [Item])), (Item_1) => (new Msg(13, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_1;
        let value_1;
        const option_1 = state.Expense;
        value_1 = ((option_1 == null) ? (new FSharpResult$2(1, [singleton_1("Expense not found.")])) : (new FSharpResult$2(0, [option_1])));
        asyncResult_1 = singleton.Return(value_1);
        return singleton.Bind(asyncResult_1, (input_1) => {
            const input_2 = input_1;
            if (input_2.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
            }
            else {
                const updatedExpense = input_2.fields[0];
                const updatedExpense_1 = new Expense_2(updatedExpense.Id, updatedExpense.Date, updatedExpense.OrgId, updatedExpense.UserId, updatedExpense.JobId, updatedExpense.Description, updatedExpense.Amount, updatedExpense.IsReceiptAttached, updatedExpense.Status, Option_ofString(state.Notes), updatedExpense.CreatedAt, updatedExpense.UpdatedAt, updatedExpense.ApprovedAt, updatedExpense.ApprovedBy, updatedExpense.RejectedAt, updatedExpense.RejectedBy, Option_ofString(state.RejectionReason));
                return rejectExpense(appRoot.ExpenseService, AppRootModule_getSelectedOrgId(), updatedExpense_1);
            }
        });
    }));
}

export function init(expenseId) {
    return [new State(undefined, "", "00000000-0000-0000-0000-000000000000", singleton_1({
        Id: "00000000-0000-0000-0000-000000000000",
        Name: "Not Selected",
    }), undefined, true, false, false, false, "", false, new Busy(1, []), empty()), getExpenseCmd(expenseId)];
}

export function update(msg, state) {
    let input_5, x_1, input_7, x_3, JobId, input_1, Jobs, input_3;
    switch (msg.tag) {
        case 1:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Expense, state.Notes, state.JobId, state.Jobs, state.Attachment, false, state.IsConfirmApproval, state.IsApprovingIP, state.IsConfirmRejection, state.RejectionReason, state.IsRejectingIP, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.Expense, state.Notes, state.JobId, state.Jobs, msg.fields[0].fields[0], false, state.IsConfirmApproval, state.IsApprovingIP, state.IsConfirmRejection, state.RejectionReason, state.IsRejectingIP, new Busy(0, []), state.Errors), Cmd_none()];
            }
        case 2:
            return [new State(state.Expense, msg.fields[0], state.JobId, state.Jobs, state.Attachment, state.IsGettingAttachment, state.IsConfirmApproval, state.IsApprovingIP, state.IsConfirmRejection, state.RejectionReason, state.IsRejectingIP, state.IsBusy, state.Errors), Cmd_none()];
        case 3:
            return [new State(state.Expense, state.Notes, msg.fields[0], state.Jobs, state.Attachment, state.IsGettingAttachment, state.IsConfirmApproval, state.IsApprovingIP, state.IsConfirmRejection, state.RejectionReason, state.IsRejectingIP, state.IsBusy, state.Errors), Cmd_none()];
        case 4: {
            const updatedState = new State(state.Expense, state.Notes, state.JobId, state.Jobs, state.Attachment, state.IsGettingAttachment, state.IsConfirmApproval, state.IsApprovingIP, state.IsConfirmRejection, state.RejectionReason, state.IsRejectingIP, new Busy(2, []), state.Errors);
            return [updatedState, saveExpenseCmd(updatedState)];
        }
        case 5:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Expense, state.Notes, state.JobId, state.Jobs, state.Attachment, state.IsGettingAttachment, state.IsConfirmApproval, state.IsApprovingIP, state.IsConfirmRejection, state.RejectionReason, state.IsRejectingIP, state.IsBusy, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.Expense, state.Notes, state.JobId, state.Jobs, state.Attachment, state.IsGettingAttachment, state.IsConfirmApproval, state.IsApprovingIP, state.IsConfirmRejection, state.RejectionReason, state.IsRejectingIP, new Busy(0, []), state.Errors), Cmd_none()];
            }
        case 6:
            return [new State(state.Expense, state.Notes, state.JobId, state.Jobs, state.Attachment, state.IsGettingAttachment, true, state.IsApprovingIP, state.IsConfirmRejection, state.RejectionReason, state.IsRejectingIP, state.IsBusy, state.Errors), Cmd_none()];
        case 7: {
            const updatedState_1 = new State(state.Expense, state.Notes, state.JobId, state.Jobs, state.Attachment, state.IsGettingAttachment, false, msg.fields[0], state.IsConfirmRejection, state.RejectionReason, state.IsRejectingIP, state.IsBusy, state.Errors);
            return [updatedState_1, msg.fields[0] ? approveExpenseCmd(updatedState_1) : Cmd_none()];
        }
        case 8:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Expense, state.Notes, state.JobId, state.Jobs, state.Attachment, state.IsGettingAttachment, state.IsConfirmApproval, false, state.IsConfirmRejection, state.RejectionReason, state.IsRejectingIP, state.IsBusy, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State((input_5 = state.Expense, (input_5 == null) ? undefined : ((x_1 = input_5, new Expense_2(x_1.Id, x_1.Date, x_1.OrgId, x_1.UserId, x_1.JobId, x_1.Description, x_1.Amount, x_1.IsReceiptAttached, new ExpenseStatus(2, []), x_1.Notes, x_1.CreatedAt, x_1.UpdatedAt, x_1.ApprovedAt, x_1.ApprovedBy, x_1.RejectedAt, x_1.RejectedBy, x_1.RejectionReason)))), state.Notes, state.JobId, state.Jobs, state.Attachment, state.IsGettingAttachment, state.IsConfirmApproval, false, state.IsConfirmRejection, state.RejectionReason, state.IsRejectingIP, state.IsBusy, empty()), Cmd_none()];
            }
        case 9:
            return [new State(state.Expense, state.Notes, state.JobId, state.Jobs, state.Attachment, state.IsGettingAttachment, state.IsConfirmApproval, state.IsApprovingIP, true, state.RejectionReason, state.IsRejectingIP, state.IsBusy, state.Errors), Cmd_none()];
        case 10: {
            const updatedState_2 = new State(state.Expense, state.Notes, state.JobId, state.Jobs, state.Attachment, state.IsGettingAttachment, state.IsConfirmApproval, state.IsApprovingIP, false, state.RejectionReason, state.IsRejectingIP, state.IsBusy, state.Errors);
            return [updatedState_2, msg.fields[0] ? rejectExpenseCmd(updatedState_2) : Cmd_none()];
        }
        case 11:
            return [new State(state.Expense, state.Notes, state.JobId, state.Jobs, state.Attachment, state.IsGettingAttachment, state.IsConfirmApproval, state.IsApprovingIP, state.IsConfirmRejection, msg.fields[0], state.IsRejectingIP, state.IsBusy, state.Errors), Cmd_none()];
        case 12:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Expense, state.Notes, state.JobId, state.Jobs, state.Attachment, state.IsGettingAttachment, state.IsConfirmApproval, state.IsApprovingIP, state.IsConfirmRejection, state.RejectionReason, state.IsRejectingIP, state.IsBusy, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State((input_7 = state.Expense, (input_7 == null) ? undefined : ((x_3 = input_7, new Expense_2(x_3.Id, x_3.Date, x_3.OrgId, x_3.UserId, x_3.JobId, x_3.Description, x_3.Amount, x_3.IsReceiptAttached, new ExpenseStatus(3, []), x_3.Notes, x_3.CreatedAt, x_3.UpdatedAt, x_3.ApprovedAt, x_3.ApprovedBy, x_3.RejectedAt, x_3.RejectedBy, x_3.RejectionReason)))), state.Notes, state.JobId, state.Jobs, state.Attachment, state.IsGettingAttachment, state.IsConfirmApproval, state.IsApprovingIP, state.IsConfirmRejection, state.RejectionReason, state.IsRejectingIP, state.IsBusy, empty()), Cmd_none()];
            }
        case 13:
            return [new State(state.Expense, state.Notes, state.JobId, state.Jobs, state.Attachment, state.IsGettingAttachment, state.IsConfirmApproval, state.IsApprovingIP, state.IsConfirmRejection, state.RejectionReason, state.IsRejectingIP, state.IsBusy, singleton_1(msg.fields[0].message)), Cmd_none()];
        default:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Expense, state.Notes, state.JobId, state.Jobs, state.Attachment, state.IsGettingAttachment, state.IsConfirmApproval, state.IsApprovingIP, state.IsConfirmRejection, state.RejectionReason, state.IsRejectingIP, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                let patternInput;
                if (msg.fields[0].fields[0][1] == null) {
                    patternInput = [false, "00000000-0000-0000-0000-000000000000"];
                }
                else {
                    const e = msg.fields[0].fields[0][1];
                    patternInput = [e.IsReceiptAttached, e.Id];
                }
                const expenseHasAttachment = patternInput[0];
                return [(JobId = defaultArg((input_1 = msg.fields[0].fields[0][1], (input_1 == null) ? undefined : defaultArg(input_1.JobId, "00000000-0000-0000-0000-000000000000")), "00000000-0000-0000-0000-000000000000"), (Jobs = cons({
                    Id: "00000000-0000-0000-0000-000000000000",
                    Name: "Not Selected",
                }, map((j) => ({
                    Id: j.Id,
                    Name: j.Title,
                }), msg.fields[0].fields[0][0])), new State(msg.fields[0].fields[0][1], defaultArg((input_3 = msg.fields[0].fields[0][1], (input_3 == null) ? undefined : Option_toString(input_3.Notes)), ""), JobId, Jobs, state.Attachment, expenseHasAttachment, state.IsConfirmApproval, state.IsApprovingIP, state.IsConfirmRejection, state.RejectionReason, state.IsRejectingIP, new Busy(0, []), state.Errors))), expenseHasAttachment ? getExpenseAttachmentCmd(patternInput[1]) : Cmd_none()];
            }
    }
}

export function IndexView(indexViewInputProps) {
    let input_1, input_3, input_5, input_7, input_9, e_4;
    const expenseId = indexViewInputProps.expenseId;
    let patternInput;
    const init_1 = init(expenseId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const description = defaultArg((input_1 = state_1.Expense, (input_1 == null) ? undefined : input_1.Description), "");
    const amount = System_Decimal__Decimal_ToDisplayCurrency(defaultArg((input_3 = state_1.Expense, (input_3 == null) ? undefined : input_3.Amount), fromParts(0, 0, 0, false, 1)));
    const expenseStatus = ExpenseStatusModule_toString(defaultArg((input_5 = state_1.Expense, (input_5 == null) ? undefined : input_5.Status), new ExpenseStatus(0, [])));
    const created = defaultArg((input_7 = state_1.Expense, (input_7 == null) ? undefined : DateTime_dateTimeToStringWithDayName(toLocalTime(input_7.CreatedAt))), "");
    const expenseIsReadOnly = defaultArg((input_9 = state_1.Expense, (input_9 == null) ? undefined : ((e_4 = input_9, (equals(e_4.Status, new ExpenseStatus(2, [])) ? true : equals(e_4.Status, new ExpenseStatus(3, []))) ? true : equals(e_4.Status, new ExpenseStatus(4, []))))), false);
    return container(toList(delay(() => {
        let elems;
        return append(singleton_2(createElement("div", createObj(ofArray([["className", "flex justify-between"], (elems = [Daisy_h4(`Expense - ${description}`)], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => append(singleton_2(createElement("div", createObj(Helpers_combineClasses("divider", empty())))), delay(() => append(state_1.IsConfirmApproval ? singleton_2(confirmSimple(new Daisy_DialogWidth(1, []), "Are you sure you want to approve this expense?", (_arg_4) => {
            dispatch(new Msg(7, [false]));
        }, (_arg_5) => {
            dispatch(new Msg(7, [true]));
        })) : singleton_2(react.createElement(react.Fragment, {})), delay(() => {
            let elems_1;
            return append(state_1.IsConfirmRejection ? singleton_2(confirmSimpleWithBody(new Daisy_DialogWidth(1, []), createElement("div", createObj(ofArray([["className", "flex flex-col"], (elems_1 = [createElement("label", createObj(Helpers_combineClasses("label", singleton_1(["children", "Are you sure you want to reject this expense?"])))), createElement("label", createObj(Helpers_combineClasses("label", singleton_1(["children", "Rejection Reason"])))), createElement("textarea", createObj(Helpers_combineClasses("textarea", ofArray([["className", "input-bordered"], ["rows", 3], ["value", state_1.RejectionReason], ["onChange", (ev) => {
                dispatch(new Msg(11, [ev.target.value]));
            }]]))))], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))), (_arg_6) => {
                dispatch(new Msg(10, [false]));
            }, (_arg_7) => {
                dispatch(new Msg(10, [true]));
            })) : singleton_2(react.createElement(react.Fragment, {})), delay(() => append(singleton_2(Daisy_error(state_1.Errors)), delay(() => {
                let children_4;
                return append(singleton_2((children_4 = singleton_1(createElement(Daisy_progressState, {
                    busy: state_1.IsBusy,
                })), createElement("div", {
                    className: "form-control",
                    children: reactApi.Children.toArray(Array.from(children_4)),
                }))), delay(() => {
                    let elems_3;
                    return append(singleton_2(createElement("div", createObj(Helpers_combineClasses("navbar", ofArray([["className", "space-x-2"], (elems_3 = [createElement("button", createObj(Helpers_combineClasses("btn", toList(delay(() => append(BusyModule_isSaving(state_1.IsBusy) ? singleton_2(["className", "loading"]) : empty_1(), delay(() => append(singleton_2(["className", "btn-primary"]), delay(() => append(singleton_2(["className", "btn-sm"]), delay(() => append(singleton_2(["children", "Save"]), delay(() => singleton_2(["onClick", (_arg_9) => {
                        dispatch(new Msg(4, []));
                    }]))))))))))))))], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))))), delay(() => {
                        let children_8;
                        return append(singleton_2((children_8 = singleton_1(Daisy_h6(`Amount - ${amount}`)), createElement("div", {
                            className: "form-control",
                            children: reactApi.Children.toArray(Array.from(children_8)),
                        }))), delay(() => {
                            let children_10;
                            return append(singleton_2((children_10 = singleton_1(Daisy_h6(`Status - ${expenseStatus}`)), createElement("div", {
                                className: "form-control",
                                children: reactApi.Children.toArray(Array.from(children_10)),
                            }))), delay(() => {
                                let children_12;
                                return append(singleton_2((children_12 = singleton_1(Daisy_h6(`Created - ${created}`)), createElement("div", {
                                    className: "form-control",
                                    children: reactApi.Children.toArray(Array.from(children_12)),
                                }))), delay(() => {
                                    let children_16, elems_7;
                                    return append(singleton_2((children_16 = ofArray([createElement("label", createObj(Helpers_combineClasses("label", singleton_1(["children", "Job"])))), createElement("select", createObj(Helpers_combineClasses("select", ofArray([["className", "select-bordered"], ["className", "select-xs"], ["className", "w-full"], ["placeholder", "Job"], ["value", state_1.JobId], ["disabled", expenseIsReadOnly], ["onChange", (ev_1) => {
                                        dispatch(new Msg(3, [parse(ev_1.target.value)]));
                                    }], (elems_7 = toList(delay(() => map_1((job) => createElement("option", {
                                        value: job.Id,
                                        children: job.Name,
                                    }), state_1.Jobs))), ["children", reactApi.Children.toArray(Array.from(elems_7))])]))))]), createElement("div", {
                                        className: "form-control",
                                        children: reactApi.Children.toArray(Array.from(children_16)),
                                    }))), delay(() => {
                                        let children_20;
                                        return append(singleton_2((children_20 = ofArray([createElement("label", createObj(Helpers_combineClasses("label", singleton_1(["children", "Notes"])))), createElement("textarea", createObj(Helpers_combineClasses("textarea", ofArray([["className", "input-bordered"], ["className", "input-sm"], ["value", state_1.Notes], ["disabled", expenseIsReadOnly], ["onChange", (ev_2) => {
                                            dispatch(new Msg(2, [ev_2.target.value]));
                                        }]]))))]), createElement("div", {
                                            className: "form-control",
                                            children: reactApi.Children.toArray(Array.from(children_20)),
                                        }))), delay(() => {
                                            let children_23;
                                            return append(singleton_2((children_23 = toList(delay(() => {
                                                if (state_1.IsGettingAttachment) {
                                                    return singleton_2(createElement("progress", createObj(Helpers_combineClasses("progress", singleton_1(["className", "progress-primary"])))));
                                                }
                                                else {
                                                    const matchValue = state_1.Attachment;
                                                    if (matchValue == null) {
                                                        return singleton_2(react.createElement(react.Fragment, {}));
                                                    }
                                                    else {
                                                        const attachment = matchValue;
                                                        return singleton_2(createElement("img", {
                                                            className: join(" ", ["object-scale-down", "h-auto", "w-1/2", "self-center", "rounded-2xl"]),
                                                            src: "data:image/jpg;base64," + toBase64String(attachment),
                                                        }));
                                                    }
                                                }
                                            })), createElement("div", {
                                                className: "form-control",
                                                children: reactApi.Children.toArray(Array.from(children_23)),
                                            }))), delay(() => {
                                                let elems_11;
                                                return singleton_2(createElement("div", createObj(ofArray([["className", join(" ", ["flex justify-center space-x-4"])], (elems_11 = [createElement("button", createObj(Helpers_combineClasses("btn", toList(delay(() => append(state_1.IsApprovingIP ? singleton_2(["className", "loading"]) : empty_1(), delay(() => append(singleton_2(["className", "btn-primary"]), delay(() => append(singleton_2(["className", "btn-sm"]), delay(() => append(singleton_2(["disabled", expenseIsReadOnly]), delay(() => append(singleton_2(["children", "Approve"]), delay(() => singleton_2(["onClick", (_arg_10) => {
                                                    dispatch(new Msg(6, []));
                                                }])))))))))))))))), createElement("button", createObj(Helpers_combineClasses("btn", toList(delay(() => append(state_1.IsRejectingIP ? singleton_2(["className", "loading"]) : empty_1(), delay(() => append(singleton_2(["className", "btn-secondary"]), delay(() => append(singleton_2(["disabled", expenseIsReadOnly]), delay(() => append(singleton_2(["className", "btn-sm"]), delay(() => append(singleton_2(["children", "Reject"]), delay(() => singleton_2(["onClick", (_arg_11) => {
                                                    dispatch(new Msg(9, []));
                                                }]))))))))))))))))], ["children", reactApi.Children.toArray(Array.from(elems_11))])]))));
                                            }));
                                        }));
                                    }));
                                }));
                            }));
                        }));
                    }));
                }));
            }))));
        }))))));
    })));
}

