import { Record, Union } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, union_type, string_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { isNullOrEmpty } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/String.js";
import { singleton } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/List.js";
import { createInternal } from "./Util.fs.js";
import { SubscriptionCount_$reflection } from "./SubscriptionCount.fs.js";
import { Product } from "./Product.fs.js";
import { FSharpResult$2 } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Result.js";

export class StripeProductId_StripeProductId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["StripeProductId"];
    }
}

export function StripeProductId_StripeProductId_$reflection() {
    return union_type("Domain.Entity.Stripe.StripeProductId.StripeProductId", [], StripeProductId_StripeProductId, () => [[["Item", string_type]]]);
}

function StripeProductId_validations() {
    return singleton([isNullOrEmpty, (_arg) => "Stripe Product Id must not be empty."]);
}

export function StripeProductId_create(stripeProductId_1) {
    return createInternal(StripeProductId_validations(), stripeProductId_1, new StripeProductId_StripeProductId(stripeProductId_1));
}

export function StripeProductId_value(_arg) {
    return _arg.fields[0];
}

export class StripePriceId_StripePriceId extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["StripePriceId"];
    }
}

export function StripePriceId_StripePriceId_$reflection() {
    return union_type("Domain.Entity.Stripe.StripePriceId.StripePriceId", [], StripePriceId_StripePriceId, () => [[["Item", string_type]]]);
}

function StripePriceId_validations() {
    return singleton([isNullOrEmpty, (_arg) => "Stripe Price Id must not be empty."]);
}

export function StripePriceId_create(stripePriceId_1) {
    return createInternal(StripePriceId_validations(), stripePriceId_1, new StripePriceId_StripePriceId(stripePriceId_1));
}

export function StripePriceId_value(_arg) {
    return _arg.fields[0];
}

export class SubscriptionDto extends Record {
    constructor(ProductId, PriceId, SubscriptionCount) {
        super();
        this.ProductId = ProductId;
        this.PriceId = PriceId;
        this.SubscriptionCount = SubscriptionCount;
    }
}

export function SubscriptionDto_$reflection() {
    return record_type("Domain.Entity.Stripe.SubscriptionDto", [], SubscriptionDto, () => [["ProductId", StripeProductId_StripeProductId_$reflection()], ["PriceId", StripePriceId_StripePriceId_$reflection()], ["SubscriptionCount", SubscriptionCount_$reflection()]]);
}

export function stripeProductId(_arg) {
    switch (_arg.tag) {
        case 1:
            return "prod_NqLGxwwclZwVLx";
        case 2:
            return "prod_Qnp0NLgU1zy9EU";
        case 3:
            return "prod_Qnp0SmZJTARGf9";
        default:
            return "prod_NSeGwrYS7ofsSy";
    }
}

export function stripePriceId(_arg) {
    switch (_arg.tag) {
        case 1:
            return "price_1OY4B2KkfeQiA4LLxFZ4RHXl";
        case 2:
            return "price_1PwDMpKkfeQiA4LLbfaTsjNi";
        case 3:
            return "price_1PwDMGKkfeQiA4LLsmXdRcch";
        default:
            return "price_1Mhiy9KkfeQiA4LLdpxpFzUW";
    }
}

export function product(stripeProductId_1) {
    switch (stripeProductId_1) {
        case "prod_NSeGwrYS7ofsSy":
            return new Product(0, []);
        case "prod_NqLGxwwclZwVLx":
            return new Product(1, []);
        case "prod_Qnp0NLgU1zy9EU":
            return new Product(2, []);
        case "prod_Qnp0SmZJTARGf9":
            return new Product(3, []);
        default:
            return undefined;
    }
}

export function getProductIdAndPriceId(product_1) {
    const input_2 = StripeProductId_create(stripeProductId(product_1));
    if (input_2.tag === 1) {
        return new FSharpResult$2(1, [input_2.fields[0]]);
    }
    else {
        const input = StripePriceId_create(stripePriceId(product_1));
        if (input.tag === 1) {
            return new FSharpResult$2(1, [input.fields[0]]);
        }
        else {
            return new FSharpResult$2(0, [[input_2.fields[0], input.fields[0]]]);
        }
    }
}

