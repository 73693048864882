import { toString, Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { class_type, unit_type, union_type, record_type, list_type, string_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Busy, Busy_$reflection } from "../../SharedView.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { Guid_parse } from "../../../../Logos.Shared/Util.fs.js";
import { acceptInvite, getInviteDetails } from "../../../AppDomain/UseCase/User.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../Infrastructure/AppRoot.fs.js";
import { ofArray, singleton as singleton_1, empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Page, Cmd_navigateToPage } from "../../Router.fs.js";
import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../../Component/ViewContainer.fs.js";
import { Daisy_h6, Daisy_error, Daisy_h4 } from "../../Component/Component.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";

export class State extends Record {
    constructor(InviteId, InviteDetails, IsBusy, Info, Errors) {
        super();
        this.InviteId = InviteId;
        this.InviteDetails = InviteDetails;
        this.IsBusy = IsBusy;
        this.Info = Info;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.AcceptInvitePage.State", [], State, () => [["InviteId", string_type], ["InviteDetails", string_type], ["IsBusy", Busy_$reflection()], ["Info", list_type(string_type)], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetInviteDetailsResponse", "AcceptInvite", "AcceptInviteResponse", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.AcceptInvitePage.Msg", [], Msg, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [string_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", string_type]], [["ErrorValue", list_type(string_type)]]])]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Exception")]]]);
}

export function getInviteDetailsCmd(inviteId) {
    return cmdOfAsync((Item) => (new Msg(0, [Item])), (Item_1) => (new Msg(3, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_1;
        const value_1 = Guid_parse(inviteId);
        asyncResult_1 = singleton.Return(value_1);
        return singleton.Bind(asyncResult_1, (input_1) => {
            const input_2 = input_1;
            if (input_2.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
            }
            else {
                return getInviteDetails(appRoot.UserService, AppRootModule_getSelectedOrgId(), input_2.fields[0]);
            }
        });
    }));
}

export function acceptInviteCmd(inviteId) {
    return cmdOfAsync((Item) => (new Msg(2, [Item])), (Item_1) => (new Msg(3, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_1;
        const value_1 = Guid_parse(inviteId);
        asyncResult_1 = singleton.Return(value_1);
        return singleton.Bind(asyncResult_1, (input_1) => {
            const input_2 = input_1;
            if (input_2.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
            }
            else {
                return acceptInvite(appRoot.UserService, AppRootModule_getSelectedOrgId(), input_2.fields[0]);
            }
        });
    }));
}

export function init(inviteId) {
    return [new State(inviteId, "", new Busy(1, []), empty(), empty()), getInviteDetailsCmd(inviteId)];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [state, acceptInviteCmd(state.InviteId)];
        case 2:
            if (msg.fields[0].tag === 1) {
                return [new State(state.InviteId, state.InviteDetails, state.IsBusy, state.Info, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [state, Cmd_navigateToPage(new Page(22, []))];
            }
        case 3:
            return [new State(state.InviteId, state.InviteDetails, new Busy(0, []), state.Info, singleton_1(toString(msg.fields[0]))), Cmd_none()];
        default:
            if (msg.fields[0].tag === 1) {
                return [new State(state.InviteId, state.InviteDetails, state.IsBusy, state.Info, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.InviteId, msg.fields[0].fields[0], state.IsBusy, state.Info, state.Errors), Cmd_none()];
            }
    }
}

export function indexView(indexViewInputProps) {
    let children;
    const inviteId = indexViewInputProps.inviteId;
    let patternInput;
    const init_1 = init(inviteId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    return container([(children = singleton_1(Daisy_h4("Accept invitation")), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    })), Daisy_error(state_1.Errors), Daisy_h6(state_1.InviteDetails), createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-primary"], ["children", "Accept Invite"], ["onClick", (_arg_2) => {
        patternInput[1](new Msg(1, []));
    }]]))))]);
}

