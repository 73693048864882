import { toString, Record, Union } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, class_type, union_type, list_type, unit_type, string_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library-js.4.19.3/Result.js";
import { ofArray, singleton as singleton_1, empty } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { Cmd_map, Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { cmdOfAsync } from "../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { resetPassword, getOneTimeResetCode } from "../../AppDomain/UseCase/Auth.fs.js";
import { appRoot } from "../../Infrastructure/AppRoot.fs.js";
import { createElement } from "react";
import React from "react";
import { createObj } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { isNullOrEmpty, join } from "../../fable_modules/fable-library-js.4.19.3/String.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { Helpers_combineClasses } from "../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { Daisy_error } from "../Component/Component.fs.js";
import { BusyModule_isSaving, Busy, Busy_$reflection } from "../SharedView.fs.js";
import { create } from "../../../Logos.Shared/Domain/Entity/Email.fs.js";
import { create as create_1 } from "../../../Logos.Shared/Domain/Entity/Password.fs.js";
import { ResetPasswordDto } from "../../../Logos.Shared/Domain/Dto/User.fs.js";
import { Page, Cmd_navigateToPage } from "../Router.fs.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../Component/ViewContainer.fs.js";
import { singleton as singleton_2, delay, toList } from "../../fable_modules/fable-library-js.4.19.3/Seq.js";

export class MsgStateEnterUsername extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetUsername", "ClickNext", "GetOneTimeResetCodeResponse", "ApiError"];
    }
}

export function MsgStateEnterUsername_$reflection() {
    return union_type("Presentation.Pages.ResetPassword.MsgStateEnterUsername", [], MsgStateEnterUsername, () => [[["Item", string_type]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Exception")]]]);
}

export class MsgStateEnterNewPassword extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetOneTimeResetCode", "SetNewPassword", "SetConfirmNewPassword", "ResetPassword", "ResetPasswordResponse", "ApiError"];
    }
}

export function MsgStateEnterNewPassword_$reflection() {
    return union_type("Presentation.Pages.ResetPassword.MsgStateEnterNewPassword", [], MsgStateEnterNewPassword, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Exception")]]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["MsgStateEnterUsername", "MsgStateEnterNewPassword"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.ResetPassword.Msg", [], Msg, () => [[["Item", MsgStateEnterUsername_$reflection()]], [["Item", MsgStateEnterNewPassword_$reflection()]]]);
}

export class EnterUsername_StateEnterUsername extends Record {
    constructor(Username, Errors) {
        super();
        this.Username = Username;
        this.Errors = Errors;
    }
}

export function EnterUsername_StateEnterUsername_$reflection() {
    return record_type("Presentation.Pages.ResetPassword.EnterUsername.StateEnterUsername", [], EnterUsername_StateEnterUsername, () => [["Username", string_type], ["Errors", list_type(string_type)]]);
}

export function EnterUsername_initStateEnterUsername() {
    return [new EnterUsername_StateEnterUsername("", empty()), Cmd_none()];
}

export function EnterUsername_getOneTimeResetCodeCmd(username) {
    return cmdOfAsync((Item) => (new MsgStateEnterUsername(2, [Item])), (Item_1) => (new MsgStateEnterUsername(3, [Item_1])), () => singleton.Delay(() => getOneTimeResetCode(appRoot.AuthService, username)));
}

export function EnterUsername_updateStateEnterUsername(msg, state) {
    switch (msg.tag) {
        case 1:
            return [new EnterUsername_StateEnterUsername(state.Username, empty()), EnterUsername_getOneTimeResetCodeCmd(state.Username)];
        case 2:
            if (msg.fields[0].tag === 1) {
                return [new EnterUsername_StateEnterUsername(state.Username, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [state, Cmd_none()];
            }
        case 3:
            return [new EnterUsername_StateEnterUsername(state.Username, singleton_1(toString(msg.fields[0]))), Cmd_none()];
        default:
            return [new EnterUsername_StateEnterUsername(msg.fields[0], state.Errors), Cmd_none()];
    }
}

export function EnterUsername_SetUsernameView(props) {
    let elems_3, children_3, children_1, children_6;
    const dispatch = (arg) => {
        props.Dispatch(new Msg(0, [arg]));
    };
    return createElement("div", createObj(ofArray([["className", join(" ", ["sm:px-40", "space-y-8"])], (elems_3 = [(children_3 = ofArray([(children_1 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Please enter your username to receive a reset code to reset your password.",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_1)),
    })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["autoFocus", true], ["placeholder", "Username"], ["onChange", (ev) => {
        dispatch(new MsgStateEnterUsername(0, [ev.target.value]));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_3)),
    })), Daisy_error(props.State.Errors), (children_6 = singleton_1(createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-primary"], ["children", "Next"], ["onClick", (_arg) => {
        dispatch(new MsgStateEnterUsername(1, []));
    }]]))))), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_6)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])));
}

export class EnterNewPassword_StateEnterNewPassword extends Record {
    constructor(Username, OneTimeResetCode, NewPassword, ConfirmNewPassword, IsBusy, Errors) {
        super();
        this.Username = Username;
        this.OneTimeResetCode = OneTimeResetCode;
        this.NewPassword = NewPassword;
        this.ConfirmNewPassword = ConfirmNewPassword;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function EnterNewPassword_StateEnterNewPassword_$reflection() {
    return record_type("Presentation.Pages.ResetPassword.EnterNewPassword.StateEnterNewPassword", [], EnterNewPassword_StateEnterNewPassword, () => [["Username", string_type], ["OneTimeResetCode", string_type], ["NewPassword", string_type], ["ConfirmNewPassword", string_type], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export function EnterNewPassword_initStateEnterNewPassword(username) {
    return [new EnterNewPassword_StateEnterNewPassword(username, "", "", "", new Busy(0, []), empty()), Cmd_none()];
}

export function EnterNewPassword_resetPasswordCmd(state) {
    return cmdOfAsync((Item) => (new MsgStateEnterNewPassword(4, [Item])), (Item_1) => (new MsgStateEnterNewPassword(5, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_5;
        const value_1_1 = !isNullOrEmpty(state.OneTimeResetCode) ? (new FSharpResult$2(0, [undefined])) : (new FSharpResult$2(1, [singleton_1("Invalid One Time Reset code.")]));
        asyncResult_5 = singleton.Return(value_1_1);
        return singleton.Bind(asyncResult_5, (input_1_4) => {
            const input_14 = input_1_4;
            if (input_14.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_14.fields[0]]));
            }
            else {
                let asyncResult_4;
                const value_1_2 = (state.NewPassword === state.ConfirmNewPassword) ? (new FSharpResult$2(0, [undefined])) : (new FSharpResult$2(1, [singleton_1("Passwords do not match.")]));
                asyncResult_4 = singleton.Return(value_1_2);
                return singleton.Bind(asyncResult_4, (input_1_3) => {
                    const input_11 = input_1_3;
                    if (input_11.tag === 1) {
                        return singleton.Return(new FSharpResult$2(1, [input_11.fields[0]]));
                    }
                    else {
                        let asyncResult_3;
                        const value_1_3 = create(state.Username);
                        asyncResult_3 = singleton.Return(value_1_3);
                        return singleton.Bind(asyncResult_3, (input_1_2) => {
                            const input_8 = input_1_2;
                            if (input_8.tag === 1) {
                                return singleton.Return(new FSharpResult$2(1, [input_8.fields[0]]));
                            }
                            else {
                                let asyncResult_2;
                                const value_1_4 = create_1(state.NewPassword);
                                asyncResult_2 = singleton.Return(value_1_4);
                                return singleton.Bind(asyncResult_2, (input_1_1) => {
                                    const input_5 = input_1_1;
                                    if (input_5.tag === 1) {
                                        return singleton.Return(new FSharpResult$2(1, [input_5.fields[0]]));
                                    }
                                    else {
                                        let asyncResult_1;
                                        const value_1_5 = create_1(state.ConfirmNewPassword);
                                        asyncResult_1 = singleton.Return(value_1_5);
                                        return singleton.Bind(asyncResult_1, (input_1) => {
                                            const input_2 = input_1;
                                            if (input_2.tag === 1) {
                                                return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                                            }
                                            else {
                                                return resetPassword(appRoot.AuthService, new ResetPasswordDto(input_8.fields[0], state.OneTimeResetCode, input_5.fields[0], input_2.fields[0]));
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });
    }));
}

export function EnterNewPassword_updateStateEnterNewPassword(msg, state) {
    switch (msg.tag) {
        case 1:
            return [new EnterNewPassword_StateEnterNewPassword(state.Username, state.OneTimeResetCode, msg.fields[0], state.ConfirmNewPassword, state.IsBusy, state.Errors), Cmd_none()];
        case 2:
            return [new EnterNewPassword_StateEnterNewPassword(state.Username, state.OneTimeResetCode, state.NewPassword, msg.fields[0], state.IsBusy, state.Errors), Cmd_none()];
        case 3:
            return [state, EnterNewPassword_resetPasswordCmd(state)];
        case 4:
            if (msg.fields[0].tag === 1) {
                return [new EnterNewPassword_StateEnterNewPassword(state.Username, state.OneTimeResetCode, state.NewPassword, state.ConfirmNewPassword, state.IsBusy, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [state, Cmd_navigateToPage(new Page(20, []))];
            }
        case 5:
            return [new EnterNewPassword_StateEnterNewPassword(state.Username, state.OneTimeResetCode, state.NewPassword, state.ConfirmNewPassword, state.IsBusy, singleton_1(toString(msg.fields[0]))), Cmd_none()];
        default:
            return [new EnterNewPassword_StateEnterNewPassword(state.Username, msg.fields[0], state.NewPassword, state.ConfirmNewPassword, state.IsBusy, state.Errors), Cmd_none()];
    }
}

export function EnterNewPassword_SetPasswordView(props) {
    let elems_9, children_3, children_1, children_8, children_6, children_13, children_11, children_18, children_16, children_21;
    const state = props.State;
    const dispatch = (arg) => {
        props.Dispatch(new Msg(1, [arg]));
    };
    const isSaving = BusyModule_isSaving(state.IsBusy);
    return createElement("div", createObj(ofArray([["className", join(" ", ["sm:px-40", "space-y-8"])], (elems_9 = [(children_3 = ofArray([(children_1 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Username",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_1)),
    })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["value", state.Username], ["readOnly", true]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_3)),
    })), (children_8 = ofArray([(children_6 = singleton_1(createElement("span", {
        className: "label-text",
        children: "One time reset code",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_6)),
    })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["autoFocus", true], ["value", state.OneTimeResetCode], ["placeholder", "One time reset code"], ["readOnly", isSaving], ["onChange", (ev) => {
        dispatch(new MsgStateEnterNewPassword(0, [ev.target.value]));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_8)),
    })), (children_13 = ofArray([(children_11 = singleton_1(createElement("span", {
        className: "label-text",
        children: "New Password",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_11)),
    })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["type", "password"], ["autoFocus", true], ["value", state.NewPassword], ["placeholder", "New Password"], ["readOnly", isSaving], ["onChange", (ev_1) => {
        dispatch(new MsgStateEnterNewPassword(1, [ev_1.target.value]));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_13)),
    })), (children_18 = ofArray([(children_16 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Confirm Password",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_16)),
    })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["type", "password"], ["autoFocus", true], ["value", state.ConfirmNewPassword], ["placeholder", "Confirm Password"], ["readOnly", isSaving], ["onChange", (ev_2) => {
        dispatch(new MsgStateEnterNewPassword(2, [ev_2.target.value]));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_18)),
    })), Daisy_error(state.Errors), (children_21 = singleton_1(createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-primary"], ["children", "Reset Password"], ["onClick", (_arg_1) => {
        dispatch(new MsgStateEnterNewPassword(3, []));
    }]]))))), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_21)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_9))])])));
}

export class State extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["StateEnterUsername", "StateEnterNewPassword"];
    }
}

export function State_$reflection() {
    return union_type("Presentation.Pages.ResetPassword.State", [], State, () => [[["Item", EnterUsername_StateEnterUsername_$reflection()]], [["Item", EnterNewPassword_StateEnterNewPassword_$reflection()]]]);
}

export function init() {
    const patternInput = EnterUsername_initStateEnterUsername();
    return [new State(0, [patternInput[0]]), patternInput[1]];
}

export function update(msg, state) {
    if (msg.tag === 1) {
        if (state.tag === 0) {
            return [state, Cmd_none()];
        }
        else {
            const patternInput_2 = EnterNewPassword_updateStateEnterNewPassword(msg.fields[0], state.fields[0]);
            return [new State(1, [patternInput_2[0]]), Cmd_map((Item_1) => (new Msg(1, [Item_1])), patternInput_2[1])];
        }
    }
    else if (state.tag === 1) {
        return [state, Cmd_none()];
    }
    else {
        let matchResult;
        if (msg.fields[0].tag === 2) {
            if (msg.fields[0].fields[0].tag === 0) {
                matchResult = 0;
            }
            else {
                matchResult = 1;
            }
        }
        else {
            matchResult = 1;
        }
        switch (matchResult) {
            case 0: {
                const patternInput = EnterNewPassword_initStateEnterNewPassword(state.fields[0].Username);
                return [new State(1, [patternInput[0]]), patternInput[1]];
            }
            default: {
                const patternInput_1 = EnterUsername_updateStateEnterUsername(msg.fields[0], state.fields[0]);
                return [new State(0, [patternInput_1[0]]), Cmd_map((Item) => (new Msg(0, [Item])), patternInput_1[1])];
            }
        }
    }
}

export function IndexView() {
    let elems;
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return container([createElement("div", createObj(singleton_1((elems = toList(delay(() => ((state_1.tag === 1) ? singleton_2(createElement(EnterNewPassword_SetPasswordView, {
        Dispatch: dispatch,
        State: state_1.fields[0],
    })) : singleton_2(createElement(EnterUsername_SetUsernameView, {
        Dispatch: dispatch,
        State: state_1.fields[0],
    }))))), ["children", reactApi.Children.toArray(Array.from(elems))]))))]);
}

