import { singleton } from "../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library-js.4.19.3/Result.js";
import { SimproConnectionDetailDtoModule_ofSimproConnectionDetail, SimproLookupDtoModule_toSimproLookup, SimproSetupDetailDtoModule_ofSimproSetupDetail, SimproConnectionDetailDtoModule_toSimproConnectionDetail } from "../../../Logos.Shared/Domain/Dto/Simpro.fs.js";
import { map } from "../../fable_modules/fable-library-js.4.19.3/List.js";

export function searchSimproConnectionDetails(simproService, selectedOrgId, searchText, pagination) {
    return singleton.Delay(() => simproService.SearchConnectionDetails(selectedOrgId, searchText, pagination));
}

export function getSimproConnectionDetail(simproService, selectedOrgId, simproConnectionDetailId) {
    return singleton.Delay(() => {
        const input_2 = simproService.GetSimproConnectionDetail(selectedOrgId, simproConnectionDetailId);
        return singleton.Bind(input_2, (x$0027) => {
            let input_1;
            let value;
            const input_4 = x$0027;
            value = ((input_4.tag === 1) ? (new FSharpResult$2(1, [input_4.fields[0]])) : (new FSharpResult$2(0, [(input_1 = input_4.fields[0], (input_1 == null) ? undefined : SimproConnectionDetailDtoModule_toSimproConnectionDetail(input_1))])));
            return singleton.Return(value);
        });
    });
}

export function getSimproSetupDetails(simproService, selectedOrgId, simproSetupDetail) {
    return singleton.Delay(() => {
        let input;
        const simproSetupDetail_1 = SimproSetupDetailDtoModule_ofSimproSetupDetail(simproSetupDetail);
        input = simproService.GetSimproSetupDetails(selectedOrgId, simproSetupDetail_1);
        return singleton.Bind(input, (x$0027) => {
            let tupledArg;
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [(tupledArg = input_2.fields[0], [map(SimproLookupDtoModule_toSimproLookup, tupledArg[0]), map(SimproLookupDtoModule_toSimproLookup, tupledArg[1]), map(SimproLookupDtoModule_toSimproLookup, tupledArg[2])])])));
            return singleton.Return(value);
        });
    });
}

export function addSimproConnectionDetail(simproService, selectedOrgId, simproConnectionDetail) {
    return singleton.Delay(() => {
        const simproConnectionDetail_1 = SimproConnectionDetailDtoModule_ofSimproConnectionDetail(simproConnectionDetail);
        return simproService.AddSimproConnectionDetail(selectedOrgId, simproConnectionDetail_1);
    });
}

export function updateSimproConnectionDetail(simproService, selectedOrgId, simproConnectionDetail) {
    return singleton.Delay(() => {
        const simproConnectionDetail_1 = SimproConnectionDetailDtoModule_ofSimproConnectionDetail(simproConnectionDetail);
        return simproService.UpdateSimproConnectionDetail(selectedOrgId, simproConnectionDetail_1);
    });
}

