import { Record } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { option_type, list_type, bool_type, class_type, record_type, decimal_type, string_type, int32_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { SimproSetupDetail, SimproApiKey_$reflection, SimproConnectionDetail, SimproLookup, SimproContractorWorkOrderDetail, SimproJobCostCentre, SimproJobSection, SimproJob } from "../Entity/Simpro.fs.js";
import { map } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/List.js";

export class SimproJobDto extends Record {
    constructor(Id, CompanyId, Description, TotalExTax, Tax, IncTax) {
        super();
        this.Id = (Id | 0);
        this.CompanyId = (CompanyId | 0);
        this.Description = Description;
        this.TotalExTax = TotalExTax;
        this.Tax = Tax;
        this.IncTax = IncTax;
    }
}

export function SimproJobDto_$reflection() {
    return record_type("Domain.Dto.Simpro.SimproJobDto", [], SimproJobDto, () => [["Id", int32_type], ["CompanyId", int32_type], ["Description", string_type], ["TotalExTax", decimal_type], ["Tax", decimal_type], ["IncTax", decimal_type]]);
}

export function SimproJobDtoModule_ofSimproJob(job) {
    return new SimproJobDto(job.Id, job.CompanyId, job.Description, job.TotalExTax, job.Tax, job.IncTax);
}

export function SimproJobDtoModule_toSimproJob(job) {
    return new SimproJob(job.Id, job.CompanyId, job.Description, job.TotalExTax, job.Tax, job.IncTax);
}

export class SimproJobSectionDto extends Record {
    constructor(Id, JobId, Name, Description) {
        super();
        this.Id = (Id | 0);
        this.JobId = (JobId | 0);
        this.Name = Name;
        this.Description = Description;
    }
}

export function SimproJobSectionDto_$reflection() {
    return record_type("Domain.Dto.Simpro.SimproJobSectionDto", [], SimproJobSectionDto, () => [["Id", int32_type], ["JobId", int32_type], ["Name", string_type], ["Description", string_type]]);
}

export function SimproJobSectionDtoModule_ofSimproJobSection(section) {
    return new SimproJobSectionDto(section.Id, section.JobId, section.Name, section.Description);
}

export function SimproJobSectionDtoModule_toSimproJobSection(section) {
    return new SimproJobSection(section.Id, section.JobId, section.Name, section.Description);
}

export class SimproJobCostCentreDto extends Record {
    constructor(Id, JobId, Name, SectionId) {
        super();
        this.Id = (Id | 0);
        this.JobId = (JobId | 0);
        this.Name = Name;
        this.SectionId = (SectionId | 0);
    }
}

export function SimproJobCostCentreDto_$reflection() {
    return record_type("Domain.Dto.Simpro.SimproJobCostCentreDto", [], SimproJobCostCentreDto, () => [["Id", int32_type], ["JobId", int32_type], ["Name", string_type], ["SectionId", int32_type]]);
}

export function SimproJobCostCentreDtoModule_ofSimproJobCostCentre(costCentre) {
    return new SimproJobCostCentreDto(costCentre.Id, costCentre.JobId, costCentre.Name, costCentre.SectionId);
}

export function SimproJobCostCentreDtoModule_toSimproJobCostCentre(costCentre) {
    return new SimproJobCostCentre(costCentre.Id, costCentre.JobId, costCentre.Name, costCentre.SectionId);
}

export class SimproContractorWorkOrderDetailDto extends Record {
    constructor(PaymentRunNumber, JobTitle, Date$, LabourHours, LabourCost, PaymentRunId) {
        super();
        this.PaymentRunNumber = PaymentRunNumber;
        this.JobTitle = JobTitle;
        this.Date = Date$;
        this.LabourHours = LabourHours;
        this.LabourCost = LabourCost;
        this.PaymentRunId = PaymentRunId;
    }
}

export function SimproContractorWorkOrderDetailDto_$reflection() {
    return record_type("Domain.Dto.Simpro.SimproContractorWorkOrderDetailDto", [], SimproContractorWorkOrderDetailDto, () => [["PaymentRunNumber", string_type], ["JobTitle", string_type], ["Date", class_type("System.DateTimeOffset")], ["LabourHours", decimal_type], ["LabourCost", decimal_type], ["PaymentRunId", class_type("System.Guid")]]);
}

export function SimproContractorWorkOrderDetailDtoModule_ofSimproContractorWorkOrderDetail(detail) {
    return new SimproContractorWorkOrderDetailDto(detail.PaymentRunNumber, detail.JobTitle, detail.Date, detail.LabourHours, detail.LabourCost, detail.PaymentRunId);
}

export function SimproContractorWorkOrderDetailDtoModule_toSimproContractorWorkOrderDetail(detail) {
    return new SimproContractorWorkOrderDetail(detail.PaymentRunNumber, detail.JobTitle, detail.Date, detail.LabourHours, detail.LabourCost, detail.PaymentRunId);
}

export class SimproLookupDto extends Record {
    constructor(Id, Name) {
        super();
        this.Id = (Id | 0);
        this.Name = Name;
    }
}

export function SimproLookupDto_$reflection() {
    return record_type("Domain.Dto.Simpro.SimproLookupDto", [], SimproLookupDto, () => [["Id", int32_type], ["Name", string_type]]);
}

export function SimproLookupDtoModule_ofSimproLookup(lookup) {
    return new SimproLookupDto(lookup.Id, lookup.Name);
}

export function SimproLookupDtoModule_toSimproLookup(lookup) {
    return new SimproLookup(lookup.Id, lookup.Name);
}

export class SimproConnectionDetailDto extends Record {
    constructor(Id, IsDefaultOrgConnection, Name, OrganisationId, CompanyURL, ApiKey, CompanyId, Companies, ContractorId, Contractors, TaxCodeId, TaxCodes) {
        super();
        this.Id = Id;
        this.IsDefaultOrgConnection = IsDefaultOrgConnection;
        this.Name = Name;
        this.OrganisationId = OrganisationId;
        this.CompanyURL = CompanyURL;
        this.ApiKey = ApiKey;
        this.CompanyId = (CompanyId | 0);
        this.Companies = Companies;
        this.ContractorId = (ContractorId | 0);
        this.Contractors = Contractors;
        this.TaxCodeId = (TaxCodeId | 0);
        this.TaxCodes = TaxCodes;
    }
}

export function SimproConnectionDetailDto_$reflection() {
    return record_type("Domain.Dto.Simpro.SimproConnectionDetailDto", [], SimproConnectionDetailDto, () => [["Id", class_type("System.Guid")], ["IsDefaultOrgConnection", bool_type], ["Name", string_type], ["OrganisationId", class_type("System.Guid")], ["CompanyURL", string_type], ["ApiKey", string_type], ["CompanyId", int32_type], ["Companies", list_type(SimproLookupDto_$reflection())], ["ContractorId", int32_type], ["Contractors", list_type(SimproLookupDto_$reflection())], ["TaxCodeId", int32_type], ["TaxCodes", list_type(SimproLookupDto_$reflection())]]);
}

export function SimproConnectionDetailDtoModule_ofSimproConnectionDetail(detail) {
    return new SimproConnectionDetailDto(detail.Id, detail.IsDefaultOrgConnection, detail.Name, detail.OrganisationId, detail.CompanyURL, detail.ApiKey, detail.CompanyId, map(SimproLookupDtoModule_ofSimproLookup, detail.Companies), detail.ContractorId, map(SimproLookupDtoModule_ofSimproLookup, detail.Contractors), detail.TaxCodeId, map(SimproLookupDtoModule_ofSimproLookup, detail.TaxCodes));
}

export function SimproConnectionDetailDtoModule_toSimproConnectionDetail(detail) {
    return new SimproConnectionDetail(detail.Id, detail.IsDefaultOrgConnection, detail.Name, detail.OrganisationId, detail.CompanyURL, detail.ApiKey, detail.CompanyId, map(SimproLookupDtoModule_toSimproLookup, detail.Companies), detail.ContractorId, map(SimproLookupDtoModule_toSimproLookup, detail.Contractors), detail.TaxCodeId, map(SimproLookupDtoModule_toSimproLookup, detail.TaxCodes));
}

export class SimproSetupDetailDto extends Record {
    constructor(ApiKey, CompanyURL, CompanyId, ContractorId, TaxCodeId) {
        super();
        this.ApiKey = ApiKey;
        this.CompanyURL = CompanyURL;
        this.CompanyId = CompanyId;
        this.ContractorId = ContractorId;
        this.TaxCodeId = TaxCodeId;
    }
}

export function SimproSetupDetailDto_$reflection() {
    return record_type("Domain.Dto.Simpro.SimproSetupDetailDto", [], SimproSetupDetailDto, () => [["ApiKey", SimproApiKey_$reflection()], ["CompanyURL", string_type], ["CompanyId", option_type(int32_type)], ["ContractorId", option_type(int32_type)], ["TaxCodeId", option_type(int32_type)]]);
}

export function SimproSetupDetailDtoModule_ofSimproSetupDetail(detail) {
    return new SimproSetupDetailDto(detail.ApiKey, detail.CompanyURL, detail.CompanyId, detail.ContractorId, detail.TaxCodeId);
}

export function SimproSetupDetailDtoModule_toSimproSetupDetail(detail) {
    return new SimproSetupDetail(detail.ApiKey, detail.CompanyURL, detail.CompanyId, detail.ContractorId, detail.TaxCodeId);
}

