import { appRoot, AppRootModule_getSelectedOrgId } from "../../../Infrastructure/AppRoot.fs.js";
import { searchTimeSheetPayments } from "../../../AppDomain/UseCase/Timesheet.fs.js";
import { map, singleton, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { uncurry2, createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { iconButton, showModalDialog, ButtonType, showModalEx } from "../../Component/ModalDialog.fs.js";
import { alignIconCenter, alignCellTextTop, Daisy_h5, Daisy_DialogWidth } from "../../Component/Component.fs.js";
import { IndexView as IndexView_1 } from "./TimesheetPaymentRun.fs.js";
import { IndexView as IndexView_3, Msg$1 } from "../BaseSearch.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { ofArray, singleton as singleton_1 } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { IndexView as IndexView_2 } from "./TimesheetPaymentRunEdit.fs.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { DateTime_dateTimeToStringWithDayName, DateTime_dateToStringWithDayName } from "../../../AppDomain/Util.fs.js";
import { toLocalTime } from "../../../fable_modules/fable-library-js.4.19.3/DateOffset.js";

export function searchFunc() {
    const selectedOrgId = AppRootModule_getSelectedOrgId();
    return (searchText) => ((pagination) => searchTimeSheetPayments(appRoot.ShiftService, selectedOrgId, searchText, pagination));
}

export function paymentRunTable(props) {
    const children_8 = toList(delay(() => {
        let elems;
        return append(singleton(createElement("div", createObj(Helpers_combineClasses("navbar", singleton_1((elems = [createElement(showModalEx, {
            dialogId: "id-timesheet-search-add-timesheet",
            header: "",
            buttonType: new ButtonType(0, ["Generate Payroll"]),
            width: new Daisy_DialogWidth(3, []),
            heightPercent: "80",
            body: () => createElement(IndexView_1, null),
            onShow: () => {
                props.dispatch(new Msg$1(4, [undefined]));
            },
            onClose: () => {
                props.dispatch(new Msg$1(4, [undefined]));
            },
        })], ["children", reactApi.Children.toArray(Array.from(elems))])))))), delay(() => {
            let matchValue_1, x_1;
            return append((matchValue_1 = props.state.EntityToEdit, (matchValue_1 == null) ? singleton(react.createElement(react.Fragment, {})) : ((x_1 = matchValue_1, singleton(createElement(showModalDialog, {
                dialogId: x_1,
                header: "",
                width: new Daisy_DialogWidth(5, []),
                heightPercent: "90",
                body: () => {
                    const matchValue = props.state.EntityToEdit;
                    if (matchValue == null) {
                        const xs = [Daisy_h5("No Payment Run Selected.")];
                        return react.createElement(react.Fragment, {}, ...xs);
                    }
                    else {
                        return createElement(IndexView_2, {
                            paymentRunId: matchValue,
                        });
                    }
                },
                onShow: () => {
                    props.dispatch(new Msg$1(4, [x_1]));
                },
                onClose: () => {
                    props.dispatch(new Msg$1(4, [undefined]));
                },
            }))))), delay(() => {
                let elems_15, children_3, children_1, children_5;
                return singleton(createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["flex", "w-full"])], (elems_15 = [(children_3 = singleton_1((children_1 = ofArray([createElement("th", {
                    width: 20 + "%",
                    children: "Run #",
                }), createElement("th", {
                    width: 20 + "%",
                    children: "Period",
                }), createElement("th", {
                    width: 15 + "%",
                    children: "Created",
                }), createElement("th", {
                    width: 15 + "%",
                    children: "Canceled",
                }), createElement("th", {
                    width: 15 + "%",
                    children: "Paid",
                }), createElement("th", {
                    width: 5 + "%",
                    children: "",
                })]), createElement("tr", {
                    children: reactApi.Children.toArray(Array.from(children_1)),
                }))), createElement("thead", {
                    children: reactApi.Children.toArray(Array.from(children_3)),
                })), (children_5 = toList(delay(() => map((payment) => {
                    let elems_14, elems_3, elems_2, elems_1, elems_5, elems_4, elems_7, elems_6, elems_9, elems_8, elems_11, elems_10, elems_13, elems_12;
                    return createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top", "hover"])], (elems_14 = [createElement("td", createObj(ofArray([alignCellTextTop, ["style", {
                        cursor: "pointer",
                    }], ["onClick", (_arg_4) => {
                        props.dispatch(new Msg$1(4, [payment.Id]));
                    }], (elems_3 = [createElement("div", createObj(singleton_1((elems_2 = [createElement("span", createObj(ofArray([["style", {
                        display: "flex",
                        alignItems: "center",
                    }], (elems_1 = [createElement("i", {
                        className: join(" ", toList(delay(() => append(singleton("fa"), delay(() => ((payment.Paid != null) ? singleton("fa-check") : ((payment.Canceled != null) ? singleton("fa-times") : singleton("fa-hourglass")))))))),
                        style: createObj(toList(delay(() => append(singleton(["marginRight", "8px"]), delay(() => ((payment.Paid != null) ? singleton(["color", "green"]) : ((payment.Canceled != null) ? singleton(["color", "red"]) : singleton(["color", "orange"])))))))),
                    }), createElement("div", createObj(ofArray([alignCellTextTop, ["children", payment.RunNumber]])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))]))))], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("td", createObj(ofArray([["style", {
                        cursor: "pointer",
                    }], ["onClick", (_arg_5) => {
                        props.dispatch(new Msg$1(4, [payment.Id]));
                    }], (elems_5 = [createElement("div", createObj(ofArray([alignCellTextTop, (elems_4 = [createElement("p", {
                        children: [DateTime_dateToStringWithDayName(toLocalTime(payment.PayPeriodStart))],
                    }), createElement("p", {
                        children: [DateTime_dateToStringWithDayName(toLocalTime(payment.PayPeriodFinish))],
                    })], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", reactApi.Children.toArray(Array.from(elems_5))])]))), createElement("td", createObj(ofArray([["style", {
                        cursor: "pointer",
                    }], ["onClick", (_arg_6) => {
                        props.dispatch(new Msg$1(4, [payment.Id]));
                    }], (elems_7 = [createElement("div", createObj(ofArray([alignCellTextTop, (elems_6 = [createElement("p", {
                        children: [DateTime_dateTimeToStringWithDayName(payment.Created.DateCreated)],
                    }), createElement("p", {
                        children: [payment.Created.CreatedByName],
                    })], ["children", reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", reactApi.Children.toArray(Array.from(elems_7))])]))), createElement("td", createObj(ofArray([["style", {
                        cursor: "pointer",
                    }], ["onClick", (_arg_7) => {
                        props.dispatch(new Msg$1(4, [payment.Id]));
                    }], (elems_9 = [createElement("div", createObj(ofArray([alignCellTextTop, (elems_8 = toList(delay(() => {
                        const matchValue_2 = payment.Canceled;
                        if (matchValue_2 == null) {
                            return singleton(react.createElement(react.Fragment, {}));
                        }
                        else {
                            const x_2 = matchValue_2;
                            return append(singleton(createElement("p", {
                                children: [DateTime_dateTimeToStringWithDayName(x_2.DateCanceled)],
                            })), delay(() => singleton(createElement("p", {
                                children: [x_2.CanceledByName],
                            }))));
                        }
                    })), ["children", reactApi.Children.toArray(Array.from(elems_8))])])))], ["children", reactApi.Children.toArray(Array.from(elems_9))])]))), createElement("td", createObj(ofArray([["style", {
                        cursor: "pointer",
                    }], ["onClick", (_arg_8) => {
                        props.dispatch(new Msg$1(4, [payment.Id]));
                    }], (elems_11 = [createElement("div", createObj(ofArray([alignCellTextTop, (elems_10 = toList(delay(() => {
                        const matchValue_3 = payment.Paid;
                        if (matchValue_3 == null) {
                            return singleton(react.createElement(react.Fragment, {}));
                        }
                        else {
                            const x_3 = matchValue_3;
                            return append(singleton(createElement("p", {
                                children: [DateTime_dateTimeToStringWithDayName(x_3.DatePaid)],
                            })), delay(() => singleton(createElement("p", {
                                children: [x_3.PaidByName],
                            }))));
                        }
                    })), ["children", reactApi.Children.toArray(Array.from(elems_10))])])))], ["children", reactApi.Children.toArray(Array.from(elems_11))])]))), createElement("td", createObj(singleton_1((elems_13 = [createElement("div", createObj(ofArray([alignIconCenter, (elems_12 = [iconButton("fas fa-edit", (_arg_9) => {
                        props.dispatch(new Msg$1(4, [payment.Id]));
                    })], ["children", reactApi.Children.toArray(Array.from(elems_12))])])))], ["children", reactApi.Children.toArray(Array.from(elems_13))]))))], ["children", reactApi.Children.toArray(Array.from(elems_14))])])));
                }, props.state.Entities))), createElement("tbody", {
                    children: reactApi.Children.toArray(Array.from(children_5)),
                }))], ["children", reactApi.Children.toArray(Array.from(elems_15))])])))));
            }));
        }));
    }));
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_8)),
    });
}

export function IndexView() {
    return createElement(IndexView_3, {
        entityName: "Payrolls",
        entityTable: paymentRunTable,
        searchFunc: uncurry2(searchFunc()),
    });
}

