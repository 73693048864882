import { Record, Union } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, option_type, class_type, union_type, string_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { isMatch, create as create_1 } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/RegExp.js";
import { singleton } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/List.js";
import { createInternal } from "./Util.fs.js";

export class Email extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["EmailType"];
    }
}

export function Email_$reflection() {
    return union_type("Domain.Entity.Email.Email", [], Email, () => [[["Item", string_type]]]);
}

const pattern = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\\.[a-zA-Z0-9-.]+$";

const regex = create_1(pattern);

function validations() {
    return singleton([(x) => !isMatch(regex, x), (x_1) => (`Invalid email address - ${x_1}.`)]);
}

export function create(email) {
    return createInternal(validations(), email, new Email(email));
}

export function value(_arg) {
    return _arg.fields[0];
}

export const NoReplyEmail = create("info@10thdoor.com");

export class PendingEmail extends Record {
    constructor(Id, OrgId, Email, Created, Sent, SentBy, TTL) {
        super();
        this.Id = Id;
        this.OrgId = OrgId;
        this.Email = Email;
        this.Created = Created;
        this.Sent = Sent;
        this.SentBy = SentBy;
        this.TTL = TTL;
    }
}

export function PendingEmail_$reflection() {
    return record_type("Domain.Entity.Email.PendingEmail", [], PendingEmail, () => [["Id", class_type("System.Guid")], ["OrgId", class_type("System.Guid")], ["Email", string_type], ["Created", class_type("System.DateTime")], ["Sent", option_type(class_type("System.DateTime"))], ["SentBy", option_type(string_type)], ["TTL", option_type(class_type("System.TimeSpan"))]]);
}

