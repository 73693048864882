import { singleton } from "../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";

export function searchVehicles(vehicleService, selectedOrgId, searchText, pagination) {
    return singleton.Delay(() => vehicleService.SearchVehicles(selectedOrgId, searchText, pagination));
}

export function getVehicle(vehicleService, selectedOrgId, vehicleId) {
    return singleton.Delay(() => vehicleService.GetVehicle(selectedOrgId, vehicleId));
}

export function getVehicleImage(vehicleService, selectedOrgId, vehicleId) {
    return singleton.Delay(() => vehicleService.GetVehicleImage(selectedOrgId, vehicleId));
}

export function getVehicles(vehicleService, selectedOrgId) {
    return singleton.Delay(() => vehicleService.GetVehicles(selectedOrgId));
}

export function getVehicleLogTags(vehicleService, selectedOrgId) {
    return singleton.Delay(() => vehicleService.GetVehicleLogTags(selectedOrgId));
}

export function upsertVehicle(vehicleService, selectedOrgId, vehicle) {
    return singleton.Delay(() => vehicleService.UpsertVehicle(selectedOrgId, vehicle));
}

export function getVehicleLogs(vehicleService, selectedOrgId, dateFrom, dateTo, vehiclesToSearchFor, vehicleLogTags, pagination) {
    return singleton.Delay(() => vehicleService.GetVehicleLogs(selectedOrgId, dateFrom, dateTo, vehiclesToSearchFor, vehicleLogTags, pagination));
}

export function getFuelSummary(vehicleService, selectedOrgId, dateFrom, dateTo) {
    return singleton.Delay(() => vehicleService.GetFuelSummary(selectedOrgId, dateFrom, dateTo));
}

export function getVehicleLogSummary(vehicleService, selectedOrgId, dateFrom, dateTo) {
    return singleton.Delay(() => vehicleService.GetVehicleLogSummary(selectedOrgId, dateFrom, dateTo));
}

