import { Record } from "../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, lambda_type, class_type, union_type, list_type, string_type, unit_type } from "../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { FSharpResult$2 } from "../../Logos.Client/fable_modules/fable-library-js.4.19.3/Result.js";
import { printf, toText } from "../../Logos.Client/fable_modules/fable-library-js.4.19.3/String.js";

export const Remoting_useBinarySerialization = true;

export class Service extends Record {
    constructor(GetMessage) {
        super();
        this.GetMessage = GetMessage;
    }
}

export function Service_$reflection() {
    return record_type("Logos.Shared.API.Service", [], Service, () => [["GetMessage", lambda_type(unit_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [string_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", string_type]], [["ErrorValue", list_type(string_type)]]])]))]]);
}

export function Service_RouteBuilder(s, m) {
    return toText(printf("/api/%s/%s"))(s)(m);
}

