import { Record, Union } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { list_type, record_type, bool_type, decimal_type, string_type, option_type, class_type, getUnionCases, name, union_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { sortBy } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Array.js";
import { comparePrimitives } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Util.js";
import { FSharpResult$2 } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Result.js";
import { singleton } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/List.js";

export class ExpenseStatus extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Pending", "Submitted", "Approved", "Rejected", "Withdrawn"];
    }
}

export function ExpenseStatus_$reflection() {
    return union_type("Domain.Entity.Expense.ExpenseStatus", [], ExpenseStatus, () => [[], [], [], [], []]);
}

export const ExpenseStatusModule_expenseStatuses = sortBy(name, getUnionCases(ExpenseStatus_$reflection(), 0), {
    Compare: comparePrimitives,
});

export function ExpenseStatusModule_toInt(_arg) {
    switch (_arg.tag) {
        case 1:
            return 1;
        case 2:
            return 2;
        case 3:
            return 3;
        case 4:
            return 4;
        default:
            return 0;
    }
}

export function ExpenseStatusModule_ofInt(_arg) {
    switch (_arg) {
        case 0:
            return new ExpenseStatus(0, []);
        case 1:
            return new ExpenseStatus(1, []);
        case 2:
            return new ExpenseStatus(2, []);
        case 3:
            return new ExpenseStatus(3, []);
        case 4:
            return new ExpenseStatus(4, []);
        default:
            throw new Error(`Invalid ExpenseStatus ${_arg}.`);
    }
}

export function ExpenseStatusModule_toString(_arg) {
    switch (_arg.tag) {
        case 1:
            return "Submitted";
        case 2:
            return "Approved";
        case 3:
            return "Rejected";
        case 4:
            return "Withdrawn";
        default:
            return "Pending";
    }
}

export function ExpenseStatusModule_ofString(x) {
    switch (x) {
        case "Pending":
            return new FSharpResult$2(0, [new ExpenseStatus(0, [])]);
        case "Submitted":
            return new FSharpResult$2(0, [new ExpenseStatus(1, [])]);
        case "Approved":
            return new FSharpResult$2(0, [new ExpenseStatus(2, [])]);
        case "Rejected":
            return new FSharpResult$2(0, [new ExpenseStatus(3, [])]);
        case "Withdrawn":
            return new FSharpResult$2(0, [new ExpenseStatus(4, [])]);
        default:
            return new FSharpResult$2(1, [singleton(`Invalid ExpenseStatus ${x}.`)]);
    }
}

export class Expense extends Record {
    constructor(Id, Date$, OrgId, UserId, JobId, Description, Amount, IsReceiptAttached, Status, Notes, CreatedAt, UpdatedAt, ApprovedAt, ApprovedBy, RejectedAt, RejectedBy, RejectionReason) {
        super();
        this.Id = Id;
        this.Date = Date$;
        this.OrgId = OrgId;
        this.UserId = UserId;
        this.JobId = JobId;
        this.Description = Description;
        this.Amount = Amount;
        this.IsReceiptAttached = IsReceiptAttached;
        this.Status = Status;
        this.Notes = Notes;
        this.CreatedAt = CreatedAt;
        this.UpdatedAt = UpdatedAt;
        this.ApprovedAt = ApprovedAt;
        this.ApprovedBy = ApprovedBy;
        this.RejectedAt = RejectedAt;
        this.RejectedBy = RejectedBy;
        this.RejectionReason = RejectionReason;
    }
}

export function Expense_$reflection() {
    return record_type("Domain.Entity.Expense.Expense", [], Expense, () => [["Id", class_type("System.Guid")], ["Date", class_type("System.DateTimeOffset")], ["OrgId", class_type("System.Guid")], ["UserId", class_type("System.Guid")], ["JobId", option_type(class_type("System.Guid"))], ["Description", string_type], ["Amount", decimal_type], ["IsReceiptAttached", bool_type], ["Status", ExpenseStatus_$reflection()], ["Notes", option_type(string_type)], ["CreatedAt", class_type("System.DateTimeOffset")], ["UpdatedAt", class_type("System.DateTimeOffset")], ["ApprovedAt", option_type(class_type("System.DateTimeOffset"))], ["ApprovedBy", option_type(class_type("System.Guid"))], ["RejectedAt", option_type(class_type("System.DateTimeOffset"))], ["RejectedBy", option_type(class_type("System.Guid"))], ["RejectionReason", option_type(string_type)]]);
}

export class ExpensesResponse extends Record {
    constructor(Expenses, HasMoreRecords) {
        super();
        this.Expenses = Expenses;
        this.HasMoreRecords = HasMoreRecords;
    }
}

export function ExpensesResponse_$reflection() {
    return record_type("Domain.Entity.Expense.ExpensesResponse", [], ExpensesResponse, () => [["Expenses", list_type(Expense_$reflection())], ["HasMoreRecords", bool_type]]);
}

export class ExpenseSearchDetails extends Record {
    constructor(SearchText, Status, FromDate, ToDate) {
        super();
        this.SearchText = SearchText;
        this.Status = Status;
        this.FromDate = FromDate;
        this.ToDate = ToDate;
    }
}

export function ExpenseSearchDetails_$reflection() {
    return record_type("Domain.Entity.Expense.ExpenseSearchDetails", [], ExpenseSearchDetails, () => [["SearchText", string_type], ["Status", option_type(ExpenseStatus_$reflection())], ["FromDate", option_type(class_type("System.DateTimeOffset"))], ["ToDate", option_type(class_type("System.DateTimeOffset"))]]);
}

export class ExpenseSearchResult extends Record {
    constructor(Id, UserId, FirstName, MiddleName, LastName, Date$, JobTitle, Description, Amount, Status, Notes, CreatedAt, UpdatedAt, ApprovedByUsername, RejectedByUsername, RejectionReason) {
        super();
        this.Id = Id;
        this.UserId = UserId;
        this.FirstName = FirstName;
        this.MiddleName = MiddleName;
        this.LastName = LastName;
        this.Date = Date$;
        this.JobTitle = JobTitle;
        this.Description = Description;
        this.Amount = Amount;
        this.Status = Status;
        this.Notes = Notes;
        this.CreatedAt = CreatedAt;
        this.UpdatedAt = UpdatedAt;
        this.ApprovedByUsername = ApprovedByUsername;
        this.RejectedByUsername = RejectedByUsername;
        this.RejectionReason = RejectionReason;
    }
}

export function ExpenseSearchResult_$reflection() {
    return record_type("Domain.Entity.Expense.ExpenseSearchResult", [], ExpenseSearchResult, () => [["Id", class_type("System.Guid")], ["UserId", class_type("System.Guid")], ["FirstName", string_type], ["MiddleName", string_type], ["LastName", string_type], ["Date", class_type("System.DateTimeOffset")], ["JobTitle", string_type], ["Description", string_type], ["Amount", decimal_type], ["Status", ExpenseStatus_$reflection()], ["Notes", option_type(string_type)], ["CreatedAt", class_type("System.DateTimeOffset")], ["UpdatedAt", class_type("System.DateTimeOffset")], ["ApprovedByUsername", option_type(string_type)], ["RejectedByUsername", option_type(string_type)], ["RejectionReason", option_type(string_type)]]);
}

