import { toString, Record, Union } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, class_type, union_type, list_type, string_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { appRoot } from "../../Infrastructure/AppRoot.fs.js";
import { Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Page, Cmd_navigateToPage } from "../Router.fs.js";
import { ofArray, empty, singleton as singleton_1 } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { createElement } from "react";
import React from "react";
import { createObj } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { join } from "../../fable_modules/fable-library-js.4.19.3/String.js";

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetMessage", "GetMessageResponse", "NavigateToScanBankStatement", "NavigateToRememberWhereItems", "NavigateToLogMaster", "CreateOrganisation", "JoinOrganisation", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.Dashboard.Msg", [], Msg, () => [[], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [string_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", string_type]], [["ErrorValue", list_type(string_type)]]])]], [], [], [], [], [], [["Item", class_type("System.Exception")]]]);
}

export class State extends Record {
    constructor(Message, Errors) {
        super();
        this.Message = Message;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.Dashboard.State", [], State, () => [["Message", string_type], ["Errors", list_type(string_type)]]);
}

export function getMessageCmd() {
    return cmdOfAsync((Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(7, [Item_1])), () => singleton.Delay(() => appRoot.Service.GetMessage()));
}

export function update(msg, model) {
    switch (msg.tag) {
        case 1:
            if (msg.fields[0].tag === 1) {
                return [new State(model.Message, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(msg.fields[0].fields[0], model.Errors), Cmd_none()];
            }
        case 2:
            return [model, Cmd_navigateToPage(new Page(23, []))];
        case 3:
            return [model, Cmd_navigateToPage(new Page(3, []))];
        case 4:
            return [model, Cmd_navigateToPage(new Page(15, []))];
        case 5:
            return [model, Cmd_navigateToPage(new Page(24, []))];
        case 6:
            return [model, Cmd_none()];
        case 7:
            return [new State(model.Message, singleton_1(toString(msg.fields[0]))), Cmd_none()];
        default:
            return [model, getMessageCmd()];
    }
}

export function init() {
    return [new State("", empty()), Cmd_none()];
}

export function messageCard(state, dispatch) {
    let elems_3, children_4, elems, children_2;
    return createElement("div", createObj(Helpers_combineClasses("card", ofArray([["className", "bordered"], ["className", "image-full"], (elems_3 = [(children_4 = ofArray([createElement("h2", createObj(Helpers_combineClasses("card-title", singleton_1((elems = [createElement("span", {
        children: ["Test message"],
    })], ["children", reactApi.Children.toArray(Array.from(elems))]))))), createElement("label", {
        children: `Dashboard ${state.Message}`,
    }), (children_2 = singleton_1(createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-primary"], ["children", "Get Message"], ["onClick", (_arg) => {
        dispatch(new Msg(0, []));
    }]]))))), createElement("div", {
        className: "card-actions",
        children: reactApi.Children.toArray(Array.from(children_2)),
    }))]), createElement("div", {
        className: "card-body",
        children: reactApi.Children.toArray(Array.from(children_4)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))));
}

export function scanBankStatementsCard(state, dispatch) {
    let elems_3, children_4, elems, children_2;
    return createElement("div", createObj(Helpers_combineClasses("card", ofArray([["className", "bordered"], ["className", "image-full"], (elems_3 = [(children_4 = ofArray([createElement("h2", createObj(Helpers_combineClasses("card-title", singleton_1((elems = [createElement("span", {
        children: ["Scan Bank Statements"],
    })], ["children", reactApi.Children.toArray(Array.from(elems))]))))), (children_2 = singleton_1(createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-primary"], ["children", "Scan"], ["onClick", (_arg) => {
        dispatch(new Msg(2, []));
    }]]))))), createElement("div", {
        className: "card-actions",
        children: reactApi.Children.toArray(Array.from(children_2)),
    }))]), createElement("div", {
        className: "card-body",
        children: reactApi.Children.toArray(Array.from(children_4)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))));
}

export function createOrJoinOrganisationCard(state, dispatch) {
    let elems_3, children_5, elems, children_3;
    return createElement("div", createObj(Helpers_combineClasses("card", ofArray([["className", "bordered"], ["className", "image-full"], (elems_3 = [(children_5 = ofArray([createElement("h2", createObj(Helpers_combineClasses("card-title", singleton_1((elems = [createElement("span", {
        children: ["Create or Join an Organisation to start working"],
    })], ["children", reactApi.Children.toArray(Array.from(elems))]))))), (children_3 = ofArray([createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-primary"], ["children", "Create a new Organisation"], ["onClick", (_arg) => {
        dispatch(new Msg(5, []));
    }]])))), createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-primary"], ["children", "Join an Organisation"]]))))]), createElement("div", {
        className: "card-actions",
        children: reactApi.Children.toArray(Array.from(children_3)),
    }))]), createElement("div", {
        className: "card-body",
        children: reactApi.Children.toArray(Array.from(children_5)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))));
}

export function rememberWhereCard(state, dispatch) {
    let elems_3, children_4, elems, children_2;
    return createElement("div", createObj(Helpers_combineClasses("card", ofArray([["className", "bordered"], ["className", "image-full"], (elems_3 = [(children_4 = ofArray([createElement("h2", createObj(Helpers_combineClasses("card-title", singleton_1((elems = [createElement("span", {
        children: ["View Items"],
    })], ["children", reactApi.Children.toArray(Array.from(elems))]))))), (children_2 = singleton_1(createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-primary"], ["children", "View"], ["onClick", (_arg) => {
        dispatch(new Msg(3, []));
    }]]))))), createElement("div", {
        className: "card-actions",
        children: reactApi.Children.toArray(Array.from(children_2)),
    }))]), createElement("div", {
        className: "card-body",
        children: reactApi.Children.toArray(Array.from(children_4)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))));
}

export function logMasterCard(state, dispatch) {
    let elems_3, children_4, elems, children_2;
    return createElement("div", createObj(Helpers_combineClasses("card", ofArray([["className", "bordered"], ["className", "image-full"], (elems_3 = [(children_4 = ofArray([createElement("h2", createObj(Helpers_combineClasses("card-title", singleton_1((elems = [createElement("span", {
        children: ["LogMaster"],
    })], ["children", reactApi.Children.toArray(Array.from(elems))]))))), (children_2 = singleton_1(createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-primary"], ["children", "View"], ["onClick", (_arg) => {
        dispatch(new Msg(4, []));
    }]]))))), createElement("div", {
        className: "card-actions",
        children: reactApi.Children.toArray(Array.from(children_2)),
    }))]), createElement("div", {
        className: "card-body",
        children: reactApi.Children.toArray(Array.from(children_4)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))));
}

export function IndexView() {
    let elems_1, elems;
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init, update, (_arg, _arg_1) => {
    }), undefined, []);
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex-row", "py-12", "px-4", "sm:px-6", "lg:px-8"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["max-w-md", "w-full", "space-y-8"])], (elems = [messageCard(patternInput[0], patternInput[1])], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

