import { toString, Union, Record } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type, record_type, string_type, bool_type, option_type, class_type, list_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Pagination_$reflection } from "../../../Logos.Shared/Domain/Entity/Pagination.fs.js";
import { Busy, Busy_$reflection } from "../SharedView.fs.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { paginationControl, firstPage, nextPage, previousPage, getDefaultPagination } from "../Component/Pagination.fs.js";
import { singleton as singleton_1, isEmpty, empty } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { equals } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { isNullOrEmpty } from "../../fable_modules/fable-library-js.4.19.3/String.js";
import { containerWithPadding } from "../Component/ViewContainer.fs.js";
import { empty as empty_1, singleton as singleton_2, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { String_isNotNullOrEmpty } from "../../../Logos.Shared/Util.fs.js";
import { Daisy_progressState, Daisy_error, Daisy_h4 } from "../Component/Component.fs.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/./Interop.fs.js";

export class State$2 extends Record {
    constructor(SearchDetails, Entities, EntityToEdit, NextPagination, CurrentPagination, RefreshPage, IsBusy, Errors) {
        super();
        this.SearchDetails = SearchDetails;
        this.Entities = Entities;
        this.EntityToEdit = EntityToEdit;
        this.NextPagination = NextPagination;
        this.CurrentPagination = CurrentPagination;
        this.RefreshPage = RefreshPage;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State$2_$reflection(gen0, gen1) {
    return record_type("Presentation.Pages.BaseSearchAdvanced.State`2", [gen0, gen1], State$2, () => [["SearchDetails", gen0], ["Entities", list_type(gen1)], ["EntityToEdit", option_type(class_type("System.Guid"))], ["NextPagination", Pagination_$reflection()], ["CurrentPagination", Pagination_$reflection()], ["RefreshPage", bool_type], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg$2 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetSearchDetails", "Search", "RefreshPage", "SearchResponse", "EditEntity", "NoAction", "GotoPreviousPage", "GotoNextPage", "GotoFirstPage", "SetRefreshPage", "ApiError"];
    }
}

export function Msg$2_$reflection(gen0, gen1) {
    return union_type("Presentation.Pages.BaseSearchAdvanced.Msg`2", [gen0, gen1], Msg$2, () => [[["Item", gen0]], [], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(gen1), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", list_type(gen1)]], [["ErrorValue", list_type(string_type)]]])]], [["Item", option_type(class_type("System.Guid"))]], [], [], [], [], [["Item", bool_type]], [["Item", class_type("System.Exception")]]]);
}

export function searchEntitiesCmd(state, search) {
    return cmdOfAsync((Item) => (new Msg$2(3, [Item])), (Item_1) => (new Msg$2(10, [Item_1])), () => singleton.Delay(() => search(state.SearchDetails, state.NextPagination)));
}

export function init(searchDetails, searchFunc) {
    let state;
    const pagination = getDefaultPagination();
    state = (new State$2(searchDetails, empty(), undefined, pagination, pagination, false, new Busy(1, []), empty()));
    return [state, searchEntitiesCmd(state, searchFunc)];
}

function changePage(state, f, searchFunc) {
    const currentState = state;
    const newState = new State$2(state.SearchDetails, state.Entities, state.EntityToEdit, f(state.CurrentPagination), state.CurrentPagination, state.RefreshPage, new Busy(1, []), state.Errors);
    if (equals(currentState, newState)) {
        return [currentState, Cmd_none()];
    }
    else {
        return [newState, searchEntitiesCmd(newState, searchFunc)];
    }
}

export function update(searchFunc, msg, state) {
    switch (msg.tag) {
        case 1:
            return [new State$2(state.SearchDetails, empty(), state.EntityToEdit, state.NextPagination, state.CurrentPagination, state.RefreshPage, new Busy(1, []), empty()), searchEntitiesCmd(state, searchFunc)];
        case 3:
            if (msg.fields[0].tag === 1) {
                return [new State$2(state.SearchDetails, state.Entities, state.EntityToEdit, state.NextPagination, state.CurrentPagination, state.RefreshPage, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                const newState = isEmpty(msg.fields[0].fields[0]) ? (new State$2(state.SearchDetails, state.Entities, state.EntityToEdit, state.CurrentPagination, state.CurrentPagination, state.RefreshPage, state.IsBusy, state.Errors)) : (new State$2(state.SearchDetails, msg.fields[0].fields[0], state.EntityToEdit, state.NextPagination, state.NextPagination, state.RefreshPage, state.IsBusy, state.Errors));
                return [new State$2(newState.SearchDetails, newState.Entities, newState.EntityToEdit, newState.NextPagination, newState.CurrentPagination, newState.RefreshPage, new Busy(0, []), newState.Errors), Cmd_none()];
            }
        case 4:
            return [new State$2(state.SearchDetails, state.Entities, msg.fields[0], state.NextPagination, state.CurrentPagination, state.RefreshPage, state.IsBusy, state.Errors), Cmd_none()];
        case 6:
            return changePage(state, previousPage, searchFunc);
        case 7:
            return changePage(state, nextPage, searchFunc);
        case 8:
            return changePage(state, firstPage, searchFunc);
        case 9:
            return [new State$2(state.SearchDetails, state.Entities, state.EntityToEdit, state.NextPagination, state.CurrentPagination, msg.fields[0], state.IsBusy, state.Errors), Cmd_none()];
        case 2:
            if (state.RefreshPage) {
                return [new State$2(state.SearchDetails, state.Entities, state.EntityToEdit, state.NextPagination, state.CurrentPagination, false, state.IsBusy, state.Errors), searchEntitiesCmd(state, searchFunc)];
            }
            else {
                return [state, Cmd_none()];
            }
        case 10:
            return [new State$2(state.SearchDetails, state.Entities, state.EntityToEdit, state.NextPagination, state.CurrentPagination, state.RefreshPage, new Busy(0, []), singleton_1(toString(msg.fields[0]))), Cmd_none()];
        case 5:
            return [state, Cmd_none()];
        default: {
            const pagination = getDefaultPagination();
            return [new State$2(msg.fields[0], state.Entities, state.EntityToEdit, pagination, pagination, state.RefreshPage, state.IsBusy, state.Errors), Cmd_none()];
        }
    }
}

export function IndexView(props) {
    let patternInput;
    const init_1 = init(props.searchDetails, props.searchFunc);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, (msg, state) => update(props.searchFunc, msg, state), (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    let patternInput_1;
    const matchValue = isNullOrEmpty(props.xPadding);
    const matchValue_1 = isNullOrEmpty(props.yPadding);
    patternInput_1 = (matchValue ? (matchValue_1 ? ["px-8", "py-8"] : ["px-8", props.yPadding]) : (matchValue_1 ? [props.xPadding, "py-8"] : [props.xPadding, props.yPadding]));
    return containerWithPadding(patternInput_1[0], patternInput_1[1], toList(delay(() => {
        let children;
        return append(String_isNotNullOrEmpty(props.entityName) ? singleton_2((children = singleton_1(Daisy_h4(`${props.entityName} search`)), createElement("div", {
            children: reactApi.Children.toArray(Array.from(children)),
        }))) : empty_1(), delay(() => append(singleton_2(props.searchPanel({
            dispatch: dispatch,
            state: state_1,
        })), delay(() => append(singleton_2(Daisy_error(state_1.Errors)), delay(() => {
            let children_2;
            return append(singleton_2((children_2 = singleton_1(createElement(Daisy_progressState, {
                busy: state_1.IsBusy,
            })), createElement("div", {
                className: "form-control",
                children: reactApi.Children.toArray(Array.from(children_2)),
            }))), delay(() => append(singleton_2(props.entityTable({
                dispatch: dispatch,
                state: state_1,
            })), delay(() => singleton_2(paginationControl(false, state_1.CurrentPagination.CurrentPage, (_arg_2) => {
                dispatch(new Msg$2(6, []));
            }, (_arg_3) => {
                dispatch(new Msg$2(7, []));
            }, (_arg_4) => {
                dispatch(new Msg$2(8, []));
            }))))));
        }))))));
    })));
}

