import { Record } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, option_type, lambda_type, union_type, list_type, string_type, class_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Pagination_$reflection } from "../Entity/Pagination.fs.js";
import { HolidayDto_$reflection } from "../Dto/Shift.fs.js";
import { FSharpResult$2 } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Result.js";
import { printf, toText } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/String.js";

export class HolidayService extends Record {
    constructor(searchHolidays, getHoliday, upsertHoliday, getHolidays) {
        super();
        this.searchHolidays = searchHolidays;
        this.getHoliday = getHoliday;
        this.upsertHoliday = upsertHoliday;
        this.getHolidays = getHolidays;
    }
}

export function HolidayService_$reflection() {
    return record_type("Logos.Shared.Domain.Remoting.HolidayService", [], HolidayService, () => [["searchHolidays", lambda_type(class_type("System.Guid"), lambda_type(string_type, lambda_type(Pagination_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(HolidayDto_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", list_type(HolidayDto_$reflection())]], [["ErrorValue", list_type(string_type)]]])]))))], ["getHoliday", lambda_type(class_type("System.Guid"), lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [option_type(HolidayDto_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", option_type(HolidayDto_$reflection())]], [["ErrorValue", list_type(string_type)]]])])))], ["upsertHoliday", lambda_type(class_type("System.Guid"), lambda_type(HolidayDto_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [class_type("System.Guid"), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", class_type("System.Guid")]], [["ErrorValue", list_type(string_type)]]])])))], ["getHolidays", lambda_type(class_type("System.Guid"), lambda_type(class_type("System.DateTimeOffset"), lambda_type(class_type("System.DateTimeOffset"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(HolidayDto_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", list_type(HolidayDto_$reflection())]], [["ErrorValue", list_type(string_type)]]])]))))]]);
}

export function HolidayService_RouteBuilder(s, m) {
    return toText(printf("/api/%s/%s"))(s)(m);
}

