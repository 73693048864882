import * as leaflet from "../../../../node_modules/leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
import { Record } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { int32_type, tuple_type, float64_type, class_type, record_type, list_type, string_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Location_$reflection } from "../../../Logos.Shared/Domain/Entity/Location.fs.js";
import { toNumber } from "../../fable_modules/fable-library-js.4.19.3/Decimal.js";
import { map, empty, singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { createElement } from "react";
import * as react from "react";
import { join } from "../../fable_modules/fable-library-js.4.19.3/String.js";
import { isEmpty, tryHead, ofArray, map as map_1, concat, singleton as singleton_1 } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { Map as Map$, Circle as Circle_1, TileLayer, Marker as Marker_1, Tooltip, Popup } from "react-leaflet";
import { keyValueList } from "../../fable_modules/fable-library-js.4.19.3/MapUtil.js";
import { MapProps_Style_Z6271F8AC, MapProps, CircleProps, CircleProps_Custom_433E080 } from "../../fable_modules/Fable.ReactLeaflet.2.0.0/ReactLeaflet.fs.js";
import { ShiftLogTypeModule_toString } from "../../../Logos.Shared/Domain/Entity/Shift.fs.js";
import { CSSProp } from "../../fable_modules/Fable.React.9.4.0/Fable.React.Props.fs.js";
import { map as map_2, defaultArg } from "../../fable_modules/fable-library-js.4.19.3/Option.js";


Icon.Default.imagePath = "//cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/";

export class Marker extends Record {
    constructor(Info, Link, Location) {
        super();
        this.Info = Info;
        this.Link = Link;
        this.Location = Location;
    }
}

export function Marker_$reflection() {
    return record_type("Presentation.Component.Map.Marker", [], Marker, () => [["Info", list_type(string_type)], ["Link", string_type], ["Location", Location_$reflection()]]);
}

export class InternalMarker extends Record {
    constructor(Info, Link, Position, Location) {
        super();
        this.Info = Info;
        this.Link = Link;
        this.Position = Position;
        this.Location = Location;
    }
}

export function InternalMarker_$reflection() {
    return record_type("Presentation.Component.Map.InternalMarker", [], InternalMarker, () => [["Info", list_type(string_type)], ["Link", string_type], ["Position", class_type("Fable.Core.U3`3", [class_type("Leaflet.LatLng"), class_type("Leaflet.LatLngLiteral"), tuple_type(float64_type, float64_type)])], ["Location", Location_$reflection()]]);
}

export class Circle extends Record {
    constructor(Center, Radius) {
        super();
        this.Center = Center;
        this.Radius = (Radius | 0);
    }
}

export function Circle_$reflection() {
    return record_type("Presentation.Component.Map.Circle", [], Circle, () => [["Center", Location_$reflection()], ["Radius", int32_type]]);
}

export function toLatLngExpression(location) {
    return [toNumber(location.Latitude), toNumber(location.Longitude)];
}

export function buildMarker(showPopup, showTooltip, marker) {
    const children_6 = toList(delay(() => {
        let children_2, children, props_3;
        return append(showPopup ? singleton((children_2 = singleton_1((children = singleton_1(createElement("a", {
            href: marker.Link,
            children: join(",", marker.Info),
        })), createElement("div", {
            children: reactApi.Children.toArray(Array.from(children)),
        }))), (props_3 = {
            key: marker.Link,
        }, react.createElement(Popup, props_3, ...children_2)))) : empty(), delay(() => {
            let children_4, props_6;
            return showTooltip ? singleton((children_4 = toList(delay(() => map((info) => createElement("div", {
                children: info,
            }), marker.Info))), (props_6 = {
                permanent: true,
            }, react.createElement(Tooltip, props_6, ...children_4)))) : empty();
        }));
    }));
    const props_8 = {
        position: marker.Position,
    };
    return react.createElement(Marker_1, props_8, ...children_6);
}

export const tile = (() => {
    const props_1 = {
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        attribution: "<a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a>",
    };
    return react.createElement(TileLayer, props_1);
})();

export function mapElements(showPopup, showTooltip, circles, markers) {
    return concat([singleton_1(tile), map_1((marker) => buildMarker(showPopup, showTooltip, marker), markers), map_1((x) => {
        const props_1 = keyValueList([CircleProps_Custom_433E080("center", toLatLngExpression(x.Center)), new CircleProps(17, [x.Radius])], 1);
        return react.createElement(Circle_1, props_1);
    }, circles)]);
}

export function toLatLngBoundsExpression(locations) {
    const collection = map_1((x) => [toNumber(x.Latitude), toNumber(x.Longitude)], locations);
    return Array.from(collection);
}

export function ShiftLogs_toMapMarker(shiftLog) {
    return new Marker(singleton_1(ShiftLogTypeModule_toString(shiftLog.ShiftLogType)), "", shiftLog.Location);
}

export function maps(defaultZoom, showPopup, showTooltip, circles, markers) {
    const bounds = toLatLngBoundsExpression(map_1((x) => x.Location, markers));
    const internalMarkers = map_1((x_1) => (new InternalMarker(x_1.Info, x_1.Link, toLatLngExpression(x_1.Location), x_1.Location)), markers);
    const props = toList(delay(() => append(singleton(new MapProps(42, [defaultZoom])), delay(() => append(singleton(MapProps_Style_Z6271F8AC(ofArray([new CSSProp(251, [300]), new CSSProp(253, [200]), new CSSProp(395, ["w-full"])]))), delay(() => append(singleton(new MapProps(36, [defaultArg(map_2((x_2) => x_2.Position, tryHead(internalMarkers)), [0, 0])])), delay(() => (!isEmpty(markers) ? singleton(new MapProps(34, [bounds])) : empty())))))))));
    const children = mapElements(showPopup, showTooltip, circles, internalMarkers);
    const props_1 = keyValueList(props, 1);
    return react.createElement(Map$, props_1, ...children);
}

