import { createElement } from "react";
import { createObj } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.3/String.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { ofArray } from "../../fable_modules/fable-library-js.4.19.3/List.js";

export function containerWithPadding(xPadding, yPadding, children) {
    let elems_1;
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "items-center", "justify-center"])], (elems_1 = [createElement("div", {
        className: join(" ", ["container", xPadding, yPadding, "space-y-4"]),
        children: reactApi.Children.toArray(Array.from(children)),
    })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function container(children) {
    return containerWithPadding("px-8", "py-8", children);
}

