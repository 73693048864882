import { isEmpty, choose } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/List.js";
import { some } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Option.js";
import { FSharpResult$2 } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Result.js";

function applyValidations(value, okFun, validations) {
    const validationResults = choose((tupledArg) => {
        if (tupledArg[0](value)) {
            return some(tupledArg[1](value));
        }
        else {
            return undefined;
        }
    }, validations);
    if (isEmpty(validationResults)) {
        return new FSharpResult$2(0, [okFun]);
    }
    else {
        return new FSharpResult$2(1, [validationResults]);
    }
}

export function createInternal(validations, value, result) {
    return applyValidations(value, result, validations);
}

export function runCommand(messageHandler, command, model) {
    return messageHandler(command, model);
}

