import { replicate, collect } from "../../fable_modules/fable-library-js.4.19.3/Array.js";
import { createElement } from "react";
import { createObj } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.3/String.js";
import { singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { reactApi } from "../../fable_modules/Feliz.ChartJS.0.8.12/../Feliz.2.8.0/Interop.fs.js";
import { doughnut as doughnut_1 } from "../../fable_modules/Feliz.ChartJS.0.8.12/./Interop.fs.js";
import { reactApi as reactApi_1 } from "../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { ofArray } from "../../fable_modules/fable-library-js.4.19.3/List.js";

export function repeatAllElements(repeatCount, arr) {
    return Array.from(collect((elem) => replicate(repeatCount, elem), arr));
}

export const borderColors = ["rgba(75, 192, 192, 1)", "rgba(255, 159, 64, 1)", "rgba(153, 102, 255, 1)", "rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)"];

export const backgroundColors = ["rgba(75, 192, 192, 0.2)", "rgba(255, 159, 64, 0.2)", "rgba(153, 102, 255, 0.2)", "rgba(255, 99, 132, 0.2)", "rgba(54, 162, 235, 0.2)", "rgba(255, 206, 86, 0.2)"];

export function doughnut(titleText, labels, data, paramBorderColors, paramBackgroundColors) {
    let elems, props_9;
    return createElement("div", createObj(ofArray([["className", join(" ", ["overflow-x-auto", "w-full", "p-2", "shadow-xl", "mt-6"])], (elems = [(props_9 = [["options", {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: true,
                text: titleText,
            },
            datalabels: {
                display: true,
                align: "bottom",
                borderRadius: 3,
                color: "red",
                backgroundColor: "green",
            },
        },
    }], ["data", {
        labels: labels,
        datasets: [createObj(toList(delay(() => append(singleton(["label", "# of Votes"]), delay(() => append((paramBorderColors.length === 0) ? singleton(["borderColor", borderColors]) : singleton(["borderColor", paramBorderColors]), delay(() => append(singleton(["borderWidth", 1]), delay(() => append((paramBackgroundColors.length === 0) ? singleton(["backgroundColor", backgroundColors]) : singleton(["backgroundColor", paramBackgroundColors]), delay(() => append(singleton(["data", data]), delay(() => singleton(["datalabels", {
            anchor: "end",
        }]))))))))))))))],
    }]], reactApi.createElement(doughnut_1, createObj(props_9)))], ["children", reactApi_1.Children.toArray(Array.from(elems))])])));
}

