import { Union, Record } from "../../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { class_type, tuple_type, union_type, string_type, list_type, record_type, bool_type } from "../../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { AccommodationTypeModule_toString, EnglishProficiencyModule_toString, ResidencyStatusModule_toString, AccommodationTypeModule_getLookupValues, EnglishProficiencyModule_getLookupValues, AccommodationTypeModule_ofString, EnglishProficiencyModule_ofString, Skill_$reflection } from "../../../../../Logos.Shared/Domain/Entity/User.fs.js";
import { SkillDtoModule_toSkill, UserReportDto_$reflection } from "../../../../../Logos.Shared/Domain/Dto/User.fs.js";
import { Busy, Busy_$reflection } from "../../../SharedView.fs.js";
import { FSharpResult$2 } from "../../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { DataFormat, String_splitWord, DataFormatPayload$1_$reflection, DataFormat_$reflection } from "../../../../../Logos.Shared/Util.fs.js";
import { cmdOfAsync } from "../../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { getAllSkills } from "../../../../AppDomain/UseCase/Skill.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../../Infrastructure/AppRoot.fs.js";
import { empty, choose, singleton as singleton_1, ofArray, map } from "../../../../fable_modules/fable-library-js.4.19.3/List.js";
import { getUserReportData } from "../../../../AppDomain/UseCase/User.fs.js";
import { int32ToString, createObj, equals } from "../../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Cmd_none } from "../../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { HttpUtil_saveByteArray } from "../../../../AppDomain/Util.fs.js";
import { get_UTF8 } from "../../../../fable_modules/fable-library-js.4.19.3/Encoding.js";
import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../../../Component/ViewContainer.fs.js";
import { Daisy_progressState, Daisy_error, Daisy_h4 } from "../../../Component/Component.fs.js";
import { reactApi } from "../../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { TitleSize, viewBox } from "../../../Component/ViewBox.fs.js";
import { join } from "../../../../fable_modules/fable-library-js.4.19.3/String.js";
import { Helpers_combineClasses } from "../../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { map as map_1, delay, toList } from "../../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { defaultArg } from "../../../../fable_modules/fable-library-js.4.19.3/Option.js";

export class SkillVM extends Record {
    constructor(IsChecked, Skill) {
        super();
        this.IsChecked = IsChecked;
        this.Skill = Skill;
    }
}

export function SkillVM_$reflection() {
    return record_type("Presentation.Pages.WorkMate.Report.UserListReport.SkillVM", [], SkillVM, () => [["IsChecked", bool_type], ["Skill", Skill_$reflection()]]);
}

export function SkillVMModule_ofSkill(x) {
    return new SkillVM(false, x);
}

export function SkillVMModule_toSkill(x) {
    return new SkillVM(false, x);
}

export class State extends Record {
    constructor(Skills, EnglishProficiency, AccommodationType, UserReportData, IsBusy, Errors) {
        super();
        this.Skills = Skills;
        this.EnglishProficiency = EnglishProficiency;
        this.AccommodationType = AccommodationType;
        this.UserReportData = UserReportData;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.WorkMate.Report.UserListReport.State", [], State, () => [["Skills", list_type(SkillVM_$reflection())], ["EnglishProficiency", string_type], ["AccommodationType", string_type], ["UserReportData", list_type(UserReportDto_$reflection())], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetSkillsResponse", "SetSelectedSkill", "SetEnglishProficiency", "SetAccommodationType", "GetUsers", "GetUsersResponse", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.WorkMate.Report.UserListReport.Msg", [], Msg, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(SkillVM_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", list_type(SkillVM_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [["Item", tuple_type(bool_type, Skill_$reflection())]], [["Item", string_type]], [["Item", string_type]], [["Item", DataFormat_$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [DataFormatPayload$1_$reflection(list_type(UserReportDto_$reflection())), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", DataFormatPayload$1_$reflection(list_type(UserReportDto_$reflection()))]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Exception")]]]);
}

export function getSkillsCmd() {
    return cmdOfAsync((Item) => (new Msg(0, [Item])), (Item_1) => (new Msg(6, [Item_1])), () => singleton.Delay(() => {
        const input = getAllSkills(appRoot.SkillService, AppRootModule_getSelectedOrgId());
        return singleton.Bind(input, (x$0027) => {
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [map((arg) => SkillVMModule_ofSkill(SkillDtoModule_toSkill(arg)), input_2.fields[0])])));
            return singleton.Return(value);
        });
    }));
}

export function getUsersCmd(responseDataFormat, state) {
    return cmdOfAsync((Item) => (new Msg(5, [Item])), (Item_1) => (new Msg(6, [Item_1])), () => singleton.Delay(() => {
        let englishProficiencies;
        const matchValue = EnglishProficiencyModule_ofString(state.EnglishProficiency) | 0;
        englishProficiencies = ((matchValue === 0) ? ofArray([0, 1, 2, 3]) : singleton_1(matchValue));
        let accommodationTypes;
        const matchValue_1 = AccommodationTypeModule_ofString(state.AccommodationType) | 0;
        accommodationTypes = ((matchValue_1 === 0) ? ofArray([0, 3, 4, 1, 2]) : singleton_1(matchValue_1));
        return getUserReportData(appRoot.UserService, responseDataFormat, AppRootModule_getSelectedOrgId(), choose((x_2) => {
            if (x_2.IsChecked) {
                return x_2.Skill.Id;
            }
            else {
                return undefined;
            }
        }, state.Skills), englishProficiencies, accommodationTypes);
    }));
}

export function init() {
    return [new State(empty(), "", "", empty(), new Busy(1, []), empty()), getSkillsCmd()];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [new State(map((x) => {
                if (equals(x.Skill, msg.fields[0][1])) {
                    return new SkillVM(msg.fields[0][0], x.Skill);
                }
                else {
                    return x;
                }
            }, state.Skills), state.EnglishProficiency, state.AccommodationType, state.UserReportData, state.IsBusy, state.Errors), Cmd_none()];
        case 2:
            return [new State(state.Skills, msg.fields[0], state.AccommodationType, state.UserReportData, state.IsBusy, state.Errors), Cmd_none()];
        case 3:
            return [new State(state.Skills, state.EnglishProficiency, msg.fields[0], state.UserReportData, state.IsBusy, state.Errors), Cmd_none()];
        case 4:
            return [new State(state.Skills, state.EnglishProficiency, state.AccommodationType, state.UserReportData, new Busy(1, []), empty()), getUsersCmd(msg.fields[0], state)];
        case 5:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Skills, state.EnglishProficiency, state.AccommodationType, state.UserReportData, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                switch (msg.fields[0].fields[0].tag) {
                    case 1: {
                        HttpUtil_saveByteArray(get_UTF8().getBytes(msg.fields[0].fields[0].fields[0]), "UserReport.csv", "text/csv");
                        return [new State(state.Skills, state.EnglishProficiency, state.AccommodationType, state.UserReportData, new Busy(0, []), state.Errors), Cmd_none()];
                    }
                    case 2: {
                        HttpUtil_saveByteArray(msg.fields[0].fields[0].fields[0], "UserReport.pdf", "application/pdf");
                        return [new State(state.Skills, state.EnglishProficiency, state.AccommodationType, state.UserReportData, new Busy(0, []), state.Errors), Cmd_none()];
                    }
                    default:
                        return [new State(state.Skills, state.EnglishProficiency, state.AccommodationType, msg.fields[0].fields[0].fields[0], new Busy(0, []), state.Errors), Cmd_none()];
                }
            }
        case 6:
            return [new State(state.Skills, state.EnglishProficiency, state.AccommodationType, state.UserReportData, new Busy(0, []), singleton_1(msg.fields[0].message)), Cmd_none()];
        default:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Skills, state.EnglishProficiency, state.AccommodationType, state.UserReportData, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(msg.fields[0].fields[0], state.EnglishProficiency, state.AccommodationType, state.UserReportData, new Busy(0, []), state.Errors), Cmd_none()];
            }
    }
}

export function IndexView() {
    let children, children_2, elems_8, children_8, children_5, elems_3, children_14, children_11, elems_5, children_20, children_17, elems_7, elems_9, elems_11, elems_10, children_26, children_24, children_30;
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return container([(children = singleton_1(Daisy_h4("User List Report")), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    })), Daisy_error(state_1.Errors), (children_2 = singleton_1(createElement(Daisy_progressState, {
        busy: state_1.IsBusy,
    })), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), viewBox("Search Criteria", new TitleSize(0, []), ofArray([createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "flex-wrap", "gap-x-4"])], (elems_8 = [(children_8 = ofArray([(children_5 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Skills",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_5)),
    })), createElement("div", createObj(ofArray([["className", join(" ", ["w-full", "h-32", "overflow-auto", "box-content", "border-2", "rounded"])], (elems_3 = map((x) => {
        let elems_2;
        return createElement("div", createObj(ofArray([["className", join(" ", ["m-2", "flex", "items-center"])], (elems_2 = [createElement("input", createObj(Helpers_combineClasses("checkbox", ofArray([["type", "checkbox"], ["className", "checkbox-xs"], ["className", join(" ", ["mr-2"])], ["checked", x.IsChecked], ["onChange", (ev) => {
            dispatch(new Msg(1, [[ev.target.checked, x.Skill]]));
        }]])))), createElement("span", {
            className: "label-text",
            children: x.Skill.Name,
        })], ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
    }, state_1.Skills), ["children", reactApi.Children.toArray(Array.from(elems_3))])])))]), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_8)),
    })), (children_14 = ofArray([(children_11 = singleton_1(createElement("span", {
        className: "label-text",
        children: "English proficiency",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_11)),
    })), createElement("select", createObj(Helpers_combineClasses("select", ofArray([["className", "select-bordered"], ["className", "select-sm"], ["placeholder", "English proficiency"], ["value", state_1.EnglishProficiency], ["onChange", (ev_1) => {
        dispatch(new Msg(2, [ev_1.target.value]));
    }], (elems_5 = toList(delay(() => map_1((case$) => createElement("option", {
        value: case$,
        children: String_splitWord(case$),
    }), EnglishProficiencyModule_getLookupValues()))), ["children", reactApi.Children.toArray(Array.from(elems_5))])]))))]), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_14)),
    })), (children_20 = ofArray([(children_17 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Accommodation type",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_17)),
    })), createElement("select", createObj(Helpers_combineClasses("select", ofArray([["className", "select-bordered"], ["className", "select-sm"], ["placeholder", "Accommodation type"], ["value", state_1.AccommodationType], ["onChange", (ev_2) => {
        dispatch(new Msg(3, [ev_2.target.value]));
    }], (elems_7 = toList(delay(() => map_1((case$_1) => createElement("option", {
        value: case$_1,
        children: String_splitWord(case$_1),
    }), AccommodationTypeModule_getLookupValues()))), ["children", reactApi.Children.toArray(Array.from(elems_7))])]))))]), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_20)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_8))])]))), createElement("div", {
        className: join(" ", ["mt-4"]),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "flex-wrap", "gap-x-2"])], (elems_9 = [createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-sm"], ["className", "btn-primary"], ["children", "Refresh"], ["onClick", (_arg_2) => {
        dispatch(new Msg(4, [new DataFormat(0, [])]));
    }]])))), createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-sm"], ["className", "btn-primary"], ["children", "Export CSV"], ["onClick", (_arg_3) => {
        dispatch(new Msg(4, [new DataFormat(1, [])]));
    }]]))))], ["children", reactApi.Children.toArray(Array.from(elems_9))])])))])), createElement("div", createObj(ofArray([["className", join(" ", ["mt-4"])], (elems_11 = [createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], (elems_10 = [(children_26 = singleton_1((children_24 = ofArray([createElement("th", {
        className: join(" ", ["px-4", "py-2"]),
        children: "Name",
    }), createElement("th", {
        className: join(" ", ["px-4", "py-2"]),
        children: "Skills",
    }), createElement("th", {
        className: join(" ", ["px-4", "py-2"]),
        children: "Residency status",
    }), createElement("th", {
        className: join(" ", ["px-4", "py-2"]),
        children: "Driver\'s license",
    }), createElement("th", {
        className: join(" ", ["px-4", "py-2"]),
        children: "Age",
    }), createElement("th", {
        className: join(" ", ["px-4", "py-2"]),
        children: "English proficiency",
    }), createElement("th", {
        className: join(" ", ["px-4", "py-2"]),
        children: "Accommodation type",
    })]), createElement("tr", {
        children: reactApi.Children.toArray(Array.from(children_24)),
    }))), createElement("thead", {
        children: reactApi.Children.toArray(Array.from(children_26)),
    })), (children_30 = toList(delay(() => map_1((user) => {
        const children_28 = ofArray([createElement("td", {
            className: join(" ", ["border", "px-4", "py-2"]),
            children: `${user.FirstName} ${user.MiddleName} ${user.LastName}`,
        }), createElement("td", {
            className: join(" ", ["border", "px-4", "py-2"]),
            children: join(", ", user.UserSkills),
        }), createElement("td", {
            className: join(" ", ["border", "px-4", "py-2"]),
            children: String_splitWord(ResidencyStatusModule_toString(user.ResidencyStatus)),
        }), createElement("td", {
            className: join(" ", ["border", "px-4", "py-2"]),
            children: `${user.DriverLicenseCountry} ${user.DriverLicenseNumber}`,
        }), createElement("td", {
            className: join(" ", ["border", "px-4", "py-2", "text-right"]),
            children: int32ToString(defaultArg(user.Age, 0)),
        }), createElement("td", {
            className: join(" ", ["border", "px-4", "py-2"]),
            children: String_splitWord(EnglishProficiencyModule_toString(user.EnglishProficiency)),
        }), createElement("td", {
            className: join(" ", ["border", "px-4", "py-2"]),
            children: String_splitWord(AccommodationTypeModule_toString(user.AccommodationType)),
        })]);
        return createElement("tr", {
            children: reactApi.Children.toArray(Array.from(children_28)),
        });
    }, state_1.UserReportData))), createElement("tbody", {
        children: reactApi.Children.toArray(Array.from(children_30)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_10))])]))))], ["children", reactApi.Children.toArray(Array.from(elems_11))])])))]);
}

