import { toString, Union, Record } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type, class_type, record_type, list_type, lambda_type, unit_type, bool_type, string_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Address, Address_$reflection } from "../../../Logos.Shared/Domain/Entity/Address.fs.js";
import { singleton, empty, ofArray } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { createObj } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { empty as empty_1, singleton as singleton_1, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { Helpers_combineClasses } from "../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { join } from "../../fable_modules/fable-library-js.4.19.3/String.js";
import { Marker, maps } from "./Map.fs.js";
import { Location } from "../../../Logos.Shared/Domain/Entity/Location.fs.js";

export class State extends Record {
    constructor(UnitNumber, Street, Suburb, City, Country, PostCode, IsActive, Address, OnAddressChange, Errors) {
        super();
        this.UnitNumber = UnitNumber;
        this.Street = Street;
        this.Suburb = Suburb;
        this.City = City;
        this.Country = Country;
        this.PostCode = PostCode;
        this.IsActive = IsActive;
        this.Address = Address;
        this.OnAddressChange = OnAddressChange;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Component.Address.State", [], State, () => [["UnitNumber", string_type], ["Street", string_type], ["Suburb", string_type], ["City", string_type], ["Country", string_type], ["PostCode", string_type], ["IsActive", bool_type], ["Address", Address_$reflection()], ["OnAddressChange", lambda_type(Address_$reflection(), unit_type)], ["Errors", list_type(string_type)]]);
}

export function StateModule_toAddress(state) {
    return new Address(state.Address.Id, state.Address.ParentId, state.UnitNumber, state.Street, state.Suburb, state.City, state.Country, state.PostCode, state.Address.Latitude, state.Address.Longitude, state.IsActive);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetUnitNumber", "SetStreet", "SetSuburb", "SetCity", "SetCountry", "SetPostCode", "SetIsActive", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Component.Address.Msg", [], Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", class_type("System.Exception")]]]);
}

export function toAddressString(address) {
    return ofArray([`${address.UnitNumber} ${address.Street}`, address.Suburb, address.City, address.Country, address.PostCode]);
}

export function init(address, onAddressChange) {
    return [new State(address.UnitNumber, address.Street, address.Suburb, address.City, address.Country, address.PostCode, address.IsActive, address, onAddressChange, empty()), Cmd_none()];
}

export function update(msg, state) {
    const updateStateAndParentCmd = (updatedState) => {
        state.OnAddressChange(StateModule_toAddress(updatedState));
        return [updatedState, Cmd_none()];
    };
    switch (msg.tag) {
        case 1:
            return updateStateAndParentCmd(new State(state.UnitNumber, msg.fields[0], state.Suburb, state.City, state.Country, state.PostCode, state.IsActive, state.Address, state.OnAddressChange, state.Errors));
        case 2:
            return updateStateAndParentCmd(new State(state.UnitNumber, state.Street, msg.fields[0], state.City, state.Country, state.PostCode, state.IsActive, state.Address, state.OnAddressChange, state.Errors));
        case 3:
            return updateStateAndParentCmd(new State(state.UnitNumber, state.Street, state.Suburb, msg.fields[0], state.Country, state.PostCode, state.IsActive, state.Address, state.OnAddressChange, state.Errors));
        case 4:
            return updateStateAndParentCmd(new State(state.UnitNumber, state.Street, state.Suburb, state.City, msg.fields[0], state.PostCode, state.IsActive, state.Address, state.OnAddressChange, state.Errors));
        case 5:
            return updateStateAndParentCmd(new State(state.UnitNumber, state.Street, state.Suburb, state.City, state.Country, msg.fields[0], state.IsActive, state.Address, state.OnAddressChange, state.Errors));
        case 6:
            return updateStateAndParentCmd(new State(state.UnitNumber, state.Street, state.Suburb, state.City, state.Country, state.PostCode, msg.fields[0], state.Address, state.OnAddressChange, state.Errors));
        case 7:
            return [new State(state.UnitNumber, state.Street, state.Suburb, state.City, state.Country, state.PostCode, state.IsActive, state.Address, state.OnAddressChange, singleton(toString(msg.fields[0]))), Cmd_none()];
        default:
            return updateStateAndParentCmd(new State(msg.fields[0], state.Street, state.Suburb, state.City, state.Country, state.PostCode, state.IsActive, state.Address, state.OnAddressChange, state.Errors));
    }
}

export function IndexView(props) {
    let elems_12;
    let patternInput;
    const init_1 = init(props.Address, props.OnAddressChange);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.Address]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return createElement("div", createObj(singleton((elems_12 = toList(delay(() => {
        let children_3, children_1;
        return append(singleton_1((children_3 = ofArray([(children_1 = singleton(createElement("span", {
            className: "label-text",
            children: "Unit Number",
        })), createElement("label", {
            className: "label",
            children: reactApi.Children.toArray(Array.from(children_1)),
        })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", state_1.UnitNumber], ["placeholder", "Unit Number"], ["onChange", (ev) => {
            dispatch(new Msg(0, [ev.target.value]));
        }]]))))]), createElement("div", {
            className: "form-control",
            children: reactApi.Children.toArray(Array.from(children_3)),
        }))), delay(() => {
            let children_8, children_6;
            return append(singleton_1((children_8 = ofArray([(children_6 = singleton(createElement("span", {
                className: "label-text",
                children: "Street",
            })), createElement("label", {
                className: "label",
                children: reactApi.Children.toArray(Array.from(children_6)),
            })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", state_1.Street], ["placeholder", "Street"], ["onChange", (ev_1) => {
                dispatch(new Msg(1, [ev_1.target.value]));
            }]]))))]), createElement("div", {
                className: "form-control",
                children: reactApi.Children.toArray(Array.from(children_8)),
            }))), delay(() => {
                let children_13, children_11;
                return append(singleton_1((children_13 = ofArray([(children_11 = singleton(createElement("span", {
                    className: "label-text",
                    children: "Suburb",
                })), createElement("label", {
                    className: "label",
                    children: reactApi.Children.toArray(Array.from(children_11)),
                })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", state_1.Suburb], ["placeholder", "Suburb"], ["onChange", (ev_2) => {
                    dispatch(new Msg(2, [ev_2.target.value]));
                }]]))))]), createElement("div", {
                    className: "form-control",
                    children: reactApi.Children.toArray(Array.from(children_13)),
                }))), delay(() => {
                    let children_18, children_16;
                    return append(singleton_1((children_18 = ofArray([(children_16 = singleton(createElement("span", {
                        className: "label-text",
                        children: "City",
                    })), createElement("label", {
                        className: "label",
                        children: reactApi.Children.toArray(Array.from(children_16)),
                    })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", state_1.City], ["placeholder", "City"], ["onChange", (ev_3) => {
                        dispatch(new Msg(3, [ev_3.target.value]));
                    }]]))))]), createElement("div", {
                        className: "form-control",
                        children: reactApi.Children.toArray(Array.from(children_18)),
                    }))), delay(() => {
                        let children_23, children_21;
                        return append(singleton_1((children_23 = ofArray([(children_21 = singleton(createElement("span", {
                            className: "label-text",
                            children: "Country",
                        })), createElement("label", {
                            className: "label",
                            children: reactApi.Children.toArray(Array.from(children_21)),
                        })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", state_1.Country], ["placeholder", "Country"], ["onChange", (ev_4) => {
                            dispatch(new Msg(4, [ev_4.target.value]));
                        }]]))))]), createElement("div", {
                            className: "form-control",
                            children: reactApi.Children.toArray(Array.from(children_23)),
                        }))), delay(() => {
                            let children_26;
                            return append(props.ShowIsActive ? singleton_1((children_26 = ofArray([createElement("span", {
                                className: "label-text",
                                children: "Active",
                            }), createElement("input", createObj(Helpers_combineClasses("checkbox", ofArray([["type", "checkbox"], ["className", "checkbox-sm"], ["checked", state_1.IsActive], ["onChange", (ev_5) => {
                                dispatch(new Msg(6, [ev_5.target.checked]));
                            }]]))))]), createElement("div", {
                                className: "form-control",
                                children: reactApi.Children.toArray(Array.from(children_26)),
                            }))) : empty_1(), delay(() => {
                                let elems_11;
                                return props.ShowMap ? singleton_1(createElement("div", createObj(Helpers_combineClasses("form-control", ofArray([["className", join(" ", ["mt-4"])], (elems_11 = [maps(5, false, true, props.Circles, singleton(new Marker(toAddressString(state_1.Address), "", new Location(state_1.Address.Latitude, state_1.Address.Longitude, undefined))))], ["children", reactApi.Children.toArray(Array.from(elems_11))])]))))) : empty_1();
                            }));
                        }));
                    }));
                }));
            }));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_12))]))));
}

