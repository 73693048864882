import { createElement } from "react";
import React from "react";
import { createObj } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.3/String.js";
import { Daisy_h5, Daisy_h2 } from "../Component/Component.fs.js";
import { Helpers_combineClasses } from "../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { ofArray, singleton, empty } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/./Interop.fs.js";

export function IndexView() {
    let elems_3, elems_2, value_4, elems, children_1, value_7, children_3, value_9, elems_1, children_5, value_13, children_7, value_15, value_20, value_22;
    return createElement("div", createObj(ofArray([["className", join(" ", ["items-center", "justify-center", "py-8", "px-4", "sm:px-6", "lg:px-40"])], (elems_3 = [createElement("div", createObj(ofArray([["className", join(" ", ["space-y-4", "w-full", "box-content", "bg-white", "border", "border-2", "py-12", "px-4", "sm:px-6", "lg:px-8"])], (elems_2 = [Daisy_h2("10thDoor Solutions Limited - Privacy Policy"), createElement("div", createObj(Helpers_combineClasses("divider", empty()))), Daisy_h2("Welcome"), createElement("p", {
        children: "Welcome to our Privacy policy.",
    }), createElement("p", createObj(singleton((value_4 = "For our WorkMate app, we collect user location and shift details to enhance the \n                            functionality and user experience. By using WorkMate, you agree to allow the collection \n                            and utilization of this data for reporting purposes. We prioritize the security and \n                            confidentiality of your information, and we are committed to ensuring that your privacy is \n                            protected.", ["children", value_4])))), Daisy_h5("What Information We Collect:"), createElement("ol", createObj(ofArray([["className", join(" ", ["list-disc"])], (elems = [(children_1 = singleton(createElement("p", createObj(singleton((value_7 = "User Location: WorkMate may collect your device\'s location to provide location-based features and \n                                        improve the accuracy of shift-related information.", ["children", value_7]))))), createElement("li", {
        children: reactApi.Children.toArray(Array.from(children_1)),
    })), (children_3 = singleton(createElement("p", createObj(singleton((value_9 = "Shift Details: We collect details related to your work shifts, such as start and end times, \n                                        to facilitate efficient scheduling and reporting.", ["children", value_9]))))), createElement("li", {
        children: reactApi.Children.toArray(Array.from(children_3)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems))])]))), Daisy_h5("How We Use Your Data:"), createElement("ol", createObj(ofArray([["className", join(" ", ["list-disc"])], (elems_1 = [(children_5 = singleton(createElement("p", createObj(singleton((value_13 = "Reporting: The collected data is utilized to generate reports for users and administrators, \n                                        offering insights into work patterns and optimizing workforce management.", ["children", value_13]))))), createElement("li", {
        children: reactApi.Children.toArray(Array.from(children_5)),
    })), (children_7 = singleton(createElement("p", createObj(singleton((value_15 = "Enhancing User Experience: Location data is used to provide relevant features and improve \n                                        the overall user experience within the app.", ["children", value_15]))))), createElement("li", {
        children: reactApi.Children.toArray(Array.from(children_7)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))), Daisy_h5("Your Consent:"), createElement("p", {
        children: "By using WorkMate, you consent to the collection and use of your data as outlined in this policy.",
    }), Daisy_h5("Security:"), createElement("p", createObj(singleton((value_20 = "We are committed to ensuring that your information is secure. To prevent \n                            unauthorised access or disclosure, we have put in place suitable physical, electronic, \n                            and managerial procedures to safeguard and secure the information we collect.", ["children", value_20])))), Daisy_h5("Changes to the Privacy Policy:"), createElement("p", createObj(singleton((value_22 = "We may update this policy from time to time by posting a new version on this page. \n                            You should check this page occasionally to ensure you are happy with any changes.", ["children", value_22])))), createElement("p", {
        children: "Please visit this page regularly to check the updated privacy policy.",
    })], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])));
}

