import { singleton } from "../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { singleton as singleton_1, isEmpty } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library-js.4.19.3/Result.js";

export function searchUsers(userService, selectedOrgId, userSearchDetails, pagination) {
    return singleton.Delay(() => userService.SearchUsers(selectedOrgId, userSearchDetails, pagination));
}

export function getUser(userService, selectedOrgId, userId) {
    return singleton.Delay(() => userService.GetUser(selectedOrgId, userId));
}

export function addUserToOrganisation(userService, selectedOrgId, user) {
    return singleton.Delay(() => userService.AddUserToOrganisation(selectedOrgId, user));
}

export function updateUser(userService, selectedOrgId, user, userSkillsToDelete) {
    return singleton.Delay(() => userService.UpdateUser(selectedOrgId, user, userSkillsToDelete));
}

export function inviteUser(userService, selectedOrgId, email, product) {
    return singleton.Delay(() => userService.InviteUser(selectedOrgId, email, product));
}

export function getInviteDetails(userService, selectedOrgId, inviteId) {
    return singleton.Delay(() => userService.GetInviteDetails(selectedOrgId, inviteId));
}

export function acceptInvite(userService, selectedOrgId, inviteId) {
    return singleton.Delay(() => userService.AcceptInvite(selectedOrgId, inviteId));
}

export function getUserMemberships(userService, selectedOrgId) {
    return singleton.Delay(() => userService.GetUserMemberships(selectedOrgId));
}

export function getUserMeasurementSystem(userService, selectedOrgId) {
    return singleton.Delay(() => userService.GetUserMeasurementSystem(selectedOrgId));
}

export function checkUserNameExists(userService, username) {
    return singleton.Delay(() => userService.CheckUsernameExists(username));
}

export function getUserReportData(userService, responseDataFormat, selectedOrgId, skills, englishProficiencies, accommodationTypes) {
    return singleton.Delay(() => {
        let asyncResult_1;
        const value_1_1 = !isEmpty(skills) ? (new FSharpResult$2(0, [undefined])) : (new FSharpResult$2(1, [singleton_1("At least one skill must be selected.")]));
        asyncResult_1 = singleton.Return(value_1_1);
        return singleton.Bind(asyncResult_1, (input_1) => {
            const input_2 = input_1;
            if (input_2.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
            }
            else {
                return userService.GetUserReportData(responseDataFormat, selectedOrgId, skills, englishProficiencies, accommodationTypes);
            }
        });
    });
}

export function getUserInviteSent(userService, selectedOrgId, invitee, product) {
    return singleton.Delay(() => userService.GetUserInviteSent(selectedOrgId, invitee, product));
}

export function resetPassword(userService, selectedOrgId, invitee) {
    return singleton.Delay(() => userService.ResendTempPassword(selectedOrgId, invitee));
}

export function getUserImportCsv(userService, selectedOrgId) {
    return singleton.Delay(() => userService.GetUserImportCsv(selectedOrgId));
}

export function uploadUserImportCsv(userService, selectedOrgId, file) {
    return singleton.Delay(() => userService.UploadUserImportCsv(selectedOrgId, file));
}

export function sendPendingImportUserEmails(userService, selectedOrgId) {
    return singleton.Delay(() => userService.SendPendingImportUserEmails(selectedOrgId));
}

export function getUserSubscriptionDetails(userService, selectedOrgId, userId) {
    return singleton.Delay(() => userService.GetUserSubscriptionDetails(selectedOrgId, userId));
}

