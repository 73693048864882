import { Union, Record } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { list_type, decimal_type, enum_type, int32_type, option_type, getUnionCases, name, union_type, record_type, string_type, class_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { singleton, ofArray } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/List.js";
import { sortBy } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Array.js";
import { comparePrimitives } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Util.js";
import { FSharpResult$2 } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Result.js";
import { op_Multiply, op_Division, fromParts } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Decimal.js";
import { DistanceUnit_$reflection } from "./MeasurementUnits.fs.js";
import { Year_$reflection } from "./Year.fs.js";
import { Location_$reflection } from "./Location.fs.js";

export class VehicleLogTag extends Record {
    constructor(Id, Name) {
        super();
        this.Id = Id;
        this.Name = Name;
    }
}

export function VehicleLogTag_$reflection() {
    return record_type("Domain.Entity.LogMaster.VehicleLogTag", [], VehicleLogTag, () => [["Id", class_type("System.Guid")], ["Name", string_type]]);
}

export class VehicleLogTagType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Personal", "Work", "Custom"];
    }
}

export function VehicleLogTagType_$reflection() {
    return union_type("Domain.Entity.LogMaster.VehicleLogTagType", [], VehicleLogTagType, () => [[["Item", class_type("System.Guid")]], [["Item", class_type("System.Guid")]], [["Item", class_type("System.Guid")]]]);
}

export const vehicleLogTagWork = "b1d6eb47-8456-471f-9144-7710c3f186ee";

export const vehicleLogTagPersonal = "1d6ca77c-7f4e-4c30-aea9-d9fb9a03f006";

export const predefinedVehicleLogTags = ofArray([new VehicleLogTag(vehicleLogTagPersonal, "Personal"), new VehicleLogTag(vehicleLogTagWork, "Work")]);

export function VehicleLogTagModule_toId(_arg) {
    switch (_arg.tag) {
        case 1:
            return _arg.fields[0];
        case 2:
            return _arg.fields[0];
        default:
            return _arg.fields[0];
    }
}

export function VehicleLogTagModule_ofId(id) {
    if (id === vehicleLogTagPersonal) {
        return new VehicleLogTagType(0, [id]);
    }
    else if (id === vehicleLogTagWork) {
        return new VehicleLogTagType(1, [id]);
    }
    else {
        return new VehicleLogTagType(2, [id]);
    }
}

export class FuelType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Petrol", "Diesel", "Electric", "Hydrogen", "Gas"];
    }
}

export function FuelType_$reflection() {
    return union_type("Domain.Entity.LogMaster.FuelType", [], FuelType, () => [[], [], [], [], []]);
}

export const FuelTypeModule_fuelTypes = sortBy(name, getUnionCases(FuelType_$reflection(), 0), {
    Compare: comparePrimitives,
});

export function FuelTypeModule_toInt(_arg) {
    switch (_arg.tag) {
        case 1:
            return 1;
        case 2:
            return 2;
        case 3:
            return 3;
        case 4:
            return 4;
        default:
            return 0;
    }
}

export function FuelTypeModule_ofInt(_arg) {
    switch (_arg) {
        case 0:
            return new FuelType(0, []);
        case 1:
            return new FuelType(1, []);
        case 2:
            return new FuelType(2, []);
        case 3:
            return new FuelType(3, []);
        case 4:
            return new FuelType(4, []);
        default:
            throw new Error(`Invalid FuelType ${_arg}.`);
    }
}

export function FuelTypeModule_toString(_arg) {
    switch (_arg.tag) {
        case 1:
            return "Diesel";
        case 2:
            return "Electric";
        case 3:
            return "Hydrogen";
        case 4:
            return "Gas";
        default:
            return "Petrol";
    }
}

export function FuelTypeModule_ofString(value) {
    switch (value) {
        case "Petrol":
            return new FSharpResult$2(0, [new FuelType(0, [])]);
        case "Diesel":
            return new FSharpResult$2(0, [new FuelType(1, [])]);
        case "Electric":
            return new FSharpResult$2(0, [new FuelType(2, [])]);
        case "Hydrogen":
            return new FSharpResult$2(0, [new FuelType(3, [])]);
        default:
            return new FSharpResult$2(1, [singleton("Invalid FuelType string.")]);
    }
}

export class FuelUnit extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Litre", "USGallon", "Kwh", "Kg"];
    }
}

export function FuelUnit_$reflection() {
    return union_type("Domain.Entity.LogMaster.FuelUnit", [], FuelUnit, () => [[], [], [], []]);
}

export const FuelUnitModule_fuelUnits = sortBy(name, getUnionCases(FuelUnit_$reflection(), 0), {
    Compare: comparePrimitives,
});

export function FuelUnitModule_toInt(_arg) {
    switch (_arg.tag) {
        case 1:
            return 1;
        case 2:
            return 2;
        case 3:
            return 3;
        default:
            return 0;
    }
}

export function FuelUnitModule_ofInt(_arg) {
    switch (_arg) {
        case 0:
            return new FuelUnit(0, []);
        case 1:
            return new FuelUnit(1, []);
        case 2:
            return new FuelUnit(2, []);
        case 3:
            return new FuelUnit(3, []);
        default:
            throw new Error(`Invalid FuelUnit ${_arg}.`);
    }
}

export function FuelUnitModule_toString(_arg) {
    switch (_arg.tag) {
        case 1:
            return "USGallon";
        case 2:
            return "Kwh";
        case 3:
            return "Kg";
        default:
            return "Litre";
    }
}

export function FuelUnitModule_ofString(value) {
    switch (value) {
        case "Litre":
            return new FSharpResult$2(0, [new FuelUnit(0, [])]);
        case "USGallon":
            return new FSharpResult$2(0, [new FuelUnit(1, [])]);
        case "Kwh":
            return new FSharpResult$2(0, [new FuelUnit(2, [])]);
        case "Kg":
            return new FSharpResult$2(0, [new FuelUnit(3, [])]);
        default:
            return new FSharpResult$2(1, [singleton("Invalid FuelType string.")]);
    }
}

const FuelUnitModule_litersPerUSGallon = fromParts(378541, 0, 0, false, 5);

export function FuelUnitModule_litersToUSGallons(liters) {
    return op_Division(liters, FuelUnitModule_litersPerUSGallon);
}

export function FuelUnitModule_usGallonsToLiters(usGallons) {
    return op_Multiply(usGallons, FuelUnitModule_litersPerUSGallon);
}

export class VehicleStatus extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Invalid", "Active", "Inactive", "Binned"];
    }
}

export function VehicleStatus_$reflection() {
    return union_type("Domain.Entity.LogMaster.VehicleStatus", [], VehicleStatus, () => [[], [], [], []]);
}

export function VehicleStatusModule_toInt(_arg) {
    switch (_arg.tag) {
        case 1:
            return 1;
        case 2:
            return 2;
        case 3:
            return 3;
        default:
            return 0;
    }
}

export function VehicleStatusModule_ofInt(_arg) {
    switch (_arg) {
        case 1:
            return new VehicleStatus(1, []);
        case 2:
            return new VehicleStatus(2, []);
        case 3:
            return new VehicleStatus(3, []);
        default:
            return new VehicleStatus(0, []);
    }
}

export class Make extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["Make"];
    }
}

export function Make_$reflection() {
    return union_type("Domain.Entity.LogMaster.Make", [], Make, () => [[["Item", string_type]]]);
}

export function Make_value_7CC7BEEA(_arg) {
    return _arg.fields[0];
}

export class Model extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["Model"];
    }
}

export function Model_$reflection() {
    return union_type("Domain.Entity.LogMaster.Model", [], Model, () => [[["Item", string_type]]]);
}

export function Model_value_15C4E147(_arg) {
    return _arg.fields[0];
}

export class Registration extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["Registration"];
    }
}

export function Registration_$reflection() {
    return union_type("Domain.Entity.LogMaster.Registration", [], Registration, () => [[["Item", string_type]]]);
}

export function Registration_value_1CC5E859(_arg) {
    return _arg.fields[0];
}

export class Filename extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["Filename"];
    }
}

export function Filename_$reflection() {
    return union_type("Domain.Entity.LogMaster.Filename", [], Filename, () => [[["Item", string_type]]]);
}

export function Filename_value_5A6F1309(_arg) {
    return _arg.fields[0];
}

export class Vehicle extends Record {
    constructor(Id, UserId, OrgId, Make, DistanceUnit, FuelType, Model, Year, Registration, Updated, Status, PhotoFilename, ServerUpdated, FuelUnit, DefaultTag) {
        super();
        this.Id = Id;
        this.UserId = UserId;
        this.OrgId = OrgId;
        this.Make = Make;
        this.DistanceUnit = DistanceUnit;
        this.FuelType = FuelType;
        this.Model = Model;
        this.Year = Year;
        this.Registration = Registration;
        this.Updated = Updated;
        this.Status = Status;
        this.PhotoFilename = PhotoFilename;
        this.ServerUpdated = ServerUpdated;
        this.FuelUnit = FuelUnit;
        this.DefaultTag = DefaultTag;
    }
}

export function Vehicle_$reflection() {
    return record_type("Domain.Entity.LogMaster.Vehicle", [], Vehicle, () => [["Id", class_type("System.Guid")], ["UserId", class_type("System.Guid")], ["OrgId", class_type("System.Guid")], ["Make", Make_$reflection()], ["DistanceUnit", DistanceUnit_$reflection()], ["FuelType", FuelType_$reflection()], ["Model", option_type(Model_$reflection())], ["Year", option_type(Year_$reflection())], ["Registration", option_type(Registration_$reflection())], ["Updated", class_type("System.DateTime")], ["Status", VehicleStatus_$reflection()], ["PhotoFilename", Filename_$reflection()], ["ServerUpdated", class_type("System.DateTime")], ["FuelUnit", FuelUnit_$reflection()], ["DefaultTag", VehicleLogTag_$reflection()]]);
}

export class VehicleSearchDetail extends Record {
    constructor(Id, Description) {
        super();
        this.Id = Id;
        this.Description = Description;
    }
}

export function VehicleSearchDetail_$reflection() {
    return record_type("Domain.Entity.LogMaster.VehicleSearchDetail", [], VehicleSearchDetail, () => [["Id", class_type("System.Guid")], ["Description", string_type]]);
}

export class VehicleMake extends Record {
    constructor(Id, Make, Model, Registration, FuelType, DistanceUnit) {
        super();
        this.Id = Id;
        this.Make = Make;
        this.Model = Model;
        this.Registration = Registration;
        this.FuelType = FuelType;
        this.DistanceUnit = DistanceUnit;
    }
}

export function VehicleMake_$reflection() {
    return record_type("Domain.Entity.LogMaster.VehicleMake", [], VehicleMake, () => [["Id", class_type("System.Guid")], ["Make", string_type], ["Model", string_type], ["Registration", string_type], ["FuelType", FuelType_$reflection()], ["DistanceUnit", DistanceUnit_$reflection()]]);
}

export class LogPoint extends Record {
    constructor(Id, LogPointType, Note, DateTime, EndDateTime, Odo, Location, LogPointAutoFillStage, FuelQuantityFilled, FuelPriceTotal) {
        super();
        this.Id = Id;
        this.LogPointType = (LogPointType | 0);
        this.Note = Note;
        this.DateTime = DateTime;
        this.EndDateTime = EndDateTime;
        this.Odo = Odo;
        this.Location = Location;
        this.LogPointAutoFillStage = (LogPointAutoFillStage | 0);
        this.FuelQuantityFilled = FuelQuantityFilled;
        this.FuelPriceTotal = FuelPriceTotal;
    }
}

export function LogPoint_$reflection() {
    return record_type("Domain.Entity.LogMaster.LogPoint", [], LogPoint, () => [["Id", class_type("System.Guid")], ["LogPointType", enum_type("Domain.Entity.LogMaster.LogPointType", int32_type, [["Start", 0], ["Stop", 1], ["End", 2], ["FuelStop", 3]])], ["Note", string_type], ["DateTime", class_type("System.DateTime")], ["EndDateTime", option_type(class_type("System.DateTime"))], ["Odo", decimal_type], ["Location", Location_$reflection()], ["LogPointAutoFillStage", enum_type("Domain.Entity.LogMaster.LogPointAutoFillStage", int32_type, [["Initial", 0], ["Started", 1], ["Ended", 2]])], ["FuelQuantityFilled", decimal_type], ["FuelPriceTotal", decimal_type]]);
}

export class VehicleLog extends Record {
    constructor(Id, UserId, OrgId, ServerUpdated, VehicleId, Notes, status, Updated, Tag, DistanceUnit, FuelType, FuelUnit, LogPoints) {
        super();
        this.Id = Id;
        this.UserId = UserId;
        this.OrgId = OrgId;
        this.ServerUpdated = ServerUpdated;
        this.VehicleId = VehicleId;
        this.Notes = Notes;
        this.status = (status | 0);
        this.Updated = Updated;
        this.Tag = Tag;
        this.DistanceUnit = DistanceUnit;
        this.FuelType = FuelType;
        this.FuelUnit = FuelUnit;
        this.LogPoints = LogPoints;
    }
}

export function VehicleLog_$reflection() {
    return record_type("Domain.Entity.LogMaster.VehicleLog", [], VehicleLog, () => [["Id", class_type("System.Guid")], ["UserId", class_type("System.Guid")], ["OrgId", class_type("System.Guid")], ["ServerUpdated", class_type("System.DateTime")], ["VehicleId", class_type("System.Guid")], ["Notes", string_type], ["status", enum_type("Domain.Entity.LogMaster.VehicleLogStatus", int32_type, [["Open", 0], ["Finished", 1], ["Discarded", 2]])], ["Updated", class_type("System.DateTime")], ["Tag", VehicleLogTag_$reflection()], ["DistanceUnit", DistanceUnit_$reflection()], ["FuelType", FuelType_$reflection()], ["FuelUnit", FuelUnit_$reflection()], ["LogPoints", list_type(LogPoint_$reflection())]]);
}

export class FuelSummary extends Record {
    constructor(Vehicle, FuelQtyTotal, FuelAmountTotal, FuelType, FuelUnit) {
        super();
        this.Vehicle = Vehicle;
        this.FuelQtyTotal = FuelQtyTotal;
        this.FuelAmountTotal = FuelAmountTotal;
        this.FuelType = FuelType;
        this.FuelUnit = FuelUnit;
    }
}

export function FuelSummary_$reflection() {
    return record_type("Domain.Entity.LogMaster.FuelSummary", [], FuelSummary, () => [["Vehicle", string_type], ["FuelQtyTotal", decimal_type], ["FuelAmountTotal", decimal_type], ["FuelType", FuelType_$reflection()], ["FuelUnit", FuelUnit_$reflection()]]);
}

export class VehicleLogToCalculateSummary extends Record {
    constructor(Vehicle, Tag, VehicleLogId, Time, Odo, DistanceUnit) {
        super();
        this.Vehicle = Vehicle;
        this.Tag = Tag;
        this.VehicleLogId = VehicleLogId;
        this.Time = Time;
        this.Odo = Odo;
        this.DistanceUnit = DistanceUnit;
    }
}

export function VehicleLogToCalculateSummary_$reflection() {
    return record_type("Domain.Entity.LogMaster.VehicleLogToCalculateSummary", [], VehicleLogToCalculateSummary, () => [["Vehicle", string_type], ["Tag", VehicleLogTag_$reflection()], ["VehicleLogId", class_type("System.Guid")], ["Time", class_type("System.DateTime")], ["Odo", decimal_type], ["DistanceUnit", DistanceUnit_$reflection()]]);
}

export class VehicleLogSummaryByVehicle extends Record {
    constructor(Vehicle, TripCount, TotalTripDuration, TotalDistanceTraveled, DistanceUnit) {
        super();
        this.Vehicle = Vehicle;
        this.TripCount = (TripCount | 0);
        this.TotalTripDuration = TotalTripDuration;
        this.TotalDistanceTraveled = TotalDistanceTraveled;
        this.DistanceUnit = DistanceUnit;
    }
}

export function VehicleLogSummaryByVehicle_$reflection() {
    return record_type("Domain.Entity.LogMaster.VehicleLogSummaryByVehicle", [], VehicleLogSummaryByVehicle, () => [["Vehicle", string_type], ["TripCount", int32_type], ["TotalTripDuration", class_type("System.TimeSpan")], ["TotalDistanceTraveled", decimal_type], ["DistanceUnit", DistanceUnit_$reflection()]]);
}

export class VehicleLogSummaryByTag extends Record {
    constructor(Tag, TripCount, TotalTripDuration, TotalDistanceTraveled, DistanceUnit) {
        super();
        this.Tag = Tag;
        this.TripCount = (TripCount | 0);
        this.TotalTripDuration = TotalTripDuration;
        this.TotalDistanceTraveled = TotalDistanceTraveled;
        this.DistanceUnit = DistanceUnit;
    }
}

export function VehicleLogSummaryByTag_$reflection() {
    return record_type("Domain.Entity.LogMaster.VehicleLogSummaryByTag", [], VehicleLogSummaryByTag, () => [["Tag", VehicleLogTag_$reflection()], ["TripCount", int32_type], ["TotalTripDuration", class_type("System.TimeSpan")], ["TotalDistanceTraveled", decimal_type], ["DistanceUnit", DistanceUnit_$reflection()]]);
}

