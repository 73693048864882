import { Union, Record } from "../../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { WorkersAtJob_$reflection } from "../../../../../Logos.Shared/Domain/Entity/Job.fs.js";
import { class_type, union_type, record_type, string_type, list_type } from "../../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Busy, Busy_$reflection } from "../../../SharedView.fs.js";
import { FSharpResult$2 } from "../../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { getWorkersAtJobs } from "../../../../AppDomain/UseCase/Job.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../../Infrastructure/AppRoot.fs.js";
import { sumBy, ofArray, map, singleton as singleton_1, empty } from "../../../../fable_modules/fable-library-js.4.19.3/List.js";
import { Cmd_none } from "../../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { int32ToString, createObj } from "../../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { IndexViewEx } from "../../../Component/MapViewBase.fs.js";
import { Marker } from "../../../Component/Map.fs.js";
import { Helpers_combineClasses } from "../../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { join } from "../../../../fable_modules/fable-library-js.4.19.3/String.js";
import { reactApi } from "../../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { map as map_1, delay, toList } from "../../../../fable_modules/fable-library-js.4.19.3/Seq.js";

export class State extends Record {
    constructor(WorkersAtJobs, IsBusy, Errors) {
        super();
        this.WorkersAtJobs = WorkersAtJobs;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.WorkMate.WorkersAtJob.State", [], State, () => [["WorkersAtJobs", list_type(WorkersAtJob_$reflection())], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetWorkforceSkillsResult", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.WorkMate.WorkersAtJob.Msg", [], Msg, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(WorkersAtJob_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", list_type(WorkersAtJob_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Exception")]]]);
}

export function getWorkersAtJobsCmd() {
    return cmdOfAsync((Item) => (new Msg(0, [Item])), (Item_1) => (new Msg(1, [Item_1])), () => singleton.Delay(() => getWorkersAtJobs(appRoot.JobService, AppRootModule_getSelectedOrgId())));
}

export function init() {
    return [new State(empty(), new Busy(1, []), empty()), getWorkersAtJobsCmd()];
}

export function update(msg, state) {
    if (msg.tag === 1) {
        return [new State(state.WorkersAtJobs, new Busy(0, []), singleton_1(msg.fields[0].message)), Cmd_none()];
    }
    else if (msg.fields[0].tag === 1) {
        return [new State(state.WorkersAtJobs, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
    }
    else {
        return [new State(msg.fields[0].fields[0], new Busy(0, []), state.Errors), Cmd_none()];
    }
}

export function IndexView() {
    let elems_5, elems_4, children_2, children, children_4, children_6, elems_3, elems_2;
    const state_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init, update, (_arg, _arg_1) => {
    }), undefined, [])[0];
    return createElement("div", createObj(ofArray([["className", "flex flex-col"], (elems_5 = [createElement(IndexViewEx, {
        title: "Workers at Jobs",
        errors: state_1.Errors,
        isBusy: state_1.IsBusy,
        markers: map((x) => (new Marker(singleton_1(`${x.JobName} - ${x.WorkerCount} workers`), "", x.JobLocation)), state_1.WorkersAtJobs),
        elements: [createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["flex", "w-full"])], (elems_4 = [(children_2 = singleton_1((children = ofArray([createElement("th", {
            width: 15 + "%",
            children: "Job",
        }), createElement("th", {
            width: 30 + "%",
            className: join(" ", ["text-right"]),
            children: "Workers",
        })]), createElement("tr", {
            children: reactApi.Children.toArray(Array.from(children)),
        }))), createElement("thead", {
            children: reactApi.Children.toArray(Array.from(children_2)),
        })), (children_4 = toList(delay(() => map_1((data) => {
            let elems_1, elems;
            return createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top", "gap-4", "hover"])], (elems_1 = [createElement("td", {
                children: [data.JobName],
            }), createElement("td", createObj(ofArray([["className", join(" ", ["text-right"])], (elems = [int32ToString(data.WorkerCount)], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
        }, state_1.WorkersAtJobs))), createElement("tbody", {
            children: reactApi.Children.toArray(Array.from(children_4)),
        })), (children_6 = singleton_1(createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top", "gap-4"])], (elems_3 = [createElement("td", {
            children: ["Total"],
        }), createElement("td", createObj(ofArray([["className", join(" ", ["text-right"])], (elems_2 = [int32ToString(sumBy((x_1) => x_1.WorkerCount, state_1.WorkersAtJobs, {
            GetZero: () => 0,
            Add: (x_2, y) => (x_2 + y),
        }))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))), createElement("tfoot", {
            children: reactApi.Children.toArray(Array.from(children_6)),
        }))], ["children", reactApi.Children.toArray(Array.from(elems_4))])]))))],
    })], ["children", reactApi.Children.toArray(Array.from(elems_5))])])));
}

