import { Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { tuple_type, union_type, record_type, string_type, option_type, list_type, anonRecord_type, bool_type, class_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { TimesheetRun_$reflection } from "../../../../Logos.Shared/Domain/Entity/Shift.fs.js";
import { PayFrequencyModule_payFrequencyDays, PayFrequency, PayFrequencyModule_isDateRangeValid, OrganisationOptions_$reflection } from "../../../../Logos.Shared/Domain/Entity/Organisation.fs.js";
import { Busy, Busy_$reflection } from "../../SharedView.fs.js";
import { getDefaultConfig, DateRange, DateRange_$reflection } from "../../Component/ReactTailwindDatePicker.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { addDays as addDays_1, now as now_1, toLocalTime, fromDate } from "../../../fable_modules/fable-library-js.4.19.3/DateOffset.js";
import { now as now_2, parse, addDays, addSeconds, date as date_1 } from "../../../fable_modules/fable-library-js.4.19.3/Date.js";
import { ofArray, empty, filter, map, singleton as singleton_1 } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { create } from "../../../../Logos.Shared/Domain/Entity/DateRangeOffset.fs.js";
import { generatePaymentRun, searchTimesheetsForPaymentRun } from "../../../AppDomain/UseCase/Timesheet.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../Infrastructure/AppRoot.fs.js";
import { TimesheetRunDtoModule_toTimesheetRun } from "../../../../Logos.Shared/Domain/Dto/Shift.fs.js";
import { getOrganisation } from "../../../AppDomain/UseCase/Organisation.fs.js";
import { OrganisationOptionsDtoModule_toOrganisationOptions } from "../../../../Logos.Shared/Domain/Dto/Organisation.fs.js";
import { defaultArg } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../../Component/ViewContainer.fs.js";
import { map as map_1, empty as empty_1, singleton as singleton_2, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { Daisy_info, Daisy_error, Daisy_h4 } from "../../Component/Component.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import react_tailwindcss_datepicker from "react-tailwindcss-datepicker";
import { createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { DateTime_dateTimeToStringWithDayName } from "../../../AppDomain/Util.fs.js";
import { AddressModule_toAddressString } from "../../../../Logos.Shared/Domain/Entity/Address.fs.js";

export class State extends Record {
    constructor(PayPeriodStart, PayPeriodFinish, ApprovedTimesheetRuns, OrgOptions, IsPaymentRunGenerated, GeneratedPaymentRunNumber, IsBusy, Errors) {
        super();
        this.PayPeriodStart = PayPeriodStart;
        this.PayPeriodFinish = PayPeriodFinish;
        this.ApprovedTimesheetRuns = ApprovedTimesheetRuns;
        this.OrgOptions = OrgOptions;
        this.IsPaymentRunGenerated = IsPaymentRunGenerated;
        this.GeneratedPaymentRunNumber = GeneratedPaymentRunNumber;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.TimesheetPaymentRun.State", [], State, () => [["PayPeriodStart", class_type("System.DateTimeOffset")], ["PayPeriodFinish", class_type("System.DateTimeOffset")], ["ApprovedTimesheetRuns", list_type(anonRecord_type(["IsChecked", bool_type], ["TimesheetRun", TimesheetRun_$reflection()]))], ["OrgOptions", option_type(OrganisationOptions_$reflection())], ["IsPaymentRunGenerated", bool_type], ["GeneratedPaymentRunNumber", string_type], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetPayPeriod", "GetTimesheetRunsResponse", "GetOrgOptionsResponse", "SetSelectedTimesheetRun", "GeneratePaymentRun", "GeneratePaymentRunResponse", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.TimesheetPaymentRun.Msg", [], Msg, () => [[["Item", DateRange_$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(anonRecord_type(["IsChecked", bool_type], ["TimesheetRun", TimesheetRun_$reflection()])), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", list_type(anonRecord_type(["IsChecked", bool_type], ["TimesheetRun", TimesheetRun_$reflection()]))]], [["ErrorValue", list_type(string_type)]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [option_type(OrganisationOptions_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", option_type(OrganisationOptions_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [["Item", tuple_type(bool_type, TimesheetRun_$reflection())]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [string_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", string_type]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Exception")]]]);
}

export function getTimesheetRunsCmd(orgPayFrequency, payPeriodStart, payPeriodFinish) {
    return cmdOfAsync((Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(6, [Item_1])), () => singleton.Delay(() => {
        const payPeriodStart_1 = fromDate(date_1(toLocalTime(payPeriodStart)));
        const payPeriodFinish_1 = fromDate(addSeconds(addDays(date_1(toLocalTime(payPeriodFinish)), 1), -1));
        let asyncResult_3;
        const value_1 = PayFrequencyModule_isDateRangeValid(orgPayFrequency, payPeriodStart_1, payPeriodFinish_1);
        asyncResult_3 = singleton.Return(value_1);
        return singleton.Bind(asyncResult_3, (input_1_4) => {
            const input_12 = input_1_4;
            if (input_12.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_12.fields[0]]));
            }
            else {
                let asyncResult_2;
                const value_1_1 = input_12.fields[0] ? (new FSharpResult$2(0, [undefined])) : (new FSharpResult$2(1, [singleton_1("Pay period is not valid for selected pay frequency.")]));
                asyncResult_2 = singleton.Return(value_1_1);
                return singleton.Bind(asyncResult_2, (input_1_3) => {
                    const input_9 = input_1_3;
                    if (input_9.tag === 1) {
                        return singleton.Return(new FSharpResult$2(1, [input_9.fields[0]]));
                    }
                    else {
                        let asyncResult_1;
                        const value_1_2 = create(payPeriodStart_1, payPeriodFinish_1);
                        asyncResult_1 = singleton.Return(value_1_2);
                        return singleton.Bind(asyncResult_1, (input_1_2) => {
                            const input_6 = input_1_2;
                            if (input_6.tag === 1) {
                                return singleton.Return(new FSharpResult$2(1, [input_6.fields[0]]));
                            }
                            else {
                                const input = searchTimesheetsForPaymentRun(appRoot.ShiftService, AppRootModule_getSelectedOrgId(), input_6.fields[0]);
                                return singleton.Bind(input, (x$0027) => {
                                    let value_6;
                                    const input_2 = x$0027;
                                    value_6 = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [map((x) => ({
                                        IsChecked: false,
                                        TimesheetRun: TimesheetRunDtoModule_toTimesheetRun(x),
                                    }), input_2.fields[0])])));
                                    return singleton.Return(value_6);
                                });
                            }
                        });
                    }
                });
            }
        });
    }));
}

export function getOrgOptionsCmd() {
    return cmdOfAsync((Item) => (new Msg(2, [Item])), (Item_1) => (new Msg(6, [Item_1])), () => singleton.Delay(() => {
        const input_2 = getOrganisation(appRoot.OrganisationService, AppRootModule_getSelectedOrgId());
        return singleton.Bind(input_2, (x$0027) => {
            let input_1;
            let value;
            const input_4 = x$0027;
            value = ((input_4.tag === 1) ? (new FSharpResult$2(1, [input_4.fields[0]])) : (new FSharpResult$2(0, [(input_1 = input_4.fields[0], (input_1 == null) ? undefined : OrganisationOptionsDtoModule_toOrganisationOptions(input_1.OrganisationOptions))])));
            return singleton.Return(value);
        });
    }));
}

export function generatePaymentRunCmd(state) {
    return cmdOfAsync((Item) => (new Msg(5, [Item])), (Item_1) => (new Msg(6, [Item_1])), () => singleton.Delay(() => {
        let input_1;
        const timesheetRuns = map((x_1) => x_1.TimesheetRun.Id, filter((x) => x.IsChecked, state.ApprovedTimesheetRuns));
        let asyncResult_3;
        const value_1_1 = PayFrequencyModule_isDateRangeValid(defaultArg((input_1 = state.OrgOptions, (input_1 == null) ? undefined : input_1.PayFrequency), new PayFrequency(0, [])), state.PayPeriodStart, state.PayPeriodFinish);
        asyncResult_3 = singleton.Return(value_1_1);
        return singleton.Bind(asyncResult_3, (input_1_3) => {
            const input_9 = input_1_3;
            if (input_9.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_9.fields[0]]));
            }
            else {
                let asyncResult_2;
                const value_1_2 = input_9.fields[0] ? (new FSharpResult$2(0, [undefined])) : (new FSharpResult$2(1, [singleton_1("Pay period is not valid for selected pay frequency.")]));
                asyncResult_2 = singleton.Return(value_1_2);
                return singleton.Bind(asyncResult_2, (input_1_2) => {
                    const input_6 = input_1_2;
                    if (input_6.tag === 1) {
                        return singleton.Return(new FSharpResult$2(1, [input_6.fields[0]]));
                    }
                    else {
                        let asyncResult_1;
                        const value_1_3 = create(state.PayPeriodStart, state.PayPeriodFinish);
                        asyncResult_1 = singleton.Return(value_1_3);
                        return singleton.Bind(asyncResult_1, (input_1_1) => {
                            const input_3 = input_1_1;
                            if (input_3.tag === 1) {
                                return singleton.Return(new FSharpResult$2(1, [input_3.fields[0]]));
                            }
                            else {
                                return generatePaymentRun(appRoot.ShiftService, AppRootModule_getSelectedOrgId(), input_3.fields[0], timesheetRuns);
                            }
                        });
                    }
                });
            }
        });
    }));
}

export function init() {
    let now;
    return [(now = now_1(), new State(addDays_1(now, -6), now, empty(), undefined, false, "", new Busy(1, []), empty())), getOrgOptionsCmd()];
}

export function update(msg, state) {
    let input_3, input_5, input_1;
    switch (msg.tag) {
        case 1:
            if (msg.fields[0].tag === 1) {
                return [new State(state.PayPeriodStart, state.PayPeriodFinish, state.ApprovedTimesheetRuns, state.OrgOptions, state.IsPaymentRunGenerated, state.GeneratedPaymentRunNumber, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.PayPeriodStart, state.PayPeriodFinish, msg.fields[0].fields[0], state.OrgOptions, state.IsPaymentRunGenerated, state.GeneratedPaymentRunNumber, new Busy(0, []), state.Errors), Cmd_none()];
            }
        case 2:
            if (msg.fields[0].tag === 1) {
                return [new State(state.PayPeriodStart, state.PayPeriodFinish, state.ApprovedTimesheetRuns, state.OrgOptions, state.IsPaymentRunGenerated, state.GeneratedPaymentRunNumber, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                const now = now_1();
                const updatedState_1 = new State(addDays_1(now, -defaultArg((input_3 = msg.fields[0].fields[0], (input_3 == null) ? undefined : (PayFrequencyModule_payFrequencyDays(input_3.PayFrequency) - 1)), 6)), now, state.ApprovedTimesheetRuns, msg.fields[0].fields[0], state.IsPaymentRunGenerated, state.GeneratedPaymentRunNumber, new Busy(0, []), state.Errors);
                return [updatedState_1, defaultArg((input_5 = msg.fields[0].fields[0], (input_5 == null) ? undefined : getTimesheetRunsCmd(input_5.PayFrequency, updatedState_1.PayPeriodStart, updatedState_1.PayPeriodFinish)), Cmd_none())];
            }
        case 3:
            return [new State(state.PayPeriodStart, state.PayPeriodFinish, map((x_7) => {
                if (x_7.TimesheetRun.Id === msg.fields[0][1].Id) {
                    return {
                        IsChecked: msg.fields[0][0],
                        TimesheetRun: msg.fields[0][1],
                    };
                }
                else {
                    return x_7;
                }
            }, state.ApprovedTimesheetRuns), state.OrgOptions, state.IsPaymentRunGenerated, state.GeneratedPaymentRunNumber, state.IsBusy, state.Errors), Cmd_none()];
        case 4:
            return [new State(state.PayPeriodStart, state.PayPeriodFinish, state.ApprovedTimesheetRuns, state.OrgOptions, state.IsPaymentRunGenerated, state.GeneratedPaymentRunNumber, new Busy(1, []), empty()), generatePaymentRunCmd(state)];
        case 5:
            if (msg.fields[0].tag === 1) {
                return [new State(state.PayPeriodStart, state.PayPeriodFinish, state.ApprovedTimesheetRuns, state.OrgOptions, state.IsPaymentRunGenerated, state.GeneratedPaymentRunNumber, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.PayPeriodStart, state.PayPeriodFinish, state.ApprovedTimesheetRuns, state.OrgOptions, true, msg.fields[0].fields[0], new Busy(0, []), state.Errors), Cmd_none()];
            }
        case 6:
            return [new State(state.PayPeriodStart, state.PayPeriodFinish, state.ApprovedTimesheetRuns, state.OrgOptions, state.IsPaymentRunGenerated, state.GeneratedPaymentRunNumber, new Busy(0, []), singleton_1(msg.fields[0].message)), Cmd_none()];
        default: {
            const updatedState = new State(fromDate(msg.fields[0].startDate), fromDate(msg.fields[0].endDate), state.ApprovedTimesheetRuns, state.OrgOptions, state.IsPaymentRunGenerated, state.GeneratedPaymentRunNumber, state.IsBusy, empty());
            return [updatedState, defaultArg((input_1 = state.OrgOptions, (input_1 == null) ? undefined : getTimesheetRunsCmd(input_1.PayFrequency, updatedState.PayPeriodStart, updatedState.PayPeriodFinish)), Cmd_none())];
        }
    }
}

export function IndexView() {
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return container(toList(delay(() => {
        let children;
        return append(singleton_2((children = singleton_1(Daisy_h4("Generate Payroll")), createElement("div", {
            children: reactApi.Children.toArray(Array.from(children)),
        }))), delay(() => append(singleton_2(Daisy_error(state_1.Errors)), delay(() => append(state_1.IsPaymentRunGenerated ? singleton_2(Daisy_info(singleton_1(`Payroll generated successfully. New Payment Run number is ${state_1.GeneratedPaymentRunNumber}.`))) : empty_1(), delay(() => {
            let children_6, children_3, props_4;
            return append(singleton_2((children_6 = ofArray([(children_3 = singleton_1(createElement("span", {
                className: "label-text",
                children: "Pay Period",
            })), createElement("label", {
                className: "label",
                children: reactApi.Children.toArray(Array.from(children_3)),
            })), (props_4 = {
                value: new DateRange(toLocalTime(state_1.PayPeriodStart), toLocalTime(state_1.PayPeriodFinish)),
                displayFormat: "DD/MM/YYYY",
                onChange: (x) => {
                    dispatch(new Msg(0, [new DateRange(parse(x.startDate), parse(x.endDate))]));
                },
                showShortcuts: true,
                configs: getDefaultConfig(now_2()),
            }, react.createElement(react_tailwindcss_datepicker, props_4))]), createElement("div", {
                className: "form-control",
                children: reactApi.Children.toArray(Array.from(children_6)),
            }))), delay(() => {
                let children_25, children_9, elems_9, children_13, children_11, children_22;
                return append(singleton_2((children_25 = ofArray([(children_9 = singleton_1(createElement("span", {
                    className: "label-text",
                    children: "Timesheet Runs to process Payment for",
                })), createElement("label", {
                    className: "label",
                    children: reactApi.Children.toArray(Array.from(children_9)),
                })), createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["w-full", "overflow-auto", "box-content", "border-2", "rounded"])], (elems_9 = [(children_13 = singleton_1((children_11 = ofArray([createElement("th", {
                    width: 20 + "%",
                    children: "Timesheet Run Number",
                }), createElement("th", {
                    width: 20 + "%",
                    children: "Timesheet Run Date",
                }), createElement("th", {
                    width: 60 + "%",
                })]), createElement("tr", {
                    children: reactApi.Children.toArray(Array.from(children_11)),
                }))), createElement("thead", {
                    children: reactApi.Children.toArray(Array.from(children_13)),
                })), (children_22 = toList(delay(() => map_1((timesheetRun) => {
                    let elems_8, elems_4, elems_3, elems_7, elems_6, children_17, children_15, children_19;
                    return createElement("tr", createObj(singleton_1((elems_8 = [createElement("td", createObj(ofArray([["className", join(" ", ["align-text-top"])], (elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "items-center"])], (elems_3 = [createElement("input", createObj(Helpers_combineClasses("checkbox", ofArray([["type", "checkbox"], ["className", "checkbox-md"], ["readOnly", state_1.IsPaymentRunGenerated], ["checked", timesheetRun.IsChecked], ["onChange", (ev) => {
                        dispatch(new Msg(3, [[ev.target.checked, timesheetRun.TimesheetRun]]));
                    }]])))), createElement("div", {
                        className: join(" ", ["ml-2", "align-text-top"]),
                        children: timesheetRun.TimesheetRun.RunNumber,
                    })], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("td", {
                        className: join(" ", ["align-text-top"]),
                        children: DateTime_dateTimeToStringWithDayName(timesheetRun.TimesheetRun.Created.DateCreated),
                    }), createElement("td", createObj(ofArray([["className", join(" ", ["align-text-top"])], (elems_7 = [createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["flex", "w-full", "col-span-6"])], (elems_6 = [(children_17 = singleton_1((children_15 = ofArray([createElement("th", {
                        width: 40 + "%",
                        children: "Job",
                    }), createElement("th", {
                        width: 60 + "%",
                        children: "Address",
                    })]), createElement("tr", {
                        children: reactApi.Children.toArray(Array.from(children_15)),
                    }))), createElement("thead", {
                        children: reactApi.Children.toArray(Array.from(children_17)),
                    })), (children_19 = toList(delay(() => map_1((job) => {
                        let elems_5;
                        return createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top"])], (elems_5 = [createElement("td", {
                            children: job.Title,
                        }), createElement("td", {
                            children: AddressModule_toAddressString(job.Address),
                        })], ["children", reactApi.Children.toArray(Array.from(elems_5))])])));
                    }, timesheetRun.TimesheetRun.Jobs))), createElement("tbody", {
                        children: reactApi.Children.toArray(Array.from(children_19)),
                    }))], ["children", reactApi.Children.toArray(Array.from(elems_6))])]))))], ["children", reactApi.Children.toArray(Array.from(elems_7))])])))], ["children", reactApi.Children.toArray(Array.from(elems_8))]))));
                }, state_1.ApprovedTimesheetRuns))), createElement("tbody", {
                    children: reactApi.Children.toArray(Array.from(children_22)),
                }))], ["children", reactApi.Children.toArray(Array.from(elems_9))])]))))]), createElement("div", {
                    className: "form-control",
                    children: reactApi.Children.toArray(Array.from(children_25)),
                }))), delay(() => singleton_2(createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-sm"], ["className", "btn-primary"], ["children", "Generate Payroll"], ["disabled", state_1.IsPaymentRunGenerated], ["onClick", (_arg_2) => {
                    dispatch(new Msg(4, []));
                }]])))))));
            }));
        }))))));
    })));
}

