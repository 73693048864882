import { Union, Record } from "../../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { class_type, union_type, tuple_type, record_type, list_type, anonRecord_type, bool_type, string_type } from "../../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { WorkerJobs_$reflection, JobDetail_$reflection } from "../../../../../Logos.Shared/Domain/Entity/Job.fs.js";
import { Busy, Busy_$reflection } from "../../../SharedView.fs.js";
import { FSharpResult$2 } from "../../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../../Infrastructure/AppRoot.fs.js";
import { cmdOfAsync } from "../../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { LocalUserDataModule_getUserDetails } from "../../../../AppDomain/SystemService.fs.js";
import { getWorkerJobs, getJobsForWorkerJobs } from "../../../../AppDomain/UseCase/Job.fs.js";
import { ofArray, forAll, empty, singleton as singleton_1, length, filter, map, sortBy } from "../../../../fable_modules/fable-library-js.4.19.3/List.js";
import { createObj, comparePrimitives } from "../../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Cmd_none } from "../../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createElement } from "react";
import React from "react";
import { Helpers_combineClasses } from "../../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { join } from "../../../../fable_modules/fable-library-js.4.19.3/String.js";
import { Daisy_progressState, Daisy_error, Daisy_h4, alignCellTextTop } from "../../../Component/Component.fs.js";
import { reactApi } from "../../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { map as map_1, delay, toList } from "../../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { DateTime_dateToStringWithDayName } from "../../../../AppDomain/Util.fs.js";
import { toLocalTime } from "../../../../fable_modules/fable-library-js.4.19.3/DateOffset.js";
import { React_useElmish_Z6C327F2E } from "../../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../../../Component/ViewContainer.fs.js";

export class State extends Record {
    constructor(SelectedJobId, Jobs, WorkerJobs, IsSelectAllChecked, IsBusy, Errors) {
        super();
        this.SelectedJobId = SelectedJobId;
        this.Jobs = Jobs;
        this.WorkerJobs = WorkerJobs;
        this.IsSelectAllChecked = IsSelectAllChecked;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.WorkMate.Report.WorkerJobs.State", [], State, () => [["SelectedJobId", string_type], ["Jobs", list_type(anonRecord_type(["IsChecked", bool_type], ["JobDetail", JobDetail_$reflection()]))], ["WorkerJobs", list_type(WorkerJobs_$reflection())], ["IsSelectAllChecked", bool_type], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetSelectedJob", "SetSelectAll", "GetJobs", "GetJobsResponse", "GetWorkerJobs", "GetWorkerJobsResponse", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.WorkMate.Report.WorkerJobs.Msg", [], Msg, () => [[["Item", tuple_type(bool_type, JobDetail_$reflection())]], [["Item", bool_type]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(JobDetail_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", list_type(JobDetail_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(WorkerJobs_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", list_type(WorkerJobs_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Exception")]]]);
}

export function getUserData() {
    const matchValue = appRoot.SystemService.Load("UserData");
    if (matchValue.tag === 1) {
        return undefined;
    }
    else {
        return matchValue.fields[0];
    }
}

export function getJobsCmd() {
    return cmdOfAsync((Item) => (new Msg(3, [Item])), (Item_1) => (new Msg(6, [Item_1])), () => singleton.Delay(() => {
        const userDataDetails = LocalUserDataModule_getUserDetails(getUserData());
        const input = getJobsForWorkerJobs(appRoot.JobService, AppRootModule_getSelectedOrgId(), userDataDetails.UserRole);
        return singleton.Bind(input, (x$0027) => {
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [sortBy((_arg_1) => _arg_1.Title, input_2.fields[0], {
                Compare: comparePrimitives,
            })])));
            return singleton.Return(value);
        });
    }));
}

export function getWorkerJobsCmd(state) {
    return cmdOfAsync((Item) => (new Msg(5, [Item])), (Item_1) => (new Msg(6, [Item_1])), () => singleton.Delay(() => {
        const jobIds = map((x_1) => x_1.JobDetail.Id, filter((x) => x.IsChecked, state.Jobs));
        let asyncResult_1;
        const value_1_1 = !(length(jobIds) === 0) ? (new FSharpResult$2(0, [undefined])) : (new FSharpResult$2(1, [singleton_1("Please select at least one job to get worker jobs for.")]));
        asyncResult_1 = singleton.Return(value_1_1);
        return singleton.Bind(asyncResult_1, (input_1_2) => {
            const input_6 = input_1_2;
            if (input_6.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_6.fields[0]]));
            }
            else {
                const input = getWorkerJobs(appRoot.JobService, AppRootModule_getSelectedOrgId(), jobIds);
                return singleton.Bind(input, (x$0027) => {
                    let value_3;
                    const input_2 = x$0027;
                    value_3 = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [sortBy((_arg_2) => _arg_2.WorkerName, input_2.fields[0], {
                        Compare: comparePrimitives,
                    })])));
                    return singleton.Return(value_3);
                });
            }
        });
    }));
}

export function init() {
    return [new State("", empty(), empty(), true, new Busy(0, []), empty()), singleton_1((dispatch) => {
        dispatch(new Msg(2, []));
    })];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [new State(state.SelectedJobId, map((x_2) => ({
                IsChecked: msg.fields[0],
                JobDetail: x_2.JobDetail,
            }), state.Jobs), state.WorkerJobs, msg.fields[0], state.IsBusy, state.Errors), Cmd_none()];
        case 2:
            return [new State(state.SelectedJobId, state.Jobs, state.WorkerJobs, state.IsSelectAllChecked, new Busy(1, []), empty()), getJobsCmd()];
        case 3:
            if (msg.fields[0].tag === 1) {
                return [new State(state.SelectedJobId, state.Jobs, state.WorkerJobs, state.IsSelectAllChecked, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.SelectedJobId, map((x_3) => ({
                    IsChecked: true,
                    JobDetail: x_3,
                }), msg.fields[0].fields[0]), state.WorkerJobs, state.IsSelectAllChecked, new Busy(0, []), state.Errors), Cmd_none()];
            }
        case 4: {
            const updatedState = new State(state.SelectedJobId, state.Jobs, state.WorkerJobs, state.IsSelectAllChecked, new Busy(1, []), empty());
            return [updatedState, getWorkerJobsCmd(updatedState)];
        }
        case 5:
            if (msg.fields[0].tag === 1) {
                return [new State(state.SelectedJobId, state.Jobs, state.WorkerJobs, state.IsSelectAllChecked, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.SelectedJobId, state.Jobs, msg.fields[0].fields[0], state.IsSelectAllChecked, new Busy(0, []), state.Errors), Cmd_none()];
            }
        case 6:
            return [new State(state.SelectedJobId, state.Jobs, state.WorkerJobs, state.IsSelectAllChecked, state.IsBusy, singleton_1(msg.fields[0].message)), Cmd_none()];
        default: {
            const updatedJobs = map((x) => {
                if (x.JobDetail.Id === msg.fields[0][1].Id) {
                    return {
                        IsChecked: msg.fields[0][0],
                        JobDetail: x.JobDetail,
                    };
                }
                else {
                    return x;
                }
            }, state.Jobs);
            return [new State(state.SelectedJobId, updatedJobs, state.WorkerJobs, forAll((x_1) => x_1.IsChecked, updatedJobs), state.IsBusy, state.Errors), Cmd_none()];
        }
    }
}

export function workerJobsTable(state) {
    let elems_4, children_2, children, elems_3;
    return createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-xs"], ["className", "table-zebra"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["w-full"])], (elems_4 = [(children_2 = singleton_1((children = ofArray([createElement("th", createObj(ofArray([alignCellTextTop, ["children", "Worker"]]))), createElement("th", createObj(ofArray([alignCellTextTop, ["children", "Jobs"]])))]), createElement("tr", {
        children: reactApi.Children.toArray(Array.from(children)),
    }))), createElement("thead", {
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), createElement("tbody", createObj(singleton_1((elems_3 = toList(delay(() => map_1((workerJob) => {
        let children_11, elems_2, elems_1, children_6, children_4, elems;
        const children_13 = ofArray([createElement("td", {
            children: workerJob.WorkerName,
        }), (children_11 = singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "flex-wrap", "gap-x-2"])], (elems_2 = [createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-xs"], ["className", "table-zebra"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["w-full"])], (elems_1 = [(children_6 = singleton_1((children_4 = ofArray([createElement("th", createObj(ofArray([alignCellTextTop, ["children", "Job"]]))), createElement("th", createObj(ofArray([alignCellTextTop, ["children", "Start Date"]]))), createElement("th", createObj(ofArray([alignCellTextTop, ["children", "End Date"]])))]), createElement("tr", {
            children: reactApi.Children.toArray(Array.from(children_4)),
        }))), createElement("thead", {
            children: reactApi.Children.toArray(Array.from(children_6)),
        })), createElement("tbody", createObj(singleton_1((elems = toList(delay(() => map_1((job) => {
            const children_8 = ofArray([createElement("td", {
                children: job.Title,
            }), createElement("td", {
                children: DateTime_dateToStringWithDayName(toLocalTime(job.StartDate)),
            }), createElement("td", {
                children: DateTime_dateToStringWithDayName(toLocalTime(job.EndDate)),
            })]);
            return createElement("tr", {
                children: reactApi.Children.toArray(Array.from(children_8)),
            });
        }, sortBy((_arg) => _arg.Title, workerJob.Jobs, {
            Compare: comparePrimitives,
        })))), ["children", reactApi.Children.toArray(Array.from(elems))]))))], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))), createElement("td", {
            children: reactApi.Children.toArray(Array.from(children_11)),
        }))]);
        return createElement("tr", {
            children: reactApi.Children.toArray(Array.from(children_13)),
        });
    }, state.WorkerJobs))), ["children", reactApi.Children.toArray(Array.from(elems_3))]))))], ["children", reactApi.Children.toArray(Array.from(elems_4))])]))));
}

export function IndexView() {
    let children, children_2, children_9, children_5, elems_2, elems_4, elems_6;
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return container([(children = singleton_1(Daisy_h4("Worker Jobs report")), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    })), Daisy_error(state_1.Errors), (children_2 = singleton_1(createElement(Daisy_progressState, {
        busy: state_1.IsBusy,
    })), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), (children_9 = ofArray([(children_5 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Jobs to get workers for",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_5)),
    })), createElement("div", createObj(ofArray([["className", join(" ", ["m-2", "flex", "items-center"])], (elems_2 = [createElement("input", createObj(Helpers_combineClasses("checkbox", ofArray([["type", "checkbox"], ["className", "checkbox-sm"], ["className", join(" ", ["mr-2"])], ["checked", state_1.IsSelectAllChecked], ["onChange", (ev) => {
        dispatch(new Msg(1, [ev.target.checked]));
    }]])))), createElement("span", {
        className: "label-text",
        children: "Select All",
    })], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["w-full", "h-64", "overflow-auto", "box-content", "border-2", "rounded"])], (elems_4 = map((x) => {
        let elems_3;
        return createElement("div", createObj(ofArray([["className", join(" ", ["m-2", "flex", "items-center"])], (elems_3 = [createElement("input", createObj(Helpers_combineClasses("checkbox", ofArray([["type", "checkbox"], ["className", "checkbox-sm"], ["className", join(" ", ["mr-2"])], ["checked", x.IsChecked], ["onChange", (ev_1) => {
            dispatch(new Msg(0, [[ev_1.target.checked, x.JobDetail]]));
        }]])))), createElement("span", {
            className: "label-text",
            children: x.JobDetail.Title,
        })], ["children", reactApi.Children.toArray(Array.from(elems_3))])])));
    }, state_1.Jobs), ["children", reactApi.Children.toArray(Array.from(elems_4))])])))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_9)),
    })), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "flex-wrap", "gap-x-2"])], (elems_6 = [createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-sm"], ["className", "btn-primary"], ["children", "Refresh"], ["onClick", (_arg_2) => {
        dispatch(new Msg(4, []));
    }]]))))], ["children", reactApi.Children.toArray(Array.from(elems_6))])]))), workerJobsTable(state_1)]);
}

