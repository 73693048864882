import { singleton } from "../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { create } from "../../../Logos.Shared/Domain/Entity/Email.fs.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library-js.4.19.3/Result.js";
import { map, toArray, head, length, isEmpty, singleton as singleton_1 } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { create as create_1 } from "../../../Logos.Shared/Domain/Entity/Password.fs.js";
import { SignupDto } from "../../../Logos.Shared/Domain/Dto/User.fs.js";
import { LocalUserData, BrowserData } from "../SystemService.fs.js";
import { UserRole } from "../../../Logos.Shared/Domain/Entity/User.fs.js";
import { OrganisationDetailDtoModule_toOrganisation } from "../../../Logos.Shared/Domain/Dto/Organisation.fs.js";
import { map as map_1 } from "../../fable_modules/fable-library-js.4.19.3/Array.js";
import { stripeProductId } from "../../../Logos.Shared/Domain/Entity/Stripe.fs.js";
import { ResultCE_result, ResultCE_ResultBuilder__Zero } from "../../fable_modules/FsToolkit.ErrorHandling.4.16.0/./ResultCE.fs.js";

export function getOneTimeSignupCode(username, authService) {
    return singleton.Delay(() => {
        let asyncResult_1;
        const value_1 = create(username);
        asyncResult_1 = singleton.Return(value_1);
        return singleton.Bind(asyncResult_1, (input_1) => {
            const input_2 = input_1;
            if (input_2.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
            }
            else {
                return authService.GetOneTimeSignupCode(input_2.fields[0]);
            }
        });
    });
}

export function getOneTimeResetCode(authService, username) {
    return singleton.Delay(() => {
        let asyncResult_1;
        const value_1 = create(username);
        asyncResult_1 = singleton.Return(value_1);
        return singleton.Bind(asyncResult_1, (input_1) => {
            const input_2 = input_1;
            if (input_2.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
            }
            else {
                return authService.GetOneTimeResetCode(input_2.fields[0]);
            }
        });
    });
}

export function resetPassword(authService, resetPassword_1) {
    return singleton.Delay(() => authService.ResetPassword(resetPassword_1));
}

export function signup(username, password, confirmPassword, oneTimeSignupCode, authService) {
    return singleton.Delay(() => {
        let asyncResult_4;
        const value_1_1 = (password === confirmPassword) ? (new FSharpResult$2(0, [undefined])) : (new FSharpResult$2(1, [singleton_1("Passwords must match.")]));
        asyncResult_4 = singleton.Return(value_1_1);
        return singleton.Bind(asyncResult_4, (input_1_3) => {
            const input_11 = input_1_3;
            if (input_11.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_11.fields[0]]));
            }
            else {
                let asyncResult_3;
                const value_1_2 = create(username);
                asyncResult_3 = singleton.Return(value_1_2);
                return singleton.Bind(asyncResult_3, (input_1_2) => {
                    const input_8 = input_1_2;
                    if (input_8.tag === 1) {
                        return singleton.Return(new FSharpResult$2(1, [input_8.fields[0]]));
                    }
                    else {
                        let asyncResult_2;
                        const value_1_3 = create_1(password);
                        asyncResult_2 = singleton.Return(value_1_3);
                        return singleton.Bind(asyncResult_2, (input_1_1) => {
                            const input_5 = input_1_1;
                            if (input_5.tag === 1) {
                                return singleton.Return(new FSharpResult$2(1, [input_5.fields[0]]));
                            }
                            else {
                                let asyncResult_1;
                                const value_1_4 = create_1(confirmPassword);
                                asyncResult_1 = singleton.Return(value_1_4);
                                return singleton.Bind(asyncResult_1, (input_1) => {
                                    const input_2 = input_1;
                                    if (input_2.tag === 1) {
                                        return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                                    }
                                    else {
                                        const signupDto = new SignupDto(input_8.fields[0], oneTimeSignupCode, input_5.fields[0], input_2.fields[0]);
                                        return authService.Signup(signupDto);
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });
    });
}

export function saveUserData(systemService, userData) {
    return singleton.Delay(() => {
        const value_1 = systemService.Save(new BrowserData(userData));
        return singleton.Return(value_1);
    });
}

export function signIn(username, password, systemService, authService, organisationService) {
    return singleton.Delay(() => {
        let asyncResult_4;
        const value_1 = create(username);
        asyncResult_4 = singleton.Return(value_1);
        return singleton.Bind(asyncResult_4, (input_1_5) => {
            const input_18 = input_1_5;
            if (input_18.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_18.fields[0]]));
            }
            else {
                let asyncResult_3;
                const value_1_1 = create_1(password);
                asyncResult_3 = singleton.Return(value_1_1);
                return singleton.Bind(asyncResult_3, (input_1_4) => {
                    const input_15 = input_1_4;
                    if (input_15.tag === 1) {
                        return singleton.Return(new FSharpResult$2(1, [input_15.fields[0]]));
                    }
                    else {
                        const asyncResult_2 = authService.SignIn(input_18.fields[0], input_15.fields[0]);
                        return singleton.Bind(asyncResult_2, (input_1_3) => {
                            const input_12 = input_1_3;
                            if (input_12.tag === 1) {
                                return singleton.Return(new FSharpResult$2(1, [input_12.fields[0]]));
                            }
                            else {
                                const signInResult = input_12.fields[0];
                                const userData = new LocalUserData(username, signInResult.IsMember, [], signInResult.Token, undefined, []);
                                const asyncResult_1 = saveUserData(systemService, userData);
                                return singleton.Bind(asyncResult_1, (input_1_2) => {
                                    const input_9 = input_1_2;
                                    if (input_9.tag === 1) {
                                        return singleton.Return(new FSharpResult$2(1, [input_9.fields[0]]));
                                    }
                                    else {
                                        const asyncResult = organisationService.GetUserOrganisations();
                                        return singleton.Bind(asyncResult, (input_1_1) => {
                                            let x_2;
                                            const input_6 = input_1_1;
                                            if (input_6.tag === 1) {
                                                return singleton.Return(new FSharpResult$2(1, [input_6.fields[0]]));
                                            }
                                            else {
                                                const userOrganisations = input_6.fields[0];
                                                const x_3 = saveUserData(systemService, new LocalUserData(userData.Username, userData.IsMember, userData.PurchasedServices, userData.Token, (isEmpty(userOrganisations) ? [undefined, new UserRole(3, [])] : ((length(userOrganisations) === 1) ? ((x_2 = head(userOrganisations), [x_2.Id, x_2.UserRole])) : [undefined, new UserRole(3, [])]))[0], toArray(map(OrganisationDetailDtoModule_toOrganisation, userOrganisations))));
                                                return singleton.Bind(x_3, (x$0027) => {
                                                    let value_3;
                                                    const input_2 = x$0027;
                                                    value_3 = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [signInResult])));
                                                    return singleton.Return(value_3);
                                                });
                                            }
                                        });
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });
    });
}

export function signOut(systemService) {
    return systemService.Delete("UserData");
}

export function getPurchasedServices(systemService, organisationService, selectedOrgId) {
    return singleton.Delay(() => {
        let asyncResult_1;
        const input = organisationService.GetPurchasedServices(selectedOrgId);
        asyncResult_1 = singleton.Bind(input, (x$0027) => {
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [map_1(stripeProductId, toArray(input_2.fields[0]))])));
            return singleton.Return(value);
        });
        return singleton.Bind(asyncResult_1, (input_1_4) => {
            const input_13 = input_1_4;
            if (input_13.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_13.fields[0]]));
            }
            else {
                let asyncResult;
                const value_1_2 = systemService.Load("UserData");
                asyncResult = singleton.Return(value_1_2);
                return singleton.Bind(asyncResult, (input_1_3) => {
                    const input_10 = input_1_3;
                    if (input_10.tag === 1) {
                        return singleton.Return(new FSharpResult$2(1, [input_10.fields[0]]));
                    }
                    else {
                        const _arg_2 = input_10.fields[0];
                        if (_arg_2 == null) {
                            const value_5 = ResultCE_ResultBuilder__Zero(ResultCE_result);
                            return singleton.Return(value_5);
                        }
                        else {
                            const userData = _arg_2;
                            const x_1 = saveUserData(systemService, new LocalUserData(userData.Username, userData.IsMember, input_13.fields[0], userData.Token, userData.SelectedOrganisation, userData.UserOrganisations));
                            return singleton.Bind(x_1, (x$0027_1) => {
                                let value_3;
                                const input_6 = x$0027_1;
                                value_3 = ((input_6.tag === 1) ? (new FSharpResult$2(1, [input_6.fields[0]])) : (new FSharpResult$2(0, [undefined])));
                                return singleton.Return(value_3);
                            });
                        }
                    }
                });
            }
        });
    });
}

