import { Record } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { list_type, int32_type, option_type, class_type, string_type, record_type, decimal_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { FSharpResult$2 } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Result.js";
import { Location } from "../Entity/Location.fs.js";
import { Guid_parse, Option_mapResult } from "../../Util.fs.js";
import { RememberWhereItem, RememberWhereItemStatusModule_ofInt, RememberWhereItemStatusModule_toInt } from "../Entity/RememberWhere.fs.js";

export class LocationDto extends Record {
    constructor(Latitude, Longitude) {
        super();
        this.Latitude = Latitude;
        this.Longitude = Longitude;
    }
}

export function LocationDto_$reflection() {
    return record_type("Domain.Dto.RememberWhere.LocationDto", [], LocationDto, () => [["Latitude", decimal_type], ["Longitude", decimal_type]]);
}

export class RememberWhereItemDto extends Record {
    constructor(Id, GroupId, Description, PhotoFile, Location, Updated, Status) {
        super();
        this.Id = Id;
        this.GroupId = GroupId;
        this.Description = Description;
        this.PhotoFile = PhotoFile;
        this.Location = Location;
        this.Updated = Updated;
        this.Status = (Status | 0);
    }
}

export function RememberWhereItemDto_$reflection() {
    return record_type("Domain.Dto.RememberWhere.RememberWhereItemDto", [], RememberWhereItemDto, () => [["Id", string_type], ["GroupId", class_type("System.Guid")], ["Description", string_type], ["PhotoFile", option_type(string_type)], ["Location", option_type(LocationDto_$reflection())], ["Updated", class_type("System.DateTime")], ["Status", int32_type]]);
}

export class RememberWhereItemsResponse extends Record {
    constructor(LastUpdate, RememberWhereItems) {
        super();
        this.LastUpdate = LastUpdate;
        this.RememberWhereItems = RememberWhereItems;
    }
}

export function RememberWhereItemsResponse_$reflection() {
    return record_type("Domain.Dto.RememberWhere.RememberWhereItemsResponse", [], RememberWhereItemsResponse, () => [["LastUpdate", class_type("System.DateTime")], ["RememberWhereItems", list_type(RememberWhereItemDto_$reflection())]]);
}

export function LocationDtoModule_ofLocation(location) {
    return new FSharpResult$2(0, [new LocationDto(location.Latitude, location.Longitude)]);
}

export function LocationDtoModule_ofLocationEx(location) {
    return new LocationDto(location.Latitude, location.Longitude);
}

export function LocationDtoModule_toLocation(location) {
    return new FSharpResult$2(0, [new Location(location.Latitude, location.Longitude, undefined)]);
}

export function LocationDtoModule_toLocationEx(location) {
    return new Location(location.Latitude, location.Longitude, undefined);
}

export function RememberWhereItemDtoModule_ofRememberWhereItem(rememberWhereItem) {
    const input = Option_mapResult(LocationDtoModule_ofLocation, rememberWhereItem.Location);
    if (input.tag === 1) {
        return new FSharpResult$2(1, [input.fields[0]]);
    }
    else {
        return new FSharpResult$2(0, [new RememberWhereItemDto(rememberWhereItem.Id, rememberWhereItem.GroupId, rememberWhereItem.Description, rememberWhereItem.PhotoFile, input.fields[0], rememberWhereItem.Updated, RememberWhereItemStatusModule_toInt(rememberWhereItem.Status))]);
    }
}

export function RememberWhereItemDtoModule_toRememberWhereItem(rememberWhereItem) {
    const input_2 = Guid_parse(rememberWhereItem.Id);
    if (input_2.tag === 1) {
        return new FSharpResult$2(1, [input_2.fields[0]]);
    }
    else {
        const input = Option_mapResult(LocationDtoModule_toLocation, rememberWhereItem.Location);
        if (input.tag === 1) {
            return new FSharpResult$2(1, [input.fields[0]]);
        }
        else {
            return new FSharpResult$2(0, [new RememberWhereItem(input_2.fields[0], rememberWhereItem.GroupId, rememberWhereItem.Description, rememberWhereItem.PhotoFile, input.fields[0], rememberWhereItem.Updated, RememberWhereItemStatusModule_ofInt(rememberWhereItem.Status))]);
        }
    }
}

