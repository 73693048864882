import { singleton as singleton_1, empty, map, concat } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { Customer } from "../../../Logos.Shared/Domain/Entity/Customer.fs.js";
import { Guid_parse, Customer_getCustomerNames } from "../../../Logos.Shared/Util.fs.js";
import { List_distinct } from "../../fable_modules/fable-library-js.4.19.3/Seq2.js";
import { stringHash } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { singleton } from "../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library-js.4.19.3/Result.js";
import { CategoryModule_categories } from "../../../Logos.Shared/Domain/Entity/Assessment.fs.js";
import { name } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";

export function extractCustomers(scanResult) {
    return concat(map((x_2) => map((y_1) => (new Customer(undefined, undefined, undefined, undefined, y_1.Title, y_1.FirstName, y_1.MiddleName, y_1.LastName, empty(), empty(), undefined, true)), Customer_getCustomerNames(x_2)), List_distinct(map((x) => x.CustomerName, scanResult), {
        Equals: (x_1, y) => (x_1 === y),
        GetHashCode: stringHash,
    })));
}

export function createCustomers(customerService, selectedOrgId, scanResultIds, addresses, customers) {
    return singleton.Delay(() => customerService.AddCustomersFromBankStatement(selectedOrgId, scanResultIds, addresses, customers));
}

export function assignScanToExistingCustomer(customerService, selectedOrgId, scanResultIds, selectedCustomerId) {
    return singleton.Delay(() => customerService.AssignScanToExistingCustomer(selectedOrgId, scanResultIds, selectedCustomerId));
}

export function searchCustomer(customerService, selectedOrgId, searchText, pagination) {
    return singleton.Delay(() => customerService.SearchCustomer(selectedOrgId, searchText, pagination));
}

export function getCustomer(customerService, selectedOrgId, customerId) {
    return singleton.Delay(() => {
        let asyncResult_1;
        const value_1 = Guid_parse(customerId);
        asyncResult_1 = singleton.Return(value_1);
        return singleton.Bind(asyncResult_1, (input_1) => {
            const input_2 = input_1;
            if (input_2.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
            }
            else {
                return customerService.GetCustomer(selectedOrgId, input_2.fields[0]);
            }
        });
    });
}

export function addNewCustomer(customerService, selectedOrgId, customer) {
    return singleton.Delay(() => customerService.AddNewCustomer(selectedOrgId, customer));
}

export function saveCustomer(customerService, selectedOrgId, customer) {
    return singleton.Delay(() => customerService.SaveCustomer(selectedOrgId, customer));
}

export function loadAssessments(customerService, selectedOrgId, customerId) {
    return singleton.Delay(() => customerService.LoadAssessments(selectedOrgId, customerId));
}

export function loadAssessmentItems(customerService, selectedOrgId, assessmentId) {
    return singleton.Delay(() => {
        let asyncResult_1;
        const value_1 = Guid_parse(assessmentId);
        asyncResult_1 = singleton.Return(value_1);
        return singleton.Bind(asyncResult_1, (input_1) => {
            const input_2 = input_1;
            if (input_2.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
            }
            else {
                return customerService.LoadAssessmentItems(selectedOrgId, input_2.fields[0]);
            }
        });
    });
}

export function saveAssessmentItem(customerService, selectedOrgId, itemId, newValue) {
    return singleton.Delay(() => {
        let asyncResult_1;
        const value_1_1 = CategoryModule_categories.some((x) => (name(x) === newValue)) ? (new FSharpResult$2(0, [undefined])) : (new FSharpResult$2(1, [singleton_1("Invalid Category.")]));
        asyncResult_1 = singleton.Return(value_1_1);
        return singleton.Bind(asyncResult_1, (input_1) => {
            const input_2 = input_1;
            if (input_2.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
            }
            else {
                return customerService.SaveAssessmentItem(selectedOrgId, itemId, newValue);
            }
        });
    });
}

