import { Union } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { createElement } from "react";
import { createObj } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.3/String.js";
import { singleton as singleton_1, ofArray, append } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { singleton, append as append_1, delay, toList } from "../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { Daisy_h7, Daisy_h4, Daisy_h5 } from "./Component.fs.js";
import { Helpers_combineClasses } from "../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/./Interop.fs.js";

export class TitleSize extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Small", "Medium", "Large"];
    }
}

export function TitleSize_$reflection() {
    return union_type("Presentation.Component.ViewBox.TitleSize", [], TitleSize, () => [[], [], []]);
}

function viewBoxInternal(title, toolTip, titleSize, body) {
    let elems_1;
    return createElement("div", createObj(ofArray([["className", join(" ", ["box-content", "border-2", (titleSize.tag === 1) ? "p-4" : ((titleSize.tag === 2) ? "p-8" : "p-2"), "rounded"])], (elems_1 = append(toList(delay(() => {
        let matchValue, matchValue_1, elems;
        return append_1((matchValue = toolTip, (matchValue == null) ? ((matchValue_1 = titleSize, (matchValue_1.tag === 1) ? singleton(Daisy_h5(title)) : ((matchValue_1.tag === 2) ? singleton(Daisy_h4(title)) : singleton(Daisy_h7(title))))) : singleton(createElement("div", createObj(Helpers_combineClasses("tooltip", ofArray([["data-tip", matchValue], (elems = [createElement("span", {
            className: "label-text",
            children: `${title} `,
        }), createElement("i", {
            className: join(" ", ["fa fa-solid fa-info-circle"]),
            style: {
                fontSize: 14 + "px",
            },
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))))), delay(() => singleton(createElement("div", createObj(Helpers_combineClasses("divider", singleton_1(["className", join(" ", ["my-0"])])))))));
    })), body), ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function viewBox(title, titleSize, body) {
    return viewBoxInternal(title, undefined, titleSize, body);
}

export function viewBoxWithTooltip(title, tooltip, titleSize, body) {
    return viewBoxInternal(title, tooltip, titleSize, body);
}

