import { Remoting_buildProxy_64DC51C } from "../../fable_modules/Fable.Remoting.Client.7.32.0/./Remoting.fs.js";
import { RemotingModule_createApi, RemotingModule_withBaseUrl, RemotingModule_withRouteBuilder, RemotingModule_withBinarySerialization, RemotingModule_withCustomHeader, RemotingModule_withAuthorizationHeader } from "../../fable_modules/Fable.Remoting.Client.7.32.0/Remoting.fs.js";
import { singleton } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { Service_$reflection, Service_RouteBuilder, Remoting_useBinarySerialization } from "../../../Logos.Shared/Domain/API.fs.js";
import { AuthService_$reflection, AuthService_RouteBuilder } from "../../../Logos.Shared/Domain/Remoting/AuthService.fs.js";
import { defaultOf, createAtom } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { printf, toConsole } from "../../fable_modules/fable-library-js.4.19.3/String.js";
import { singleton as singleton_1 } from "../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { ResultCE_result, ResultCE_ResultBuilder__Zero } from "../../fable_modules/FsToolkit.ErrorHandling.4.16.0/./ResultCE.fs.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library-js.4.19.3/Result.js";
import { BrowserData, LocalUserData } from "../../AppDomain/SystemService.fs.js";
import { toString } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { UserRole } from "../../../Logos.Shared/Domain/Entity/User.fs.js";
import { defaultArg } from "../../fable_modules/fable-library-js.4.19.3/Option.js";
import { find } from "../../fable_modules/fable-library-js.4.19.3/Array.js";
import { ProxyRequestException__get_StatusCode, ProxyRequestException } from "../../fable_modules/Fable.Remoting.Client.7.32.0/Types.fs.js";
import { DocumentService_$reflection, DocumentService_RouteBuilder } from "../../../Logos.Shared/Domain/Remoting/DocumentService.fs.js";
import { OrganisationService_$reflection, OrganisationService_RouteBuilder } from "../../../Logos.Shared/Domain/Remoting/OrganisationService.fs.js";
import { CustomerService_$reflection, CustomerService_RouteBuilder } from "../../../Logos.Shared/Domain/Remoting/CustomerService.fs.js";
import { StripeService_$reflection, StripeService_RouteBuilder } from "../../../Logos.Shared/Domain/Remoting/StripeService.fs.js";
import { RememberWhereService_$reflection, RememberWhereService_RouteBuilder } from "../../../Logos.Shared/Domain/Remoting/RememberWhereService.fs.js";
import { VehicleService_$reflection, VehicleService_RouteBuilder } from "../../../Logos.Shared/Domain/Remoting/VehicleService.fs.js";
import { LogMasterService_$reflection, LogMasterService_RouteBuilder } from "../../../Logos.Shared/Domain/Remoting/LogMasterService.fs.js";
import { UserService_$reflection, UserService_RouteBuilder } from "../../../Logos.Shared/Domain/Remoting/UserService.fs.js";
import { SkillService_$reflection, SkillService_RouteBuilder } from "../../../Logos.Shared/Domain/Remoting/SkillService.fs.js";
import { JobService_$reflection, JobService_RouteBuilder } from "../../../Logos.Shared/Domain/Remoting/JobService.fs.js";
import { ShiftService_$reflection, ShiftService_RouteBuilder } from "../../../Logos.Shared/Domain/Remoting/Shift.fs.js";
import { LeavesService_$reflection, LeavesService_RouteBuilder } from "../../../Logos.Shared/Domain/Remoting/LeavesService.fs.js";
import { ExpenseService_$reflection, ExpenseService_RouteBuilder } from "../../../Logos.Shared/Domain/Remoting/ExpenseService.fs.js";
import { XeroService_$reflection, XeroService_RouteBuilder } from "../../../Logos.Shared/Domain/Remoting/XeroService.fs.js";
import { InvoiceService_$reflection, InvoiceService_RouteBuilder } from "../../../Logos.Shared/Domain/Remoting/InvoiceService.fs.js";
import { HolidayService_$reflection } from "../../../Logos.Shared/Domain/Remoting/HolidayService.fs.js";
import { SimproService_$reflection, SimproService_RouteBuilder } from "../../../Logos.Shared/Domain/Remoting/SimproService.fs.js";

export const baseUrl = "https://api.10thdoor.com/";

export function serviceProxy(token, userRole) {
    return Remoting_buildProxy_64DC51C(RemotingModule_withAuthorizationHeader(`Bearer ${token}`, RemotingModule_withCustomHeader(singleton(["UserRole", userRole]), (Remoting_useBinarySerialization ? (RemotingModule_withBinarySerialization) : ((x) => x))(RemotingModule_withRouteBuilder(Service_RouteBuilder, RemotingModule_withBaseUrl(baseUrl, RemotingModule_createApi()))))), Service_$reflection());
}

export const authServiceProxy = Remoting_buildProxy_64DC51C(RemotingModule_withRouteBuilder(AuthService_RouteBuilder, RemotingModule_withBaseUrl(baseUrl, RemotingModule_createApi())), AuthService_$reflection());

export let unauthorizedAction = createAtom(() => {
    toConsole(printf("-----> Unassigned unauthorizedAction."));
});

export function handleUnauthorized(systemService, authService) {
    return singleton_1.Delay(() => {
        const matchValue = systemService.Load("UserData");
        if (matchValue.tag === 1) {
            unauthorizedAction()();
            const value_9 = ResultCE_ResultBuilder__Zero(ResultCE_result);
            return singleton_1.Return(value_9);
        }
        else if (matchValue.fields[0] == null) {
            unauthorizedAction()();
            const value_8 = ResultCE_ResultBuilder__Zero(ResultCE_result);
            return singleton_1.Return(value_8);
        }
        else {
            const x = matchValue.fields[0];
            const matchValue_1 = x.Token;
            if (matchValue_1 == null) {
                unauthorizedAction()();
                const value_7 = ResultCE_ResultBuilder__Zero(ResultCE_result);
                return singleton_1.Return(value_7);
            }
            else {
                const token = matchValue_1;
                let asyncResult;
                const input = authService.RefreshToken(token.Token, token.RefreshToken);
                asyncResult = singleton_1.Bind(input, (x$0027) => {
                    let value;
                    const input_2 = x$0027;
                    value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [(unauthorizedAction()(), input_2.fields[0])])) : (new FSharpResult$2(0, [input_2.fields[0]])));
                    return singleton_1.Return(value);
                });
                return singleton_1.Bind(asyncResult, (input_1_3) => {
                    const input_10 = input_1_3;
                    if (input_10.tag === 1) {
                        return singleton_1.Return(new FSharpResult$2(1, [input_10.fields[0]]));
                    }
                    else {
                        const userData = new BrowserData(new LocalUserData(x.Username, x.IsMember, x.PurchasedServices, input_10.fields[0], x.SelectedOrganisation, x.UserOrganisations));
                        let x_3;
                        const value_1_2 = systemService.Save(userData);
                        x_3 = singleton_1.Return(value_1_2);
                        return singleton_1.Bind(x_3, (x$0027_1) => {
                            let value_3;
                            const input_6 = x$0027_1;
                            value_3 = ((input_6.tag === 1) ? (new FSharpResult$2(1, [input_6.fields[0]])) : (new FSharpResult$2(0, [undefined])));
                            return singleton_1.Return(value_3);
                        });
                    }
                });
            }
        }
    });
}

export function getTokenAndUserRole(systemService) {
    let input_1, input_3, x_1;
    const matchValue = systemService.Load("UserData");
    if (matchValue.tag === 1) {
        return ["", toString(new UserRole(3, []))];
    }
    else if (matchValue.fields[0] == null) {
        return ["", toString(new UserRole(3, []))];
    }
    else {
        const userData = matchValue.fields[0];
        return [defaultArg((input_1 = userData.Token, (input_1 == null) ? undefined : input_1.Token), ""), defaultArg((input_3 = userData.SelectedOrganisation, (input_3 == null) ? undefined : ((x_1 = input_3, toString(find((z) => (z.Id === x_1), userData.UserOrganisations).UserRole)))), toString(new UserRole(3, [])))];
    }
}

export function makeSecureRemoteCall(systemService, authService, getRemotingProxy, remoteCall) {
    return singleton_1.Delay(() => {
        const call = () => singleton_1.Delay(() => {
            const input = remoteCall(getRemotingProxy());
            return singleton_1.Bind(input, (x$0027) => singleton_1.Return(new FSharpResult$2(0, [x$0027])));
        });
        const computation = singleton_1.Delay(call);
        return singleton_1.TryWith(computation, (_arg) => {
            let matchResult, ex_1;
            if (_arg instanceof ProxyRequestException) {
                if (ProxyRequestException__get_StatusCode(_arg) === 401) {
                    matchResult = 0;
                    ex_1 = _arg;
                }
                else {
                    matchResult = 1;
                }
            }
            else {
                matchResult = 1;
            }
            switch (matchResult) {
                case 0: {
                    const asyncResult_3 = handleUnauthorized(systemService, authService);
                    return singleton_1.Bind(asyncResult_3, (input_1_1) => {
                        const input_4 = input_1_1;
                        if (input_4.tag === 1) {
                            return singleton_1.Return(new FSharpResult$2(1, [input_4.fields[0]]));
                        }
                        else {
                            return call();
                        }
                    });
                }
                default: {
                    throw _arg;
                    return defaultOf();
                }
            }
        });
    });
}

export function documentServiceProxy(token, userToken) {
    return Remoting_buildProxy_64DC51C(RemotingModule_withAuthorizationHeader(`Bearer ${token}`, RemotingModule_withCustomHeader(singleton(["UserRole", userToken]), (Remoting_useBinarySerialization ? (RemotingModule_withBinarySerialization) : ((x) => x))(RemotingModule_withRouteBuilder(DocumentService_RouteBuilder, RemotingModule_withBaseUrl(baseUrl, RemotingModule_createApi()))))), DocumentService_$reflection());
}

export function organisationServiceProxy(token, userToken) {
    return Remoting_buildProxy_64DC51C(RemotingModule_withAuthorizationHeader(`Bearer ${token}`, RemotingModule_withCustomHeader(singleton(["UserRole", userToken]), (Remoting_useBinarySerialization ? (RemotingModule_withBinarySerialization) : ((x) => x))(RemotingModule_withRouteBuilder(OrganisationService_RouteBuilder, RemotingModule_withBaseUrl(baseUrl, RemotingModule_createApi()))))), OrganisationService_$reflection());
}

export function customerServiceProxy(token, userToken) {
    return Remoting_buildProxy_64DC51C(RemotingModule_withAuthorizationHeader(`Bearer ${token}`, RemotingModule_withCustomHeader(singleton(["UserRole", userToken]), (Remoting_useBinarySerialization ? (RemotingModule_withBinarySerialization) : ((x) => x))(RemotingModule_withRouteBuilder(CustomerService_RouteBuilder, RemotingModule_withBaseUrl(baseUrl, RemotingModule_createApi()))))), CustomerService_$reflection());
}

export function stripeServiceProxy(token, userToken) {
    return Remoting_buildProxy_64DC51C(RemotingModule_withAuthorizationHeader(`Bearer ${token}`, RemotingModule_withCustomHeader(singleton(["UserRole", userToken]), (Remoting_useBinarySerialization ? (RemotingModule_withBinarySerialization) : ((x) => x))(RemotingModule_withRouteBuilder(StripeService_RouteBuilder, RemotingModule_withBaseUrl(baseUrl, RemotingModule_createApi()))))), StripeService_$reflection());
}

export function rememberWhereServiceProxy(token, userToken) {
    return Remoting_buildProxy_64DC51C(RemotingModule_withAuthorizationHeader(`Bearer ${token}`, RemotingModule_withCustomHeader(singleton(["UserRole", userToken]), (Remoting_useBinarySerialization ? (RemotingModule_withBinarySerialization) : ((x) => x))(RemotingModule_withRouteBuilder(RememberWhereService_RouteBuilder, RemotingModule_withBaseUrl(baseUrl, RemotingModule_createApi()))))), RememberWhereService_$reflection());
}

export function vehicleServiceProxy(token, userToken) {
    return Remoting_buildProxy_64DC51C(RemotingModule_withAuthorizationHeader(`Bearer ${token}`, RemotingModule_withCustomHeader(singleton(["UserRole", userToken]), (Remoting_useBinarySerialization ? (RemotingModule_withBinarySerialization) : ((x) => x))(RemotingModule_withRouteBuilder(VehicleService_RouteBuilder, RemotingModule_withBaseUrl(baseUrl, RemotingModule_createApi()))))), VehicleService_$reflection());
}

export function logMasterServiceProxy(token, userToken) {
    return Remoting_buildProxy_64DC51C(RemotingModule_withAuthorizationHeader(`Bearer ${token}`, RemotingModule_withCustomHeader(singleton(["UserRole", userToken]), (Remoting_useBinarySerialization ? (RemotingModule_withBinarySerialization) : ((x) => x))(RemotingModule_withRouteBuilder(LogMasterService_RouteBuilder, RemotingModule_withBaseUrl(baseUrl, RemotingModule_createApi()))))), LogMasterService_$reflection());
}

export function userServiceProxy(token, userToken) {
    return Remoting_buildProxy_64DC51C(RemotingModule_withAuthorizationHeader(`Bearer ${token}`, RemotingModule_withCustomHeader(singleton(["UserRole", userToken]), (Remoting_useBinarySerialization ? (RemotingModule_withBinarySerialization) : ((x) => x))(RemotingModule_withRouteBuilder(UserService_RouteBuilder, RemotingModule_withBaseUrl(baseUrl, RemotingModule_createApi()))))), UserService_$reflection());
}

export function skillServiceProxy(token, userToken) {
    return Remoting_buildProxy_64DC51C(RemotingModule_withAuthorizationHeader(`Bearer ${token}`, RemotingModule_withCustomHeader(singleton(["UserRole", userToken]), (Remoting_useBinarySerialization ? (RemotingModule_withBinarySerialization) : ((x) => x))(RemotingModule_withRouteBuilder(SkillService_RouteBuilder, RemotingModule_withBaseUrl(baseUrl, RemotingModule_createApi()))))), SkillService_$reflection());
}

export function jobServiceProxy(token, userToken) {
    return Remoting_buildProxy_64DC51C(RemotingModule_withAuthorizationHeader(`Bearer ${token}`, RemotingModule_withCustomHeader(singleton(["UserRole", userToken]), (Remoting_useBinarySerialization ? (RemotingModule_withBinarySerialization) : ((x) => x))(RemotingModule_withRouteBuilder(JobService_RouteBuilder, RemotingModule_withBaseUrl(baseUrl, RemotingModule_createApi()))))), JobService_$reflection());
}

export function shiftServiceProxy(token, userToken) {
    return Remoting_buildProxy_64DC51C(RemotingModule_withAuthorizationHeader(`Bearer ${token}`, RemotingModule_withCustomHeader(singleton(["UserRole", userToken]), (Remoting_useBinarySerialization ? (RemotingModule_withBinarySerialization) : ((x) => x))(RemotingModule_withRouteBuilder(ShiftService_RouteBuilder, RemotingModule_withBaseUrl(baseUrl, RemotingModule_createApi()))))), ShiftService_$reflection());
}

export function leavesServiceProxy(token, userToken) {
    return Remoting_buildProxy_64DC51C(RemotingModule_withAuthorizationHeader(`Bearer ${token}`, RemotingModule_withCustomHeader(singleton(["UserRole", userToken]), (Remoting_useBinarySerialization ? (RemotingModule_withBinarySerialization) : ((x) => x))(RemotingModule_withRouteBuilder(LeavesService_RouteBuilder, RemotingModule_withBaseUrl(baseUrl, RemotingModule_createApi()))))), LeavesService_$reflection());
}

export function expenseServiceProxy(token, userToken) {
    return Remoting_buildProxy_64DC51C(RemotingModule_withAuthorizationHeader(`Bearer ${token}`, RemotingModule_withCustomHeader(singleton(["UserRole", userToken]), (Remoting_useBinarySerialization ? (RemotingModule_withBinarySerialization) : ((x) => x))(RemotingModule_withRouteBuilder(ExpenseService_RouteBuilder, RemotingModule_withBaseUrl(baseUrl, RemotingModule_createApi()))))), ExpenseService_$reflection());
}

export function xeroServiceProxy(token, userToken) {
    return Remoting_buildProxy_64DC51C(RemotingModule_withAuthorizationHeader(`Bearer ${token}`, RemotingModule_withCustomHeader(singleton(["UserRole", userToken]), (Remoting_useBinarySerialization ? (RemotingModule_withBinarySerialization) : ((x) => x))(RemotingModule_withRouteBuilder(XeroService_RouteBuilder, RemotingModule_withBaseUrl(baseUrl, RemotingModule_createApi()))))), XeroService_$reflection());
}

export function invoiceServiceProxy(token, userToken) {
    return Remoting_buildProxy_64DC51C(RemotingModule_withAuthorizationHeader(`Bearer ${token}`, RemotingModule_withCustomHeader(singleton(["UserRole", userToken]), (Remoting_useBinarySerialization ? (RemotingModule_withBinarySerialization) : ((x) => x))(RemotingModule_withRouteBuilder(InvoiceService_RouteBuilder, RemotingModule_withBaseUrl(baseUrl, RemotingModule_createApi()))))), InvoiceService_$reflection());
}

export function holidayServiceProxy(token, userToken) {
    return Remoting_buildProxy_64DC51C(RemotingModule_withAuthorizationHeader(`Bearer ${token}`, RemotingModule_withCustomHeader(singleton(["UserRole", userToken]), (Remoting_useBinarySerialization ? (RemotingModule_withBinarySerialization) : ((x) => x))(RemotingModule_withRouteBuilder(InvoiceService_RouteBuilder, RemotingModule_withBaseUrl(baseUrl, RemotingModule_createApi()))))), HolidayService_$reflection());
}

export function simproServiceProxy(token, userToken) {
    return Remoting_buildProxy_64DC51C(RemotingModule_withAuthorizationHeader(`Bearer ${token}`, RemotingModule_withCustomHeader(singleton(["UserRole", userToken]), (Remoting_useBinarySerialization ? (RemotingModule_withBinarySerialization) : ((x) => x))(RemotingModule_withRouteBuilder(SimproService_RouteBuilder, RemotingModule_withBaseUrl(baseUrl, RemotingModule_createApi()))))), SimproService_$reflection());
}

