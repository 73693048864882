import { Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type, tuple_type, record_type, string_type, option_type, class_type, list_type, decimal_type, bool_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Holiday_$reflection, TimesheetOfUser_$reflection } from "../../../../Logos.Shared/Domain/Entity/Shift.fs.js";
import { Busy, Busy_$reflection } from "../../SharedView.fs.js";
import { OrganisationOptions_$reflection } from "../../../../Logos.Shared/Domain/Entity/Organisation.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { LeaveRequestTypeModule_toString, LeaveRequest_$reflection } from "../../../../Logos.Shared/Domain/Entity/User.fs.js";
import { cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { System_TimeSpan__TimeSpan_ToDisplayDurationInHoursAndMinutes, AsyncResult_bindOfResult } from "../../../../Logos.Shared/Util.fs.js";
import { append as append_1, sortBy, concat, tryHead, map, sumBy, ofArray, empty, singleton as singleton_1 } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../Infrastructure/AppRoot.fs.js";
import { OrganisationDtoModule_toOrganisation } from "../../../../Logos.Shared/Domain/Dto/Organisation.fs.js";
import { getTimesheetsForUser } from "../../../AppDomain/UseCase/Timesheet.fs.js";
import { toNumber, fromParts } from "../../../fable_modules/fable-library-js.4.19.3/Decimal.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { safeHash, equals, createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { defaultArg } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { Daisy_progressState, Daisy_error, Daisy_DialogWidth, Daisy_h4, alignCellTextTop } from "../../Component/Component.fs.js";
import { DateTime_dateTimeToStringWithDayName } from "../../../AppDomain/Util.fs.js";
import { map as map_1, collect, empty as empty_1, singleton as singleton_2, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { compare, toLocalTime } from "../../../fable_modules/fable-library-js.4.19.3/Date.js";
import { toLocalTime as toLocalTime_1 } from "../../../fable_modules/fable-library-js.4.19.3/DateOffset.js";
import { fromHours, ticks, fromTicks } from "../../../fable_modules/fable-library-js.4.19.3/TimeSpan.js";
import { op_Addition, toInt64 } from "../../../fable_modules/fable-library-js.4.19.3/BigInt.js";
import { List_distinct } from "../../../fable_modules/fable-library-js.4.19.3/Seq2.js";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../../Component/ViewContainer.fs.js";
import { showModalDialog } from "../../Component/ModalDialog.fs.js";
import { IndexView as IndexView_1 } from "../Shift/ShiftEdit.fs.js";

export class State extends Record {
    constructor(IsUsingOvertimeAfterWorkHours, OrgOvertimeAfterWorkHours, Timesheets, ViewingShift, IsBusy, Errors) {
        super();
        this.IsUsingOvertimeAfterWorkHours = IsUsingOvertimeAfterWorkHours;
        this.OrgOvertimeAfterWorkHours = OrgOvertimeAfterWorkHours;
        this.Timesheets = Timesheets;
        this.ViewingShift = ViewingShift;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.PaymentRunUserTimesheets.State", [], State, () => [["IsUsingOvertimeAfterWorkHours", bool_type], ["OrgOvertimeAfterWorkHours", decimal_type], ["Timesheets", list_type(TimesheetOfUser_$reflection())], ["ViewingShift", option_type(class_type("System.Guid"))], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetTimesheetResponse", "ViewShift", "HideShift", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.PaymentRunUserTimesheets.Msg", [], Msg, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(OrganisationOptions_$reflection(), list_type(TimesheetOfUser_$reflection())), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", tuple_type(OrganisationOptions_$reflection(), list_type(TimesheetOfUser_$reflection()))]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Guid")]], [], [["Item", class_type("System.Exception")]]]);
}

export class RowData extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Timesheet", "Leave", "Holiday"];
    }
}

export function RowData_$reflection() {
    return union_type("Presentation.Pages.PaymentRunUserTimesheets.RowData", [], RowData, () => [[["Item", TimesheetOfUser_$reflection()]], [["Item", LeaveRequest_$reflection()]], [["Item", Holiday_$reflection()]]]);
}

export function getTimesheetsCmd(paymentRunId, userId) {
    return cmdOfAsync((Item) => (new Msg(0, [Item])), (Item_1) => (new Msg(3, [Item_1])), () => singleton.Delay(() => {
        let asyncResult;
        const input = AsyncResult_bindOfResult((option) => {
            const option_1 = option;
            if (option_1 == null) {
                return new FSharpResult$2(1, [singleton_1("Organisation not found.")]);
            }
            else {
                return new FSharpResult$2(0, [option_1]);
            }
        }, appRoot.OrganisationService.GetOrganisation(AppRootModule_getSelectedOrgId()));
        asyncResult = singleton.Bind(input, (x$0027) => {
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [OrganisationDtoModule_toOrganisation(input_2.fields[0])])));
            return singleton.Return(value);
        });
        return singleton.Bind(asyncResult, (input_1_3) => {
            const input_10 = input_1_3;
            if (input_10.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_10.fields[0]]));
            }
            else {
                const x_3 = getTimesheetsForUser(appRoot.ShiftService, AppRootModule_getSelectedOrgId(), paymentRunId, userId);
                return singleton.Bind(x_3, (x$0027_1) => {
                    let value_2;
                    const input_6 = x$0027_1;
                    value_2 = ((input_6.tag === 1) ? (new FSharpResult$2(1, [input_6.fields[0]])) : (new FSharpResult$2(0, [[input_10.fields[0].OrganisationOptions, input_6.fields[0]]])));
                    return singleton.Return(value_2);
                });
            }
        });
    }));
}

export function init(paymentRunId, userId) {
    return [new State(false, fromParts(0, 0, 0, false, 0), empty(), undefined, new Busy(1, []), empty()), getTimesheetsCmd(paymentRunId, userId)];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [new State(state.IsUsingOvertimeAfterWorkHours, state.OrgOvertimeAfterWorkHours, state.Timesheets, msg.fields[0], state.IsBusy, state.Errors), Cmd_none()];
        case 2:
            return [new State(state.IsUsingOvertimeAfterWorkHours, state.OrgOvertimeAfterWorkHours, state.Timesheets, undefined, state.IsBusy, state.Errors), Cmd_none()];
        case 3:
            return [new State(state.IsUsingOvertimeAfterWorkHours, state.OrgOvertimeAfterWorkHours, state.Timesheets, state.ViewingShift, new Busy(0, []), singleton_1(msg.fields[0].message)), Cmd_none()];
        default:
            if (msg.fields[0].tag === 1) {
                return [new State(state.IsUsingOvertimeAfterWorkHours, state.OrgOvertimeAfterWorkHours, state.Timesheets, state.ViewingShift, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(msg.fields[0].fields[0][0].IsUsingOvertimeAfterWorkHours, msg.fields[0].fields[0][0].OvertimeAfterWorkHours, msg.fields[0].fields[0][1], state.ViewingShift, new Busy(0, []), state.Errors), Cmd_none()];
            }
    }
}

function timesheetRow(dispatch, timesheet) {
    let elems_1, elems;
    const viewShiftButton = createElement("div", createObj(Helpers_combineClasses("tooltip", ofArray([["data-tip", "View Shift"], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["btn btn-square btn-xs text-primary"])], (elems = [createElement("i", {
        className: join(" ", ["fa", "fa-book"]),
    })], ["children", reactApi.Children.toArray(Array.from(elems))]), ["onClick", (_arg) => {
        dispatch(new Msg(1, [timesheet.ShiftId]));
    }]])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))));
    const children_1 = ofArray([createElement("td", {
        className: join(" ", ["align-text-top", "font-bold"]),
        children: reactApi.Children.toArray([viewShiftButton]),
    }), createElement("td", {
        className: join(" ", ["align-text-top", "font-bold"]),
        children: timesheet.TimesheetRunNumber,
    }), createElement("td", {
        className: join(" ", ["align-text-top", "font-bold"]),
        children: defaultArg(timesheet.ApprovedBy, ""),
    }), createElement("td", createObj(ofArray([alignCellTextTop, ["children", DateTime_dateTimeToStringWithDayName(timesheet.ShiftStartDate)]]))), createElement("td", {
        children: timesheet.IsTotalWorkDurationAdjusted ? System_TimeSpan__TimeSpan_ToDisplayDurationInHoursAndMinutes(timesheet.TotalWorkDurationAdjustment) : System_TimeSpan__TimeSpan_ToDisplayDurationInHoursAndMinutes(timesheet.TotalWorkDuration),
    }), createElement("td", {
        children: timesheet.IsTotalBreakDurationAdjusted ? System_TimeSpan__TimeSpan_ToDisplayDurationInHoursAndMinutes(timesheet.TotalBreakDurationAdjustment) : System_TimeSpan__TimeSpan_ToDisplayDurationInHoursAndMinutes(timesheet.TotalBreakDuration),
    }), createElement("td", {
        children: timesheet.SupervisorComment,
    }), createElement("td", {
        children: timesheet.WorkerShiftComments,
    })]);
    return createElement("tr", {
        children: reactApi.Children.toArray(Array.from(children_1)),
    });
}

export function leaveRow(leave) {
    let elems_1;
    const rowBackgroundRed = ["style", {
        backgroundColor: "#f8d7da",
    }];
    const children = ofArray([createElement("td", createObj(singleton_1(rowBackgroundRed))), createElement("td", createObj(singleton_1(rowBackgroundRed))), createElement("td", createObj(singleton_1(rowBackgroundRed))), createElement("td", createObj(ofArray([alignCellTextTop, rowBackgroundRed, (elems_1 = [createElement("div", createObj(toList(delay(() => {
        const leaveStart = DateTime_dateTimeToStringWithDayName(toLocalTime(leave.StartDate));
        const leaveFinish = DateTime_dateTimeToStringWithDayName(toLocalTime(leave.FinishDate));
        const isOneDayLeave = leaveFinish === leaveStart;
        return append(singleton_2(["className", join(" ", [`grid grid-rows-${isOneDayLeave ? 1 : 2} gap-2`])]), delay(() => {
            let elems;
            return singleton_2((elems = toList(delay(() => append(singleton_2(createElement("div", {
                children: `${leaveStart}`,
            })), delay(() => (!isOneDayLeave ? singleton_2(createElement("div", {
                children: `${leaveFinish}`,
            })) : empty_1()))))), ["children", reactApi.Children.toArray(Array.from(elems))]));
        }));
    }))))], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("td", createObj(ofArray([rowBackgroundRed, ["children", `${LeaveRequestTypeModule_toString(leave.LeaveRequestType)}`]]))), createElement("td", createObj(singleton_1(rowBackgroundRed))), createElement("td", createObj(ofArray([rowBackgroundRed, ["children", leave.Reason]]))), createElement("td", createObj(singleton_1(rowBackgroundRed)))]);
    return createElement("tr", {
        children: reactApi.Children.toArray(Array.from(children)),
    });
}

export function holidayRow(holiday) {
    const rowBackgroundRed = ["style", {
        backgroundColor: "#f8d7fa",
    }];
    const children = ofArray([createElement("td", createObj(singleton_1(rowBackgroundRed))), createElement("td", createObj(singleton_1(rowBackgroundRed))), createElement("td", createObj(singleton_1(rowBackgroundRed))), createElement("td", createObj(ofArray([alignCellTextTop, rowBackgroundRed, ["children", DateTime_dateTimeToStringWithDayName(toLocalTime_1(holiday.Date))]]))), createElement("td", createObj(singleton_1(rowBackgroundRed))), createElement("td", createObj(singleton_1(rowBackgroundRed))), createElement("td", createObj(ofArray([alignCellTextTop, rowBackgroundRed, ["children", holiday.Name]]))), createElement("td", createObj(singleton_1(rowBackgroundRed)))]);
    return createElement("tr", {
        children: reactApi.Children.toArray(Array.from(children)),
    });
}

function timesheetTotalRow(isUsingOvertimeAfterWorkHours, orgOvertimeAfterWorkHours, timesheetsForUser) {
    const totalWorkDuration = fromTicks(sumBy(ticks, map((x) => {
        if (x.IsTotalWorkDurationAdjusted) {
            return x.TotalWorkDurationAdjustment;
        }
        else {
            return x.TotalWorkDuration;
        }
    }, timesheetsForUser), {
        GetZero: () => (0n),
        Add: (x_2, y) => toInt64(op_Addition(x_2, y)),
    }));
    const totalBreakDuration = fromTicks(sumBy(ticks, map((x_3) => {
        if (x_3.IsTotalBreakDurationAdjusted) {
            return x_3.TotalBreakDurationAdjustment;
        }
        else {
            return x_3.TotalBreakDuration;
        }
    }, timesheetsForUser), {
        GetZero: () => (0n),
        Add: (x_5, y_1) => toInt64(op_Addition(x_5, y_1)),
    }));
    return toList(delay(() => {
        let children;
        return append(singleton_2((children = ofArray([createElement("td", {}), createElement("td", {}), createElement("td", {}), createElement("td", {
            className: join(" ", ["font-bold"]),
            children: "Total",
        }), createElement("td", {
            className: join(" ", ["font-bold"]),
            children: System_TimeSpan__TimeSpan_ToDisplayDurationInHoursAndMinutes(totalWorkDuration),
        }), createElement("td", {
            className: join(" ", ["font-bold"]),
            children: System_TimeSpan__TimeSpan_ToDisplayDurationInHoursAndMinutes(totalBreakDuration),
        }), createElement("td", {}), createElement("td", {}), createElement("td", {})]), createElement("tr", {
            children: reactApi.Children.toArray(Array.from(children)),
        }))), delay(() => {
            let input_1, children_2;
            if (isUsingOvertimeAfterWorkHours) {
                let overtimeDuration;
                let overtimeAfterWorkHours;
                const x_7 = defaultArg((input_1 = tryHead(timesheetsForUser), (input_1 == null) ? undefined : input_1.OvertimeAfterWorkHours), 0);
                overtimeAfterWorkHours = ((x_7 === 0) ? orgOvertimeAfterWorkHours : x_7);
                overtimeDuration = ((totalWorkDuration > overtimeAfterWorkHours) ? (totalWorkDuration - overtimeAfterWorkHours) : 0);
                return singleton_2((children_2 = ofArray([createElement("td", {}), createElement("td", {}), createElement("td", {}), createElement("td", {
                    className: join(" ", ["font-bold"]),
                    children: "Overtime",
                }), createElement("td", {
                    className: join(" ", ["font-bold"]),
                    style: createObj(toList(delay(() => ((overtimeDuration > 0) ? singleton_2(["color", "red"]) : empty_1())))),
                    children: System_TimeSpan__TimeSpan_ToDisplayDurationInHoursAndMinutes(overtimeDuration),
                }), createElement("td", {}), createElement("td", {}), createElement("td", {}), createElement("td", {})]), createElement("tr", {
                    children: reactApi.Children.toArray(Array.from(children_2)),
                })));
            }
            else {
                return empty_1();
            }
        }));
    }));
}

export function timesheetTable(dispatch, isUsingOvertimeAfterWorkHours, orgOvertimeAfterWorkHours, timesheets) {
    let elems_2, children_2, children, elems, elems_1, children_4;
    return createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-xs"], ["className", "table-zebra"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["w-full"])], (elems_2 = [(children_2 = singleton_1((children = ofArray([createElement("th", createObj(ofArray([alignCellTextTop, ["width", 2 + "%"], ["children", "Shift"]]))), createElement("th", createObj(ofArray([alignCellTextTop, ["width", 3 + "%"], ["children", "Timesheet Run #"]]))), createElement("th", createObj(ofArray([alignCellTextTop, ["width", 15 + "%"], ["children", "Approved By"]]))), createElement("th", createObj(ofArray([alignCellTextTop, ["width", 15 + "%"], ["children", "Shift start"]]))), createElement("th", createObj(ofArray([alignCellTextTop, ["width", 10 + "%"], (elems = [createElement("div", {
        children: "Work",
    }), createElement("div", {
        children: "Duration",
    }), createElement("div", {
        children: "(H:M)",
    })], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement("th", createObj(ofArray([alignCellTextTop, ["width", 10 + "%"], (elems_1 = [createElement("div", {
        children: "Break",
    }), createElement("div", {
        children: "Duration",
    }), createElement("div", {
        children: "(H:M)",
    })], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("th", createObj(ofArray([alignCellTextTop, ["width", 30 + "%"], ["children", "Supervisor Comments"]]))), createElement("th", createObj(ofArray([alignCellTextTop, ["width", 20 + "%"], ["children", "User Comments"]])))]), createElement("tr", {
        children: reactApi.Children.toArray(Array.from(children)),
    }))), createElement("thead", {
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), (children_4 = toList(delay(() => {
        let list_10, list_7;
        const leaves = List_distinct(concat(map((x) => x.Leaves, timesheets)), {
            Equals: equals,
            GetHashCode: safeHash,
        });
        return append(collect((row) => {
            const matchValue = row;
            return (matchValue.tag === 1) ? singleton_2(leaveRow(matchValue.fields[0])) : ((matchValue.tag === 2) ? singleton_2(holidayRow(matchValue.fields[0])) : singleton_2(timesheetRow(dispatch, matchValue.fields[0])));
        }, sortBy((x_7) => {
            switch (x_7.tag) {
                case 1:
                    return x_7.fields[0].StartDate;
                case 2:
                    return toLocalTime_1(x_7.fields[0].Date);
                default:
                    return x_7.fields[0].ShiftStartDate;
            }
        }, (list_10 = ((list_7 = map((x_4) => (new RowData(0, [x_4])), timesheets), append_1(map((x_5) => (new RowData(1, [x_5])), leaves), list_7))), append_1(map((x_6) => (new RowData(2, [x_6])), List_distinct(concat(map((x_2) => x_2.Holidays, timesheets)), {
            Equals: equals,
            GetHashCode: safeHash,
        })), list_10)), {
            Compare: compare,
        })), delay(() => map_1((row_1) => row_1, timesheetTotalRow(isUsingOvertimeAfterWorkHours, orgOvertimeAfterWorkHours, timesheets))));
    })), createElement("tbody", {
        children: reactApi.Children.toArray(Array.from(children_4)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))));
}

export function IndexView(props) {
    let patternInput;
    const init_1 = init(props.PaymentRunId, props.UserId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return container(toList(delay(() => {
        let children;
        return append(singleton_2((children = singleton_1(Daisy_h4(`Timesheets of ${props.Username}`)), createElement("div", {
            children: reactApi.Children.toArray(Array.from(children)),
        }))), delay(() => {
            let matchValue, shiftId;
            return append((matchValue = state_1.ViewingShift, (matchValue == null) ? singleton_2(react.createElement(react.Fragment, {})) : ((shiftId = matchValue, singleton_2(createElement(showModalDialog, {
                dialogId: shiftId,
                header: "",
                width: new Daisy_DialogWidth(4, []),
                heightPercent: "80",
                body: () => createElement(IndexView_1, {
                    shiftId: shiftId,
                }),
                onShow: () => {
                },
                onClose: () => {
                    dispatch(new Msg(2, []));
                },
            }))))), delay(() => append(singleton_2(Daisy_error(state_1.Errors)), delay(() => {
                let children_2;
                return append(singleton_2((children_2 = singleton_1(createElement(Daisy_progressState, {
                    busy: state_1.IsBusy,
                })), createElement("div", {
                    className: "form-control",
                    children: reactApi.Children.toArray(Array.from(children_2)),
                }))), delay(() => singleton_2(timesheetTable(dispatch, state_1.IsUsingOvertimeAfterWorkHours, fromHours(toNumber(state_1.OrgOvertimeAfterWorkHours)), state_1.Timesheets))));
            }))));
        }));
    })));
}

