import { Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type, class_type, record_type, string_type, lambda_type, unit_type, list_type, bool_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { JobShiftCheckItem_$reflection } from "../../../../Logos.Shared/Domain/Entity/Job.fs.js";
import { ofArray, singleton, empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { Daisy_error } from "../../Component/Component.fs.js";
import { createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { IndexView as IndexView_1 } from "./JobShiftCheckItemsEditor.fs.js";
import { unwrap } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";

export class State extends Record {
    constructor(IsUsingShiftPreCheck, IsUsingShiftPostCheck, JobShiftPreChecks, JobShiftPostChecks, OnIsUsingShiftPreCheckChange, OnIsUsingShiftPostCheckChange, OnJobShiftPreChecksChange, OnJobShiftPostChecksChange, Errors) {
        super();
        this.IsUsingShiftPreCheck = IsUsingShiftPreCheck;
        this.IsUsingShiftPostCheck = IsUsingShiftPostCheck;
        this.JobShiftPreChecks = JobShiftPreChecks;
        this.JobShiftPostChecks = JobShiftPostChecks;
        this.OnIsUsingShiftPreCheckChange = OnIsUsingShiftPreCheckChange;
        this.OnIsUsingShiftPostCheckChange = OnIsUsingShiftPostCheckChange;
        this.OnJobShiftPreChecksChange = OnJobShiftPreChecksChange;
        this.OnJobShiftPostChecksChange = OnJobShiftPostChecksChange;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.ShiftPrePostChecks.State", [], State, () => [["IsUsingShiftPreCheck", bool_type], ["IsUsingShiftPostCheck", bool_type], ["JobShiftPreChecks", list_type(JobShiftCheckItem_$reflection())], ["JobShiftPostChecks", list_type(JobShiftCheckItem_$reflection())], ["OnIsUsingShiftPreCheckChange", lambda_type(bool_type, unit_type)], ["OnIsUsingShiftPostCheckChange", lambda_type(bool_type, unit_type)], ["OnJobShiftPreChecksChange", lambda_type(list_type(JobShiftCheckItem_$reflection()), unit_type)], ["OnJobShiftPostChecksChange", lambda_type(list_type(JobShiftCheckItem_$reflection()), unit_type)], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetIsUsingShiftPreCheck", "SetIsUsingShiftPostCheck", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.ShiftPrePostChecks.Msg", [], Msg, () => [[["Item", bool_type]], [["Item", bool_type]], [["Item", class_type("System.Exception")]]]);
}

export function init(isUsingShiftPreCheck, isUsingShiftPostCheck, jobShiftPreChecks, jobShiftPostChecks, onIsUsingShiftPreCheckChange, onIsUsingShiftPostCheckChange, onJobShiftPreChecksChange, onJobShiftPostChecksChange) {
    return [new State(isUsingShiftPreCheck, isUsingShiftPostCheck, jobShiftPreChecks, jobShiftPostChecks, onIsUsingShiftPreCheckChange, onIsUsingShiftPostCheckChange, onJobShiftPreChecksChange, onJobShiftPostChecksChange, empty()), Cmd_none()];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1: {
            const isUsingShiftPostCheck = msg.fields[0];
            state.OnIsUsingShiftPostCheckChange(isUsingShiftPostCheck);
            return [new State(state.IsUsingShiftPreCheck, isUsingShiftPostCheck, state.JobShiftPreChecks, state.JobShiftPostChecks, state.OnIsUsingShiftPreCheckChange, state.OnIsUsingShiftPostCheckChange, state.OnJobShiftPreChecksChange, state.OnJobShiftPostChecksChange, state.Errors), Cmd_none()];
        }
        case 2:
            return [new State(state.IsUsingShiftPreCheck, state.IsUsingShiftPostCheck, state.JobShiftPreChecks, state.JobShiftPostChecks, state.OnIsUsingShiftPreCheckChange, state.OnIsUsingShiftPostCheckChange, state.OnJobShiftPreChecksChange, state.OnJobShiftPostChecksChange, singleton(msg.fields[0].message)), Cmd_none()];
        default: {
            const isUsingShiftPreCheck = msg.fields[0];
            state.OnIsUsingShiftPreCheckChange(isUsingShiftPreCheck);
            return [new State(isUsingShiftPreCheck, state.IsUsingShiftPostCheck, state.JobShiftPreChecks, state.JobShiftPostChecks, state.OnIsUsingShiftPreCheckChange, state.OnIsUsingShiftPostCheckChange, state.OnJobShiftPreChecksChange, state.OnJobShiftPostChecksChange, state.Errors), Cmd_none()];
        }
    }
}

export function IndexView(props) {
    let elems_1, elems, elems_2, elems_4, elems_3, elems_5;
    let patternInput;
    const init_1 = init(props.IsUsingShiftPreCheck, props.IsUsingShiftPostCheck, props.JobShiftPreChecks, props.JobShiftPostChecks, props.OnIsUsingShiftPreCheckChange, props.OnIsUsingShiftPostCheckChange, props.OnJobShiftPreChecksChange, props.OnJobShiftPostChecksChange);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.JobShiftPreChecks, props.JobShiftPostChecks]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const children_4 = ofArray([Daisy_error(state_1.Errors), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-start", "mb-2"])], (elems_1 = [createElement("div", createObj(Helpers_combineClasses("tooltip", ofArray([["data-tip", "Use Shift Pre-Checks"], (elems = [createElement("span", {
        className: "label-text",
        children: "Use Shift Pre-Checks ",
    }), createElement("i", {
        className: join(" ", ["fa fa-solid fa-info-circle"]),
        style: {
            fontSize: 14 + "px",
        },
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])))), createElement("input", createObj(Helpers_combineClasses("checkbox", ofArray([["type", "checkbox"], ["className", join(" ", ["ml-2"])], ["className", "checkbox-sm"], ["checked", state_1.IsUsingShiftPreCheck], ["onChange", (ev) => {
        dispatch(new Msg(0, [ev.target.checked]));
    }]]))))], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", "mt-2 mb-4"], (elems_2 = [createElement(IndexView_1, {
        JobShiftCheckItems: state_1.JobShiftPreChecks,
        OnJobShiftCheckItemsChange: state_1.OnJobShiftPreChecksChange,
        ShiftTemplateSearchDialog: unwrap(props.ShiftPreCheckTemplateSearchDialog),
        Title: "Shift Pre-Checks",
    })], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "justify-start"])], (elems_4 = [createElement("div", createObj(Helpers_combineClasses("tooltip", ofArray([["data-tip", "Use Shift Post-Checks"], (elems_3 = [createElement("span", {
        className: "label-text",
        children: "Use Shift Post-Checks ",
    }), createElement("i", {
        className: join(" ", ["fa fa-solid fa-info-circle"]),
        style: {
            fontSize: 14 + "px",
        },
    })], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))), createElement("input", createObj(Helpers_combineClasses("checkbox", ofArray([["type", "checkbox"], ["className", join(" ", ["ml-2"])], ["className", "checkbox-sm"], ["checked", state_1.IsUsingShiftPostCheck], ["onChange", (ev_1) => {
        dispatch(new Msg(1, [ev_1.target.checked]));
    }]]))))], ["children", reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("div", createObj(ofArray([["className", "mt-2 mb-4"], (elems_5 = [createElement(IndexView_1, {
        JobShiftCheckItems: state_1.JobShiftPostChecks,
        OnJobShiftCheckItemsChange: state_1.OnJobShiftPostChecksChange,
        ShiftTemplateSearchDialog: unwrap(props.ShiftPostCheckTemplateSearchDialog),
        Title: "Shift Post-Checks",
    })], ["children", reactApi.Children.toArray(Array.from(elems_5))])])))]);
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_4)),
    });
}

