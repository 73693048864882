import { Union, Record } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { class_type, union_type, record_type, list_type, string_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { ofArray, singleton, empty } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createElement } from "react";
import React from "react";
import { createObj } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { ButtonType, showModalEx } from "../Component/ModalDialog.fs.js";
import { Daisy_error, Daisy_h2, Daisy_DialogWidth } from "../Component/Component.fs.js";
import { appRoot } from "../../Infrastructure/AppRoot.fs.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../Component/ViewContainer.fs.js";
import { join } from "../../fable_modules/fable-library-js.4.19.3/String.js";
import { map, delay, toList } from "../../fable_modules/fable-library-js.4.19.3/Seq.js";

export class State extends Record {
    constructor(Errors) {
        super();
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.BaseReport.State", [], State, () => [["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["ShowUserReport"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.BaseReport.Msg", [], Msg, () => [[]]);
}

export function init(systemService) {
    return [new State(empty()), Cmd_none()];
}

export function update(msg, state) {
    return [new State(empty()), Cmd_none()];
}

export function MessageCard(messageCardInputProps) {
    let elems_3, children_3, elems, children_1;
    const report = messageCardInputProps.report;
    const description = messageCardInputProps.description;
    const title = messageCardInputProps.title;
    return createElement("div", createObj(Helpers_combineClasses("card", ofArray([["className", "bordered"], ["className", "compact"], ["className", "shadow-lg"], (elems_3 = [(children_3 = ofArray([createElement("h2", createObj(Helpers_combineClasses("card-title", singleton((elems = [createElement("span", {
        children: [title],
    })], ["children", reactApi.Children.toArray(Array.from(elems))]))))), createElement("label", {
        children: description,
    }), (children_1 = singleton(createElement(showModalEx, {
        dialogId: "base-report",
        header: "",
        buttonType: new ButtonType(0, ["View"]),
        width: new Daisy_DialogWidth(3, []),
        heightPercent: "80",
        body: () => report,
        onShow: () => {
        },
        onClose: () => {
        },
    })), createElement("div", {
        className: "card-actions",
        children: reactApi.Children.toArray(Array.from(children_1)),
    }))]), createElement("div", {
        className: "card-body",
        children: reactApi.Children.toArray(Array.from(children_3)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))));
}

export class ReportCard extends Record {
    constructor(Title, Description, Report) {
        super();
        this.Title = Title;
        this.Description = Description;
        this.Report = Report;
    }
}

export function ReportCard_$reflection() {
    return record_type("Presentation.Pages.BaseReport.ReportCard", [], ReportCard, () => [["Title", string_type], ["Description", string_type], ["Report", class_type("Fable.React.ReactElement")]]);
}

export function IndexView(props) {
    let elems_3, elems_2, elems_1;
    let patternInput;
    const init_1 = init(appRoot.SystemService);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, []);
    return container([createElement("div", createObj(singleton((elems_3 = [Daisy_h2(props.reportPageTitle), Daisy_error(patternInput[0].Errors), createElement("div", createObj(singleton((elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-wrap", "-mx-4", "py-12"])], (elems_1 = toList(delay(() => map((reportCard) => {
        let elems;
        return createElement("div", createObj(ofArray([["className", join(" ", ["w-full", "md:w-1/2", "px-4", "mb-8"])], (elems = [createElement(MessageCard, {
            title: reportCard.Title,
            description: reportCard.Description,
            report: reportCard.Report,
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])));
    }, props.reportCards))), ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))]))))], ["children", reactApi.Children.toArray(Array.from(elems_3))]))))]);
}

