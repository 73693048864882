import { Record } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { list_type, int32_type, record_type, bool_type, decimal_type, string_type, option_type, class_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { ExpenseSearchResult, ExpensesResponse, ExpenseStatusModule_ofInt, ExpenseStatusModule_toInt, Expense, ExpenseStatus_$reflection } from "../Entity/Expense.fs.js";
import { fromDate, toUniversalTime } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/DateOffset.js";
import { ofNullable, defaultArg, toNullable } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Option.js";
import { Option_ofString } from "../../Util.fs.js";
import { map } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/List.js";

export class ExpenseDto extends Record {
    constructor(Id, Date$, OrgId, UserId, JobId, Description, Amount, IsReceiptAttached, Status, Notes, CreatedAt, UpdatedAt, ApprovedAt, ApprovedBy, RejectedAt, RejectedBy, RejectionReason) {
        super();
        this.Id = Id;
        this.Date = Date$;
        this.OrgId = OrgId;
        this.UserId = UserId;
        this.JobId = JobId;
        this.Description = Description;
        this.Amount = Amount;
        this.IsReceiptAttached = IsReceiptAttached;
        this.Status = Status;
        this.Notes = Notes;
        this.CreatedAt = CreatedAt;
        this.UpdatedAt = UpdatedAt;
        this.ApprovedAt = ApprovedAt;
        this.ApprovedBy = ApprovedBy;
        this.RejectedAt = RejectedAt;
        this.RejectedBy = RejectedBy;
        this.RejectionReason = RejectionReason;
    }
}

export function ExpenseDto_$reflection() {
    return record_type("Domain.Dto.Expense.ExpenseDto", [], ExpenseDto, () => [["Id", class_type("System.Guid")], ["Date", class_type("System.DateTimeOffset")], ["OrgId", class_type("System.Guid")], ["UserId", class_type("System.Guid")], ["JobId", option_type(class_type("System.Guid"))], ["Description", string_type], ["Amount", decimal_type], ["IsReceiptAttached", bool_type], ["Status", ExpenseStatus_$reflection()], ["Notes", option_type(string_type)], ["CreatedAt", class_type("System.DateTimeOffset")], ["UpdatedAt", class_type("System.DateTimeOffset")], ["ApprovedAt", option_type(class_type("System.DateTimeOffset"))], ["ApprovedBy", option_type(class_type("System.Guid"))], ["RejectedAt", option_type(class_type("System.DateTimeOffset"))], ["RejectedBy", option_type(class_type("System.Guid"))], ["RejectionReason", option_type(string_type)]]);
}

export function ExpenseDtoModule_ofExpense(expense) {
    return new ExpenseDto(expense.Id, expense.Date, expense.OrgId, expense.UserId, expense.JobId, expense.Description, expense.Amount, expense.IsReceiptAttached, expense.Status, expense.Notes, expense.CreatedAt, expense.UpdatedAt, expense.ApprovedAt, expense.ApprovedBy, expense.RejectedAt, expense.RejectedBy, expense.RejectionReason);
}

export function ExpenseDtoModule_toExpense(expenseDto) {
    return new Expense(expenseDto.Id, expenseDto.Date, expenseDto.OrgId, expenseDto.UserId, expenseDto.JobId, expenseDto.Description, expenseDto.Amount, expenseDto.IsReceiptAttached, expenseDto.Status, expenseDto.Notes, expenseDto.CreatedAt, expenseDto.UpdatedAt, expenseDto.ApprovedAt, expenseDto.ApprovedBy, expenseDto.RejectedAt, expenseDto.RejectedBy, expenseDto.RejectionReason);
}

export class ExpenseRawDto extends Record {
    constructor(Id, Date$, OrgId, UserId, JobId, Description, Amount, IsReceiptAttached, Status, Notes, CreatedAt, UpdatedAt, ApprovedAt, ApprovedBy, RejectedAt, RejectedBy, RejectionReason) {
        super();
        this.Id = Id;
        this.Date = Date$;
        this.OrgId = OrgId;
        this.UserId = UserId;
        this.JobId = JobId;
        this.Description = Description;
        this.Amount = Amount;
        this.IsReceiptAttached = IsReceiptAttached;
        this.Status = (Status | 0);
        this.Notes = Notes;
        this.CreatedAt = CreatedAt;
        this.UpdatedAt = UpdatedAt;
        this.ApprovedAt = ApprovedAt;
        this.ApprovedBy = ApprovedBy;
        this.RejectedAt = RejectedAt;
        this.RejectedBy = RejectedBy;
        this.RejectionReason = RejectionReason;
    }
}

export function ExpenseRawDto_$reflection() {
    return record_type("Domain.Dto.Expense.ExpenseRawDto", [], ExpenseRawDto, () => [["Id", class_type("System.Guid")], ["Date", class_type("System.DateTime")], ["OrgId", class_type("System.Guid")], ["UserId", class_type("System.Guid")], ["JobId", class_type("System.Nullable`1", [class_type("System.Guid")])], ["Description", string_type], ["Amount", decimal_type], ["IsReceiptAttached", bool_type], ["Status", int32_type], ["Notes", string_type], ["CreatedAt", class_type("System.DateTime")], ["UpdatedAt", class_type("System.DateTime")], ["ApprovedAt", class_type("System.Nullable`1", [class_type("System.DateTime")])], ["ApprovedBy", class_type("System.Nullable`1", [class_type("System.Guid")])], ["RejectedAt", class_type("System.Nullable`1", [class_type("System.DateTime")])], ["RejectedBy", class_type("System.Nullable`1", [class_type("System.Guid")])], ["RejectionReason", string_type]]);
}

export function ExpenseRawDtoModule_ofExpense(expense) {
    let input_1, input_3;
    return new ExpenseRawDto(expense.Id, toUniversalTime(expense.Date), expense.OrgId, expense.UserId, toNullable(expense.JobId), expense.Description, expense.Amount, expense.IsReceiptAttached, ExpenseStatusModule_toInt(expense.Status), defaultArg(expense.Notes, ""), toUniversalTime(expense.CreatedAt), toUniversalTime(expense.UpdatedAt), toNullable((input_1 = expense.ApprovedAt, (input_1 == null) ? undefined : toUniversalTime(input_1))), toNullable(expense.ApprovedBy), toNullable((input_3 = expense.RejectedAt, (input_3 == null) ? undefined : toUniversalTime(input_3))), toNullable(expense.RejectedBy), defaultArg(expense.RejectionReason, ""));
}

export function ExpenseRawDtoModule_toExpense(expenseRawDto) {
    let input_1, input_3;
    return new Expense(expenseRawDto.Id, fromDate(expenseRawDto.Date), expenseRawDto.OrgId, expenseRawDto.UserId, ofNullable(expenseRawDto.JobId), expenseRawDto.Description, expenseRawDto.Amount, expenseRawDto.IsReceiptAttached, ExpenseStatusModule_ofInt(expenseRawDto.Status), Option_ofString(expenseRawDto.Notes), fromDate(expenseRawDto.CreatedAt), fromDate(expenseRawDto.UpdatedAt), (input_1 = ofNullable(expenseRawDto.ApprovedAt), (input_1 == null) ? undefined : fromDate(input_1)), ofNullable(expenseRawDto.ApprovedBy), (input_3 = ofNullable(expenseRawDto.RejectedAt), (input_3 == null) ? undefined : fromDate(input_3)), ofNullable(expenseRawDto.RejectedBy), Option_ofString(expenseRawDto.RejectionReason));
}

export class ExpensesResponseRawDto extends Record {
    constructor(Expenses, HasMoreRecords) {
        super();
        this.Expenses = Expenses;
        this.HasMoreRecords = HasMoreRecords;
    }
}

export function ExpensesResponseRawDto_$reflection() {
    return record_type("Domain.Dto.Expense.ExpensesResponseRawDto", [], ExpensesResponseRawDto, () => [["Expenses", list_type(ExpenseRawDto_$reflection())], ["HasMoreRecords", bool_type]]);
}

export function ExpensesResponseRawDtoModule_ofExpensesResponse(expensesResponse) {
    return new ExpensesResponseRawDto(map(ExpenseRawDtoModule_ofExpense, expensesResponse.Expenses), expensesResponse.HasMoreRecords);
}

export function ExpensesResponseRawDtoModule_toExpensesResponse(expensesResponseRawDto) {
    return new ExpensesResponse(map(ExpenseRawDtoModule_toExpense, expensesResponseRawDto.Expenses), expensesResponseRawDto.HasMoreRecords);
}

export class ExpenseSearchResultDto extends Record {
    constructor(Id, UserId, FirstName, MiddleName, LastName, Date$, JobTitle, Description, Amount, Status, Notes, CreatedAt, UpdatedAt, ApprovedByUsername, RejectedByUsername, RejectionReason) {
        super();
        this.Id = Id;
        this.UserId = UserId;
        this.FirstName = FirstName;
        this.MiddleName = MiddleName;
        this.LastName = LastName;
        this.Date = Date$;
        this.JobTitle = JobTitle;
        this.Description = Description;
        this.Amount = Amount;
        this.Status = Status;
        this.Notes = Notes;
        this.CreatedAt = CreatedAt;
        this.UpdatedAt = UpdatedAt;
        this.ApprovedByUsername = ApprovedByUsername;
        this.RejectedByUsername = RejectedByUsername;
        this.RejectionReason = RejectionReason;
    }
}

export function ExpenseSearchResultDto_$reflection() {
    return record_type("Domain.Dto.Expense.ExpenseSearchResultDto", [], ExpenseSearchResultDto, () => [["Id", class_type("System.Guid")], ["UserId", class_type("System.Guid")], ["FirstName", string_type], ["MiddleName", string_type], ["LastName", string_type], ["Date", class_type("System.DateTimeOffset")], ["JobTitle", string_type], ["Description", string_type], ["Amount", decimal_type], ["Status", ExpenseStatus_$reflection()], ["Notes", option_type(string_type)], ["CreatedAt", class_type("System.DateTimeOffset")], ["UpdatedAt", class_type("System.DateTimeOffset")], ["ApprovedByUsername", option_type(string_type)], ["RejectedByUsername", option_type(string_type)], ["RejectionReason", option_type(string_type)]]);
}

export function ExpenseSearchResultDtoModule_ofExpenseSearchResult(expenseSearchResult) {
    return new ExpenseSearchResultDto(expenseSearchResult.Id, expenseSearchResult.UserId, expenseSearchResult.FirstName, expenseSearchResult.MiddleName, expenseSearchResult.LastName, expenseSearchResult.Date, expenseSearchResult.JobTitle, expenseSearchResult.Description, expenseSearchResult.Amount, expenseSearchResult.Status, expenseSearchResult.Notes, expenseSearchResult.CreatedAt, expenseSearchResult.UpdatedAt, expenseSearchResult.ApprovedByUsername, expenseSearchResult.RejectedByUsername, expenseSearchResult.RejectionReason);
}

export function ExpenseSearchResultDtoModule_toExpenseSearchResult(expenseSearchResultDto) {
    return new ExpenseSearchResult(expenseSearchResultDto.Id, expenseSearchResultDto.UserId, expenseSearchResultDto.FirstName, expenseSearchResultDto.MiddleName, expenseSearchResultDto.LastName, expenseSearchResultDto.Date, expenseSearchResultDto.JobTitle, expenseSearchResultDto.Description, expenseSearchResultDto.Amount, expenseSearchResultDto.Status, expenseSearchResultDto.Notes, expenseSearchResultDto.CreatedAt, expenseSearchResultDto.UpdatedAt, expenseSearchResultDto.ApprovedByUsername, expenseSearchResultDto.RejectedByUsername, expenseSearchResultDto.RejectionReason);
}

