import { Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type, tuple_type, record_type, string_type, option_type, bool_type, list_type, lambda_type, unit_type, class_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { InvoiceGenerationMethodModule_toString, InvoiceRun as InvoiceRun_2, InvoiceGenerationMethod, InvoiceRunStatus as InvoiceRunStatus_2, InvoiceRunItemDetailAggregate_$reflection, InvoiceRunItemDetail_$reflection, InvoiceRunItem_$reflection, InvoiceRun_$reflection } from "../../../../Logos.Shared/Domain/Entity/Invoice.fs.js";
import { UserDetail_$reflection } from "../../../../Logos.Shared/Domain/Entity/User.fs.js";
import { JobDetail_$reflection } from "../../../../Logos.Shared/Domain/Entity/Job.fs.js";
import { CustomerDetail_$reflection } from "../../../../Logos.Shared/Domain/Entity/Customer.fs.js";
import { TimesheetRunDetail_$reflection } from "../../../../Logos.Shared/Domain/Entity/Shift.fs.js";
import { BusyModule_isSaving, Busy, Busy_$reflection } from "../../SharedView.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { cancelInvoiceRun, approveInvoiceRun, getInvoiceRunData } from "../../../AppDomain/UseCase/Invoice.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../Infrastructure/AppRoot.fs.js";
import { toLocalTime, now, minValue } from "../../../fable_modules/fable-library-js.4.19.3/DateOffset.js";
import { exists, filter, mapIndexed, map, ofArray, tryFind, sortBy, singleton as singleton_1, empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { equals, createObj, comparePrimitives } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { Daisy_DialogWidth, confirmSimple, Daisy_progressState, Daisy_error, Daisy_h4, Daisy_h6 } from "../../Component/Component.fs.js";
import { DateTime_dateTimeToStringWithDayName } from "../../../AppDomain/Util.fs.js";
import { defaultArg } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { map as map_1, empty as empty_1, append, collect, singleton as singleton_2, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { System_Decimal__Decimal_ToDisplayCurrency } from "../../../../Logos.Shared/Util.fs.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { totalHours } from "../../../fable_modules/fable-library-js.4.19.3/TimeSpan.js";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../../Component/ViewContainer.fs.js";
import { showModalDialog } from "../../Component/ModalDialog.fs.js";
import { IndexView as IndexView_1 } from "../Timesheet/TimesheetEdit.fs.js";
import { IndexView as IndexView_2 } from "../Timesheet/TimesheetRuns.fs.js";

export class State extends Record {
    constructor(InvoiceRunId, OnInvoiceUpdated, InvoiceRun, InvoiceRunItems, InvoiceRunItemDetails, InvoiceRunItemDetailAggregates, UserDetails, JobDetails, CustomerDetails, TimesheetRunDetails, IsApprovingInvoiceRun, IsCancellingInvoiceRun, ConfirmingInvoiceRunApproval, ConfirmingInvoiceRunCancellation, TimesheetRunToShow, IsBusy, Errors) {
        super();
        this.InvoiceRunId = InvoiceRunId;
        this.OnInvoiceUpdated = OnInvoiceUpdated;
        this.InvoiceRun = InvoiceRun;
        this.InvoiceRunItems = InvoiceRunItems;
        this.InvoiceRunItemDetails = InvoiceRunItemDetails;
        this.InvoiceRunItemDetailAggregates = InvoiceRunItemDetailAggregates;
        this.UserDetails = UserDetails;
        this.JobDetails = JobDetails;
        this.CustomerDetails = CustomerDetails;
        this.TimesheetRunDetails = TimesheetRunDetails;
        this.IsApprovingInvoiceRun = IsApprovingInvoiceRun;
        this.IsCancellingInvoiceRun = IsCancellingInvoiceRun;
        this.ConfirmingInvoiceRunApproval = ConfirmingInvoiceRunApproval;
        this.ConfirmingInvoiceRunCancellation = ConfirmingInvoiceRunCancellation;
        this.TimesheetRunToShow = TimesheetRunToShow;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.InvoiceRunEdit.State", [], State, () => [["InvoiceRunId", class_type("System.Guid")], ["OnInvoiceUpdated", lambda_type(unit_type, unit_type)], ["InvoiceRun", InvoiceRun_$reflection()], ["InvoiceRunItems", list_type(InvoiceRunItem_$reflection())], ["InvoiceRunItemDetails", list_type(InvoiceRunItemDetail_$reflection())], ["InvoiceRunItemDetailAggregates", list_type(InvoiceRunItemDetailAggregate_$reflection())], ["UserDetails", list_type(UserDetail_$reflection())], ["JobDetails", list_type(JobDetail_$reflection())], ["CustomerDetails", list_type(CustomerDetail_$reflection())], ["TimesheetRunDetails", list_type(TimesheetRunDetail_$reflection())], ["IsApprovingInvoiceRun", bool_type], ["IsCancellingInvoiceRun", bool_type], ["ConfirmingInvoiceRunApproval", bool_type], ["ConfirmingInvoiceRunCancellation", bool_type], ["TimesheetRunToShow", option_type(class_type("System.Guid"))], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetInvoiceRunResponse", "ConfirmingInvoiceRunApproval", "ConfirmingInvoiceRunApprovalResponse", "ConfirmingInvoiceRunCancellation", "ConfirmingInvoiceRunCancellationResponse", "ApproveInvoiceRunResponse", "CancelInvoiceRunResponse", "ShowTimesheetRun", "HideTimesheetRun", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.InvoiceRunEdit.Msg", [], Msg, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(InvoiceRun_$reflection(), list_type(InvoiceRunItem_$reflection()), list_type(InvoiceRunItemDetail_$reflection()), list_type(InvoiceRunItemDetailAggregate_$reflection()), list_type(UserDetail_$reflection()), list_type(JobDetail_$reflection()), list_type(CustomerDetail_$reflection()), list_type(TimesheetRunDetail_$reflection())), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", tuple_type(InvoiceRun_$reflection(), list_type(InvoiceRunItem_$reflection()), list_type(InvoiceRunItemDetail_$reflection()), list_type(InvoiceRunItemDetailAggregate_$reflection()), list_type(UserDetail_$reflection()), list_type(JobDetail_$reflection()), list_type(CustomerDetail_$reflection()), list_type(TimesheetRunDetail_$reflection()))]], [["ErrorValue", list_type(string_type)]]])]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Guid")]], [], [["Item", class_type("System.Exception")]]]);
}

export function getInvoiceRunCmd(invoiceRunId) {
    return cmdOfAsync((Item) => (new Msg(0, [Item])), (Item_1) => (new Msg(9, [Item_1])), () => singleton.Delay(() => getInvoiceRunData(appRoot.InvoiceService, AppRootModule_getSelectedOrgId(), invoiceRunId)));
}

export function approveInvoiceRunCmd(state) {
    return cmdOfAsync((Item) => (new Msg(5, [Item])), (Item_1) => (new Msg(9, [Item_1])), () => singleton.Delay(() => approveInvoiceRun(appRoot.InvoiceService, AppRootModule_getSelectedOrgId(), state.InvoiceRunId)));
}

export function cancelInvoiceRunCmd(state) {
    return cmdOfAsync((Item) => (new Msg(6, [Item])), (Item_1) => (new Msg(9, [Item_1])), () => singleton.Delay(() => cancelInvoiceRun(appRoot.InvoiceService, AppRootModule_getSelectedOrgId(), state.InvoiceRunId)));
}

export function init(invoiceRunId, onInvoiceUpdated) {
    return [new State(invoiceRunId, onInvoiceUpdated, new InvoiceRun_2("00000000-0000-0000-0000-000000000000", "", "00000000-0000-0000-0000-000000000000", new InvoiceRunStatus_2(0, []), minValue(), minValue(), new InvoiceGenerationMethod(0, []), empty()), empty(), empty(), empty(), empty(), empty(), empty(), empty(), false, false, false, false, undefined, new Busy(0, []), empty()), getInvoiceRunCmd(invoiceRunId)];
}

export function update(msg, state) {
    let bind$0040, bind$0040_1;
    switch (msg.tag) {
        case 1:
            return [new State(state.InvoiceRunId, state.OnInvoiceUpdated, state.InvoiceRun, state.InvoiceRunItems, state.InvoiceRunItemDetails, state.InvoiceRunItemDetailAggregates, state.UserDetails, state.JobDetails, state.CustomerDetails, state.TimesheetRunDetails, state.IsApprovingInvoiceRun, state.IsCancellingInvoiceRun, msg.fields[0], state.ConfirmingInvoiceRunCancellation, state.TimesheetRunToShow, state.IsBusy, state.Errors), Cmd_none()];
        case 2:
            return [new State(state.InvoiceRunId, state.OnInvoiceUpdated, state.InvoiceRun, state.InvoiceRunItems, state.InvoiceRunItemDetails, state.InvoiceRunItemDetailAggregates, state.UserDetails, state.JobDetails, state.CustomerDetails, state.TimesheetRunDetails, msg.fields[0], state.IsCancellingInvoiceRun, false, state.ConfirmingInvoiceRunCancellation, state.TimesheetRunToShow, msg.fields[0] ? (new Busy(2, [])) : state.IsBusy, state.Errors), msg.fields[0] ? approveInvoiceRunCmd(state) : Cmd_none()];
        case 3:
            return [new State(state.InvoiceRunId, state.OnInvoiceUpdated, state.InvoiceRun, state.InvoiceRunItems, state.InvoiceRunItemDetails, state.InvoiceRunItemDetailAggregates, state.UserDetails, state.JobDetails, state.CustomerDetails, state.TimesheetRunDetails, state.IsApprovingInvoiceRun, state.IsCancellingInvoiceRun, state.ConfirmingInvoiceRunApproval, msg.fields[0], state.TimesheetRunToShow, state.IsBusy, state.Errors), Cmd_none()];
        case 4:
            return [new State(state.InvoiceRunId, state.OnInvoiceUpdated, state.InvoiceRun, state.InvoiceRunItems, state.InvoiceRunItemDetails, state.InvoiceRunItemDetailAggregates, state.UserDetails, state.JobDetails, state.CustomerDetails, state.TimesheetRunDetails, state.IsApprovingInvoiceRun, false, state.ConfirmingInvoiceRunApproval, false, state.TimesheetRunToShow, msg.fields[0] ? (new Busy(2, [])) : state.IsBusy, state.Errors), msg.fields[0] ? cancelInvoiceRunCmd(state) : Cmd_none()];
        case 5:
            if (msg.fields[0].tag === 1) {
                return [new State(state.InvoiceRunId, state.OnInvoiceUpdated, state.InvoiceRun, state.InvoiceRunItems, state.InvoiceRunItemDetails, state.InvoiceRunItemDetailAggregates, state.UserDetails, state.JobDetails, state.CustomerDetails, state.TimesheetRunDetails, state.IsApprovingInvoiceRun, state.IsCancellingInvoiceRun, state.ConfirmingInvoiceRunApproval, state.ConfirmingInvoiceRunCancellation, state.TimesheetRunToShow, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                state.OnInvoiceUpdated();
                return [new State(state.InvoiceRunId, state.OnInvoiceUpdated, (bind$0040 = state.InvoiceRun, new InvoiceRun_2(bind$0040.Id, bind$0040.RunNumber, bind$0040.OrgId, new InvoiceRunStatus_2(3, [{
                    ApprovedBy: "00000000-0000-0000-0000-000000000000",
                    ApprovedByName: "",
                    DateApproved: now(),
                }]), bind$0040.TimesheetDateFrom, bind$0040.TimesheetDateTo, bind$0040.InvoiceGenerationMethod, bind$0040.JobsSelectedForInvoicing)), state.InvoiceRunItems, state.InvoiceRunItemDetails, state.InvoiceRunItemDetailAggregates, state.UserDetails, state.JobDetails, state.CustomerDetails, state.TimesheetRunDetails, state.IsApprovingInvoiceRun, state.IsCancellingInvoiceRun, state.ConfirmingInvoiceRunApproval, state.ConfirmingInvoiceRunCancellation, state.TimesheetRunToShow, new Busy(0, []), state.Errors), Cmd_none()];
            }
        case 6:
            if (msg.fields[0].tag === 1) {
                return [new State(state.InvoiceRunId, state.OnInvoiceUpdated, state.InvoiceRun, state.InvoiceRunItems, state.InvoiceRunItemDetails, state.InvoiceRunItemDetailAggregates, state.UserDetails, state.JobDetails, state.CustomerDetails, state.TimesheetRunDetails, state.IsApprovingInvoiceRun, state.IsCancellingInvoiceRun, state.ConfirmingInvoiceRunApproval, state.ConfirmingInvoiceRunCancellation, state.TimesheetRunToShow, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                state.OnInvoiceUpdated();
                return [new State(state.InvoiceRunId, state.OnInvoiceUpdated, (bind$0040_1 = state.InvoiceRun, new InvoiceRun_2(bind$0040_1.Id, bind$0040_1.RunNumber, bind$0040_1.OrgId, new InvoiceRunStatus_2(2, [{
                    CanceledBy: "00000000-0000-0000-0000-000000000000",
                    CanceledByName: "",
                    DateCanceled: now(),
                }]), bind$0040_1.TimesheetDateFrom, bind$0040_1.TimesheetDateTo, bind$0040_1.InvoiceGenerationMethod, bind$0040_1.JobsSelectedForInvoicing)), state.InvoiceRunItems, state.InvoiceRunItemDetails, state.InvoiceRunItemDetailAggregates, state.UserDetails, state.JobDetails, state.CustomerDetails, state.TimesheetRunDetails, state.IsApprovingInvoiceRun, state.IsCancellingInvoiceRun, state.ConfirmingInvoiceRunApproval, state.ConfirmingInvoiceRunCancellation, state.TimesheetRunToShow, new Busy(0, []), state.Errors), Cmd_none()];
            }
        case 7:
            return [new State(state.InvoiceRunId, state.OnInvoiceUpdated, state.InvoiceRun, state.InvoiceRunItems, state.InvoiceRunItemDetails, state.InvoiceRunItemDetailAggregates, state.UserDetails, state.JobDetails, state.CustomerDetails, state.TimesheetRunDetails, state.IsApprovingInvoiceRun, state.IsCancellingInvoiceRun, state.ConfirmingInvoiceRunApproval, state.ConfirmingInvoiceRunCancellation, msg.fields[0], state.IsBusy, state.Errors), Cmd_none()];
        case 8:
            return [new State(state.InvoiceRunId, state.OnInvoiceUpdated, state.InvoiceRun, state.InvoiceRunItems, state.InvoiceRunItemDetails, state.InvoiceRunItemDetailAggregates, state.UserDetails, state.JobDetails, state.CustomerDetails, state.TimesheetRunDetails, state.IsApprovingInvoiceRun, state.IsCancellingInvoiceRun, state.ConfirmingInvoiceRunApproval, state.ConfirmingInvoiceRunCancellation, undefined, state.IsBusy, state.Errors), Cmd_none()];
        case 9:
            return [new State(state.InvoiceRunId, state.OnInvoiceUpdated, state.InvoiceRun, state.InvoiceRunItems, state.InvoiceRunItemDetails, state.InvoiceRunItemDetailAggregates, state.UserDetails, state.JobDetails, state.CustomerDetails, state.TimesheetRunDetails, state.IsApprovingInvoiceRun, state.IsCancellingInvoiceRun, state.ConfirmingInvoiceRunApproval, state.ConfirmingInvoiceRunCancellation, state.TimesheetRunToShow, new Busy(0, []), singleton_1(msg.fields[0].message)), Cmd_none()];
        default:
            if (msg.fields[0].tag === 1) {
                return [new State(state.InvoiceRunId, state.OnInvoiceUpdated, state.InvoiceRun, state.InvoiceRunItems, state.InvoiceRunItemDetails, state.InvoiceRunItemDetailAggregates, state.UserDetails, state.JobDetails, state.CustomerDetails, state.TimesheetRunDetails, state.IsApprovingInvoiceRun, state.IsCancellingInvoiceRun, state.ConfirmingInvoiceRunApproval, state.ConfirmingInvoiceRunCancellation, state.TimesheetRunToShow, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.InvoiceRunId, state.OnInvoiceUpdated, msg.fields[0].fields[0][0], msg.fields[0].fields[0][1], msg.fields[0].fields[0][2], msg.fields[0].fields[0][3], msg.fields[0].fields[0][4], msg.fields[0].fields[0][5], msg.fields[0].fields[0][6], sortBy((x) => x.RunNumber, msg.fields[0].fields[0][7], {
                    Compare: comparePrimitives,
                }), state.IsApprovingInvoiceRun, state.IsCancellingInvoiceRun, state.ConfirmingInvoiceRunApproval, state.ConfirmingInvoiceRunCancellation, state.TimesheetRunToShow, new Busy(0, []), state.Errors), Cmd_none()];
            }
    }
}

export function invoiceRunItemView(invoiceGenerationMethod, invoiceRunItem, customers, jobs) {
    let elems_1, elems, input_1, input_3;
    return createElement("div", createObj(ofArray([["className", join(" ", ["w-full", "grid", "grid-cols-5"])], (elems_1 = [Daisy_h6("Created"), createElement("div", {
        children: DateTime_dateTimeToStringWithDayName(toLocalTime(invoiceRunItem.Created)),
    }), createElement("div", {}), Daisy_h6("Xero Invoice Number"), createElement("div", createObj(singleton_1((elems = [createElement("a", {
        href: `https://go.xero.com/app/!-f7WD/invoicing/edit/${invoiceRunItem.XeroInvoiceId}`,
        target: "_blank",
        children: defaultArg(invoiceRunItem.XeroInvoiceNumber, ""),
        style: {
            color: "blue",
            textDecorationLine: "underline",
            cursor: "pointer",
        },
    })], ["children", reactApi.Children.toArray(Array.from(elems))])))), Daisy_h6("Customer"), createElement("div", {
        children: defaultArg((input_1 = tryFind((x) => (x.Id === invoiceRunItem.CustomerId), customers), (input_1 == null) ? undefined : input_1.BusinessName), ""),
    }), createElement("div", {}), createElement("div", {}), createElement("div", {}), Daisy_h6("Generation Method"), createElement("div", {
        children: InvoiceGenerationMethodModule_toString(invoiceGenerationMethod),
    }), createElement("div", {}), createElement("div", {}), createElement("div", {}), Daisy_h6("Job"), createElement("div", {
        children: defaultArg((input_3 = tryFind((x_2) => (x_2.Id === invoiceRunItem.JobId), jobs), (input_3 == null) ? undefined : input_3.Title), ""),
    }), createElement("div", {}), createElement("div", {}), createElement("div", {}), Daisy_h6("Amount"), createElement("div", createObj(toList(delay(() => singleton_2(["children", System_Decimal__Decimal_ToDisplayCurrency(invoiceRunItem.Amount)]))))), createElement("div", {}), createElement("div", {}), createElement("div", {})], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

export function invoiceRunItemDetailView(invoiceRunItemDetails, invoiceRunItemDetailAggregates, users) {
    let elems_1, children_2, children, children_4;
    return createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["w-full"])], (elems_1 = [(children_2 = singleton_1((children = ofArray([createElement("th", {
        width: 40 + "%",
        children: "User",
    }), createElement("th", {
        width: 20 + "%",
        children: "Duration (hh:mm)",
    }), createElement("th", {
        className: "text-right",
        width: 20 + "%",
        children: "Rate",
    }), createElement("th", {
        className: "text-right",
        width: 20 + "%",
        children: "Amount",
    })]), createElement("tr", {
        children: reactApi.Children.toArray(Array.from(children)),
    }))), createElement("thead", {
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), (children_4 = toList(delay(() => collect((invoiceRunItemDetailAggregateWithUsername) => {
        let elems, input_3, copyOfStruct;
        const invoiceRunItemDetailAggregate = invoiceRunItemDetailAggregateWithUsername.InvoiceRunItemDetailAggregates;
        return singleton_2(createElement("tr", createObj(ofArray([["className", "hover"], (elems = [createElement("td", {
            children: defaultArg((input_3 = tryFind((x_1) => (x_1.Id === invoiceRunItemDetailAggregate.UserId), users), (input_3 == null) ? undefined : input_3.Name), ""),
        }), createElement("td", {
            children: (copyOfStruct = totalHours(invoiceRunItemDetailAggregate.TotalWorkDuration), copyOfStruct.toString()),
        }), createElement("td", createObj(toList(delay(() => append(singleton_2(["className", "text-right"]), delay(() => singleton_2(["children", System_Decimal__Decimal_ToDisplayCurrency(invoiceRunItemDetailAggregate.Rate)]))))))), createElement("td", createObj(toList(delay(() => append(singleton_2(["className", "text-right"]), delay(() => singleton_2(["children", System_Decimal__Decimal_ToDisplayCurrency(invoiceRunItemDetailAggregate.Amount)])))))))], ["children", reactApi.Children.toArray(Array.from(elems))])]))));
    }, sortBy((x_9) => x_9.Username, map((x) => {
        let input_1;
        return {
            InvoiceRunItemDetailAggregates: x,
            Username: defaultArg((input_1 = tryFind((y) => (y.Id === x.UserId), users), (input_1 == null) ? undefined : input_1.Name), x.UserId),
        };
    }, invoiceRunItemDetailAggregates), {
        Compare: comparePrimitives,
    })))), createElement("tbody", {
        children: reactApi.Children.toArray(Array.from(children_4)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))));
}

export function IndexView(props) {
    let patternInput;
    const init_1 = init(props.invoiceRunId, props.onInvoiceUpdated);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const getJobTitle = (jobId) => {
        let input_1;
        return defaultArg((input_1 = tryFind((x) => (x.Id === jobId), state_1.JobDetails), (input_1 == null) ? undefined : input_1.Title), "");
    };
    return container(toList(delay(() => {
        let elems_2, elems_1, elems;
        return append(singleton_2(createElement("div", createObj(singleton_1((elems_2 = [Daisy_h4((`Invoice Run # - ${state_1.InvoiceRun.RunNumber}`).trim()), Daisy_h6((`Generation Method: ${InvoiceGenerationMethodModule_toString(state_1.InvoiceRun.InvoiceGenerationMethod)}`).trim()), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-wrap", "mb-2"])], (elems_1 = [createElement("div", {
            className: "mr-1 font-semibold",
            children: "Jobs Selected for Invoicing:",
        }), createElement("div", createObj(singleton_1((elems = map((tupledArg) => createElement("span", {
            className: join(" ", ["px-1", "py-1", "rounded", "text-xs", "font-semibold", "mr-1", tupledArg[1] ? "bg-green-200" : "bg-red-200"]),
            children: tupledArg[0],
        }), sortBy((x_3) => x_3[0], mapIndexed((i, x_2) => {
            let input_3;
            return defaultArg((input_3 = tryFind((y) => (y.JobId === x_2), state_1.InvoiceRunItems), (input_3 == null) ? undefined : [getJobTitle(input_3.JobId), true]), [getJobTitle(x_2), false]);
        }, state_1.InvoiceRun.JobsSelectedForInvoicing), {
            Compare: comparePrimitives,
        })), ["children", reactApi.Children.toArray(Array.from(elems))]))))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))]))))), delay(() => append(singleton_2(createElement("div", createObj(Helpers_combineClasses("divider", empty())))), delay(() => append(singleton_2(Daisy_error(state_1.Errors)), delay(() => {
            let children_1;
            return append(singleton_2((children_1 = singleton_1(createElement(Daisy_progressState, {
                busy: state_1.IsBusy,
            })), createElement("div", {
                className: "form-control",
                children: reactApi.Children.toArray(Array.from(children_1)),
            }))), delay(() => append(state_1.ConfirmingInvoiceRunApproval ? singleton_2(confirmSimple(new Daisy_DialogWidth(2, []), "Would you like to approve this invoice run?", (_arg_3) => {
                dispatch(new Msg(2, [false]));
            }, (_arg_4) => {
                dispatch(new Msg(2, [true]));
            })) : singleton_2(createElement("div", {})), delay(() => append(state_1.ConfirmingInvoiceRunCancellation ? singleton_2(confirmSimple(new Daisy_DialogWidth(2, []), "Would you like to cancel this invoice run?", (_arg_5) => {
                dispatch(new Msg(4, [false]));
            }, (_arg_6) => {
                dispatch(new Msg(4, [true]));
            })) : singleton_2(createElement("div", {})), delay(() => {
                let matchValue, x_5;
                return append((matchValue = state_1.TimesheetRunToShow, (matchValue == null) ? singleton_2(react.createElement(react.Fragment, {})) : ((x_5 = matchValue, singleton_2(createElement(showModalDialog, {
                    dialogId: x_5,
                    header: "",
                    width: new Daisy_DialogWidth(4, []),
                    heightPercent: "90",
                    body: () => createElement(IndexView_1, {
                        onTimesheetUpdated: () => {
                        },
                        timesheetRunId: x_5,
                    }),
                    onShow: () => {
                    },
                    onClose: () => {
                        dispatch(new Msg(8, []));
                    },
                }))))), delay(() => {
                    let elems_6;
                    return append(singleton_2(createElement("div", createObj(Helpers_combineClasses("navbar", ofArray([["disabled", !equals(state_1.IsBusy, new Busy(0, []))], (elems_6 = [createElement("button", createObj(Helpers_combineClasses("btn", toList(delay(() => append((state_1.IsApprovingInvoiceRun && BusyModule_isSaving(state_1.IsBusy)) ? singleton_2(["className", "loading"]) : empty_1(), delay(() => append(singleton_2(["className", "btn-success"]), delay(() => append(singleton_2(["className", "btn-sm"]), delay(() => {
                        let matchValue_1;
                        return append(singleton_2(["disabled", ((matchValue_1 = state_1.InvoiceRun.InvoiceRunStatus, (matchValue_1.tag === 3) ? true : (matchValue_1.tag === 2))) ? true : BusyModule_isSaving(state_1.IsBusy)]), delay(() => append(singleton_2(["children", "Approve Invoice Run"]), delay(() => singleton_2(["onClick", (_arg_14) => {
                            dispatch(new Msg(1, [true]));
                        }])))));
                    }))))))))))), createElement("div", {
                        className: join(" ", ["ml-2"]),
                    }), createElement("button", createObj(Helpers_combineClasses("btn", toList(delay(() => append((state_1.IsCancellingInvoiceRun && BusyModule_isSaving(state_1.IsBusy)) ? singleton_2(["className", "loading"]) : empty_1(), delay(() => append(singleton_2(["className", "btn-error"]), delay(() => append(singleton_2(["className", "btn-sm"]), delay(() => {
                        let matchValue_2;
                        return append(singleton_2(["disabled", ((matchValue_2 = state_1.InvoiceRun.InvoiceRunStatus, (matchValue_2.tag === 3) ? true : (matchValue_2.tag === 2))) ? true : BusyModule_isSaving(state_1.IsBusy)]), delay(() => append(singleton_2(["children", "Cancel Invoice Run"]), delay(() => singleton_2(["onClick", (_arg_17) => {
                            dispatch(new Msg(3, [true]));
                        }])))));
                    })))))))))))], ["children", reactApi.Children.toArray(Array.from(elems_6))])]))))), delay(() => {
                        let elems_9;
                        return singleton_2(createElement("div", createObj(ofArray([["className", join(" ", ["w-full"])], (elems_9 = toList(delay(() => map_1((invoiceRunItem) => {
                            let elems_8, children_7;
                            return createElement("div", createObj(Helpers_combineClasses("card", ofArray([["className", "mb-2"], ["className", "bordered"], ["className", "compact"], (elems_8 = [(children_7 = toList(delay(() => append(singleton_2(invoiceRunItemView(state_1.InvoiceRun.InvoiceGenerationMethod, invoiceRunItem, state_1.CustomerDetails, state_1.JobDetails)), delay(() => {
                                const invoiceRunItemDetails = filter((x_6) => (x_6.InvoiceRunItemId === invoiceRunItem.Id), state_1.InvoiceRunItemDetails);
                                const invoiceRunItemDetailAggregates = filter((x_7) => (x_7.InvoiceRunItemId === invoiceRunItem.Id), state_1.InvoiceRunItemDetailAggregates);
                                return append(singleton_2(createElement(IndexView_2, {
                                    TimesheetRuns: filter((x_8) => exists((y_3) => (y_3.TimesheetRunId === x_8.Id), invoiceRunItemDetails), state_1.TimesheetRunDetails),
                                    Title: "Following timesheets are included in this Invoice:",
                                })), delay(() => singleton_2(invoiceRunItemDetailView(invoiceRunItemDetails, invoiceRunItemDetailAggregates, state_1.UserDetails))));
                            })))), createElement("div", {
                                className: "card-body",
                                children: reactApi.Children.toArray(Array.from(children_7)),
                            }))], ["children", reactApi.Children.toArray(Array.from(elems_8))])]))));
                        }, state_1.InvoiceRunItems))), ["children", reactApi.Children.toArray(Array.from(elems_9))])]))));
                    }));
                }));
            }))))));
        }))))));
    })));
}

