import { toString, Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { class_type, union_type, record_type, list_type, option_type, string_type, bool_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { SkillDto, SkillDto_$reflection } from "../../../../Logos.Shared/Domain/Dto/User.fs.js";
import { BusyModule_isSaving, Busy, Busy_$reflection } from "../../SharedView.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { Guid_parse } from "../../../../Logos.Shared/Util.fs.js";
import { updateSkill, addSkill, getSkill } from "../../../AppDomain/UseCase/Skill.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../Infrastructure/AppRoot.fs.js";
import { ofArray, empty, singleton as singleton_1 } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { join, isNullOrEmpty } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../../Component/ViewContainer.fs.js";
import { Daisy_progressState, Daisy_error, Daisy_h4 } from "../../Component/Component.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { empty as empty_1, singleton as singleton_2, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";

export class State extends Record {
    constructor(IsNewSkill, Name, SkillDto, IsBusy, Errors) {
        super();
        this.IsNewSkill = IsNewSkill;
        this.Name = Name;
        this.SkillDto = SkillDto;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.SkillEdit.State", [], State, () => [["IsNewSkill", bool_type], ["Name", string_type], ["SkillDto", option_type(SkillDto_$reflection())], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetName", "GetSkillResponse", "SaveSkill", "SaveSkillResponse", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.SkillEdit.Msg", [], Msg, () => [[["Item", string_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [option_type(SkillDto_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", option_type(SkillDto_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [option_type(class_type("System.Guid")), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", option_type(class_type("System.Guid"))]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Exception")]]]);
}

export function getSkillCmd(skillId) {
    return cmdOfAsync((Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(4, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_1;
        const value_1 = Guid_parse(skillId);
        asyncResult_1 = singleton.Return(value_1);
        return singleton.Bind(asyncResult_1, (input_1) => {
            const input_2 = input_1;
            if (input_2.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
            }
            else {
                return getSkill(appRoot.SkillService, AppRootModule_getSelectedOrgId(), input_2.fields[0]);
            }
        });
    }));
}

export function saveSkillCmd(state) {
    return cmdOfAsync((Item) => (new Msg(3, [Item])), (Item_1) => (new Msg(4, [Item_1])), () => singleton.Delay(() => {
        let asyncResult;
        let value_1_1;
        if (state.IsNewSkill) {
            value_1_1 = (new FSharpResult$2(0, [new SkillDto("00000000-0000-0000-0000-000000000000", state.Name)]));
        }
        else {
            const option_1 = state.SkillDto;
            value_1_1 = ((option_1 == null) ? (new FSharpResult$2(1, [singleton_1("Skill data not found.")])) : (new FSharpResult$2(0, [option_1])));
        }
        asyncResult = singleton.Return(value_1_1);
        return singleton.Bind(asyncResult, (input_1_2) => {
            const input_10 = input_1_2;
            if (input_10.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_10.fields[0]]));
            }
            else {
                const updatedSkillDto = new SkillDto(input_10.fields[0].Id, state.Name);
                if (state.IsNewSkill) {
                    const x_1 = addSkill(appRoot.SkillService, AppRootModule_getSelectedOrgId(), updatedSkillDto);
                    return singleton.Bind(x_1, (x$0027) => {
                        let value_2;
                        const input_2 = x$0027;
                        value_2 = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [input_2.fields[0]])));
                        return singleton.Return(value_2);
                    });
                }
                else {
                    const x_3 = updateSkill(appRoot.SkillService, AppRootModule_getSelectedOrgId(), updatedSkillDto);
                    return singleton.Bind(x_3, (x$0027_1) => {
                        let value_4;
                        const input_6 = x$0027_1;
                        value_4 = ((input_6.tag === 1) ? (new FSharpResult$2(1, [input_6.fields[0]])) : (new FSharpResult$2(0, [undefined])));
                        return singleton.Return(value_4);
                    });
                }
            }
        });
    }));
}

export function init(skillId) {
    const isNewSkill = isNullOrEmpty(skillId);
    return [new State(isNewSkill, "", undefined, isNewSkill ? (new Busy(0, [])) : (new Busy(1, [])), empty()), isNewSkill ? Cmd_none() : getSkillCmd(skillId)];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            if (msg.fields[0].tag === 1) {
                return [new State(state.IsNewSkill, state.Name, state.SkillDto, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else if (msg.fields[0].fields[0] == null) {
                return [new State(state.IsNewSkill, state.Name, state.SkillDto, state.IsBusy, singleton_1("Skill not found.")), Cmd_none()];
            }
            else {
                const x_1 = msg.fields[0].fields[0];
                return [new State(false, x_1.Name, x_1, new Busy(0, []), state.Errors), Cmd_none()];
            }
        case 2:
            return [new State(state.IsNewSkill, state.Name, state.SkillDto, new Busy(2, []), empty()), saveSkillCmd(state)];
        case 3:
            if (msg.fields[0].tag === 1) {
                return [new State(state.IsNewSkill, state.Name, state.SkillDto, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else if (msg.fields[0].fields[0] == null) {
                return [new State(state.IsNewSkill, state.Name, state.SkillDto, new Busy(0, []), state.Errors), Cmd_none()];
            }
            else {
                const x_3 = msg.fields[0].fields[0];
                return [new State(false, state.Name, state.SkillDto, new Busy(0, []), state.Errors), state.IsNewSkill ? getSkillCmd(x_3) : Cmd_none()];
            }
        case 4:
            return [new State(state.IsNewSkill, state.Name, state.SkillDto, new Busy(0, []), singleton_1(toString(msg.fields[0]))), Cmd_none()];
        default:
            return [new State(state.IsNewSkill, msg.fields[0], state.SkillDto, state.IsBusy, state.Errors), Cmd_none()];
    }
}

export function IndexView(indexViewInputProps) {
    let children, children_2, elems_3, children_9, children_7;
    const skillId = indexViewInputProps.skillId;
    let patternInput;
    const init_1 = init(skillId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return container([(children = singleton_1(Daisy_h4((`Skill - ${state_1.Name}`).trim())), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    })), Daisy_error(state_1.Errors), (children_2 = singleton_1(createElement(Daisy_progressState, {
        busy: state_1.IsBusy,
    })), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), createElement("button", createObj(Helpers_combineClasses("btn", toList(delay(() => append(BusyModule_isSaving(state_1.IsBusy) ? singleton_2(["className", "loading"]) : empty_1(), delay(() => append(singleton_2(["className", join(" ", ["my-4"])]), delay(() => append(singleton_2(["className", "btn-primary"]), delay(() => append(singleton_2(["className", "btn-sm"]), delay(() => append(singleton_2(["className", join(" ", ["mr-4"])]), delay(() => append(singleton_2(["children", "Save"]), delay(() => singleton_2(["onClick", (_arg_3) => {
        dispatch(new Msg(2, []));
    }])))))))))))))))))), createElement("div", createObj(Helpers_combineClasses("divider", empty()))), createElement("div", createObj(singleton_1((elems_3 = [(children_9 = ofArray([(children_7 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Skill name",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_7)),
    })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", state_1.Name], ["placeholder", "Skill name"], ["onChange", (ev) => {
        dispatch(new Msg(0, [ev.target.value]));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_9)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_3))]))))]);
}

