import { toString as toString_1, Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type, record_type, lambda_type, unit_type, option_type, list_type, string_type, bool_type, class_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { SkillRatingModule_getLookupValues, SkillRatingModule_toString, SkillRatingModule_ofString, Skill_$reflection } from "../../../../Logos.Shared/Domain/Entity/User.fs.js";
import { JobUserSkillDto as JobUserSkillDto_1, JobUserSkillDto_$reflection } from "../../../../Logos.Shared/Domain/Dto/Job.fs.js";
import { BusyModule_isSaving, Busy, Busy_$reflection } from "../../SharedView.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { getAllSkills } from "../../../AppDomain/UseCase/Skill.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../Infrastructure/AppRoot.fs.js";
import { ofArray, tryHead, empty, singleton as singleton_1, map } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { SkillDtoModule_toSkill } from "../../../../Logos.Shared/Domain/Dto/User.fs.js";
import { updateJobUserSkill, addJobUserSkill, getJobUserSkill } from "../../../AppDomain/UseCase/Job.fs.js";
import { toString, fromParts } from "../../../fable_modules/fable-library-js.4.19.3/Decimal.js";
import Decimal from "../../../fable_modules/fable-library-js.4.19.3/Decimal.js";
import { Guid_parse } from "../../../../Logos.Shared/Util.fs.js";
import { parse } from "../../../fable_modules/fable-library-js.4.19.3/Int32.js";
import { Cmd_none, Cmd_batch } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { map as map_1, empty as empty_1, singleton as singleton_2, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { defaultArg } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { createObj, int32ToString } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../../Component/ViewContainer.fs.js";
import { Daisy_progressState, Daisy_error, Daisy_h4 } from "../../Component/Component.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";

export class State extends Record {
    constructor(Id, JobId, IsNew, SelectedSkillId, Skills, UserCount, Rating, EstimatedPrice, AgreedPrice, JobUserSkillDto, OnSaved, IsBusy, Errors) {
        super();
        this.Id = Id;
        this.JobId = JobId;
        this.IsNew = IsNew;
        this.SelectedSkillId = SelectedSkillId;
        this.Skills = Skills;
        this.UserCount = UserCount;
        this.Rating = Rating;
        this.EstimatedPrice = EstimatedPrice;
        this.AgreedPrice = AgreedPrice;
        this.JobUserSkillDto = JobUserSkillDto;
        this.OnSaved = OnSaved;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.JobUserSkillEdit.State", [], State, () => [["Id", class_type("System.Guid")], ["JobId", class_type("System.Guid")], ["IsNew", bool_type], ["SelectedSkillId", string_type], ["Skills", list_type(Skill_$reflection())], ["UserCount", string_type], ["Rating", string_type], ["EstimatedPrice", string_type], ["AgreedPrice", string_type], ["JobUserSkillDto", option_type(JobUserSkillDto_$reflection())], ["OnSaved", lambda_type(unit_type, unit_type)], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetSkillId", "SetUserCount", "SetRating", "SetEstimatedPrice", "SetAgreedPrice", "GetSkillsResponse", "GetJobUserSkillResponse", "Save", "SaveResponse", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.JobUserSkillEdit.Msg", [], Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(Skill_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", list_type(Skill_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [option_type(JobUserSkillDto_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", option_type(JobUserSkillDto_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [option_type(class_type("System.Guid")), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", option_type(class_type("System.Guid"))]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Exception")]]]);
}

export function getSkillsCmd() {
    return cmdOfAsync((Item) => (new Msg(5, [Item])), (Item_1) => (new Msg(9, [Item_1])), () => singleton.Delay(() => {
        const input = getAllSkills(appRoot.SkillService, AppRootModule_getSelectedOrgId());
        return singleton.Bind(input, (x$0027) => {
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [map(SkillDtoModule_toSkill, input_2.fields[0])])));
            return singleton.Return(value);
        });
    }));
}

export function getJobUserSkillCmd(jobId, jobUserSkillId) {
    return cmdOfAsync((Item) => (new Msg(6, [Item])), (Item_1) => (new Msg(9, [Item_1])), () => singleton.Delay(() => getJobUserSkill(appRoot.JobService, AppRootModule_getSelectedOrgId(), jobId, jobUserSkillId)));
}

export function saveCmd(state) {
    return cmdOfAsync((Item) => (new Msg(8, [Item])), (Item_1) => (new Msg(9, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_1;
        let value_1_1;
        if (state.IsNew) {
            value_1_1 = (new FSharpResult$2(0, [new JobUserSkillDto_1("00000000-0000-0000-0000-000000000000", state.JobId, "00000000-0000-0000-0000-000000000000", 0, 0, fromParts(0, 0, 0, false, 0), fromParts(0, 0, 0, false, 0))]));
        }
        else {
            const option_1 = state.JobUserSkillDto;
            value_1_1 = ((option_1 == null) ? (new FSharpResult$2(1, [singleton_1("Job User Skill data not found.")])) : (new FSharpResult$2(0, [option_1])));
        }
        asyncResult_1 = singleton.Return(value_1_1);
        return singleton.Bind(asyncResult_1, (input_1_3) => {
            const input_13 = input_1_3;
            if (input_13.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_13.fields[0]]));
            }
            else {
                let asyncResult;
                const value_1_2 = Guid_parse(state.SelectedSkillId);
                asyncResult = singleton.Return(value_1_2);
                return singleton.Bind(asyncResult, (input_1_2) => {
                    const input_10 = input_1_2;
                    if (input_10.tag === 1) {
                        return singleton.Return(new FSharpResult$2(1, [input_10.fields[0]]));
                    }
                    else {
                        const updatedJobUserSkillDtoDto = new JobUserSkillDto_1(input_13.fields[0].Id, state.JobId, input_10.fields[0], parse(state.UserCount, 511, false, 32), SkillRatingModule_ofString(state.Rating), new Decimal(state.EstimatedPrice), new Decimal(state.AgreedPrice));
                        if (state.IsNew) {
                            const x_16 = addJobUserSkill(appRoot.JobService, AppRootModule_getSelectedOrgId(), updatedJobUserSkillDtoDto);
                            return singleton.Bind(x_16, (x$0027) => {
                                let value_5;
                                const input_2 = x$0027;
                                value_5 = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [(state.OnSaved(), input_2.fields[0])])));
                                return singleton.Return(value_5);
                            });
                        }
                        else {
                            const x_18 = updateJobUserSkill(appRoot.JobService, AppRootModule_getSelectedOrgId(), updatedJobUserSkillDtoDto);
                            return singleton.Bind(x_18, (x$0027_1) => {
                                let value_7;
                                const input_6 = x$0027_1;
                                value_7 = ((input_6.tag === 1) ? (new FSharpResult$2(1, [input_6.fields[0]])) : (new FSharpResult$2(0, [(state.OnSaved(), undefined)])));
                                return singleton.Return(value_7);
                            });
                        }
                    }
                });
            }
        });
    }));
}

export function init(jobId, jobUserSkillId, onSaved) {
    const isNew = jobUserSkillId == null;
    return [new State("00000000-0000-0000-0000-000000000000", jobId, isNew, "", empty(), "0", SkillRatingModule_toString(0), "0", "0", undefined, onSaved, isNew ? (new Busy(0, [])) : (new Busy(1, [])), empty()), Cmd_batch(toList(delay(() => append(singleton_2(getSkillsCmd()), delay(() => {
        const matchValue = jobUserSkillId;
        return (matchValue == null) ? singleton_2(Cmd_none()) : singleton_2(getJobUserSkillCmd(jobId, matchValue));
    })))))];
}

export function update(msg, state) {
    let input_1;
    switch (msg.tag) {
        case 1:
            return [new State(state.Id, state.JobId, state.IsNew, state.SelectedSkillId, state.Skills, msg.fields[0], state.Rating, state.EstimatedPrice, state.AgreedPrice, state.JobUserSkillDto, state.OnSaved, state.IsBusy, state.Errors), Cmd_none()];
        case 2:
            return [new State(state.Id, state.JobId, state.IsNew, state.SelectedSkillId, state.Skills, state.UserCount, msg.fields[0], state.EstimatedPrice, state.AgreedPrice, state.JobUserSkillDto, state.OnSaved, state.IsBusy, state.Errors), Cmd_none()];
        case 3:
            return [new State(state.Id, state.JobId, state.IsNew, state.SelectedSkillId, state.Skills, state.UserCount, state.Rating, msg.fields[0], state.AgreedPrice, state.JobUserSkillDto, state.OnSaved, state.IsBusy, state.Errors), Cmd_none()];
        case 4:
            return [new State(state.Id, state.JobId, state.IsNew, state.SelectedSkillId, state.Skills, state.UserCount, state.Rating, state.EstimatedPrice, msg.fields[0], state.JobUserSkillDto, state.OnSaved, state.IsBusy, state.Errors), Cmd_none()];
        case 5:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Id, state.JobId, state.IsNew, state.SelectedSkillId, state.Skills, state.UserCount, state.Rating, state.EstimatedPrice, state.AgreedPrice, state.JobUserSkillDto, state.OnSaved, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.Id, state.JobId, state.IsNew, defaultArg((input_1 = tryHead(msg.fields[0].fields[0]), (input_1 == null) ? undefined : input_1.Id), ""), msg.fields[0].fields[0], state.UserCount, state.Rating, state.EstimatedPrice, state.AgreedPrice, state.JobUserSkillDto, state.OnSaved, new Busy(0, []), state.Errors), Cmd_none()];
            }
        case 6:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Id, state.JobId, state.IsNew, state.SelectedSkillId, state.Skills, state.UserCount, state.Rating, state.EstimatedPrice, state.AgreedPrice, state.JobUserSkillDto, state.OnSaved, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else if (msg.fields[0].fields[0] == null) {
                return [new State(state.Id, state.JobId, state.IsNew, state.SelectedSkillId, state.Skills, state.UserCount, state.Rating, state.EstimatedPrice, state.AgreedPrice, state.JobUserSkillDto, state.OnSaved, new Busy(0, []), singleton_1("Job User Skill not found.")), Cmd_none()];
            }
            else {
                const x_8 = msg.fields[0].fields[0];
                return [new State(x_8.Id, state.JobId, false, x_8.SkillId, state.Skills, int32ToString(x_8.UserCount), SkillRatingModule_toString(defaultArg(x_8.Rating, 0)), toString(x_8.EstimatedPrice), toString(x_8.AgreedPrice), x_8, state.OnSaved, new Busy(0, []), state.Errors), Cmd_none()];
            }
        case 7: {
            const updatedState = new State(state.Id, state.JobId, state.IsNew, state.SelectedSkillId, state.Skills, state.UserCount, state.Rating, state.EstimatedPrice, state.AgreedPrice, state.JobUserSkillDto, state.OnSaved, new Busy(2, []), empty());
            return [updatedState, saveCmd(updatedState)];
        }
        case 8:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Id, state.JobId, state.IsNew, state.SelectedSkillId, state.Skills, state.UserCount, state.Rating, state.EstimatedPrice, state.AgreedPrice, state.JobUserSkillDto, state.OnSaved, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else if (msg.fields[0].fields[0] == null) {
                return [new State(state.Id, state.JobId, state.IsNew, state.SelectedSkillId, state.Skills, state.UserCount, state.Rating, state.EstimatedPrice, state.AgreedPrice, state.JobUserSkillDto, state.OnSaved, new Busy(0, []), state.Errors), Cmd_none()];
            }
            else {
                const x_16 = msg.fields[0].fields[0];
                return [new State(x_16, state.JobId, state.IsNew, state.SelectedSkillId, state.Skills, state.UserCount, state.Rating, state.EstimatedPrice, state.AgreedPrice, state.JobUserSkillDto, state.OnSaved, new Busy(0, []), state.Errors), state.IsNew ? getJobUserSkillCmd(state.JobId, x_16) : Cmd_none()];
            }
        case 9:
            return [new State(state.Id, state.JobId, state.IsNew, state.SelectedSkillId, state.Skills, state.UserCount, state.Rating, state.EstimatedPrice, state.AgreedPrice, state.JobUserSkillDto, state.OnSaved, new Busy(0, []), singleton_1(toString_1(msg.fields[0]))), Cmd_none()];
        default:
            return [new State(state.Id, state.JobId, state.IsNew, msg.fields[0], state.Skills, state.UserCount, state.Rating, state.EstimatedPrice, state.AgreedPrice, state.JobUserSkillDto, state.OnSaved, state.IsBusy, state.Errors), Cmd_none()];
    }
}

export function IndexView(props) {
    let children, children_2, elems_13;
    let patternInput;
    const init_1 = init(props.jobId, props.jobUserSkillId, props.onSaved);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return container([(children = singleton_1(Daisy_h4("Job User Skill".trim())), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    })), Daisy_error(state_1.Errors), (children_2 = singleton_1(createElement(Daisy_progressState, {
        busy: state_1.IsBusy,
    })), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), createElement("button", createObj(Helpers_combineClasses("btn", toList(delay(() => append(BusyModule_isSaving(state_1.IsBusy) ? singleton_2(["className", "loading"]) : empty_1(), delay(() => append(singleton_2(["className", "btn-primary"]), delay(() => append(singleton_2(["className", "btn-sm"]), delay(() => append(singleton_2(["className", join(" ", ["mr-4"])]), delay(() => append(singleton_2(["children", "Save"]), delay(() => singleton_2(["onClick", (_arg_3) => {
        dispatch(new Msg(7, []));
    }])))))))))))))))), createElement("div", createObj(Helpers_combineClasses("divider", empty()))), createElement("div", createObj(singleton_1((elems_13 = toList(delay(() => {
        let children_10, children_7, elems_2;
        return append(singleton_2((children_10 = ofArray([(children_7 = singleton_1(createElement("span", {
            className: "label-text",
            children: "Skill",
        })), createElement("label", {
            className: "label",
            children: reactApi.Children.toArray(Array.from(children_7)),
        })), createElement("select", createObj(Helpers_combineClasses("select", ofArray([["className", "select-bordered"], ["className", "select-sm"], ["placeholder", "Skill"], ["value", state_1.SelectedSkillId], ["onChange", (ev) => {
            dispatch(new Msg(0, [ev.target.value]));
        }], (elems_2 = toList(delay(() => map_1((case$) => createElement("option", {
            value: case$.Id,
            children: case$.Name,
        }), state_1.Skills))), ["children", reactApi.Children.toArray(Array.from(elems_2))])]))))]), createElement("div", {
            className: "form-control",
            children: reactApi.Children.toArray(Array.from(children_10)),
        }))), delay(() => {
            let children_15, children_13;
            return append(singleton_2((children_15 = ofArray([(children_13 = singleton_1(createElement("span", {
                className: "label-text",
                children: "Count",
            })), createElement("label", {
                className: "label",
                children: reactApi.Children.toArray(Array.from(children_13)),
            })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["type", "number"], ["value", state_1.UserCount], ["placeholder", "Count"], ["onChange", (ev_1) => {
                dispatch(new Msg(1, [ev_1.target.value]));
            }]]))))]), createElement("div", {
                className: "form-control",
                children: reactApi.Children.toArray(Array.from(children_15)),
            }))), delay(() => {
                let children_21, children_18, elems_7;
                return append(props.useUserSkillRatingForJob ? singleton_2((children_21 = ofArray([(children_18 = singleton_1(createElement("span", {
                    className: "label-text",
                    children: "Rating",
                })), createElement("label", {
                    className: "label",
                    children: reactApi.Children.toArray(Array.from(children_18)),
                })), createElement("select", createObj(Helpers_combineClasses("select", ofArray([["className", "select-bordered"], ["className", "select-sm"], ["placeholder", "Rating"], ["value", state_1.Rating], ["onChange", (ev_2) => {
                    dispatch(new Msg(2, [ev_2.target.value]));
                }], (elems_7 = toList(delay(() => map_1((skill) => createElement("option", {
                    value: skill,
                    children: skill,
                }), SkillRatingModule_getLookupValues()))), ["children", reactApi.Children.toArray(Array.from(elems_7))])]))))]), createElement("div", {
                    className: "form-control",
                    children: reactApi.Children.toArray(Array.from(children_21)),
                }))) : empty_1(), delay(() => {
                    let children_26, children_24;
                    return append(singleton_2((children_26 = ofArray([(children_24 = singleton_1(createElement("span", {
                        className: "label-text",
                        children: "Estimate Price",
                    })), createElement("label", {
                        className: "label",
                        children: reactApi.Children.toArray(Array.from(children_24)),
                    })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["type", "number"], ["value", state_1.EstimatedPrice], ["placeholder", "Estimate Price"], ["onChange", (ev_3) => {
                        dispatch(new Msg(3, [ev_3.target.value]));
                    }]]))))]), createElement("div", {
                        className: "form-control",
                        children: reactApi.Children.toArray(Array.from(children_26)),
                    }))), delay(() => {
                        let children_31, children_29;
                        return singleton_2((children_31 = ofArray([(children_29 = singleton_1(createElement("span", {
                            className: "label-text",
                            children: "Agreed Price",
                        })), createElement("label", {
                            className: "label",
                            children: reactApi.Children.toArray(Array.from(children_29)),
                        })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["type", "number"], ["value", state_1.AgreedPrice], ["placeholder", "Agreed Price"], ["onChange", (ev_4) => {
                            dispatch(new Msg(4, [ev_4.target.value]));
                        }]]))))]), createElement("div", {
                            className: "form-control",
                            children: reactApi.Children.toArray(Array.from(children_31)),
                        })));
                    }));
                }));
            }));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_13))]))))]);
}

