import { appRoot, AppRootModule_getSelectedOrgId } from "../../../Infrastructure/AppRoot.fs.js";
import { searchTimesheets } from "../../../AppDomain/UseCase/Timesheet.fs.js";
import { collect, map, singleton, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { uncurry2, createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { iconButton, showModalDialog, ButtonType, showModalEx } from "../../Component/ModalDialog.fs.js";
import { alignIconCenter, alignCellTextTop, Daisy_h5, Daisy_DialogWidth } from "../../Component/Component.fs.js";
import { IndexView as IndexView_1 } from "./TimesheetRun.fs.js";
import { IndexView as IndexView_3, Msg$1 } from "../BaseSearch.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { ofArray, singleton as singleton_1 } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { IndexView as IndexView_2 } from "./TimesheetEdit.fs.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { value as value_118 } from "../../../../Logos.Shared/Domain/Entity/DateRange.fs.js";
import { DateTime_dateTimeToString, DateTime_dateToString } from "../../../AppDomain/Util.fs.js";
import { map as map_1, defaultArg } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { AddressDtoModule_toAddressString } from "../../../../Logos.Shared/Domain/Dto/Address.fs.js";

export function searchFunc() {
    const selectedOrgId = AppRootModule_getSelectedOrgId();
    return (searchText) => ((pagination) => searchTimesheets(appRoot.ShiftService, selectedOrgId, searchText, pagination));
}

export function timesheetTable(props) {
    const children_8 = toList(delay(() => {
        let elems;
        return append(singleton(createElement("div", createObj(Helpers_combineClasses("navbar", singleton_1((elems = [createElement(showModalEx, {
            dialogId: "id-timesheet-search-add-timesheet",
            header: "",
            buttonType: new ButtonType(0, ["Generate Timesheets"]),
            width: new Daisy_DialogWidth(3, []),
            heightPercent: "80",
            body: () => createElement(IndexView_1, null),
            onShow: () => {
                props.dispatch(new Msg$1(4, [undefined]));
            },
            onClose: () => {
                props.dispatch(new Msg$1(1, []));
            },
        })], ["children", reactApi.Children.toArray(Array.from(elems))])))))), delay(() => {
            let matchValue_1, x_1;
            return append((matchValue_1 = props.state.EntityToEdit, (matchValue_1 == null) ? singleton(react.createElement(react.Fragment, {})) : ((x_1 = matchValue_1, singleton(createElement(showModalDialog, {
                dialogId: x_1,
                header: "",
                width: new Daisy_DialogWidth(5, []),
                heightPercent: "90",
                body: () => {
                    const matchValue = props.state.EntityToEdit;
                    if (matchValue == null) {
                        const xs = [Daisy_h5("No Timesheet Run Selected.")];
                        return react.createElement(react.Fragment, {}, ...xs);
                    }
                    else {
                        return createElement(IndexView_2, {
                            onTimesheetUpdated: () => {
                                props.dispatch(new Msg$1(9, [true]));
                            },
                            timesheetRunId: matchValue,
                        });
                    }
                },
                onShow: () => {
                    props.dispatch(new Msg$1(4, [x_1]));
                },
                onClose: () => {
                    props.dispatch(new Msg$1(4, [undefined]));
                },
            }))))), delay(() => {
                let elems_12, children_3, children_1, children_5;
                return singleton(createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["flex", "w-full"])], (elems_12 = [(children_3 = singleton_1((children_1 = ofArray([createElement("th", {
                    width: 10 + "%",
                    children: "Run #",
                }), createElement("th", {
                    width: 18 + "%",
                    children: "Run date range",
                }), createElement("th", {
                    width: 12 + "%",
                    children: "Created",
                }), createElement("th", {
                    width: 10 + "%",
                    children: "Approved",
                }), createElement("th", {
                    width: 15 + "%",
                    children: "Canceled",
                }), createElement("th", {
                    width: 25 + "%",
                    children: "Jobs",
                }), createElement("th", {
                    width: 10 + "%",
                    children: "",
                })]), createElement("tr", {
                    children: reactApi.Children.toArray(Array.from(children_1)),
                }))), createElement("thead", {
                    children: reactApi.Children.toArray(Array.from(children_3)),
                })), (children_5 = toList(delay(() => map((timesheet) => {
                    let elems_11;
                    return createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top", "hover"])], (elems_11 = toList(delay(() => {
                        let elems_3, elems_2, elems_1;
                        return append(singleton(createElement("td", createObj(ofArray([alignCellTextTop, ["style", {
                            cursor: "pointer",
                        }], ["onClick", (_arg_5) => {
                            props.dispatch(new Msg$1(4, [timesheet.Id]));
                        }], (elems_3 = [createElement("div", createObj(singleton_1((elems_2 = [createElement("span", createObj(ofArray([["style", {
                            display: "flex",
                            alignItems: "center",
                        }], (elems_1 = [createElement("i", {
                            className: join(" ", toList(delay(() => append(singleton("fa"), delay(() => ((timesheet.Approved != null) ? singleton("fa-check") : ((timesheet.Canceled != null) ? singleton("fa-times") : singleton("fa-hourglass")))))))),
                            style: createObj(toList(delay(() => append(singleton(["marginRight", "8px"]), delay(() => ((timesheet.Approved != null) ? singleton(["color", "green"]) : ((timesheet.Canceled != null) ? singleton(["color", "red"]) : singleton(["color", "orange"])))))))),
                        }), createElement("div", createObj(ofArray([alignCellTextTop, ["children", timesheet.RunNumber]])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))]))))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))), delay(() => {
                            const dateRange = value_118(timesheet.DateRange);
                            return append(singleton(createElement("td", createObj(ofArray([alignCellTextTop, ["style", {
                                cursor: "pointer",
                            }], ["onClick", (_arg_6) => {
                                props.dispatch(new Msg$1(4, [timesheet.Id]));
                            }], ["children", `${DateTime_dateToString(dateRange.Start)} - ${DateTime_dateToString(dateRange.Finish)}`]])))), delay(() => {
                                let elems_4;
                                return append(singleton(createElement("td", createObj(ofArray([alignCellTextTop, ["style", {
                                    cursor: "pointer",
                                }], ["onClick", (_arg_7) => {
                                    props.dispatch(new Msg$1(4, [timesheet.Id]));
                                }], (elems_4 = [createElement("p", {
                                    children: [`${DateTime_dateTimeToString(timesheet.Created.DateCreated)}`],
                                }), createElement("p", {
                                    children: [`${timesheet.Created.CreatedByName}`],
                                })], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))), delay(() => {
                                    let elems_5;
                                    return append(singleton(createElement("td", createObj(ofArray([alignCellTextTop, ["style", {
                                        cursor: "pointer",
                                    }], ["onClick", (_arg_8) => {
                                        props.dispatch(new Msg$1(4, [timesheet.Id]));
                                    }], (elems_5 = [createElement("p", {
                                        children: [defaultArg(map_1((x_2) => DateTime_dateTimeToString(x_2.DateApproved), timesheet.Approved), "")],
                                    }), createElement("p", {
                                        children: [defaultArg(map_1((x_3) => x_3.ApprovedByName, timesheet.Approved), "")],
                                    })], ["children", reactApi.Children.toArray(Array.from(elems_5))])])))), delay(() => {
                                        let elems_6;
                                        return append(singleton(createElement("td", createObj(ofArray([alignCellTextTop, ["style", {
                                            cursor: "pointer",
                                        }], ["onClick", (_arg_9) => {
                                            props.dispatch(new Msg$1(4, [timesheet.Id]));
                                        }], (elems_6 = [createElement("p", {
                                            children: [defaultArg(map_1((x_4) => DateTime_dateTimeToString(x_4.DateCanceled), timesheet.Canceled), "")],
                                        }), createElement("p", {
                                            children: [defaultArg(map_1((x_5) => x_5.CanceledByName, timesheet.Canceled), "")],
                                        })], ["children", reactApi.Children.toArray(Array.from(elems_6))])])))), delay(() => {
                                            let elems_8, elems_7;
                                            return append(singleton(createElement("td", createObj(ofArray([["style", {
                                                cursor: "pointer",
                                            }], ["onClick", (_arg_10) => {
                                                props.dispatch(new Msg$1(4, [timesheet.Id]));
                                            }], (elems_8 = [createElement("div", createObj(ofArray([alignCellTextTop, ["key", timesheet.Id], (elems_7 = toList(delay(() => collect((job) => append(singleton(createElement("div", {
                                                className: join(" ", ["font-bold"]),
                                                children: job.Title,
                                            })), delay(() => singleton(createElement("div", {
                                                children: AddressDtoModule_toAddressString(job.Address),
                                            })))), timesheet.Jobs))), ["children", reactApi.Children.toArray(Array.from(elems_7))])])))], ["children", reactApi.Children.toArray(Array.from(elems_8))])])))), delay(() => {
                                                let elems_10, elems_9;
                                                return singleton(createElement("td", createObj(singleton_1((elems_10 = [createElement("div", createObj(ofArray([alignIconCenter, (elems_9 = [iconButton("fas fa-edit", (_arg_11) => {
                                                    props.dispatch(new Msg$1(4, [timesheet.Id]));
                                                })], ["children", reactApi.Children.toArray(Array.from(elems_9))])])))], ["children", reactApi.Children.toArray(Array.from(elems_10))])))));
                                            }));
                                        }));
                                    }));
                                }));
                            }));
                        }));
                    })), ["children", reactApi.Children.toArray(Array.from(elems_11))])])));
                }, props.state.Entities))), createElement("tbody", {
                    children: reactApi.Children.toArray(Array.from(children_5)),
                }))], ["children", reactApi.Children.toArray(Array.from(elems_12))])])))));
            }));
        }));
    }));
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_8)),
    });
}

export function IndexView() {
    return createElement(IndexView_3, {
        entityName: "Timesheets",
        entityTable: timesheetTable,
        searchFunc: uncurry2(searchFunc()),
    });
}

