import { Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type, record_type, list_type, string_type, option_type, bool_type, class_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { JobShiftCheckTemplate as JobShiftCheckTemplate_2, JobShiftCheckItem_$reflection, JobShiftCheckTemplate_$reflection } from "../../../../Logos.Shared/Domain/Entity/Job.fs.js";
import { BusyModule_isSaving, Busy, Busy_$reflection } from "../../SharedView.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { Guid_parse } from "../../../../Logos.Shared/Util.fs.js";
import { updateJobShiftTemplate, addJobShiftTemplate, getJobShiftTemplate } from "../../../AppDomain/UseCase/Job.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../Infrastructure/AppRoot.fs.js";
import { defaultArg } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { ofArray, singleton as singleton_1, empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { join, isNullOrEmpty } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../../Component/ViewContainer.fs.js";
import { Daisy_progressState, Daisy_error, Daisy_h4 } from "../../Component/Component.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { empty as empty_1, singleton as singleton_2, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { IndexView as IndexView_1 } from "../Job/JobShiftCheckItemsEditor.fs.js";

export class State extends Record {
    constructor(Id, IsNewTemplate, JobShiftCheckTemplate, IsBusy, Errors) {
        super();
        this.Id = Id;
        this.IsNewTemplate = IsNewTemplate;
        this.JobShiftCheckTemplate = JobShiftCheckTemplate;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.ShiftTemplateEdit.State", [], State, () => [["Id", class_type("System.Guid")], ["IsNewTemplate", bool_type], ["JobShiftCheckTemplate", option_type(JobShiftCheckTemplate_$reflection())], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetTemplateName", "SetJobShiftCheckItems", "GetShiftTemplateResponse", "SaveShiftTemplate", "SaveShiftTemplateResponse", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.ShiftTemplateEdit.Msg", [], Msg, () => [[["Item", string_type]], [["Item", list_type(JobShiftCheckItem_$reflection())]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [option_type(JobShiftCheckTemplate_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", option_type(JobShiftCheckTemplate_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [option_type(class_type("System.Guid")), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", option_type(class_type("System.Guid"))]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Exception")]]]);
}

export function getShiftTemplateCmd(shiftTemplateId) {
    return cmdOfAsync((Item) => (new Msg(2, [Item])), (Item_1) => (new Msg(5, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_1;
        const value_1 = Guid_parse(shiftTemplateId);
        asyncResult_1 = singleton.Return(value_1);
        return singleton.Bind(asyncResult_1, (input_1) => {
            const input_2 = input_1;
            if (input_2.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
            }
            else {
                return getJobShiftTemplate(appRoot.JobService, AppRootModule_getSelectedOrgId(), input_2.fields[0]);
            }
        });
    }));
}

export function saveShiftTemplateCmd(state) {
    return cmdOfAsync((Item) => (new Msg(4, [Item])), (Item_1) => (new Msg(5, [Item_1])), () => singleton.Delay(() => {
        let input_1, input_3;
        let asyncResult;
        let value_1_1;
        if (state.IsNewTemplate) {
            value_1_1 = (new FSharpResult$2(0, [new JobShiftCheckTemplate_2("00000000-0000-0000-0000-000000000000", defaultArg((input_1 = state.JobShiftCheckTemplate, (input_1 == null) ? undefined : input_1.Name), ""), defaultArg((input_3 = state.JobShiftCheckTemplate, (input_3 == null) ? undefined : input_3.ShiftCheckItems), empty()))]));
        }
        else {
            const option_3 = state.JobShiftCheckTemplate;
            value_1_1 = ((option_3 == null) ? (new FSharpResult$2(1, [singleton_1("Shift template data not found.")])) : (new FSharpResult$2(0, [option_3])));
        }
        asyncResult = singleton.Return(value_1_1);
        return singleton.Bind(asyncResult, (input_1_3) => {
            let input_5;
            const input_15 = input_1_3;
            if (input_15.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_15.fields[0]]));
            }
            else {
                const shiftTemplateDto = input_15.fields[0];
                const updatedShiftTemplate = new JobShiftCheckTemplate_2(shiftTemplateDto.Id, defaultArg((input_5 = state.JobShiftCheckTemplate, (input_5 == null) ? undefined : input_5.Name), ""), shiftTemplateDto.ShiftCheckItems);
                if (state.IsNewTemplate) {
                    const x_4 = addJobShiftTemplate(appRoot.JobService, AppRootModule_getSelectedOrgId(), updatedShiftTemplate);
                    return singleton.Bind(x_4, (x$0027) => {
                        let value_5;
                        const input_7 = x$0027;
                        value_5 = ((input_7.tag === 1) ? (new FSharpResult$2(1, [input_7.fields[0]])) : (new FSharpResult$2(0, [input_7.fields[0]])));
                        return singleton.Return(value_5);
                    });
                }
                else {
                    const x_6 = updateJobShiftTemplate(appRoot.JobService, AppRootModule_getSelectedOrgId(), updatedShiftTemplate);
                    return singleton.Bind(x_6, (x$0027_1) => {
                        let value_7;
                        const input_11 = x$0027_1;
                        value_7 = ((input_11.tag === 1) ? (new FSharpResult$2(1, [input_11.fields[0]])) : (new FSharpResult$2(0, [undefined])));
                        return singleton.Return(value_7);
                    });
                }
            }
        });
    }));
}

export function init(shiftTemplateId) {
    const isNewTemplate = isNullOrEmpty(shiftTemplateId);
    return [new State("00000000-0000-0000-0000-000000000000", isNewTemplate, undefined, new Busy(0, []), empty()), isNewTemplate ? Cmd_none() : getShiftTemplateCmd(shiftTemplateId)];
}

export function update(msg, state) {
    let input_3, input_5, input_1, x;
    switch (msg.tag) {
        case 1:
            return [new State(state.Id, state.IsNewTemplate, new JobShiftCheckTemplate_2(state.Id, defaultArg((input_3 = state.JobShiftCheckTemplate, (input_3 == null) ? undefined : input_3.Name), ""), msg.fields[0]), state.IsBusy, state.Errors), Cmd_none()];
        case 2:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Id, state.IsNewTemplate, state.JobShiftCheckTemplate, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(defaultArg((input_5 = msg.fields[0].fields[0], (input_5 == null) ? undefined : input_5.Id), "00000000-0000-0000-0000-000000000000"), state.IsNewTemplate, msg.fields[0].fields[0], new Busy(0, []), state.Errors), Cmd_none()];
            }
        case 3:
            return [new State(state.Id, state.IsNewTemplate, state.JobShiftCheckTemplate, new Busy(1, []), empty()), saveShiftTemplateCmd(state)];
        case 4:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Id, state.IsNewTemplate, state.JobShiftCheckTemplate, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else if (msg.fields[0].fields[0] == null) {
                return [new State(state.Id, state.IsNewTemplate, state.JobShiftCheckTemplate, new Busy(0, []), state.Errors), Cmd_none()];
            }
            else {
                const x_3 = msg.fields[0].fields[0];
                return [new State(x_3, false, state.JobShiftCheckTemplate, new Busy(0, []), state.Errors), state.IsNewTemplate ? getShiftTemplateCmd(x_3) : Cmd_none()];
            }
        case 5:
            return [new State(state.Id, state.IsNewTemplate, state.JobShiftCheckTemplate, new Busy(0, []), singleton_1(msg.fields[0].message)), Cmd_none()];
        default:
            return [new State(state.Id, state.IsNewTemplate, (input_1 = state.JobShiftCheckTemplate, (input_1 == null) ? undefined : ((x = input_1, new JobShiftCheckTemplate_2(x.Id, msg.fields[0], x.ShiftCheckItems)))), state.IsBusy, state.Errors), Cmd_none()];
    }
}

export function IndexView(indexViewInputProps) {
    let input_1, children, children_2, elems_4, children_9, children_7, elems_3, input_3;
    const shiftTemplateId = indexViewInputProps.shiftTemplateId;
    let patternInput;
    const init_1 = init(shiftTemplateId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const templateName = defaultArg((input_1 = state_1.JobShiftCheckTemplate, (input_1 == null) ? undefined : input_1.Name), "");
    return container([(children = singleton_1(Daisy_h4((`Shift template - ${templateName}`).trim())), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    })), Daisy_error(state_1.Errors), (children_2 = singleton_1(createElement(Daisy_progressState, {
        busy: state_1.IsBusy,
    })), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), createElement("button", createObj(Helpers_combineClasses("btn", toList(delay(() => append(BusyModule_isSaving(state_1.IsBusy) ? singleton_2(["className", "loading"]) : empty_1(), delay(() => append(singleton_2(["className", join(" ", ["my-4"])]), delay(() => append(singleton_2(["className", "btn-primary"]), delay(() => append(singleton_2(["className", "btn-sm"]), delay(() => append(singleton_2(["className", join(" ", ["mr-4"])]), delay(() => append(singleton_2(["children", "Save"]), delay(() => singleton_2(["onClick", (_arg_3) => {
        dispatch(new Msg(3, []));
    }])))))))))))))))))), createElement("div", createObj(Helpers_combineClasses("divider", empty()))), createElement("div", createObj(singleton_1((elems_4 = [(children_9 = ofArray([(children_7 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Shift Template name",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_7)),
    })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", templateName], ["placeholder", "Shift Template name"], ["onChange", (ev) => {
        dispatch(new Msg(0, [ev.target.value]));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_9)),
    })), createElement("div", createObj(ofArray([["className", "mt-2 mb-4"], (elems_3 = [createElement(IndexView_1, {
        JobShiftCheckItems: defaultArg((input_3 = state_1.JobShiftCheckTemplate, (input_3 == null) ? undefined : input_3.ShiftCheckItems), empty()),
        OnJobShiftCheckItemsChange: (arg_1) => {
            dispatch(new Msg(1, [arg_1]));
        },
        Title: "Shift Checks",
    })], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", reactApi.Children.toArray(Array.from(elems_4))]))))]);
}

