import { appRoot, AppRootModule_getSelectedOrgId } from "../../../Infrastructure/AppRoot.fs.js";
import { searchDocuments } from "../../../AppDomain/UseCase/Document.fs.js";
import { createElement } from "react";
import React from "react";
import { uncurry2, createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { ofArray, singleton } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { DocumentSearchDetails } from "../../../../Logos.Shared/Domain/Entity/Document.fs.js";
import { IndexView as IndexView_3, Msg$2 } from "../BaseSearchAdvanced.fs.js";
import { PropHelpers_createOnKey } from "../../../fable_modules/Feliz.2.8.0/./Properties.fs.js";
import { key_enter } from "../../../fable_modules/Feliz.2.8.0/Key.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { unwrap, defaultArg } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { IndexView as IndexView_1 } from "./DocumentEdit.fs.js";
import { map, empty, singleton as singleton_1, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { ButtonType, showModalEx } from "../../Component/ModalDialog.fs.js";
import { Daisy_info, Daisy_DialogWidth } from "../../Component/Component.fs.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { IndexView as IndexView_2 } from "./DocumentSearchAction.fs.js";

export function searchFunc(documentSearchDetails) {
    const selectedOrgId = AppRootModule_getSelectedOrgId();
    return (pagination) => searchDocuments(appRoot.DocumentService, selectedOrgId, documentSearchDetails, pagination);
}

export function searchPanel(props) {
    let children_2, elems;
    const children_4 = singleton((children_2 = ofArray([createElement("label", createObj(Helpers_combineClasses("label", singleton(["children", "Search"])))), createElement("div", createObj(ofArray([["className", "flex space-x-2"], (elems = [createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "w-full"], ["className", "input-bordered"], ["className", "input-sm"], ["autoFocus", true], ["placeholder", "Search"], ["readOnly", false], ["onChange", (ev) => {
        let bind$0040;
        props.dispatch(new Msg$2(0, [(bind$0040 = props.state.SearchDetails, new DocumentSearchDetails(ev.target.value, bind$0040.OwnerId, bind$0040.FromDate, bind$0040.ToDate))]));
    }], ["onKeyUp", (ev_1) => {
        PropHelpers_createOnKey(key_enter, (_arg) => {
            props.dispatch(new Msg$2(1, []));
        }, ev_1);
    }]])))), createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-primary"], ["className", "btn-sm"], ["children", "Search"], ["onClick", (_arg_1) => {
        props.dispatch(new Msg$2(1, []));
    }]]))))], ["children", reactApi.Children.toArray(Array.from(elems))])])))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_2)),
    })));
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_4)),
    });
}

export function documentTable(documentType, userId, props) {
    const body = () => {
        const userId_1 = defaultArg(userId, "00000000-0000-0000-0000-000000000000");
        const matchValue = props.state.EntityToEdit;
        if (matchValue == null) {
            return createElement(IndexView_1, {
                DocumentId: "",
                DocumentType: documentType,
                UserId: userId_1,
            });
        }
        else {
            return createElement(IndexView_1, {
                DocumentId: matchValue,
                DocumentType: documentType,
                UserId: userId_1,
            });
        }
    };
    const children_12 = toList(delay(() => {
        let elems;
        return append((userId != null) ? singleton_1(createElement("div", createObj(Helpers_combineClasses("navbar", singleton((elems = toList(delay(() => ((defaultArg(userId, "00000000-0000-0000-0000-000000000000") !== "00000000-0000-0000-0000-000000000000") ? singleton_1(createElement(showModalEx, {
            dialogId: "id-Document-search-add-Document",
            header: "",
            buttonType: new ButtonType(0, ["Add Document"]),
            width: new Daisy_DialogWidth(1, []),
            body: body,
            onShow: () => {
                props.dispatch(new Msg$2(4, [undefined]));
            },
            onClose: () => {
                props.dispatch(new Msg$2(4, [undefined]));
            },
        })) : singleton_1(Daisy_info(singleton("Please save the document owner first before adding a document.")))))), ["children", reactApi.Children.toArray(Array.from(elems))])))))) : empty(), delay(() => {
            let elems_4, children_3, children_1, children_9;
            return singleton_1(createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["flex", "w-full"])], (elems_4 = [(children_3 = singleton((children_1 = ofArray([createElement("th", {
                width: 50 + "%",
                children: "Document",
            }), createElement("th", {
                width: 40 + "%",
                children: "Tags",
            }), createElement("th", {
                width: 10 + "%",
                children: "",
            })]), createElement("tr", {
                children: reactApi.Children.toArray(Array.from(children_1)),
            }))), createElement("thead", {
                children: reactApi.Children.toArray(Array.from(children_3)),
            })), (children_9 = toList(delay(() => map((document$) => {
                let elems_3, children_5, elems_1, children_7, elems_2;
                return createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top", "hover"])], (elems_3 = [createElement("td", {
                    children: [document$.Name],
                }), (children_5 = singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "justify-start", "vertical", "space-x-2"])], (elems_1 = toList(delay(() => map((tag) => createElement("span", {
                    className: join(" ", ["badge", "badge-primary"]),
                    children: tag.Name,
                }), document$.Tags))), ["children", reactApi.Children.toArray(Array.from(elems_1))])])))), createElement("td", {
                    children: reactApi.Children.toArray(Array.from(children_5)),
                })), (children_7 = singleton(createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "justify-end", "vertical", "space-x-2"])], (elems_2 = [createElement(IndexView_2, {
                    DocumentId: document$.Id,
                    FileExtension: unwrap(document$.FileExtension),
                    Name: document$.Name,
                }), createElement(showModalEx, {
                    dialogId: document$.Id,
                    header: "",
                    buttonType: new ButtonType(1, ["fa-edit"]),
                    width: new Daisy_DialogWidth(1, []),
                    body: body,
                    onShow: () => {
                        props.dispatch(new Msg$2(4, [document$.Id]));
                    },
                    onClose: () => {
                        props.dispatch(new Msg$2(4, [undefined]));
                    },
                })], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))), createElement("td", {
                    children: reactApi.Children.toArray(Array.from(children_7)),
                }))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])));
            }, props.state.Entities))), createElement("tbody", {
                children: reactApi.Children.toArray(Array.from(children_9)),
            }))], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))));
        }));
    }));
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_12)),
    });
}

export function IndexView(indexViewInputProps) {
    const documentType = indexViewInputProps.documentType;
    const ownerId = indexViewInputProps.ownerId;
    return createElement(IndexView_3, {
        entityName: "",
        entityTable: (props) => documentTable(documentType, ownerId, props),
        searchDetails: new DocumentSearchDetails("", ownerId, undefined, undefined),
        searchFunc: uncurry2(searchFunc),
        searchPanel: searchPanel,
        xPadding: "px-0",
        yPadding: "py-0",
    });
}

