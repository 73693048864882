import { Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { JobShiftCheckItem, JobShiftCheckItem_$reflection } from "../../../../Logos.Shared/Domain/Entity/Job.fs.js";
import { union_type, class_type, int32_type, record_type, string_type, option_type, bool_type, lambda_type, unit_type, list_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { ofArray, singleton, append, empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { List_remove, List_replace } from "../../../../Logos.Shared/Util.fs.js";
import { newGuid } from "../../../fable_modules/fable-library-js.4.19.3/Guid.js";
import { utcNow } from "../../../fable_modules/fable-library-js.4.19.3/DateOffset.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { TitleSize, viewBox } from "../../Component/ViewBox.fs.js";
import { map, singleton as singleton_1, append as append_1, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { Daisy_DialogWidth, confirmSimple, Daisy_error } from "../../Component/Component.fs.js";
import { createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";

export class State extends Record {
    constructor(JobShiftCheckItems, OnJobShiftCheckItemsChange, IsConfirmingRemove, ItemToRemove, Errors) {
        super();
        this.JobShiftCheckItems = JobShiftCheckItems;
        this.OnJobShiftCheckItemsChange = OnJobShiftCheckItemsChange;
        this.IsConfirmingRemove = IsConfirmingRemove;
        this.ItemToRemove = ItemToRemove;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.JobShiftCheckItemsEditor.State", [], State, () => [["JobShiftCheckItems", list_type(JobShiftCheckItem_$reflection())], ["OnJobShiftCheckItemsChange", lambda_type(list_type(JobShiftCheckItem_$reflection()), unit_type)], ["IsConfirmingRemove", bool_type], ["ItemToRemove", option_type(JobShiftCheckItem_$reflection())], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetName", "SetDescription", "SetPriority", "AddItem", "RemoveItem", "ConfirmRemoveItem", "ConfirmRemoveItemResponse", "NoAction", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.JobShiftCheckItemsEditor.Msg", [], Msg, () => [[["Item1", JobShiftCheckItem_$reflection()], ["Item2", string_type]], [["Item1", JobShiftCheckItem_$reflection()], ["Item2", string_type]], [["Item1", JobShiftCheckItem_$reflection()], ["Item2", int32_type]], [], [["Item", JobShiftCheckItem_$reflection()]], [["Item", JobShiftCheckItem_$reflection()]], [["Item", bool_type]], [], [["Item", class_type("System.Exception")]]]);
}

export function init(jobShiftCheckItems, onJobShiftCheckItemsChange) {
    return [new State(jobShiftCheckItems, onJobShiftCheckItemsChange, false, undefined, empty()), Cmd_none()];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1: {
            const jobShiftCheckItem_1 = msg.fields[0];
            const updatedJobShiftCheckItems_1 = List_replace(jobShiftCheckItem_1, new JobShiftCheckItem(jobShiftCheckItem_1.Id, jobShiftCheckItem_1.Name, msg.fields[1], jobShiftCheckItem_1.Priority, jobShiftCheckItem_1.Created, jobShiftCheckItem_1.Updated), state.JobShiftCheckItems);
            state.OnJobShiftCheckItemsChange(updatedJobShiftCheckItems_1);
            return [new State(updatedJobShiftCheckItems_1, state.OnJobShiftCheckItemsChange, state.IsConfirmingRemove, state.ItemToRemove, state.Errors), Cmd_none()];
        }
        case 2: {
            const jobShiftCheckItem_2 = msg.fields[0];
            const updatedJobShiftCheckItems_2 = List_replace(jobShiftCheckItem_2, new JobShiftCheckItem(jobShiftCheckItem_2.Id, jobShiftCheckItem_2.Name, jobShiftCheckItem_2.Description, msg.fields[1], jobShiftCheckItem_2.Created, jobShiftCheckItem_2.Updated), state.JobShiftCheckItems);
            state.OnJobShiftCheckItemsChange(updatedJobShiftCheckItems_2);
            return [new State(updatedJobShiftCheckItems_2, state.OnJobShiftCheckItemsChange, state.IsConfirmingRemove, state.ItemToRemove, state.Errors), Cmd_none()];
        }
        case 3: {
            const newItem_3 = new JobShiftCheckItem(newGuid(), "", "", 0, utcNow(), utcNow());
            state.OnJobShiftCheckItemsChange(append(state.JobShiftCheckItems, singleton(newItem_3)));
            return [new State(append(state.JobShiftCheckItems, singleton(newItem_3)), state.OnJobShiftCheckItemsChange, state.IsConfirmingRemove, state.ItemToRemove, state.Errors), Cmd_none()];
        }
        case 4: {
            const updatedJobShiftCheckItems_3 = List_remove(msg.fields[0], state.JobShiftCheckItems);
            state.OnJobShiftCheckItemsChange(updatedJobShiftCheckItems_3);
            return [new State(updatedJobShiftCheckItems_3, state.OnJobShiftCheckItemsChange, state.IsConfirmingRemove, state.ItemToRemove, state.Errors), Cmd_none()];
        }
        case 5:
            return [new State(state.JobShiftCheckItems, state.OnJobShiftCheckItemsChange, true, msg.fields[0], state.Errors), Cmd_none()];
        case 6: {
            const matchValue = state.ItemToRemove;
            let matchResult;
            if (msg.fields[0]) {
                if (matchValue != null) {
                    matchResult = 0;
                }
                else {
                    matchResult = 1;
                }
            }
            else {
                matchResult = 1;
            }
            switch (matchResult) {
                case 0: {
                    const item_2 = matchValue;
                    return [new State(state.JobShiftCheckItems, state.OnJobShiftCheckItemsChange, false, undefined, state.Errors), singleton((dispatch) => {
                        dispatch(new Msg(4, [item_2]));
                    })];
                }
                default:
                    return [new State(state.JobShiftCheckItems, state.OnJobShiftCheckItemsChange, false, undefined, state.Errors), Cmd_none()];
            }
        }
        case 7:
            return [state, Cmd_none()];
        case 8:
            return [new State(state.JobShiftCheckItems, state.OnJobShiftCheckItemsChange, state.IsConfirmingRemove, state.ItemToRemove, singleton(msg.fields[0].message)), Cmd_none()];
        default: {
            const jobShiftCheckItem = msg.fields[0];
            const updatedJobShiftCheckItems = List_replace(jobShiftCheckItem, new JobShiftCheckItem(jobShiftCheckItem.Id, msg.fields[1], jobShiftCheckItem.Description, jobShiftCheckItem.Priority, jobShiftCheckItem.Created, jobShiftCheckItem.Updated), state.JobShiftCheckItems);
            state.OnJobShiftCheckItemsChange(updatedJobShiftCheckItems);
            return [new State(updatedJobShiftCheckItems, state.OnJobShiftCheckItemsChange, state.IsConfirmingRemove, state.ItemToRemove, state.Errors), Cmd_none()];
        }
    }
}

export function IndexView(props) {
    let patternInput;
    const init_1 = init(props.JobShiftCheckItems, props.OnJobShiftCheckItemsChange);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.JobShiftCheckItems, props.ShiftTemplateSearchDialog]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return viewBox(props.Title, new TitleSize(0, []), toList(delay(() => append_1(singleton_1(Daisy_error(state_1.Errors)), delay(() => append_1(state_1.IsConfirmingRemove ? singleton_1(confirmSimple(new Daisy_DialogWidth(1, []), "Are you sure you want to remove this item?", (_arg_2) => {
        dispatch(new Msg(6, [false]));
    }, (_arg_3) => {
        dispatch(new Msg(6, [true]));
    })) : singleton_1(react.createElement(react.Fragment, {})), delay(() => {
        let elems;
        return append_1(singleton_1(createElement("div", createObj(Helpers_combineClasses("navbar", ofArray([["className", "space-x-2"], (elems = toList(delay(() => append_1(singleton_1(createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-primary"], ["className", "btn-sm"], ["children", "Add"], ["onClick", (_arg_4) => {
            dispatch(new Msg(3, []));
        }]]))))), delay(() => {
            const matchValue = props.ShiftTemplateSearchDialog;
            return (matchValue == null) ? singleton_1(react.createElement(react.Fragment, {})) : singleton_1(matchValue());
        })))), ["children", reactApi.Children.toArray(Array.from(elems))])]))))), delay(() => {
            let elems_2, children_3;
            return singleton_1(createElement("div", createObj(ofArray([["className", "border border-gray-200 rounded-md p-2 overflow-auto h-40 mt-4"], (elems_2 = [(children_3 = toList(delay(() => map((item) => {
                let elems_1;
                return createElement("li", createObj(ofArray([["className", "flex flex-row justify-between items-center mb-2 space-x-2"], (elems_1 = [createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-xs"], ["style", {
                    width: "25rem",
                }], ["placeholder", "Name"], ["value", item.Name], ["onChange", (ev) => {
                    dispatch(new Msg(0, [item, ev.target.value]));
                }]])))), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-xs"], ["style", {
                    width: "70rem",
                }], ["placeholder", "Description"], ["value", item.Description], ["onChange", (ev_1) => {
                    dispatch(new Msg(1, [item, ev_1.target.value]));
                }]])))), createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-primary"], ["className", "btn-xs"], ["style", {
                    width: "5rem",
                }], ["children", "Remove"], ["onClick", (_arg_5) => {
                    dispatch(new Msg(5, [item]));
                }]]))))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
            }, state_1.JobShiftCheckItems))), createElement("ul", {
                children: reactApi.Children.toArray(Array.from(children_3)),
            }))], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))));
        }));
    })))))));
}

