import { Union } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";

export class Busy extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["No", "Loading", "Saving"];
    }
}

export function Busy_$reflection() {
    return union_type("Logos.Client.SharedView.Busy", [], Busy, () => [[], [], []]);
}

export function BusyModule_isLoading(_arg) {
    switch (_arg.tag) {
        case 2:
        case 0:
            return false;
        default:
            return true;
    }
}

export function BusyModule_isSaving(_arg) {
    switch (_arg.tag) {
        case 1:
        case 0:
            return false;
        default:
            return true;
    }
}

