import { singleton } from "../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { Pagination } from "../../../Logos.Shared/Domain/Entity/Pagination.fs.js";

export function searchSkills(skillService, selectedOrgId, searchText, pagination) {
    return singleton.Delay(() => skillService.SearchSkills(selectedOrgId, searchText, pagination));
}

export function getSkill(skillService, selectedOrgId, skillId) {
    return singleton.Delay(() => skillService.GetSkill(selectedOrgId, skillId));
}

export function getAllSkills(skillService, selectedOrgId) {
    return singleton.Delay(() => searchSkills(skillService, selectedOrgId, "", new Pagination(10000n, 0n, 1n)));
}

export function addSkill(skillService, selectedOrgId, skill) {
    return singleton.Delay(() => skillService.AddSkill(selectedOrgId, skill));
}

export function updateSkill(skillService, selectedOrgId, skill) {
    return singleton.Delay(() => skillService.UpdateSkill(selectedOrgId, skill));
}

