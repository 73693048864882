import { toString, Union, Record } from "../../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type, record_type, list_type, decimal_type, int32_type, class_type, string_type } from "../../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { VehicleLogTag_$reflection, LogPoint_$reflection } from "../../../../../Logos.Shared/Domain/Entity/LogMaster.fs.js";
import { defaultArg } from "../../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { ofArray, singleton as singleton_1, isEmpty, empty, choose, map2, filter, length, concat, map, tryFind } from "../../../../fable_modules/fable-library-js.4.19.3/List.js";
import { DateTime_timeToString, DateTime_dateTimeToStringWithDayName } from "../../../../AppDomain/Util.fs.js";
import { toNumber, op_Subtraction as op_Subtraction_1, fromParts } from "../../../../fable_modules/fable-library-js.4.19.3/Decimal.js";
import { op_Subtraction, minValue } from "../../../../fable_modules/fable-library-js.4.19.3/Date.js";
import { traverseResultA } from "../../../../fable_modules/FsToolkit.ErrorHandling.4.16.0/List.fs.js";
import { LogPointDtoModule_toLogPoint } from "../../../../../Logos.Shared/Domain/Dto/LogMaster/VehicleLog.fs.js";
import { FSharpResult$2 } from "../../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { DistanceUnit, MeasurementSystem_$reflection, DistanceUnitModule_toString } from "../../../../../Logos.Shared/Domain/Entity/MeasurementUnits.fs.js";
import { Option_ofString, System_TimeSpan__TimeSpan_ToDisplayDuration } from "../../../../../Logos.Shared/Util.fs.js";
import { format, join } from "../../../../fable_modules/fable-library-js.4.19.3/String.js";
import { Pagination_$reflection } from "../../../../../Logos.Shared/Domain/Entity/Pagination.fs.js";
import { Busy, Busy_$reflection } from "../../../SharedView.fs.js";
import { cmdOfAsync } from "../../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { getVehicleLogs } from "../../../../AppDomain/UseCase/Vehicle.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../../Infrastructure/AppRoot.fs.js";
import { fromDateTime } from "../../../../fable_modules/fable-library-js.4.19.3/DateOnly.js";
import { paginationControl as paginationControl_1, firstPage, nextPage, previousPage, getDefaultPagination } from "../../../Component/Pagination.fs.js";
import { Cmd_none } from "../../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { int32ToString, createObj, equals } from "../../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { Daisy_h4, Daisy_h6, Daisy_error } from "../../../Component/Component.fs.js";
import { Helpers_combineClasses } from "../../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { reactApi } from "../../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { empty as empty_1, append, singleton as singleton_2, collect, delay, toList } from "../../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { minutes, hours } from "../../../../fable_modules/fable-library-js.4.19.3/TimeSpan.js";

export class VehicleLogViewData extends Record {
    constructor(Start, End, Vehicle, DistanceUnit, Status, Tag, Stops, TotalDuration, TotalDistance, Notes, RowColor, LogPoints) {
        super();
        this.Start = Start;
        this.End = End;
        this.Vehicle = Vehicle;
        this.DistanceUnit = DistanceUnit;
        this.Status = Status;
        this.Tag = Tag;
        this.Stops = (Stops | 0);
        this.TotalDuration = TotalDuration;
        this.TotalDistance = TotalDistance;
        this.Notes = Notes;
        this.RowColor = RowColor;
        this.LogPoints = LogPoints;
    }
}

export function VehicleLogViewData_$reflection() {
    return record_type("Presentation.Pages.LogMaster.VehicleLogAggregate.VehicleLogViewData", [], VehicleLogViewData, () => [["Start", string_type], ["End", string_type], ["Vehicle", string_type], ["DistanceUnit", string_type], ["Status", string_type], ["Tag", class_type("System.Guid")], ["Stops", int32_type], ["TotalDuration", string_type], ["TotalDistance", decimal_type], ["Notes", string_type], ["RowColor", string_type], ["LogPoints", list_type(LogPoint_$reflection())]]);
}

export function VehicleLogDashboardViewData_ofVehicleLogDto(vehicle, vehicleLog) {
    let input_1, x_1, input_3, x_3, matchValue, matchValue_1;
    const patternInput = defaultArg((input_1 = tryFind((x) => (x.LogPointType === 0), vehicleLog.LogPoints), (input_1 == null) ? undefined : ((x_1 = input_1, [x_1.Odo, x_1.DateTime, DateTime_dateTimeToStringWithDayName(x_1.DateTime)]))), [fromParts(0, 0, 0, false, 0), minValue(), ""]);
    const patternInput_1 = defaultArg((input_3 = tryFind((x_2) => (x_2.LogPointType === 2), vehicleLog.LogPoints), (input_3 == null) ? undefined : ((x_3 = input_3, [x_3.Odo, x_3.DateTime, DateTime_dateTimeToStringWithDayName(x_3.DateTime)]))), [fromParts(0, 0, 0, false, 0), minValue(), ""]);
    let input_6;
    const input_5 = traverseResultA((x_4) => x_4, map(LogPointDtoModule_toLogPoint, vehicleLog.LogPoints));
    input_6 = ((input_5.tag === 1) ? (new FSharpResult$2(1, [concat(input_5.fields[0])])) : (new FSharpResult$2(0, [input_5.fields[0]])));
    if (input_6.tag === 1) {
        return new FSharpResult$2(1, [input_6.fields[0]]);
    }
    else {
        return new FSharpResult$2(0, [new VehicleLogViewData(patternInput[2], patternInput_1[2], `${vehicle.Make} ${vehicle.Model} - ${vehicle.Registration}`, DistanceUnitModule_toString(vehicle.DistanceUnit), (matchValue = (vehicleLog.status | 0), (matchValue === 0) ? "Open" : ((matchValue === 1) ? "Finished" : ((matchValue === 2) ? "Discarded" : (() => {
            throw new Error("Unknown Status");
        })()))), vehicleLog.Tag.Id, length(filter((x_6) => {
            if (x_6.LogPointType === 1) {
                return true;
            }
            else {
                return x_6.LogPointType === 3;
            }
        }, vehicleLog.LogPoints)), System_TimeSpan__TimeSpan_ToDisplayDuration(op_Subtraction(patternInput_1[1], patternInput[1])), op_Subtraction_1(patternInput_1[0], patternInput[0]), join(",", [vehicleLog.Notes]), (matchValue_1 = (vehicleLog.status | 0), (matchValue_1 === 0) ? "" : ((matchValue_1 === 1) ? "" : ((matchValue_1 === 2) ? "text-red-500" : ""))), input_6.fields[0])]);
    }
}

export class State extends Record {
    constructor(DateFrom, DateTo, VehiclesToSearchFor, VehicleLogTags, AllVehicleLogTags, VehicleLogs, UserMeasurementSystem, NextPagination, CurrentPagination, IsBusy, Errors) {
        super();
        this.DateFrom = DateFrom;
        this.DateTo = DateTo;
        this.VehiclesToSearchFor = VehiclesToSearchFor;
        this.VehicleLogTags = VehicleLogTags;
        this.AllVehicleLogTags = AllVehicleLogTags;
        this.VehicleLogs = VehicleLogs;
        this.UserMeasurementSystem = UserMeasurementSystem;
        this.NextPagination = NextPagination;
        this.CurrentPagination = CurrentPagination;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.LogMaster.VehicleLogAggregate.State", [], State, () => [["DateFrom", class_type("System.DateOnly")], ["DateTo", class_type("System.DateOnly")], ["VehiclesToSearchFor", list_type(class_type("System.Guid"))], ["VehicleLogTags", list_type(class_type("System.Guid"))], ["AllVehicleLogTags", list_type(VehicleLogTag_$reflection())], ["VehicleLogs", list_type(VehicleLogViewData_$reflection())], ["UserMeasurementSystem", MeasurementSystem_$reflection()], ["NextPagination", Pagination_$reflection()], ["CurrentPagination", Pagination_$reflection()], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetVehicleLogs", "GetVehicleLogsResponse", "GotoPreviousPage", "GotoNextPage", "GotoFirstPage", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.LogMaster.VehicleLogAggregate.Msg", [], Msg, () => [[], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(VehicleLogViewData_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", list_type(VehicleLogViewData_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [], [], [], [["Item", class_type("System.Exception")]]]);
}

export function getVehicleLogsCmd(state) {
    return cmdOfAsync((Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(5, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_1;
        const input = getVehicleLogs(appRoot.VehicleService, AppRootModule_getSelectedOrgId(), state.DateFrom, state.DateTo, state.VehiclesToSearchFor, state.VehicleLogTags, state.NextPagination);
        asyncResult_1 = singleton.Bind(input, (x$0027) => {
            let x, vehicleLogs;
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [(x = input_2.fields[0], (vehicleLogs = x[1], map2(VehicleLogDashboardViewData_ofVehicleLogDto, choose((y) => tryFind((z) => (z.Id === y.VehicleId), x[0]), vehicleLogs), vehicleLogs)))])));
            return singleton.Return(value);
        });
        return singleton.Bind(asyncResult_1, (input_1_2) => {
            const input_8 = input_1_2;
            if (input_8.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_8.fields[0]]));
            }
            else {
                let value_1_2;
                const input_6 = traverseResultA((x_3) => x_3, input_8.fields[0]);
                value_1_2 = ((input_6.tag === 1) ? (new FSharpResult$2(1, [concat(input_6.fields[0])])) : (new FSharpResult$2(0, [input_6.fields[0]])));
                return singleton.Return(value_1_2);
            }
        });
    }));
}

export function init(dateRange, vehiclesToSearchFor, vehicleLogTags, allVehicleLogTags, userMeasurementSystem) {
    let state;
    const DateFrom = fromDateTime(dateRange.startDate);
    const DateTo = fromDateTime(dateRange.endDate);
    const CurrentPagination = getDefaultPagination();
    state = (new State(DateFrom, DateTo, vehiclesToSearchFor, vehicleLogTags, allVehicleLogTags, empty(), userMeasurementSystem, getDefaultPagination(), CurrentPagination, new Busy(0, []), empty()));
    return [state, (isEmpty(vehiclesToSearchFor) ? true : isEmpty(vehicleLogTags)) ? Cmd_none() : getVehicleLogsCmd(state)];
}

function changePage(state, f) {
    const currentState = state;
    const newState = new State(state.DateFrom, state.DateTo, state.VehiclesToSearchFor, state.VehicleLogTags, state.AllVehicleLogTags, state.VehicleLogs, state.UserMeasurementSystem, f(state.CurrentPagination), state.CurrentPagination, state.IsBusy, state.Errors);
    if (equals(currentState, newState)) {
        return [currentState, Cmd_none()];
    }
    else {
        return [newState, getVehicleLogsCmd(newState)];
    }
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            if (msg.fields[0].tag === 1) {
                return [new State(state.DateFrom, state.DateTo, state.VehiclesToSearchFor, state.VehicleLogTags, state.AllVehicleLogTags, state.VehicleLogs, state.UserMeasurementSystem, state.NextPagination, state.CurrentPagination, state.IsBusy, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [isEmpty(msg.fields[0].fields[0]) ? (new State(state.DateFrom, state.DateTo, state.VehiclesToSearchFor, state.VehicleLogTags, state.AllVehicleLogTags, state.VehicleLogs, state.UserMeasurementSystem, state.CurrentPagination, state.CurrentPagination, state.IsBusy, state.Errors)) : (new State(state.DateFrom, state.DateTo, state.VehiclesToSearchFor, state.VehicleLogTags, state.AllVehicleLogTags, msg.fields[0].fields[0], state.UserMeasurementSystem, state.NextPagination, state.NextPagination, state.IsBusy, state.Errors)), Cmd_none()];
            }
        case 2:
            return changePage(state, previousPage);
        case 3:
            return changePage(state, nextPage);
        case 4:
            return changePage(state, firstPage);
        case 5:
            return [new State(state.DateFrom, state.DateTo, state.VehiclesToSearchFor, state.VehicleLogTags, state.AllVehicleLogTags, state.VehicleLogs, state.UserMeasurementSystem, state.NextPagination, state.CurrentPagination, state.IsBusy, singleton_1(toString(msg.fields[0]))), Cmd_none()];
        default:
            return [state, getVehicleLogsCmd(state)];
    }
}

export function indexDashboardView(indexDashboardViewInputProps) {
    let elems_1, elems, children_2, children, children_6;
    const userMeasurementSystem = indexDashboardViewInputProps.userMeasurementSystem;
    const allVehicleLogTags = indexDashboardViewInputProps.allVehicleLogTags;
    const vehicleLogTags = indexDashboardViewInputProps.vehicleLogTags;
    const vehiclesToSearchFor = indexDashboardViewInputProps.vehiclesToSearchFor;
    const dateRange = indexDashboardViewInputProps.dateRange;
    let patternInput;
    const init_1 = init(dateRange, vehiclesToSearchFor, vehicleLogTags, allVehicleLogTags, userMeasurementSystem);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [dateRange, vehiclesToSearchFor, vehicleLogTags]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const distanceUnit = (state_1.UserMeasurementSystem.tag === 1) ? DistanceUnitModule_toString(new DistanceUnit(1, [])) : DistanceUnitModule_toString(new DistanceUnit(0, []));
    return createElement("div", createObj(singleton_1((elems_1 = [Daisy_error(state_1.Errors), createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", join(" ", ["flex", "w-full", "gap-4"])], ["className", "table-xs"], ["className", "table-zebra"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], (elems = [(children_2 = singleton_1((children = ofArray([createElement("th", {
        children: "Start",
    }), createElement("th", {
        children: "End",
    }), createElement("th", {
        children: "Vehicle",
    }), createElement("th", {
        children: "Status",
    }), createElement("th", {
        className: join(" ", ["text-right"]),
        children: "Stops",
    }), createElement("th", {
        className: join(" ", ["text-right"]),
        children: "Duration",
    }), createElement("th", {
        className: join(" ", ["text-right"]),
        children: `Distance (${distanceUnit})`,
    }), createElement("th", {
        children: "Notes",
    })]), createElement("tr", {
        children: reactApi.Children.toArray(Array.from(children)),
    }))), createElement("thead", {
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), (children_6 = toList(delay(() => collect((x) => {
        let children_4, v_3;
        const row = (v) => createElement("th", {
            className: join(" ", [x.RowColor]),
            children: v,
        });
        return singleton_2((children_4 = ofArray([row(x.Start), row(x.End), row(x.Vehicle), row(x.Status), createElement("th", {
            className: join(" ", [x.RowColor, "text-right"]),
            children: x.Stops,
        }), createElement("th", {
            className: join(" ", [x.RowColor, "text-right"]),
            children: x.TotalDuration,
        }), (v_3 = toNumber(x.TotalDistance), createElement("th", {
            className: join(" ", [x.RowColor, "text-right"]),
            children: v_3,
        })), row(x.Notes)]), createElement("tr", {
            children: reactApi.Children.toArray(Array.from(children_4)),
        })));
    }, state_1.VehicleLogs))), createElement("tbody", {
        children: reactApi.Children.toArray(Array.from(children_6)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems))])])))), createElement("div", createObj(Helpers_combineClasses("divider", empty()))), paginationControl_1(false, state_1.CurrentPagination.CurrentPage, (_arg_4) => {
        dispatch(new Msg(2, []));
    }, (_arg_5) => {
        dispatch(new Msg(3, []));
    }, (_arg_6) => {
        dispatch(new Msg(4, []));
    })], ["children", reactApi.Children.toArray(Array.from(elems_1))]))));
}

export function indexLogReportView(indexLogReportViewInputProps) {
    let elems_16;
    const userMeasurementSystem = indexLogReportViewInputProps.userMeasurementSystem;
    const allTags = indexLogReportViewInputProps.allTags;
    const vehicleLogTags = indexLogReportViewInputProps.vehicleLogTags;
    const vehiclesToSearchFor = indexLogReportViewInputProps.vehiclesToSearchFor;
    const dateRange = indexLogReportViewInputProps.dateRange;
    let patternInput;
    const init_1 = init(dateRange, vehiclesToSearchFor, vehicleLogTags, empty(), userMeasurementSystem);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [dateRange, vehiclesToSearchFor, vehicleLogTags]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const distanceUnit = (state_1.UserMeasurementSystem.tag === 1) ? DistanceUnitModule_toString(new DistanceUnit(1, [])) : DistanceUnitModule_toString(new DistanceUnit(0, []));
    const paginationControl = paginationControl_1(false, state_1.CurrentPagination.CurrentPage, (_arg_4) => {
        dispatch(new Msg(2, []));
    }, (_arg_5) => {
        dispatch(new Msg(3, []));
    }, (_arg_6) => {
        dispatch(new Msg(4, []));
    });
    return createElement("div", createObj(singleton_1((elems_16 = toList(delay(() => append(singleton_2(Daisy_error(state_1.Errors)), delay(() => append(singleton_2(createElement("div", {
        className: join(" ", ["mb-4"]),
        children: reactApi.Children.toArray([paginationControl]),
    })), delay(() => append(collect((vehicleLog) => {
        let input_1, elems_15, children_30, elems_2, elems_1, elems_3, children, elems_11, children_10, children_8, children_26, elems_12, elems_13;
        const vehicleLogTag = defaultArg((input_1 = tryFind((x) => (x.Id === vehicleLog.Tag), allTags), (input_1 == null) ? undefined : input_1.Name), "NoTag");
        return singleton_2(createElement("div", createObj(Helpers_combineClasses("card", ofArray([["className", join(" ", ["mb-4", "shadow-xl"])], ["className", "bordered"], (elems_15 = [(children_30 = ofArray([createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-2"])], (elems_2 = [Daisy_h6(vehicleLog.Start), createElement("div", createObj(ofArray([["className", join(" ", ["text-right"])], (elems_1 = [Daisy_h6(vehicleLog.Vehicle)], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-3", "gap-4"])], (elems_3 = [Daisy_h4(vehicleLog.Notes), createElement("div", {}), createElement("h4", {
            className: join(" ", ["mb-3", "text-2xl", "font-bold", "italic", "text-right", "text-blue-300"]),
            children: vehicleLog.Status,
        })], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))), (children = singleton_1(Daisy_h6(`Tag: ${vehicleLogTag}`)), createElement("div", {
            children: reactApi.Children.toArray(Array.from(children)),
        })), createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], (elems_11 = [(children_10 = singleton_1((children_8 = ofArray([createElement("td", {
            children: reactApi.Children.toArray(["Note"]),
        }), createElement("td", {
            children: reactApi.Children.toArray(["Date"]),
        }), createElement("td", {
            children: reactApi.Children.toArray(["Address"]),
        }), createElement("td", {
            className: join(" ", ["text-right"]),
            children: reactApi.Children.toArray(["Odometer"]),
        }), createElement("td", {
            className: join(" ", ["text-right"]),
            children: reactApi.Children.toArray(["Time"]),
        })]), createElement("tr", {
            children: reactApi.Children.toArray(Array.from(children_8)),
        }))), createElement("thead", {
            children: reactApi.Children.toArray(Array.from(children_10)),
        })), (children_26 = toList(delay(() => collect((logPoint) => {
            let children_18, children_12, matchValue_1, children_14, children_16, elems_6, elems_7;
            return append(singleton_2((children_18 = ofArray([(children_12 = singleton_1(defaultArg(Option_ofString(logPoint.Note), (matchValue_1 = (logPoint.LogPointType | 0), (matchValue_1 === 0) ? "Start" : ((matchValue_1 === 2) ? "End" : "")))), createElement("td", {
                children: reactApi.Children.toArray(Array.from(children_12)),
            })), (children_14 = singleton_1(DateTime_dateTimeToStringWithDayName(logPoint.DateTime)), createElement("td", {
                children: reactApi.Children.toArray(Array.from(children_14)),
            })), (children_16 = singleton_1(defaultArg(logPoint.Location.Address, "")), createElement("td", {
                children: reactApi.Children.toArray(Array.from(children_16)),
            })), createElement("td", createObj(ofArray([["className", join(" ", ["text-right"])], (elems_6 = [toNumber(logPoint.Odo)], ["children", reactApi.Children.toArray(Array.from(elems_6))])]))), createElement("td", createObj(ofArray([["className", join(" ", ["text-right"])], (elems_7 = [DateTime_timeToString(logPoint.DateTime)], ["children", reactApi.Children.toArray(Array.from(elems_7))])])))]), createElement("tr", {
                children: reactApi.Children.toArray(Array.from(children_18)),
            }))), delay(() => {
                let children_22, children_20, elems_8;
                const matchValue_2 = logPoint.EndDateTime;
                if (matchValue_2 == null) {
                    return empty_1();
                }
                else {
                    const endDateTime = matchValue_2;
                    return append(singleton_2((children_22 = ofArray([createElement("td", {}), (children_20 = singleton_1(DateTime_dateTimeToStringWithDayName(endDateTime)), createElement("td", {
                        children: reactApi.Children.toArray(Array.from(children_20)),
                    })), createElement("td", {}), createElement("td", {}), createElement("td", createObj(ofArray([["className", join(" ", ["text-right"])], (elems_8 = [DateTime_timeToString(endDateTime)], ["children", reactApi.Children.toArray(Array.from(elems_8))])])))]), createElement("tr", {
                        children: reactApi.Children.toArray(Array.from(children_22)),
                    }))), delay(() => {
                        let children_24, elems_10, x_2, copyOfStruct, copyOfStruct_1;
                        return singleton_2((children_24 = ofArray([createElement("td", {}), createElement("td", {
                            className: join(" ", ["italic", "font-bold"]),
                            children: reactApi.Children.toArray(["Duration"]),
                        }), createElement("td", {}), createElement("td", {}), createElement("td", createObj(ofArray([["className", join(" ", ["text-right", "font-bold"])], (elems_10 = [(x_2 = op_Subtraction(endDateTime, logPoint.DateTime), (((copyOfStruct = hours(x_2), int32ToString(copyOfStruct))) + ":") + ((copyOfStruct_1 = (minutes(x_2) | 0), format('{0:' + "#00" + '}', copyOfStruct_1))))], ["children", reactApi.Children.toArray(Array.from(elems_10))])])))]), createElement("tr", {
                            children: reactApi.Children.toArray(Array.from(children_24)),
                        })));
                    }));
                }
            }));
        }, vehicleLog.LogPoints))), createElement("tbody", {
            children: reactApi.Children.toArray(Array.from(children_26)),
        }))], ["children", reactApi.Children.toArray(Array.from(elems_11))])])))), createElement("div", createObj(Helpers_combineClasses("divider", empty()))), createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-3", "gap-4"])], (elems_12 = [Daisy_h6("Total Duration (h:mm)"), createElement("div", {}), createElement("h4", {
            className: join(" ", ["mb-1", "text-sm", "font-bold", "text-right"]),
            children: vehicleLog.TotalDuration,
        })], ["children", reactApi.Children.toArray(Array.from(elems_12))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-3", "gap-4"])], (elems_13 = [Daisy_h6(`Total Distance (${distanceUnit})`), createElement("div", {}), createElement("h4", {
            className: join(" ", ["mb-1", "text-sm", "font-bold", "text-right"]),
            children: toNumber(vehicleLog.TotalDistance),
        })], ["children", reactApi.Children.toArray(Array.from(elems_13))])])))]), createElement("div", {
            className: "card-body",
            children: reactApi.Children.toArray(Array.from(children_30)),
        }))], ["children", reactApi.Children.toArray(Array.from(elems_15))])])))));
    }, state_1.VehicleLogs), delay(() => singleton_2(paginationControl))))))))), ["children", reactApi.Children.toArray(Array.from(elems_16))]))));
}

