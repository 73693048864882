import { appRoot, AppRootModule_getSelectedOrgId } from "../../../Infrastructure/AppRoot.fs.js";
import { searchExpenses } from "../../../AppDomain/UseCase/Expense.fs.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { uncurry2, createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { cons, map as map_2, ofArray, singleton } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { ExpenseStatusModule_ofString, ExpenseStatusModule_toString, ExpenseSearchDetails } from "../../../../Logos.Shared/Domain/Entity/Expense.fs.js";
import { IndexView as IndexView_2, Msg$2 } from "../BaseSearchAdvanced.fs.js";
import { PropHelpers_createOnKey } from "../../../fable_modules/Feliz.2.8.0/./Properties.fs.js";
import { key_enter } from "../../../fable_modules/Feliz.2.8.0/Key.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { map, defaultArg } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { Result_ToOption } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { collect, singleton as singleton_1, append, map as map_1, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { Option_toString, System_Decimal__Decimal_ToDisplayCurrency, System_DateTime__DateTime_ToLocalKind, String_splitWord } from "../../../../Logos.Shared/Util.fs.js";
import { name as name_70 } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { leaveRequestStatuses } from "../../../../Logos.Shared/Domain/Entity/User.fs.js";
import { parse, toString } from "../../../fable_modules/fable-library-js.4.19.3/Date.js";
import { fromDate, toLocalTime } from "../../../fable_modules/fable-library-js.4.19.3/DateOffset.js";
import { iconButton, showModalDialog } from "../../Component/ModalDialog.fs.js";
import { alignIconCenter, alignCellTextTop, Daisy_h5, Daisy_DialogWidth } from "../../Component/Component.fs.js";
import { IndexView as IndexView_1 } from "./ExpenseEdit.fs.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { DateTime_dateTimeToStringWithDayName } from "../../../AppDomain/Util.fs.js";

export function searchFunc(expenseSearchDetails) {
    const selectedOrgId = AppRootModule_getSelectedOrgId();
    return (pagination) => searchExpenses(appRoot.ExpenseService, selectedOrgId, expenseSearchDetails, pagination);
}

export function searchPanel(props) {
    let children_2, elems, elems_10, children_8, children_5, elems_4, elems_3, children_13, children_11, children_18, children_16;
    const children_20 = ofArray([(children_2 = ofArray([createElement("label", createObj(Helpers_combineClasses("label", singleton(["children", "Search"])))), createElement("div", createObj(ofArray([["className", "flex space-x-2"], (elems = [createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "w-full"], ["className", "input-bordered"], ["className", "input-sm"], ["autoFocus", true], ["placeholder", "Search"], ["readOnly", false], ["onChange", (ev) => {
        let bind$0040;
        props.dispatch(new Msg$2(0, [(bind$0040 = props.state.SearchDetails, new ExpenseSearchDetails(ev.target.value, bind$0040.Status, bind$0040.FromDate, bind$0040.ToDate))]));
    }], ["onKeyUp", (ev_1) => {
        PropHelpers_createOnKey(key_enter, (_arg) => {
            props.dispatch(new Msg$2(1, []));
        }, ev_1);
    }]])))), createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-primary"], ["className", "btn-sm"], ["children", "Search"], ["onClick", (_arg_1) => {
        props.dispatch(new Msg$2(1, []));
    }]]))))], ["children", reactApi.Children.toArray(Array.from(elems))])])))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), createElement("div", createObj(ofArray([["className", "flex space-x-2 mt-2"], (elems_10 = [(children_8 = ofArray([(children_5 = singleton(createElement("span", {
        className: "label-text",
        children: "Status",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_5)),
    })), createElement("div", createObj(ofArray([["className", "flex space-x-2"], (elems_4 = [createElement("select", createObj(Helpers_combineClasses("select", ofArray([["className", "select-bordered"], ["className", "select-sm"], ["placeholder", "Status"], ["value", defaultArg(map(ExpenseStatusModule_toString, props.state.SearchDetails.Status), "All")], ["onChange", (ev_2) => {
        let bind$0040_1;
        const x_1 = ev_2.target.value;
        props.dispatch(new Msg$2(0, [(bind$0040_1 = props.state.SearchDetails, new ExpenseSearchDetails(bind$0040_1.SearchText, (x_1 === "All") ? undefined : Result_ToOption(ExpenseStatusModule_ofString(x_1)), bind$0040_1.FromDate, bind$0040_1.ToDate))]));
    }], (elems_3 = toList(delay(() => map_1((case$) => createElement("option", {
        value: case$,
        children: String_splitWord(case$),
    }), cons("All", map_2(name_70, ofArray(leaveRequestStatuses)))))), ["children", reactApi.Children.toArray(Array.from(elems_3))])]))))], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_8)),
    })), (children_13 = ofArray([(children_11 = singleton(createElement("span", {
        className: "label-text",
        children: "From Date",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_11)),
    })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["type", "date"], ["className", "input-bordered"], ["className", "input-sm"], ["value", defaultArg(map((x_5) => toString(toLocalTime(x_5), "yyyy-MM-dd"), props.state.SearchDetails.FromDate), "")], ["onInput", (e) => {
        let bind$0040_2, bind$0040_3;
        if (e.target.value === "") {
            props.dispatch(new Msg$2(0, [(bind$0040_2 = props.state.SearchDetails, new ExpenseSearchDetails(bind$0040_2.SearchText, bind$0040_2.Status, undefined, bind$0040_2.ToDate))]));
        }
        else {
            props.dispatch(new Msg$2(0, [(bind$0040_3 = props.state.SearchDetails, new ExpenseSearchDetails(bind$0040_3.SearchText, bind$0040_3.Status, fromDate(System_DateTime__DateTime_ToLocalKind(parse(e.target.value))), bind$0040_3.ToDate))]));
        }
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_13)),
    })), (children_18 = ofArray([(children_16 = singleton(createElement("span", {
        className: "label-text",
        children: "To Date",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_16)),
    })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["type", "date"], ["className", "input-bordered"], ["className", "input-sm"], ["value", defaultArg(map((x_6) => toString(toLocalTime(x_6), "yyyy-MM-dd"), props.state.SearchDetails.ToDate), "")], ["onInput", (e_1) => {
        let bind$0040_4, bind$0040_5;
        if (e_1.target.value === "") {
            props.dispatch(new Msg$2(0, [(bind$0040_4 = props.state.SearchDetails, new ExpenseSearchDetails(bind$0040_4.SearchText, bind$0040_4.Status, bind$0040_4.FromDate, undefined))]));
        }
        else {
            props.dispatch(new Msg$2(0, [(bind$0040_5 = props.state.SearchDetails, new ExpenseSearchDetails(bind$0040_5.SearchText, bind$0040_5.Status, bind$0040_5.FromDate, fromDate(System_DateTime__DateTime_ToLocalKind(parse(e_1.target.value)))))]));
        }
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_18)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_10))])])))]);
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_20)),
    });
}

export function expenseTable(props) {
    const children_7 = toList(delay(() => {
        let matchValue_1, x_1;
        return append((matchValue_1 = props.state.EntityToEdit, (matchValue_1 == null) ? singleton_1(react.createElement(react.Fragment, {})) : ((x_1 = matchValue_1, singleton_1(createElement(showModalDialog, {
            dialogId: x_1,
            header: "",
            width: new Daisy_DialogWidth(1, []),
            heightPercent: "70",
            body: () => {
                const matchValue = props.state.EntityToEdit;
                if (matchValue == null) {
                    const xs = [Daisy_h5("No Expense selected.")];
                    return react.createElement(react.Fragment, {}, ...xs);
                }
                else {
                    const x = matchValue;
                    return createElement(IndexView_1, {
                        expenseId: x,
                    });
                }
            },
            onShow: () => {
                props.dispatch(new Msg$2(4, [x_1]));
            },
            onClose: () => {
                props.dispatch(new Msg$2(4, [undefined]));
            },
        }))))), delay(() => {
            let elems_10, children_2, children, children_4;
            return singleton_1(createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["flex", "w-full"])], (elems_10 = [(children_2 = singleton((children = ofArray([createElement("th", {
                width: 15 + "%",
                children: "User",
            }), createElement("th", {
                width: 20 + "%",
                children: "Created",
            }), createElement("th", {
                width: 12.5 + "%",
                children: "Job",
            }), createElement("th", {
                width: 12.5 + "%",
                children: "Description",
            }), createElement("th", {
                width: 5 + "%",
                children: "Amount",
            }), createElement("th", {
                width: 15 + "%",
                children: "Notes",
            }), createElement("th", {
                width: 15 + "%",
                children: "Status",
            }), createElement("th", {
                width: 5 + "%",
                children: "",
            })]), createElement("tr", {
                children: reactApi.Children.toArray(Array.from(children)),
            }))), createElement("thead", {
                children: reactApi.Children.toArray(Array.from(children_2)),
            })), (children_4 = toList(delay(() => collect((expense) => {
                let elems_9, elems_2, elems_1, elems, elems_4, elems_3, elems_6, elems_5, elems_8, elems_7;
                const username = ((expense.FirstName + " ") + ((expense.MiddleName === "") ? "" : (`${expense.MiddleName} `))) + expense.LastName;
                return singleton_1(createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top", "hover"])], (elems_9 = [createElement("td", createObj(ofArray([alignCellTextTop, ["style", {
                    cursor: "pointer",
                }], ["onClick", (_arg_2) => {
                    props.dispatch(new Msg$2(4, [expense.Id]));
                }], (elems_2 = [createElement("div", createObj(singleton((elems_1 = [createElement("span", createObj(ofArray([["style", {
                    display: "flex",
                    alignItems: "center",
                }], (elems = [createElement("i", createObj(toList(delay(() => {
                    const matchValue_2 = expense.Status;
                    return (matchValue_2.tag === 1) ? append(singleton_1(["className", "fa fa-paper-plane"]), delay(() => singleton_1(["style", {
                        marginRight: "8px",
                        color: "blue",
                    }]))) : ((matchValue_2.tag === 4) ? append(singleton_1(["className", "fa fa-undo"]), delay(() => singleton_1(["style", {
                        marginRight: "8px",
                        color: "blue",
                    }]))) : ((matchValue_2.tag === 2) ? append(singleton_1(["className", "fa fa-check"]), delay(() => singleton_1(["style", {
                        marginRight: "8px",
                        color: "green",
                    }]))) : ((matchValue_2.tag === 3) ? append(singleton_1(["className", "fa fa-times"]), delay(() => singleton_1(["style", {
                        marginRight: "8px",
                        color: "red",
                    }]))) : append(singleton_1(["className", "fa fa-book"]), delay(() => singleton_1(["style", {
                        marginRight: "8px",
                        color: "blue",
                    }]))))));
                })))), createElement("div", createObj(ofArray([alignCellTextTop, ["children", username]])))], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))]))))], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("td", createObj(ofArray([alignCellTextTop, ["style", {
                    cursor: "pointer",
                }], ["onClick", (_arg_3) => {
                    props.dispatch(new Msg$2(4, [expense.Id]));
                }], ["children", DateTime_dateTimeToStringWithDayName(toLocalTime(expense.CreatedAt))]]))), createElement("td", createObj(ofArray([alignCellTextTop, ["style", {
                    cursor: "pointer",
                }], ["onClick", (_arg_4) => {
                    props.dispatch(new Msg$2(4, [expense.Id]));
                }], ["children", expense.JobTitle]]))), createElement("td", createObj(ofArray([alignCellTextTop, ["style", {
                    cursor: "pointer",
                }], ["onClick", (_arg_5) => {
                    props.dispatch(new Msg$2(4, [expense.Id]));
                }], ["children", expense.Description]]))), createElement("td", createObj(ofArray([alignCellTextTop, ["style", {
                    cursor: "pointer",
                }], ["onClick", (_arg_6) => {
                    props.dispatch(new Msg$2(4, [expense.Id]));
                }], ["children", System_Decimal__Decimal_ToDisplayCurrency(expense.Amount)]]))), createElement("td", createObj(ofArray([alignCellTextTop, ["style", {
                    cursor: "pointer",
                }], ["onClick", (_arg_7) => {
                    props.dispatch(new Msg$2(4, [expense.Id]));
                }], ["children", Option_toString(expense.Notes)]]))), createElement("td", createObj(ofArray([alignCellTextTop, ["style", {
                    cursor: "pointer",
                }], ["onClick", (_arg_8) => {
                    props.dispatch(new Msg$2(4, [expense.Id]));
                }], (elems_4 = [createElement("div", createObj(ofArray([["className", "grid grid-rows-2"], (elems_3 = toList(delay(() => append(singleton_1(createElement("div", {
                    children: ExpenseStatusModule_toString(expense.Status),
                })), delay(() => {
                    const matchValue_3 = expense.Status;
                    switch (matchValue_3.tag) {
                        case 2:
                            return singleton_1(createElement("div", {
                                children: defaultArg(expense.ApprovedByUsername, ""),
                            }));
                        case 3:
                            return append(singleton_1(createElement("div", {
                                children: defaultArg(expense.RejectedByUsername, ""),
                            })), delay(() => {
                                const matchValue_4 = expense.RejectionReason;
                                return (matchValue_4 == null) ? singleton_1(react.createElement(react.Fragment, {})) : singleton_1(createElement("div", {
                                    className: "text-red-500",
                                    children: matchValue_4,
                                }));
                            }));
                        default:
                            return singleton_1(react.createElement(react.Fragment, {}));
                    }
                })))), ["children", reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("td", createObj(ofArray([["style", {
                    cursor: "pointer",
                }], ["onClick", (_arg_10) => {
                    props.dispatch(new Msg$2(4, [expense.Id]));
                }], (elems_6 = [createElement("div", createObj(ofArray([alignCellTextTop, ["key", expense.Id], (elems_5 = [createElement("div", {
                    className: join(" ", ["font-bold"]),
                    children: "",
                })], ["children", reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", reactApi.Children.toArray(Array.from(elems_6))])]))), createElement("td", createObj(singleton((elems_8 = [createElement("div", createObj(ofArray([alignIconCenter, (elems_7 = [iconButton("fas fa-edit", (_arg_11) => {
                    props.dispatch(new Msg$2(4, [expense.Id]));
                })], ["children", reactApi.Children.toArray(Array.from(elems_7))])])))], ["children", reactApi.Children.toArray(Array.from(elems_8))]))))], ["children", reactApi.Children.toArray(Array.from(elems_9))])]))));
            }, props.state.Entities))), createElement("tbody", {
                children: reactApi.Children.toArray(Array.from(children_4)),
            }))], ["children", reactApi.Children.toArray(Array.from(elems_10))])])))));
        }));
    }));
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_7)),
    });
}

export function IndexView() {
    return createElement(IndexView_2, {
        entityName: "Expense",
        entityTable: expenseTable,
        searchDetails: new ExpenseSearchDetails("", undefined, undefined, undefined),
        searchFunc: uncurry2(searchFunc),
        searchPanel: searchPanel,
        xPadding: "",
        yPadding: "",
    });
}

