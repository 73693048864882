import { Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { class_type, union_type, record_type, list_type, option_type, string_type, bool_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { DocumentTag as DocumentTag_2, DocumentTag_$reflection } from "../../../../Logos.Shared/Domain/Entity/Document.fs.js";
import { BusyModule_isSaving, Busy, Busy_$reflection } from "../../SharedView.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { Guid_parse } from "../../../../Logos.Shared/Util.fs.js";
import { updateDocumentTag, addDocumentTag, getDocumentTag as getDocumentTag_1 } from "../../../AppDomain/UseCase/Document.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../Infrastructure/AppRoot.fs.js";
import { ofArray, empty, singleton as singleton_1 } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { join, isNullOrEmpty } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../../Component/ViewContainer.fs.js";
import { Daisy_progressState, Daisy_error, Daisy_h4 } from "../../Component/Component.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { empty as empty_1, singleton as singleton_2, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";

export class State extends Record {
    constructor(IsNewTag, Name, DocumentTag, IsBusy, Errors) {
        super();
        this.IsNewTag = IsNewTag;
        this.Name = Name;
        this.DocumentTag = DocumentTag;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.DocumentTagEdit.State", [], State, () => [["IsNewTag", bool_type], ["Name", string_type], ["DocumentTag", option_type(DocumentTag_$reflection())], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetName", "GetDocumentTagResponse", "SaveDocumentTag", "SaveDocumentTagResponse", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.DocumentTagEdit.Msg", [], Msg, () => [[["Item", string_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [option_type(DocumentTag_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", option_type(DocumentTag_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [option_type(class_type("System.Guid")), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", option_type(class_type("System.Guid"))]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Exception")]]]);
}

export function getDocumentTagCmd(documentId) {
    return cmdOfAsync((Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(4, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_1;
        const value_1 = Guid_parse(documentId);
        asyncResult_1 = singleton.Return(value_1);
        return singleton.Bind(asyncResult_1, (input_1) => {
            const input_2 = input_1;
            if (input_2.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
            }
            else {
                return getDocumentTag_1(appRoot.DocumentService, AppRootModule_getSelectedOrgId(), input_2.fields[0]);
            }
        });
    }));
}

export function saveDocumentTagCmd(state) {
    return cmdOfAsync((Item) => (new Msg(3, [Item])), (Item_1) => (new Msg(4, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_2;
        let value_1_1;
        if (state.IsNewTag) {
            value_1_1 = (new FSharpResult$2(0, [new DocumentTag_2("00000000-0000-0000-0000-000000000000", AppRootModule_getSelectedOrgId(), state.Name)]));
        }
        else {
            const option_1 = state.DocumentTag;
            value_1_1 = ((option_1 == null) ? (new FSharpResult$2(1, [singleton_1("Document tag data not found.")])) : (new FSharpResult$2(0, [option_1])));
        }
        asyncResult_2 = singleton.Return(value_1_1);
        return singleton.Bind(asyncResult_2, (input_1_3) => {
            const input_11 = input_1_3;
            if (input_11.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_11.fields[0]]));
            }
            else {
                const documentTag = input_11.fields[0];
                const updatedDocumentTag = new DocumentTag_2(documentTag.Id, documentTag.OrganisationId, state.Name);
                if (state.IsNewTag) {
                    const input = addDocumentTag(appRoot.DocumentService, AppRootModule_getSelectedOrgId(), updatedDocumentTag);
                    return singleton.Bind(input, (x$0027) => {
                        let value_2;
                        const input_2 = x$0027;
                        value_2 = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [input_2.fields[0]])));
                        return singleton.Return(value_2);
                    });
                }
                else {
                    const input_5 = updateDocumentTag(appRoot.DocumentService, AppRootModule_getSelectedOrgId(), updatedDocumentTag);
                    return singleton.Bind(input_5, (x$0027_1) => {
                        let value_4;
                        const input_7 = x$0027_1;
                        value_4 = ((input_7.tag === 1) ? (new FSharpResult$2(1, [input_7.fields[0]])) : (new FSharpResult$2(0, [undefined])));
                        return singleton.Return(value_4);
                    });
                }
            }
        });
    }));
}

export function init(documentId) {
    const isNewTag = isNullOrEmpty(documentId);
    return [new State(isNewTag, "", undefined, isNewTag ? (new Busy(0, [])) : (new Busy(1, [])), empty()), isNewTag ? Cmd_none() : getDocumentTagCmd(documentId)];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            if (msg.fields[0].tag === 1) {
                return [new State(state.IsNewTag, state.Name, state.DocumentTag, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else if (msg.fields[0].fields[0] == null) {
                return [new State(state.IsNewTag, state.Name, undefined, new Busy(0, []), singleton_1("Document tag not found.")), Cmd_none()];
            }
            else {
                const documentTag = msg.fields[0].fields[0];
                return [new State(state.IsNewTag, documentTag.Name, documentTag, new Busy(0, []), state.Errors), Cmd_none()];
            }
        case 2:
            return [new State(state.IsNewTag, state.Name, state.DocumentTag, new Busy(1, []), empty()), saveDocumentTagCmd(state)];
        case 3:
            if (msg.fields[0].tag === 1) {
                return [new State(state.IsNewTag, state.Name, state.DocumentTag, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else if (msg.fields[0].fields[0] == null) {
                return [new State(state.IsNewTag, state.Name, state.DocumentTag, new Busy(0, []), state.Errors), Cmd_none()];
            }
            else {
                const x = msg.fields[0].fields[0];
                return [new State(false, state.Name, state.DocumentTag, new Busy(0, []), state.Errors), state.IsNewTag ? getDocumentTagCmd(x) : Cmd_none()];
            }
        case 4:
            return [new State(state.IsNewTag, state.Name, state.DocumentTag, new Busy(0, []), singleton_1(msg.fields[0].message)), Cmd_none()];
        default:
            return [new State(state.IsNewTag, msg.fields[0], state.DocumentTag, state.IsBusy, state.Errors), Cmd_none()];
    }
}

export function IndexView(indexViewInputProps) {
    let children, children_2, elems_3, children_9, children_7;
    const documentTagId = indexViewInputProps.documentTagId;
    let patternInput;
    const init_1 = init(documentTagId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return container([(children = singleton_1(Daisy_h4((`Document Tag - ${state_1.Name}`).trim())), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    })), Daisy_error(state_1.Errors), (children_2 = singleton_1(createElement(Daisy_progressState, {
        busy: state_1.IsBusy,
    })), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), createElement("button", createObj(Helpers_combineClasses("btn", toList(delay(() => append(BusyModule_isSaving(state_1.IsBusy) ? singleton_2(["className", "loading"]) : empty_1(), delay(() => append(singleton_2(["className", join(" ", ["my-4"])]), delay(() => append(singleton_2(["className", "btn-primary"]), delay(() => append(singleton_2(["className", "btn-sm"]), delay(() => append(singleton_2(["className", join(" ", ["mr-4"])]), delay(() => append(singleton_2(["children", "Save"]), delay(() => singleton_2(["onClick", (_arg_3) => {
        dispatch(new Msg(2, []));
    }])))))))))))))))))), createElement("div", createObj(Helpers_combineClasses("divider", empty()))), createElement("div", createObj(singleton_1((elems_3 = [(children_9 = ofArray([(children_7 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Document Tag name",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_7)),
    })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", state_1.Name], ["placeholder", "Document Tag name"], ["onChange", (ev) => {
        dispatch(new Msg(0, [ev.target.value]));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_9)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_3))]))))]);
}

