import { Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { TimesheetRunDetail_$reflection } from "../../../../Logos.Shared/Domain/Entity/Shift.fs.js";
import { union_type, record_type, option_type, class_type, list_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { join, printf, toConsole } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { TitleSize, viewBox } from "../../Component/ViewBox.fs.js";
import { collect, singleton, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { showModalDialog } from "../../Component/ModalDialog.fs.js";
import { Daisy_DialogWidth } from "../../Component/Component.fs.js";
import { IndexView as IndexView_1 } from "./TimesheetEdit.fs.js";
import { createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { singleton as singleton_1, ofArray } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { value as value_37 } from "../../../../Logos.Shared/Domain/Entity/DateRange.fs.js";
import { DateTime_dateToString } from "../../../AppDomain/Util.fs.js";

export class State extends Record {
    constructor(TimesheetRuns, TimesheetRunToShow) {
        super();
        this.TimesheetRuns = TimesheetRuns;
        this.TimesheetRunToShow = TimesheetRunToShow;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.TimesheetRuns.State", [], State, () => [["TimesheetRuns", list_type(TimesheetRunDetail_$reflection())], ["TimesheetRunToShow", option_type(class_type("System.Guid"))]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ShowTimesheetRun", "HideTimesheetRun"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.TimesheetRuns.Msg", [], Msg, () => [[["Item", class_type("System.Guid")]], []]);
}

export function init(timesheetRuns) {
    return [new State(timesheetRuns, undefined), Cmd_none()];
}

export function update(msg, state) {
    if (msg.tag === 1) {
        return [new State(state.TimesheetRuns, undefined), Cmd_none()];
    }
    else {
        return [new State(state.TimesheetRuns, msg.fields[0]), Cmd_none()];
    }
}

export function IndexView(props) {
    let patternInput;
    const init_1 = init(props.TimesheetRuns);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.TimesheetRuns]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    toConsole(printf("TimesheetRuns: %A"))(props.TimesheetRuns);
    return viewBox(props.Title, new TitleSize(0, []), toList(delay(() => {
        let matchValue, x;
        return append((matchValue = state_1.TimesheetRunToShow, (matchValue == null) ? singleton(react.createElement(react.Fragment, {})) : ((x = matchValue, singleton(createElement(showModalDialog, {
            dialogId: x,
            header: "",
            width: new Daisy_DialogWidth(4, []),
            heightPercent: "90",
            body: () => createElement(IndexView_1, {
                onTimesheetUpdated: () => {
                },
                timesheetRunId: x,
            }),
            onShow: () => {
            },
            onClose: () => {
                dispatch(new Msg(1, []));
            },
        }))))), delay(() => {
            let elems_4, children_2, children, children_5;
            return singleton(createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["flex", "w-full"])], (elems_4 = [(children_2 = singleton_1((children = ofArray([createElement("th", {
                width: 15 + "%",
                children: "Timesheet Run #",
            }), createElement("th", {
                width: 25 + "%",
                children: "Run date range",
            }), createElement("th", {
                children: "",
            })]), createElement("tr", {
                children: reactApi.Children.toArray(Array.from(children)),
            }))), createElement("thead", {
                children: reactApi.Children.toArray(Array.from(children_2)),
            })), (children_5 = toList(delay(() => collect((timesheetRun) => {
                let elems_3, elems_2, elems_1, elems;
                const dateRange = value_37(timesheetRun.DateRange);
                return singleton(createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top", "hover"])], (elems_3 = [createElement("td", {
                    children: timesheetRun.RunNumber,
                }), createElement("td", {
                    children: `${DateTime_dateToString(dateRange.Start)} - ${DateTime_dateToString(dateRange.Finish)}`,
                }), createElement("td", createObj(ofArray([["className", join(" ", ["align-text-top", "font-bold"])], (elems_2 = [createElement("div", createObj(Helpers_combineClasses("tooltip", ofArray([["data-tip", "View Timesheet Run"], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["btn btn-square btn-xs text-primary"])], (elems = [createElement("i", {
                    className: join(" ", ["fa", "fa-book"]),
                })], ["children", reactApi.Children.toArray(Array.from(elems))]), ["onClick", (_arg_6) => {
                    dispatch(new Msg(0, [timesheetRun.Id]));
                }]])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))));
            }, state_1.TimesheetRuns))), createElement("tbody", {
                children: reactApi.Children.toArray(Array.from(children_5)),
            }))], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))));
        }));
    })));
}

