import { Record } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, lambda_type, union_type, list_type, unit_type, string_type, class_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { FSharpResult$2 } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Result.js";
import { XeroCustomerDto_$reflection } from "../Dto/Customer.fs.js";
import { printf, toText } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/String.js";

export class XeroService extends Record {
    constructor(saveXeroCode, getXeroCustomers, saveXeroSettings) {
        super();
        this.saveXeroCode = saveXeroCode;
        this.getXeroCustomers = getXeroCustomers;
        this.saveXeroSettings = saveXeroSettings;
    }
}

export function XeroService_$reflection() {
    return record_type("Logos.Shared.Domain.Remoting.XeroService", [], XeroService, () => [["saveXeroCode", lambda_type(class_type("System.Guid"), lambda_type(string_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])])))], ["getXeroCustomers", lambda_type(class_type("System.Guid"), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(XeroCustomerDto_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", list_type(XeroCustomerDto_$reflection())]], [["ErrorValue", list_type(string_type)]]])]))], ["saveXeroSettings", lambda_type(class_type("System.Guid"), lambda_type(string_type, lambda_type(string_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])]))))]]);
}

export function XeroService_RouteBuilder(s, m) {
    return toText(printf("/api/%s/%s"))(s)(m);
}

