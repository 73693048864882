import { Record, Union } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { list_type, record_type, decimal_type, string_type, class_type, getUnionCases, name, union_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { sortBy } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Array.js";
import { comparePrimitives } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Util.js";
import { FSharpResult$2 } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Result.js";
import { singleton } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/List.js";
import { ScanResultBase$1_$reflection } from "./Document.fs.js";

export class Category extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Unknown", "Food", "Utilities", "Rates", "Transport", "OngoingHouseholdExpenses", "Childcare", "Healthcare", "PersonalCareClothing", "PublicEducation", "PrivateEducation", "HouseContentInsurance", "VehicleInsurance", "HealthMedicalFuneralPetInsurance", "LifeDisabilityInsurance", "SavingsInvestments", "Entertainment", "Tithing", "Other"];
    }
}

export function Category_$reflection() {
    return union_type("Domain.Entity.Assessment.Category", [], Category, () => [[], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], [], []]);
}

export const CategoryModule_categories = sortBy(name, getUnionCases(Category_$reflection(), 0), {
    Compare: comparePrimitives,
});

export function CategoryModule_toString(_arg) {
    switch (_arg.tag) {
        case 1:
            return "Food";
        case 2:
            return "Utilities";
        case 3:
            return "Rates";
        case 4:
            return "Transport";
        case 5:
            return "OngoingHouseholdExpenses";
        case 6:
            return "Childcare";
        case 7:
            return "Healthcare";
        case 8:
            return "PersonalCareClothing";
        case 9:
            return "PublicEducation";
        case 10:
            return "PrivateEducation";
        case 11:
            return "HouseContentInsurance";
        case 12:
            return "VehicleInsurance";
        case 13:
            return "HealthMedicalFuneralPetInsurance";
        case 14:
            return "LifeDisabilityInsurance";
        case 15:
            return "SavingsInvestments";
        case 16:
            return "Entertainment";
        case 17:
            return "Tithing";
        case 18:
            return "Other";
        default:
            return "Unknown";
    }
}

export function CategoryModule_ofString(value) {
    switch (value) {
        case "Unknown":
            return new FSharpResult$2(0, [new Category(0, [])]);
        case "Food":
            return new FSharpResult$2(0, [new Category(1, [])]);
        case "Utilities":
            return new FSharpResult$2(0, [new Category(2, [])]);
        case "Rates":
            return new FSharpResult$2(0, [new Category(3, [])]);
        case "Transport":
            return new FSharpResult$2(0, [new Category(4, [])]);
        case "OngoingHouseholdExpenses":
            return new FSharpResult$2(0, [new Category(5, [])]);
        case "Childcare":
            return new FSharpResult$2(0, [new Category(6, [])]);
        case "Healthcare":
            return new FSharpResult$2(0, [new Category(7, [])]);
        case "PersonalCareClothing":
            return new FSharpResult$2(0, [new Category(8, [])]);
        case "PublicEducation":
            return new FSharpResult$2(0, [new Category(9, [])]);
        case "PrivateEducation":
            return new FSharpResult$2(0, [new Category(10, [])]);
        case "HouseContentInsurance":
            return new FSharpResult$2(0, [new Category(11, [])]);
        case "VehicleInsurance":
            return new FSharpResult$2(0, [new Category(12, [])]);
        case "HealthMedicalFuneralPetInsurance":
            return new FSharpResult$2(0, [new Category(13, [])]);
        case "LifeDisabilityInsurance":
            return new FSharpResult$2(0, [new Category(14, [])]);
        case "SavingsInvestments":
            return new FSharpResult$2(0, [new Category(15, [])]);
        case "Entertainment":
            return new FSharpResult$2(0, [new Category(16, [])]);
        case "Tithing":
            return new FSharpResult$2(0, [new Category(17, [])]);
        case "Other":
            return new FSharpResult$2(0, [new Category(18, [])]);
        default:
            return new FSharpResult$2(1, [singleton("Invalid Category string.")]);
    }
}

export class ScanResultStatementDetailCategorized extends Record {
    constructor(Id, TransactionDate, Description, Amount, Category) {
        super();
        this.Id = Id;
        this.TransactionDate = TransactionDate;
        this.Description = Description;
        this.Amount = Amount;
        this.Category = Category;
    }
}

export function ScanResultStatementDetailCategorized_$reflection() {
    return record_type("Domain.Entity.Assessment.ScanResultStatementDetailCategorized", [], ScanResultStatementDetailCategorized, () => [["Id", class_type("System.Guid")], ["TransactionDate", string_type], ["Description", string_type], ["Amount", decimal_type], ["Category", Category_$reflection()]]);
}

export class Assessment extends Record {
    constructor(Id, AssessmentDate, Scans) {
        super();
        this.Id = Id;
        this.AssessmentDate = AssessmentDate;
        this.Scans = Scans;
    }
}

export function Assessment_$reflection() {
    return record_type("Domain.Entity.Assessment.Assessment", [], Assessment, () => [["Id", class_type("System.Guid")], ["AssessmentDate", class_type("System.DateTime")], ["Scans", list_type(ScanResultBase$1_$reflection(ScanResultStatementDetailCategorized_$reflection()))]]);
}

