import { toString, FSharpRef, Record, Union } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { name as name_186, unit_type, tuple_type, record_type, list_type, int32_type, option_type, string_type, bool_type, class_type, union_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { UserSkillDtoModule_toUserSkill, UserSkillDtoModule_ofUserSkill, UserSkillDto, UserDto as UserDto_1, SkillDtoModule_toSkill, UserDto_$reflection } from "../../../../Logos.Shared/Domain/Dto/User.fs.js";
import { IndexView as IndexView_1, UserSkillVM, UserSkillVMModule_ofUserSkill, UserSkillVMModule_toUserSkill, UserSkillVM_$reflection } from "./UserSkill.fs.js";
import { AccommodationTypeModule_getLookupValues, EnglishProficiencyModule_getLookupValues, ResidencyStatusModule_getLookupValues, EmploymentTypeModule_getLookupValues, AccommodationTypeModule_toString, EnglishProficiencyModule_toString, ResidencyStatusModule_toString, EmploymentTypeModule_ofString, AccommodationTypeModule_ofString, EnglishProficiencyModule_ofString, ResidencyStatusModule_ofString, PayrollSystemInfo as PayrollSystemInfo_1, EmploymentTypeModule_toString, EmploymentType as EmploymentType_1, UserRole as UserRole_1, UserRole_$reflection, Skill_$reflection } from "../../../../Logos.Shared/Domain/Entity/User.fs.js";
import { BusyModule_isSaving, Busy, Busy_$reflection } from "../../SharedView.fs.js";
import { DateRange, DateRange_$reflection } from "../../Component/ReactTailwindDatePicker.fs.js";
import { OrganisationDto_$reflection } from "../../../../Logos.Shared/Domain/Dto/Organisation.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { join, printf, toText, isNullOrEmpty } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { AppRoot__get_SignedInUserRole, appRoot, AppRootModule_getSelectedOrgId } from "../../../Infrastructure/AppRoot.fs.js";
import { String_addSpaceBeforeWord, String_splitWord, Option_ofString, TimeSpan_parseDuration, Guid_parse } from "../../../../Logos.Shared/Util.fs.js";
import { resetPassword, checkUserNameExists, updateUser, addUserToOrganisation, getUser } from "../../../AppDomain/UseCase/User.fs.js";
import { getAllSkills } from "../../../AppDomain/UseCase/Skill.fs.js";
import { ofArray, mapIndexed, choose, append, sortBy, filter, singleton as singleton_1, empty, map } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { zip } from "../../../fable_modules/FsToolkit.ErrorHandling.4.16.0/./Result.fs.js";
import { value as value_505, create } from "../../../../Logos.Shared/Domain/Entity/Email.fs.js";
import { MeasurementSystemModule_measurementSystems, MeasurementSystemModule_toString, MeasurementSystem as MeasurementSystem_1, MeasurementSystemModule_ofString } from "../../../../Logos.Shared/Domain/Entity/MeasurementUnits.fs.js";
import { toString as toString_1, parse, fromParts } from "../../../fable_modules/fable-library-js.4.19.3/Decimal.js";
import Decimal from "../../../fable_modules/fable-library-js.4.19.3/Decimal.js";
import { tryParse } from "../../../fable_modules/fable-library-js.4.19.3/Int32.js";
import { totalHours, fromHours } from "../../../fable_modules/fable-library-js.4.19.3/TimeSpan.js";
import { defaultArg } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { equals, createObj, compareArrays } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { TitleSize, viewBox } from "../../Component/ViewBox.fs.js";
import { collect, empty as empty_1, map as map_1, append as append_1, singleton as singleton_2, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { newGuid } from "../../../fable_modules/fable-library-js.4.19.3/Guid.js";
import { parse as parse_1, minValue } from "../../../fable_modules/fable-library-js.4.19.3/Date.js";
import react_tailwindcss_datepicker from "react-tailwindcss-datepicker";
import { Daisy_progressState, Daisy_error, Daisy_DialogWidth, confirmSimple, Daisy_info, Daisy_h4, Daisy_h6, Daisy_labelWithTooltip } from "../../Component/Component.fs.js";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../../Component/ViewContainer.fs.js";
import { op_PlusPlus } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/Operators.fs.js";
import { rangeDouble } from "../../../fable_modules/fable-library-js.4.19.3/Range.js";
import { IndexView as IndexView_2 } from "./UserInvitePage.fs.js";
import { IndexView as IndexView_3 } from "../Document/DocumentSearch.fs.js";
import { DocumentType$ } from "../../../../Logos.Shared/Domain/Entity/Document.fs.js";
import { IndexView as IndexView_4 } from "./UserSubscriptions.fs.js";

export class Tab extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["UserDetail", "UserDocuments", "UserSubscriptions"];
    }
}

export function Tab_$reflection() {
    return union_type("Presentation.Pages.UserEdit.Tab", [], Tab, () => [[], [], []]);
}

export class State extends Record {
    constructor(Id, IsNewUser, Username, UsernameExists, IsActive, IsEditedUserAdmin, IsSignedInUserAdmin, IsSignedInUserOfficeAdmin, IsSignedInUserPayrollAdmin, FirstName, MiddleName, LastName, Mobile, UserDto, MeasurementSystem, HourlyRate, ChargeOutRate, Rating, UserSkills, UserSkillsToDelete, OrganisationPayrollSystemIndex, OvertimeAfterWorkHours, SmartlyUserCode, Skills, UserRole, Dob, DriverLicenseCountry, DriverLicenseNumber, ResidencyStatus, EnglishProficiency, AccommodationType, IsConfirmingResetPassword, EmploymentType, WeeklyFulltimeWorkHours, ActiveTab, IsBusy, Errors) {
        super();
        this.Id = Id;
        this.IsNewUser = IsNewUser;
        this.Username = Username;
        this.UsernameExists = UsernameExists;
        this.IsActive = IsActive;
        this.IsEditedUserAdmin = IsEditedUserAdmin;
        this.IsSignedInUserAdmin = IsSignedInUserAdmin;
        this.IsSignedInUserOfficeAdmin = IsSignedInUserOfficeAdmin;
        this.IsSignedInUserPayrollAdmin = IsSignedInUserPayrollAdmin;
        this.FirstName = FirstName;
        this.MiddleName = MiddleName;
        this.LastName = LastName;
        this.Mobile = Mobile;
        this.UserDto = UserDto;
        this.MeasurementSystem = MeasurementSystem;
        this.HourlyRate = HourlyRate;
        this.ChargeOutRate = ChargeOutRate;
        this.Rating = (Rating | 0);
        this.UserSkills = UserSkills;
        this.UserSkillsToDelete = UserSkillsToDelete;
        this.OrganisationPayrollSystemIndex = (OrganisationPayrollSystemIndex | 0);
        this.OvertimeAfterWorkHours = OvertimeAfterWorkHours;
        this.SmartlyUserCode = SmartlyUserCode;
        this.Skills = Skills;
        this.UserRole = UserRole;
        this.Dob = Dob;
        this.DriverLicenseCountry = DriverLicenseCountry;
        this.DriverLicenseNumber = DriverLicenseNumber;
        this.ResidencyStatus = ResidencyStatus;
        this.EnglishProficiency = EnglishProficiency;
        this.AccommodationType = AccommodationType;
        this.IsConfirmingResetPassword = IsConfirmingResetPassword;
        this.EmploymentType = EmploymentType;
        this.WeeklyFulltimeWorkHours = WeeklyFulltimeWorkHours;
        this.ActiveTab = ActiveTab;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.UserEdit.State", [], State, () => [["Id", class_type("System.Guid")], ["IsNewUser", bool_type], ["Username", string_type], ["UsernameExists", option_type(bool_type)], ["IsActive", bool_type], ["IsEditedUserAdmin", bool_type], ["IsSignedInUserAdmin", bool_type], ["IsSignedInUserOfficeAdmin", bool_type], ["IsSignedInUserPayrollAdmin", bool_type], ["FirstName", string_type], ["MiddleName", string_type], ["LastName", string_type], ["Mobile", string_type], ["UserDto", option_type(UserDto_$reflection())], ["MeasurementSystem", string_type], ["HourlyRate", string_type], ["ChargeOutRate", string_type], ["Rating", int32_type], ["UserSkills", list_type(UserSkillVM_$reflection())], ["UserSkillsToDelete", list_type(UserSkillVM_$reflection())], ["OrganisationPayrollSystemIndex", int32_type], ["OvertimeAfterWorkHours", string_type], ["SmartlyUserCode", string_type], ["Skills", list_type(Skill_$reflection())], ["UserRole", UserRole_$reflection()], ["Dob", option_type(class_type("System.DateTime"))], ["DriverLicenseCountry", string_type], ["DriverLicenseNumber", string_type], ["ResidencyStatus", string_type], ["EnglishProficiency", string_type], ["AccommodationType", string_type], ["IsConfirmingResetPassword", bool_type], ["EmploymentType", string_type], ["WeeklyFulltimeWorkHours", string_type], ["ActiveTab", Tab_$reflection()], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetIsActive", "SetUsername", "SetFirstName", "SetMiddleName", "SetLastName", "SetMobile", "SetMeasurementSystem", "SetHourlyRate", "SetChargeOutRate", "SetOvertimeAfterWorkHours", "SetUserRole", "SetDob", "SetDriverLicenseCountry", "SetDriverLicenseNumber", "SetResidencyStatus", "SetEnglishProficiency", "SetAccommodationType", "GetDataResponse", "SaveUser", "SaveUserResponse", "CheckUsernameExists", "CheckUsernameExistsResponse", "AddUserSkill", "UserSkillModelChanged", "SetSmartlyUserCode", "ConfirmResetPassword", "ConfirmResetPasswordResult", "ResetPassword", "ResetPasswordResponse", "SetEmploymentType", "SetFulltimeWorkHours", "SetTab", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.UserEdit.Msg", [], Msg, () => [[["Item", bool_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", UserRole_$reflection()]], [["Item", DateRange_$reflection()]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(option_type(UserDto_$reflection()), list_type(Skill_$reflection()), option_type(OrganisationDto_$reflection())), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", tuple_type(option_type(UserDto_$reflection()), list_type(Skill_$reflection()), option_type(OrganisationDto_$reflection()))]], [["ErrorValue", list_type(string_type)]]])]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [class_type("System.Guid"), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", class_type("System.Guid")]], [["ErrorValue", list_type(string_type)]]])]], [["Item", string_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [bool_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", bool_type]], [["ErrorValue", list_type(string_type)]]])]], [], [["Item1", int32_type], ["Item2", UserSkillVM_$reflection()]], [["Item", string_type]], [], [["Item", bool_type]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])]], [["Item", string_type]], [["Item", string_type]], [["Item", Tab_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

export function getDataCmd(userId) {
    return cmdOfAsync((Item) => (new Msg(17, [Item])), (Item_1) => (new Msg(32, [Item_1])), () => singleton.Delay(() => {
        const isNewUser = isNullOrEmpty(userId);
        const selectedOrgId = AppRootModule_getSelectedOrgId();
        const getUserJob = singleton.Delay(() => {
            if (isNewUser) {
                return singleton.Return(new FSharpResult$2(0, [undefined]));
            }
            else {
                let asyncResult_1;
                const value_1_2 = Guid_parse(userId);
                asyncResult_1 = singleton.Return(value_1_2);
                return singleton.Bind(asyncResult_1, (input_1) => {
                    const input_2 = input_1;
                    if (input_2.tag === 1) {
                        return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                    }
                    else {
                        return getUser(appRoot.UserService, selectedOrgId, input_2.fields[0]);
                    }
                });
            }
        });
        let skillsJob;
        const input_4 = getAllSkills(appRoot.SkillService, selectedOrgId);
        skillsJob = singleton.Bind(input_4, (x$0027) => {
            let value_5;
            const input_6 = x$0027;
            value_5 = ((input_6.tag === 1) ? (new FSharpResult$2(1, [input_6.fields[0]])) : (new FSharpResult$2(0, [map(SkillDtoModule_toSkill, input_6.fields[0])])));
            return singleton.Return(value_5);
        });
        const organisationJob = appRoot.OrganisationService.GetOrganisation(selectedOrgId);
        let x_2;
        let t2_1;
        const input_11 = singleton.Bind(organisationJob, (l) => singleton.Bind(getUserJob, (r) => singleton.Return([l, r])));
        t2_1 = singleton.Bind(input_11, (x$0027_1) => {
            let value_8;
            const tupledArg = x$0027_1;
            value_8 = zip(tupledArg[0], tupledArg[1]);
            return singleton.Return(value_8);
        });
        const input_16 = singleton.Bind(skillsJob, (l_1) => singleton.Bind(t2_1, (r_1) => singleton.Return([l_1, r_1])));
        x_2 = singleton.Bind(input_16, (x$0027_2) => {
            let value_11;
            const tupledArg_1 = x$0027_2;
            value_11 = zip(tupledArg_1[0], tupledArg_1[1]);
            return singleton.Return(value_11);
        });
        return singleton.Bind(x_2, (x$0027_3) => {
            let _arg_2;
            let value_13;
            const input_20 = x$0027_3;
            value_13 = ((input_20.tag === 1) ? (new FSharpResult$2(1, [input_20.fields[0]])) : (new FSharpResult$2(0, [(_arg_2 = input_20.fields[0], [_arg_2[1][1], _arg_2[0], _arg_2[1][0]])])));
            return singleton.Return(value_13);
        });
    }));
}

export function saveUserCmd(state) {
    return cmdOfAsync((Item) => (new Msg(19, [Item])), (Item_1) => (new Msg(32, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_3;
        const value_1 = create(state.Username);
        asyncResult_3 = singleton.Return(value_1);
        return singleton.Bind(asyncResult_3, (input_1_6) => {
            const input_20 = input_1_6;
            if (input_20.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_20.fields[0]]));
            }
            else {
                let asyncResult_2;
                const value_1_1 = MeasurementSystemModule_ofString(state.MeasurementSystem);
                asyncResult_2 = singleton.Return(value_1_1);
                return singleton.Bind(asyncResult_2, (input_1_5) => {
                    const input_17 = input_1_5;
                    if (input_17.tag === 1) {
                        return singleton.Return(new FSharpResult$2(1, [input_17.fields[0]]));
                    }
                    else {
                        let asyncResult_1;
                        let value_1_2;
                        if (state.IsNewUser) {
                            value_1_2 = (new FSharpResult$2(0, [new UserDto_1("00000000-0000-0000-0000-000000000000", input_20.fields[0], true, false, "", "", "", "", new MeasurementSystem_1(0, []), fromParts(0, 0, 0, false, 0), fromParts(0, 0, 0, false, 0), 0, undefined, empty(), new UserRole_1(3, []), undefined, undefined, undefined, 0, 0, 0, fromParts(0, 0, 0, false, 0), new EmploymentType_1(3, []), undefined)]));
                        }
                        else {
                            const option_1 = state.UserDto;
                            value_1_2 = ((option_1 == null) ? (new FSharpResult$2(1, [singleton_1("User data not found.")])) : (new FSharpResult$2(0, [option_1])));
                        }
                        asyncResult_1 = singleton.Return(value_1_2);
                        return singleton.Bind(asyncResult_1, (input_1_4) => {
                            const input_14 = input_1_4;
                            if (input_14.tag === 1) {
                                return singleton.Return(new FSharpResult$2(1, [input_14.fields[0]]));
                            }
                            else {
                                const userDto = input_14.fields[0];
                                let asyncResult;
                                let value_1_3;
                                if (state.EmploymentType === EmploymentTypeModule_toString(new EmploymentType_1(0, []))) {
                                    let patternInput;
                                    let outArg = 0;
                                    patternInput = [tryParse(state.WeeklyFulltimeWorkHours, 511, false, 32, new FSharpRef(() => outArg, (v) => {
                                        outArg = (v | 0);
                                    })), outArg];
                                    const parsedDuration = patternInput[1] | 0;
                                    if (patternInput[0]) {
                                        value_1_3 = ((parsedDuration < 0) ? (new FSharpResult$2(1, [singleton_1("Fulltime work hours must be greater than or equal to 0.")])) : (new FSharpResult$2(0, [fromHours(parsedDuration)])));
                                    }
                                    else {
                                        const input_1 = TimeSpan_parseDuration(state.WeeklyFulltimeWorkHours);
                                        value_1_3 = ((input_1.tag === 1) ? (new FSharpResult$2(1, [input_1.fields[0]])) : (new FSharpResult$2(0, [input_1.fields[0]])));
                                    }
                                }
                                else {
                                    value_1_3 = (new FSharpResult$2(0, [undefined]));
                                }
                                asyncResult = singleton.Return(value_1_3);
                                return singleton.Bind(asyncResult, (input_1_3) => {
                                    const input_11 = input_1_3;
                                    if (input_11.tag === 1) {
                                        return singleton.Return(new FSharpResult$2(1, [input_11.fields[0]]));
                                    }
                                    else {
                                        const updatedUserDto = new UserDto_1(userDto.Id, userDto.Username, state.IsActive, userDto.IsAdmin, state.FirstName, state.MiddleName, state.LastName, state.Mobile, input_17.fields[0], parse(state.HourlyRate), parse(state.ChargeOutRate), state.Rating, (state.OrganisationPayrollSystemIndex === 1) ? (new PayrollSystemInfo_1(1, [{
                                            PayRateCode: "1",
                                            UserCode: state.SmartlyUserCode,
                                        }])) : undefined, map((x_11) => (new UserSkillDto(defaultArg(x_11.Id, "00000000-0000-0000-0000-000000000000"), defaultArg(x_11.SkillId, "00000000-0000-0000-0000-000000000000"), x_11.ExperienceMonths, x_11.Country, x_11.Rating)), filter((x_10) => !x_10.IsDeleted, state.UserSkills)), state.UserRole, state.Dob, Option_ofString(state.DriverLicenseCountry), Option_ofString(state.DriverLicenseNumber), ResidencyStatusModule_ofString(state.ResidencyStatus), EnglishProficiencyModule_ofString(state.EnglishProficiency), AccommodationTypeModule_ofString(state.AccommodationType), new Decimal(state.OvertimeAfterWorkHours), EmploymentTypeModule_ofString(state.EmploymentType), input_11.fields[0]);
                                        if (state.IsNewUser) {
                                            const x_12 = addUserToOrganisation(appRoot.UserService, AppRootModule_getSelectedOrgId(), updatedUserDto);
                                            return singleton.Bind(x_12, (x$0027) => {
                                                let value_14;
                                                const input_3 = x$0027;
                                                value_14 = ((input_3.tag === 1) ? (new FSharpResult$2(1, [input_3.fields[0]])) : (new FSharpResult$2(0, [input_3.fields[0]])));
                                                return singleton.Return(value_14);
                                            });
                                        }
                                        else {
                                            const userSkillsToDelete = map((arg_1) => UserSkillDtoModule_ofUserSkill(UserSkillVMModule_toUserSkill(arg_1)), state.UserSkillsToDelete);
                                            const x_14 = updateUser(appRoot.UserService, AppRootModule_getSelectedOrgId(), updatedUserDto, userSkillsToDelete);
                                            return singleton.Bind(x_14, (x$0027_1) => {
                                                let value_16;
                                                const input_7 = x$0027_1;
                                                value_16 = ((input_7.tag === 1) ? (new FSharpResult$2(1, [input_7.fields[0]])) : (new FSharpResult$2(0, [state.Id])));
                                                return singleton.Return(value_16);
                                            });
                                        }
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });
    }));
}

export function checkUsernameExistsCmd(state) {
    return cmdOfAsync((Item) => (new Msg(21, [Item])), (Item_1) => (new Msg(32, [Item_1])), () => singleton.Delay(() => {
        const matchValue = state.UsernameExists;
        let matchResult;
        if (matchValue != null) {
            if (matchValue) {
                matchResult = 0;
            }
            else {
                matchResult = 1;
            }
        }
        else {
            matchResult = 1;
        }
        switch (matchResult) {
            case 0:
                return singleton.Return(new FSharpResult$2(0, [true]));
            default: {
                let asyncResult_1;
                const value_1_2 = create(state.Username);
                asyncResult_1 = singleton.Return(value_1_2);
                return singleton.Bind(asyncResult_1, (input_1) => {
                    const input_2 = input_1;
                    if (input_2.tag === 1) {
                        return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                    }
                    else {
                        return checkUserNameExists(appRoot.UserService, input_2.fields[0]);
                    }
                });
            }
        }
    }));
}

export function resetPasswordCmd(state) {
    return cmdOfAsync((Item) => (new Msg(28, [Item])), (Item_1) => (new Msg(32, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_1;
        const value_1 = create(state.Username);
        asyncResult_1 = singleton.Return(value_1);
        return singleton.Bind(asyncResult_1, (input_1) => {
            const input_2 = input_1;
            if (input_2.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
            }
            else {
                return resetPassword(appRoot.UserService, AppRootModule_getSelectedOrgId(), input_2.fields[0]);
            }
        });
    }));
}

export function init(userId) {
    const isNewUser = isNullOrEmpty(userId);
    const signedInUserRole = toString(AppRoot__get_SignedInUserRole(appRoot));
    return [new State("00000000-0000-0000-0000-000000000000", isNewUser, "", undefined, isNewUser, false, signedInUserRole === toString(new UserRole_1(0, [])), signedInUserRole === toString(new UserRole_1(1, [])), signedInUserRole === toString(new UserRole_1(2, [])), "", "", "", "", undefined, MeasurementSystemModule_toString(new MeasurementSystem_1(0, [])), "1", "1", 0, empty(), empty(), 0, "0", "", empty(), new UserRole_1(3, []), undefined, "", "", "", "", "", false, EmploymentTypeModule_toString(new EmploymentType_1(3, [])), "", new Tab(0, []), isNewUser ? (new Busy(0, [])) : (new Busy(1, [])), empty()), getDataCmd(userId)];
}

export function update(msg, state) {
    let input_1, copyOfStruct, input_3, UserSkillsToDelete_1;
    switch (msg.tag) {
        case 1:
            return [new State(state.Id, state.IsNewUser, msg.fields[0], undefined, state.IsActive, state.IsEditedUserAdmin, state.IsSignedInUserAdmin, state.IsSignedInUserOfficeAdmin, state.IsSignedInUserPayrollAdmin, state.FirstName, state.MiddleName, state.LastName, state.Mobile, state.UserDto, state.MeasurementSystem, state.HourlyRate, state.ChargeOutRate, state.Rating, state.UserSkills, state.UserSkillsToDelete, state.OrganisationPayrollSystemIndex, state.OvertimeAfterWorkHours, state.SmartlyUserCode, state.Skills, state.UserRole, state.Dob, state.DriverLicenseCountry, state.DriverLicenseNumber, state.ResidencyStatus, state.EnglishProficiency, state.AccommodationType, state.IsConfirmingResetPassword, state.EmploymentType, state.WeeklyFulltimeWorkHours, state.ActiveTab, state.IsBusy, state.Errors), Cmd_none()];
        case 2:
            return [new State(state.Id, state.IsNewUser, state.Username, state.UsernameExists, state.IsActive, state.IsEditedUserAdmin, state.IsSignedInUserAdmin, state.IsSignedInUserOfficeAdmin, state.IsSignedInUserPayrollAdmin, msg.fields[0], state.MiddleName, state.LastName, state.Mobile, state.UserDto, state.MeasurementSystem, state.HourlyRate, state.ChargeOutRate, state.Rating, state.UserSkills, state.UserSkillsToDelete, state.OrganisationPayrollSystemIndex, state.OvertimeAfterWorkHours, state.SmartlyUserCode, state.Skills, state.UserRole, state.Dob, state.DriverLicenseCountry, state.DriverLicenseNumber, state.ResidencyStatus, state.EnglishProficiency, state.AccommodationType, state.IsConfirmingResetPassword, state.EmploymentType, state.WeeklyFulltimeWorkHours, state.ActiveTab, state.IsBusy, state.Errors), Cmd_none()];
        case 3:
            return [new State(state.Id, state.IsNewUser, state.Username, state.UsernameExists, state.IsActive, state.IsEditedUserAdmin, state.IsSignedInUserAdmin, state.IsSignedInUserOfficeAdmin, state.IsSignedInUserPayrollAdmin, state.FirstName, msg.fields[0], state.LastName, state.Mobile, state.UserDto, state.MeasurementSystem, state.HourlyRate, state.ChargeOutRate, state.Rating, state.UserSkills, state.UserSkillsToDelete, state.OrganisationPayrollSystemIndex, state.OvertimeAfterWorkHours, state.SmartlyUserCode, state.Skills, state.UserRole, state.Dob, state.DriverLicenseCountry, state.DriverLicenseNumber, state.ResidencyStatus, state.EnglishProficiency, state.AccommodationType, state.IsConfirmingResetPassword, state.EmploymentType, state.WeeklyFulltimeWorkHours, state.ActiveTab, state.IsBusy, state.Errors), Cmd_none()];
        case 4:
            return [new State(state.Id, state.IsNewUser, state.Username, state.UsernameExists, state.IsActive, state.IsEditedUserAdmin, state.IsSignedInUserAdmin, state.IsSignedInUserOfficeAdmin, state.IsSignedInUserPayrollAdmin, state.FirstName, state.MiddleName, msg.fields[0], state.Mobile, state.UserDto, state.MeasurementSystem, state.HourlyRate, state.ChargeOutRate, state.Rating, state.UserSkills, state.UserSkillsToDelete, state.OrganisationPayrollSystemIndex, state.OvertimeAfterWorkHours, state.SmartlyUserCode, state.Skills, state.UserRole, state.Dob, state.DriverLicenseCountry, state.DriverLicenseNumber, state.ResidencyStatus, state.EnglishProficiency, state.AccommodationType, state.IsConfirmingResetPassword, state.EmploymentType, state.WeeklyFulltimeWorkHours, state.ActiveTab, state.IsBusy, state.Errors), Cmd_none()];
        case 5:
            return [new State(state.Id, state.IsNewUser, state.Username, state.UsernameExists, state.IsActive, state.IsEditedUserAdmin, state.IsSignedInUserAdmin, state.IsSignedInUserOfficeAdmin, state.IsSignedInUserPayrollAdmin, state.FirstName, state.MiddleName, state.LastName, msg.fields[0], state.UserDto, state.MeasurementSystem, state.HourlyRate, state.ChargeOutRate, state.Rating, state.UserSkills, state.UserSkillsToDelete, state.OrganisationPayrollSystemIndex, state.OvertimeAfterWorkHours, state.SmartlyUserCode, state.Skills, state.UserRole, state.Dob, state.DriverLicenseCountry, state.DriverLicenseNumber, state.ResidencyStatus, state.EnglishProficiency, state.AccommodationType, state.IsConfirmingResetPassword, state.EmploymentType, state.WeeklyFulltimeWorkHours, state.ActiveTab, state.IsBusy, state.Errors), Cmd_none()];
        case 6:
            return [new State(state.Id, state.IsNewUser, state.Username, state.UsernameExists, state.IsActive, state.IsEditedUserAdmin, state.IsSignedInUserAdmin, state.IsSignedInUserOfficeAdmin, state.IsSignedInUserPayrollAdmin, state.FirstName, state.MiddleName, state.LastName, state.Mobile, state.UserDto, msg.fields[0], state.HourlyRate, state.ChargeOutRate, state.Rating, state.UserSkills, state.UserSkillsToDelete, state.OrganisationPayrollSystemIndex, state.OvertimeAfterWorkHours, state.SmartlyUserCode, state.Skills, state.UserRole, state.Dob, state.DriverLicenseCountry, state.DriverLicenseNumber, state.ResidencyStatus, state.EnglishProficiency, state.AccommodationType, state.IsConfirmingResetPassword, state.EmploymentType, state.WeeklyFulltimeWorkHours, state.ActiveTab, state.IsBusy, state.Errors), Cmd_none()];
        case 7:
            return [new State(state.Id, state.IsNewUser, state.Username, state.UsernameExists, state.IsActive, state.IsEditedUserAdmin, state.IsSignedInUserAdmin, state.IsSignedInUserOfficeAdmin, state.IsSignedInUserPayrollAdmin, state.FirstName, state.MiddleName, state.LastName, state.Mobile, state.UserDto, state.MeasurementSystem, msg.fields[0], state.ChargeOutRate, state.Rating, state.UserSkills, state.UserSkillsToDelete, state.OrganisationPayrollSystemIndex, state.OvertimeAfterWorkHours, state.SmartlyUserCode, state.Skills, state.UserRole, state.Dob, state.DriverLicenseCountry, state.DriverLicenseNumber, state.ResidencyStatus, state.EnglishProficiency, state.AccommodationType, state.IsConfirmingResetPassword, state.EmploymentType, state.WeeklyFulltimeWorkHours, state.ActiveTab, state.IsBusy, state.Errors), Cmd_none()];
        case 8:
            return [new State(state.Id, state.IsNewUser, state.Username, state.UsernameExists, state.IsActive, state.IsEditedUserAdmin, state.IsSignedInUserAdmin, state.IsSignedInUserOfficeAdmin, state.IsSignedInUserPayrollAdmin, state.FirstName, state.MiddleName, state.LastName, state.Mobile, state.UserDto, state.MeasurementSystem, state.HourlyRate, msg.fields[0], state.Rating, state.UserSkills, state.UserSkillsToDelete, state.OrganisationPayrollSystemIndex, state.OvertimeAfterWorkHours, state.SmartlyUserCode, state.Skills, state.UserRole, state.Dob, state.DriverLicenseCountry, state.DriverLicenseNumber, state.ResidencyStatus, state.EnglishProficiency, state.AccommodationType, state.IsConfirmingResetPassword, state.EmploymentType, state.WeeklyFulltimeWorkHours, state.ActiveTab, state.IsBusy, state.Errors), Cmd_none()];
        case 9:
            return [new State(state.Id, state.IsNewUser, state.Username, state.UsernameExists, state.IsActive, state.IsEditedUserAdmin, state.IsSignedInUserAdmin, state.IsSignedInUserOfficeAdmin, state.IsSignedInUserPayrollAdmin, state.FirstName, state.MiddleName, state.LastName, state.Mobile, state.UserDto, state.MeasurementSystem, state.HourlyRate, state.ChargeOutRate, state.Rating, state.UserSkills, state.UserSkillsToDelete, state.OrganisationPayrollSystemIndex, msg.fields[0], state.SmartlyUserCode, state.Skills, state.UserRole, state.Dob, state.DriverLicenseCountry, state.DriverLicenseNumber, state.ResidencyStatus, state.EnglishProficiency, state.AccommodationType, state.IsConfirmingResetPassword, state.EmploymentType, state.WeeklyFulltimeWorkHours, state.ActiveTab, state.IsBusy, state.Errors), Cmd_none()];
        case 10:
            return [new State(state.Id, state.IsNewUser, state.Username, state.UsernameExists, state.IsActive, state.IsEditedUserAdmin, state.IsSignedInUserAdmin, state.IsSignedInUserOfficeAdmin, state.IsSignedInUserPayrollAdmin, state.FirstName, state.MiddleName, state.LastName, state.Mobile, state.UserDto, state.MeasurementSystem, state.HourlyRate, state.ChargeOutRate, state.Rating, state.UserSkills, state.UserSkillsToDelete, state.OrganisationPayrollSystemIndex, state.OvertimeAfterWorkHours, state.SmartlyUserCode, state.Skills, msg.fields[0], state.Dob, state.DriverLicenseCountry, state.DriverLicenseNumber, state.ResidencyStatus, state.EnglishProficiency, state.AccommodationType, state.IsConfirmingResetPassword, state.EmploymentType, state.WeeklyFulltimeWorkHours, state.ActiveTab, state.IsBusy, state.Errors), Cmd_none()];
        case 11:
            return [new State(state.Id, state.IsNewUser, state.Username, state.UsernameExists, state.IsActive, state.IsEditedUserAdmin, state.IsSignedInUserAdmin, state.IsSignedInUserOfficeAdmin, state.IsSignedInUserPayrollAdmin, state.FirstName, state.MiddleName, state.LastName, state.Mobile, state.UserDto, state.MeasurementSystem, state.HourlyRate, state.ChargeOutRate, state.Rating, state.UserSkills, state.UserSkillsToDelete, state.OrganisationPayrollSystemIndex, state.OvertimeAfterWorkHours, state.SmartlyUserCode, state.Skills, state.UserRole, msg.fields[0].startDate, state.DriverLicenseCountry, state.DriverLicenseNumber, state.ResidencyStatus, state.EnglishProficiency, state.AccommodationType, state.IsConfirmingResetPassword, state.EmploymentType, state.WeeklyFulltimeWorkHours, state.ActiveTab, state.IsBusy, state.Errors), Cmd_none()];
        case 12:
            return [new State(state.Id, state.IsNewUser, state.Username, state.UsernameExists, state.IsActive, state.IsEditedUserAdmin, state.IsSignedInUserAdmin, state.IsSignedInUserOfficeAdmin, state.IsSignedInUserPayrollAdmin, state.FirstName, state.MiddleName, state.LastName, state.Mobile, state.UserDto, state.MeasurementSystem, state.HourlyRate, state.ChargeOutRate, state.Rating, state.UserSkills, state.UserSkillsToDelete, state.OrganisationPayrollSystemIndex, state.OvertimeAfterWorkHours, state.SmartlyUserCode, state.Skills, state.UserRole, state.Dob, msg.fields[0], state.DriverLicenseNumber, state.ResidencyStatus, state.EnglishProficiency, state.AccommodationType, state.IsConfirmingResetPassword, state.EmploymentType, state.WeeklyFulltimeWorkHours, state.ActiveTab, state.IsBusy, state.Errors), Cmd_none()];
        case 13:
            return [new State(state.Id, state.IsNewUser, state.Username, state.UsernameExists, state.IsActive, state.IsEditedUserAdmin, state.IsSignedInUserAdmin, state.IsSignedInUserOfficeAdmin, state.IsSignedInUserPayrollAdmin, state.FirstName, state.MiddleName, state.LastName, state.Mobile, state.UserDto, state.MeasurementSystem, state.HourlyRate, state.ChargeOutRate, state.Rating, state.UserSkills, state.UserSkillsToDelete, state.OrganisationPayrollSystemIndex, state.OvertimeAfterWorkHours, state.SmartlyUserCode, state.Skills, state.UserRole, state.Dob, state.DriverLicenseCountry, msg.fields[0], state.ResidencyStatus, state.EnglishProficiency, state.AccommodationType, state.IsConfirmingResetPassword, state.EmploymentType, state.WeeklyFulltimeWorkHours, state.ActiveTab, state.IsBusy, state.Errors), Cmd_none()];
        case 14:
            return [new State(state.Id, state.IsNewUser, state.Username, state.UsernameExists, state.IsActive, state.IsEditedUserAdmin, state.IsSignedInUserAdmin, state.IsSignedInUserOfficeAdmin, state.IsSignedInUserPayrollAdmin, state.FirstName, state.MiddleName, state.LastName, state.Mobile, state.UserDto, state.MeasurementSystem, state.HourlyRate, state.ChargeOutRate, state.Rating, state.UserSkills, state.UserSkillsToDelete, state.OrganisationPayrollSystemIndex, state.OvertimeAfterWorkHours, state.SmartlyUserCode, state.Skills, state.UserRole, state.Dob, state.DriverLicenseCountry, state.DriverLicenseNumber, msg.fields[0], state.EnglishProficiency, state.AccommodationType, state.IsConfirmingResetPassword, state.EmploymentType, state.WeeklyFulltimeWorkHours, state.ActiveTab, state.IsBusy, state.Errors), Cmd_none()];
        case 15:
            return [new State(state.Id, state.IsNewUser, state.Username, state.UsernameExists, state.IsActive, state.IsEditedUserAdmin, state.IsSignedInUserAdmin, state.IsSignedInUserOfficeAdmin, state.IsSignedInUserPayrollAdmin, state.FirstName, state.MiddleName, state.LastName, state.Mobile, state.UserDto, state.MeasurementSystem, state.HourlyRate, state.ChargeOutRate, state.Rating, state.UserSkills, state.UserSkillsToDelete, state.OrganisationPayrollSystemIndex, state.OvertimeAfterWorkHours, state.SmartlyUserCode, state.Skills, state.UserRole, state.Dob, state.DriverLicenseCountry, state.DriverLicenseNumber, state.ResidencyStatus, msg.fields[0], state.AccommodationType, state.IsConfirmingResetPassword, state.EmploymentType, state.WeeklyFulltimeWorkHours, state.ActiveTab, state.IsBusy, state.Errors), Cmd_none()];
        case 16:
            return [new State(state.Id, state.IsNewUser, state.Username, state.UsernameExists, state.IsActive, state.IsEditedUserAdmin, state.IsSignedInUserAdmin, state.IsSignedInUserOfficeAdmin, state.IsSignedInUserPayrollAdmin, state.FirstName, state.MiddleName, state.LastName, state.Mobile, state.UserDto, state.MeasurementSystem, state.HourlyRate, state.ChargeOutRate, state.Rating, state.UserSkills, state.UserSkillsToDelete, state.OrganisationPayrollSystemIndex, state.OvertimeAfterWorkHours, state.SmartlyUserCode, state.Skills, state.UserRole, state.Dob, state.DriverLicenseCountry, state.DriverLicenseNumber, state.ResidencyStatus, state.EnglishProficiency, msg.fields[0], state.IsConfirmingResetPassword, state.EmploymentType, state.WeeklyFulltimeWorkHours, state.ActiveTab, state.IsBusy, state.Errors), Cmd_none()];
        case 17:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Id, state.IsNewUser, state.Username, state.UsernameExists, state.IsActive, state.IsEditedUserAdmin, state.IsSignedInUserAdmin, state.IsSignedInUserOfficeAdmin, state.IsSignedInUserPayrollAdmin, state.FirstName, state.MiddleName, state.LastName, state.Mobile, state.UserDto, state.MeasurementSystem, state.HourlyRate, state.ChargeOutRate, state.Rating, state.UserSkills, state.UserSkillsToDelete, state.OrganisationPayrollSystemIndex, state.OvertimeAfterWorkHours, state.SmartlyUserCode, state.Skills, state.UserRole, state.Dob, state.DriverLicenseCountry, state.DriverLicenseNumber, state.ResidencyStatus, state.EnglishProficiency, state.AccommodationType, state.IsConfirmingResetPassword, state.EmploymentType, state.WeeklyFulltimeWorkHours, state.ActiveTab, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                let state_2;
                if (state.IsNewUser) {
                    state_2 = state;
                }
                else if (msg.fields[0].fields[0][0] == null) {
                    state_2 = (new State(state.Id, state.IsNewUser, state.Username, state.UsernameExists, state.IsActive, state.IsEditedUserAdmin, state.IsSignedInUserAdmin, state.IsSignedInUserOfficeAdmin, state.IsSignedInUserPayrollAdmin, state.FirstName, state.MiddleName, state.LastName, state.Mobile, state.UserDto, state.MeasurementSystem, state.HourlyRate, state.ChargeOutRate, state.Rating, state.UserSkills, state.UserSkillsToDelete, state.OrganisationPayrollSystemIndex, state.OvertimeAfterWorkHours, state.SmartlyUserCode, state.Skills, state.UserRole, state.Dob, state.DriverLicenseCountry, state.DriverLicenseNumber, state.ResidencyStatus, state.EnglishProficiency, state.AccommodationType, state.IsConfirmingResetPassword, state.EmploymentType, state.WeeklyFulltimeWorkHours, state.ActiveTab, state.IsBusy, singleton_1("User not found.")));
                }
                else {
                    const x_17 = msg.fields[0].fields[0][0];
                    let smartlyUserCode;
                    const matchValue = x_17.PayrollSystemInfo;
                    let matchResult, x_18;
                    if (matchValue != null) {
                        if (matchValue.tag === 1) {
                            matchResult = 0;
                            x_18 = matchValue.fields[0];
                        }
                        else {
                            matchResult = 1;
                        }
                    }
                    else {
                        matchResult = 1;
                    }
                    switch (matchResult) {
                        case 0: {
                            smartlyUserCode = x_18.UserCode;
                            break;
                        }
                        default:
                            smartlyUserCode = "";
                    }
                    const Username = value_505(x_17.Username);
                    const MeasurementSystem = MeasurementSystemModule_toString(x_17.MeasurementSystem);
                    const HourlyRate = toString_1(x_17.HourlyRate);
                    const ChargeOutRate = toString_1(x_17.ChargeOutRate);
                    const UserSkills = map((arg) => UserSkillVMModule_ofUserSkill(UserSkillDtoModule_toUserSkill(arg)), sortBy((x_19) => [x_19.SkillId, x_19.Country, x_19.Rating], x_17.UserSkills, {
                        Compare: compareArrays,
                    }));
                    const DriverLicenseCountry = defaultArg(x_17.DriverLicenseCountry, "");
                    const DriverLicenseNumber = defaultArg(x_17.DriverLicenseNumber, "");
                    const ResidencyStatus = ResidencyStatusModule_toString(x_17.ResidencyStatus);
                    const EnglishProficiency = EnglishProficiencyModule_toString(x_17.EnglishProficiency);
                    const AccommodationType = AccommodationTypeModule_toString(x_17.AccommodationType);
                    state_2 = (new State(x_17.Id, false, Username, state.UsernameExists, x_17.IsActive, x_17.IsAdmin, state.IsSignedInUserAdmin, state.IsSignedInUserOfficeAdmin, state.IsSignedInUserPayrollAdmin, x_17.FirstName, x_17.MiddleName, x_17.LastName, x_17.Mobile, x_17, MeasurementSystem, HourlyRate, ChargeOutRate, x_17.Rating, UserSkills, empty(), state.OrganisationPayrollSystemIndex, toString_1(x_17.OvertimeAfterWorkHours), smartlyUserCode, state.Skills, x_17.UserRole, x_17.Dob, DriverLicenseCountry, DriverLicenseNumber, ResidencyStatus, EnglishProficiency, AccommodationType, state.IsConfirmingResetPassword, EmploymentTypeModule_toString(x_17.EmploymentType), defaultArg((input_1 = x_17.WeeklyFulltimeWorkHours, (input_1 == null) ? undefined : ((copyOfStruct = totalHours(input_1), copyOfStruct.toString()))), ""), state.ActiveTab, new Busy(0, []), state.Errors));
                }
                const state_3 = new State(state_2.Id, state_2.IsNewUser, state_2.Username, state_2.UsernameExists, state_2.IsActive, state_2.IsEditedUserAdmin, state_2.IsSignedInUserAdmin, state_2.IsSignedInUserOfficeAdmin, state_2.IsSignedInUserPayrollAdmin, state_2.FirstName, state_2.MiddleName, state_2.LastName, state_2.Mobile, state_2.UserDto, state_2.MeasurementSystem, state_2.HourlyRate, state_2.ChargeOutRate, state_2.Rating, state_2.UserSkills, state_2.UserSkillsToDelete, defaultArg((input_3 = msg.fields[0].fields[0][2], (input_3 == null) ? undefined : input_3.PayrollSystemIndex), 0), state_2.OvertimeAfterWorkHours, state_2.SmartlyUserCode, state_2.Skills, state_2.UserRole, state_2.Dob, state_2.DriverLicenseCountry, state_2.DriverLicenseNumber, state_2.ResidencyStatus, state_2.EnglishProficiency, state_2.AccommodationType, state_2.IsConfirmingResetPassword, state_2.EmploymentType, state_2.WeeklyFulltimeWorkHours, state_2.ActiveTab, state_2.IsBusy, state_2.Errors);
                return [new State(state_3.Id, state_3.IsNewUser, state_3.Username, state_3.UsernameExists, state_3.IsActive, state_3.IsEditedUserAdmin, state_3.IsSignedInUserAdmin, state_3.IsSignedInUserOfficeAdmin, state_3.IsSignedInUserPayrollAdmin, state_3.FirstName, state_3.MiddleName, state_3.LastName, state_3.Mobile, state_3.UserDto, state_3.MeasurementSystem, state_3.HourlyRate, state_3.ChargeOutRate, state_3.Rating, state_3.UserSkills, state_3.UserSkillsToDelete, state_3.OrganisationPayrollSystemIndex, state_3.OvertimeAfterWorkHours, state_3.SmartlyUserCode, msg.fields[0].fields[0][1], state_3.UserRole, state_3.Dob, state_3.DriverLicenseCountry, state_3.DriverLicenseNumber, state_3.ResidencyStatus, state_3.EnglishProficiency, state_3.AccommodationType, state_3.IsConfirmingResetPassword, state_3.EmploymentType, state_3.WeeklyFulltimeWorkHours, state_3.ActiveTab, state_3.IsBusy, state_3.Errors), Cmd_none()];
            }
        case 18:
            return [new State(state.Id, state.IsNewUser, state.Username, state.UsernameExists, state.IsActive, state.IsEditedUserAdmin, state.IsSignedInUserAdmin, state.IsSignedInUserOfficeAdmin, state.IsSignedInUserPayrollAdmin, state.FirstName, state.MiddleName, state.LastName, state.Mobile, state.UserDto, state.MeasurementSystem, state.HourlyRate, state.ChargeOutRate, state.Rating, state.UserSkills, state.UserSkillsToDelete, state.OrganisationPayrollSystemIndex, state.OvertimeAfterWorkHours, state.SmartlyUserCode, state.Skills, state.UserRole, state.Dob, state.DriverLicenseCountry, state.DriverLicenseNumber, state.ResidencyStatus, state.EnglishProficiency, state.AccommodationType, state.IsConfirmingResetPassword, state.EmploymentType, state.WeeklyFulltimeWorkHours, state.ActiveTab, new Busy(2, []), state.Errors), saveUserCmd(state)];
        case 19:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Id, state.IsNewUser, state.Username, state.UsernameExists, state.IsActive, state.IsEditedUserAdmin, state.IsSignedInUserAdmin, state.IsSignedInUserOfficeAdmin, state.IsSignedInUserPayrollAdmin, state.FirstName, state.MiddleName, state.LastName, state.Mobile, state.UserDto, state.MeasurementSystem, state.HourlyRate, state.ChargeOutRate, state.Rating, state.UserSkills, state.UserSkillsToDelete, state.OrganisationPayrollSystemIndex, state.OvertimeAfterWorkHours, state.SmartlyUserCode, state.Skills, state.UserRole, state.Dob, state.DriverLicenseCountry, state.DriverLicenseNumber, state.ResidencyStatus, state.EnglishProficiency, state.AccommodationType, state.IsConfirmingResetPassword, state.EmploymentType, state.WeeklyFulltimeWorkHours, state.ActiveTab, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.Id, state.IsNewUser, state.Username, state.UsernameExists, state.IsActive, state.IsEditedUserAdmin, state.IsSignedInUserAdmin, state.IsSignedInUserOfficeAdmin, state.IsSignedInUserPayrollAdmin, state.FirstName, state.MiddleName, state.LastName, state.Mobile, state.UserDto, state.MeasurementSystem, state.HourlyRate, state.ChargeOutRate, state.Rating, state.UserSkills, state.UserSkillsToDelete, state.OrganisationPayrollSystemIndex, state.OvertimeAfterWorkHours, state.SmartlyUserCode, state.Skills, state.UserRole, state.Dob, state.DriverLicenseCountry, state.DriverLicenseNumber, state.ResidencyStatus, state.EnglishProficiency, state.AccommodationType, state.IsConfirmingResetPassword, state.EmploymentType, state.WeeklyFulltimeWorkHours, state.ActiveTab, new Busy(0, []), state.Errors), getDataCmd(msg.fields[0].fields[0])];
            }
        case 20:
            return [new State(state.Id, state.IsNewUser, state.Username, state.UsernameExists, state.IsActive, state.IsEditedUserAdmin, state.IsSignedInUserAdmin, state.IsSignedInUserOfficeAdmin, state.IsSignedInUserPayrollAdmin, state.FirstName, state.MiddleName, state.LastName, state.Mobile, state.UserDto, state.MeasurementSystem, state.HourlyRate, state.ChargeOutRate, state.Rating, state.UserSkills, state.UserSkillsToDelete, state.OrganisationPayrollSystemIndex, state.OvertimeAfterWorkHours, state.SmartlyUserCode, state.Skills, state.UserRole, state.Dob, state.DriverLicenseCountry, state.DriverLicenseNumber, state.ResidencyStatus, state.EnglishProficiency, state.AccommodationType, state.IsConfirmingResetPassword, state.EmploymentType, state.WeeklyFulltimeWorkHours, state.ActiveTab, state.IsBusy, empty()), state.IsNewUser ? checkUsernameExistsCmd(state) : Cmd_none()];
        case 21:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Id, state.IsNewUser, state.Username, state.UsernameExists, state.IsActive, state.IsEditedUserAdmin, state.IsSignedInUserAdmin, state.IsSignedInUserOfficeAdmin, state.IsSignedInUserPayrollAdmin, state.FirstName, state.MiddleName, state.LastName, state.Mobile, state.UserDto, state.MeasurementSystem, state.HourlyRate, state.ChargeOutRate, state.Rating, state.UserSkills, state.UserSkillsToDelete, state.OrganisationPayrollSystemIndex, state.OvertimeAfterWorkHours, state.SmartlyUserCode, state.Skills, state.UserRole, state.Dob, state.DriverLicenseCountry, state.DriverLicenseNumber, state.ResidencyStatus, state.EnglishProficiency, state.AccommodationType, state.IsConfirmingResetPassword, state.EmploymentType, state.WeeklyFulltimeWorkHours, state.ActiveTab, state.IsBusy, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.Id, state.IsNewUser, state.Username, msg.fields[0].fields[0], state.IsActive, state.IsEditedUserAdmin, state.IsSignedInUserAdmin, state.IsSignedInUserOfficeAdmin, state.IsSignedInUserPayrollAdmin, state.FirstName, state.MiddleName, state.LastName, state.Mobile, state.UserDto, state.MeasurementSystem, state.HourlyRate, state.ChargeOutRate, state.Rating, state.UserSkills, state.UserSkillsToDelete, state.OrganisationPayrollSystemIndex, state.OvertimeAfterWorkHours, state.SmartlyUserCode, state.Skills, state.UserRole, state.Dob, state.DriverLicenseCountry, state.DriverLicenseNumber, state.ResidencyStatus, state.EnglishProficiency, state.AccommodationType, state.IsConfirmingResetPassword, state.EmploymentType, state.WeeklyFulltimeWorkHours, state.ActiveTab, state.IsBusy, state.Errors), Cmd_none()];
            }
        case 22:
            return [new State(state.Id, state.IsNewUser, state.Username, state.UsernameExists, state.IsActive, state.IsEditedUserAdmin, state.IsSignedInUserAdmin, state.IsSignedInUserOfficeAdmin, state.IsSignedInUserPayrollAdmin, state.FirstName, state.MiddleName, state.LastName, state.Mobile, state.UserDto, state.MeasurementSystem, state.HourlyRate, state.ChargeOutRate, state.Rating, sortBy((x_27) => [x_27.SkillId, x_27.Country, x_27.Rating], append(state.UserSkills, singleton_1(new UserSkillVM(undefined, undefined, 0, undefined, 0, false))), {
                Compare: compareArrays,
            }), state.UserSkillsToDelete, state.OrganisationPayrollSystemIndex, state.OvertimeAfterWorkHours, state.SmartlyUserCode, state.Skills, state.UserRole, state.Dob, state.DriverLicenseCountry, state.DriverLicenseNumber, state.ResidencyStatus, state.EnglishProficiency, state.AccommodationType, state.IsConfirmingResetPassword, state.EmploymentType, state.WeeklyFulltimeWorkHours, state.ActiveTab, state.IsBusy, state.Errors), Cmd_none()];
        case 23:
            return [(UserSkillsToDelete_1 = append(state.UserSkillsToDelete, (msg.fields[1].IsDeleted && (msg.fields[1].Id != null)) ? singleton_1(msg.fields[1]) : empty()), new State(state.Id, state.IsNewUser, state.Username, state.UsernameExists, state.IsActive, state.IsEditedUserAdmin, state.IsSignedInUserAdmin, state.IsSignedInUserOfficeAdmin, state.IsSignedInUserPayrollAdmin, state.FirstName, state.MiddleName, state.LastName, state.Mobile, state.UserDto, state.MeasurementSystem, state.HourlyRate, state.ChargeOutRate, state.Rating, choose((x_30) => {
                if (x_30.IsDeleted) {
                    return undefined;
                }
                else {
                    return x_30;
                }
            }, mapIndexed((i, y_2) => {
                if (i === msg.fields[0]) {
                    return msg.fields[1];
                }
                else {
                    return y_2;
                }
            }, state.UserSkills)), UserSkillsToDelete_1, state.OrganisationPayrollSystemIndex, state.OvertimeAfterWorkHours, state.SmartlyUserCode, state.Skills, state.UserRole, state.Dob, state.DriverLicenseCountry, state.DriverLicenseNumber, state.ResidencyStatus, state.EnglishProficiency, state.AccommodationType, state.IsConfirmingResetPassword, state.EmploymentType, state.WeeklyFulltimeWorkHours, state.ActiveTab, state.IsBusy, state.Errors)), Cmd_none()];
        case 24:
            return [new State(state.Id, state.IsNewUser, state.Username, state.UsernameExists, state.IsActive, state.IsEditedUserAdmin, state.IsSignedInUserAdmin, state.IsSignedInUserOfficeAdmin, state.IsSignedInUserPayrollAdmin, state.FirstName, state.MiddleName, state.LastName, state.Mobile, state.UserDto, state.MeasurementSystem, state.HourlyRate, state.ChargeOutRate, state.Rating, state.UserSkills, state.UserSkillsToDelete, state.OrganisationPayrollSystemIndex, state.OvertimeAfterWorkHours, msg.fields[0], state.Skills, state.UserRole, state.Dob, state.DriverLicenseCountry, state.DriverLicenseNumber, state.ResidencyStatus, state.EnglishProficiency, state.AccommodationType, state.IsConfirmingResetPassword, state.EmploymentType, state.WeeklyFulltimeWorkHours, state.ActiveTab, state.IsBusy, state.Errors), Cmd_none()];
        case 25:
            return [new State(state.Id, state.IsNewUser, state.Username, state.UsernameExists, state.IsActive, state.IsEditedUserAdmin, state.IsSignedInUserAdmin, state.IsSignedInUserOfficeAdmin, state.IsSignedInUserPayrollAdmin, state.FirstName, state.MiddleName, state.LastName, state.Mobile, state.UserDto, state.MeasurementSystem, state.HourlyRate, state.ChargeOutRate, state.Rating, state.UserSkills, state.UserSkillsToDelete, state.OrganisationPayrollSystemIndex, state.OvertimeAfterWorkHours, state.SmartlyUserCode, state.Skills, state.UserRole, state.Dob, state.DriverLicenseCountry, state.DriverLicenseNumber, state.ResidencyStatus, state.EnglishProficiency, state.AccommodationType, true, state.EmploymentType, state.WeeklyFulltimeWorkHours, state.ActiveTab, state.IsBusy, state.Errors), Cmd_none()];
        case 26:
            return [new State(state.Id, state.IsNewUser, state.Username, state.UsernameExists, state.IsActive, state.IsEditedUserAdmin, state.IsSignedInUserAdmin, state.IsSignedInUserOfficeAdmin, state.IsSignedInUserPayrollAdmin, state.FirstName, state.MiddleName, state.LastName, state.Mobile, state.UserDto, state.MeasurementSystem, state.HourlyRate, state.ChargeOutRate, state.Rating, state.UserSkills, state.UserSkillsToDelete, state.OrganisationPayrollSystemIndex, state.OvertimeAfterWorkHours, state.SmartlyUserCode, state.Skills, state.UserRole, state.Dob, state.DriverLicenseCountry, state.DriverLicenseNumber, state.ResidencyStatus, state.EnglishProficiency, state.AccommodationType, false, state.EmploymentType, state.WeeklyFulltimeWorkHours, state.ActiveTab, state.IsBusy, state.Errors), msg.fields[0] ? singleton_1((dispatch) => {
                dispatch(new Msg(27, []));
            }) : Cmd_none()];
        case 27:
            return [new State(state.Id, state.IsNewUser, state.Username, state.UsernameExists, state.IsActive, state.IsEditedUserAdmin, state.IsSignedInUserAdmin, state.IsSignedInUserOfficeAdmin, state.IsSignedInUserPayrollAdmin, state.FirstName, state.MiddleName, state.LastName, state.Mobile, state.UserDto, state.MeasurementSystem, state.HourlyRate, state.ChargeOutRate, state.Rating, state.UserSkills, state.UserSkillsToDelete, state.OrganisationPayrollSystemIndex, state.OvertimeAfterWorkHours, state.SmartlyUserCode, state.Skills, state.UserRole, state.Dob, state.DriverLicenseCountry, state.DriverLicenseNumber, state.ResidencyStatus, state.EnglishProficiency, state.AccommodationType, false, state.EmploymentType, state.WeeklyFulltimeWorkHours, state.ActiveTab, new Busy(2, []), state.Errors), resetPasswordCmd(state)];
        case 28:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Id, state.IsNewUser, state.Username, state.UsernameExists, state.IsActive, state.IsEditedUserAdmin, state.IsSignedInUserAdmin, state.IsSignedInUserOfficeAdmin, state.IsSignedInUserPayrollAdmin, state.FirstName, state.MiddleName, state.LastName, state.Mobile, state.UserDto, state.MeasurementSystem, state.HourlyRate, state.ChargeOutRate, state.Rating, state.UserSkills, state.UserSkillsToDelete, state.OrganisationPayrollSystemIndex, state.OvertimeAfterWorkHours, state.SmartlyUserCode, state.Skills, state.UserRole, state.Dob, state.DriverLicenseCountry, state.DriverLicenseNumber, state.ResidencyStatus, state.EnglishProficiency, state.AccommodationType, state.IsConfirmingResetPassword, state.EmploymentType, state.WeeklyFulltimeWorkHours, state.ActiveTab, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.Id, state.IsNewUser, state.Username, state.UsernameExists, state.IsActive, state.IsEditedUserAdmin, state.IsSignedInUserAdmin, state.IsSignedInUserOfficeAdmin, state.IsSignedInUserPayrollAdmin, state.FirstName, state.MiddleName, state.LastName, state.Mobile, state.UserDto, state.MeasurementSystem, state.HourlyRate, state.ChargeOutRate, state.Rating, state.UserSkills, state.UserSkillsToDelete, state.OrganisationPayrollSystemIndex, state.OvertimeAfterWorkHours, state.SmartlyUserCode, state.Skills, state.UserRole, state.Dob, state.DriverLicenseCountry, state.DriverLicenseNumber, state.ResidencyStatus, state.EnglishProficiency, state.AccommodationType, state.IsConfirmingResetPassword, state.EmploymentType, state.WeeklyFulltimeWorkHours, state.ActiveTab, new Busy(0, []), state.Errors), Cmd_none()];
            }
        case 29:
            return [new State(state.Id, state.IsNewUser, state.Username, state.UsernameExists, state.IsActive, state.IsEditedUserAdmin, state.IsSignedInUserAdmin, state.IsSignedInUserOfficeAdmin, state.IsSignedInUserPayrollAdmin, state.FirstName, state.MiddleName, state.LastName, state.Mobile, state.UserDto, state.MeasurementSystem, state.HourlyRate, state.ChargeOutRate, state.Rating, state.UserSkills, state.UserSkillsToDelete, state.OrganisationPayrollSystemIndex, state.OvertimeAfterWorkHours, state.SmartlyUserCode, state.Skills, state.UserRole, state.Dob, state.DriverLicenseCountry, state.DriverLicenseNumber, state.ResidencyStatus, state.EnglishProficiency, state.AccommodationType, state.IsConfirmingResetPassword, msg.fields[0], state.WeeklyFulltimeWorkHours, state.ActiveTab, state.IsBusy, state.Errors), Cmd_none()];
        case 30:
            return [new State(state.Id, state.IsNewUser, state.Username, state.UsernameExists, state.IsActive, state.IsEditedUserAdmin, state.IsSignedInUserAdmin, state.IsSignedInUserOfficeAdmin, state.IsSignedInUserPayrollAdmin, state.FirstName, state.MiddleName, state.LastName, state.Mobile, state.UserDto, state.MeasurementSystem, state.HourlyRate, state.ChargeOutRate, state.Rating, state.UserSkills, state.UserSkillsToDelete, state.OrganisationPayrollSystemIndex, state.OvertimeAfterWorkHours, state.SmartlyUserCode, state.Skills, state.UserRole, state.Dob, state.DriverLicenseCountry, state.DriverLicenseNumber, state.ResidencyStatus, state.EnglishProficiency, state.AccommodationType, state.IsConfirmingResetPassword, state.EmploymentType, msg.fields[0], state.ActiveTab, state.IsBusy, state.Errors), Cmd_none()];
        case 31:
            return [new State(state.Id, state.IsNewUser, state.Username, state.UsernameExists, state.IsActive, state.IsEditedUserAdmin, state.IsSignedInUserAdmin, state.IsSignedInUserOfficeAdmin, state.IsSignedInUserPayrollAdmin, state.FirstName, state.MiddleName, state.LastName, state.Mobile, state.UserDto, state.MeasurementSystem, state.HourlyRate, state.ChargeOutRate, state.Rating, state.UserSkills, state.UserSkillsToDelete, state.OrganisationPayrollSystemIndex, state.OvertimeAfterWorkHours, state.SmartlyUserCode, state.Skills, state.UserRole, state.Dob, state.DriverLicenseCountry, state.DriverLicenseNumber, state.ResidencyStatus, state.EnglishProficiency, state.AccommodationType, state.IsConfirmingResetPassword, state.EmploymentType, state.WeeklyFulltimeWorkHours, msg.fields[0], state.IsBusy, state.Errors), Cmd_none()];
        case 32:
            return [new State(state.Id, state.IsNewUser, state.Username, state.UsernameExists, state.IsActive, state.IsEditedUserAdmin, state.IsSignedInUserAdmin, state.IsSignedInUserOfficeAdmin, state.IsSignedInUserPayrollAdmin, state.FirstName, state.MiddleName, state.LastName, state.Mobile, state.UserDto, state.MeasurementSystem, state.HourlyRate, state.ChargeOutRate, state.Rating, state.UserSkills, state.UserSkillsToDelete, state.OrganisationPayrollSystemIndex, state.OvertimeAfterWorkHours, state.SmartlyUserCode, state.Skills, state.UserRole, state.Dob, state.DriverLicenseCountry, state.DriverLicenseNumber, state.ResidencyStatus, state.EnglishProficiency, state.AccommodationType, state.IsConfirmingResetPassword, state.EmploymentType, state.WeeklyFulltimeWorkHours, state.ActiveTab, new Busy(0, []), singleton_1(toString(msg.fields[0]))), Cmd_none()];
        default:
            return [new State(state.Id, state.IsNewUser, state.Username, state.UsernameExists, msg.fields[0], state.IsEditedUserAdmin, state.IsSignedInUserAdmin, state.IsSignedInUserOfficeAdmin, state.IsSignedInUserPayrollAdmin, state.FirstName, state.MiddleName, state.LastName, state.Mobile, state.UserDto, state.MeasurementSystem, state.HourlyRate, state.ChargeOutRate, state.Rating, state.UserSkills, state.UserSkillsToDelete, state.OrganisationPayrollSystemIndex, state.OvertimeAfterWorkHours, state.SmartlyUserCode, state.Skills, state.UserRole, state.Dob, state.DriverLicenseCountry, state.DriverLicenseNumber, state.ResidencyStatus, state.EnglishProficiency, state.AccommodationType, state.IsConfirmingResetPassword, state.EmploymentType, state.WeeklyFulltimeWorkHours, state.ActiveTab, state.IsBusy, state.Errors), Cmd_none()];
    }
}

export function payrollView(state, dispatch) {
    return viewBox("Smartly Payroll", new TitleSize(0, []), toList(delay(() => {
        let children_5, children_3, children_1;
        return (state.OrganisationPayrollSystemIndex === 1) ? singleton_2((children_5 = singleton_1((children_3 = ofArray([(children_1 = singleton_1(createElement("span", {
            className: "label-text",
            children: "User code",
        })), createElement("label", {
            className: "label",
            children: reactApi.Children.toArray(Array.from(children_1)),
        })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", state.SmartlyUserCode], ["placeholder", "User code"], ["readOnly", BusyModule_isSaving(state.IsBusy)], ["onChange", (ev) => {
            dispatch(new Msg(24, [ev.target.value]));
        }]]))))]), createElement("div", {
            className: "form-control",
            children: reactApi.Children.toArray(Array.from(children_3)),
        }))), createElement("div", {
            children: reactApi.Children.toArray(Array.from(children_5)),
        }))) : singleton_2(react.createElement(react.Fragment, {}));
    })));
}

export function modalId() {
    let arg;
    let copyOfStruct = newGuid();
    arg = copyOfStruct;
    return toText(printf("logos-client-confirm-dialog-%s"))(arg);
}

export function userDetail(state, userAlreadyExists, dispatch) {
    let elems_49;
    const isSaving = BusyModule_isSaving(state.IsBusy);
    return createElement("div", createObj(singleton_1((elems_49 = toList(delay(() => {
        let children_4, children_1, elems_1;
        return append_1(singleton_2((children_4 = ofArray([(children_1 = singleton_1(createElement("span", {
            className: "label-text",
            children: "Username - Email",
        })), createElement("label", {
            className: "label",
            children: reactApi.Children.toArray(Array.from(children_1)),
        })), createElement("div", createObj(ofArray([["className", join(" ", ["flex"])], (elems_1 = toList(delay(() => append_1(singleton_2(createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", join(" ", ["w-full"])], ["className", "input-bordered"], ["className", "input-sm"], ["value", state.Username], ["placeholder", "Username"], ["readOnly", !state.IsNewUser], ["onChange", (ev) => {
            dispatch(new Msg(1, [ev.target.value]));
        }], ["onBlur", (x) => {
            dispatch(new Msg(20, [state.Username]));
        }]]))))), delay(() => (state.IsNewUser ? singleton_2(react.createElement(react.Fragment, {})) : singleton_2(createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", join(" ", ["ml-4"])], ["className", "btn-secondary"], ["className", "btn-sm"], ["children", "Reset password"], ["onClick", (_arg_1) => {
            dispatch(new Msg(25, []));
        }]])))))))))), ["children", reactApi.Children.toArray(Array.from(elems_1))])])))]), createElement("div", {
            className: "form-control",
            children: reactApi.Children.toArray(Array.from(children_4)),
        }))), delay(() => {
            let children_9, children_7;
            return append_1(singleton_2((children_9 = ofArray([(children_7 = singleton_1(createElement("span", {
                className: "label-text",
                children: "First-name",
            })), createElement("label", {
                className: "label",
                children: reactApi.Children.toArray(Array.from(children_7)),
            })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["autoFocus", true], ["value", state.FirstName], ["placeholder", "First-name"], ["readOnly", isSaving], ["onChange", (ev_1) => {
                dispatch(new Msg(2, [ev_1.target.value]));
            }], ["disabled", userAlreadyExists]]))))]), createElement("div", {
                className: "form-control",
                children: reactApi.Children.toArray(Array.from(children_9)),
            }))), delay(() => {
                let children_14, children_12;
                return append_1(singleton_2((children_14 = ofArray([(children_12 = singleton_1(createElement("span", {
                    className: "label-text",
                    children: "Middle-name",
                })), createElement("label", {
                    className: "label",
                    children: reactApi.Children.toArray(Array.from(children_12)),
                })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", state.MiddleName], ["placeholder", "Middle-name"], ["readOnly", isSaving], ["onChange", (ev_2) => {
                    dispatch(new Msg(3, [ev_2.target.value]));
                }], ["disabled", userAlreadyExists]]))))]), createElement("div", {
                    className: "form-control",
                    children: reactApi.Children.toArray(Array.from(children_14)),
                }))), delay(() => {
                    let children_19, children_17;
                    return append_1(singleton_2((children_19 = ofArray([(children_17 = singleton_1(createElement("span", {
                        className: "label-text",
                        children: "Last-name",
                    })), createElement("label", {
                        className: "label",
                        children: reactApi.Children.toArray(Array.from(children_17)),
                    })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", state.LastName], ["placeholder", "Last-name"], ["readOnly", isSaving], ["onChange", (ev_3) => {
                        dispatch(new Msg(4, [ev_3.target.value]));
                    }], ["disabled", userAlreadyExists]]))))]), createElement("div", {
                        className: "form-control",
                        children: reactApi.Children.toArray(Array.from(children_19)),
                    }))), delay(() => {
                        let children_25, children_22, props_26, input_1, x_1;
                        return append_1(singleton_2((children_25 = ofArray([(children_22 = singleton_1(createElement("span", {
                            className: "label-text",
                            children: "Date of birth",
                        })), createElement("label", {
                            className: "label",
                            children: reactApi.Children.toArray(Array.from(children_22)),
                        })), (props_26 = {
                            asSingle: true,
                            useRange: false,
                            displayFormat: "DD/MM/YYYY",
                            value: defaultArg((input_1 = state.Dob, (input_1 == null) ? undefined : ((x_1 = input_1, new DateRange(x_1, x_1)))), new DateRange(minValue(), minValue())),
                            onChange: (x_2) => {
                                dispatch(new Msg(11, [new DateRange(parse_1(x_2.startDate), parse_1(x_2.endDate))]));
                            },
                            showShortcuts: false,
                        }, react.createElement(react_tailwindcss_datepicker, props_26))]), createElement("div", {
                            className: "form-control",
                            children: reactApi.Children.toArray(Array.from(children_25)),
                        }))), delay(() => {
                            let children_30, children_28;
                            return append_1(singleton_2((children_30 = ofArray([(children_28 = singleton_1(createElement("span", {
                                className: "label-text",
                                children: "Mobile",
                            })), createElement("label", {
                                className: "label",
                                children: reactApi.Children.toArray(Array.from(children_28)),
                            })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", state.Mobile], ["placeholder", "Mobile"], ["readOnly", isSaving], ["onChange", (ev_4) => {
                                dispatch(new Msg(5, [ev_4.target.value]));
                            }], ["disabled", userAlreadyExists]]))))]), createElement("div", {
                                className: "form-control",
                                children: reactApi.Children.toArray(Array.from(children_30)),
                            }))), delay(() => {
                                let children_35, children_33;
                                return append_1(singleton_2((children_35 = ofArray([(children_33 = singleton_1(createElement("span", {
                                    className: "label-text",
                                    children: "Driver license country",
                                })), createElement("label", {
                                    className: "label",
                                    children: reactApi.Children.toArray(Array.from(children_33)),
                                })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", state.DriverLicenseCountry], ["placeholder", "Driver license country"], ["readOnly", isSaving], ["onChange", (ev_5) => {
                                    dispatch(new Msg(12, [ev_5.target.value]));
                                }], ["disabled", userAlreadyExists]]))))]), createElement("div", {
                                    className: "form-control",
                                    children: reactApi.Children.toArray(Array.from(children_35)),
                                }))), delay(() => {
                                    let children_40, children_38;
                                    return append_1(singleton_2((children_40 = ofArray([(children_38 = singleton_1(createElement("span", {
                                        className: "label-text",
                                        children: "Driver license number",
                                    })), createElement("label", {
                                        className: "label",
                                        children: reactApi.Children.toArray(Array.from(children_38)),
                                    })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", state.DriverLicenseNumber], ["placeholder", "Driver license number"], ["readOnly", isSaving], ["onChange", (ev_6) => {
                                        dispatch(new Msg(13, [ev_6.target.value]));
                                    }], ["disabled", userAlreadyExists]]))))]), createElement("div", {
                                        className: "form-control",
                                        children: reactApi.Children.toArray(Array.from(children_40)),
                                    }))), delay(() => {
                                        let elems_22;
                                        return append_1(singleton_2(createElement("div", createObj(ofArray([["className", "flex gap-4"], (elems_22 = toList(delay(() => {
                                            let children_46, children_43, elems_18;
                                            return append_1(singleton_2((children_46 = ofArray([(children_43 = singleton_1(createElement("span", {
                                                className: "label-text",
                                                children: "Employment Type",
                                            })), createElement("label", {
                                                className: "label",
                                                children: reactApi.Children.toArray(Array.from(children_43)),
                                            })), createElement("select", createObj(Helpers_combineClasses("select", ofArray([["className", "select-bordered"], ["className", "select-sm"], ["placeholder", "Employment Type"], ["readOnly", isSaving], ["value", state.EmploymentType], ["onChange", (ev_7) => {
                                                dispatch(new Msg(29, [ev_7.target.value]));
                                            }], (elems_18 = toList(delay(() => map_1((case$) => createElement("option", {
                                                value: name_186(case$),
                                                children: String_splitWord(name_186(case$)),
                                            }), EmploymentTypeModule_getLookupValues()))), ["children", reactApi.Children.toArray(Array.from(elems_18))]), ["disabled", userAlreadyExists]]))))]), createElement("div", {
                                                className: "form-control",
                                                children: reactApi.Children.toArray(Array.from(children_46)),
                                            }))), delay(() => {
                                                let children_51, children_49;
                                                return (state.EmploymentType === EmploymentTypeModule_toString(new EmploymentType_1(0, []))) ? singleton_2((children_51 = ofArray([(children_49 = singleton_1(createElement("span", {
                                                    className: "label-text",
                                                    children: "Weekly Fulltime work hours",
                                                })), createElement("label", {
                                                    className: "label",
                                                    children: reactApi.Children.toArray(Array.from(children_49)),
                                                })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", state.WeeklyFulltimeWorkHours], ["placeholder", "Weekly Fulltime work hours"], ["readOnly", isSaving], ["onChange", (ev_8) => {
                                                    dispatch(new Msg(30, [ev_8.target.value]));
                                                }], ["disabled", userAlreadyExists]]))))]), createElement("div", {
                                                    className: "form-control",
                                                    children: reactApi.Children.toArray(Array.from(children_51)),
                                                }))) : empty_1();
                                            }));
                                        })), ["children", reactApi.Children.toArray(Array.from(elems_22))])])))), delay(() => {
                                            let children_57, children_54, elems_24;
                                            return append_1(singleton_2((children_57 = ofArray([(children_54 = singleton_1(createElement("span", {
                                                className: "label-text",
                                                children: "Residency status",
                                            })), createElement("label", {
                                                className: "label",
                                                children: reactApi.Children.toArray(Array.from(children_54)),
                                            })), createElement("select", createObj(Helpers_combineClasses("select", ofArray([["className", "select-bordered"], ["className", "select-sm"], ["placeholder", "Residency status"], ["readOnly", isSaving], ["value", state.ResidencyStatus], ["onChange", (ev_9) => {
                                                dispatch(new Msg(14, [ev_9.target.value]));
                                            }], (elems_24 = toList(delay(() => map_1((case$_1) => createElement("option", {
                                                value: case$_1,
                                                children: String_splitWord(case$_1),
                                            }), ResidencyStatusModule_getLookupValues()))), ["children", reactApi.Children.toArray(Array.from(elems_24))]), ["disabled", userAlreadyExists]]))))]), createElement("div", {
                                                className: "form-control",
                                                children: reactApi.Children.toArray(Array.from(children_57)),
                                            }))), delay(() => {
                                                let children_63, children_60, elems_27;
                                                return append_1(singleton_2((children_63 = ofArray([(children_60 = singleton_1(createElement("span", {
                                                    className: "label-text",
                                                    children: "English proficiency",
                                                })), createElement("label", {
                                                    className: "label",
                                                    children: reactApi.Children.toArray(Array.from(children_60)),
                                                })), createElement("select", createObj(Helpers_combineClasses("select", ofArray([["className", "select-bordered"], ["className", "select-sm"], ["placeholder", "English proficiency"], ["readOnly", isSaving], ["value", state.EnglishProficiency], ["onChange", (ev_10) => {
                                                    dispatch(new Msg(15, [ev_10.target.value]));
                                                }], (elems_27 = toList(delay(() => map_1((case$_2) => createElement("option", {
                                                    value: case$_2,
                                                    children: String_splitWord(case$_2),
                                                }), EnglishProficiencyModule_getLookupValues()))), ["children", reactApi.Children.toArray(Array.from(elems_27))]), ["disabled", userAlreadyExists]]))))]), createElement("div", {
                                                    className: "form-control",
                                                    children: reactApi.Children.toArray(Array.from(children_63)),
                                                }))), delay(() => {
                                                    let children_69, children_66, elems_30;
                                                    return append_1(singleton_2((children_69 = ofArray([(children_66 = singleton_1(createElement("span", {
                                                        className: "label-text",
                                                        children: "Accommodation type",
                                                    })), createElement("label", {
                                                        className: "label",
                                                        children: reactApi.Children.toArray(Array.from(children_66)),
                                                    })), createElement("select", createObj(Helpers_combineClasses("select", ofArray([["className", "select-bordered"], ["className", "select-sm"], ["placeholder", "Accommodation type"], ["readOnly", isSaving], ["value", state.AccommodationType], ["onChange", (ev_11) => {
                                                        dispatch(new Msg(16, [ev_11.target.value]));
                                                    }], (elems_30 = toList(delay(() => map_1((case$_3) => createElement("option", {
                                                        value: case$_3,
                                                        children: String_splitWord(case$_3),
                                                    }), AccommodationTypeModule_getLookupValues()))), ["children", reactApi.Children.toArray(Array.from(elems_30))]), ["disabled", userAlreadyExists]]))))]), createElement("div", {
                                                        className: "form-control",
                                                        children: reactApi.Children.toArray(Array.from(children_69)),
                                                    }))), delay(() => {
                                                        let elems_33, elems_32, value_362;
                                                        return append_1(singleton_2(createElement("label", createObj(Helpers_combineClasses("label", ofArray([["className", join(" ", ["flex", "justify-start", "mt-2"])], (elems_33 = [createElement("div", createObj(ofArray([["className", join(" ", ["mr-4"])], (elems_32 = [createElement("span", {
                                                            className: "label-text",
                                                            children: "Active",
                                                        })], ["children", reactApi.Children.toArray(Array.from(elems_32))])]))), createElement("input", createObj(Helpers_combineClasses("checkbox", ofArray([["type", "checkbox"], ["className", "checkbox-sm"], ["disabled", state.IsEditedUserAdmin], ["checked", state.IsActive], ["onChange", (ev_12) => {
                                                            dispatch(new Msg(0, [ev_12.target.checked]));
                                                        }], ["disabled", userAlreadyExists]])))), createElement("div", createObj(ofArray([["className", join(" ", ["ml-4"])], (value_362 = "User is active and can login to the system. On mobile app, the user cannot start a shift if inactive.", ["children", value_362])])))], ["children", reactApi.Children.toArray(Array.from(elems_33))])]))))), delay(() => {
                                                            let children_77, children_74, elems_35;
                                                            return append_1(singleton_2((children_77 = ofArray([(children_74 = singleton_1(createElement("span", {
                                                                className: "label-text",
                                                                children: "Measurement System",
                                                            })), createElement("label", {
                                                                className: "label",
                                                                children: reactApi.Children.toArray(Array.from(children_74)),
                                                            })), createElement("select", createObj(Helpers_combineClasses("select", ofArray([["className", "select-bordered"], ["className", "select-sm"], ["placeholder", "Measurement System"], ["readOnly", isSaving], ["value", state.MeasurementSystem], ["onChange", (ev_13) => {
                                                                dispatch(new Msg(6, [ev_13.target.value]));
                                                            }], (elems_35 = toList(delay(() => map_1((case$_4) => createElement("option", {
                                                                value: name_186(case$_4),
                                                                children: String_splitWord(name_186(case$_4)),
                                                            }), MeasurementSystemModule_measurementSystems))), ["children", reactApi.Children.toArray(Array.from(elems_35))]), ["disabled", userAlreadyExists]]))))]), createElement("div", {
                                                                className: "form-control",
                                                                children: reactApi.Children.toArray(Array.from(children_77)),
                                                            }))), delay(() => {
                                                                let children_82, children_80;
                                                                return append_1((state.IsSignedInUserAdmin ? true : state.IsSignedInUserPayrollAdmin) ? append_1(singleton_2((children_82 = ofArray([(children_80 = singleton_1(createElement("span", {
                                                                    className: "label-text",
                                                                    children: "Hourly pay rate",
                                                                })), createElement("label", {
                                                                    className: "label",
                                                                    children: reactApi.Children.toArray(Array.from(children_80)),
                                                                })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["type", "number"], ["value", state.HourlyRate], ["placeholder", "Hourly pay rate"], ["readOnly", isSaving], ["onChange", (ev_14) => {
                                                                    dispatch(new Msg(7, [ev_14.target.value]));
                                                                }], ["disabled", userAlreadyExists]]))))]), createElement("div", {
                                                                    className: "form-control",
                                                                    children: reactApi.Children.toArray(Array.from(children_82)),
                                                                }))), delay(() => {
                                                                    let children_87, children_85;
                                                                    return append_1(singleton_2((children_87 = ofArray([(children_85 = singleton_1(createElement("span", {
                                                                        className: "label-text",
                                                                        children: "Hourly charge-out rate",
                                                                    })), createElement("label", {
                                                                        className: "label",
                                                                        children: reactApi.Children.toArray(Array.from(children_85)),
                                                                    })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["type", "number"], ["value", state.ChargeOutRate], ["placeholder", "Hourly charge-out rate"], ["readOnly", isSaving], ["onChange", (ev_15) => {
                                                                        dispatch(new Msg(8, [ev_15.target.value]));
                                                                    }], ["disabled", userAlreadyExists]]))))]), createElement("div", {
                                                                        className: "form-control",
                                                                        children: reactApi.Children.toArray(Array.from(children_87)),
                                                                    }))), delay(() => {
                                                                        let children_89;
                                                                        return append_1(singleton_2((children_89 = ofArray([Daisy_labelWithTooltip("Overtime starts after working for (hours) in pay period", "Overtime starts after working for this duration in pay period.", "fa fa-solid fa-info-circle"), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["type", "number"], ["className", "input-bordered"], ["className", "input-sm"], ["disabled", userAlreadyExists], ["value", state.OvertimeAfterWorkHours], ["placeholder", "Overtime starts after working for (hours) in pay period"], ["readOnly", isSaving], ["onChange", (ev_16) => {
                                                                            dispatch(new Msg(9, [ev_16.target.value]));
                                                                        }]]))))]), createElement("div", {
                                                                            className: "form-control",
                                                                            children: reactApi.Children.toArray(Array.from(children_89)),
                                                                        }))), delay(() => {
                                                                            let children_96, elems_45, elems_44, elems_43;
                                                                            return append_1(singleton_2((children_96 = singleton_1(createElement("div", createObj(ofArray([["className", "pt-4"], (elems_45 = [viewBox("User Role", new TitleSize(0, []), singleton_1(createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-4", "gap-2"])], (elems_44 = [createElement("div", createObj(ofArray([["className", join(" ", ["pl-8"])], (elems_43 = toList(delay(() => map_1((role) => {
                                                                                let children_92;
                                                                                const children_94 = singleton_1((children_92 = ofArray([createElement("span", {
                                                                                    className: "label-text",
                                                                                    children: String_addSpaceBeforeWord(toString(role)),
                                                                                }), createElement("input", createObj(Helpers_combineClasses("radio", ofArray([["type", "radio"], ["className", "radio-sm"], ["name", "UserRole"], ["checked", equals(state.UserRole, role)], ["onChange", (ev_17) => {
                                                                                    const _arg_3 = ev_17.target.checked;
                                                                                    dispatch(new Msg(10, [role]));
                                                                                }]]))))]), createElement("label", {
                                                                                    className: "label",
                                                                                    children: reactApi.Children.toArray(Array.from(children_92)),
                                                                                })));
                                                                                return createElement("div", {
                                                                                    children: reactApi.Children.toArray(Array.from(children_94)),
                                                                                });
                                                                            }, [new UserRole_1(0, []), new UserRole_1(5, []), new UserRole_1(6, []), new UserRole_1(1, []), new UserRole_1(2, []), new UserRole_1(4, []), new UserRole_1(3, [])]))), ["children", reactApi.Children.toArray(Array.from(elems_43))])]))), createElement("div", {})], ["children", reactApi.Children.toArray(Array.from(elems_44))])])))))], ["children", reactApi.Children.toArray(Array.from(elems_45))])])))), createElement("div", {
                                                                                className: "form-control",
                                                                                children: reactApi.Children.toArray(Array.from(children_96)),
                                                                            }))), delay(() => {
                                                                                let elems_47;
                                                                                return (state.IsSignedInUserAdmin ? true : state.IsSignedInUserPayrollAdmin) ? ((state.OrganisationPayrollSystemIndex === 1) ? singleton_2(createElement("div", createObj(Helpers_combineClasses("form-control", ofArray([["className", join(" ", ["mt-4"])], (elems_47 = [payrollView(state, dispatch)], ["children", reactApi.Children.toArray(Array.from(elems_47))])]))))) : empty_1()) : empty_1();
                                                                            }));
                                                                        }));
                                                                    }));
                                                                })) : empty_1(), delay(() => append_1(singleton_2(createElement("div", {
                                                                    className: join(" ", ["mb-4"]),
                                                                })), delay(() => singleton_2(viewBox("User Skills", new TitleSize(2, []), toList(delay(() => append_1(singleton_2(createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", join(" ", ["my-4"])], ["className", "btn-secondary"], ["className", "btn-sm"], ["children", "Add"], ["onClick", (_arg_4) => {
                                                                    dispatch(new Msg(22, []));
                                                                }]]))))), delay(() => {
                                                                    let elems_48;
                                                                    return append_1(singleton_2(createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-5", "my-2", "gap-2"])], (elems_48 = [Daisy_h6("Skill"), Daisy_h6("Experience (months)"), Daisy_h6("Country"), Daisy_h6("Rating"), createElement("div", {})], ["children", reactApi.Children.toArray(Array.from(elems_48))])])))), delay(() => collect((matchValue) => {
                                                                        const idx = matchValue[0] | 0;
                                                                        return singleton_2(createElement(IndexView_1, {
                                                                            Index: idx,
                                                                            IsSaving: isSaving,
                                                                            OnUserSkillModelChanged: (x_3) => {
                                                                                let tupledArg;
                                                                                dispatch((tupledArg = [idx, x_3], new Msg(23, [tupledArg[0], tupledArg[1]])));
                                                                            },
                                                                            Skills: state.Skills,
                                                                            UserSkill: matchValue[1],
                                                                        }));
                                                                    }, mapIndexed((i, x_4) => [i, x_4], state.UserSkills))));
                                                                }))))))))));
                                                            }));
                                                        }));
                                                    }));
                                                }));
                                            }));
                                        }));
                                    }));
                                }));
                            }));
                        }));
                    }));
                }));
            }));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_49))]))));
}

export function IndexView(props) {
    let patternInput;
    const init_1 = init(props.userId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const userAlreadyExists = defaultArg(state_1.UsernameExists, false);
    return container(toList(delay(() => {
        let children_1, elems;
        return append_1(singleton_2((children_1 = ofArray([Daisy_h4((`User - ${state_1.FirstName} ${state_1.MiddleName} ${state_1.LastName}`).trim()), createElement("div", createObj(Helpers_combineClasses("rating", ofArray([["className", "rating-sm"], (elems = toList(delay(() => collect((i) => {
            const isChecked = i === state_1.Rating;
            return (i === 0) ? singleton_2(createElement("input", createObj(ofArray([["type", "radio"], ["disabled", true], ["name", "rating-0"], op_PlusPlus(["className", "bg-warning"], ["className", "mask mask-star"]), ["className", join(" ", ["rating-hidden"])], ["defaultChecked", isChecked]])))) : (isChecked ? singleton_2(createElement("input", createObj(ofArray([["type", "radio"], ["disabled", true], ["name", "rating-0"], op_PlusPlus(["className", "bg-warning"], ["className", "mask mask-star"]), ["defaultChecked", isChecked]])))) : singleton_2(createElement("input", createObj(ofArray([["type", "radio"], ["disabled", true], ["name", "rating-0"], op_PlusPlus(["className", "bg-warning"], ["className", "mask mask-star"])])))));
        }, rangeDouble((state_1.Rating === 0) ? 0 : 1, 1, 5)))), ["children", reactApi.Children.toArray(Array.from(elems))])]))))]), createElement("div", {
            children: reactApi.Children.toArray(Array.from(children_1)),
        }))), delay(() => append_1(userAlreadyExists ? singleton_2(Daisy_info(singleton_1("User already exists, please invite the user to join your organisation."))) : empty_1(), delay(() => append_1(state_1.IsNewUser ? singleton_2(Daisy_info(singleton_1("New user will be sent an invitation email to join your organisation after saving."))) : empty_1(), delay(() => append_1(state_1.IsConfirmingResetPassword ? singleton_2(confirmSimple(new Daisy_DialogWidth(1, []), "Are you sure you want to reset the user\'s password? User will have to login with the new password to use the system.", (_arg_2) => {
            dispatch(new Msg(26, [false]));
        }, (_arg_3) => {
            dispatch(new Msg(26, [true]));
        })) : singleton_2(react.createElement(react.Fragment, {})), delay(() => append_1(singleton_2(Daisy_error(state_1.Errors)), delay(() => {
            let children_3;
            return append_1(singleton_2((children_3 = singleton_1(createElement(Daisy_progressState, {
                busy: state_1.IsBusy,
            })), createElement("div", {
                className: "form-control",
                children: reactApi.Children.toArray(Array.from(children_3)),
            }))), delay(() => append_1(singleton_2(createElement("button", createObj(Helpers_combineClasses("btn", toList(delay(() => append_1(BusyModule_isSaving(state_1.IsBusy) ? singleton_2(["className", "loading"]) : empty_1(), delay(() => append_1(singleton_2(["className", "btn-primary"]), delay(() => append_1(singleton_2(["className", "btn-sm"]), delay(() => append_1(singleton_2(["className", join(" ", ["mr-4"])]), delay(() => append_1(singleton_2(["children", "Save"]), delay(() => append_1(singleton_2(["onClick", (_arg_5) => {
                dispatch(new Msg(18, []));
            }]), delay(() => singleton_2(["disabled", userAlreadyExists]))))))))))))))))))), delay(() => append_1(userAlreadyExists ? singleton_2(createElement(IndexView_2, {
                username: state_1.Username,
            })) : empty_1(), delay(() => {
                let elems_2;
                const getTabProps = (viewTab) => {
                    const getTabProp = (title, viewTabA) => append(ofArray([["children", title], ["onClick", (_arg_6) => {
                        dispatch(new Msg(31, [viewTabA]));
                    }]]), toList(delay(() => (equals(state_1.ActiveTab, viewTabA) ? singleton_2(["className", "tab-active"]) : empty_1()))));
                    switch (viewTab.tag) {
                        case 1:
                            return getTabProp("Documents", new Tab(1, []));
                        case 2:
                            return getTabProp("Subscriptions", new Tab(2, []));
                        default:
                            return getTabProp("Details", new Tab(0, []));
                    }
                };
                return append_1(singleton_2(createElement("div", createObj(Helpers_combineClasses("tabs", ofArray([["className", "tabs-lifted"], (elems_2 = [createElement("a", createObj(Helpers_combineClasses("tab", getTabProps(new Tab(0, []))))), createElement("a", createObj(Helpers_combineClasses("tab", getTabProps(new Tab(1, []))))), createElement("a", createObj(Helpers_combineClasses("tab", getTabProps(new Tab(2, [])))))], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))))), delay(() => {
                    const matchValue = state_1.ActiveTab;
                    return (matchValue.tag === 1) ? singleton_2(createElement(IndexView_3, {
                        ownerId: state_1.Id,
                        documentType: new DocumentType$(0, []),
                    })) : ((matchValue.tag === 2) ? singleton_2(createElement(IndexView_4, {
                        userId: state_1.Id,
                    })) : singleton_2(userDetail(state_1, userAlreadyExists, dispatch)));
                }));
            }))))));
        }))))))))));
    })));
}

