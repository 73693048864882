import { Union, Record } from "../../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type, tuple_type, record_type, string_type, list_type, class_type } from "../../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { InvoiceGenerationMethodModule_toString, InvoiceRunTimesheetRunNumber_$reflection, InvoiceRunDetail_$reflection, InvoiceRunItem_$reflection } from "../../../../../Logos.Shared/Domain/Entity/Invoice.fs.js";
import { Pagination_$reflection } from "../../../../../Logos.Shared/Domain/Entity/Pagination.fs.js";
import { Busy, Busy_$reflection } from "../../../SharedView.fs.js";
import { FSharpResult$2 } from "../../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { getInvoiceRunItemsForJob } from "../../../../AppDomain/UseCase/Invoice.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../../Infrastructure/AppRoot.fs.js";
import { comparePrimitives, createObj, equals } from "../../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Cmd_none } from "../../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { paginationControl, firstPage, nextPage, previousPage, getDefaultPagination } from "../../../Component/Pagination.fs.js";
import { filter, map, sort, tryFind, ofArray, isEmpty, singleton as singleton_1, empty } from "../../../../fable_modules/fable-library-js.4.19.3/List.js";
import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { Daisy_progressState, Daisy_error } from "../../../Component/Component.fs.js";
import { reactApi } from "../../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { Helpers_combineClasses } from "../../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { join } from "../../../../fable_modules/fable-library-js.4.19.3/String.js";
import { append, singleton as singleton_2, collect, delay, toList } from "../../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { defaultArg } from "../../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { DateTime_dateToStringWithDayName } from "../../../../AppDomain/Util.fs.js";
import { fromDateTimeOffset } from "../../../../fable_modules/fable-library-js.4.19.3/Date.js";
import { System_Decimal__Decimal_ToDisplayCurrency } from "../../../../../Logos.Shared/Util.fs.js";

export class State extends Record {
    constructor(JobId, InvoiceRunItems, InvoiceRunDetails, InvoiceRunTimesheetRunNumbers, NextPagination, CurrentPagination, Errors, IsBusy) {
        super();
        this.JobId = JobId;
        this.InvoiceRunItems = InvoiceRunItems;
        this.InvoiceRunDetails = InvoiceRunDetails;
        this.InvoiceRunTimesheetRunNumbers = InvoiceRunTimesheetRunNumbers;
        this.NextPagination = NextPagination;
        this.CurrentPagination = CurrentPagination;
        this.Errors = Errors;
        this.IsBusy = IsBusy;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.InvoiceList.State", [], State, () => [["JobId", class_type("System.Guid")], ["InvoiceRunItems", list_type(InvoiceRunItem_$reflection())], ["InvoiceRunDetails", list_type(InvoiceRunDetail_$reflection())], ["InvoiceRunTimesheetRunNumbers", list_type(InvoiceRunTimesheetRunNumber_$reflection())], ["NextPagination", Pagination_$reflection()], ["CurrentPagination", Pagination_$reflection()], ["Errors", list_type(string_type)], ["IsBusy", Busy_$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetInvoiceRunItemsResponse", "GotoPreviousPage", "GotoNextPage", "GotoFirstPage", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.InvoiceList.Msg", [], Msg, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(list_type(InvoiceRunItem_$reflection()), list_type(InvoiceRunDetail_$reflection()), list_type(InvoiceRunTimesheetRunNumber_$reflection())), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", tuple_type(list_type(InvoiceRunItem_$reflection()), list_type(InvoiceRunDetail_$reflection()), list_type(InvoiceRunTimesheetRunNumber_$reflection()))]], [["ErrorValue", list_type(string_type)]]])]], [], [], [], [["Item", class_type("System.Exception")]]]);
}

export function getInvoiceRunItemsCmd(jobId, pagination) {
    return cmdOfAsync((Item) => (new Msg(0, [Item])), (Item_1) => (new Msg(4, [Item_1])), () => singleton.Delay(() => getInvoiceRunItemsForJob(appRoot.InvoiceService, AppRootModule_getSelectedOrgId(), jobId, pagination)));
}

function changePage(state, f) {
    const newPagination = f(state.CurrentPagination);
    if (equals(state.NextPagination, newPagination)) {
        return [state, Cmd_none()];
    }
    else {
        return [new State(state.JobId, state.InvoiceRunItems, state.InvoiceRunDetails, state.InvoiceRunTimesheetRunNumbers, newPagination, state.CurrentPagination, state.Errors, new Busy(1, [])), getInvoiceRunItemsCmd(state.JobId, newPagination)];
    }
}

export function init(jobId) {
    const pagination = getDefaultPagination();
    return [new State(jobId, empty(), empty(), empty(), pagination, pagination, empty(), new Busy(1, [])), getInvoiceRunItemsCmd(jobId, pagination)];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return changePage(state, previousPage);
        case 2:
            return changePage(state, nextPage);
        case 3:
            return changePage(state, firstPage);
        case 4:
            return [new State(state.JobId, state.InvoiceRunItems, state.InvoiceRunDetails, state.InvoiceRunTimesheetRunNumbers, state.NextPagination, state.CurrentPagination, singleton_1(msg.fields[0].message), new Busy(0, [])), Cmd_none()];
        default:
            if (msg.fields[0].tag === 1) {
                return [new State(state.JobId, state.InvoiceRunItems, state.InvoiceRunDetails, state.InvoiceRunTimesheetRunNumbers, state.NextPagination, state.CurrentPagination, msg.fields[0].fields[0], new Busy(0, [])), Cmd_none()];
            }
            else {
                return [isEmpty(msg.fields[0].fields[0][0]) ? (new State(state.JobId, state.InvoiceRunItems, state.InvoiceRunDetails, state.InvoiceRunTimesheetRunNumbers, state.CurrentPagination, state.CurrentPagination, state.Errors, new Busy(0, []))) : (new State(state.JobId, msg.fields[0].fields[0][0], msg.fields[0].fields[0][1], msg.fields[0].fields[0][2], state.NextPagination, state.NextPagination, state.Errors, new Busy(0, []))), Cmd_none()];
            }
    }
}

export function IndexView(indexViewInputProps) {
    let children, elems_1, children_5, children_3, children_11;
    const jobId = indexViewInputProps.jobId;
    let patternInput;
    const init_1 = init(jobId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const children_15 = ofArray([Daisy_error(state_1.Errors), (children = singleton_1(createElement(Daisy_progressState, {
        busy: state_1.IsBusy,
    })), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children)),
    })), createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["w-full"])], (elems_1 = [(children_5 = singleton_1((children_3 = ofArray([createElement("th", {
        width: 15 + "%",
        children: "Created",
    }), createElement("th", {
        width: 5 + "%",
        children: "Invoice Run #",
    }), createElement("th", {
        width: 10 + "%",
        children: "Generation Method",
    }), createElement("th", {
        width: 50 + "%",
        children: "Timesheet Runs",
    }), createElement("th", {
        className: "text-right",
        width: 10 + "%",
        children: "Amount",
    }), createElement("th", {
        width: 10 + "%",
        children: "Xero Inv #",
    })]), createElement("tr", {
        children: reactApi.Children.toArray(Array.from(children_3)),
    }))), createElement("thead", {
        children: reactApi.Children.toArray(Array.from(children_5)),
    })), (children_11 = toList(delay(() => collect((invoice) => {
        let input_1, x_1, children_9, children_7;
        const patternInput_1 = defaultArg((input_1 = tryFind((x) => (x.Id === invoice.InvoiceRunId), state_1.InvoiceRunDetails), (input_1 == null) ? undefined : ((x_1 = input_1, [x_1.RunNumber, InvoiceGenerationMethodModule_toString(x_1.InvoiceGenerationMethod)]))), ["", ""]);
        return singleton_2((children_9 = ofArray([createElement("td", {
            children: DateTime_dateToStringWithDayName(fromDateTimeOffset(invoice.Created, 0)),
        }), createElement("td", {
            children: patternInput_1[0],
        }), createElement("td", {
            children: patternInput_1[1],
        }), createElement("td", {
            children: join(", ", sort(map((x_3) => x_3.TimesheetRunNumber, filter((x_2) => (x_2.InvoiceRunId === invoice.Id), state_1.InvoiceRunTimesheetRunNumbers)), {
                Compare: comparePrimitives,
            })),
        }), createElement("td", createObj(toList(delay(() => append(singleton_2(["className", "text-right"]), delay(() => singleton_2(["children", System_Decimal__Decimal_ToDisplayCurrency(invoice.Amount)]))))))), (children_7 = singleton_1(createElement("a", {
            href: `https://go.xero.com/app/!-f7WD/invoicing/edit/${invoice.XeroInvoiceId}`,
            target: "_blank",
            children: defaultArg(invoice.XeroInvoiceNumber, ""),
            style: {
                color: "blue",
                textDecorationLine: "underline",
                cursor: "pointer",
            },
        })), createElement("td", {
            children: reactApi.Children.toArray(Array.from(children_7)),
        }))]), createElement("tr", {
            children: reactApi.Children.toArray(Array.from(children_9)),
        })));
    }, state_1.InvoiceRunItems))), createElement("tbody", {
        children: reactApi.Children.toArray(Array.from(children_11)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))), createElement("div", createObj(Helpers_combineClasses("divider", empty()))), paginationControl(false, state_1.CurrentPagination.CurrentPage, (_arg_3) => {
        dispatch(new Msg(1, []));
    }, (_arg_4) => {
        dispatch(new Msg(2, []));
    }, (_arg_5) => {
        dispatch(new Msg(3, []));
    })]);
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_15)),
    });
}

