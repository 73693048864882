import { Union, Record } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type, record_type, class_type, option_type, array_type, bool_type, string_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { TokenResult_$reflection } from "../../Logos.Shared/Domain/Dto/User.fs.js";
import { OrganisationDetail_$reflection } from "../../Logos.Shared/Domain/Entity/Organisation.fs.js";
import { UserRole, UserRole_$reflection } from "../../Logos.Shared/Domain/Entity/User.fs.js";
import { map, defaultArg } from "../fable_modules/fable-library-js.4.19.3/Option.js";
import { contains, find } from "../fable_modules/fable-library-js.4.19.3/Array.js";
import { stripeProductId } from "../../Logos.Shared/Domain/Entity/Stripe.fs.js";
import { Product } from "../../Logos.Shared/Domain/Entity/Product.fs.js";
import { stringHash } from "../fable_modules/fable-library-js.4.19.3/Util.js";

export class LocalUserData extends Record {
    constructor(Username, IsMember, PurchasedServices, Token, SelectedOrganisation, UserOrganisations) {
        super();
        this.Username = Username;
        this.IsMember = IsMember;
        this.PurchasedServices = PurchasedServices;
        this.Token = Token;
        this.SelectedOrganisation = SelectedOrganisation;
        this.UserOrganisations = UserOrganisations;
    }
}

export function LocalUserData_$reflection() {
    return record_type("AppDomain.SystemService.LocalUserData", [], LocalUserData, () => [["Username", string_type], ["IsMember", bool_type], ["PurchasedServices", array_type(string_type)], ["Token", option_type(TokenResult_$reflection())], ["SelectedOrganisation", option_type(class_type("System.Guid"))], ["UserOrganisations", array_type(OrganisationDetail_$reflection())]]);
}

export class LocalUserDataModule_UserDetails extends Record {
    constructor(HasLogMasterSubscription, HasWorkMateSubscription, HasAnySubscription, SelectedOrgName, UserRole, UserOrganisations, IsUsingXero, IsUsingSimpro) {
        super();
        this.HasLogMasterSubscription = HasLogMasterSubscription;
        this.HasWorkMateSubscription = HasWorkMateSubscription;
        this.HasAnySubscription = HasAnySubscription;
        this.SelectedOrgName = SelectedOrgName;
        this.UserRole = UserRole;
        this.UserOrganisations = UserOrganisations;
        this.IsUsingXero = IsUsingXero;
        this.IsUsingSimpro = IsUsingSimpro;
    }
}

export function LocalUserDataModule_UserDetails_$reflection() {
    return record_type("AppDomain.SystemService.LocalUserDataModule.UserDetails", [], LocalUserDataModule_UserDetails, () => [["HasLogMasterSubscription", bool_type], ["HasWorkMateSubscription", bool_type], ["HasAnySubscription", bool_type], ["SelectedOrgName", string_type], ["UserRole", UserRole_$reflection()], ["UserOrganisations", array_type(OrganisationDetail_$reflection())], ["IsUsingXero", bool_type], ["IsUsingSimpro", bool_type]]);
}

export function LocalUserDataModule_getUserDetails(userData) {
    if (userData == null) {
        return new LocalUserDataModule_UserDetails(false, false, false, "", new UserRole(3, []), [], false, false);
    }
    else {
        const x = userData;
        const patternInput = defaultArg(map((y) => {
            const x_1 = find((z) => (z.Id === y), x.UserOrganisations);
            return [x_1.Name, x_1.UserRole, x_1.IsUsingXero, x_1.IsUsingSimpro];
        }, x.SelectedOrganisation), ["", new UserRole(3, []), false, false]);
        return new LocalUserDataModule_UserDetails(contains(stripeProductId(new Product(1, [])), x.PurchasedServices, {
            Equals: (x_2, y_1) => (x_2 === y_1),
            GetHashCode: stringHash,
        }), contains(stripeProductId(new Product(2, [])), x.PurchasedServices, {
            Equals: (x_3, y_2) => (x_3 === y_2),
            GetHashCode: stringHash,
        }), x.PurchasedServices.length > 0, patternInput[0], patternInput[1], x.UserOrganisations, patternInput[2], patternInput[3]);
    }
}

export class BrowserData extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["UserData"];
    }
}

export function BrowserData_$reflection() {
    return union_type("AppDomain.SystemService.BrowserData", [], BrowserData, () => [[["Item", LocalUserData_$reflection()]]]);
}

export function BrowserDataModule_getKeyValue(_arg) {
    return ["UserData", JSON.stringify(_arg.fields[0])];
}

