import { Record } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, uint64_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";

export class Pagination extends Record {
    constructor(Limit, Offset, CurrentPage) {
        super();
        this.Limit = Limit;
        this.Offset = Offset;
        this.CurrentPage = CurrentPage;
    }
}

export function Pagination_$reflection() {
    return record_type("Domain.Entity.Pagination.Pagination", [], Pagination, () => [["Limit", uint64_type], ["Offset", uint64_type], ["CurrentPage", uint64_type]]);
}

