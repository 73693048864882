import { Union, Record } from "../../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { tuple_type, union_type, record_type, option_type, list_type, string_type, bool_type, class_type } from "../../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { SimproLookupModule_notSelected, SimproConnectionDetail as SimproConnectionDetail_1, SimproSetupDetail, SimproApiKey, SimproConnectionDetail_$reflection, SimproLookup_$reflection } from "../../../../../Logos.Shared/Domain/Entity/Simpro.fs.js";
import { BusyModule_isSaving, Busy, Busy_$reflection } from "../../../SharedView.fs.js";
import { FSharpResult$2 } from "../../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { Option_ofString, Guid_parse } from "../../../../../Logos.Shared/Util.fs.js";
import { updateSimproConnectionDetail, addSimproConnectionDetail, getSimproSetupDetails, getSimproConnectionDetail } from "../../../../AppDomain/UseCase/Simpro.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../../Infrastructure/AppRoot.fs.js";
import { parse } from "../../../../fable_modules/fable-library-js.4.19.3/Int32.js";
import { ofArray, cons, empty, singleton as singleton_1 } from "../../../../fable_modules/fable-library-js.4.19.3/List.js";
import { join, isNullOrEmpty } from "../../../../fable_modules/fable-library-js.4.19.3/String.js";
import { Cmd_none } from "../../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createObj, int32ToString } from "../../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../../../Component/ViewContainer.fs.js";
import { Daisy_h6, Daisy_labelWithTooltip, Daisy_progressState, Daisy_error, Daisy_h4 } from "../../../Component/Component.fs.js";
import { reactApi } from "../../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { Helpers_combineClasses } from "../../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { map, empty as empty_1, singleton as singleton_2, append, delay, toList } from "../../../../fable_modules/fable-library-js.4.19.3/Seq.js";

export class State extends Record {
    constructor(Id, IsNewConnection, IsDefaultOrgConnection, Name, CompanyURL, IsCompanyURLModified, ApiKey, IsApiKeyModified, CompanyId, Companies, ContractorId, Contractors, TaxCodeId, TaxCodes, SimproConnectionDetail, IsBusy, Errors) {
        super();
        this.Id = Id;
        this.IsNewConnection = IsNewConnection;
        this.IsDefaultOrgConnection = IsDefaultOrgConnection;
        this.Name = Name;
        this.CompanyURL = CompanyURL;
        this.IsCompanyURLModified = IsCompanyURLModified;
        this.ApiKey = ApiKey;
        this.IsApiKeyModified = IsApiKeyModified;
        this.CompanyId = CompanyId;
        this.Companies = Companies;
        this.ContractorId = ContractorId;
        this.Contractors = Contractors;
        this.TaxCodeId = TaxCodeId;
        this.TaxCodes = TaxCodes;
        this.SimproConnectionDetail = SimproConnectionDetail;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.SimproConnectionDetailEdit.State", [], State, () => [["Id", class_type("System.Guid")], ["IsNewConnection", bool_type], ["IsDefaultOrgConnection", bool_type], ["Name", string_type], ["CompanyURL", string_type], ["IsCompanyURLModified", bool_type], ["ApiKey", string_type], ["IsApiKeyModified", bool_type], ["CompanyId", string_type], ["Companies", list_type(SimproLookup_$reflection())], ["ContractorId", string_type], ["Contractors", list_type(SimproLookup_$reflection())], ["TaxCodeId", string_type], ["TaxCodes", list_type(SimproLookup_$reflection())], ["SimproConnectionDetail", option_type(SimproConnectionDetail_$reflection())], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetIsDefaultOrgConnection", "SetName", "SetCompanyURL", "SetCompanyURLModified", "SetApiKey", "SetApiKeyModified", "SetCompanyId", "SetContractorId", "SetTaxCodeId", "GetSimproConnectionDetailResponse", "GetSimproSetupDetails", "GetSimproSetupDetailsResponse", "SaveSimproConnectionDetail", "SaveSimproConnectionDetailResponse", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.SimproConnectionDetailEdit.Msg", [], Msg, () => [[["Item", bool_type]], [["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [option_type(SimproConnectionDetail_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", option_type(SimproConnectionDetail_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(list_type(SimproLookup_$reflection()), list_type(SimproLookup_$reflection()), list_type(SimproLookup_$reflection())), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", tuple_type(list_type(SimproLookup_$reflection()), list_type(SimproLookup_$reflection()), list_type(SimproLookup_$reflection()))]], [["ErrorValue", list_type(string_type)]]])]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [option_type(class_type("System.Guid")), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", option_type(class_type("System.Guid"))]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Exception")]]]);
}

export function getSimproConnectionDetailCmd(simproConnectionDetailId) {
    return cmdOfAsync((Item) => (new Msg(9, [Item])), (Item_1) => (new Msg(14, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_1;
        const value_1 = Guid_parse(simproConnectionDetailId);
        asyncResult_1 = singleton.Return(value_1);
        return singleton.Bind(asyncResult_1, (input_1) => {
            const input_2 = input_1;
            if (input_2.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
            }
            else {
                return getSimproConnectionDetail(appRoot.SimproService, AppRootModule_getSelectedOrgId(), input_2.fields[0]);
            }
        });
    }));
}

export function getSimproSetupDetailsCmd(state) {
    return cmdOfAsync((Item) => (new Msg(11, [Item])), (Item_1) => (new Msg(14, [Item_1])), () => singleton.Delay(() => {
        let input_1, input_3, input_5;
        const simproSetupDetail = new SimproSetupDetail(new SimproApiKey(state.ApiKey), state.CompanyURL, (input_1 = Option_ofString(state.CompanyId), (input_1 == null) ? undefined : parse(input_1, 511, false, 32)), (input_3 = Option_ofString(state.ContractorId), (input_3 == null) ? undefined : parse(input_3, 511, false, 32)), (input_5 = Option_ofString(state.TaxCodeId), (input_5 == null) ? undefined : parse(input_5, 511, false, 32)));
        return getSimproSetupDetails(appRoot.SimproService, AppRootModule_getSelectedOrgId(), simproSetupDetail);
    }));
}

export function saveSimproConnectionDetailCmd(state) {
    return cmdOfAsync((Item) => (new Msg(13, [Item])), (Item_1) => (new Msg(14, [Item_1])), () => singleton.Delay(() => {
        let asyncResult;
        let value_1_1;
        if (state.IsNewConnection) {
            value_1_1 = (new FSharpResult$2(0, [new SimproConnectionDetail_1("00000000-0000-0000-0000-000000000000", state.IsDefaultOrgConnection, state.Name, AppRootModule_getSelectedOrgId(), state.CompanyURL, state.ApiKey, parse(state.CompanyId, 511, false, 32), state.Companies, parse(state.ContractorId, 511, false, 32), state.Contractors, parse(state.TaxCodeId, 511, false, 32), state.TaxCodes)]));
        }
        else {
            const option_1 = state.SimproConnectionDetail;
            value_1_1 = ((option_1 == null) ? (new FSharpResult$2(1, [singleton_1("Simpro connection detail not found.")])) : (new FSharpResult$2(0, [option_1])));
        }
        asyncResult = singleton.Return(value_1_1);
        return singleton.Bind(asyncResult, (input_1_2) => {
            const input_10 = input_1_2;
            if (input_10.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_10.fields[0]]));
            }
            else {
                const simproConnectionDetail = input_10.fields[0];
                const updatedSimproConnectionDetail = new SimproConnectionDetail_1(simproConnectionDetail.Id, state.IsDefaultOrgConnection, state.Name, simproConnectionDetail.OrganisationId, state.CompanyURL, state.ApiKey, parse(state.CompanyId, 511, false, 32), state.Companies, parse(state.ContractorId, 511, false, 32), state.Contractors, parse(state.TaxCodeId, 511, false, 32), state.TaxCodes);
                if (state.IsNewConnection) {
                    const x_1 = addSimproConnectionDetail(appRoot.SimproService, AppRootModule_getSelectedOrgId(), updatedSimproConnectionDetail);
                    return singleton.Bind(x_1, (x$0027) => {
                        let value_8;
                        const input_2 = x$0027;
                        value_8 = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [input_2.fields[0]])));
                        return singleton.Return(value_8);
                    });
                }
                else {
                    const x_3 = updateSimproConnectionDetail(appRoot.SimproService, AppRootModule_getSelectedOrgId(), updatedSimproConnectionDetail);
                    return singleton.Bind(x_3, (x$0027_1) => {
                        let value_10;
                        const input_6 = x$0027_1;
                        value_10 = ((input_6.tag === 1) ? (new FSharpResult$2(1, [input_6.fields[0]])) : (new FSharpResult$2(0, [undefined])));
                        return singleton.Return(value_10);
                    });
                }
            }
        });
    }));
}

export function init(simproConnectionDetailId) {
    const isNewConnection = isNullOrEmpty(simproConnectionDetailId);
    return [new State("00000000-0000-0000-0000-000000000000", isNewConnection, false, "", "", false, "", false, "", empty(), "", empty(), "", empty(), undefined, isNewConnection ? (new Busy(0, [])) : (new Busy(1, [])), empty()), isNewConnection ? Cmd_none() : getSimproConnectionDetailCmd(simproConnectionDetailId)];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [new State(state.Id, state.IsNewConnection, state.IsDefaultOrgConnection, msg.fields[0], state.CompanyURL, state.IsCompanyURLModified, state.ApiKey, state.IsApiKeyModified, state.CompanyId, state.Companies, state.ContractorId, state.Contractors, state.TaxCodeId, state.TaxCodes, state.SimproConnectionDetail, state.IsBusy, state.Errors), Cmd_none()];
        case 2:
            return [new State(state.Id, state.IsNewConnection, state.IsDefaultOrgConnection, state.Name, msg.fields[0], true, state.ApiKey, state.IsApiKeyModified, state.CompanyId, state.Companies, state.ContractorId, state.Contractors, state.TaxCodeId, state.TaxCodes, state.SimproConnectionDetail, state.IsBusy, state.Errors), Cmd_none()];
        case 3:
            return [new State(state.Id, state.IsNewConnection, state.IsDefaultOrgConnection, state.Name, state.CompanyURL, msg.fields[0], state.ApiKey, state.IsApiKeyModified, state.CompanyId, state.Companies, state.ContractorId, state.Contractors, state.TaxCodeId, state.TaxCodes, state.SimproConnectionDetail, state.IsBusy, state.Errors), Cmd_none()];
        case 4:
            return [new State(state.Id, state.IsNewConnection, state.IsDefaultOrgConnection, state.Name, state.CompanyURL, state.IsCompanyURLModified, msg.fields[0], true, state.CompanyId, state.Companies, state.ContractorId, state.Contractors, state.TaxCodeId, state.TaxCodes, state.SimproConnectionDetail, state.IsBusy, state.Errors), Cmd_none()];
        case 5:
            return [new State(state.Id, state.IsNewConnection, state.IsDefaultOrgConnection, state.Name, state.CompanyURL, state.IsCompanyURLModified, state.ApiKey, msg.fields[0], state.CompanyId, state.Companies, state.ContractorId, state.Contractors, state.TaxCodeId, state.TaxCodes, state.SimproConnectionDetail, state.IsBusy, state.Errors), Cmd_none()];
        case 6: {
            const updatedState = new State(state.Id, state.IsNewConnection, state.IsDefaultOrgConnection, state.Name, state.CompanyURL, state.IsCompanyURLModified, state.ApiKey, state.IsApiKeyModified, msg.fields[0], state.Companies, state.ContractorId, state.Contractors, state.TaxCodeId, state.TaxCodes, state.SimproConnectionDetail, state.IsBusy, state.Errors);
            return [updatedState, getSimproSetupDetailsCmd(updatedState)];
        }
        case 7:
            return [new State(state.Id, state.IsNewConnection, state.IsDefaultOrgConnection, state.Name, state.CompanyURL, state.IsCompanyURLModified, state.ApiKey, state.IsApiKeyModified, state.CompanyId, state.Companies, msg.fields[0], state.Contractors, state.TaxCodeId, state.TaxCodes, state.SimproConnectionDetail, state.IsBusy, state.Errors), Cmd_none()];
        case 8:
            return [new State(state.Id, state.IsNewConnection, state.IsDefaultOrgConnection, state.Name, state.CompanyURL, state.IsCompanyURLModified, state.ApiKey, state.IsApiKeyModified, state.CompanyId, state.Companies, state.ContractorId, state.Contractors, msg.fields[0], state.TaxCodes, state.SimproConnectionDetail, state.IsBusy, state.Errors), Cmd_none()];
        case 10:
            if (isNullOrEmpty(state.CompanyURL) ? true : isNullOrEmpty(state.ApiKey)) {
                return [state, Cmd_none()];
            }
            else {
                return [state, getSimproSetupDetailsCmd(state)];
            }
        case 9:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Id, state.IsNewConnection, state.IsDefaultOrgConnection, state.Name, state.CompanyURL, state.IsCompanyURLModified, state.ApiKey, state.IsApiKeyModified, state.CompanyId, state.Companies, state.ContractorId, state.Contractors, state.TaxCodeId, state.TaxCodes, state.SimproConnectionDetail, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else if (msg.fields[0].fields[0] == null) {
                return [new State(state.Id, state.IsNewConnection, state.IsDefaultOrgConnection, state.Name, state.CompanyURL, state.IsCompanyURLModified, state.ApiKey, state.IsApiKeyModified, state.CompanyId, state.Companies, state.ContractorId, state.Contractors, state.TaxCodeId, state.TaxCodes, state.SimproConnectionDetail, new Busy(0, []), state.Errors), Cmd_none()];
            }
            else {
                const x_9 = msg.fields[0].fields[0];
                return [new State(state.Id, state.IsNewConnection, x_9.IsDefaultOrgConnection, x_9.Name, x_9.CompanyURL, state.IsCompanyURLModified, x_9.ApiKey, state.IsApiKeyModified, int32ToString(x_9.CompanyId), cons(SimproLookupModule_notSelected, x_9.Companies), int32ToString(x_9.ContractorId), cons(SimproLookupModule_notSelected, x_9.Contractors), int32ToString(x_9.TaxCodeId), cons(SimproLookupModule_notSelected, x_9.TaxCodes), x_9, new Busy(0, []), state.Errors), Cmd_none()];
            }
        case 11:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Id, state.IsNewConnection, state.IsDefaultOrgConnection, state.Name, state.CompanyURL, state.IsCompanyURLModified, state.ApiKey, state.IsApiKeyModified, state.CompanyId, state.Companies, state.ContractorId, state.Contractors, state.TaxCodeId, state.TaxCodes, state.SimproConnectionDetail, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.Id, state.IsNewConnection, state.IsDefaultOrgConnection, state.Name, state.CompanyURL, state.IsCompanyURLModified, state.ApiKey, state.IsApiKeyModified, state.CompanyId, cons(SimproLookupModule_notSelected, msg.fields[0].fields[0][0]), state.ContractorId, cons(SimproLookupModule_notSelected, msg.fields[0].fields[0][1]), state.TaxCodeId, cons(SimproLookupModule_notSelected, msg.fields[0].fields[0][2]), state.SimproConnectionDetail, state.IsBusy, state.Errors), Cmd_none()];
            }
        case 12: {
            const updatedState_1 = new State(state.Id, state.IsNewConnection, state.IsDefaultOrgConnection, state.Name, state.CompanyURL, state.IsCompanyURLModified, state.ApiKey, state.IsApiKeyModified, state.CompanyId, state.Companies, state.ContractorId, state.Contractors, state.TaxCodeId, state.TaxCodes, state.SimproConnectionDetail, new Busy(2, []), state.Errors);
            return [updatedState_1, saveSimproConnectionDetailCmd(updatedState_1)];
        }
        case 13:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Id, state.IsNewConnection, state.IsDefaultOrgConnection, state.Name, state.CompanyURL, state.IsCompanyURLModified, state.ApiKey, state.IsApiKeyModified, state.CompanyId, state.Companies, state.ContractorId, state.Contractors, state.TaxCodeId, state.TaxCodes, state.SimproConnectionDetail, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else if (msg.fields[0].fields[0] == null) {
                return [new State(state.Id, state.IsNewConnection, state.IsDefaultOrgConnection, state.Name, state.CompanyURL, state.IsCompanyURLModified, state.ApiKey, state.IsApiKeyModified, state.CompanyId, state.Companies, state.ContractorId, state.Contractors, state.TaxCodeId, state.TaxCodes, state.SimproConnectionDetail, new Busy(0, []), state.Errors), Cmd_none()];
            }
            else {
                const x_12 = msg.fields[0].fields[0];
                return [new State(x_12, false, state.IsDefaultOrgConnection, state.Name, state.CompanyURL, state.IsCompanyURLModified, state.ApiKey, state.IsApiKeyModified, state.CompanyId, state.Companies, state.ContractorId, state.Contractors, state.TaxCodeId, state.TaxCodes, state.SimproConnectionDetail, new Busy(0, []), state.Errors), Cmd_none()];
            }
        case 14:
            return [new State(state.Id, state.IsNewConnection, state.IsDefaultOrgConnection, state.Name, state.CompanyURL, state.IsCompanyURLModified, state.ApiKey, state.IsApiKeyModified, state.CompanyId, state.Companies, state.ContractorId, state.Contractors, state.TaxCodeId, state.TaxCodes, state.SimproConnectionDetail, new Busy(0, []), singleton_1(msg.fields[0].message)), Cmd_none()];
        default:
            return [new State(state.Id, state.IsNewConnection, msg.fields[0], state.Name, state.CompanyURL, state.IsCompanyURLModified, state.ApiKey, state.IsApiKeyModified, state.CompanyId, state.Companies, state.ContractorId, state.Contractors, state.TaxCodeId, state.TaxCodes, state.SimproConnectionDetail, state.IsBusy, state.Errors), Cmd_none()];
    }
}

export function IndexView(indexViewInputProps) {
    let children, children_2, elems_15, children_7, value_14, children_10, children_12, elems_7, children_15, elems_4, value_68, value_76, elems_5, elems_10, elems_9, elems_8, children_22, elems_11, children_26, elems_13;
    const simproConnectionDetailId = indexViewInputProps.simproConnectionDetailId;
    let patternInput;
    const init_1 = init(simproConnectionDetailId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const isSaving = BusyModule_isSaving(state_1.IsBusy);
    return container([(children = singleton_1(Daisy_h4((`Simpro connection details - ${state_1.CompanyURL}`).trim())), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    })), Daisy_error(state_1.Errors), (children_2 = singleton_1(createElement(Daisy_progressState, {
        busy: state_1.IsBusy,
    })), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), createElement("button", createObj(Helpers_combineClasses("btn", toList(delay(() => append(BusyModule_isSaving(state_1.IsBusy) ? singleton_2(["className", "loading"]) : empty_1(), delay(() => append(singleton_2(["className", join(" ", ["my-4"])]), delay(() => append(singleton_2(["className", "btn-primary"]), delay(() => append(singleton_2(["className", "btn-sm"]), delay(() => append(singleton_2(["className", join(" ", ["mr-4"])]), delay(() => append(singleton_2(["children", "Save"]), delay(() => singleton_2(["onClick", (_arg_4) => {
        dispatch(new Msg(12, []));
    }])))))))))))))))))), createElement("div", createObj(Helpers_combineClasses("divider", empty()))), createElement("div", createObj(singleton_1((elems_15 = [(children_7 = ofArray([createElement("label", createObj(Helpers_combineClasses("label", singleton_1((value_14 = "Default Organisation Connection (Contractor Work orders for default Simpro connection will use hourly rate otherwise charge-out rate will be used.)", ["children", value_14]))))), createElement("input", createObj(Helpers_combineClasses("checkbox", ofArray([["type", "checkbox"], ["checked", state_1.IsDefaultOrgConnection], ["onChange", (ev) => {
        dispatch(new Msg(0, [ev.target.checked]));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_7)),
    })), (children_10 = ofArray([createElement("label", createObj(Helpers_combineClasses("label", singleton_1(["children", "Name"])))), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", state_1.Name], ["placeholder", "Name"], ["readOnly", isSaving], ["onChange", (ev_1) => {
        dispatch(new Msg(1, [ev_1.target.value]));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_10)),
    })), (children_12 = ofArray([Daisy_labelWithTooltip("Company URL", "This is the first part of the Simpro company URL, e.g. for `https://10thdoor.simprosuite.com` enter `10thdoor` here", "fa fa-solid fa-info-circle"), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", state_1.CompanyURL], ["placeholder", "Company URL"], ["readOnly", isSaving], ["onChange", (ev_2) => {
        dispatch(new Msg(2, [ev_2.target.value]));
    }], ["onFocus", (_arg_5) => {
        dispatch(new Msg(3, [false]));
    }], ["onBlur", (_arg_6) => {
        if (state_1.IsCompanyURLModified) {
            dispatch(new Msg(10, []));
        }
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_12)),
    })), createElement("div", createObj(ofArray([["className", "mt-4"], (elems_7 = [(children_15 = ofArray([Daisy_h6("To generate a new API key in `Simpro`:"), createElement("span", {
        className: "label-text",
        children: "Ask someone with administrative privileges to add a new Rest API Application.",
    }), createElement("ol", createObj(ofArray([["className", "list-decimal ml-4"], (elems_4 = [createElement("li", {
        children: "Click on System -> Setup",
    }), createElement("li", createObj(singleton_1((value_68 = "API should be near the bottom. If it is not available, then they may not be an administrator. Hover over it and click Applications.", ["children", value_68])))), createElement("li", {
        children: "Click Create Application",
    }), createElement("li", {
        children: "Set Company to `10thDoor`.",
    }), createElement("li", {
        children: "Set Name to `WorkMate`.",
    }), createElement("li", createObj(singleton_1((value_76 = "Select `API Key` for Authentication Method, copy the Access Token shown and paste it into the `API Key` field below.", ["children", value_76])))), createElement("li", {
        children: "Click on Create button (in Simpro) to save the Application.",
    })], ["children", reactApi.Children.toArray(Array.from(elems_4))])]))), Daisy_labelWithTooltip("API Key", "This is the API key for the Simpro company. To generate this key, go to the Simpro company settings and generate a new API key.", "fa fa-solid fa-info-circle"), createElement("div", createObj(ofArray([["className", "grid grid-cols-2 gap-4"], (elems_5 = [createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", state_1.ApiKey], ["placeholder", "API Key"], ["readOnly", isSaving], ["onChange", (ev_3) => {
        dispatch(new Msg(4, [ev_3.target.value]));
    }], ["onFocus", (_arg_7) => {
        dispatch(new Msg(5, [false]));
    }], ["onBlur", (_arg_8) => {
        if (state_1.IsApiKeyModified) {
            dispatch(new Msg(10, []));
        }
    }]]))))], ["children", reactApi.Children.toArray(Array.from(elems_5))])])))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_15)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_7))])]))), createElement("div", createObj(Helpers_combineClasses("form-control", ofArray([["className", join(" ", ["mt-4"])], (elems_10 = [createElement("p", {
        children: "Choose a company, save it, then select a contractor and tax code to set up Simpro.",
    }), createElement("label", createObj(Helpers_combineClasses("label", singleton_1(["children", "Simpro Company"])))), createElement("div", createObj(ofArray([["className", "grid grid-cols-2 gap-4"], (elems_9 = [createElement("select", createObj(Helpers_combineClasses("select", ofArray([["className", "select-bordered"], ["className", "select-sm"], ["placeholder", "Simpro Company"], ["readOnly", isSaving], ["value", state_1.CompanyId], ["onChange", (ev_4) => {
        dispatch(new Msg(6, [ev_4.target.value]));
    }], (elems_8 = toList(delay(() => map((company) => createElement("option", {
        value: company.Id,
        children: company.Name,
    }), state_1.Companies))), ["children", reactApi.Children.toArray(Array.from(elems_8))])]))))], ["children", reactApi.Children.toArray(Array.from(elems_9))])])))], ["children", reactApi.Children.toArray(Array.from(elems_10))])])))), (children_22 = ofArray([createElement("label", createObj(Helpers_combineClasses("label", singleton_1(["children", "Simpro Contractor"])))), createElement("select", createObj(Helpers_combineClasses("select", ofArray([["className", "select-bordered"], ["className", "select-sm"], ["placeholder", "Simpro Contractor"], ["readOnly", isSaving], ["value", state_1.ContractorId], ["onChange", (ev_5) => {
        dispatch(new Msg(7, [ev_5.target.value]));
    }], (elems_11 = toList(delay(() => map((contractor) => createElement("option", {
        value: contractor.Id,
        children: contractor.Name,
    }), state_1.Contractors))), ["children", reactApi.Children.toArray(Array.from(elems_11))])]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_22)),
    })), (children_26 = ofArray([createElement("label", createObj(Helpers_combineClasses("label", singleton_1(["children", "Simpro Tax Code"])))), createElement("select", createObj(Helpers_combineClasses("select", ofArray([["className", "select-bordered"], ["className", "select-sm"], ["placeholder", "Simpro Tax Code"], ["readOnly", isSaving], ["value", state_1.TaxCodeId], ["onChange", (ev_6) => {
        dispatch(new Msg(8, [ev_6.target.value]));
    }], (elems_13 = toList(delay(() => map((taxCode) => createElement("option", {
        value: taxCode.Id,
        children: taxCode.Name,
    }), state_1.TaxCodes))), ["children", reactApi.Children.toArray(Array.from(elems_13))])]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_26)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_15))]))))]);
}

