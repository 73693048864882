import firebase from "firebase/compat/app";
import "firebase/compat/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyAipbVbdkcO8frkDigID2gE928P9CfvJSA",
    authDomain: "thdoor-de17a.firebaseapp.com",
    projectId: "thdoor-de17a",
    storageBucket: "thdoor-de17a.firebasestorage.app",
    messagingSenderId: "203660332750",
    appId: "1:203660332750:web:d380b4a10779d94e134a9f",
    measurementId: "G-CX924NMSH4"
  };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

// Initialize Analytics and get a reference to the service
const analytics = firebase.analytics();

export const logInfo = (message) => {
    analytics.logEvent('information', { message: message });
}