import { Record } from "../../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { list_type, record_type, decimal_type, option_type, string_type, enum_type, int32_type, class_type } from "../../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Location_$reflection } from "../../Entity/Location.fs.js";
import { VehicleLogSummaryByTag, VehicleLogSummaryByVehicle, FuelSummary, VehicleLog, LogPoint, FuelUnit_$reflection, FuelType_$reflection, VehicleLogTag_$reflection } from "../../Entity/LogMaster.fs.js";
import { DistanceUnit_$reflection } from "../../Entity/MeasurementUnits.fs.js";
import { FSharpResult$2 } from "../../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Result.js";
import { Result_traverse } from "../../../Util.fs.js";
import { map } from "../../../../Logos.Client/fable_modules/fable-library-js.4.19.3/List.js";

export class LogPointDto extends Record {
    constructor(Id, LogPointType, Note, DateTime, EndDateTime, Odo, Location, LogPointAutoFillStage, FuelQuantityFilled, FuelPriceTotal) {
        super();
        this.Id = Id;
        this.LogPointType = (LogPointType | 0);
        this.Note = Note;
        this.DateTime = DateTime;
        this.EndDateTime = EndDateTime;
        this.Odo = Odo;
        this.Location = Location;
        this.LogPointAutoFillStage = (LogPointAutoFillStage | 0);
        this.FuelQuantityFilled = FuelQuantityFilled;
        this.FuelPriceTotal = FuelPriceTotal;
    }
}

export function LogPointDto_$reflection() {
    return record_type("Domain.Dto.LogMaster.LogPointDto", [], LogPointDto, () => [["Id", class_type("System.Guid")], ["LogPointType", enum_type("Domain.Entity.LogMaster.LogPointType", int32_type, [["Start", 0], ["Stop", 1], ["End", 2], ["FuelStop", 3]])], ["Note", string_type], ["DateTime", class_type("System.DateTime")], ["EndDateTime", option_type(class_type("System.DateTime"))], ["Odo", decimal_type], ["Location", Location_$reflection()], ["LogPointAutoFillStage", enum_type("Domain.Entity.LogMaster.LogPointAutoFillStage", int32_type, [["Initial", 0], ["Started", 1], ["Ended", 2]])], ["FuelQuantityFilled", decimal_type], ["FuelPriceTotal", decimal_type]]);
}

export class VehicleLogDto extends Record {
    constructor(Id, UserId, OrgId, ServerUpdated, VehicleId, Notes, status, Updated, Tag, DistanceUnit, FuelType, FuelUnit, LogPoints) {
        super();
        this.Id = Id;
        this.UserId = UserId;
        this.OrgId = OrgId;
        this.ServerUpdated = ServerUpdated;
        this.VehicleId = VehicleId;
        this.Notes = Notes;
        this.status = (status | 0);
        this.Updated = Updated;
        this.Tag = Tag;
        this.DistanceUnit = DistanceUnit;
        this.FuelType = FuelType;
        this.FuelUnit = FuelUnit;
        this.LogPoints = LogPoints;
    }
}

export function VehicleLogDto_$reflection() {
    return record_type("Domain.Dto.LogMaster.VehicleLogDto", [], VehicleLogDto, () => [["Id", class_type("System.Guid")], ["UserId", class_type("System.Guid")], ["OrgId", class_type("System.Guid")], ["ServerUpdated", class_type("System.DateTime")], ["VehicleId", class_type("System.Guid")], ["Notes", string_type], ["status", enum_type("Domain.Entity.LogMaster.VehicleLogStatus", int32_type, [["Open", 0], ["Finished", 1], ["Discarded", 2]])], ["Updated", class_type("System.DateTime")], ["Tag", VehicleLogTag_$reflection()], ["DistanceUnit", DistanceUnit_$reflection()], ["FuelType", FuelType_$reflection()], ["FuelUnit", FuelUnit_$reflection()], ["LogPoints", list_type(LogPointDto_$reflection())]]);
}

export function LogPointDtoModule_ofLogPoint(logPoint) {
    return new FSharpResult$2(0, [new LogPointDto(logPoint.Id, logPoint.LogPointType, logPoint.Note, logPoint.DateTime, logPoint.EndDateTime, logPoint.Odo, logPoint.Location, logPoint.LogPointAutoFillStage, logPoint.FuelQuantityFilled, logPoint.FuelPriceTotal)]);
}

export function LogPointDtoModule_toLogPoint(logPoint) {
    return new FSharpResult$2(0, [new LogPoint(logPoint.Id, logPoint.LogPointType, logPoint.Note, logPoint.DateTime, logPoint.EndDateTime, logPoint.Odo, logPoint.Location, logPoint.LogPointAutoFillStage, logPoint.FuelQuantityFilled, logPoint.FuelPriceTotal)]);
}

export function VehicleLogDtoModule_ofVehicleLog(vehicleLog) {
    const input = Result_traverse(map(LogPointDtoModule_ofLogPoint, vehicleLog.LogPoints));
    if (input.tag === 1) {
        return new FSharpResult$2(1, [input.fields[0]]);
    }
    else {
        return new FSharpResult$2(0, [new VehicleLogDto(vehicleLog.Id, vehicleLog.UserId, vehicleLog.OrgId, vehicleLog.ServerUpdated, vehicleLog.VehicleId, vehicleLog.Notes, vehicleLog.status, vehicleLog.Updated, vehicleLog.Tag, vehicleLog.DistanceUnit, vehicleLog.FuelType, vehicleLog.FuelUnit, input.fields[0])]);
    }
}

export function VehicleLogDtoModule_toVehicleLog(vehicleLog, userId, orgId) {
    const input = Result_traverse(map(LogPointDtoModule_toLogPoint, vehicleLog.LogPoints));
    if (input.tag === 1) {
        return new FSharpResult$2(1, [input.fields[0]]);
    }
    else {
        return new FSharpResult$2(0, [new VehicleLog(vehicleLog.Id, vehicleLog.UserId, vehicleLog.OrgId, vehicleLog.ServerUpdated, vehicleLog.VehicleId, vehicleLog.Notes, vehicleLog.status, vehicleLog.Updated, vehicleLog.Tag, vehicleLog.DistanceUnit, vehicleLog.FuelType, vehicleLog.FuelUnit, input.fields[0])]);
    }
}

export class FuelSummaryDto extends Record {
    constructor(Vehicle, FuelQtyTotal, FuelAmountTotal, FuelType, FuelUnit) {
        super();
        this.Vehicle = Vehicle;
        this.FuelQtyTotal = FuelQtyTotal;
        this.FuelAmountTotal = FuelAmountTotal;
        this.FuelType = FuelType;
        this.FuelUnit = FuelUnit;
    }
}

export function FuelSummaryDto_$reflection() {
    return record_type("Domain.Dto.LogMaster.FuelSummaryDto", [], FuelSummaryDto, () => [["Vehicle", string_type], ["FuelQtyTotal", decimal_type], ["FuelAmountTotal", decimal_type], ["FuelType", FuelType_$reflection()], ["FuelUnit", FuelUnit_$reflection()]]);
}

export function FuelSummaryDtoModule_ofFuelSummary(fuelSummary) {
    return new FSharpResult$2(0, [new FuelSummaryDto(fuelSummary.Vehicle, fuelSummary.FuelQtyTotal, fuelSummary.FuelAmountTotal, fuelSummary.FuelType, fuelSummary.FuelUnit)]);
}

export function FuelSummaryDtoModule_toFuelSummary(fuelSummary) {
    return new FSharpResult$2(0, [new FuelSummary(fuelSummary.Vehicle, fuelSummary.FuelQtyTotal, fuelSummary.FuelAmountTotal, fuelSummary.FuelType, fuelSummary.FuelUnit)]);
}

export class VehicleLogSummaryByVehicleDto extends Record {
    constructor(Vehicle, TripCount, TotalTripDuration, TotalDistanceTraveled, DistanceUnit) {
        super();
        this.Vehicle = Vehicle;
        this.TripCount = (TripCount | 0);
        this.TotalTripDuration = TotalTripDuration;
        this.TotalDistanceTraveled = TotalDistanceTraveled;
        this.DistanceUnit = DistanceUnit;
    }
}

export function VehicleLogSummaryByVehicleDto_$reflection() {
    return record_type("Domain.Dto.LogMaster.VehicleLogSummaryByVehicleDto", [], VehicleLogSummaryByVehicleDto, () => [["Vehicle", string_type], ["TripCount", int32_type], ["TotalTripDuration", class_type("System.TimeSpan")], ["TotalDistanceTraveled", decimal_type], ["DistanceUnit", DistanceUnit_$reflection()]]);
}

export function VehicleLogSummaryByVehicleDtoModule_ofVehicleLogSummary(vehicleLogSummary) {
    return new FSharpResult$2(0, [new VehicleLogSummaryByVehicleDto(vehicleLogSummary.Vehicle, vehicleLogSummary.TripCount, vehicleLogSummary.TotalTripDuration, vehicleLogSummary.TotalDistanceTraveled, vehicleLogSummary.DistanceUnit)]);
}

export function VehicleLogSummaryByVehicleDtoModule_toVehicleLogSummary(vehicleLogSummary) {
    return new FSharpResult$2(0, [new VehicleLogSummaryByVehicle(vehicleLogSummary.Vehicle, vehicleLogSummary.TripCount, vehicleLogSummary.TotalTripDuration, vehicleLogSummary.TotalDistanceTraveled, vehicleLogSummary.DistanceUnit)]);
}

export class VehicleLogSummaryByTagDto extends Record {
    constructor(Tag, TripCount, TotalTripDuration, TotalDistanceTraveled, DistanceUnit) {
        super();
        this.Tag = Tag;
        this.TripCount = (TripCount | 0);
        this.TotalTripDuration = TotalTripDuration;
        this.TotalDistanceTraveled = TotalDistanceTraveled;
        this.DistanceUnit = DistanceUnit;
    }
}

export function VehicleLogSummaryByTagDto_$reflection() {
    return record_type("Domain.Dto.LogMaster.VehicleLogSummaryByTagDto", [], VehicleLogSummaryByTagDto, () => [["Tag", VehicleLogTag_$reflection()], ["TripCount", int32_type], ["TotalTripDuration", class_type("System.TimeSpan")], ["TotalDistanceTraveled", decimal_type], ["DistanceUnit", DistanceUnit_$reflection()]]);
}

export function VehicleLogSummaryByTagDtoModule_ofVehicleLogSummary(vehicleLogSummary) {
    return new FSharpResult$2(0, [new VehicleLogSummaryByTagDto(vehicleLogSummary.Tag, vehicleLogSummary.TripCount, vehicleLogSummary.TotalTripDuration, vehicleLogSummary.TotalDistanceTraveled, vehicleLogSummary.DistanceUnit)]);
}

export function VehicleLogSummaryByTagDtoModule_toVehicleLogSummary(vehicleLogSummary) {
    return new FSharpResult$2(0, [new VehicleLogSummaryByTag(vehicleLogSummary.Tag, vehicleLogSummary.TripCount, vehicleLogSummary.TotalTripDuration, vehicleLogSummary.TotalDistanceTraveled, vehicleLogSummary.DistanceUnit)]);
}

