import { singleton } from "../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library-js.4.19.3/Result.js";
import { map } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { HolidayDtoModule_ofHoliday, HolidayDtoModule_toHoliday } from "../../../Logos.Shared/Domain/Dto/Shift.fs.js";

export function searchHolidays(holidayService, selectedOrgId, searchText, pagination) {
    return singleton.Delay(() => {
        const input = holidayService.SearchHolidays(selectedOrgId, searchText, pagination);
        return singleton.Bind(input, (x$0027) => {
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [map(HolidayDtoModule_toHoliday, input_2.fields[0])])));
            return singleton.Return(value);
        });
    });
}

export function getHoliday(holidayService, selectedOrgId, holidayId) {
    return singleton.Delay(() => {
        const input_2 = holidayService.GetHoliday(selectedOrgId, holidayId);
        return singleton.Bind(input_2, (x$0027) => {
            let input_1;
            let value;
            const input_4 = x$0027;
            value = ((input_4.tag === 1) ? (new FSharpResult$2(1, [input_4.fields[0]])) : (new FSharpResult$2(0, [(input_1 = input_4.fields[0], (input_1 == null) ? undefined : HolidayDtoModule_toHoliday(input_1))])));
            return singleton.Return(value);
        });
    });
}

export function upsertHoliday(holidayService, selectedOrgId, holiday) {
    return singleton.Delay(() => {
        const holiday_1 = HolidayDtoModule_ofHoliday(holiday);
        return holidayService.UpsertHoliday(selectedOrgId, holiday_1);
    });
}

export function getHolidays(holidayService, selectedOrgId, startDate, finishDte) {
    return singleton.Delay(() => {
        const input = holidayService.GetHolidays(selectedOrgId, startDate, finishDte);
        return singleton.Bind(input, (x$0027) => {
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [map(HolidayDtoModule_toHoliday, input_2.fields[0])])));
            return singleton.Return(value);
        });
    });
}

