import { Record, Union } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { value as value_2, create, Phone_$reflection } from "./Phone.fs.js";
import { value as value_1, create as create_1, Email_$reflection } from "./Email.fs.js";
import { string_type, list_type, record_type, bool_type, option_type, class_type, union_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { FSharpResult$2 } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Result.js";
import { singleton } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/List.js";
import { Address_$reflection } from "./Address.fs.js";

export class ContactType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Phone", "Email"];
    }
}

export function ContactType_$reflection() {
    return union_type("Domain.Entity.Customer.ContactType", [], ContactType, () => [[["Item", Phone_$reflection()]], [["Item", Email_$reflection()]]]);
}

export function ContactTypeModule_getContact(contactType, value) {
    switch (contactType) {
        case 1: {
            const input_1 = create(value);
            if (input_1.tag === 1) {
                return new FSharpResult$2(1, [input_1.fields[0]]);
            }
            else {
                return new FSharpResult$2(0, [new ContactType(0, [input_1.fields[0]])]);
            }
        }
        case 2: {
            const input_3 = create_1(value);
            if (input_3.tag === 1) {
                return new FSharpResult$2(1, [input_3.fields[0]]);
            }
            else {
                return new FSharpResult$2(0, [new ContactType(1, [input_3.fields[0]])]);
            }
        }
        default:
            return new FSharpResult$2(1, [singleton("Invalid Contact type.")]);
    }
}

export function ContactTypeModule_toInt(_arg) {
    if (_arg.tag === 1) {
        return 2;
    }
    else {
        return 1;
    }
}

export class Contact extends Record {
    constructor(Id, ParentId, ContactType, IsActive) {
        super();
        this.Id = Id;
        this.ParentId = ParentId;
        this.ContactType = ContactType;
        this.IsActive = IsActive;
    }
}

export function Contact_$reflection() {
    return record_type("Domain.Entity.Customer.Contact", [], Contact, () => [["Id", option_type(class_type("System.Guid"))], ["ParentId", option_type(class_type("System.Guid"))], ["ContactType", ContactType_$reflection()], ["IsActive", bool_type]]);
}

export function ContactModule_typeName(contact) {
    if (contact.ContactType.tag === 1) {
        return "Email";
    }
    else {
        return "Phone";
    }
}

export function ContactModule_value(contact) {
    const matchValue = contact.ContactType;
    if (matchValue.tag === 1) {
        return value_1(matchValue.fields[0]);
    }
    else {
        return value_2(matchValue.fields[0]);
    }
}

export class CustomerGroup extends Record {
    constructor(Id, OrganisationId, Addresses, Contacts) {
        super();
        this.Id = Id;
        this.OrganisationId = OrganisationId;
        this.Addresses = Addresses;
        this.Contacts = Contacts;
    }
}

export function CustomerGroup_$reflection() {
    return record_type("Domain.Entity.Customer.CustomerGroup", [], CustomerGroup, () => [["Id", option_type(class_type("System.Guid"))], ["OrganisationId", option_type(class_type("System.Guid"))], ["Addresses", list_type(Address_$reflection())], ["Contacts", list_type(Contact_$reflection())]]);
}

export class CustomerMember extends Record {
    constructor(Id, FirstName, MiddleName, LastName) {
        super();
        this.Id = Id;
        this.FirstName = FirstName;
        this.MiddleName = MiddleName;
        this.LastName = LastName;
    }
}

export function CustomerMember_$reflection() {
    return record_type("Domain.Entity.Customer.CustomerMember", [], CustomerMember, () => [["Id", option_type(class_type("System.Guid"))], ["FirstName", string_type], ["MiddleName", option_type(string_type)], ["LastName", string_type]]);
}

export class Customer extends Record {
    constructor(Id, OrganisationId, CustomerGroupId, BusinessName, Title, FirstName, MiddleName, LastName, Addresses, Contacts, XeroCustomerId, IsActive) {
        super();
        this.Id = Id;
        this.OrganisationId = OrganisationId;
        this.CustomerGroupId = CustomerGroupId;
        this.BusinessName = BusinessName;
        this.Title = Title;
        this.FirstName = FirstName;
        this.MiddleName = MiddleName;
        this.LastName = LastName;
        this.Addresses = Addresses;
        this.Contacts = Contacts;
        this.XeroCustomerId = XeroCustomerId;
        this.IsActive = IsActive;
    }
}

export function Customer_$reflection() {
    return record_type("Domain.Entity.Customer.Customer", [], Customer, () => [["Id", option_type(class_type("System.Guid"))], ["OrganisationId", option_type(class_type("System.Guid"))], ["CustomerGroupId", option_type(class_type("System.Guid"))], ["BusinessName", option_type(string_type)], ["Title", option_type(string_type)], ["FirstName", string_type], ["MiddleName", option_type(string_type)], ["LastName", string_type], ["Addresses", list_type(Address_$reflection())], ["Contacts", list_type(Contact_$reflection())], ["XeroCustomerId", option_type(class_type("System.Guid"))], ["IsActive", bool_type]]);
}

export class XeroCustomer extends Record {
    constructor(Id, CustomerName) {
        super();
        this.Id = Id;
        this.CustomerName = CustomerName;
    }
}

export function XeroCustomer_$reflection() {
    return record_type("Domain.Entity.Customer.XeroCustomer", [], XeroCustomer, () => [["Id", class_type("System.Guid")], ["CustomerName", string_type]]);
}

export class CustomerDetail extends Record {
    constructor(Id, BusinessName) {
        super();
        this.Id = Id;
        this.BusinessName = BusinessName;
    }
}

export function CustomerDetail_$reflection() {
    return record_type("Domain.Entity.Customer.CustomerDetail", [], CustomerDetail, () => [["Id", class_type("System.Guid")], ["BusinessName", string_type]]);
}

