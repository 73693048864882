import { createElement } from "react";
import React from "react";
import * as react from "react";
import { indexView } from "./Widget.fs.js";
import { Daisy_progressState, Daisy_error } from "./Component.fs.js";
import { ofArray, empty, singleton } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { createObj } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.3/String.js";
import { isEmpty, singleton as singleton_1, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { maps } from "./Map.fs.js";

export function IndexViewEx(indexViewExInputProps) {
    let children, elems_1;
    const elements = indexViewExInputProps.elements;
    const markers = indexViewExInputProps.markers;
    const isBusy = indexViewExInputProps.isBusy;
    const errors = indexViewExInputProps.errors;
    const title = indexViewExInputProps.title;
    return createElement(indexView, {
        title: title,
        elements: [Daisy_error(errors), (children = singleton(createElement(Daisy_progressState, {
            busy: isBusy,
        })), createElement("div", {
            className: "form-control",
            children: reactApi.Children.toArray(Array.from(children)),
        })), createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-1", "gap-4"])], (elems_1 = toList(delay(() => append(singleton_1(maps(10, false, true, empty(), markers)), delay(() => (isEmpty(elements) ? singleton_1(react.createElement(react.Fragment, {})) : singleton_1(createElement("div", {
            children: reactApi.Children.toArray(Array.from(elements)),
        }))))))), ["children", reactApi.Children.toArray(Array.from(elems_1))])])))],
    });
}

