import { toString, Union, Record } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { class_type, union_type, unit_type, record_type, list_type, bool_type, string_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { signup, getOneTimeSignupCode } from "../../AppDomain/UseCase/Auth.fs.js";
import { appRoot } from "../../Infrastructure/AppRoot.fs.js";
import { ofArray, cons, isEmpty, singleton as singleton_1, empty } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Page, Cmd_navigateToPage } from "../Router.fs.js";
import { createElement } from "react";
import React from "react";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { createObj } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { empty as empty_1, singleton as singleton_2, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { Daisy_error } from "../Component/Component.fs.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../Component/ViewContainer.fs.js";

export class State extends Record {
    constructor(Username, UsernameCheckOk, Password, ConfirmPassword, OneTimeSignupCode, IsBusy, Error$) {
        super();
        this.Username = Username;
        this.UsernameCheckOk = UsernameCheckOk;
        this.Password = Password;
        this.ConfirmPassword = ConfirmPassword;
        this.OneTimeSignupCode = OneTimeSignupCode;
        this.IsBusy = IsBusy;
        this.Error = Error$;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.SignUp.State", [], State, () => [["Username", string_type], ["UsernameCheckOk", bool_type], ["Password", string_type], ["ConfirmPassword", string_type], ["OneTimeSignupCode", string_type], ["IsBusy", bool_type], ["Error", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetUsername", "CheckUsername", "CheckUsernameResponse", "SetOneTimeSignupCode", "SetPassword", "SetConfirmPassword", "Signup", "SignupResponse", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.SignUp.Msg", [], Msg, () => [[["Item", string_type]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Exception")]]]);
}

export function getOneTimeSignupCodeCmd(username) {
    return cmdOfAsync((Item) => (new Msg(2, [Item])), (Item_1) => (new Msg(8, [Item_1])), () => singleton.Delay(() => getOneTimeSignupCode(username, appRoot.AuthService)));
}

export function signupCmd(state) {
    return cmdOfAsync((Item) => (new Msg(7, [Item])), (Item_1) => (new Msg(8, [Item_1])), () => singleton.Delay(() => signup(state.Username, state.Password, state.ConfirmPassword, state.OneTimeSignupCode, appRoot.AuthService)));
}

export function update(msg, model) {
    switch (msg.tag) {
        case 1:
            return [new State(model.Username, model.UsernameCheckOk, model.Password, model.ConfirmPassword, model.OneTimeSignupCode, true, empty()), getOneTimeSignupCodeCmd(model.Username)];
        case 2:
            if (msg.fields[0].tag === 1) {
                return [new State(model.Username, model.UsernameCheckOk, model.Password, model.ConfirmPassword, model.OneTimeSignupCode, false, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(model.Username, true, model.Password, model.ConfirmPassword, model.OneTimeSignupCode, false, model.Error), Cmd_none()];
            }
        case 3:
            return [new State(model.Username, model.UsernameCheckOk, model.Password, model.ConfirmPassword, msg.fields[0], model.IsBusy, model.Error), Cmd_none()];
        case 4:
            return [new State(model.Username, model.UsernameCheckOk, msg.fields[0], model.ConfirmPassword, model.OneTimeSignupCode, model.IsBusy, model.Error), Cmd_none()];
        case 5:
            return [new State(model.Username, model.UsernameCheckOk, model.Password, msg.fields[0], model.OneTimeSignupCode, model.IsBusy, model.Error), Cmd_none()];
        case 6:
            return [new State(model.Username, model.UsernameCheckOk, model.Password, model.ConfirmPassword, model.OneTimeSignupCode, true, empty()), signupCmd(model)];
        case 7:
            if (msg.fields[0].tag === 1) {
                return [new State(model.Username, model.UsernameCheckOk, model.Password, model.ConfirmPassword, model.OneTimeSignupCode, false, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(model.Username, model.UsernameCheckOk, model.Password, model.ConfirmPassword, model.OneTimeSignupCode, false, model.Error), Cmd_navigateToPage(new Page(20, []))];
            }
        case 8:
            return [new State(model.Username, model.UsernameCheckOk, model.Password, model.ConfirmPassword, model.OneTimeSignupCode, false, singleton_1(toString(msg.fields[0]))), Cmd_none()];
        default:
            return [new State(msg.fields[0], model.UsernameCheckOk, model.Password, model.ConfirmPassword, model.OneTimeSignupCode, model.IsBusy, model.Error), Cmd_none()];
    }
}

export function usernameCheck(state, dispatch) {
    let children_3, children_1, children_6;
    const hasError = !isEmpty(state.Error);
    const children_8 = ofArray([(children_3 = ofArray([(children_1 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Email",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_1)),
    })), createElement("input", createObj(Helpers_combineClasses("input", cons(["type", "text"], toList(delay(() => append(hasError ? singleton_2(["className", "input-error"]) : singleton_2(["className", "input-bordered"]), delay(() => append(singleton_2(["autoFocus", true]), delay(() => append(singleton_2(["placeholder", "Email"]), delay(() => append(singleton_2(["readOnly", state.UsernameCheckOk ? true : state.IsBusy]), delay(() => singleton_2(["onChange", (ev) => {
        dispatch(new Msg(0, [ev.target.value]));
    }])))))))))))))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_3)),
    })), Daisy_error(state.Error), createElement("br", {}), (children_6 = singleton_1(createElement("button", createObj(Helpers_combineClasses("btn", toList(delay(() => append(singleton_2(["className", "btn-primary"]), delay(() => append(state.IsBusy ? singleton_2(["className", "loading"]) : empty_1(), delay(() => append(singleton_2(["children", "Next"]), delay(() => singleton_2(["onClick", (_arg) => {
        dispatch(new Msg(1, []));
    }]))))))))))))), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_6)),
    }))]);
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_8)),
    });
}

export function signupDetails(state, dispatch) {
    let children_3, children_1, children_8, children_6, children_13, children_11, children_18, children_16, children_21;
    const hasError = !isEmpty(state.Error);
    const children_23 = ofArray([(children_3 = ofArray([(children_1 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Username",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_1)),
    })), createElement("input", createObj(Helpers_combineClasses("input", cons(["type", "text"], toList(delay(() => append(hasError ? singleton_2(["className", "input-error"]) : singleton_2(["className", "input-bordered"]), delay(() => append(singleton_2(["placeholder", "Username"]), delay(() => append(singleton_2(["disabled", state.UsernameCheckOk]), delay(() => append(singleton_2(["readOnly", state.IsBusy]), delay(() => singleton_2(["onChange", (ev) => {
        dispatch(new Msg(0, [ev.target.value]));
    }])))))))))))))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_3)),
    })), (children_8 = ofArray([(children_6 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Sign up code",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_6)),
    })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["placeholder", "Sign up code"], ["readOnly", state.IsBusy], ["onChange", (ev_1) => {
        dispatch(new Msg(3, [ev_1.target.value]));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_8)),
    })), (children_13 = ofArray([(children_11 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Password",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_11)),
    })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["type", "password"], ["className", "input-bordered"], ["placeholder", "Password"], ["readOnly", state.IsBusy], ["onChange", (ev_2) => {
        dispatch(new Msg(4, [ev_2.target.value]));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_13)),
    })), (children_18 = ofArray([(children_16 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Confirm Password",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_16)),
    })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["type", "password"], ["className", "input-bordered"], ["placeholder", "Confirm Password"], ["readOnly", state.IsBusy], ["onChange", (ev_3) => {
        dispatch(new Msg(5, [ev_3.target.value]));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_18)),
    })), Daisy_error(state.Error), createElement("br", {}), (children_21 = singleton_1(createElement("button", createObj(Helpers_combineClasses("btn", toList(delay(() => append(state.IsBusy ? singleton_2(["className", "loading"]) : empty_1(), delay(() => append(singleton_2(["className", "btn-primary"]), delay(() => append(singleton_2(["children", "Sign up"]), delay(() => singleton_2(["onClick", (_arg) => {
        dispatch(new Msg(6, []));
    }]))))))))))))), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_21)),
    }))]);
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_23)),
    });
}

export function init() {
    return [new State("", false, "", "", "", false, empty()), Cmd_none()];
}

export function IndexView() {
    let elems;
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state = patternInput[0];
    const dispatch = patternInput[1];
    return container([createElement("div", createObj(ofArray([["className", "space-y-8 max-w-md mx-auto"], (elems = toList(delay(() => (state.UsernameCheckOk ? singleton_2(signupDetails(state, dispatch)) : singleton_2(usernameCheck(state, dispatch))))), ["children", reactApi.Children.toArray(Array.from(elems))])])))]);
}

