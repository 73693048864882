import { Cmd_OfAsync_start, Cmd_OfAsyncWith_either } from "../../fable_modules/Fable.Elmish.4.2.0/./cmd.fs.js";
import { Cmd_OfPromise_either, Cmd_OfFunc_either } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";

export function cmdOfAsync(ofSuccess, ofError, task) {
    return Cmd_OfAsyncWith_either((x) => {
        Cmd_OfAsync_start(x);
    }, task, undefined, ofSuccess, ofError);
}

export function cmdOfFunc(ofSuccess, ofError, task) {
    return Cmd_OfFunc_either(task, undefined, ofSuccess, ofError);
}

export function cmdOfPromise(ofSuccess, ofError, task) {
    return Cmd_OfPromise_either(task, undefined, ofSuccess, ofError);
}

