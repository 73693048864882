import { toString, Union, Record } from "../../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { FuelUnit, FuelUnitModule_toString, FuelSummary_$reflection, FuelType_$reflection } from "../../../../../Logos.Shared/Domain/Entity/LogMaster.fs.js";
import { union_type, string_type, list_type, class_type, record_type, decimal_type } from "../../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { MeasurementSystem_$reflection } from "../../../../../Logos.Shared/Domain/Entity/MeasurementUnits.fs.js";
import { Busy, Busy_$reflection } from "../../../SharedView.fs.js";
import { FSharpResult$2 } from "../../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { Result_traverse, AsyncResult_bindOfResult } from "../../../../../Logos.Shared/Util.fs.js";
import { toArray, ofArray, singleton as singleton_1, sumBy, empty, map } from "../../../../fable_modules/fable-library-js.4.19.3/List.js";
import { FuelSummaryDtoModule_toFuelSummary } from "../../../../../Logos.Shared/Domain/Dto/LogMaster/VehicleLog.fs.js";
import { getFuelSummary } from "../../../../AppDomain/UseCase/Vehicle.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../../Infrastructure/AppRoot.fs.js";
import { fromDateTime } from "../../../../fable_modules/fable-library-js.4.19.3/DateOnly.js";
import { toNumber, toString as toString_1, op_Addition, fromParts } from "../../../../fable_modules/fable-library-js.4.19.3/Decimal.js";
import Decimal from "../../../../fable_modules/fable-library-js.4.19.3/Decimal.js";
import { Cmd_none } from "../../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { List_groupBy } from "../../../../fable_modules/fable-library-js.4.19.3/Seq2.js";
import { createObj, safeHash, equals } from "../../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { createElement } from "react";
import React from "react";
import { Helpers_combineClasses } from "../../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { format, join } from "../../../../fable_modules/fable-library-js.4.19.3/String.js";
import { reactApi } from "../../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { map as map_1, delay, toList } from "../../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { doughnut } from "../../../Component/Doughnut.fs.js";
import { React_useElmish_Z6C327F2E } from "../../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { indexView } from "../../../Component/Widget.fs.js";

export class FuelTypeSummary extends Record {
    constructor(FuelType, TotalFuelUsed, TotalFuelCost) {
        super();
        this.FuelType = FuelType;
        this.TotalFuelUsed = TotalFuelUsed;
        this.TotalFuelCost = TotalFuelCost;
    }
}

export function FuelTypeSummary_$reflection() {
    return record_type("Presentation.Pages.LogMaster.FuelSummary.FuelTypeSummary", [], FuelTypeSummary, () => [["FuelType", FuelType_$reflection()], ["TotalFuelUsed", decimal_type], ["TotalFuelCost", decimal_type]]);
}

export class State extends Record {
    constructor(DateFrom, DateTo, FuelSummaries, FuelTypeSummary, TotalFuelUsed, TotalFuelCost, UserMeasurementSystem, IsBusy, Errors) {
        super();
        this.DateFrom = DateFrom;
        this.DateTo = DateTo;
        this.FuelSummaries = FuelSummaries;
        this.FuelTypeSummary = FuelTypeSummary;
        this.TotalFuelUsed = TotalFuelUsed;
        this.TotalFuelCost = TotalFuelCost;
        this.UserMeasurementSystem = UserMeasurementSystem;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.LogMaster.FuelSummary.State", [], State, () => [["DateFrom", class_type("System.DateOnly")], ["DateTo", class_type("System.DateOnly")], ["FuelSummaries", list_type(FuelSummary_$reflection())], ["FuelTypeSummary", list_type(FuelTypeSummary_$reflection())], ["TotalFuelUsed", decimal_type], ["TotalFuelCost", decimal_type], ["UserMeasurementSystem", MeasurementSystem_$reflection()], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetFuelSummary", "GetFuelSummaryResponse", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.LogMaster.FuelSummary.Msg", [], Msg, () => [[], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(FuelSummary_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", list_type(FuelSummary_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Exception")]]]);
}

export function getFuelSummaryCmd(state) {
    return cmdOfAsync((Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(2, [Item_1])), () => singleton.Delay(() => AsyncResult_bindOfResult((arg) => Result_traverse(map(FuelSummaryDtoModule_toFuelSummary, arg)), getFuelSummary(appRoot.VehicleService, AppRootModule_getSelectedOrgId(), state.DateFrom, state.DateTo))));
}

export function init(dateRange, userMeasurementSystem) {
    const state = new State(fromDateTime(dateRange.startDate), fromDateTime(dateRange.endDate), empty(), empty(), fromParts(0, 0, 0, false, 0), fromParts(0, 0, 0, false, 0), userMeasurementSystem, new Busy(0, []), empty());
    return [state, getFuelSummaryCmd(state)];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            if (msg.fields[0].tag === 1) {
                return [new State(state.DateFrom, state.DateTo, empty(), empty(), state.TotalFuelUsed, state.TotalFuelCost, state.UserMeasurementSystem, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.DateFrom, state.DateTo, msg.fields[0].fields[0], map((tupledArg) => {
                    const xs = tupledArg[1];
                    return new FuelTypeSummary(tupledArg[0], sumBy((x_3) => x_3.FuelQtyTotal, xs, {
                        GetZero: () => (new Decimal("0")),
                        Add: op_Addition,
                    }), sumBy((x_5) => x_5.FuelAmountTotal, xs, {
                        GetZero: () => (new Decimal("0")),
                        Add: op_Addition,
                    }));
                }, List_groupBy((x_1) => x_1.FuelType, msg.fields[0].fields[0], {
                    Equals: equals,
                    GetHashCode: safeHash,
                })), sumBy((y_3) => y_3.FuelQtyTotal, msg.fields[0].fields[0], {
                    GetZero: () => (new Decimal("0")),
                    Add: op_Addition,
                }), sumBy((y_5) => y_5.FuelAmountTotal, msg.fields[0].fields[0], {
                    GetZero: () => (new Decimal("0")),
                    Add: op_Addition,
                }), state.UserMeasurementSystem, new Busy(1, []), state.Errors), Cmd_none()];
            }
        case 2:
            return [new State(state.DateFrom, state.DateTo, state.FuelSummaries, state.FuelTypeSummary, state.TotalFuelUsed, state.TotalFuelCost, state.UserMeasurementSystem, state.IsBusy, singleton_1(toString(msg.fields[0]))), Cmd_none()];
        default:
            return [state, getFuelSummaryCmd(state)];
    }
}

function indexVehicleFuelSummaryView(state) {
    let elems_6, children_2, children, children_4, children_6, elems_5, elems_3, elems_4, elems_7;
    const fuelUnit = (state.UserMeasurementSystem.tag === 1) ? FuelUnitModule_toString(new FuelUnit(1, [])) : FuelUnitModule_toString(new FuelUnit(0, []));
    const children_10 = ofArray([createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["flex", "w-full"])], (elems_6 = [(children_2 = singleton_1((children = ofArray([createElement("th", {
        width: 5 + "%",
        children: "Vehicle",
    }), createElement("th", {
        className: join(" ", ["text-right"]),
        width: 15 + "%",
        children: `Fuel used (${fuelUnit})`,
    }), createElement("th", {
        className: join(" ", ["text-right"]),
        width: 15 + "%",
        children: "Fuel Total",
    })]), createElement("tr", {
        children: reactApi.Children.toArray(Array.from(children)),
    }))), createElement("thead", {
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), (children_4 = toList(delay(() => map_1((fuelSummary) => {
        let elems_2, elems, elems_1;
        return createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top", "gap-4"])], (elems_2 = [createElement("td", {
            children: [fuelSummary.Vehicle],
        }), createElement("td", createObj(ofArray([["className", join(" ", ["text-right"])], (elems = [format('{0:' + "#,#.00" + '}', fuelSummary.FuelQtyTotal)], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement("td", createObj(ofArray([["className", join(" ", ["text-right"])], (elems_1 = [toString_1(fuelSummary.FuelAmountTotal)], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
    }, state.FuelSummaries))), createElement("tbody", {
        children: reactApi.Children.toArray(Array.from(children_4)),
    })), (children_6 = singleton_1(createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top", "gap-4"])], (elems_5 = [createElement("td", {
        children: ["Total"],
    }), createElement("td", createObj(ofArray([["className", join(" ", ["text-right"])], (elems_3 = [format('{0:' + "#,#.00" + '}', state.TotalFuelUsed)], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("td", createObj(ofArray([["className", join(" ", ["text-right"])], (elems_4 = [toString_1(state.TotalFuelCost)], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", reactApi.Children.toArray(Array.from(elems_5))])])))), createElement("tfoot", {
        children: reactApi.Children.toArray(Array.from(children_6)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_6))])])))), createElement("div", createObj(Helpers_combineClasses("divider", empty()))), createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-1", "lg:grid-cols-2", "gap-4"])], (elems_7 = [doughnut(`Fuel Used (${fuelUnit})`, toArray(map((x) => (`${x.Vehicle} (${x.FuelType})`), state.FuelSummaries)), toArray(map((x_1) => ~~toNumber(x_1.FuelQtyTotal), state.FuelSummaries)), [], []), doughnut("Fuel Price", toArray(map((x_2) => (`${x_2.Vehicle} (${x_2.FuelType})`), state.FuelSummaries)), toArray(map((x_3) => ~~toNumber(x_3.FuelAmountTotal), state.FuelSummaries)), [], [])], ["children", reactApi.Children.toArray(Array.from(elems_7))])])))]);
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_10)),
    });
}

function indexFuelTypeSummaryView(state) {
    let elems_6, children_2, children, children_4, children_6, elems_5, elems_3, elems_4, elems_7;
    const fuelUnit = (state.UserMeasurementSystem.tag === 1) ? FuelUnitModule_toString(new FuelUnit(1, [])) : FuelUnitModule_toString(new FuelUnit(0, []));
    const children_10 = ofArray([createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["flex", "w-full"])], (elems_6 = [(children_2 = singleton_1((children = ofArray([createElement("th", {
        width: 5 + "%",
        children: "Fuel",
    }), createElement("th", {
        className: join(" ", ["text-right"]),
        width: 15 + "%",
        children: `Fuel used (${fuelUnit})`,
    }), createElement("th", {
        className: join(" ", ["text-right"]),
        width: 15 + "%",
        children: "Fuel Total",
    })]), createElement("tr", {
        children: reactApi.Children.toArray(Array.from(children)),
    }))), createElement("thead", {
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), (children_4 = toList(delay(() => map_1((fuelTypeSummary) => {
        let elems_2, elems, elems_1;
        return createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top", "gap-4"])], (elems_2 = [createElement("td", {
            children: [toString(fuelTypeSummary.FuelType)],
        }), createElement("td", createObj(ofArray([["className", join(" ", ["text-right"])], (elems = [format('{0:' + "#,#.00" + '}', fuelTypeSummary.TotalFuelUsed)], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement("td", createObj(ofArray([["className", join(" ", ["text-right"])], (elems_1 = [toString_1(fuelTypeSummary.TotalFuelCost)], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
    }, state.FuelTypeSummary))), createElement("tbody", {
        children: reactApi.Children.toArray(Array.from(children_4)),
    })), (children_6 = singleton_1(createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top", "gap-4"])], (elems_5 = [createElement("td", {
        children: ["Total"],
    }), createElement("td", createObj(ofArray([["className", join(" ", ["text-right"])], (elems_3 = [format('{0:' + "#,#.00" + '}', state.TotalFuelUsed)], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("td", createObj(ofArray([["className", join(" ", ["text-right"])], (elems_4 = [toString_1(state.TotalFuelCost)], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", reactApi.Children.toArray(Array.from(elems_5))])])))), createElement("tfoot", {
        children: reactApi.Children.toArray(Array.from(children_6)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_6))])])))), createElement("div", createObj(Helpers_combineClasses("divider", empty()))), createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-1", "lg:grid-cols-2", "gap-4"])], (elems_7 = [doughnut(`Fuel Type Used (${fuelUnit})`, toArray(map((x) => (`${x.FuelType}`), state.FuelTypeSummary)), toArray(map((x_1) => ~~toNumber(x_1.TotalFuelUsed), state.FuelTypeSummary)), [], []), doughnut("Fuel Type Price", toArray(map((x_2) => (`${x_2.FuelType}`), state.FuelTypeSummary)), toArray(map((x_3) => ~~toNumber(x_3.TotalFuelCost), state.FuelTypeSummary)), [], [])], ["children", reactApi.Children.toArray(Array.from(elems_7))])])))]);
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_10)),
    });
}

export function indexFuelSummary(indexFuelSummaryInputProps) {
    let init_1, elems;
    const userMeasurementSystem = indexFuelSummaryInputProps.userMeasurementSystem;
    const dateRange = indexFuelSummaryInputProps.dateRange;
    const state_1 = ((init_1 = init(dateRange, userMeasurementSystem), React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [dateRange])))[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-1", "lg:grid-cols-2", "gap-4", "m-4"])], (elems = [createElement(indexView, {
        title: "Vehicle Fuel Summary",
        elements: [createElement(indexVehicleFuelSummaryView, state_1)],
    }), createElement(indexView, {
        title: "Fuel type Summary",
        elements: [createElement(indexFuelTypeSummaryView, state_1)],
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

