import { Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { getDefaultConfig, DateRange, DateRange_$reflection } from "../../Component/ReactTailwindDatePicker.fs.js";
import { union_type, record_type, string_type, list_type, class_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { VehicleLogTag, VehicleLogTag_$reflection } from "../../../../Logos.Shared/Domain/Entity/LogMaster.fs.js";
import { MeasurementSystem, MeasurementSystem_$reflection } from "../../../../Logos.Shared/Domain/Entity/MeasurementUnits.fs.js";
import { LogPointTagDto_$reflection, VehicleSearchDetailDto_$reflection } from "../../../../Logos.Shared/Domain/Dto/LogMaster/Vehicle.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { getVehicleLogTags, getVehicles } from "../../../AppDomain/UseCase/Vehicle.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../Infrastructure/AppRoot.fs.js";
import { getUserMeasurementSystem } from "../../../AppDomain/UseCase/User.fs.js";
import { parse, now, addDays } from "../../../fable_modules/fable-library-js.4.19.3/Date.js";
import { singleton as singleton_1, sortBy, map, ofArray, empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { Cmd_none, Cmd_batch } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createObj, comparePrimitives } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../../Component/ViewContainer.fs.js";
import { Daisy_error, Daisy_h2 } from "../../Component/Component.fs.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import react_tailwindcss_datepicker from "react-tailwindcss-datepicker";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { indexFuelSummary } from "./Component/FuelSummary.fs.js";
import { indexView } from "./Component/VehicleLogSummary.fs.js";
import { indexView as indexView_1 } from "../../Component/Widget.fs.js";
import { indexDashboardView } from "./Component/VehicleLogAggregate.fs.js";

export class State extends Record {
    constructor(DateRange, AllVehicles, AllVehicleLogTagIds, AllVehicleLogTags, UserMeasurementSystem, Errors) {
        super();
        this.DateRange = DateRange;
        this.AllVehicles = AllVehicles;
        this.AllVehicleLogTagIds = AllVehicleLogTagIds;
        this.AllVehicleLogTags = AllVehicleLogTags;
        this.UserMeasurementSystem = UserMeasurementSystem;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.LogMaster.LogMasterDashboard.State", [], State, () => [["DateRange", DateRange_$reflection()], ["AllVehicles", list_type(class_type("System.Guid"))], ["AllVehicleLogTagIds", list_type(class_type("System.Guid"))], ["AllVehicleLogTags", list_type(VehicleLogTag_$reflection())], ["UserMeasurementSystem", MeasurementSystem_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["DateRangeSelected", "GetVehiclesResponse", "GetVehicleLogTagsResponse", "GetUserMeasurementSystemResponse", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.LogMaster.LogMasterDashboard.Msg", [], Msg, () => [[["Item", DateRange_$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(VehicleSearchDetailDto_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", list_type(VehicleSearchDetailDto_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(LogPointTagDto_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", list_type(LogPointTagDto_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [MeasurementSystem_$reflection(), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", MeasurementSystem_$reflection()]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Exception")]]]);
}

export function getVehiclesCmd() {
    return cmdOfAsync((Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(4, [Item_1])), () => singleton.Delay(() => getVehicles(appRoot.VehicleService, AppRootModule_getSelectedOrgId())));
}

export function getVehicleLogTagsCmd() {
    return cmdOfAsync((Item) => (new Msg(2, [Item])), (Item_1) => (new Msg(4, [Item_1])), () => singleton.Delay(() => getVehicleLogTags(appRoot.VehicleService, AppRootModule_getSelectedOrgId())));
}

export function getUserMeasurementSystemCmd() {
    return cmdOfAsync((Item) => (new Msg(3, [Item])), (Item_1) => (new Msg(4, [Item_1])), () => singleton.Delay(() => getUserMeasurementSystem(appRoot.UserService, AppRootModule_getSelectedOrgId())));
}

export function init(systemService) {
    return [new State(new DateRange(addDays(now(), -7), now()), empty(), empty(), empty(), new MeasurementSystem(0, []), empty()), Cmd_batch(ofArray([getVehiclesCmd(), getVehicleLogTagsCmd(), getUserMeasurementSystemCmd()]))];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            if (msg.fields[0].tag === 1) {
                return [new State(state.DateRange, empty(), state.AllVehicleLogTagIds, state.AllVehicleLogTags, state.UserMeasurementSystem, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.DateRange, map((y) => y.Id, msg.fields[0].fields[0]), state.AllVehicleLogTagIds, state.AllVehicleLogTags, state.UserMeasurementSystem, state.Errors), Cmd_none()];
            }
        case 2:
            if (msg.fields[0].tag === 1) {
                return [new State(state.DateRange, state.AllVehicles, empty(), state.AllVehicleLogTags, state.UserMeasurementSystem, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.DateRange, state.AllVehicles, map((y_3) => y_3.Id, sortBy((y_1) => y_1.Name, msg.fields[0].fields[0], {
                    Compare: comparePrimitives,
                })), map((y_4) => (new VehicleLogTag(y_4.Id, y_4.Name)), msg.fields[0].fields[0]), state.UserMeasurementSystem, state.Errors), Cmd_none()];
            }
        case 3:
            if (msg.fields[0].tag === 1) {
                return [new State(state.DateRange, state.AllVehicles, state.AllVehicleLogTagIds, state.AllVehicleLogTags, state.UserMeasurementSystem, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.DateRange, state.AllVehicles, state.AllVehicleLogTagIds, state.AllVehicleLogTags, msg.fields[0].fields[0], state.Errors), Cmd_none()];
            }
        case 4:
            return [new State(state.DateRange, state.AllVehicles, state.AllVehicleLogTagIds, state.AllVehicleLogTags, state.UserMeasurementSystem, singleton_1(msg.fields[0].message)), Cmd_none()];
        default:
            return [new State(msg.fields[0], state.AllVehicles, state.AllVehicleLogTagIds, state.AllVehicleLogTags, state.UserMeasurementSystem, state.Errors), Cmd_none()];
    }
}

export function IndexView() {
    let elems_2, elems, children_1, props_1, elems_1;
    let patternInput;
    const init_1 = init(appRoot.SystemService);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    return container([createElement("div", createObj(singleton_1((elems_2 = [Daisy_h2("LogMaster"), Daisy_error(state_1.Errors), createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-1", "sm:grid-cols-2", "gap-4"])], (elems = [(children_1 = singleton_1((props_1 = {
        value: state_1.DateRange,
        onChange: (x) => {
            patternInput[1](new Msg(0, [new DateRange(parse(x.startDate), parse(x.endDate))]));
        },
        showShortcuts: true,
        configs: getDefaultConfig(now()),
    }, react.createElement(react_tailwindcss_datepicker, props_1))), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_1)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(Helpers_combineClasses("divider", empty()))), createElement(indexFuelSummary, {
        dateRange: state_1.DateRange,
        userMeasurementSystem: state_1.UserMeasurementSystem,
    }), createElement(indexView, {
        dateRange: state_1.DateRange,
        vehiclesToSearchFor: state_1.AllVehicles,
        vehicleLogTags: state_1.AllVehicleLogTagIds,
        allVehicleLogTags: state_1.AllVehicleLogTags,
        userMeasurementSystem: state_1.UserMeasurementSystem,
    }), createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-1", "lg:grid-cols-1", "gap-4", "m-4"])], (elems_1 = [createElement(indexView_1, {
        title: "Logs",
        elements: [createElement(indexDashboardView, {
            dateRange: state_1.DateRange,
            vehiclesToSearchFor: state_1.AllVehicles,
            vehicleLogTags: state_1.AllVehicleLogTagIds,
            allVehicleLogTags: state_1.AllVehicleLogTags,
            userMeasurementSystem: state_1.UserMeasurementSystem,
        })],
    })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))]))))]);
}

