import { singleton } from "../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library-js.4.19.3/Result.js";
import { isEmpty, filter, concat, singleton as singleton_1, map } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { SiteManagerDtoModule_toSiteManager } from "../../../Logos.Shared/Domain/Dto/User.fs.js";
import { Status } from "../../../Logos.Shared/Domain/Entity/Common.fs.js";
import { UserJobOverrides, UserJobOverride, JobShiftScheduleOverride, JobSearchDetails } from "../../../Logos.Shared/Domain/Entity/Job.fs.js";
import { Pagination } from "../../../Logos.Shared/Domain/Entity/Pagination.fs.js";
import { isNullOrEmpty } from "../../fable_modules/fable-library-js.4.19.3/String.js";
import { JobShiftCheckTemplateDtoModule_ofJobShiftCheckTemplate, JobShiftCheckTemplateDtoModule_toJobShiftCheckTemplate, JobShiftCheckItemNameDtoModule_toJobShiftCheckItemName, UserJobOverrideDtoModule_ofUserJobOverride, UserJobOverridesDtoModule_toUserJobOverrides, JobDetailDtoModule_toJobDetail, JobShiftScheduleOverrideDtoModule_ofJobShiftScheduleOverride, JobShiftScheduleOverrideDtoModule_toJobShiftScheduleOverride, WorkersAtJobDtoModule_toWorkersAtJob, WorkforceSkillDtoModule_toWorkforceSkill, WorkerJobAllocationDtoModule_toWorkerJobAllocation } from "../../../Logos.Shared/Domain/Dto/Job.fs.js";
import { fromDateTimeOffset, date, equals, toUniversalTime, specifyKind } from "../../fable_modules/fable-library-js.4.19.3/Date.js";
import { toLocalTime, op_Subtraction, addDays } from "../../fable_modules/fable-library-js.4.19.3/DateOffset.js";
import { toList } from "../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { rangeDouble } from "../../fable_modules/fable-library-js.4.19.3/Range.js";
import { totalDays } from "../../fable_modules/fable-library-js.4.19.3/TimeSpan.js";
import { toString } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { UserRole } from "../../../Logos.Shared/Domain/Entity/User.fs.js";
import { SimproContractorWorkOrderDetailDtoModule_toSimproContractorWorkOrderDetail, SimproJobCostCentreDtoModule_toSimproJobCostCentre, SimproJobSectionDtoModule_toSimproJobSection, SimproJobDtoModule_toSimproJob } from "../../../Logos.Shared/Domain/Dto/Simpro.fs.js";

export function searchJobs(jobService, selectedOrgId, jobSearchDetails, pagination) {
    return singleton.Delay(() => jobService.SearchJobs(selectedOrgId, jobSearchDetails, pagination));
}

export function getJobAndCustomer(jobService, selectedOrgId, jobId) {
    return singleton.Delay(() => {
        const input = jobService.GetJobAndCustomer(selectedOrgId, jobId);
        return singleton.Bind(input, (x$0027) => {
            let tupledArg;
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [(tupledArg = input_2.fields[0], [tupledArg[0], tupledArg[1], map(SiteManagerDtoModule_toSiteManager, tupledArg[2]), tupledArg[3]])])));
            return singleton.Return(value);
        });
    });
}

export function getAllJobs(jobService, selectedOrgId) {
    return singleton.Delay(() => searchJobs(jobService, selectedOrgId, new JobSearchDetails("", new Status(0, [])), new Pagination(10000n, 0n, 1n)));
}

export function validateJob(job) {
    const input_4 = !isNullOrEmpty(job.Title) ? (new FSharpResult$2(0, [undefined])) : (new FSharpResult$2(1, [singleton_1("Title is required.")]));
    if (input_4.tag === 1) {
        return new FSharpResult$2(1, [input_4.fields[0]]);
    }
    else {
        const input_2 = (job.CustomerId !== "00000000-0000-0000-0000-000000000000") ? (new FSharpResult$2(0, [undefined])) : (new FSharpResult$2(1, [singleton_1("Customer is required.")]));
        if (input_2.tag === 1) {
            return new FSharpResult$2(1, [input_2.fields[0]]);
        }
        else {
            const input = !((((isNullOrEmpty(job.Address.UnitNumber) ? true : isNullOrEmpty(job.Address.Street)) ? true : isNullOrEmpty(job.Address.Suburb)) ? true : isNullOrEmpty(job.Address.City)) ? true : isNullOrEmpty(job.Address.Country)) ? (new FSharpResult$2(0, [undefined])) : (new FSharpResult$2(1, [singleton_1("Complete Address is required.")]));
            if (input.tag === 1) {
                return new FSharpResult$2(1, [input.fields[0]]);
            }
            else {
                return new FSharpResult$2(0, [undefined]);
            }
        }
    }
}

export function addJob(jobService, selectedOrgId, job) {
    return singleton.Delay(() => {
        let asyncResult_1;
        const value_1 = validateJob(job);
        asyncResult_1 = singleton.Return(value_1);
        return singleton.Bind(asyncResult_1, (input_1) => {
            const input_2 = input_1;
            if (input_2.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
            }
            else {
                return jobService.AddJob(selectedOrgId, job);
            }
        });
    });
}

export function updateJob(jobService, selectedOrgId, job) {
    return singleton.Delay(() => {
        let asyncResult_1;
        const value_1 = validateJob(job);
        asyncResult_1 = singleton.Return(value_1);
        return singleton.Bind(asyncResult_1, (input_1) => {
            const input_2 = input_1;
            if (input_2.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
            }
            else {
                return jobService.UpdateJob(selectedOrgId, job);
            }
        });
    });
}

export function getJobUserSkill(jobService, selectedOrgId, jobId, jobUserSkillId) {
    return singleton.Delay(() => jobService.GetJobUserSkill(selectedOrgId, jobId, jobUserSkillId));
}

export function getAllJobUserSkills(jobService, selectedOrgId, jobId) {
    return singleton.Delay(() => jobService.GetAllJobUserSkills(selectedOrgId, jobId));
}

export function deleteJobUserSkill(jobService, selectedOrgId, jobId, jobUserSkillId) {
    return singleton.Delay(() => jobService.DeleteJobUserSkill(selectedOrgId, jobId, jobUserSkillId));
}

export function getAllJobUserSkillsAndAllocations(jobService, selectedOrgId, jobId) {
    return singleton.Delay(() => {
        const asyncResult = jobService.GetAllJobUserSkills(selectedOrgId, jobId);
        return singleton.Bind(asyncResult, (input_1_1) => {
            const input_6 = input_1_1;
            if (input_6.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_6.fields[0]]));
            }
            else {
                const x = jobService.GetAllJobUserSkillAllocations(selectedOrgId, jobId);
                return singleton.Bind(x, (x$0027) => {
                    let value;
                    const input_2 = x$0027;
                    value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [[input_6.fields[0], input_2.fields[0]]])));
                    return singleton.Return(value);
                });
            }
        });
    });
}

export function addJobUserSkill(jobService, selectedOrgId, jobUserSkill) {
    return singleton.Delay(() => jobService.AddJobUserSkill(selectedOrgId, jobUserSkill));
}

export function updateJobUserSkill(jobService, selectedOrgId, jobUserSkill) {
    return singleton.Delay(() => jobService.UpdateJobUserSkill(selectedOrgId, jobUserSkill));
}

export function getUsersToSelect(jobService, selectedOrgId, jobId, skillId, rating) {
    return singleton.Delay(() => jobService.GetUsersToSelect(selectedOrgId, jobId, skillId, rating));
}

export function allocateSelectedUsers(jobService, selectedOrgId, jobId, jobUserSkillId, selectedUsers) {
    return singleton.Delay(() => jobService.AllocateSelectedUsers(selectedOrgId, jobId, jobUserSkillId, selectedUsers));
}

export function removeJobUserSkillAllocation(jobService, selectedOrgId, jobId, jobUserSkillAllocationId) {
    return singleton.Delay(() => jobService.RemoveJobUserSkillAllocation(selectedOrgId, jobId, jobUserSkillAllocationId));
}

export function getWorkerJobAllocations(jobService, selectedOrgId) {
    return singleton.Delay(() => {
        const input = jobService.GetWorkerJobAllocations(selectedOrgId);
        return singleton.Bind(input, (x$0027) => {
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [map(WorkerJobAllocationDtoModule_toWorkerJobAllocation, input_2.fields[0])])));
            return singleton.Return(value);
        });
    });
}

export function getWorkforceSkills(jobService, selectedOrgId) {
    return singleton.Delay(() => {
        const input = jobService.GetWorkforceSkills(selectedOrgId);
        return singleton.Bind(input, (x$0027) => {
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [map(WorkforceSkillDtoModule_toWorkforceSkill, input_2.fields[0])])));
            return singleton.Return(value);
        });
    });
}

export function getWorkersAtJobs(jobService, selectedOrgId) {
    return singleton.Delay(() => {
        const input = jobService.GetWorkersAtJobs(selectedOrgId);
        return singleton.Bind(input, (x$0027) => {
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [map(WorkersAtJobDtoModule_toWorkersAtJob, input_2.fields[0])])));
            return singleton.Return(value);
        });
    });
}

export function getJobShiftScheduleOverrides(jobService, selectedOrgId, jobId, pagination) {
    return singleton.Delay(() => {
        const input = jobService.GetJobShiftScheduleOverrides(selectedOrgId, jobId, pagination);
        return singleton.Bind(input, (x$0027) => {
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [map(JobShiftScheduleOverrideDtoModule_toJobShiftScheduleOverride, input_2.fields[0])])));
            return singleton.Return(value);
        });
    });
}

export function upsertJobShiftScheduleOverride(jobService, selectedOrgId, jobShiftScheduleOverride) {
    return singleton.Delay(() => {
        const jobShiftScheduleOverride_2 = JobShiftScheduleOverrideDtoModule_ofJobShiftScheduleOverride(new JobShiftScheduleOverride(jobShiftScheduleOverride.Id, jobShiftScheduleOverride.JobId, jobShiftScheduleOverride.IsEnabled, jobShiftScheduleOverride.IsHoliday, specifyKind(toUniversalTime(jobShiftScheduleOverride.DateTime), 0), jobShiftScheduleOverride.ShiftStart, jobShiftScheduleOverride.ShiftEnd, jobShiftScheduleOverride.Reason));
        return jobService.UpsertJobShiftScheduleOverride(selectedOrgId, jobShiftScheduleOverride_2);
    });
}

export function getUserJobOverrideForDates(dateFrom, dateTo, userId, username) {
    return map((x) => (new UserJobOverride("00000000-0000-0000-0000-000000000000", "00000000-0000-0000-0000-000000000000", addDays(dateFrom, x), "00000000-0000-0000-0000-000000000000", userId, username)), toList(rangeDouble(0, 1, ~~totalDays(op_Subtraction(dateTo, dateFrom)))));
}

export function getActiveJobs(jobService, selectedOrgId) {
    return singleton.Delay(() => {
        const input = jobService.GetActiveJobs(selectedOrgId);
        return singleton.Bind(input, (x$0027) => {
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [map(JobDetailDtoModule_toJobDetail, input_2.fields[0])])));
            return singleton.Return(value);
        });
    });
}

export function getJobsOfSiteManager(jobService, selectedOrgId) {
    return singleton.Delay(() => {
        const input = jobService.GetJobsOfSiteManager(selectedOrgId);
        return singleton.Bind(input, (x$0027) => {
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [map(JobDetailDtoModule_toJobDetail, input_2.fields[0])])));
            return singleton.Return(value);
        });
    });
}

export function getUserJobOverrides(jobService, selectedOrgId, dateFrom, dateTo, pagination, userRole) {
    return singleton.Delay(() => {
        let copyOfStruct;
        const asyncResult = (((copyOfStruct = userRole, toString(copyOfStruct))) === toString(new UserRole(4, []))) ? getJobsOfSiteManager(jobService, selectedOrgId) : getActiveJobs(jobService, selectedOrgId);
        return singleton.Bind(asyncResult, (input_1_3) => {
            const input_10 = input_1_3;
            if (input_10.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_10.fields[0]]));
            }
            else {
                let x_2;
                const input = jobService.GetUserJobOverrides(selectedOrgId, dateFrom, dateTo, pagination);
                x_2 = singleton.Bind(input, (x$0027) => {
                    let value;
                    const input_2 = x$0027;
                    value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [map(UserJobOverridesDtoModule_toUserJobOverrides, input_2.fields[0])])));
                    return singleton.Return(value);
                });
                return singleton.Bind(x_2, (x$0027_1) => {
                    let value_2;
                    const input_6 = x$0027_1;
                    value_2 = ((input_6.tag === 1) ? (new FSharpResult$2(1, [input_6.fields[0]])) : (new FSharpResult$2(0, [[input_10.fields[0], map((x_1) => (new UserJobOverrides(x_1.UserId, x_1.Username, x_1.UserIsActive, concat(map((y) => {
                        const userJobOverrides_1 = filter((z) => equals(date(toLocalTime(z.DateTime)), date(fromDateTimeOffset(y.DateTime, 0))), x_1.UserJobOverrides);
                        if (isEmpty(userJobOverrides_1)) {
                            return singleton_1(y);
                        }
                        else {
                            return userJobOverrides_1;
                        }
                    }, getUserJobOverrideForDates(dateFrom, dateTo, x_1.UserId, x_1.Username))), x_1.UserLeaves)), input_6.fields[0])]])));
                    return singleton.Return(value_2);
                });
            }
        });
    });
}

export function saveUserJobOverrides(jobService, selectedOrgId, userJobOverridesToUpsert, userOverridesToDelete) {
    return singleton.Delay(() => {
        const userJobOverridesToUpsert_2 = map(UserJobOverrideDtoModule_ofUserJobOverride, userJobOverridesToUpsert);
        const userOverridesToDelete_1 = map(UserJobOverrideDtoModule_ofUserJobOverride, userOverridesToDelete);
        return jobService.SaveUserJobOverrides(selectedOrgId, userJobOverridesToUpsert_2, userOverridesToDelete_1);
    });
}

export function getSimproJob(jobService, selectedOrgId, simproJobId, simproConnectionDetailId) {
    return singleton.Delay(() => {
        const input = jobService.GetSimproJob(selectedOrgId, simproJobId, simproConnectionDetailId);
        return singleton.Bind(input, (x$0027) => {
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [SimproJobDtoModule_toSimproJob(input_2.fields[0])])));
            return singleton.Return(value);
        });
    });
}

export function getSimproJobSections(jobService, selectedOrgId, jobId, simproConnectionDetailId) {
    return singleton.Delay(() => {
        const input = jobService.GetSimproJobSections(selectedOrgId, jobId, simproConnectionDetailId);
        return singleton.Bind(input, (x$0027) => {
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [map(SimproJobSectionDtoModule_toSimproJobSection, input_2.fields[0])])));
            return singleton.Return(value);
        });
    });
}

export function getSimproJobCostCentres(jobService, selectedOrgId, simproJobId, simproConnectionDetailId, sectionId) {
    return singleton.Delay(() => {
        const input = jobService.GetSimproJobCostCentres(selectedOrgId, simproJobId, simproConnectionDetailId, sectionId);
        return singleton.Bind(input, (x$0027) => {
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [map(SimproJobCostCentreDtoModule_toSimproJobCostCentre, input_2.fields[0])])));
            return singleton.Return(value);
        });
    });
}

export function getSimproContractorWorkOrderDetails(jobService, selectedOrgId, jobId, pagination) {
    return singleton.Delay(() => {
        const input = jobService.GetSimproContractorWorkOrderDetails(selectedOrgId, jobId, pagination);
        return singleton.Bind(input, (x$0027) => {
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [map(SimproContractorWorkOrderDetailDtoModule_toSimproContractorWorkOrderDetail, input_2.fields[0])])));
            return singleton.Return(value);
        });
    });
}

export function getJobShiftTemplateNames(jobService, selectedOrgId) {
    return singleton.Delay(() => {
        const input = jobService.GetJobShiftTemplateNames(selectedOrgId);
        return singleton.Bind(input, (x$0027) => {
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [map(JobShiftCheckItemNameDtoModule_toJobShiftCheckItemName, input_2.fields[0])])));
            return singleton.Return(value);
        });
    });
}

export function searchJobShiftTemplates(jobService, selectedOrgId, searchText, pagination) {
    return singleton.Delay(() => {
        const input = jobService.SearchJobShiftTemplates(selectedOrgId, searchText, pagination);
        return singleton.Bind(input, (x$0027) => {
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [map(JobShiftCheckItemNameDtoModule_toJobShiftCheckItemName, input_2.fields[0])])));
            return singleton.Return(value);
        });
    });
}

export function getJobShiftTemplate(jobService, selectedOrgId, shiftTemplateId) {
    return singleton.Delay(() => {
        const input_2 = jobService.GetJobShiftTemplate(selectedOrgId, shiftTemplateId);
        return singleton.Bind(input_2, (x$0027) => {
            let input_1;
            let value;
            const input_4 = x$0027;
            value = ((input_4.tag === 1) ? (new FSharpResult$2(1, [input_4.fields[0]])) : (new FSharpResult$2(0, [(input_1 = input_4.fields[0], (input_1 == null) ? undefined : JobShiftCheckTemplateDtoModule_toJobShiftCheckTemplate(input_1))])));
            return singleton.Return(value);
        });
    });
}

export function addJobShiftTemplate(jobService, selectedOrgId, jobShiftCheckTemplate) {
    return singleton.Delay(() => {
        const jobShiftCheckTemplate_1 = JobShiftCheckTemplateDtoModule_ofJobShiftCheckTemplate(jobShiftCheckTemplate);
        return jobService.AddJobShiftTemplate(selectedOrgId, jobShiftCheckTemplate_1);
    });
}

export function updateJobShiftTemplate(jobService, selectedOrgId, jobShiftCheckTemplate) {
    return singleton.Delay(() => {
        const jobShiftCheckTemplate_1 = JobShiftCheckTemplateDtoModule_ofJobShiftCheckTemplate(jobShiftCheckTemplate);
        return jobService.UpdateJobShiftTemplate(selectedOrgId, jobShiftCheckTemplate_1);
    });
}

export function getJobShiftDefaultTemplates(jobService, selectedOrgId) {
    return singleton.Delay(() => {
        const input = jobService.GetJobShiftDefaultTemplates(selectedOrgId);
        return singleton.Bind(input, (x$0027) => {
            let tupledArg;
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [(tupledArg = input_2.fields[0], [map(JobShiftCheckTemplateDtoModule_toJobShiftCheckTemplate, tupledArg[0]), tupledArg[1], tupledArg[2]])])));
            return singleton.Return(value);
        });
    });
}

export function getJobsForWorkerJobs(jobService, selectedOrgId, userRole) {
    return singleton.Delay(() => {
        let copyOfStruct;
        if (((copyOfStruct = userRole, toString(copyOfStruct))) === toString(new UserRole(4, []))) {
            return getJobsOfSiteManager(jobService, selectedOrgId);
        }
        else {
            return getActiveJobs(jobService, selectedOrgId);
        }
    });
}

export function getWorkerJobs(jobService, selectedOrgId, jobs) {
    return singleton.Delay(() => jobService.GetWorkerJobs(selectedOrgId, jobs));
}

export function getShiftJobImageDetails(jobService, selectedOrgId, jobId, pagination) {
    return singleton.Delay(() => jobService.GetShiftJobImageDetails(selectedOrgId, jobId, pagination));
}

export function getShiftJobImageThumbnail(jobService, selectedOrgId, shiftJobImageId) {
    return singleton.Delay(() => jobService.GetShiftJobImageThumbnail(selectedOrgId, shiftJobImageId));
}

export function getShiftJobImage(jobService, selectedOrgId, shiftJobImageId) {
    return singleton.Delay(() => jobService.GetShiftJobImage(selectedOrgId, shiftJobImageId));
}

