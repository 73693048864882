import { Union } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type, lambda_type, unit_type, class_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { appRoot, AppRootModule_getSelectedOrgId } from "../../../Infrastructure/AppRoot.fs.js";
import { searchJobShiftTemplates } from "../../../AppDomain/UseCase/Job.fs.js";
import { createElement } from "react";
import React from "react";
import { IndexView as IndexView_1 } from "./ShiftTemplateEdit.fs.js";
import { map, empty, singleton, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { uncurry2, createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { ButtonType, showModalEx } from "../../Component/ModalDialog.fs.js";
import { alignIconCenter, Daisy_DialogWidth } from "../../Component/Component.fs.js";
import { IndexView as IndexView_2, Msg$1 } from "../BaseSearch.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { ofArray, singleton as singleton_1 } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";

export class SearchType extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Normal", "Picker"];
    }
}

export function SearchType_$reflection() {
    return union_type("Presentation.Pages.ShiftTemplateSearch.SearchType", [], SearchType, () => [[], [["Item", lambda_type(class_type("System.Guid"), unit_type)]]]);
}

export function searchFunc() {
    const selectedOrgId = AppRootModule_getSelectedOrgId();
    return (searchText) => ((pagination) => searchJobShiftTemplates(appRoot.JobService, selectedOrgId, searchText, pagination));
}

export function shiftTemplateTable(searchType, props) {
    const isPicker = !(searchType.tag === 0);
    const body = () => {
        const matchValue = props.state.EntityToEdit;
        if (matchValue == null) {
            return createElement(IndexView_1, {
                shiftTemplateId: "",
            });
        }
        else {
            const x = matchValue;
            return createElement(IndexView_1, {
                shiftTemplateId: x,
            });
        }
    };
    const children_12 = toList(delay(() => {
        let elems;
        return append(!isPicker ? singleton(createElement("div", createObj(Helpers_combineClasses("navbar", singleton_1((elems = [createElement(showModalEx, {
            dialogId: "id-shift-template-search",
            header: "",
            buttonType: new ButtonType(0, ["Add Shift Template"]),
            width: new Daisy_DialogWidth(3, []),
            body: body,
            onShow: () => {
                props.dispatch(new Msg$1(4, [undefined]));
            },
            onClose: () => {
                props.dispatch(new Msg$1(4, [undefined]));
            },
        })], ["children", reactApi.Children.toArray(Array.from(elems))])))))) : empty(), delay(() => {
            let elems_3, children_3, children_1, children_9;
            return singleton(createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["flex", "w-full"])], (elems_3 = [(children_3 = singleton_1((children_1 = ofArray([createElement("th", {
                width: 90 + "%",
                children: "Template Name",
            }), createElement("th", {
                width: 10 + "%",
                children: "",
            })]), createElement("tr", {
                children: reactApi.Children.toArray(Array.from(children_1)),
            }))), createElement("thead", {
                children: reactApi.Children.toArray(Array.from(children_3)),
            })), (children_9 = toList(delay(() => map((shiftTemplate) => {
                let elems_2, children_7, elems_1;
                return createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top", "hover"])], (elems_2 = [createElement("td", {
                    children: [shiftTemplate.Name],
                }), (children_7 = singleton_1(createElement("div", createObj(ofArray([alignIconCenter, (elems_1 = toList(delay(() => {
                    let children_5;
                    return (searchType.tag === 0) ? singleton(createElement(showModalEx, {
                        dialogId: shiftTemplate.Id,
                        header: "",
                        buttonType: new ButtonType(1, ["fa-edit"]),
                        width: new Daisy_DialogWidth(3, []),
                        body: body,
                        onShow: () => {
                            props.dispatch(new Msg$1(4, [shiftTemplate.Id]));
                        },
                        onClose: () => {
                            props.dispatch(new Msg$1(4, [undefined]));
                        },
                    })) : singleton((children_5 = singleton_1(createElement("input", createObj(Helpers_combineClasses("radio", ofArray([["type", "radio"], ["className", "checkbox-sm"], ["name", "shift-template"], ["className", join(" ", ["align-middle"])], ["onClick", (_arg_2) => {
                        searchType.fields[0](shiftTemplate.Id);
                    }]]))))), createElement("div", {
                        children: reactApi.Children.toArray(Array.from(children_5)),
                    })));
                })), ["children", reactApi.Children.toArray(Array.from(elems_1))])])))), createElement("td", {
                    children: reactApi.Children.toArray(Array.from(children_7)),
                }))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
            }, props.state.Entities))), createElement("tbody", {
                children: reactApi.Children.toArray(Array.from(children_9)),
            }))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))));
        }));
    }));
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_12)),
    });
}

export function IndexView(props) {
    return createElement(IndexView_2, {
        entityName: "Shift Template",
        entityTable: (props_1) => shiftTemplateTable(props.SearchType, props_1),
        searchFunc: uncurry2(searchFunc()),
    });
}

