import { createElement } from "react";
import React from "react";
import { createObj } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { join } from "../../fable_modules/fable-library-js.4.19.3/String.js";
import { ofArray, singleton, append } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { toList } from "../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/./Interop.fs.js";

export function indexView(indexViewInputProps) {
    let elems_1, children_1;
    const elements = indexViewInputProps.elements;
    const title = indexViewInputProps.title;
    return createElement("div", createObj(Helpers_combineClasses("card", ofArray([["className", join(" ", ["overflow-x-auto", "w-full", "p-2", "shadow-xl", "mt-6"])], ["className", "bordered"], (elems_1 = [(children_1 = append(singleton(createElement("h2", {
        className: "card-title",
        children: title,
    })), toList(elements)), createElement("div", {
        className: "card-body",
        children: reactApi.Children.toArray(Array.from(children_1)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))));
}

