import { Union, Record } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type, list_type, decimal_type, string_type, record_type, option_type, class_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";

export class StatementPeriod extends Record {
    constructor(Start, End) {
        super();
        this.Start = Start;
        this.End = End;
    }
}

export function StatementPeriod_$reflection() {
    return record_type("Domain.Entity.Document.StatementPeriod", [], StatementPeriod, () => [["Start", option_type(class_type("System.DateTime"))], ["End", option_type(class_type("System.DateTime"))]]);
}

export class ScanResultStatementDetail extends Record {
    constructor(Id, TransactionDate, Description, Amount) {
        super();
        this.Id = Id;
        this.TransactionDate = TransactionDate;
        this.Description = Description;
        this.Amount = Amount;
    }
}

export function ScanResultStatementDetail_$reflection() {
    return record_type("Domain.Entity.Document.ScanResultStatementDetail", [], ScanResultStatementDetail, () => [["Id", option_type(class_type("System.Guid"))], ["TransactionDate", string_type], ["Description", string_type], ["Amount", decimal_type]]);
}

export class ScanResultBase$1 extends Record {
    constructor(Id, OrganisationId, DateScanned, VendorName, CustomerName, Address, Period, TotalAmount, Details) {
        super();
        this.Id = Id;
        this.OrganisationId = OrganisationId;
        this.DateScanned = DateScanned;
        this.VendorName = VendorName;
        this.CustomerName = CustomerName;
        this.Address = Address;
        this.Period = Period;
        this.TotalAmount = TotalAmount;
        this.Details = Details;
    }
}

export function ScanResultBase$1_$reflection(gen0) {
    return record_type("Domain.Entity.Document.ScanResultBase`1", [gen0], ScanResultBase$1, () => [["Id", class_type("System.Guid")], ["OrganisationId", class_type("System.Guid")], ["DateScanned", class_type("System.DateTime")], ["VendorName", string_type], ["CustomerName", string_type], ["Address", string_type], ["Period", StatementPeriod_$reflection()], ["TotalAmount", option_type(decimal_type)], ["Details", list_type(gen0)]]);
}

export class DocumentTag extends Record {
    constructor(Id, OrganisationId, Name) {
        super();
        this.Id = Id;
        this.OrganisationId = OrganisationId;
        this.Name = Name;
    }
}

export function DocumentTag_$reflection() {
    return record_type("Domain.Entity.Document.DocumentTag", [], DocumentTag, () => [["Id", class_type("System.Guid")], ["OrganisationId", class_type("System.Guid")], ["Name", string_type]]);
}

export class DocumentType$ extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["User", "Customer", "Job"];
    }
}

export function DocumentType$_$reflection() {
    return union_type("Domain.Entity.Document.DocumentType", [], DocumentType$, () => [[], [], []]);
}

export function DocumentTypeModule_ofInt(_arg) {
    switch (_arg) {
        case 10:
            return new DocumentType$(0, []);
        case 20:
            return new DocumentType$(1, []);
        case 30:
            return new DocumentType$(2, []);
        default:
            throw new Error("Invalid document type.");
    }
}

export function DocumentTypeModule_toInt(_arg) {
    switch (_arg.tag) {
        case 1:
            return 20;
        case 2:
            return 30;
        default:
            return 10;
    }
}

export class Document$ extends Record {
    constructor(Id, OrganisationId, OwnerId, Name, FileExtension, Expiry, DocumentType$, Tags, ModifiedBy, Modified) {
        super();
        this.Id = Id;
        this.OrganisationId = OrganisationId;
        this.OwnerId = OwnerId;
        this.Name = Name;
        this.FileExtension = FileExtension;
        this.Expiry = Expiry;
        this.DocumentType = DocumentType$;
        this.Tags = Tags;
        this.ModifiedBy = ModifiedBy;
        this.Modified = Modified;
    }
}

export function Document$_$reflection() {
    return record_type("Domain.Entity.Document.Document", [], Document$, () => [["Id", class_type("System.Guid")], ["OrganisationId", class_type("System.Guid")], ["OwnerId", class_type("System.Guid")], ["Name", string_type], ["FileExtension", option_type(string_type)], ["Expiry", option_type(class_type("System.DateTimeOffset"))], ["DocumentType", DocumentType$_$reflection()], ["Tags", list_type(DocumentTag_$reflection())], ["ModifiedBy", class_type("System.Guid")], ["Modified", class_type("System.DateTimeOffset")]]);
}

export class DocumentSearchDetails extends Record {
    constructor(SearchText, OwnerId, FromDate, ToDate) {
        super();
        this.SearchText = SearchText;
        this.OwnerId = OwnerId;
        this.FromDate = FromDate;
        this.ToDate = ToDate;
    }
}

export function DocumentSearchDetails_$reflection() {
    return record_type("Domain.Entity.Document.DocumentSearchDetails", [], DocumentSearchDetails, () => [["SearchText", string_type], ["OwnerId", option_type(class_type("System.Guid"))], ["FromDate", option_type(class_type("System.DateTimeOffset"))], ["ToDate", option_type(class_type("System.DateTimeOffset"))]]);
}

export class DocumentTagSearchDetails extends Record {
    constructor(SearchText) {
        super();
        this.SearchText = SearchText;
    }
}

export function DocumentTagSearchDetails_$reflection() {
    return record_type("Domain.Entity.Document.DocumentTagSearchDetails", [], DocumentTagSearchDetails, () => [["SearchText", string_type]]);
}

