import { Record } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, bool_type, decimal_type, string_type, option_type, class_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { defaultArg } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Option.js";
import { Address } from "../Entity/Address.fs.js";

export class AddressDto extends Record {
    constructor(Id, UnitNumber, Street, Suburb, City, Country, PostCode, Latitude, Longitude, IsActive) {
        super();
        this.Id = Id;
        this.UnitNumber = UnitNumber;
        this.Street = Street;
        this.Suburb = Suburb;
        this.City = City;
        this.Country = Country;
        this.PostCode = PostCode;
        this.Latitude = Latitude;
        this.Longitude = Longitude;
        this.IsActive = IsActive;
    }
}

export function AddressDto_$reflection() {
    return record_type("Domain.Dto.Address.AddressDto", [], AddressDto, () => [["Id", option_type(class_type("System.Guid"))], ["UnitNumber", string_type], ["Street", string_type], ["Suburb", string_type], ["City", string_type], ["Country", string_type], ["PostCode", string_type], ["Latitude", decimal_type], ["Longitude", decimal_type], ["IsActive", bool_type]]);
}

export function AddressDtoModule_ofAddress(address) {
    return new AddressDto(address.Id, address.UnitNumber, address.Street, address.Suburb, address.City, address.Country, address.PostCode, address.Latitude, address.Longitude, address.IsActive);
}

export function AddressDtoModule_toAddress(parentId, address) {
    return new Address(defaultArg(address.Id, "00000000-0000-0000-0000-000000000000"), defaultArg(parentId, "00000000-0000-0000-0000-000000000000"), address.UnitNumber, address.Street, address.Suburb, address.City, address.Country, address.PostCode, address.Latitude, address.Longitude, address.IsActive);
}

export function AddressDtoModule_toAddressString(address) {
    return `${address.UnitNumber} ${address.Street} ${address.Suburb} ${address.City} ${address.Country} ${address.PostCode} `;
}

export class AddressRawDto extends Record {
    constructor(UnitNumber, Street, Suburb, City, Country, PostCode, Latitude, Longitude) {
        super();
        this.UnitNumber = UnitNumber;
        this.Street = Street;
        this.Suburb = Suburb;
        this.City = City;
        this.Country = Country;
        this.PostCode = PostCode;
        this.Latitude = Latitude;
        this.Longitude = Longitude;
    }
}

export function AddressRawDto_$reflection() {
    return record_type("Domain.Dto.Address.AddressRawDto", [], AddressRawDto, () => [["UnitNumber", string_type], ["Street", string_type], ["Suburb", string_type], ["City", string_type], ["Country", string_type], ["PostCode", string_type], ["Latitude", decimal_type], ["Longitude", decimal_type]]);
}

export function AddressRawDtoModule_ofAddress(address) {
    return new AddressRawDto(address.UnitNumber, address.Street, address.Suburb, address.City, address.Country, address.PostCode, address.Latitude, address.Longitude);
}

