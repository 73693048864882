import react_select from "react-select";
import { Union, Record } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type, array_type, record_type, string_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { map } from "../../fable_modules/fable-library-js.4.19.3/Array.js";

export const select = react_select;

export class Choice extends Record {
    constructor(Value, Label) {
        super();
        this.Value = Value;
        this.Label = Label;
    }
}

export function Choice_$reflection() {
    return record_type("Feliz.ReactSelect.Choice", [], Choice, () => [["Value", string_type], ["Label", string_type]]);
}

export function ChoiceTSModule_toChoice(choice) {
    return new Choice(choice.value, choice.label);
}

export function ChoiceTSModule_ofChoice(choice) {
    return {
        label: choice.Label,
        value: choice.Value,
    };
}

export class GroupedChoice extends Record {
    constructor(Label, Options) {
        super();
        this.Label = Label;
        this.Options = Options;
    }
}

export function GroupedChoice_$reflection() {
    return record_type("Feliz.ReactSelect.GroupedChoice", [], GroupedChoice, () => [["Label", string_type], ["Options", array_type(Choice_$reflection())]]);
}

export function GroupedChoiceTSModule_toGroupedChoice(groupedChoice) {
    return new GroupedChoice(groupedChoice.label, map(ChoiceTSModule_toChoice, groupedChoice.options));
}

export function GroupedChoiceTSModule_ofGroupedChoice(groupedChoice) {
    return {
        label: groupedChoice.Label,
        options: map(ChoiceTSModule_ofChoice, groupedChoice.Options),
    };
}

export class MenuPlacement extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Auto", "Bottom", "Top"];
    }
}

export function MenuPlacement_$reflection() {
    return union_type("Feliz.ReactSelect.MenuPlacement", [], MenuPlacement, () => [[], [], []]);
}

export function MenuPlacement__get_Value(this$) {
    switch (this$.tag) {
        case 1:
            return "bottom";
        case 2:
            return "top";
        default:
            return "auto";
    }
}

export function op_EqualsGreater(key, value) {
    return [key, value];
}

export function lowerFieldNames(choices) {
    return map((option) => ({
        label: option.Label,
        value: option.Value,
    }), choices);
}

export function lowerGroupedFieldNames(opts) {
    return map((gOpt) => ({
        label: gOpt.Label,
        options: lowerFieldNames(gOpt.Options),
    }), opts);
}

