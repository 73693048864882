import { singleton } from "../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library-js.4.19.3/Result.js";
import { LeaveRequestDtoModule_ofLeaveRequest, LeaveRequestDtoModule_toLeaveRequest } from "../../../Logos.Shared/Domain/Dto/User.fs.js";
import { map } from "../../fable_modules/fable-library-js.4.19.3/List.js";

export function searchLeaves(leavesService, selectedOrgId, leaveSearchDetails, pagination) {
    return singleton.Delay(() => leavesService.SearchLeaves(selectedOrgId, leaveSearchDetails, pagination));
}

export function getLeaveRequest(leavesService, selectedOrgId, leaveId) {
    return singleton.Delay(() => {
        const input_4 = leavesService.GetLeaveRequest(selectedOrgId, leaveId);
        return singleton.Bind(input_4, (x$0027) => {
            let input_1;
            let value;
            const input_3 = x$0027;
            value = ((input_3.tag === 1) ? (new FSharpResult$2(1, [input_3.fields[0]])) : (new FSharpResult$2(0, [(input_1 = input_3.fields[0], (input_1 == null) ? undefined : LeaveRequestDtoModule_toLeaveRequest(input_1))])));
            return singleton.Return(value);
        });
    });
}

export function approveLeaveRequest(leavesService, selectedOrgId, leaveRequest) {
    return singleton.Delay(() => leavesService.ApproveLeaveRequest(selectedOrgId, leaveRequest));
}

export function rejectLeaveRequest(leavesService, selectedOrgId, leaveRequest) {
    return singleton.Delay(() => leavesService.RejectLeaveRequest(selectedOrgId, leaveRequest));
}

export function getLeaves(leavesService, selectedOrgId, startDate, endDate) {
    return singleton.Delay(() => {
        const input = leavesService.GetLeaves(selectedOrgId, startDate, endDate);
        return singleton.Bind(input, (x$0027) => {
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [map(LeaveRequestDtoModule_toLeaveRequest, input_2.fields[0])])));
            return singleton.Return(value);
        });
    });
}

export function getLeavePdf(leavesService, selectedOrgId, leaveRequestId) {
    return singleton.Delay(() => leavesService.GetLeavePdf(selectedOrgId, leaveRequestId));
}

export function saveLeaveRequest(leavesService, selectedOrgId, leaveRequest) {
    return singleton.Delay(() => {
        const leaveRequest_1 = LeaveRequestDtoModule_ofLeaveRequest(leaveRequest);
        return leavesService.SaveLeaveRequest(selectedOrgId, leaveRequest_1);
    });
}

