import { Record } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, option_type, string_type, decimal_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";

export class Location extends Record {
    constructor(Latitude, Longitude, Address) {
        super();
        this.Latitude = Latitude;
        this.Longitude = Longitude;
        this.Address = Address;
    }
}

export function Location_$reflection() {
    return record_type("Domain.Entity.Location.Location", [], Location, () => [["Latitude", decimal_type], ["Longitude", decimal_type], ["Address", option_type(string_type)]]);
}

