import { toString, Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { name as name_88, unit_type, union_type, record_type, list_type, array_type, uint8_type, option_type, string_type, class_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { VehicleDto as VehicleDto_1, VehicleDto_$reflection } from "../../../../Logos.Shared/Domain/Dto/LogMaster/Vehicle.fs.js";
import { BusyModule_isLoading, BusyModule_isSaving, Busy, Busy_$reflection } from "../../SharedView.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { String_splitWord, Option_toString, Option_ofString, Guid_parse } from "../../../../Logos.Shared/Util.fs.js";
import { upsertVehicle, getVehicleImage, getVehicle } from "../../../AppDomain/UseCase/Vehicle.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../Infrastructure/AppRoot.fs.js";
import { ofArray, empty, item, find, singleton as singleton_1 } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { DistanceUnitModule_distanceUnits, DistanceUnit as DistanceUnit_1, DistanceUnitModule_toString, DistanceUnitModule_ofString } from "../../../../Logos.Shared/Domain/Entity/MeasurementUnits.fs.js";
import { FuelUnitModule_fuelUnits, FuelTypeModule_fuelTypes, FuelUnit as FuelUnit_1, FuelUnitModule_toString, FuelType as FuelType_1, FuelTypeModule_toString, VehicleStatus, predefinedVehicleLogTags, FuelUnitModule_ofString, FuelTypeModule_ofString } from "../../../../Logos.Shared/Domain/Entity/LogMaster.fs.js";
import { defaultArg } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { now } from "../../../fable_modules/fable-library-js.4.19.3/Date.js";
import { toBase64String, join, isNullOrEmpty } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { Cmd_batch, Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../../Component/ViewContainer.fs.js";
import { Daisy_progressState, Daisy_error } from "../../Component/Component.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { map, empty as empty_1, singleton as singleton_2, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";

export class State extends Record {
    constructor(Id, Make, Model, Year, Registration, DistanceUnit, FuelType, FuelUnit, Status, Tag, VehicleDto, Image, IsGettingVehicle, IsGettingVehicleImage, Errors) {
        super();
        this.Id = Id;
        this.Make = Make;
        this.Model = Model;
        this.Year = Year;
        this.Registration = Registration;
        this.DistanceUnit = DistanceUnit;
        this.FuelType = FuelType;
        this.FuelUnit = FuelUnit;
        this.Status = Status;
        this.Tag = Tag;
        this.VehicleDto = VehicleDto;
        this.Image = Image;
        this.IsGettingVehicle = IsGettingVehicle;
        this.IsGettingVehicleImage = IsGettingVehicleImage;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.VehicleEdit.State", [], State, () => [["Id", class_type("System.Guid")], ["Make", string_type], ["Model", string_type], ["Year", string_type], ["Registration", string_type], ["DistanceUnit", string_type], ["FuelType", string_type], ["FuelUnit", string_type], ["Status", string_type], ["Tag", string_type], ["VehicleDto", option_type(VehicleDto_$reflection())], ["Image", array_type(uint8_type)], ["IsGettingVehicle", Busy_$reflection()], ["IsGettingVehicleImage", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetMake", "SetModel", "SetYear", "SetRegistration", "SetDistanceUnit", "SetFuelType", "SetFuelUnit", "SetDefaultTag", "GetVehicleResponse", "GetVehicleImageResponse", "SaveVehicle", "SaveVehicleResponse", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.VehicleEdit.Msg", [], Msg, () => [[["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", string_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [VehicleDto_$reflection(), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", VehicleDto_$reflection()]], [["ErrorValue", list_type(string_type)]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(uint8_type), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", array_type(uint8_type)]], [["ErrorValue", list_type(string_type)]]])]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Exception")]]]);
}

export function getVehicleCmd(vehicleId) {
    return cmdOfAsync((Item) => (new Msg(8, [Item])), (Item_1) => (new Msg(12, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_2;
        const value_1 = Guid_parse(vehicleId);
        asyncResult_2 = singleton.Return(value_1);
        return singleton.Bind(asyncResult_2, (input_1_1) => {
            const input_5 = input_1_1;
            if (input_5.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_5.fields[0]]));
            }
            else {
                const asyncResult_1 = getVehicle(appRoot.VehicleService, AppRootModule_getSelectedOrgId(), input_5.fields[0]);
                return singleton.Bind(asyncResult_1, (input_1) => {
                    const input_2 = input_1;
                    if (input_2.tag === 1) {
                        return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                    }
                    else {
                        let value_1_1;
                        const option_1 = input_2.fields[0];
                        value_1_1 = ((option_1 == null) ? (new FSharpResult$2(1, [singleton_1("Vehicle not found")])) : (new FSharpResult$2(0, [option_1])));
                        return singleton.Return(value_1_1);
                    }
                });
            }
        });
    }));
}

export function getVehicleImageCmd(vehicleId) {
    return cmdOfAsync((Item) => (new Msg(9, [Item])), (Item_1) => (new Msg(12, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_1;
        const value_1 = Guid_parse(vehicleId);
        asyncResult_1 = singleton.Return(value_1);
        return singleton.Bind(asyncResult_1, (input_1) => {
            const input_2 = input_1;
            if (input_2.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
            }
            else {
                return getVehicleImage(appRoot.VehicleService, AppRootModule_getSelectedOrgId(), input_2.fields[0]);
            }
        });
    }));
}

export function saveVehicleCmd(state) {
    return cmdOfAsync((Item) => (new Msg(11, [Item])), (Item_1) => (new Msg(12, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_3;
        const value_1_1 = DistanceUnitModule_ofString(state.DistanceUnit);
        asyncResult_3 = singleton.Return(value_1_1);
        return singleton.Bind(asyncResult_3, (input_1_3) => {
            const input_9 = input_1_3;
            if (input_9.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_9.fields[0]]));
            }
            else {
                const distanceUnit = input_9.fields[0];
                let asyncResult_2;
                const value_1_2 = FuelTypeModule_ofString(state.FuelType);
                asyncResult_2 = singleton.Return(value_1_2);
                return singleton.Bind(asyncResult_2, (input_1_2) => {
                    const input_6 = input_1_2;
                    if (input_6.tag === 1) {
                        return singleton.Return(new FSharpResult$2(1, [input_6.fields[0]]));
                    }
                    else {
                        const fuelType = input_6.fields[0];
                        let asyncResult_1;
                        const value_1_3 = FuelUnitModule_ofString(state.FuelUnit);
                        asyncResult_1 = singleton.Return(value_1_3);
                        return singleton.Bind(asyncResult_1, (input_1_1) => {
                            let input_1, x;
                            const input_3 = input_1_1;
                            if (input_3.tag === 1) {
                                return singleton.Return(new FSharpResult$2(1, [input_3.fields[0]]));
                            }
                            else {
                                const fuelUnit = input_3.fields[0];
                                const updatedVehicleDto = defaultArg((input_1 = state.VehicleDto, (input_1 == null) ? undefined : ((x = input_1, new VehicleDto_1(x.Id, x.UserId, x.OrgId, state.Make, distanceUnit, fuelType, Option_ofString(state.Model), Option_ofString(state.Year), Option_ofString(state.Registration), x.Updated, x.Status, x.PhotoFilename, x.ServerUpdated, fuelUnit, find((y) => (y.Id === state.Tag), predefinedVehicleLogTags))))), new VehicleDto_1(state.Id, "00000000-0000-0000-0000-000000000000", "00000000-0000-0000-0000-000000000000", state.Make, distanceUnit, fuelType, Option_ofString(state.Model), Option_ofString(state.Year), Option_ofString(state.Registration), now(), new VehicleStatus(1, []), "", now(), fuelUnit, find((y_1) => (y_1.Id === state.Tag), predefinedVehicleLogTags)));
                                return upsertVehicle(appRoot.VehicleService, AppRootModule_getSelectedOrgId(), updatedVehicleDto);
                            }
                        });
                    }
                });
            }
        });
    }));
}

export function init(vehicleId) {
    return [new State("00000000-0000-0000-0000-000000000000", "", "", "", "", DistanceUnitModule_toString(new DistanceUnit_1(0, [])), FuelTypeModule_toString(new FuelType_1(0, [])), FuelUnitModule_toString(new FuelUnit_1(0, [])), "", item(0, predefinedVehicleLogTags).Id, undefined, new Uint8Array(0), isNullOrEmpty(vehicleId) ? (new Busy(0, [])) : (new Busy(1, [])), isNullOrEmpty(vehicleId) ? (new Busy(0, [])) : (new Busy(1, [])), empty()), isNullOrEmpty(vehicleId) ? Cmd_none() : Cmd_batch(ofArray([getVehicleCmd(vehicleId), getVehicleImageCmd(vehicleId)]))];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [new State(state.Id, state.Make, msg.fields[0], state.Year, state.Registration, state.DistanceUnit, state.FuelType, state.FuelUnit, state.Status, state.Tag, state.VehicleDto, state.Image, state.IsGettingVehicle, state.IsGettingVehicleImage, state.Errors), Cmd_none()];
        case 2:
            return [new State(state.Id, state.Make, state.Model, msg.fields[0], state.Registration, state.DistanceUnit, state.FuelType, state.FuelUnit, state.Status, state.Tag, state.VehicleDto, state.Image, state.IsGettingVehicle, state.IsGettingVehicleImage, state.Errors), Cmd_none()];
        case 3:
            return [new State(state.Id, state.Make, state.Model, state.Year, msg.fields[0], state.DistanceUnit, state.FuelType, state.FuelUnit, state.Status, state.Tag, state.VehicleDto, state.Image, state.IsGettingVehicle, state.IsGettingVehicleImage, state.Errors), Cmd_none()];
        case 4:
            return [new State(state.Id, state.Make, state.Model, state.Year, state.Registration, msg.fields[0], state.FuelType, state.FuelUnit, state.Status, state.Tag, state.VehicleDto, state.Image, state.IsGettingVehicle, state.IsGettingVehicleImage, state.Errors), Cmd_none()];
        case 5:
            return [new State(state.Id, state.Make, state.Model, state.Year, state.Registration, state.DistanceUnit, msg.fields[0], state.FuelUnit, state.Status, state.Tag, state.VehicleDto, state.Image, state.IsGettingVehicle, state.IsGettingVehicleImage, state.Errors), Cmd_none()];
        case 6:
            return [new State(state.Id, state.Make, state.Model, state.Year, state.Registration, state.DistanceUnit, state.FuelType, msg.fields[0], state.Status, state.Tag, state.VehicleDto, state.Image, state.IsGettingVehicle, state.IsGettingVehicleImage, state.Errors), Cmd_none()];
        case 7:
            return [new State(state.Id, state.Make, state.Model, state.Year, state.Registration, state.DistanceUnit, state.FuelType, state.FuelUnit, state.Status, msg.fields[0], state.VehicleDto, state.Image, state.IsGettingVehicle, state.IsGettingVehicleImage, state.Errors), Cmd_none()];
        case 8:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Id, state.Make, state.Model, state.Year, state.Registration, state.DistanceUnit, state.FuelType, state.FuelUnit, state.Status, state.Tag, state.VehicleDto, state.Image, new Busy(0, []), state.IsGettingVehicleImage, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.Id, msg.fields[0].fields[0].Make, Option_toString(msg.fields[0].fields[0].Model), Option_toString(msg.fields[0].fields[0].Year), Option_toString(msg.fields[0].fields[0].Registration), DistanceUnitModule_toString(msg.fields[0].fields[0].DistanceUnit), FuelTypeModule_toString(msg.fields[0].fields[0].FuelType), FuelUnitModule_toString(msg.fields[0].fields[0].FuelUnit), state.Status, msg.fields[0].fields[0].DefaultTag.Id, msg.fields[0].fields[0], state.Image, new Busy(0, []), state.IsGettingVehicleImage, state.Errors), Cmd_none()];
            }
        case 9:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Id, state.Make, state.Model, state.Year, state.Registration, state.DistanceUnit, state.FuelType, state.FuelUnit, state.Status, state.Tag, state.VehicleDto, state.Image, state.IsGettingVehicle, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.Id, state.Make, state.Model, state.Year, state.Registration, state.DistanceUnit, state.FuelType, state.FuelUnit, state.Status, state.Tag, state.VehicleDto, msg.fields[0].fields[0], state.IsGettingVehicle, new Busy(0, []), state.Errors), Cmd_none()];
            }
        case 10:
            return [state, saveVehicleCmd(state)];
        case 11:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Id, state.Make, state.Model, state.Year, state.Registration, state.DistanceUnit, state.FuelType, state.FuelUnit, state.Status, state.Tag, state.VehicleDto, state.Image, state.IsGettingVehicle, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.Id, state.Make, state.Model, state.Year, state.Registration, state.DistanceUnit, state.FuelType, state.FuelUnit, state.Status, state.Tag, state.VehicleDto, state.Image, state.IsGettingVehicle, new Busy(0, []), state.Errors), Cmd_none()];
            }
        case 12:
            return [new State(state.Id, state.Make, state.Model, state.Year, state.Registration, state.DistanceUnit, state.FuelType, state.FuelUnit, state.Status, state.Tag, state.VehicleDto, state.Image, state.IsGettingVehicle, new Busy(0, []), singleton_1(toString(msg.fields[0]))), Cmd_none()];
        default:
            return [new State(state.Id, msg.fields[0], state.Model, state.Year, state.Registration, state.DistanceUnit, state.FuelType, state.FuelUnit, state.Status, state.Tag, state.VehicleDto, state.Image, state.IsGettingVehicle, state.IsGettingVehicleImage, state.Errors), Cmd_none()];
    }
}

export function IndexView(indexViewInputProps) {
    let children, elems_22, children_6, children_12, children_10, children_17, children_15, children_22, children_20, children_27, children_25, children_33, children_30, elems_11, children_39, children_36, elems_14, children_45, children_42, elems_17, children_51, children_48, elems_20;
    const vehicleId = indexViewInputProps.vehicleId;
    let patternInput;
    const init_1 = init(vehicleId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const isSaving = BusyModule_isSaving(state_1.IsGettingVehicleImage);
    return container([Daisy_error(state_1.Errors), (children = singleton_1(createElement(Daisy_progressState, {
        busy: state_1.IsGettingVehicle,
    })), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children)),
    })), createElement("button", createObj(Helpers_combineClasses("btn", toList(delay(() => append(BusyModule_isSaving(state_1.IsGettingVehicleImage) ? singleton_2(["className", "loading"]) : empty_1(), delay(() => append(singleton_2(["className", "btn-primary"]), delay(() => append(singleton_2(["className", "btn-sm"]), delay(() => append(singleton_2(["children", "Save"]), delay(() => singleton_2(["onClick", (_arg_4) => {
        dispatch(new Msg(10, []));
    }])))))))))))))), createElement("div", createObj(Helpers_combineClasses("divider", empty()))), createElement("div", createObj(singleton_1((elems_22 = [(children_6 = toList(delay(() => (BusyModule_isLoading(state_1.IsGettingVehicleImage) ? singleton_2(createElement("progress", createObj(Helpers_combineClasses("progress", empty())))) : singleton_2(createElement("img", {
        className: join(" ", ["object-scale-down", "h-auto", "w-1/2", "self-center", "rounded-2xl"]),
        src: "data:image/jpg;base64," + toBase64String(state_1.Image),
    }))))), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_6)),
    })), createElement("div", createObj(Helpers_combineClasses("divider", empty()))), (children_12 = ofArray([(children_10 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Make",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_10)),
    })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["autoFocus", true], ["value", state_1.Make], ["placeholder", "Make"], ["readOnly", isSaving], ["onChange", (ev) => {
        dispatch(new Msg(0, [ev.target.value]));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_12)),
    })), (children_17 = ofArray([(children_15 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Model",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_15)),
    })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", state_1.Model], ["placeholder", "Model"], ["readOnly", isSaving], ["onChange", (ev_1) => {
        dispatch(new Msg(1, [ev_1.target.value]));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_17)),
    })), (children_22 = ofArray([(children_20 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Year",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_20)),
    })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", state_1.Year], ["placeholder", "Year"], ["readOnly", isSaving], ["onChange", (ev_2) => {
        dispatch(new Msg(2, [ev_2.target.value]));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_22)),
    })), (children_27 = ofArray([(children_25 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Registration",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_25)),
    })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", state_1.Registration], ["placeholder", "Registration"], ["readOnly", isSaving], ["onChange", (ev_3) => {
        dispatch(new Msg(3, [ev_3.target.value]));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_27)),
    })), (children_33 = ofArray([(children_30 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Fuel",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_30)),
    })), createElement("select", createObj(Helpers_combineClasses("select", ofArray([["className", "select-bordered"], ["className", "select-sm"], ["placeholder", "Fuel"], ["readOnly", isSaving], ["value", state_1.FuelType], ["onChange", (ev_4) => {
        dispatch(new Msg(5, [ev_4.target.value]));
    }], (elems_11 = toList(delay(() => map((case$) => createElement("option", {
        value: name_88(case$),
        children: String_splitWord(name_88(case$)),
    }), FuelTypeModule_fuelTypes))), ["children", reactApi.Children.toArray(Array.from(elems_11))])]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_33)),
    })), (children_39 = ofArray([(children_36 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Fuel Unit",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_36)),
    })), createElement("select", createObj(Helpers_combineClasses("select", ofArray([["className", "select-bordered"], ["className", "select-sm"], ["placeholder", "Fuel Unit"], ["readOnly", isSaving], ["value", state_1.FuelUnit], ["onChange", (ev_5) => {
        dispatch(new Msg(6, [ev_5.target.value]));
    }], (elems_14 = toList(delay(() => map((case$_1) => createElement("option", {
        value: name_88(case$_1),
        children: String_splitWord(name_88(case$_1)),
    }), FuelUnitModule_fuelUnits))), ["children", reactApi.Children.toArray(Array.from(elems_14))])]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_39)),
    })), (children_45 = ofArray([(children_42 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Distance Unit",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_42)),
    })), createElement("select", createObj(Helpers_combineClasses("select", ofArray([["className", "select-bordered"], ["className", "select-sm"], ["placeholder", "Distance Unit"], ["readOnly", isSaving], ["value", state_1.DistanceUnit], ["onChange", (ev_6) => {
        dispatch(new Msg(4, [ev_6.target.value]));
    }], (elems_17 = toList(delay(() => map((case$_2) => createElement("option", {
        value: name_88(case$_2),
        children: String_splitWord(name_88(case$_2)),
    }), DistanceUnitModule_distanceUnits))), ["children", reactApi.Children.toArray(Array.from(elems_17))])]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_45)),
    })), (children_51 = ofArray([(children_48 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Default Log Tag",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_48)),
    })), createElement("select", createObj(Helpers_combineClasses("select", ofArray([["className", "select-bordered"], ["className", "select-sm"], ["placeholder", "Default Log Tag"], ["readOnly", isSaving], ["value", state_1.Tag], ["onChange", (ev_7) => {
        dispatch(new Msg(7, [ev_7.target.value]));
    }], (elems_20 = toList(delay(() => map((case$_3) => createElement("option", {
        value: case$_3.Id,
        children: String_splitWord(case$_3.Name),
    }), predefinedVehicleLogTags))), ["children", reactApi.Children.toArray(Array.from(elems_20))])]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_51)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_22))]))))]);
}

