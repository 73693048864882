import { toString, Union, Record } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type, record_type, bool_type, option_type, class_type, list_type, string_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Pagination_$reflection } from "../../../Logos.Shared/Domain/Entity/Pagination.fs.js";
import { BusyModule_isLoading, Busy, Busy_$reflection } from "../SharedView.fs.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { paginationControl, firstPage, nextPage, previousPage, getDefaultPagination } from "../Component/Pagination.fs.js";
import { ofArray, singleton as singleton_1, isEmpty, empty } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { createObj, equals } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../Component/ViewContainer.fs.js";
import { Daisy_progressState, Daisy_error, Daisy_h4 } from "../Component/Component.fs.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { Helpers_combineClasses } from "../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { PropHelpers_createOnKey } from "../../fable_modules/Feliz.2.8.0/./Properties.fs.js";
import { key_enter } from "../../fable_modules/Feliz.2.8.0/Key.fs.js";

export class State$1 extends Record {
    constructor(SearchText, Entities, EntityToEdit, NextPagination, CurrentPagination, RefreshPage, IsBusy, Errors) {
        super();
        this.SearchText = SearchText;
        this.Entities = Entities;
        this.EntityToEdit = EntityToEdit;
        this.NextPagination = NextPagination;
        this.CurrentPagination = CurrentPagination;
        this.RefreshPage = RefreshPage;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State$1_$reflection(gen0) {
    return record_type("Presentation.Pages.BaseSearch.State`1", [gen0], State$1, () => [["SearchText", string_type], ["Entities", list_type(gen0)], ["EntityToEdit", option_type(class_type("System.Guid"))], ["NextPagination", Pagination_$reflection()], ["CurrentPagination", Pagination_$reflection()], ["RefreshPage", bool_type], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg$1 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetSearchText", "Search", "RefreshPage", "SearchResponse", "EditEntity", "NoAction", "GotoPreviousPage", "GotoNextPage", "GotoFirstPage", "SetRefreshPage", "ApiError"];
    }
}

export function Msg$1_$reflection(gen0) {
    return union_type("Presentation.Pages.BaseSearch.Msg`1", [gen0], Msg$1, () => [[["Item", string_type]], [], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(gen0), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", list_type(gen0)]], [["ErrorValue", list_type(string_type)]]])]], [["Item", option_type(class_type("System.Guid"))]], [], [], [], [], [["Item", bool_type]], [["Item", class_type("System.Exception")]]]);
}

export function searchEntityCmd(state, search) {
    return cmdOfAsync((Item) => (new Msg$1(3, [Item])), (Item_1) => (new Msg$1(10, [Item_1])), () => singleton.Delay(() => search(state.SearchText, state.NextPagination)));
}

export function init(searchFunc) {
    let state;
    const pagination = getDefaultPagination();
    state = (new State$1("", empty(), undefined, pagination, pagination, false, new Busy(1, []), empty()));
    return [state, searchEntityCmd(state, searchFunc)];
}

function changePage(state, f, searchFunc) {
    const currentState = state;
    const newState = new State$1(state.SearchText, state.Entities, state.EntityToEdit, f(state.CurrentPagination), state.CurrentPagination, state.RefreshPage, new Busy(1, []), state.Errors);
    if (equals(currentState, newState)) {
        return [currentState, Cmd_none()];
    }
    else {
        return [newState, searchEntityCmd(newState, searchFunc)];
    }
}

export function update(searchFunc, msg, state) {
    switch (msg.tag) {
        case 1:
            return [new State$1(state.SearchText, empty(), state.EntityToEdit, state.NextPagination, state.CurrentPagination, state.RefreshPage, new Busy(1, []), empty()), searchEntityCmd(state, searchFunc)];
        case 3:
            if (msg.fields[0].tag === 1) {
                return [new State$1(state.SearchText, state.Entities, state.EntityToEdit, state.NextPagination, state.CurrentPagination, state.RefreshPage, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                const newState = isEmpty(msg.fields[0].fields[0]) ? (new State$1(state.SearchText, state.Entities, state.EntityToEdit, state.CurrentPagination, state.CurrentPagination, state.RefreshPage, state.IsBusy, state.Errors)) : (new State$1(state.SearchText, msg.fields[0].fields[0], state.EntityToEdit, state.NextPagination, state.NextPagination, state.RefreshPage, state.IsBusy, state.Errors));
                return [new State$1(newState.SearchText, newState.Entities, newState.EntityToEdit, newState.NextPagination, newState.CurrentPagination, newState.RefreshPage, new Busy(0, []), newState.Errors), Cmd_none()];
            }
        case 4:
            return [new State$1(state.SearchText, state.Entities, msg.fields[0], state.NextPagination, state.CurrentPagination, state.RefreshPage, state.IsBusy, state.Errors), Cmd_none()];
        case 6:
            return changePage(state, previousPage, searchFunc);
        case 7:
            return changePage(state, nextPage, searchFunc);
        case 8:
            return changePage(state, firstPage, searchFunc);
        case 9:
            return [new State$1(state.SearchText, state.Entities, state.EntityToEdit, state.NextPagination, state.CurrentPagination, msg.fields[0], state.IsBusy, state.Errors), Cmd_none()];
        case 2:
            if (state.RefreshPage) {
                return [new State$1(state.SearchText, state.Entities, state.EntityToEdit, state.NextPagination, state.CurrentPagination, false, state.IsBusy, state.Errors), searchEntityCmd(state, searchFunc)];
            }
            else {
                return [state, Cmd_none()];
            }
        case 10:
            return [new State$1(state.SearchText, state.Entities, state.EntityToEdit, state.NextPagination, state.CurrentPagination, state.RefreshPage, new Busy(0, []), singleton_1(toString(msg.fields[0]))), Cmd_none()];
        case 5:
            return [state, Cmd_none()];
        default: {
            const pagination = getDefaultPagination();
            return [new State$1(msg.fields[0], state.Entities, state.EntityToEdit, pagination, pagination, state.RefreshPage, state.IsBusy, state.Errors), Cmd_none()];
        }
    }
}

export function IndexView(props) {
    let children, children_6, children_3, elems_1, children_8;
    let patternInput;
    const init_1 = init(props.searchFunc);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, (msg, state) => update(props.searchFunc, msg, state), (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return container([(children = singleton_1(Daisy_h4(`${props.entityName} search`)), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    })), (children_6 = ofArray([(children_3 = singleton_1(createElement("span", {
        className: "label-text",
        children: props.entityName,
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_3)),
    })), createElement("div", createObj(ofArray([["className", "flex space-x-2"], (elems_1 = [createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "w-full"], ["className", "input-bordered"], ["className", "input-sm"], ["autoFocus", true], ["value", state_1.SearchText], ["placeholder", props.entityName], ["readOnly", BusyModule_isLoading(state_1.IsBusy)], ["onChange", (ev) => {
        dispatch(new Msg$1(0, [ev.target.value]));
    }], ["onKeyUp", (ev_1) => {
        PropHelpers_createOnKey(key_enter, (_arg_3) => {
            dispatch(new Msg$1(1, []));
        }, ev_1);
    }]])))), createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-primary"], ["className", "btn-sm"], ["children", "Search"], ["onClick", (_arg_4) => {
        dispatch(new Msg$1(1, []));
    }]]))))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_6)),
    })), Daisy_error(state_1.Errors), (children_8 = singleton_1(createElement(Daisy_progressState, {
        busy: state_1.IsBusy,
    })), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_8)),
    })), props.entityTable({
        dispatch: dispatch,
        state: state_1,
    }), paginationControl(false, state_1.CurrentPagination.CurrentPage, (_arg_5) => {
        dispatch(new Msg$1(6, []));
    }, (_arg_6) => {
        dispatch(new Msg$1(7, []));
    }, (_arg_7) => {
        dispatch(new Msg$1(8, []));
    })]);
}

