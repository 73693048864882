import { createElement } from "react";
import React from "react";
import * as react from "react";
import { AppRootModule_getIsMember } from "../../Infrastructure/AppRoot.fs.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { singleton, delay, toList } from "../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { createObj } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { Daisy_h4 } from "./Component.fs.js";
import { ofArray, singleton as singleton_1 } from "../../fable_modules/fable-library-js.4.19.3/List.js";

export function IndexView(indexViewInputProps) {
    const element = indexViewInputProps.element;
    let patternInput;
    const initial = AppRootModule_getIsMember();
    patternInput = reactApi.useState(initial);
    const xs_3 = toList(delay(() => {
        let elems_1, children;
        return patternInput[0] ? singleton(element) : singleton(createElement("div", createObj(Helpers_combineClasses("card", ofArray([["className", "p-10"], ["className", "bordered"], ["className", "image-full"], (elems_1 = [(children = singleton_1(Daisy_h4("This feature is only available to Organisation members, please create or join an Organisation.")), createElement("div", {
            className: "card-body",
            children: reactApi.Children.toArray(Array.from(children)),
        }))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))));
    }));
    return react.createElement(react.Fragment, {}, ...xs_3);
}

export function IndexViewWithSubscription(indexViewWithSubscriptionInputProps) {
    const args = indexViewWithSubscriptionInputProps.args;
    const element = indexViewWithSubscriptionInputProps.element;
    const hasSubscription = indexViewWithSubscriptionInputProps.hasSubscription;
    const xs_3 = toList(delay(() => {
        let elems_1, children;
        return hasSubscription ? singleton(element(args)) : singleton(createElement("div", createObj(Helpers_combineClasses("card", ofArray([["className", "p-10"], ["className", "bordered"], ["className", "image-full"], (elems_1 = [(children = singleton_1(Daisy_h4("You do not have valid subscription to view this page.")), createElement("div", {
            className: "card-body",
            children: reactApi.Children.toArray(Array.from(children)),
        }))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))));
    }));
    return react.createElement(react.Fragment, {}, ...xs_3);
}

