import { toString, Union, Record } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type, lambda_type, unit_type, anonRecord_type, list_type, record_type, bool_type, string_type, class_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { isEmpty, where, ofArray, singleton, append, sortBy, filter, map, empty } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createObj, comparePrimitives, equals } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { join } from "../../fable_modules/fable-library-js.4.19.3/String.js";
import { Helpers_combineClasses } from "../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { TitleSize, viewBox } from "./ViewBox.fs.js";

export class MultiListItem extends Record {
    constructor(Id, Title, IsChecked) {
        super();
        this.Id = Id;
        this.Title = Title;
        this.IsChecked = IsChecked;
    }
}

export function MultiListItem_$reflection() {
    return record_type("Presentation.Component.MultiListSelector.MultiListItem", [], MultiListItem, () => [["Id", class_type("System.Guid")], ["Title", string_type], ["IsChecked", bool_type]]);
}

export class State extends Record {
    constructor(IsSelectAllLeftChecked, IsSelectAllRightChecked, LeftItems, RightItems, OnUpdate, Errors) {
        super();
        this.IsSelectAllLeftChecked = IsSelectAllLeftChecked;
        this.IsSelectAllRightChecked = IsSelectAllRightChecked;
        this.LeftItems = LeftItems;
        this.RightItems = RightItems;
        this.OnUpdate = OnUpdate;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Component.MultiListSelector.State", [], State, () => [["IsSelectAllLeftChecked", bool_type], ["IsSelectAllRightChecked", bool_type], ["LeftItems", list_type(MultiListItem_$reflection())], ["RightItems", list_type(MultiListItem_$reflection())], ["OnUpdate", lambda_type(anonRecord_type(["LeftItems", list_type(MultiListItem_$reflection())], ["RightItems", list_type(MultiListItem_$reflection())]), unit_type)], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetSelectAllLeft", "SetSelectAllRight", "SetLeftListItemChecked", "SetRightListItemChecked", "MoveItemRight", "MoveItemLeft", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Component.MultiListSelector.Msg", [], Msg, () => [[["Item", bool_type]], [["Item", bool_type]], [["Item1", bool_type], ["Item2", MultiListItem_$reflection()]], [["Item1", bool_type], ["Item2", MultiListItem_$reflection()]], [], [], [["Item", class_type("System.Exception")]]]);
}

export function init(leftItems, rightItems, onUpdate) {
    return [new State(false, false, leftItems, rightItems, onUpdate, empty()), Cmd_none()];
}

export function update(msg, state) {
    let RightItems_3;
    const updateStateAndParentCmd = (updatedState) => {
        state.OnUpdate({
            LeftItems: updatedState.LeftItems,
            RightItems: updatedState.RightItems,
        });
        return [updatedState, Cmd_none()];
    };
    switch (msg.tag) {
        case 1: {
            const isChecked_1 = msg.fields[0];
            return [new State(state.IsSelectAllLeftChecked, isChecked_1, state.LeftItems, map((x_1) => (new MultiListItem(x_1.Id, x_1.Title, isChecked_1)), state.RightItems), state.OnUpdate, state.Errors), Cmd_none()];
        }
        case 2:
            return [new State(state.IsSelectAllLeftChecked, state.IsSelectAllRightChecked, map((y) => {
                if (equals(y, msg.fields[1])) {
                    return new MultiListItem(y.Id, y.Title, msg.fields[0]);
                }
                else {
                    return y;
                }
            }, state.LeftItems), state.RightItems, state.OnUpdate, state.Errors), Cmd_none()];
        case 3:
            return [new State(state.IsSelectAllLeftChecked, state.IsSelectAllRightChecked, state.LeftItems, map((y_1) => {
                if (equals(y_1, msg.fields[1])) {
                    return new MultiListItem(y_1.Id, y_1.Title, msg.fields[0]);
                }
                else {
                    return y_1;
                }
            }, state.RightItems), state.OnUpdate, state.Errors), Cmd_none()];
        case 4:
            return updateStateAndParentCmd(new State(state.IsSelectAllLeftChecked, state.IsSelectAllRightChecked, filter((x_4) => !x_4.IsChecked, state.LeftItems), sortBy((x_7) => x_7.Title, map((x_6) => (new MultiListItem(x_6.Id, x_6.Title, false)), append(state.RightItems, filter((x_5) => x_5.IsChecked, state.LeftItems))), {
                Compare: comparePrimitives,
            }), state.OnUpdate, state.Errors));
        case 5:
            return updateStateAndParentCmd((RightItems_3 = filter((x_9) => !x_9.IsChecked, state.RightItems), new State(state.IsSelectAllLeftChecked, state.IsSelectAllRightChecked, sortBy((x_12) => x_12.Title, map((x_11) => (new MultiListItem(x_11.Id, x_11.Title, false)), append(state.LeftItems, filter((x_10) => x_10.IsChecked, state.RightItems))), {
                Compare: comparePrimitives,
            }), RightItems_3, state.OnUpdate, state.Errors)));
        case 6:
            return [new State(state.IsSelectAllLeftChecked, state.IsSelectAllRightChecked, state.LeftItems, state.RightItems, state.OnUpdate, singleton(toString(msg.fields[0]))), Cmd_none()];
        default: {
            const isChecked = msg.fields[0];
            return [new State(isChecked, state.IsSelectAllRightChecked, map((x) => (new MultiListItem(x.Id, x.Title, isChecked)), state.LeftItems), state.RightItems, state.OnUpdate, state.Errors), Cmd_none()];
        }
    }
}

export function MultiListSelector(props) {
    let elems_3, elems_7, elems_4, elems_6;
    let patternInput;
    const init_1 = init(props.LeftItems, props.RightItems, props.OnUpdate);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const getList = (isSelectAllChecked, setSelectAllMsg, items, msg_1) => {
        let elems_2, elems;
        return createElement("div", createObj(ofArray([["className", join(" ", ["w-full", "h-32", "overflow-auto", "box-content", "border-2", "rounded"])], (elems_2 = append(singleton(createElement("div", createObj(ofArray([["className", join(" ", ["mb-4", "ml-2", "mt-2", "flex", "items-center"])], (elems = [createElement("input", createObj(Helpers_combineClasses("checkbox", ofArray([["type", "checkbox"], ["className", "checkbox-xs"], ["className", join(" ", ["mr-2"])], ["checked", isSelectAllChecked], ["onChange", (ev) => {
            dispatch(setSelectAllMsg(ev.target.checked));
        }]])))), createElement("span", {
            className: "label-text",
            children: "Select All",
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])))), map((x) => {
            let elems_1;
            return createElement("div", createObj(ofArray([["className", join(" ", ["m-2", "flex", "items-center"])], (elems_1 = [createElement("input", createObj(Helpers_combineClasses("checkbox", ofArray([["type", "checkbox"], ["className", "checkbox-xs"], ["className", join(" ", ["mr-2"])], ["checked", x.IsChecked], ["onChange", (ev_1) => {
                dispatch(msg_1([ev_1.target.checked, x]));
            }]])))), createElement("span", {
                className: "label-text",
                children: x.Title,
            })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
        }, items)), ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
    };
    const buttonPanel = createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-1", "gap-4"])], (elems_3 = [createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-sm"], ["className", "btn-primary"], ["children", ">>"], ["onClick", (_arg_2) => {
        dispatch(new Msg(4, []));
    }], ["disabled", isEmpty(where((x_1) => x_1.IsChecked, state_1.LeftItems))]])))), createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-sm"], ["className", "btn-primary"], ["children", "<<"], ["onClick", (_arg_3) => {
        dispatch(new Msg(5, []));
    }], ["disabled", isEmpty(where((x_2) => x_2.IsChecked, state_1.RightItems))]]))))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])));
    return viewBox(props.Title, new TitleSize(0, []), singleton(createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-7", "gap-4", "h-full"])], (elems_7 = [createElement("div", createObj(ofArray([["className", join(" ", ["col-span-3"])], (elems_4 = [getList(state_1.IsSelectAllLeftChecked, (Item) => (new Msg(0, [Item])), state_1.LeftItems, (tupledArg) => (new Msg(2, [tupledArg[0], tupledArg[1]])))], ["children", reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("div", {
        className: join(" ", ["col-span-1", "mt-4"]),
        children: reactApi.Children.toArray([buttonPanel]),
    }), createElement("div", createObj(ofArray([["className", join(" ", ["col-span-3"])], (elems_6 = [getList(state_1.IsSelectAllRightChecked, (Item_3) => (new Msg(1, [Item_3])), state_1.RightItems, (tupledArg_1) => (new Msg(3, [tupledArg_1[0], tupledArg_1[1]])))], ["children", reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", reactApi.Children.toArray(Array.from(elems_7))])])))));
}

