import { Record } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { parseEnum, record_type, bool_type, list_type, enum_type, int32_type, string_type, class_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { ShiftRule } from "../Entity/ShiftRule.fs.js";
import { fromMinutes, totalMinutes } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/TimeSpan.js";
import { split, join } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/String.js";
import { ofArray, map } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/List.js";
import { int32ToString } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Util.js";
import { map as map_1 } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Array.js";

export class ShiftRuleDto extends Record {
    constructor(Id, OrgId, Name, ApplicableAfter, Tolerance, RuleDuration, EnforceAt, IsActive, Created, Updated) {
        super();
        this.Id = Id;
        this.OrgId = OrgId;
        this.Name = Name;
        this.ApplicableAfter = ApplicableAfter;
        this.Tolerance = Tolerance;
        this.RuleDuration = RuleDuration;
        this.EnforceAt = EnforceAt;
        this.IsActive = IsActive;
        this.Created = Created;
        this.Updated = Updated;
    }
}

export function ShiftRuleDto_$reflection() {
    return record_type("Domain.Dto.ShiftRule.ShiftRuleDto", [], ShiftRuleDto, () => [["Id", class_type("System.Guid")], ["OrgId", class_type("System.Guid")], ["Name", string_type], ["ApplicableAfter", class_type("System.TimeSpan")], ["Tolerance", class_type("System.TimeSpan")], ["RuleDuration", class_type("System.TimeSpan")], ["EnforceAt", list_type(enum_type("Domain.Entity.Shift.ShiftLogTypeEnum", int32_type, [["NoState", 0], ["WorkStarted", 1], ["BreakStarted", 2], ["BreakFinished", 3], ["WorkFinished", 4]]))], ["IsActive", bool_type], ["Created", class_type("System.DateTime")], ["Updated", class_type("System.DateTime")]]);
}

export function ShiftRuleDtoModule_ofShiftRule(shiftRule) {
    return new ShiftRuleDto(shiftRule.Id, shiftRule.OrgId, shiftRule.Name, shiftRule.ApplicableAfter, shiftRule.Tolerance, shiftRule.RuleDuration, shiftRule.EnforceAt, shiftRule.IsActive, shiftRule.Created, shiftRule.Updated);
}

export function ShiftRuleDtoModule_toShiftRule(shiftRule) {
    return new ShiftRule(shiftRule.Id, shiftRule.OrgId, shiftRule.Name, shiftRule.ApplicableAfter, shiftRule.Tolerance, shiftRule.RuleDuration, shiftRule.EnforceAt, shiftRule.IsActive, shiftRule.Created, shiftRule.Updated);
}

export class ShiftRuleRawDto extends Record {
    constructor(Id, OrgId, Name, ApplicableAfter, Tolerance, RuleDuration, EnforceAt, IsActive, Created, Updated) {
        super();
        this.Id = Id;
        this.OrgId = OrgId;
        this.Name = Name;
        this.ApplicableAfter = (ApplicableAfter | 0);
        this.Tolerance = (Tolerance | 0);
        this.RuleDuration = (RuleDuration | 0);
        this.EnforceAt = EnforceAt;
        this.IsActive = IsActive;
        this.Created = Created;
        this.Updated = Updated;
    }
}

export function ShiftRuleRawDto_$reflection() {
    return record_type("Domain.Dto.ShiftRule.ShiftRuleRawDto", [], ShiftRuleRawDto, () => [["Id", class_type("System.Guid")], ["OrgId", class_type("System.Guid")], ["Name", string_type], ["ApplicableAfter", int32_type], ["Tolerance", int32_type], ["RuleDuration", int32_type], ["EnforceAt", string_type], ["IsActive", bool_type], ["Created", class_type("System.DateTime")], ["Updated", class_type("System.DateTime")]]);
}

export function ShiftRuleRawDtoModule_ofShiftRule(shiftRule) {
    return new ShiftRuleRawDto(shiftRule.Id, shiftRule.OrgId, shiftRule.Name, ~~totalMinutes(shiftRule.ApplicableAfter), ~~totalMinutes(shiftRule.Tolerance), ~~totalMinutes(shiftRule.RuleDuration), join(",", map((x) => {
        let copyOfStruct = x;
        return int32ToString(copyOfStruct);
    }, shiftRule.EnforceAt)), shiftRule.IsActive, shiftRule.Created, shiftRule.Updated);
}

export function ShiftRuleRawDtoModule_toShiftRule(shiftRule) {
    return new ShiftRule(shiftRule.Id, shiftRule.OrgId, shiftRule.Name, fromMinutes(shiftRule.ApplicableAfter), fromMinutes(shiftRule.Tolerance), fromMinutes(shiftRule.RuleDuration), ofArray(map_1((x) => parseEnum(enum_type("Domain.Entity.Shift.ShiftLogTypeEnum", int32_type, [["NoState", 0], ["WorkStarted", 1], ["BreakStarted", 2], ["BreakFinished", 3], ["WorkFinished", 4]]), x), split(shiftRule.EnforceAt, [","], undefined, 0), Int32Array)), shiftRule.IsActive, shiftRule.Created, shiftRule.Updated);
}

