import { createElement } from "react";
import React from "react";
import { createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { Daisy_h2 } from "../../Component/Component.fs.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { ofArray, singleton, empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";

export function IndexView() {
    let elems_2, elems_1, value_14, value_21, value_23, value_25, value_39, value_46, elems;
    return createElement("div", createObj(ofArray([["className", join(" ", ["items-center", "justify-center", "py-8", "px-4", "sm:px-6", "lg:px-40"])], (elems_2 = [createElement("div", createObj(ofArray([["className", join(" ", ["space-y-4", "box-content", "bg-white", "border", "border-2", "py-12", "px-4", "sm:px-6", "lg:px-8"])], (elems_1 = [Daisy_h2("StressMaster"), createElement("div", createObj(Helpers_combineClasses("divider", empty()))), createElement("p", {
        children: "Stress Master helps manage stress using breathing exercise.",
    }), createElement("p", {
        className: join(" ", ["text-red-500", "text-xs", "font-bold"]),
        children: "Please consult your physician/doctor before doing this exercise.",
    }), createElement("br", {}), createElement("p", {
        children: "Open the app to see the main screen.",
    }), createElement("img", {
        src: "./image/StressMaster/main-screen.png",
    }), createElement("label", {
        className: join(" ", ["text-xs"]),
        children: "Main screen",
    }), createElement("p", createObj(singleton((value_14 = "Before using the app you can change the settings \n                            by clicking the settings icon at bottom right.\n                            This will show the settings screen.", ["children", value_14])))), createElement("img", {
        src: "./image/StressMaster/setting-screen.png",
    }), createElement("label", {
        className: join(" ", ["text-xs"]),
        children: "Setting screen",
    }), createElement("p", createObj(singleton((value_21 = "Adjust the settings to not strain while inhaling and exhaling and press\n                        the `Save` button to save the changes.", ["children", value_21])))), createElement("p", createObj(singleton((value_23 = "There are four reminders that can be set to help you remind when\n                        to do breathing exercise. The reminders can be disabled if required.", ["children", value_23])))), createElement("br", {}), createElement("p", createObj(singleton((value_25 = "To start a session click on the Start button and follow the \n                            instructions on screen to inhale and exhale.", ["children", value_25])))), createElement("img", {
        src: "./image/StressMaster/start-session.png",
    }), createElement("label", {
        className: join(" ", ["text-xs"]),
        children: "Start session",
    }), createElement("p", {
        children: "The countdown starts from the max repetition count number.",
    }), createElement("img", {
        src: "./image/StressMaster/inhale.png",
    }), createElement("label", {
        className: join(" ", ["text-xs"]),
        children: "Inhale",
    }), createElement("p", createObj(singleton((value_39 = "A second before the session switches to exhale the color changes\n                            and is an indication to stop inhaling and prepare to exhale.", ["children", value_39])))), createElement("img", {
        src: "./image/StressMaster/exhale.png",
    }), createElement("label", {
        className: join(" ", ["text-xs"]),
        children: "Exhale",
    }), createElement("p", createObj(singleton((value_46 = "This process continues till all the repetitions have finished.\n                            The session can be stopped if needed by clicking on the Cancel button in\n                            the bottom right hand corner.", ["children", value_46])))), createElement("div", createObj(Helpers_combineClasses("divider", empty()))), createElement("a", createObj(ofArray([["href", "https://play.google.com/store/apps/details?id=com.tenthdoor.stressmaster"], ["target", "_blank"], (elems = [createElement("img", {
        className: join(" ", ["btn", "p-0"]),
        src: "./image/google-play-badge.png",
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
}

