import { ShiftRule, ShiftRules, ShiftRulesModule_toString } from "../../../Logos.Shared/Domain/Entity/ShiftRule.fs.js";
import { map, cons, ofArray, tryFind } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { fromMinutes, fromHours } from "../../fable_modules/fable-library-js.4.19.3/TimeSpan.js";
import { utcNow } from "../../fable_modules/fable-library-js.4.19.3/Date.js";
import { singleton } from "../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library-js.4.19.3/Result.js";
import { ResultCE_result, ResultCE_ResultBuilder__Zero } from "../../fable_modules/FsToolkit.ErrorHandling.4.16.0/./ResultCE.fs.js";
import { saveUserData } from "./Auth.fs.js";
import { LocalUserData } from "../SystemService.fs.js";

export function upsertCompulsoryBreakAfter5HoursWork(orgId, isActive, shiftRules) {
    const ruleName = ShiftRulesModule_toString(new ShiftRules());
    const matchValue = tryFind((x) => (x.Name === ruleName), shiftRules);
    if (matchValue == null) {
        return cons(new ShiftRule("00000000-0000-0000-0000-000000000000", orgId, ruleName, fromHours(5), fromMinutes(10), fromMinutes(30), ofArray([3, 4]), true, utcNow(), utcNow()), shiftRules);
    }
    else {
        const x_1 = matchValue;
        const updatedRule = new ShiftRule(x_1.Id, x_1.OrgId, x_1.Name, x_1.ApplicableAfter, x_1.Tolerance, x_1.RuleDuration, x_1.EnforceAt, isActive, x_1.Created, x_1.Updated);
        return map((x_2) => {
            if (x_2.Name === ruleName) {
                return updatedRule;
            }
            else {
                return x_2;
            }
        }, shiftRules);
    }
}

export function upsertOrganisation(organisationService, systemService, selectedOrgId, isInsert, organisation) {
    return singleton.Delay(() => {
        const asyncResult_2 = organisationService.UpsertOrganisation(selectedOrgId, isInsert, organisation);
        return singleton.Bind(asyncResult_2, (input_1_3) => {
            const input_12 = input_1_3;
            if (input_12.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_12.fields[0]]));
            }
            else {
                let computation1;
                if (isInsert) {
                    let asyncResult;
                    const value_1 = systemService.Load("UserData");
                    asyncResult = singleton.Return(value_1);
                    computation1 = singleton.Bind(asyncResult, (input_1_1) => {
                        const input_6 = input_1_1;
                        if (input_6.tag === 1) {
                            return singleton.Return(new FSharpResult$2(1, [input_6.fields[0]]));
                        }
                        else {
                            const _arg_1 = input_6.fields[0];
                            if (_arg_1 == null) {
                                const value_4 = ResultCE_ResultBuilder__Zero(ResultCE_result);
                                return singleton.Return(value_4);
                            }
                            else {
                                const userData = _arg_1;
                                const x = saveUserData(systemService, new LocalUserData(userData.Username, true, userData.PurchasedServices, userData.Token, userData.SelectedOrganisation, userData.UserOrganisations));
                                return singleton.Bind(x, (x$0027) => {
                                    let value_2;
                                    const input_2 = x$0027;
                                    value_2 = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [undefined])));
                                    return singleton.Return(value_2);
                                });
                            }
                        }
                    });
                }
                else {
                    const value_7 = ResultCE_ResultBuilder__Zero(ResultCE_result);
                    computation1 = singleton.Return(value_7);
                }
                const computation2 = singleton.Delay(() => singleton.Return(new FSharpResult$2(0, [input_12.fields[0]])));
                return singleton.Bind(computation1, (input_1_2) => {
                    const input_9 = input_1_2;
                    if (input_9.tag === 1) {
                        return singleton.Return(new FSharpResult$2(1, [input_9.fields[0]]));
                    }
                    else {
                        return computation2;
                    }
                });
            }
        });
    });
}

export function getXeroClientId(organisationService, selectedOrgId) {
    return singleton.Delay(() => organisationService.GetXeroClientId(selectedOrgId));
}

export function getOrganisation(organisationService, selectedOrgId) {
    return singleton.Delay(() => organisationService.GetOrganisation(selectedOrgId));
}

export function saveLogo(organisationService, selectedOrgId, logo) {
    return singleton.Delay(() => organisationService.SaveLogo(selectedOrgId, logo));
}

export function getLogo(organisationService, selectedOrgId) {
    return singleton.Delay(() => organisationService.GetLogo(selectedOrgId));
}

