import { value } from "../Entity/Email.fs.js";
import { value as value_1 } from "../Entity/Phone.fs.js";
import { Record } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { bool_type, list_type, record_type, string_type, option_type, class_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { AddressDtoModule_toAddress, AddressDtoModule_ofAddress, AddressDto_$reflection } from "./Address.fs.js";
import { CustomerDetail, XeroCustomer, Customer, Contact_$reflection } from "../Entity/Customer.fs.js";
import { empty, map } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/List.js";

export function ContactDtoModule_toContactString(contact) {
    const matchValue = contact.ContactType;
    if (matchValue.tag === 1) {
        return value(matchValue.fields[0]);
    }
    else {
        return value_1(matchValue.fields[0]);
    }
}

export function ContactDtoModule_toContactTypeAndValue(contact) {
    const matchValue = contact.ContactType;
    if (matchValue.tag === 1) {
        return `Email: ${value(matchValue.fields[0])}`;
    }
    else {
        return `Phone: ${value_1(matchValue.fields[0])}`;
    }
}

export class CustomerMemberDto extends Record {
    constructor(Id, FirstName, MiddleName, LastName) {
        super();
        this.Id = Id;
        this.FirstName = FirstName;
        this.MiddleName = MiddleName;
        this.LastName = LastName;
    }
}

export function CustomerMemberDto_$reflection() {
    return record_type("Domain.Dto.Customer.CustomerMemberDto", [], CustomerMemberDto, () => [["Id", option_type(class_type("System.Guid"))], ["FirstName", string_type], ["MiddleName", option_type(string_type)], ["LastName", string_type]]);
}

export function CustomerMemberDtoModule_ofCustomerMember(customerMember) {
    return new CustomerMemberDto(customerMember.Id, customerMember.FirstName, customerMember.MiddleName, customerMember.LastName);
}

export class CustomerDto extends Record {
    constructor(Id, CustomerGroupId, BusinessName, Title, FirstName, MiddleName, LastName, Addresses, Contacts, Members, XeroCustomerId, IsActive) {
        super();
        this.Id = Id;
        this.CustomerGroupId = CustomerGroupId;
        this.BusinessName = BusinessName;
        this.Title = Title;
        this.FirstName = FirstName;
        this.MiddleName = MiddleName;
        this.LastName = LastName;
        this.Addresses = Addresses;
        this.Contacts = Contacts;
        this.Members = Members;
        this.XeroCustomerId = XeroCustomerId;
        this.IsActive = IsActive;
    }
}

export function CustomerDto_$reflection() {
    return record_type("Domain.Dto.Customer.CustomerDto", [], CustomerDto, () => [["Id", option_type(class_type("System.Guid"))], ["CustomerGroupId", option_type(class_type("System.Guid"))], ["BusinessName", option_type(string_type)], ["Title", option_type(string_type)], ["FirstName", string_type], ["MiddleName", option_type(string_type)], ["LastName", string_type], ["Addresses", list_type(AddressDto_$reflection())], ["Contacts", list_type(Contact_$reflection())], ["Members", list_type(CustomerMemberDto_$reflection())], ["XeroCustomerId", option_type(class_type("System.Guid"))], ["IsActive", bool_type]]);
}

export function CustomerDtoModule_ofCustomer(customer) {
    return new CustomerDto(customer.Id, customer.CustomerGroupId, customer.BusinessName, customer.Title, customer.FirstName, customer.MiddleName, customer.LastName, map(AddressDtoModule_ofAddress, customer.Addresses), customer.Contacts, empty(), customer.XeroCustomerId, customer.IsActive);
}

export function CustomerDtoModule_toCustomer(customer) {
    return new Customer(customer.Id, customer.CustomerGroupId, customer.CustomerGroupId, customer.BusinessName, customer.Title, customer.FirstName, customer.MiddleName, customer.LastName, map((address) => AddressDtoModule_toAddress(customer.Id, address), customer.Addresses), customer.Contacts, customer.XeroCustomerId, customer.IsActive);
}

export class XeroCustomerDto extends Record {
    constructor(Id, CustomerName) {
        super();
        this.Id = Id;
        this.CustomerName = CustomerName;
    }
}

export function XeroCustomerDto_$reflection() {
    return record_type("Domain.Dto.Customer.XeroCustomerDto", [], XeroCustomerDto, () => [["Id", class_type("System.Guid")], ["CustomerName", string_type]]);
}

export function XeroCustomerDtoModule_ofXeroCustomer(xeroCustomer) {
    return new XeroCustomerDto(xeroCustomer.Id, xeroCustomer.CustomerName);
}

export function XeroCustomerDtoModule_toXeroCustomer(xeroCustomer) {
    return new XeroCustomer(xeroCustomer.Id, xeroCustomer.CustomerName);
}

export class CustomerDetailDto extends Record {
    constructor(Id, BusinessName) {
        super();
        this.Id = Id;
        this.BusinessName = BusinessName;
    }
}

export function CustomerDetailDto_$reflection() {
    return record_type("Domain.Dto.Customer.CustomerDetailDto", [], CustomerDetailDto, () => [["Id", class_type("System.Guid")], ["BusinessName", string_type]]);
}

export function CustomerDetailDtoModule_ofCustomer(customer) {
    return new CustomerDetailDto(customer.Id, customer.BusinessName);
}

export function CustomerDetailDtoModule_toCustomer(customer) {
    return new CustomerDetail(customer.Id, customer.BusinessName);
}

