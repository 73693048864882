import { toString, Union, Record } from "../../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type, tuple_type, record_type, string_type, decimal_type, list_type, class_type } from "../../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { VehicleLogSummaryByTag, VehicleLogTag, VehicleLogSummaryByTag_$reflection, VehicleLogSummaryByVehicle_$reflection, VehicleLogTag_$reflection } from "../../../../../Logos.Shared/Domain/Entity/LogMaster.fs.js";
import { DistanceUnit, DistanceUnitModule_toString, MeasurementSystem_$reflection } from "../../../../../Logos.Shared/Domain/Entity/MeasurementUnits.fs.js";
import { Busy, Busy_$reflection } from "../../../SharedView.fs.js";
import { FSharpResult$2 } from "../../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { getVehicleLogSummary } from "../../../../AppDomain/UseCase/Vehicle.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../../Infrastructure/AppRoot.fs.js";
import { Result_traverse } from "../../../../../Logos.Shared/Util.fs.js";
import { ofArray, toArray, sumBy, singleton as singleton_1, isEmpty, empty, tryFind, map } from "../../../../fable_modules/fable-library-js.4.19.3/List.js";
import { VehicleLogSummaryByTagDtoModule_toVehicleLogSummary, VehicleLogSummaryByVehicleDtoModule_toVehicleLogSummary } from "../../../../../Logos.Shared/Domain/Dto/LogMaster/VehicleLog.fs.js";
import { defaultArg } from "../../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { fromDateTime } from "../../../../fable_modules/fable-library-js.4.19.3/DateOnly.js";
import { toNumber, toString as toString_1, op_Addition, fromParts } from "../../../../fable_modules/fable-library-js.4.19.3/Decimal.js";
import Decimal from "../../../../fable_modules/fable-library-js.4.19.3/Decimal.js";
import { Cmd_none } from "../../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createElement } from "react";
import React from "react";
import { createObj } from "../../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { join } from "../../../../fable_modules/fable-library-js.4.19.3/String.js";
import { reactApi } from "../../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { map as map_1, delay, toList } from "../../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { TimeSpan_toString } from "../../../../AppDomain/Util.fs.js";
import { doughnut } from "../../../Component/Doughnut.fs.js";
import { totalHours } from "../../../../fable_modules/fable-library-js.4.19.3/TimeSpan.js";
import { React_useElmish_Z6C327F2E } from "../../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { indexView as indexView_1 } from "../../../Component/Widget.fs.js";

export class State extends Record {
    constructor(DateFrom, DateTo, VehiclesToSearchFor, VehicleLogTags, AllVehicleTags, VehicleLogSummariesByVehicle, VehicleLogSummariesByTag, TotalTripDurationByVehicle, TotalDistanceTraveledByVehicle, TotalTripDurationByTag, TotalDistanceTraveledByTag, UserMeasurementSystem, IsBusy, Errors) {
        super();
        this.DateFrom = DateFrom;
        this.DateTo = DateTo;
        this.VehiclesToSearchFor = VehiclesToSearchFor;
        this.VehicleLogTags = VehicleLogTags;
        this.AllVehicleTags = AllVehicleTags;
        this.VehicleLogSummariesByVehicle = VehicleLogSummariesByVehicle;
        this.VehicleLogSummariesByTag = VehicleLogSummariesByTag;
        this.TotalTripDurationByVehicle = TotalTripDurationByVehicle;
        this.TotalDistanceTraveledByVehicle = TotalDistanceTraveledByVehicle;
        this.TotalTripDurationByTag = TotalTripDurationByTag;
        this.TotalDistanceTraveledByTag = TotalDistanceTraveledByTag;
        this.UserMeasurementSystem = UserMeasurementSystem;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.LogMaster.VehicleLogSummary.State", [], State, () => [["DateFrom", class_type("System.DateOnly")], ["DateTo", class_type("System.DateOnly")], ["VehiclesToSearchFor", list_type(class_type("System.Guid"))], ["VehicleLogTags", list_type(class_type("System.Guid"))], ["AllVehicleTags", list_type(VehicleLogTag_$reflection())], ["VehicleLogSummariesByVehicle", list_type(VehicleLogSummaryByVehicle_$reflection())], ["VehicleLogSummariesByTag", list_type(VehicleLogSummaryByTag_$reflection())], ["TotalTripDurationByVehicle", class_type("System.TimeSpan")], ["TotalDistanceTraveledByVehicle", decimal_type], ["TotalTripDurationByTag", class_type("System.TimeSpan")], ["TotalDistanceTraveledByTag", decimal_type], ["UserMeasurementSystem", MeasurementSystem_$reflection()], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetVehicleLogSummaryResponse", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.LogMaster.VehicleLogSummary.Msg", [], Msg, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(list_type(VehicleLogSummaryByVehicle_$reflection()), list_type(VehicleLogSummaryByTag_$reflection())), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", tuple_type(list_type(VehicleLogSummaryByVehicle_$reflection()), list_type(VehicleLogSummaryByTag_$reflection()))]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Exception")]]]);
}

export function getVehicleLogSummaryCmd(state) {
    return cmdOfAsync((Item) => (new Msg(0, [Item])), (Item_1) => (new Msg(1, [Item_1])), () => singleton.Delay(() => {
        const asyncResult_1 = getVehicleLogSummary(appRoot.VehicleService, AppRootModule_getSelectedOrgId(), state.DateFrom, state.DateTo);
        return singleton.Bind(asyncResult_1, (input_1_3) => {
            const input_10 = input_1_3;
            if (input_10.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_10.fields[0]]));
            }
            else {
                const result_1 = input_10.fields[0];
                let asyncResult;
                const value_1 = Result_traverse(map(VehicleLogSummaryByVehicleDtoModule_toVehicleLogSummary, result_1[0]));
                asyncResult = singleton.Return(value_1);
                return singleton.Bind(asyncResult, (input_1_2) => {
                    const input_7 = input_1_2;
                    if (input_7.tag === 1) {
                        return singleton.Return(new FSharpResult$2(1, [input_7.fields[0]]));
                    }
                    else {
                        let x_3;
                        const value_1_1 = Result_traverse(map(VehicleLogSummaryByTagDtoModule_toVehicleLogSummary, result_1[1]));
                        x_3 = singleton.Return(value_1_1);
                        return singleton.Bind(x_3, (x$0027) => {
                            let value_4;
                            const input_3 = x$0027;
                            value_4 = ((input_3.tag === 1) ? (new FSharpResult$2(1, [input_3.fields[0]])) : (new FSharpResult$2(0, [[input_7.fields[0], map((x_2) => {
                                let input_1;
                                return new VehicleLogSummaryByTag(new VehicleLogTag(x_2.Tag.Id, defaultArg((input_1 = tryFind((y) => (y.Id === x_2.Tag.Id), state.AllVehicleTags), (input_1 == null) ? undefined : input_1.Name), x_2.Tag.Id)), x_2.TripCount, x_2.TotalTripDuration, x_2.TotalDistanceTraveled, x_2.DistanceUnit);
                            }, input_3.fields[0])]])));
                            return singleton.Return(value_4);
                        });
                    }
                });
            }
        });
    }));
}

export function init(dateRange, vehiclesToSearchFor, vehicleLogTags, allVehicleTags, userMeasurementSystem) {
    const state = new State(fromDateTime(dateRange.startDate), fromDateTime(dateRange.endDate), vehiclesToSearchFor, vehicleLogTags, allVehicleTags, empty(), empty(), 0, fromParts(0, 0, 0, false, 0), 0, fromParts(0, 0, 0, false, 0), userMeasurementSystem, new Busy(0, []), empty());
    return [state, (isEmpty(vehiclesToSearchFor) ? true : isEmpty(vehicleLogTags)) ? Cmd_none() : getVehicleLogSummaryCmd(state)];
}

export function update(msg, state) {
    if (msg.tag === 1) {
        return [new State(state.DateFrom, state.DateTo, state.VehiclesToSearchFor, state.VehicleLogTags, state.AllVehicleTags, state.VehicleLogSummariesByVehicle, state.VehicleLogSummariesByTag, state.TotalTripDurationByVehicle, state.TotalDistanceTraveledByVehicle, state.TotalTripDurationByTag, state.TotalDistanceTraveledByTag, state.UserMeasurementSystem, state.IsBusy, singleton_1(toString(msg.fields[0]))), Cmd_none()];
    }
    else if (msg.fields[0].tag === 1) {
        return [new State(state.DateFrom, state.DateTo, state.VehiclesToSearchFor, state.VehicleLogTags, state.AllVehicleTags, empty(), state.VehicleLogSummariesByTag, state.TotalTripDurationByVehicle, state.TotalDistanceTraveledByVehicle, state.TotalTripDurationByTag, state.TotalDistanceTraveledByTag, state.UserMeasurementSystem, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
    }
    else {
        return [new State(state.DateFrom, state.DateTo, state.VehiclesToSearchFor, state.VehicleLogTags, state.AllVehicleTags, msg.fields[0].fields[0][0], msg.fields[0].fields[0][1], sumBy((x) => x.TotalTripDuration, msg.fields[0].fields[0][0], {
            GetZero: () => 0,
            Add: (x_1, y) => (x_1 + y),
        }), sumBy((x_2) => x_2.TotalDistanceTraveled, msg.fields[0].fields[0][0], {
            GetZero: () => (new Decimal("0")),
            Add: op_Addition,
        }), sumBy((x_4) => x_4.TotalTripDuration, msg.fields[0].fields[0][1], {
            GetZero: () => 0,
            Add: (x_5, y_2) => (x_5 + y_2),
        }), sumBy((x_6) => x_6.TotalDistanceTraveled, msg.fields[0].fields[0][1], {
            GetZero: () => (new Decimal("0")),
            Add: op_Addition,
        }), state.UserMeasurementSystem, new Busy(1, []), state.Errors), Cmd_none()];
    }
}

function indexViewByVehicle(state) {
    let elems_6, children_2, children, children_4, children_6, elems_5, elems_3, elems_4, elems_7;
    const labels = toArray(map((x) => x.Vehicle, state.VehicleLogSummariesByVehicle));
    const distanceUnit = (state.UserMeasurementSystem.tag === 1) ? DistanceUnitModule_toString(new DistanceUnit(1, [])) : DistanceUnitModule_toString(new DistanceUnit(0, []));
    const children_10 = ofArray([createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["flex", "w-full"])], (elems_6 = [(children_2 = singleton_1((children = ofArray([createElement("th", {
        width: 5 + "%",
        children: "Vehicle",
    }), createElement("th", {
        className: join(" ", ["text-right"]),
        width: 15 + "%",
        children: "Duration (D.H:M)",
    }), createElement("th", {
        className: join(" ", ["text-right"]),
        width: 15 + "%",
        children: `Distance (${distanceUnit})`,
    })]), createElement("tr", {
        children: reactApi.Children.toArray(Array.from(children)),
    }))), createElement("thead", {
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), (children_4 = toList(delay(() => map_1((vehicleLogSummary) => {
        let elems_2, elems, elems_1;
        return createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top", "gap-4"])], (elems_2 = [createElement("td", {
            children: [vehicleLogSummary.Vehicle],
        }), createElement("td", createObj(ofArray([["className", join(" ", ["text-right"])], (elems = [TimeSpan_toString(vehicleLogSummary.TotalTripDuration)], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement("td", createObj(ofArray([["className", join(" ", ["text-right"])], (elems_1 = [toString_1(vehicleLogSummary.TotalDistanceTraveled)], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
    }, state.VehicleLogSummariesByVehicle))), createElement("tbody", {
        children: reactApi.Children.toArray(Array.from(children_4)),
    })), (children_6 = singleton_1(createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top", "gap-4"])], (elems_5 = [createElement("td", {
        children: ["Total"],
    }), createElement("td", createObj(ofArray([["className", join(" ", ["text-right"])], (elems_3 = [TimeSpan_toString(state.TotalTripDurationByVehicle)], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("td", createObj(ofArray([["className", join(" ", ["text-right"])], (elems_4 = [toString_1(state.TotalDistanceTraveledByVehicle)], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", reactApi.Children.toArray(Array.from(elems_5))])])))), createElement("tfoot", {
        children: reactApi.Children.toArray(Array.from(children_6)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_6))])])))), createElement("div", createObj(Helpers_combineClasses("divider", empty()))), createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-1", "lg:grid-cols-2", "gap-4"])], (elems_7 = [doughnut("Duration", labels, toArray(map((x_1) => ~~totalHours(x_1.TotalTripDuration), state.VehicleLogSummariesByVehicle)), [], []), doughnut(`Distance (${distanceUnit})`, labels, toArray(map((x_2) => ~~toNumber(x_2.TotalDistanceTraveled), state.VehicleLogSummariesByVehicle)), [], [])], ["children", reactApi.Children.toArray(Array.from(elems_7))])])))]);
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_10)),
    });
}

function indexViewByTag(state) {
    let elems_6, children_2, children, children_4, children_6, elems_5, elems_3, elems_4, elems_7;
    const labels = toArray(map((x) => x.Tag.Name, state.VehicleLogSummariesByTag));
    const distanceUnit = (state.UserMeasurementSystem.tag === 1) ? DistanceUnitModule_toString(new DistanceUnit(1, [])) : DistanceUnitModule_toString(new DistanceUnit(0, []));
    const children_10 = ofArray([createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["flex", "w-full"])], (elems_6 = [(children_2 = singleton_1((children = ofArray([createElement("th", {
        width: 5 + "%",
        children: "Tag",
    }), createElement("th", {
        className: join(" ", ["text-right"]),
        width: 15 + "%",
        children: "Duration (D.H:M)",
    }), createElement("th", {
        className: join(" ", ["text-right"]),
        width: 15 + "%",
        children: `Distance (${distanceUnit})`,
    })]), createElement("tr", {
        children: reactApi.Children.toArray(Array.from(children)),
    }))), createElement("thead", {
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), (children_4 = toList(delay(() => map_1((vehicleLogSummary) => {
        let elems_2, elems, elems_1;
        return createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top", "gap-4"])], (elems_2 = [createElement("td", {
            children: [vehicleLogSummary.Tag.Name],
        }), createElement("td", createObj(ofArray([["className", join(" ", ["text-right"])], (elems = [TimeSpan_toString(vehicleLogSummary.TotalTripDuration)], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement("td", createObj(ofArray([["className", join(" ", ["text-right"])], (elems_1 = [toString_1(vehicleLogSummary.TotalDistanceTraveled)], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
    }, state.VehicleLogSummariesByTag))), createElement("tbody", {
        children: reactApi.Children.toArray(Array.from(children_4)),
    })), (children_6 = singleton_1(createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top", "gap-4"])], (elems_5 = [createElement("td", {
        children: ["Total"],
    }), createElement("td", createObj(ofArray([["className", join(" ", ["text-right"])], (elems_3 = [TimeSpan_toString(state.TotalTripDurationByTag)], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))), createElement("td", createObj(ofArray([["className", join(" ", ["text-right"])], (elems_4 = [toString_1(state.TotalDistanceTraveledByTag)], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))], ["children", reactApi.Children.toArray(Array.from(elems_5))])])))), createElement("tfoot", {
        children: reactApi.Children.toArray(Array.from(children_6)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_6))])])))), createElement("div", createObj(Helpers_combineClasses("divider", empty()))), createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-1", "lg:grid-cols-2", "gap-4"])], (elems_7 = [doughnut("Duration", labels, toArray(map((x_1) => ~~totalHours(x_1.TotalTripDuration), state.VehicleLogSummariesByTag)), [], []), doughnut("Distance", labels, toArray(map((x_2) => ~~toNumber(x_2.TotalDistanceTraveled), state.VehicleLogSummariesByTag)), [], [])], ["children", reactApi.Children.toArray(Array.from(elems_7))])])))]);
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_10)),
    });
}

export function indexView(indexViewInputProps) {
    let init_1, elems;
    const userMeasurementSystem = indexViewInputProps.userMeasurementSystem;
    const allVehicleLogTags = indexViewInputProps.allVehicleLogTags;
    const vehicleLogTags = indexViewInputProps.vehicleLogTags;
    const vehiclesToSearchFor = indexViewInputProps.vehiclesToSearchFor;
    const dateRange = indexViewInputProps.dateRange;
    const state_1 = ((init_1 = init(dateRange, vehiclesToSearchFor, vehicleLogTags, allVehicleLogTags, userMeasurementSystem), React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [dateRange, vehicleLogTags])))[0];
    return createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-1", "lg:grid-cols-2", "gap-4", "m-4"])], (elems = [createElement(indexView_1, {
        title: "Vehicle Log Summary",
        elements: [createElement(indexViewByVehicle, state_1)],
    }), createElement(indexView_1, {
        title: "Vehicle Tag Summary",
        elements: [createElement(indexViewByTag, state_1)],
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])));
}

