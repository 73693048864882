import { toString, Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { UserMembershipDto_$reflection } from "../../../../Logos.Shared/Domain/Dto/User.fs.js";
import { class_type, union_type, record_type, string_type, bool_type, list_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Busy, Busy_$reflection } from "../../SharedView.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { getUserMemberships as getUserMemberships_1 } from "../../../AppDomain/UseCase/User.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../Infrastructure/AppRoot.fs.js";
import { ofArray, singleton as singleton_1, empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../../Component/ViewContainer.fs.js";
import { Daisy_error, Daisy_h4 } from "../../Component/Component.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { map, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";

export class State extends Record {
    constructor(UserMemberships, IsActive, IsBusy, Errors) {
        super();
        this.UserMemberships = UserMemberships;
        this.IsActive = IsActive;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.UserMemberships.State", [], State, () => [["UserMemberships", list_type(UserMembershipDto_$reflection())], ["IsActive", bool_type], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetMembershipsResponse", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.UserMemberships.Msg", [], Msg, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(UserMembershipDto_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", list_type(UserMembershipDto_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Exception")]]]);
}

export function getUserMemberships() {
    return cmdOfAsync((Item) => (new Msg(0, [Item])), (Item_1) => (new Msg(1, [Item_1])), () => singleton.Delay(() => getUserMemberships_1(appRoot.UserService, AppRootModule_getSelectedOrgId())));
}

export function init() {
    return [new State(empty(), false, new Busy(1, []), empty()), getUserMemberships()];
}

export function update(msg, state) {
    if (msg.tag === 1) {
        return [new State(state.UserMemberships, state.IsActive, new Busy(0, []), singleton_1(toString(msg.fields[0]))), Cmd_none()];
    }
    else if (msg.fields[0].tag === 1) {
        return [new State(state.UserMemberships, state.IsActive, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
    }
    else {
        return [new State(msg.fields[0].fields[0], state.IsActive, state.IsBusy, state.Errors), Cmd_none()];
    }
}

export function IndexView() {
    let children, elems_1, children_5, children_3, children_7;
    const state_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init, update, (_arg, _arg_1) => {
    }), undefined, [])[0];
    return container([(children = singleton_1(Daisy_h4("User memberships")), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    })), Daisy_error(state_1.Errors), createElement("div", createObj(Helpers_combineClasses("divider", empty()))), createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["flex", "w-full"])], (elems_1 = [(children_5 = singleton_1((children_3 = ofArray([createElement("th", {
        width: 20 + "%",
        children: "Organisation",
    }), createElement("th", {
        width: 20 + "%",
        children: "Membership Active",
    })]), createElement("tr", {
        children: reactApi.Children.toArray(Array.from(children_3)),
    }))), createElement("thead", {
        children: reactApi.Children.toArray(Array.from(children_5)),
    })), (children_7 = toList(delay(() => map((membership) => {
        let elems;
        return createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top", "hover"])], (elems = [createElement("td", {
            children: [membership.OrganisationName],
        }), createElement("td", {
            children: [toString(membership.IsActive)],
        })], ["children", reactApi.Children.toArray(Array.from(elems))])])));
    }, state_1.UserMemberships))), createElement("tbody", {
        children: reactApi.Children.toArray(Array.from(children_7)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))))]);
}

