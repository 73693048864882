import { Union } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { ofArray, map } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/List.js";

export class Status extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["All", "Active", "Inactive"];
    }
}

export function Status_$reflection() {
    return union_type("Domain.Entity.Common.Status", [], Status, () => [[], [], []]);
}

export function StatusModule_ofString(str) {
    switch (str) {
        case "All":
            return new Status(0, []);
        case "Active":
            return new Status(1, []);
        case "Inactive":
            return new Status(2, []);
        default:
            throw new Error("Invalid status");
    }
}

export function StatusModule_toString(status) {
    switch (status.tag) {
        case 1:
            return "Active";
        case 2:
            return "Inactive";
        default:
            return "All";
    }
}

export const StatusModule_allStatuses = map(StatusModule_toString, ofArray([new Status(0, []), new Status(1, []), new Status(2, [])]));

export function StatusModule_toBool(_arg) {
    switch (_arg.tag) {
        case 2:
            return false;
        case 0:
            return true;
        default:
            return true;
    }
}

