import { toString, Union, Record } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { OrganisationDetail, OrganisationDetail_$reflection } from "../../../Logos.Shared/Domain/Entity/Organisation.fs.js";
import { class_type, union_type, tuple_type, record_type, lambda_type, unit_type, bool_type, string_type, list_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../Infrastructure/AppRoot.fs.js";
import { cons, empty, tryFind, ofArray, singleton as singleton_1 } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { defaultArg } from "../../fable_modules/fable-library-js.4.19.3/Option.js";
import { tryFind as tryFind_1 } from "../../fable_modules/fable-library-js.4.19.3/Array.js";
import { BrowserData, LocalUserData } from "../../AppDomain/SystemService.fs.js";
import { getPurchasedServices } from "../../AppDomain/UseCase/Auth.fs.js";
import { Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { UserRole } from "../../../Logos.Shared/Domain/Entity/User.fs.js";
import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../Component/ViewContainer.fs.js";
import { createObj } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Daisy_h6 } from "../Component/Component.fs.js";
import { Helpers_combineClasses } from "../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { map, delay, toList } from "../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/./Interop.fs.js";

export class State extends Record {
    constructor(OrganisationDetails, SelectedOrganisation, ShowNoneOption, IsValidOrganisationSelected, Errors) {
        super();
        this.OrganisationDetails = OrganisationDetails;
        this.SelectedOrganisation = SelectedOrganisation;
        this.ShowNoneOption = ShowNoneOption;
        this.IsValidOrganisationSelected = IsValidOrganisationSelected;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.OrganisationSelector.State", [], State, () => [["OrganisationDetails", list_type(OrganisationDetail_$reflection())], ["SelectedOrganisation", string_type], ["ShowNoneOption", bool_type], ["IsValidOrganisationSelected", lambda_type(bool_type, unit_type)], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["OrganisationDetailsLoaded", "SetOrganisation", "OrganisationSet", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.OrganisationSelector.Msg", [], Msg, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(string_type, list_type(OrganisationDetail_$reflection())), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", tuple_type(string_type, list_type(OrganisationDetail_$reflection()))]], [["ErrorValue", list_type(string_type)]]])]], [["Item", string_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [string_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", string_type]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Exception")]]]);
}

export function loadOrganisations() {
    return cmdOfAsync((Item) => (new Msg(0, [Item])), (Item_1) => (new Msg(3, [Item_1])), () => singleton.Delay(() => {
        let asyncResult;
        const value_1 = appRoot.SystemService.Load("UserData");
        asyncResult = singleton.Return(value_1);
        return singleton.Bind(asyncResult, (input_1_2) => {
            const input_9 = input_1_2;
            if (input_9.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_9.fields[0]]));
            }
            else {
                let x_2;
                let value_1_1;
                const option_1 = input_9.fields[0];
                value_1_1 = ((option_1 == null) ? (new FSharpResult$2(1, [singleton_1("UserData not found.")])) : (new FSharpResult$2(0, [option_1])));
                x_2 = singleton.Return(value_1_1);
                return singleton.Bind(x_2, (x$0027) => {
                    let result_3, userOrganisations, input_3, x_1, input_1;
                    let value_5;
                    const input_5 = x$0027;
                    value_5 = ((input_5.tag === 1) ? (new FSharpResult$2(1, [input_5.fields[0]])) : (new FSharpResult$2(0, [(result_3 = input_5.fields[0], (userOrganisations = ofArray(result_3.UserOrganisations), [defaultArg((input_3 = result_3.SelectedOrganisation, (input_3 == null) ? undefined : ((x_1 = input_3, defaultArg((input_1 = tryFind((y) => (y.Id === x_1), userOrganisations), (input_1 == null) ? undefined : input_1.Name), "")))), ""), userOrganisations]))])));
                    return singleton.Return(value_5);
                });
            }
        });
    }));
}

export function setOrganisationCmd(organisationName) {
    return cmdOfAsync((Item) => (new Msg(2, [Item])), (Item_1) => (new Msg(3, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_2;
        const value_1 = appRoot.SystemService.Load("UserData");
        asyncResult_2 = singleton.Return(value_1);
        return singleton.Bind(asyncResult_2, (input_1_4) => {
            const input_13 = input_1_4;
            if (input_13.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_13.fields[0]]));
            }
            else {
                let asyncResult_1;
                let value_1_1;
                const option_1 = input_13.fields[0];
                value_1_1 = ((option_1 == null) ? (new FSharpResult$2(1, [singleton_1("User data not found.")])) : (new FSharpResult$2(0, [option_1])));
                asyncResult_1 = singleton.Return(value_1_1);
                return singleton.Bind(asyncResult_1, (input_1_3) => {
                    let input_1;
                    const input_10 = input_1_3;
                    if (input_10.tag === 1) {
                        return singleton.Return(new FSharpResult$2(1, [input_10.fields[0]]));
                    }
                    else {
                        const userData_1 = input_10.fields[0];
                        const updatedUserData = new LocalUserData(userData_1.Username, userData_1.IsMember, userData_1.PurchasedServices, userData_1.Token, (input_1 = tryFind_1((x_1) => (x_1.Name === organisationName), userData_1.UserOrganisations), (input_1 == null) ? undefined : input_1.Id), userData_1.UserOrganisations);
                        let asyncResult;
                        const value_1_2 = appRoot.SystemService.Save(new BrowserData(updatedUserData));
                        asyncResult = singleton.Return(value_1_2);
                        return singleton.Bind(asyncResult, (input_1_2) => {
                            const input_7 = input_1_2;
                            if (input_7.tag === 1) {
                                return singleton.Return(new FSharpResult$2(1, [input_7.fields[0]]));
                            }
                            else {
                                const x_3 = getPurchasedServices(appRoot.SystemService, appRoot.OrganisationService, AppRootModule_getSelectedOrgId());
                                return singleton.Bind(x_3, (x$0027) => {
                                    let value_4;
                                    const input_3 = x$0027;
                                    value_4 = ((input_3.tag === 1) ? (new FSharpResult$2(1, [input_3.fields[0]])) : (new FSharpResult$2(0, [organisationName])));
                                    return singleton.Return(value_4);
                                });
                            }
                        });
                    }
                });
            }
        });
    }));
}

export function init(showNoneOption, isValidOrganisationSelected) {
    return [new State(empty(), "", showNoneOption, isValidOrganisationSelected, empty()), loadOrganisations()];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [state, setOrganisationCmd(msg.fields[0])];
        case 2:
            if (msg.fields[0].tag === 1) {
                return [new State(state.OrganisationDetails, state.SelectedOrganisation, state.ShowNoneOption, state.IsValidOrganisationSelected, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                state.IsValidOrganisationSelected(msg.fields[0].fields[0] !== "None");
                return [new State(state.OrganisationDetails, msg.fields[0].fields[0], state.ShowNoneOption, state.IsValidOrganisationSelected, state.Errors), Cmd_none()];
            }
        case 3:
            return [new State(state.OrganisationDetails, state.SelectedOrganisation, state.ShowNoneOption, state.IsValidOrganisationSelected, singleton_1(toString(msg.fields[0]))), Cmd_none()];
        default:
            if (msg.fields[0].tag === 1) {
                return [new State(state.OrganisationDetails, state.SelectedOrganisation, state.ShowNoneOption, state.IsValidOrganisationSelected, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.ShowNoneOption ? cons(new OrganisationDetail("00000000-0000-0000-0000-000000000000", "None", new UserRole(3, []), empty(), true, false, false, false, false), msg.fields[0].fields[0][1]) : msg.fields[0].fields[0][1], msg.fields[0].fields[0][0], state.ShowNoneOption, state.IsValidOrganisationSelected, state.Errors), Cmd_none()];
            }
    }
}

export function IndexView(props) {
    let elems_1, elems;
    let patternInput;
    const init_1 = init(props.ShowNoneOption, props.IsValidOrganisationSelected);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    return container([createElement("div", createObj(singleton_1((elems_1 = [Daisy_h6("Select an Organisation"), createElement("select", createObj(Helpers_combineClasses("select", ofArray([["className", "select-bordered"], ["className", "select-sm"], ["placeholder", "Organisation"], ["value", state_1.SelectedOrganisation], ["onChange", (ev) => {
        patternInput[1](new Msg(1, [ev.target.value]));
    }], (elems = toList(delay(() => map((organisation) => createElement("option", {
        value: organisation.Name,
        children: organisation.Name,
    }), state_1.OrganisationDetails))), ["children", reactApi.Children.toArray(Array.from(elems))])]))))], ["children", reactApi.Children.toArray(Array.from(elems_1))]))))]);
}

