import { Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { DateRange, DateRange_$reflection } from "../../Component/ReactTailwindDatePicker.fs.js";
import { union_type, record_type, list_type, string_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { now, addDays } from "../../../fable_modules/fable-library-js.4.19.3/Date.js";
import { singleton, ofArray, empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createElement } from "react";
import React from "react";
import { appRoot } from "../../../Infrastructure/AppRoot.fs.js";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../../Component/ViewContainer.fs.js";
import { createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Daisy_error, Daisy_h2 } from "../../Component/Component.fs.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { IndexView as IndexView_1 } from "./Component/WorkersAtJob.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { IndexView as IndexView_2 } from "./Component/WorkerJobAllocation.fs.js";
import { IndexView as IndexView_3 } from "./Component/WorkForceSkills.fs.js";

export class State extends Record {
    constructor(DateRange, Errors) {
        super();
        this.DateRange = DateRange;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.WorkMate.WorkMateDashboard.State", [], State, () => [["DateRange", DateRange_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["DateRangeSelected"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.WorkMate.WorkMateDashboard.Msg", [], Msg, () => [[["Item", DateRange_$reflection()]]]);
}

export function init(systemService) {
    return [new State(new DateRange(addDays(now(), -7), now()), empty()), Cmd_none()];
}

export function update(msg, state) {
    return [new State(msg.fields[0], state.Errors), Cmd_none()];
}

export function IndexView() {
    let elems_2, elems_1, elems;
    let patternInput;
    const init_1 = init(appRoot.SystemService);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, []);
    return container([createElement("div", createObj(singleton((elems_2 = [Daisy_h2("WorkMate"), Daisy_error(patternInput[0].Errors), createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-1", "lg:grid-cols-2", "gap-4"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["col-span-2"])], (elems = [createElement(IndexView_1, null)], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement(IndexView_2, null), createElement(IndexView_3, null)], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))]))))]);
}

