import { appRoot, AppRootModule_getSelectedOrgId } from "../../../../Infrastructure/AppRoot.fs.js";
import { searchSimproConnectionDetails } from "../../../../AppDomain/UseCase/Simpro.fs.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { IndexView as IndexView_1 } from "./SimproConnectionDetailEdit.fs.js";
import { map, singleton, append, delay, toList } from "../../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { uncurry2, createObj } from "../../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { showModalDialog, ButtonType, showModalEx } from "../../../Component/ModalDialog.fs.js";
import { iconButton, alignIconCenter, alignCellTextTop, Daisy_DialogWidth } from "../../../Component/Component.fs.js";
import { IndexView as IndexView_2, Msg$1 } from "../../BaseSearch.fs.js";
import { reactApi } from "../../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { ofArray, singleton as singleton_1 } from "../../../../fable_modules/fable-library-js.4.19.3/List.js";
import { join } from "../../../../fable_modules/fable-library-js.4.19.3/String.js";

export function searchFunc() {
    const selectedOrgId = AppRootModule_getSelectedOrgId();
    return (searchText) => ((pagination) => searchSimproConnectionDetails(appRoot.SimproService, selectedOrgId, searchText, pagination));
}

export function simproConnectionDetailTable(props) {
    const body = () => {
        const matchValue = props.state.EntityToEdit;
        if (matchValue == null) {
            return createElement(IndexView_1, {
                simproConnectionDetailId: "",
            });
        }
        else {
            const x = matchValue;
            return createElement(IndexView_1, {
                simproConnectionDetailId: x,
            });
        }
    };
    const children_8 = toList(delay(() => {
        let elems;
        return append(singleton(createElement("div", createObj(Helpers_combineClasses("navbar", singleton_1((elems = [createElement(showModalEx, {
            dialogId: "id-simpro-connection-detail-search-add",
            header: "",
            buttonType: new ButtonType(0, ["Add Simpro Connection Detail"]),
            width: new Daisy_DialogWidth(3, []),
            body: body,
            onShow: () => {
                props.dispatch(new Msg$1(4, [undefined]));
            },
            onClose: () => {
                props.dispatch(new Msg$1(4, [undefined]));
            },
        })], ["children", reactApi.Children.toArray(Array.from(elems))])))))), delay(() => {
            let matchValue_1, x_1;
            return append((matchValue_1 = props.state.EntityToEdit, (matchValue_1 == null) ? singleton(react.createElement(react.Fragment, {})) : ((x_1 = matchValue_1, singleton(createElement(showModalDialog, {
                dialogId: x_1,
                header: "",
                width: new Daisy_DialogWidth(3, []),
                body: body,
                onShow: () => {
                    props.dispatch(new Msg$1(4, [x_1]));
                },
                onClose: () => {
                    props.dispatch(new Msg$1(4, [undefined]));
                },
            }))))), delay(() => {
                let elems_4, children_3, children_1, children_5;
                return singleton(createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["flex", "w-full"])], (elems_4 = [(children_3 = singleton_1((children_1 = ofArray([createElement("th", {
                    width: 35 + "%",
                    children: "Name",
                }), createElement("th", {
                    width: 35 + "%",
                    children: "Company URL",
                }), createElement("th", {
                    width: 20 + "%",
                    children: "Company Id",
                }), createElement("th", {
                    width: 10 + "%",
                    children: "",
                })]), createElement("tr", {
                    children: reactApi.Children.toArray(Array.from(children_1)),
                }))), createElement("thead", {
                    children: reactApi.Children.toArray(Array.from(children_3)),
                })), (children_5 = toList(delay(() => map((simproConnectionDetail) => {
                    let elems_3, elems_2, elems_1;
                    return createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top", "hover"])], (elems_3 = [createElement("td", createObj(ofArray([alignCellTextTop, ["style", {
                        cursor: "pointer",
                    }], ["onClick", (_arg_4) => {
                        props.dispatch(new Msg$1(4, [simproConnectionDetail.Id]));
                    }], ["children", simproConnectionDetail.Name]]))), createElement("td", createObj(ofArray([alignCellTextTop, ["style", {
                        cursor: "pointer",
                    }], ["onClick", (_arg_5) => {
                        props.dispatch(new Msg$1(4, [simproConnectionDetail.Id]));
                    }], ["children", simproConnectionDetail.CompanyURL]]))), createElement("td", createObj(ofArray([alignCellTextTop, ["style", {
                        cursor: "pointer",
                    }], ["onClick", (_arg_6) => {
                        props.dispatch(new Msg$1(4, [simproConnectionDetail.Id]));
                    }], ["children", simproConnectionDetail.CompanyId]]))), createElement("td", createObj(singleton_1((elems_2 = [createElement("div", createObj(ofArray([alignIconCenter, (elems_1 = [iconButton("fas fa-edit", (_arg_7) => {
                        props.dispatch(new Msg$1(4, [simproConnectionDetail.Id]));
                    })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))]))))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])));
                }, props.state.Entities))), createElement("tbody", {
                    children: reactApi.Children.toArray(Array.from(children_5)),
                }))], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))));
            }));
        }));
    }));
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_8)),
    });
}

export function IndexView() {
    return createElement(IndexView_2, {
        entityName: "Simpro Connection Detail",
        entityTable: simproConnectionDetailTable,
        searchFunc: uncurry2(searchFunc()),
    });
}

