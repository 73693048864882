import { Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type, record_type, list_type, option_type, bool_type, class_type, string_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Holiday as Holiday_1, Holiday_$reflection } from "../../../../Logos.Shared/Domain/Entity/Shift.fs.js";
import { BusyModule_isSaving, Busy, Busy_$reflection } from "../../SharedView.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { System_DateTime__DateTime_ToLocalKind, Guid_parse } from "../../../../Logos.Shared/Util.fs.js";
import { upsertHoliday, getHoliday } from "../../../AppDomain/UseCase/Holiday.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../Infrastructure/AppRoot.fs.js";
import { defaultArg } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { fromDate } from "../../../fable_modules/fable-library-js.4.19.3/DateOffset.js";
import { parse, toString, fromDateTimeOffset, now, toUniversalTime } from "../../../fable_modules/fable-library-js.4.19.3/Date.js";
import { join, isNullOrEmpty } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { ofArray, singleton as singleton_1, empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../../Component/ViewContainer.fs.js";
import { Daisy_progressState, Daisy_error, Daisy_h4 } from "../../Component/Component.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { empty as empty_1, singleton as singleton_2, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";

export class State extends Record {
    constructor(Name, Date$, IsActive, Holiday, IsNewHoliday, IsBusy, Errors) {
        super();
        this.Name = Name;
        this.Date = Date$;
        this.IsActive = IsActive;
        this.Holiday = Holiday;
        this.IsNewHoliday = IsNewHoliday;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.HolidayEdit.State", [], State, () => [["Name", string_type], ["Date", class_type("System.DateTime")], ["IsActive", bool_type], ["Holiday", option_type(Holiday_$reflection())], ["IsNewHoliday", bool_type], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetHolidayResponse", "SetName", "SetDate", "SetIsActive", "SaveHoliday", "SaveHolidayResponse", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.HolidayEdit.Msg", [], Msg, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [option_type(Holiday_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", option_type(Holiday_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [["Item", string_type]], [["Item", class_type("System.DateTime")]], [["Item", bool_type]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [Holiday_$reflection(), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", Holiday_$reflection()]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Exception")]]]);
}

export function getHolidayCmd(holidayId) {
    return cmdOfAsync((Item) => (new Msg(0, [Item])), (Item_1) => (new Msg(6, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_1;
        const value_1 = Guid_parse(holidayId);
        asyncResult_1 = singleton.Return(value_1);
        return singleton.Bind(asyncResult_1, (input_1) => {
            const input_2 = input_1;
            if (input_2.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
            }
            else {
                return getHoliday(appRoot.HolidayService, AppRootModule_getSelectedOrgId(), input_2.fields[0]);
            }
        });
    }));
}

export function saveHolidayCmd(state) {
    return cmdOfAsync((Item) => (new Msg(5, [Item])), (Item_1) => (new Msg(6, [Item_1])), () => singleton.Delay(() => {
        let input_1, x;
        const holiday = defaultArg((input_1 = state.Holiday, (input_1 == null) ? undefined : ((x = input_1, new Holiday_1(x.Id, x.OrgId, state.Name, fromDate(toUniversalTime(state.Date)), state.IsActive)))), new Holiday_1("00000000-0000-0000-0000-000000000000", AppRootModule_getSelectedOrgId(), state.Name, fromDate(toUniversalTime(state.Date)), state.IsActive));
        const x_1 = upsertHoliday(appRoot.HolidayService, AppRootModule_getSelectedOrgId(), holiday);
        return singleton.Bind(x_1, (x$0027) => {
            let value_1;
            const input_3 = x$0027;
            value_1 = ((input_3.tag === 1) ? (new FSharpResult$2(1, [input_3.fields[0]])) : (new FSharpResult$2(0, [new Holiday_1(input_3.fields[0], holiday.OrgId, holiday.Name, holiday.Date, holiday.IsActive)])));
            return singleton.Return(value_1);
        });
    }));
}

export function init(holidayId) {
    const isNewHoliday = isNullOrEmpty(holidayId);
    return [new State("", System_DateTime__DateTime_ToLocalKind(now()), true, undefined, isNewHoliday, isNewHoliday ? (new Busy(0, [])) : (new Busy(1, [])), empty()), isNewHoliday ? Cmd_none() : getHolidayCmd(holidayId)];
}

export function update(msg, state) {
    let input_1, x;
    switch (msg.tag) {
        case 1:
            return [new State(msg.fields[0], state.Date, state.IsActive, state.Holiday, state.IsNewHoliday, state.IsBusy, state.Errors), Cmd_none()];
        case 2:
            return [new State(state.Name, msg.fields[0], state.IsActive, state.Holiday, state.IsNewHoliday, state.IsBusy, state.Errors), Cmd_none()];
        case 3:
            return [new State(state.Name, state.Date, msg.fields[0], state.Holiday, state.IsNewHoliday, state.IsBusy, state.Errors), Cmd_none()];
        case 4:
            return [new State(state.Name, state.Date, state.IsActive, state.Holiday, state.IsNewHoliday, new Busy(2, []), state.Errors), saveHolidayCmd(state)];
        case 5:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Name, state.Date, state.IsActive, state.Holiday, state.IsNewHoliday, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.Name, state.Date, state.IsActive, msg.fields[0].fields[0], state.IsNewHoliday, new Busy(0, []), state.Errors), Cmd_none()];
            }
        case 6:
            return [new State(state.Name, state.Date, state.IsActive, state.Holiday, state.IsNewHoliday, new Busy(0, []), singleton_1(msg.fields[0].message)), Cmd_none()];
        default:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Name, state.Date, state.IsActive, state.Holiday, state.IsNewHoliday, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                const patternInput = defaultArg((input_1 = msg.fields[0].fields[0], (input_1 == null) ? undefined : ((x = input_1, [x.Name, fromDateTimeOffset(x.Date, 0), x.IsActive]))), [state.Name, state.Date, state.IsActive]);
                return [new State(patternInput[0], patternInput[1], patternInput[2], msg.fields[0].fields[0], state.IsNewHoliday, new Busy(0, []), state.Errors), Cmd_none()];
            }
    }
}

export function IndexView(indexViewInputProps) {
    let children, children_2, elems_7, children_9, children_7, children_14, children_12, value_50, children_19, children_17;
    const holidayId = indexViewInputProps.holidayId;
    let patternInput;
    const init_1 = init(holidayId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return container([(children = singleton_1(Daisy_h4((`Holiday - ${state_1.Name}`).trim())), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    })), Daisy_error(state_1.Errors), (children_2 = singleton_1(createElement(Daisy_progressState, {
        busy: state_1.IsBusy,
    })), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), createElement("button", createObj(Helpers_combineClasses("btn", toList(delay(() => append(BusyModule_isSaving(state_1.IsBusy) ? singleton_2(["className", "loading"]) : empty_1(), delay(() => append(singleton_2(["className", join(" ", ["my-4"])]), delay(() => append(singleton_2(["className", "btn-primary"]), delay(() => append(singleton_2(["className", "btn-sm"]), delay(() => append(singleton_2(["className", join(" ", ["mr-4"])]), delay(() => append(singleton_2(["children", "Save"]), delay(() => singleton_2(["onClick", (_arg_3) => {
        dispatch(new Msg(4, []));
    }])))))))))))))))))), createElement("div", createObj(Helpers_combineClasses("divider", empty()))), createElement("div", createObj(singleton_1((elems_7 = [(children_9 = ofArray([(children_7 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Holiday name",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_7)),
    })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", state_1.Name], ["placeholder", "Holiday name"], ["onChange", (ev) => {
        dispatch(new Msg(1, [ev.target.value]));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_9)),
    })), (children_14 = ofArray([(children_12 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Date",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_12)),
    })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["type", "date"], ["className", "input-bordered"], ["className", "input-sm"], (value_50 = state_1.Date, ["value", toString(value_50, "yyyy-MM-dd")]), ["onInput", (e) => {
        dispatch(new Msg(2, [System_DateTime__DateTime_ToLocalKind(parse(e.target.value))]));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_14)),
    })), (children_19 = ofArray([(children_17 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Is active",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_17)),
    })), createElement("input", createObj(Helpers_combineClasses("checkbox", ofArray([["type", "checkbox"], ["checked", state_1.IsActive], ["onChange", (ev_1) => {
        dispatch(new Msg(3, [ev_1.target.checked]));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_19)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_7))]))))]);
}

