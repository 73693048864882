import { Record } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, int64_type, string_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { createElement } from "react";
import React from "react";
import { int64ToString, createObj } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { join } from "../../fable_modules/fable-library-js.4.19.3/String.js";
import { doughnut } from "./Doughnut.fs.js";
import { sumBy, singleton, ofArray, map, toArray } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { op_Addition, toInt64, toInt32 } from "../../fable_modules/fable-library-js.4.19.3/BigInt.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { Helpers_combineClasses } from "../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { map as map_1, delay, toList } from "../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { indexView } from "./Widget.fs.js";
import { Daisy_progressState, Daisy_error } from "./Component.fs.js";

export class DataItem extends Record {
    constructor(Name, Value) {
        super();
        this.Name = Name;
        this.Value = Value;
    }
}

export function DataItem_$reflection() {
    return record_type("Presentation.DoughnutViewBase.DataItem", [], DataItem, () => [["Name", string_type], ["Value", int64_type]]);
}

function DoughnutAndTable(doughnutAndTableInputProps) {
    let elems, elems_5, children_2, children, children_4, children_6, elems_4, elems_3;
    const dataItems = doughnutAndTableInputProps.dataItems;
    const title = doughnutAndTableInputProps.title;
    const children_9 = ofArray([createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-1", "lg:grid-cols-1", "gap-4", "border-2", "border-gray-200", "rounded-lg"])], (elems = [doughnut(title, toArray(map((x) => (`${x.Name}`), dataItems)), toArray(map((x_1) => ~~toInt32(x_1.Value), dataItems)), [], [])], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["flex", "w-full", "mt-4"])], (elems_5 = [(children_2 = singleton((children = ofArray([createElement("th", {
        children: "Job",
    }), createElement("th", {
        className: join(" ", ["text-right"]),
        children: "Workforce skills",
    })]), createElement("tr", {
        children: reactApi.Children.toArray(Array.from(children)),
    }))), createElement("thead", {
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), (children_4 = toList(delay(() => map_1((data) => {
        let elems_2, elems_1;
        return createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top", "gap-4", "hover"])], (elems_2 = [createElement("td", {
            children: [data.Name],
        }), createElement("td", createObj(ofArray([["className", join(" ", ["text-right"])], (elems_1 = [int64ToString(data.Value)], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
    }, dataItems))), createElement("tbody", {
        children: reactApi.Children.toArray(Array.from(children_4)),
    })), (children_6 = singleton(createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top", "gap-4"])], (elems_4 = [createElement("td", {
        children: ["Total"],
    }), createElement("td", createObj(ofArray([["className", join(" ", ["text-right"])], (elems_3 = [int64ToString(sumBy((x_2) => x_2.Value, dataItems, {
        GetZero: () => (0n),
        Add: (x_3, y) => toInt64(op_Addition(x_3, y)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))), createElement("tfoot", {
        children: reactApi.Children.toArray(Array.from(children_6)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_5))])]))))]);
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_9)),
    });
}

export function IndexView(indexViewInputProps) {
    let children;
    const dataItems = indexViewInputProps.dataItems;
    const isBusy = indexViewInputProps.isBusy;
    const errors = indexViewInputProps.errors;
    const title = indexViewInputProps.title;
    return createElement(indexView, {
        title: title,
        elements: [Daisy_error(errors), (children = singleton(createElement(Daisy_progressState, {
            busy: isBusy,
        })), createElement("div", {
            className: "form-control",
            children: reactApi.Children.toArray(Array.from(children)),
        })), createElement(DoughnutAndTable, {
            title: title,
            dataItems: dataItems,
        })],
    });
}

