import { toString, Union, Record } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { class_type, union_type, tuple_type, record_type, list_type, lambda_type, unit_type, bool_type, string_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { SignInDto_$reflection } from "../../../Logos.Shared/Domain/Dto/User.fs.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { signOut, getPurchasedServices, signIn } from "../../AppDomain/UseCase/Auth.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../Infrastructure/AppRoot.fs.js";
import { map, defaultArgWith, defaultArg } from "../../fable_modules/fable-library-js.4.19.3/Option.js";
import { ResultCE_result, ResultCE_ResultBuilder__Zero } from "../../fable_modules/FsToolkit.ErrorHandling.4.16.0/./ResultCE.fs.js";
import { append as append_1, ofArray, singleton as singleton_1, empty } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Router_goToUrl, Page, Cmd_navigateToPage } from "../Router.fs.js";
import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../Component/ViewContainer.fs.js";
import { createObj } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { empty as empty_1, append, singleton as singleton_2, delay, toList } from "../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { Helpers_combineClasses } from "../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { IndexView as IndexView_1 } from "./OrganisationSelector.fs.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { PropHelpers_createOnKey } from "../../fable_modules/Feliz.2.8.0/./Properties.fs.js";
import { key_enter } from "../../fable_modules/Feliz.2.8.0/Key.fs.js";
import { Daisy_error } from "../Component/Component.fs.js";
import { PageModule_toUrlSegments } from ".././Router.fs.js";
import { RouterModule_trySeparateLast, RouterModule_encodeQueryString, RouterModule_encodeParts } from "../../fable_modules/Feliz.Router.4.0.0/./Router.fs.js";

export class SignInState extends Record {
    constructor(Username, Password, IsBusy, IsSelectingOrg, IsValidOrganisation, OnValidOrganisationSelected, Error$) {
        super();
        this.Username = Username;
        this.Password = Password;
        this.IsBusy = IsBusy;
        this.IsSelectingOrg = IsSelectingOrg;
        this.IsValidOrganisation = IsValidOrganisation;
        this.OnValidOrganisationSelected = OnValidOrganisationSelected;
        this.Error = Error$;
    }
}

export function SignInState_$reflection() {
    return record_type("Presentation.Pages.SignIn.SignInState", [], SignInState, () => [["Username", string_type], ["Password", string_type], ["IsBusy", bool_type], ["IsSelectingOrg", bool_type], ["IsValidOrganisation", bool_type], ["OnValidOrganisationSelected", lambda_type(bool_type, unit_type)], ["Error", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetUsername", "SetPassword", "SignIn", "SignInResponse", "SelectOrg", "SignOut", "SignOutResponse", "IsValidOrganisationSelected", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.SignIn.Msg", [], Msg, () => [[["Item", string_type]], [["Item", string_type]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(SignInDto_$reflection(), bool_type, bool_type), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", tuple_type(SignInDto_$reflection(), bool_type, bool_type)]], [["ErrorValue", list_type(string_type)]]])]], [], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])]], [["Item", bool_type]], [["Item", class_type("System.Exception")]]]);
}

export function signInCmd(username, password) {
    return cmdOfAsync((Item) => (new Msg(3, [Item])), (Item_1) => (new Msg(8, [Item_1])), () => singleton.Delay(() => {
        const asyncResult_2 = signIn(username, password, appRoot.SystemService, appRoot.AuthService, appRoot.OrganisationService);
        return singleton.Bind(asyncResult_2, (input_1_5) => {
            const input_19 = input_1_5;
            if (input_19.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_19.fields[0]]));
            }
            else {
                let asyncResult_1;
                const value_1 = appRoot.SystemService.Load("UserData");
                asyncResult_1 = singleton.Return(value_1);
                return singleton.Bind(asyncResult_1, (input_1_4) => {
                    let input_1;
                    const input_16 = input_1_4;
                    if (input_16.tag === 1) {
                        return singleton.Return(new FSharpResult$2(1, [input_16.fields[0]]));
                    }
                    else {
                        const selectOrganisation = defaultArg((input_1 = input_16.fields[0], (input_1 == null) ? undefined : (input_1.SelectedOrganisation == null)), true);
                        let computation1;
                        if (!selectOrganisation) {
                            const x_1 = getPurchasedServices(appRoot.SystemService, appRoot.OrganisationService, AppRootModule_getSelectedOrgId());
                            computation1 = singleton.Bind(x_1, (x$0027) => {
                                let value_3;
                                const input_3 = x$0027;
                                value_3 = ((input_3.tag === 1) ? (new FSharpResult$2(1, [input_3.fields[0]])) : (new FSharpResult$2(0, [undefined])));
                                return singleton.Return(value_3);
                            });
                        }
                        else {
                            const value_5 = ResultCE_ResultBuilder__Zero(ResultCE_result);
                            computation1 = singleton.Return(value_5);
                        }
                        const computation2 = singleton.Delay(() => {
                            let x_4;
                            const value_1_2 = appRoot.SystemService.Load("UserData");
                            x_4 = singleton.Return(value_1_2);
                            return singleton.Bind(x_4, (x$0027_1) => {
                                let input_7, x_3;
                                let value_8;
                                const input_9 = x$0027_1;
                                value_8 = ((input_9.tag === 1) ? (new FSharpResult$2(1, [input_9.fields[0]])) : (new FSharpResult$2(0, [[input_19.fields[0], selectOrganisation, defaultArg((input_7 = input_9.fields[0], (input_7 == null) ? undefined : ((x_3 = input_7, x_3.PurchasedServices.length > 0))), false)]])));
                                return singleton.Return(value_8);
                            });
                        });
                        return singleton.Bind(computation1, (input_1_3) => {
                            const input_13 = input_1_3;
                            if (input_13.tag === 1) {
                                return singleton.Return(new FSharpResult$2(1, [input_13.fields[0]]));
                            }
                            else {
                                return computation2;
                            }
                        });
                    }
                });
            }
        });
    }));
}

export function signOutCmd() {
    return cmdOfAsync((Item) => (new Msg(6, [Item])), (Item_1) => (new Msg(8, [Item_1])), () => singleton.Delay(() => {
        const value_1 = signOut(appRoot.SystemService);
        return singleton.Return(value_1);
    }));
}

export function init(onValidOrganisationSelected) {
    return [new SignInState("", "", false, false, false, onValidOrganisationSelected, empty()), Cmd_none()];
}

export function update(msg, model) {
    switch (msg.tag) {
        case 1:
            return [new SignInState(model.Username, msg.fields[0], model.IsBusy, model.IsSelectingOrg, model.IsValidOrganisation, model.OnValidOrganisationSelected, model.Error), Cmd_none()];
        case 2:
            return [new SignInState(model.Username, model.Password, true, model.IsSelectingOrg, model.IsValidOrganisation, model.OnValidOrganisationSelected, empty()), signInCmd(model.Username, model.Password)];
        case 3:
            if (msg.fields[0].tag === 1) {
                return [new SignInState(model.Username, model.Password, false, model.IsSelectingOrg, model.IsValidOrganisation, model.OnValidOrganisationSelected, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                model.OnValidOrganisationSelected(msg.fields[0].fields[0][1]);
                return [new SignInState("", "", false, msg.fields[0].fields[0][1], model.IsValidOrganisation, model.OnValidOrganisationSelected, model.Error), msg.fields[0].fields[0][1] ? Cmd_none() : (msg.fields[0].fields[0][2] ? Cmd_navigateToPage(new Page(16, [])) : Cmd_navigateToPage(new Page(5, [])))];
            }
        case 4:
            return [new SignInState(model.Username, model.Password, model.IsBusy, false, model.IsValidOrganisation, model.OnValidOrganisationSelected, model.Error), Cmd_navigateToPage(new Page(16, []))];
        case 5:
            return [model, signOutCmd()];
        case 6:
            if (msg.fields[0].tag === 1) {
                return [new SignInState(model.Username, model.Password, model.IsBusy, model.IsSelectingOrg, model.IsValidOrganisation, model.OnValidOrganisationSelected, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new SignInState(model.Username, model.Password, model.IsBusy, false, model.IsValidOrganisation, model.OnValidOrganisationSelected, model.Error), Cmd_none()];
            }
        case 7: {
            model.OnValidOrganisationSelected(msg.fields[0]);
            return [new SignInState(model.Username, model.Password, model.IsBusy, model.IsSelectingOrg, msg.fields[0], model.OnValidOrganisationSelected, model.Error), Cmd_none()];
        }
        case 8:
            return [new SignInState(model.Username, model.Password, model.IsBusy, model.IsSelectingOrg, model.IsValidOrganisation, model.OnValidOrganisationSelected, singleton_1(toString(msg.fields[0]))), Cmd_none()];
        default:
            return [new SignInState(msg.fields[0], model.Password, model.IsBusy, model.IsSelectingOrg, model.IsValidOrganisation, model.OnValidOrganisationSelected, model.Error), Cmd_none()];
    }
}

export function IndexView(props) {
    let elems_9;
    let patternInput;
    const init_1 = init(props.OnValidOrganisationSelected);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state = patternInput[0];
    const dispatch = patternInput[1];
    return container([createElement("div", createObj(ofArray([["className", "space-y-8 max-w-md mx-auto"], (elems_9 = toList(delay(() => {
        let children_7, elems_2, children_4, children_2, children_12, children_10;
        return state.IsSelectingOrg ? singleton_2((children_7 = ofArray([createElement("input", createObj(Helpers_combineClasses("modal-toggle", ofArray([["type", "checkbox"], ["id", "org-selector"], ["checked", true], ["readOnly", true]])))), createElement("div", createObj(Helpers_combineClasses("modal", singleton_1((elems_2 = [(children_4 = ofArray([createElement(IndexView_1, {
            IsValidOrganisationSelected: (arg) => {
                dispatch(new Msg(7, [arg]));
            },
            ShowNoneOption: true,
        }), (children_2 = ofArray([createElement("label", createObj(Helpers_combineClasses("btn", ofArray([["htmlFor", "org-selector"], ["className", "btn-primary"], ["children", "Sign Out"], ["onClick", (_arg_2) => {
            dispatch(new Msg(5, []));
        }]])))), createElement("label", createObj(Helpers_combineClasses("btn", ofArray([["htmlFor", "org-selector"], ["className", "btn-primary"], ["children", "Select"], ["onClick", (_arg_3) => {
            dispatch(new Msg(4, []));
        }], ["disabled", !state.IsValidOrganisation]]))))]), createElement("div", {
            className: "modal-action",
            children: reactApi.Children.toArray(Array.from(children_2)),
        }))]), createElement("div", {
            className: "modal-box",
            children: reactApi.Children.toArray(Array.from(children_4)),
        }))], ["children", reactApi.Children.toArray(Array.from(elems_2))])))))]), createElement("div", {
            children: reactApi.Children.toArray(Array.from(children_7)),
        }))) : append(singleton_2((children_12 = ofArray([(children_10 = singleton_1(createElement("span", {
            className: "label-text",
            children: "Email",
        })), createElement("label", {
            className: "label",
            children: reactApi.Children.toArray(Array.from(children_10)),
        })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["autoFocus", true], ["placeholder", "Email"], ["readOnly", state.IsBusy], ["onChange", (ev) => {
            dispatch(new Msg(0, [ev.target.value]));
        }]]))))]), createElement("div", {
            className: "form-control",
            children: reactApi.Children.toArray(Array.from(children_12)),
        }))), delay(() => {
            let children_17, children_15;
            return append(singleton_2((children_17 = ofArray([(children_15 = singleton_1(createElement("span", {
                className: "label-text",
                children: "Password",
            })), createElement("label", {
                className: "label",
                children: reactApi.Children.toArray(Array.from(children_15)),
            })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["type", "password"], ["className", "input-bordered"], ["placeholder", "Password"], ["readOnly", state.IsBusy], ["onChange", (ev_1) => {
                dispatch(new Msg(1, [ev_1.target.value]));
            }], ["onKeyUp", (ev_2) => {
                PropHelpers_createOnKey(key_enter, (_arg_4) => {
                    dispatch(new Msg(2, []));
                }, ev_2);
            }]]))))]), createElement("div", {
                className: "form-control",
                children: reactApi.Children.toArray(Array.from(children_17)),
            }))), delay(() => append(singleton_2(Daisy_error(state.Error)), delay(() => {
                let children_20;
                return append(singleton_2((children_20 = singleton_1(createElement("button", createObj(Helpers_combineClasses("btn", toList(delay(() => append(state.IsBusy ? singleton_2(["className", "loading"]) : empty_1(), delay(() => append(singleton_2(["className", "btn-primary"]), delay(() => append(singleton_2(["children", "Sign in"]), delay(() => singleton_2(["onClick", (_arg_5) => {
                    dispatch(new Msg(2, []));
                }]))))))))))))), createElement("div", {
                    className: "form-control",
                    children: reactApi.Children.toArray(Array.from(children_20)),
                }))), delay(() => {
                    let children_22, tupledArg, queryString;
                    return singleton_2((children_22 = singleton_1(createElement("a", {
                        href: (tupledArg = PageModule_toUrlSegments(new Page(21, [])), (queryString = tupledArg[1], defaultArgWith(map((tupledArg_1) => RouterModule_encodeParts(append_1(tupledArg_1[0], singleton_1(tupledArg_1[1] + RouterModule_encodeQueryString(queryString))), 2), RouterModule_trySeparateLast(tupledArg[0])), () => RouterModule_encodeParts(singleton_1(RouterModule_encodeQueryString(queryString)), 2)))),
                        onClick: (e) => {
                            Router_goToUrl(e);
                        },
                        children: "Reset password",
                        className: "underline text-blue-600 hover:text-blue-800 visited:text-purple-600",
                    })), createElement("div", {
                        className: "form-control",
                        children: reactApi.Children.toArray(Array.from(children_22)),
                    })));
                }));
            }))));
        }));
    })), ["children", reactApi.Children.toArray(Array.from(elems_9))])])))]);
}

