import { Record } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { list_type, decimal_type, enum_type, int32_type, class_type, array_type, option_type, bool_type, record_type, string_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Product_$reflection } from "../Entity/Product.fs.js";
import { Email_$reflection } from "../Entity/Email.fs.js";
import { Password_$reflection } from "../Entity/Password.fs.js";
import { UserDetail, LeaveRequestTypeModule_ofInt, LeaveRequestTypeModule_toInt, LeaveRequestStatus, LeaveRequestSearchResult, LeaveRequest, LeaveRequestType_$reflection, LeaveRequestStatus_$reflection, SiteManager, UserReport, AllocatedJobSkillUser, User_$reflection, User, EmploymentType_$reflection, UserRole_$reflection, PayrollSystemInfo_$reflection, UserSkill, Skill } from "../Entity/User.fs.js";
import { MeasurementSystem_$reflection } from "../Entity/MeasurementUnits.fs.js";
import { singleton, map } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/List.js";
import { FSharpResult$2 } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Result.js";
import { ofNullable, defaultArg, toNullable, value as value_6 } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Option.js";
import { defaultOf } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Util.js";
import { Option_ofString, Option_toString } from "../../Util.fs.js";

export class TokenResult extends Record {
    constructor(Token, RefreshToken) {
        super();
        this.Token = Token;
        this.RefreshToken = RefreshToken;
    }
}

export function TokenResult_$reflection() {
    return record_type("Domain.Dto.User.TokenResult", [], TokenResult, () => [["Token", string_type], ["RefreshToken", string_type]]);
}

export class SignInDto extends Record {
    constructor(IsMember, Token, PurchasedServices, UserId) {
        super();
        this.IsMember = IsMember;
        this.Token = Token;
        this.PurchasedServices = PurchasedServices;
        this.UserId = UserId;
    }
}

export function SignInDto_$reflection() {
    return record_type("Domain.Dto.User.SignInDto", [], SignInDto, () => [["IsMember", bool_type], ["Token", option_type(TokenResult_$reflection())], ["PurchasedServices", array_type(Product_$reflection())], ["UserId", class_type("System.Guid")]]);
}

export class UserData extends Record {
    constructor(Username, Token) {
        super();
        this.Username = Username;
        this.Token = Token;
    }
}

export function UserData_$reflection() {
    return record_type("Domain.Dto.User.UserData", [], UserData, () => [["Username", string_type], ["Token", string_type]]);
}

export class SignupDto extends Record {
    constructor(Username, OneTimeSignupCode, Password, ConfirmPassword) {
        super();
        this.Username = Username;
        this.OneTimeSignupCode = OneTimeSignupCode;
        this.Password = Password;
        this.ConfirmPassword = ConfirmPassword;
    }
}

export function SignupDto_$reflection() {
    return record_type("Domain.Dto.User.SignupDto", [], SignupDto, () => [["Username", Email_$reflection()], ["OneTimeSignupCode", string_type], ["Password", Password_$reflection()], ["ConfirmPassword", Password_$reflection()]]);
}

export class ResetPasswordDto extends Record {
    constructor(Username, OneTimeResetCode, NewPassword, ConfirmNewPassword) {
        super();
        this.Username = Username;
        this.OneTimeResetCode = OneTimeResetCode;
        this.NewPassword = NewPassword;
        this.ConfirmNewPassword = ConfirmNewPassword;
    }
}

export function ResetPasswordDto_$reflection() {
    return record_type("Domain.Dto.User.ResetPasswordDto", [], ResetPasswordDto, () => [["Username", Email_$reflection()], ["OneTimeResetCode", string_type], ["NewPassword", Password_$reflection()], ["ConfirmNewPassword", Password_$reflection()]]);
}

export class SkillDto extends Record {
    constructor(Id, Name) {
        super();
        this.Id = Id;
        this.Name = Name;
    }
}

export function SkillDto_$reflection() {
    return record_type("Domain.Dto.User.SkillDto", [], SkillDto, () => [["Id", class_type("System.Guid")], ["Name", string_type]]);
}

export function SkillDtoModule_toSkill(skill) {
    return new Skill(skill.Id, skill.Name);
}

export function SkillDtoModule_ofSkill(skill) {
    return new SkillDto(skill.Id, skill.Name);
}

export class UserSkillDto extends Record {
    constructor(Id, SkillId, ExperienceMonths, Country, Rating) {
        super();
        this.Id = Id;
        this.SkillId = SkillId;
        this.ExperienceMonths = (ExperienceMonths | 0);
        this.Country = Country;
        this.Rating = (Rating | 0);
    }
}

export function UserSkillDto_$reflection() {
    return record_type("Domain.Dto.User.UserSkillDto", [], UserSkillDto, () => [["Id", class_type("System.Guid")], ["SkillId", class_type("System.Guid")], ["ExperienceMonths", int32_type], ["Country", option_type(string_type)], ["Rating", enum_type("Domain.Entity.User.SkillRating", int32_type, [["Beginner", 0], ["Intermediate", 1], ["Experienced", 2]])]]);
}

export function UserSkillDtoModule_ofUserSkill(userSkill) {
    return new UserSkillDto(userSkill.Id, userSkill.SkillId, userSkill.ExperienceMonths, userSkill.Country, userSkill.Rating);
}

export function UserSkillDtoModule_toUserSkill(userSkill) {
    return new UserSkill(userSkill.Id, userSkill.SkillId, userSkill.ExperienceMonths, userSkill.Country, userSkill.Rating);
}

export class UserDto extends Record {
    constructor(Id, Username, IsActive, IsAdmin, FirstName, MiddleName, LastName, Mobile, MeasurementSystem, HourlyRate, ChargeOutRate, Rating, PayrollSystemInfo, UserSkills, UserRole, Dob, DriverLicenseCountry, DriverLicenseNumber, ResidencyStatus, EnglishProficiency, AccommodationType, OvertimeAfterWorkHours, EmploymentType, WeeklyFulltimeWorkHours) {
        super();
        this.Id = Id;
        this.Username = Username;
        this.IsActive = IsActive;
        this.IsAdmin = IsAdmin;
        this.FirstName = FirstName;
        this.MiddleName = MiddleName;
        this.LastName = LastName;
        this.Mobile = Mobile;
        this.MeasurementSystem = MeasurementSystem;
        this.HourlyRate = HourlyRate;
        this.ChargeOutRate = ChargeOutRate;
        this.Rating = (Rating | 0);
        this.PayrollSystemInfo = PayrollSystemInfo;
        this.UserSkills = UserSkills;
        this.UserRole = UserRole;
        this.Dob = Dob;
        this.DriverLicenseCountry = DriverLicenseCountry;
        this.DriverLicenseNumber = DriverLicenseNumber;
        this.ResidencyStatus = (ResidencyStatus | 0);
        this.EnglishProficiency = (EnglishProficiency | 0);
        this.AccommodationType = (AccommodationType | 0);
        this.OvertimeAfterWorkHours = OvertimeAfterWorkHours;
        this.EmploymentType = EmploymentType;
        this.WeeklyFulltimeWorkHours = WeeklyFulltimeWorkHours;
    }
}

export function UserDto_$reflection() {
    return record_type("Domain.Dto.User.UserDto", [], UserDto, () => [["Id", class_type("System.Guid")], ["Username", Email_$reflection()], ["IsActive", bool_type], ["IsAdmin", bool_type], ["FirstName", string_type], ["MiddleName", string_type], ["LastName", string_type], ["Mobile", string_type], ["MeasurementSystem", MeasurementSystem_$reflection()], ["HourlyRate", decimal_type], ["ChargeOutRate", decimal_type], ["Rating", int32_type], ["PayrollSystemInfo", option_type(PayrollSystemInfo_$reflection())], ["UserSkills", list_type(UserSkillDto_$reflection())], ["UserRole", UserRole_$reflection()], ["Dob", option_type(class_type("System.DateTime"))], ["DriverLicenseCountry", option_type(string_type)], ["DriverLicenseNumber", option_type(string_type)], ["ResidencyStatus", enum_type("Domain.Entity.User.ResidencyStatus", int32_type, [["NotSet", 0], ["Citizen", 1], ["PermanentResident", 2], ["WorkVisa", 3], ["StudentVisa", 4], ["ResidentVisa", 5]])], ["EnglishProficiency", enum_type("Domain.Entity.User.EnglishProficiency", int32_type, [["NotSet", 0], ["Fluent", 1], ["Good", 2], ["Basic", 3]])], ["AccommodationType", enum_type("Domain.Entity.User.AccommodationType", int32_type, [["NotSet", 0], ["OwnHome", 1], ["Renting", 2], ["Boarding", 3], ["CompanyProvided", 4]])], ["OvertimeAfterWorkHours", decimal_type], ["EmploymentType", EmploymentType_$reflection()], ["WeeklyFulltimeWorkHours", option_type(class_type("System.TimeSpan"))]]);
}

export function UserDtoModule_toUser(user) {
    return new User(user.Id, user.Username, user.IsActive, user.IsAdmin, user.FirstName, user.MiddleName, user.LastName, user.Mobile, user.MeasurementSystem, user.HourlyRate, user.ChargeOutRate, user.Rating, user.PayrollSystemInfo, map(UserSkillDtoModule_toUserSkill, user.UserSkills), user.UserRole, user.Dob, user.DriverLicenseCountry, user.DriverLicenseNumber, user.ResidencyStatus, user.EnglishProficiency, user.AccommodationType, user.OvertimeAfterWorkHours, user.EmploymentType, user.WeeklyFulltimeWorkHours);
}

export function UserDtoModule_ofUser(user) {
    return new UserDto(user.Id, user.Username, user.IsActive, user.IsAdmin, user.FirstName, user.MiddleName, user.LastName, user.Mobile, user.MeasurementSystem, user.HourlyRate, user.ChargeOutRate, user.Rating, user.PayrollSystemInfo, map(UserSkillDtoModule_ofUserSkill, user.UserSkills), user.UserRole, user.Dob, user.DriverLicenseCountry, user.DriverLicenseNumber, user.ResidencyStatus, user.EnglishProficiency, user.AccommodationType, user.OvertimeAfterWorkHours, user.EmploymentType, user.WeeklyFulltimeWorkHours);
}

export class UserMembershipDto extends Record {
    constructor(OrganisationName, IsActive) {
        super();
        this.OrganisationName = OrganisationName;
        this.IsActive = IsActive;
    }
}

export function UserMembershipDto_$reflection() {
    return record_type("Domain.Dto.User.UserMembershipDto", [], UserMembershipDto, () => [["OrganisationName", string_type], ["IsActive", bool_type]]);
}

export function UserMembershipDtoModule_ofUserMembership(userMembership) {
    return new UserMembershipDto(userMembership.OrganisationName, userMembership.IsActive);
}

export class AllocatedJobSkillUserDto extends Record {
    constructor(Id, JobUserSkillId, User) {
        super();
        this.Id = Id;
        this.JobUserSkillId = JobUserSkillId;
        this.User = User;
    }
}

export function AllocatedJobSkillUserDto_$reflection() {
    return record_type("Domain.Dto.User.AllocatedJobSkillUserDto", [], AllocatedJobSkillUserDto, () => [["Id", class_type("System.Guid")], ["JobUserSkillId", class_type("System.Guid")], ["User", User_$reflection()]]);
}

export function AllocatedJobSkillUserDtoModule_ofAllocatedJobSkillUser(allocatedJobSkillUser) {
    return new AllocatedJobSkillUserDto(allocatedJobSkillUser.Id, allocatedJobSkillUser.JobUserSkillId, allocatedJobSkillUser.User);
}

export function AllocatedJobSkillUserDtoModule_toAllocatedJobSkillUser(allocatedJobSkillUser) {
    return new AllocatedJobSkillUser(allocatedJobSkillUser.Id, allocatedJobSkillUser.JobUserSkillId, allocatedJobSkillUser.User);
}

export class UserReportDto extends Record {
    constructor(Id, FirstName, MiddleName, LastName, Rating, HourlyRate, ChargeOutRate, UserSkills, DriverLicenseCountry, DriverLicenseNumber, ResidencyStatus, EnglishProficiency, AccommodationType, Age) {
        super();
        this.Id = Id;
        this.FirstName = FirstName;
        this.MiddleName = MiddleName;
        this.LastName = LastName;
        this.Rating = (Rating | 0);
        this.HourlyRate = HourlyRate;
        this.ChargeOutRate = ChargeOutRate;
        this.UserSkills = UserSkills;
        this.DriverLicenseCountry = DriverLicenseCountry;
        this.DriverLicenseNumber = DriverLicenseNumber;
        this.ResidencyStatus = (ResidencyStatus | 0);
        this.EnglishProficiency = (EnglishProficiency | 0);
        this.AccommodationType = (AccommodationType | 0);
        this.Age = Age;
    }
}

export function UserReportDto_$reflection() {
    return record_type("Domain.Dto.User.UserReportDto", [], UserReportDto, () => [["Id", class_type("System.Guid")], ["FirstName", string_type], ["MiddleName", string_type], ["LastName", string_type], ["Rating", int32_type], ["HourlyRate", decimal_type], ["ChargeOutRate", decimal_type], ["UserSkills", list_type(string_type)], ["DriverLicenseCountry", string_type], ["DriverLicenseNumber", string_type], ["ResidencyStatus", enum_type("Domain.Entity.User.ResidencyStatus", int32_type, [["NotSet", 0], ["Citizen", 1], ["PermanentResident", 2], ["WorkVisa", 3], ["StudentVisa", 4], ["ResidentVisa", 5]])], ["EnglishProficiency", enum_type("Domain.Entity.User.EnglishProficiency", int32_type, [["NotSet", 0], ["Fluent", 1], ["Good", 2], ["Basic", 3]])], ["AccommodationType", enum_type("Domain.Entity.User.AccommodationType", int32_type, [["NotSet", 0], ["OwnHome", 1], ["Renting", 2], ["Boarding", 3], ["CompanyProvided", 4]])], ["Age", option_type(int32_type)]]);
}

export function UserReportDtoModule_ofUserReport(userReport) {
    return new UserReportDto(userReport.Id, userReport.FirstName, userReport.MiddleName, userReport.LastName, userReport.Rating, userReport.HourlyRate, userReport.ChargeOutRate, userReport.UserSkills, userReport.DriverLicenseCountry, userReport.DriverLicenseNumber, userReport.ResidencyStatus, userReport.EnglishProficiency, userReport.AccommodationType, userReport.Age);
}

export function UserReportDtoModule_toUserReport(userReport) {
    return new UserReport(userReport.Id, userReport.FirstName, userReport.MiddleName, userReport.LastName, userReport.Rating, userReport.HourlyRate, userReport.ChargeOutRate, userReport.UserSkills, userReport.DriverLicenseCountry, userReport.DriverLicenseNumber, userReport.ResidencyStatus, userReport.EnglishProficiency, userReport.AccommodationType, userReport.Age);
}

export class SiteManagerDto extends Record {
    constructor(Id, Username) {
        super();
        this.Id = Id;
        this.Username = Username;
    }
}

export function SiteManagerDto_$reflection() {
    return record_type("Domain.Dto.User.SiteManagerDto", [], SiteManagerDto, () => [["Id", class_type("System.Guid")], ["Username", string_type]]);
}

export function SiteManagerDtoModule_ofSiteManager(siteManager) {
    return new SiteManagerDto(siteManager.Id, siteManager.Username);
}

export function SiteManagerDtoModule_toSiteManager(siteManager) {
    return new SiteManager(siteManager.Id, siteManager.Username);
}

export class LeaveRequestDto extends Record {
    constructor(Id, OrgId, UserId, StartDate, FinishDate, Status, Type, Reason, PayrollAdminComment, PayrollAdminCommentUser, Created, Modified) {
        super();
        this.Id = Id;
        this.OrgId = OrgId;
        this.UserId = UserId;
        this.StartDate = StartDate;
        this.FinishDate = FinishDate;
        this.Status = Status;
        this.Type = Type;
        this.Reason = Reason;
        this.PayrollAdminComment = PayrollAdminComment;
        this.PayrollAdminCommentUser = PayrollAdminCommentUser;
        this.Created = Created;
        this.Modified = Modified;
    }
}

export function LeaveRequestDto_$reflection() {
    return record_type("Domain.Dto.User.LeaveRequestDto", [], LeaveRequestDto, () => [["Id", class_type("System.Guid")], ["OrgId", class_type("System.Guid")], ["UserId", class_type("System.Guid")], ["StartDate", class_type("System.DateTime")], ["FinishDate", class_type("System.DateTime")], ["Status", LeaveRequestStatus_$reflection()], ["Type", LeaveRequestType_$reflection()], ["Reason", string_type], ["PayrollAdminComment", option_type(string_type)], ["PayrollAdminCommentUser", option_type(class_type("System.Guid"))], ["Created", class_type("System.DateTime")], ["Modified", class_type("System.DateTime")]]);
}

export function LeaveRequestDtoModule_ofLeaveRequest(leaveRequest) {
    return new LeaveRequestDto(leaveRequest.Id, leaveRequest.OrgId, leaveRequest.UserId, leaveRequest.StartDate, leaveRequest.FinishDate, leaveRequest.Status, leaveRequest.LeaveRequestType, leaveRequest.Reason, leaveRequest.PayrollAdminComment, leaveRequest.PayrollAdminCommentUser, leaveRequest.Created, leaveRequest.Modified);
}

export function LeaveRequestDtoModule_toLeaveRequest(leaveRequest) {
    return new LeaveRequest(leaveRequest.Id, leaveRequest.OrgId, leaveRequest.UserId, leaveRequest.StartDate, leaveRequest.FinishDate, leaveRequest.Status, leaveRequest.Type, leaveRequest.Reason, leaveRequest.PayrollAdminComment, leaveRequest.PayrollAdminCommentUser, leaveRequest.Created, leaveRequest.Modified);
}

export class LeaveRequestSearchResultDto extends Record {
    constructor(Id, OrgId, UserId, FirstName, MiddleName, LastName, StartDate, FinishDate, Status, ApprovedByUsername, RejectedByUsername, LeaveRequestType, Reason, PayrollAdminComment, PayrollAdminCommentUser, Created, Modified) {
        super();
        this.Id = Id;
        this.OrgId = OrgId;
        this.UserId = UserId;
        this.FirstName = FirstName;
        this.MiddleName = MiddleName;
        this.LastName = LastName;
        this.StartDate = StartDate;
        this.FinishDate = FinishDate;
        this.Status = Status;
        this.ApprovedByUsername = ApprovedByUsername;
        this.RejectedByUsername = RejectedByUsername;
        this.LeaveRequestType = LeaveRequestType;
        this.Reason = Reason;
        this.PayrollAdminComment = PayrollAdminComment;
        this.PayrollAdminCommentUser = PayrollAdminCommentUser;
        this.Created = Created;
        this.Modified = Modified;
    }
}

export function LeaveRequestSearchResultDto_$reflection() {
    return record_type("Domain.Dto.User.LeaveRequestSearchResultDto", [], LeaveRequestSearchResultDto, () => [["Id", class_type("System.Guid")], ["OrgId", class_type("System.Guid")], ["UserId", class_type("System.Guid")], ["FirstName", string_type], ["MiddleName", string_type], ["LastName", string_type], ["StartDate", class_type("System.DateTime")], ["FinishDate", class_type("System.DateTime")], ["Status", LeaveRequestStatus_$reflection()], ["ApprovedByUsername", option_type(string_type)], ["RejectedByUsername", option_type(string_type)], ["LeaveRequestType", LeaveRequestType_$reflection()], ["Reason", string_type], ["PayrollAdminComment", string_type], ["PayrollAdminCommentUser", string_type], ["Created", class_type("System.DateTime")], ["Modified", class_type("System.DateTime")]]);
}

export function LeaveRequestSearchResultDtoModule_ofLeaveRequestSearchResult(leaveRequestSearchResult) {
    return new LeaveRequestSearchResultDto(leaveRequestSearchResult.Id, leaveRequestSearchResult.OrgId, leaveRequestSearchResult.UserId, leaveRequestSearchResult.FirstName, leaveRequestSearchResult.MiddleName, leaveRequestSearchResult.LastName, leaveRequestSearchResult.StartDate, leaveRequestSearchResult.FinishDate, leaveRequestSearchResult.Status, leaveRequestSearchResult.ApprovedByUsername, leaveRequestSearchResult.RejectedByUsername, leaveRequestSearchResult.LeaveRequestType, leaveRequestSearchResult.Reason, leaveRequestSearchResult.PayrollAdminComment, leaveRequestSearchResult.PayrollAdminCommentUser, leaveRequestSearchResult.Created, leaveRequestSearchResult.Modified);
}

export function LeaveRequestSearchResultDtoModule_toLeaveRequestSearchResult(leaveRequestSearchResult) {
    return new LeaveRequestSearchResult(leaveRequestSearchResult.Id, leaveRequestSearchResult.OrgId, leaveRequestSearchResult.UserId, leaveRequestSearchResult.FirstName, leaveRequestSearchResult.MiddleName, leaveRequestSearchResult.LastName, leaveRequestSearchResult.StartDate, leaveRequestSearchResult.FinishDate, leaveRequestSearchResult.Status, leaveRequestSearchResult.ApprovedByUsername, leaveRequestSearchResult.RejectedByUsername, leaveRequestSearchResult.LeaveRequestType, leaveRequestSearchResult.Reason, leaveRequestSearchResult.PayrollAdminComment, leaveRequestSearchResult.PayrollAdminCommentUser, leaveRequestSearchResult.Created, leaveRequestSearchResult.Modified);
}

export class LeaveRequestRawDto extends Record {
    constructor(Id, OrgId, UserId, StartDate, FinishDate, Status, DateApproved, ApprovedBy, DateRejected, RejectedBy, LeaveRequestType, MedicalCertificateSubmitted, Reason, PayrollAdminComment, PayrollAdminCommentUser, Created, Modified) {
        super();
        this.Id = Id;
        this.OrgId = OrgId;
        this.UserId = UserId;
        this.StartDate = StartDate;
        this.FinishDate = FinishDate;
        this.Status = (Status | 0);
        this.DateApproved = DateApproved;
        this.ApprovedBy = ApprovedBy;
        this.DateRejected = DateRejected;
        this.RejectedBy = RejectedBy;
        this.LeaveRequestType = (LeaveRequestType | 0);
        this.MedicalCertificateSubmitted = MedicalCertificateSubmitted;
        this.Reason = Reason;
        this.PayrollAdminComment = PayrollAdminComment;
        this.PayrollAdminCommentUser = PayrollAdminCommentUser;
        this.Created = Created;
        this.Modified = Modified;
    }
}

export function LeaveRequestRawDto_$reflection() {
    return record_type("Domain.Dto.User.LeaveRequestRawDto", [], LeaveRequestRawDto, () => [["Id", class_type("System.Guid")], ["OrgId", class_type("System.Guid")], ["UserId", class_type("System.Guid")], ["StartDate", class_type("System.DateTime")], ["FinishDate", class_type("System.DateTime")], ["Status", int32_type], ["DateApproved", class_type("System.Nullable`1", [class_type("System.DateTime")])], ["ApprovedBy", class_type("System.Nullable`1", [class_type("System.Guid")])], ["DateRejected", class_type("System.Nullable`1", [class_type("System.DateTime")])], ["RejectedBy", class_type("System.Nullable`1", [class_type("System.Guid")])], ["LeaveRequestType", int32_type], ["MedicalCertificateSubmitted", class_type("System.Nullable`1", [bool_type])], ["Reason", string_type], ["PayrollAdminComment", string_type], ["PayrollAdminCommentUser", class_type("System.Nullable`1", [class_type("System.Guid")])], ["Created", class_type("System.DateTime")], ["Modified", class_type("System.DateTime")]]);
}

export function LeaveRequestStatusRaw_ofLeaveRequestStatus(status) {
    switch (status.tag) {
        case 1:
            return 1;
        case 2:
            return 2;
        case 3:
            return 3;
        case 4:
            return 4;
        case 5:
            return 5;
        default:
            return 0;
    }
}

export function LeaveRequestStatusRaw_toLeaveRequestStatus(leaveRequest) {
    let DateApproved;
    const matchValue = leaveRequest.Status | 0;
    switch (matchValue) {
        case 0:
            return new FSharpResult$2(0, [new LeaveRequestStatus(0, [])]);
        case 1:
            return new FSharpResult$2(0, [new LeaveRequestStatus(1, [])]);
        case 2:
            return new FSharpResult$2(0, [new LeaveRequestStatus(2, [])]);
        case 3:
            return new FSharpResult$2(0, [new LeaveRequestStatus(3, [])]);
        case 4:
            return new FSharpResult$2(0, [new LeaveRequestStatus(4, [(DateApproved = value_6(leaveRequest.DateApproved), {
                ApprovedBy: value_6(leaveRequest.ApprovedBy),
                DateApproved: DateApproved,
            })])]);
        case 5:
            return new FSharpResult$2(0, [new LeaveRequestStatus(5, [{
                DateRejected: value_6(leaveRequest.DateRejected),
                RejectedBy: value_6(leaveRequest.RejectedBy),
                RejectionReason: leaveRequest.Reason,
            }])]);
        default:
            return new FSharpResult$2(1, [singleton(`Invalid LeaveRequestStatus: ${matchValue}`)]);
    }
}

export function LeaveRequestRawDtoModule_ofLeaveRequest(leaveRequest) {
    let patternInput;
    const matchValue = leaveRequest.Status;
    if (matchValue.tag === 4) {
        const x = matchValue.fields[0];
        patternInput = [x.DateApproved, x.ApprovedBy];
    }
    else {
        patternInput = [defaultOf(), defaultOf()];
    }
    let patternInput_1;
    const matchValue_1 = leaveRequest.Status;
    if (matchValue_1.tag === 5) {
        const x_1 = matchValue_1.fields[0];
        patternInput_1 = [x_1.DateRejected, x_1.RejectedBy];
    }
    else {
        patternInput_1 = [defaultOf(), defaultOf()];
    }
    let sickMedicalCertificate;
    const matchValue_2 = leaveRequest.LeaveRequestType;
    sickMedicalCertificate = ((matchValue_2.tag === 0) ? matchValue_2.fields[0].MedicalCertificateSubmitted : defaultOf());
    return new LeaveRequestRawDto(leaveRequest.Id, leaveRequest.OrgId, leaveRequest.UserId, leaveRequest.StartDate, leaveRequest.FinishDate, LeaveRequestStatusRaw_ofLeaveRequestStatus(leaveRequest.Status), patternInput[0], patternInput[1], patternInput_1[0], patternInput_1[1], LeaveRequestTypeModule_toInt(leaveRequest.LeaveRequestType), sickMedicalCertificate, leaveRequest.Reason, Option_toString(leaveRequest.PayrollAdminComment), toNullable(leaveRequest.PayrollAdminCommentUser), leaveRequest.Created, leaveRequest.Modified);
}

export function LeaveRequestRawDtoModule_toLeaveRequest(leaveRequest) {
    const input = LeaveRequestStatusRaw_toLeaveRequestStatus(leaveRequest);
    if (input.tag === 1) {
        return new FSharpResult$2(1, [input.fields[0]]);
    }
    else {
        return new FSharpResult$2(0, [new LeaveRequest(leaveRequest.Id, leaveRequest.OrgId, leaveRequest.UserId, leaveRequest.StartDate, leaveRequest.FinishDate, input.fields[0], LeaveRequestTypeModule_ofInt(defaultArg(ofNullable(leaveRequest.MedicalCertificateSubmitted), false), leaveRequest.LeaveRequestType), leaveRequest.Reason, Option_ofString(leaveRequest.PayrollAdminComment), ofNullable(leaveRequest.PayrollAdminCommentUser), leaveRequest.Created, leaveRequest.Modified)]);
    }
}

export class UserLeavesResponseRawDto extends Record {
    constructor(Leaves, HasMoreRecords) {
        super();
        this.Leaves = Leaves;
        this.HasMoreRecords = HasMoreRecords;
    }
}

export function UserLeavesResponseRawDto_$reflection() {
    return record_type("Domain.Dto.User.UserLeavesResponseRawDto", [], UserLeavesResponseRawDto, () => [["Leaves", list_type(LeaveRequestRawDto_$reflection())], ["HasMoreRecords", bool_type]]);
}

export function UserLeavesResponseRawDtoModule_ofUserLeavesResponse(userLeavesResponse) {
    return new UserLeavesResponseRawDto(map(LeaveRequestRawDtoModule_ofLeaveRequest, userLeavesResponse.Leaves), userLeavesResponse.HasMoreRecords);
}

export class UserDetailDto extends Record {
    constructor(Id, Name) {
        super();
        this.Id = Id;
        this.Name = Name;
    }
}

export function UserDetailDto_$reflection() {
    return record_type("Domain.Dto.User.UserDetailDto", [], UserDetailDto, () => [["Id", class_type("System.Guid")], ["Name", string_type]]);
}

export function UserDetailDtoModule_ofUserName(userName) {
    return new UserDetailDto(userName.Id, userName.Name);
}

export function UserDetailDtoModule_toUserName(userName) {
    return new UserDetail(userName.Id, userName.Name);
}

