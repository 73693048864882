import { xeroRedirectURI, xeroScope } from "../../../../Logos.Shared/Domain/Dto/Organisation.fs.js";
import { Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { class_type, union_type, record_type, list_type, string_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { getXeroClientId as getXeroClientId_1 } from "../../../AppDomain/UseCase/Organisation.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../Infrastructure/AppRoot.fs.js";
import { ofArray, singleton as singleton_1, empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../../Component/ViewContainer.fs.js";
import { createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Daisy_error, Daisy_h2 } from "../../Component/Component.fs.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";

export function getXeroConnectUrl(clientId, redirectUrl, stateId) {
    return `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUrl}&scope=${xeroScope}=${stateId}`;
}

export class State extends Record {
    constructor(XeroClientId, Errors) {
        super();
        this.XeroClientId = XeroClientId;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.Xero.ConnectToXero.State", [], State, () => [["XeroClientId", string_type], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["ConnectToXero", "GetXeroClientIdResponse", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.Xero.ConnectToXero.Msg", [], Msg, () => [[], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [string_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", string_type]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Exception")]]]);
}

export function getXeroClientId() {
    return cmdOfAsync((Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(2, [Item_1])), () => singleton.Delay(() => getXeroClientId_1(appRoot.OrganisationService, AppRootModule_getSelectedOrgId())));
}

export function init() {
    return [new State("", empty()), getXeroClientId()];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            if (msg.fields[0].tag === 1) {
                return [new State(state.XeroClientId, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(msg.fields[0].fields[0], state.Errors), Cmd_none()];
            }
        case 2:
            return [new State(state.XeroClientId, singleton_1(msg.fields[0].message)), Cmd_none()];
        default:
            return [state, Cmd_none()];
    }
}

export function IndexView() {
    let elems_2, elems_1;
    const state_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init, update, (_arg, _arg_1) => {
    }), undefined, [])[0];
    if (state_1.XeroClientId !== "") {
        window.location.href = getXeroConnectUrl(state_1.XeroClientId, xeroRedirectURI, AppRootModule_getSelectedOrgId());
    }
    return container([createElement("div", createObj(singleton_1((elems_2 = [Daisy_h2("Connecting to Xero..."), Daisy_error(state_1.Errors), createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-1", "lg:grid-cols-2", "gap-4", "m-4"])], (elems_1 = [createElement("div", {
        children: reactApi.Children.toArray([]),
    })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))]))))]);
}

