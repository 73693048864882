import { join } from "../fable-library-js.4.19.3/String.js";
import { ofArray, choose } from "../fable-library-js.4.19.3/List.js";
import { toString } from "../fable-library-js.4.19.3/Types.js";

export function op_PlusPlus(prop1, prop2) {
    return ["className", join(" ", choose((arg) => {
        const _arg = arg;
        if (_arg[0] === "className") {
            return toString(_arg[1]);
        }
        else {
            return undefined;
        }
    }, ofArray([prop1, prop2])))];
}

