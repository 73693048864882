import { singleton } from "../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library-js.4.19.3/Result.js";
import { map } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { JobDetailDtoModule_toJobDetail } from "../../../Logos.Shared/Domain/Dto/Job.fs.js";
import { ExpenseDtoModule_ofExpense, ExpenseDtoModule_toExpense } from "../../../Logos.Shared/Domain/Dto/Expense.fs.js";

export function searchExpenses(expenseService, selectedOrgId, expenseSearchDetails, pagination) {
    return singleton.Delay(() => expenseService.SearchExpenses(selectedOrgId, expenseSearchDetails, pagination));
}

export function getExpense(expenseService, jobService, selectedOrgId, expenseId) {
    return singleton.Delay(() => {
        let jobsJob;
        const input = jobService.GetActiveJobs(selectedOrgId);
        jobsJob = singleton.Bind(input, (x$0027) => {
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [map(JobDetailDtoModule_toJobDetail, input_2.fields[0])])));
            return singleton.Return(value);
        });
        let expensesJob;
        const input_7 = expenseService.GetExpense(selectedOrgId, expenseId);
        expensesJob = singleton.Bind(input_7, (x$0027_1) => {
            let input_6;
            let value_2;
            const input_9 = x$0027_1;
            value_2 = ((input_9.tag === 1) ? (new FSharpResult$2(1, [input_9.fields[0]])) : (new FSharpResult$2(0, [(input_6 = input_9.fields[0], (input_6 == null) ? undefined : ExpenseDtoModule_toExpense(input_6))])));
            return singleton.Return(value_2);
        });
        return singleton.Bind(jobsJob, (input_1_4) => {
            const input_17 = input_1_4;
            if (input_17.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_17.fields[0]]));
            }
            else {
                return singleton.Bind(expensesJob, (x$0027_2) => {
                    let value_4;
                    const input_13 = x$0027_2;
                    value_4 = ((input_13.tag === 1) ? (new FSharpResult$2(1, [input_13.fields[0]])) : (new FSharpResult$2(0, [[input_17.fields[0], input_13.fields[0]]])));
                    return singleton.Return(value_4);
                });
            }
        });
    });
}

export function getExpenseAttachment(expenseService, selectedOrgId, expenseId) {
    return singleton.Delay(() => expenseService.GetExpenseAttachment(selectedOrgId, expenseId));
}

export function updateExpense(expenseService, selectedOrgId, expense) {
    return singleton.Delay(() => {
        const expense_1 = ExpenseDtoModule_ofExpense(expense);
        return expenseService.UpdateExpense(selectedOrgId, expense_1);
    });
}

export function approveExpense(expenseService, selectedOrgId, expense) {
    return singleton.Delay(() => {
        const expense_1 = ExpenseDtoModule_ofExpense(expense);
        return expenseService.ApproveExpense(selectedOrgId, expense_1);
    });
}

export function rejectExpense(expenseService, selectedOrgId, expense) {
    return singleton.Delay(() => {
        const expense_1 = ExpenseDtoModule_ofExpense(expense);
        return expenseService.RejectExpense(selectedOrgId, expense_1);
    });
}

