import { empty, singleton, append, delay, toList } from "../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { createElement } from "react";
import { createObj } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { ofArray } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { op_Subtraction, op_Addition, toUInt64, equals } from "../../fable_modules/fable-library-js.4.19.3/BigInt.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { Pagination } from "../../../Logos.Shared/Domain/Entity/Pagination.fs.js";

export function paginationControl(isDisabled, currentPage, previousClick, nextClick, firstPageClick) {
    const children_4 = toList(delay(() => append(singleton(createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "join-item"], ["className", "btn-sm"], ["disabled", isDisabled], ["children", "«"], ["onClick", previousClick]]))))), delay(() => append(!equals(currentPage, 1n) ? singleton(createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "join-item"], ["className", "btn-sm"], ["disabled", isDisabled], ["children", "1"], ["onClick", firstPageClick]]))))) : empty(), delay(() => append(singleton(createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "join-item"], ["className", "btn-sm"], ["disabled", isDisabled], ["children", currentPage.toString()]]))))), delay(() => singleton(createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "join-item"], ["className", "btn-sm"], ["disabled", isDisabled], ["children", "»"], ["onClick", nextClick]])))))))))))));
    return createElement("div", {
        className: "join",
        children: reactApi.Children.toArray(Array.from(children_4)),
    });
}

export function getDefaultPagination() {
    return new Pagination(20n, 0n, 1n);
}

export function firstPage(pagination) {
    return getDefaultPagination();
}

export function nextPage(pagination) {
    return new Pagination(pagination.Limit, toUInt64(op_Addition(pagination.Offset, pagination.Limit)), toUInt64(op_Addition(pagination.CurrentPage, 1n)));
}

export function previousPage(pagination) {
    if (equals(pagination.Offset, 0n)) {
        return pagination;
    }
    else {
        return new Pagination(pagination.Limit, toUInt64(op_Subtraction(pagination.Offset, pagination.Limit)), toUInt64(op_Subtraction(pagination.CurrentPage, 1n)));
    }
}

