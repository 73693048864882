import { toString, Union, Record } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type, class_type, record_type, list_type, string_type, bool_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { ofArray, singleton, empty } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createElement } from "react";
import React from "react";
import { createObj } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { join } from "../../fable_modules/fable-library-js.4.19.3/String.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";

export class SignInState extends Record {
    constructor(IsBusy, Error$) {
        super();
        this.IsBusy = IsBusy;
        this.Error = Error$;
    }
}

export function SignInState_$reflection() {
    return record_type("Presentation.Pages.Index.SignInState", [], SignInState, () => [["IsBusy", bool_type], ["Error", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.Index.Msg", [], Msg, () => [[["Item", class_type("System.Exception")]]]);
}

export function init() {
    return [new SignInState(false, empty()), Cmd_none()];
}

export function update(msg, model) {
    return [new SignInState(model.IsBusy, singleton(toString(msg.fields[0]))), Cmd_none()];
}

export const heroSection = createElement("div", createObj(Helpers_combineClasses("hero", ofArray([["className", join(" ", ["h-64", "bg-primary", "bg-cover", "bg-center"])], ["style", {
    backgroundImage: "url(\'./image/10thDoorLogo.jpg\')",
}], (() => {
    let elems_1, elems;
    const elems_2 = [createElement("div", {
        className: "hero-overlay bg-opacity-60 bg-primary",
    }), createElement("div", createObj(Helpers_combineClasses("hero-content", ofArray([["className", "text-center text-neutral-content"], (elems_1 = [createElement("div", createObj(ofArray([["className", "max-w-md"], (elems = [createElement("h1", {
        className: "mb-5 text-3xl font-bold",
        children: "10thDoor",
    }), createElement("p", {
        className: "mb-5",
        children: "Welcome to 10thDoor.",
    })], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))))];
    return ["children", reactApi.Children.toArray(Array.from(elems_2))];
})()]))));

export const featuresSection = createElement("section", createObj(ofArray([["className", "py-20 bg-base-200"], (() => {
    let elems, value_10, elems_22, elems_3, elems_2, elems_1, elems_6, elems_5, elems_4, elems_9, elems_8, elems_7, value_51, elems_12, elems_11, elems_10, elems_15, elems_14, elems_13, elems_18, elems_17, elems_16, value_90, elems_21, elems_20, elems_19, elems_23;
    const elems_24 = [createElement("div", createObj(ofArray([["className", "text-center mb-10"], (elems = [createElement("h2", {
        className: "text-4xl font-bold",
        children: "Discover the Power of WorkMate",
    }), createElement("p", createObj(ofArray([["className", "text-lg max-w-2xl mx-auto"], (value_10 = "Streamline timesheets, invoicing, payroll, expenses, and job cost allocation—all with one intuitive platform. Work smarter, not harder, with WorkMate.", ["children", value_10])])))], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", "flex flex-wrap justify-center"], (elems_22 = [createElement("div", createObj(Helpers_combineClasses("card", ofArray([["className", "m-4 w-80 bg-base-100 shadow-xl"], (elems_3 = [createElement("div", createObj(ofArray([["className", "card-body"], (elems_2 = [createElement("h3", createObj(ofArray([["className", "card-title flex items-center"], (elems_1 = [createElement("i", {
        className: "fas fa-mobile-alt mr-2",
    }), createElement("span", {
        children: ["Mobile Shift Data Capture"],
    })], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("p", {
        children: ["Employees easily input shift data on-the-go, ensuring accurate and timely records."],
    })], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))), createElement("div", createObj(Helpers_combineClasses("card", ofArray([["className", "m-4 w-80 bg-base-100 shadow-xl"], (elems_6 = [createElement("div", createObj(ofArray([["className", "card-body"], (elems_5 = [createElement("h3", createObj(ofArray([["className", "card-title flex items-center"], (elems_4 = [createElement("i", {
        className: "fas fa-clock mr-2",
    }), createElement("span", {
        children: ["Automated Timesheet Runs"],
    })], ["children", reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("p", {
        children: ["Eliminate manual timesheets with automation, saving time and reducing errors."],
    })], ["children", reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", reactApi.Children.toArray(Array.from(elems_6))])])))), createElement("div", createObj(Helpers_combineClasses("card", ofArray([["className", "m-4 w-80 bg-base-100 shadow-xl"], (elems_9 = [createElement("div", createObj(ofArray([["className", "card-body"], (elems_8 = [createElement("h3", createObj(ofArray([["className", "card-title flex items-center"], (elems_7 = [createElement("i", {
        className: "fas fa-file-invoice-dollar mr-2",
    }), createElement("span", {
        children: ["Efficient Invoicing"],
    })], ["children", reactApi.Children.toArray(Array.from(elems_7))])]))), (value_51 = "Generate invoices instantly from shift data and streamline your billing with seamless export to Xero™.", createElement("p", {
        children: [value_51],
    }))], ["children", reactApi.Children.toArray(Array.from(elems_8))])])))], ["children", reactApi.Children.toArray(Array.from(elems_9))])])))), createElement("div", createObj(Helpers_combineClasses("card", ofArray([["className", "m-4 w-80 bg-base-100 shadow-xl"], (elems_12 = [createElement("div", createObj(ofArray([["className", "card-body"], (elems_11 = [createElement("h3", createObj(ofArray([["className", "card-title flex items-center"], (elems_10 = [createElement("i", {
        className: "fas fa-file-export mr-2",
    }), createElement("span", {
        children: ["Seamless Payroll Export"],
    })], ["children", reactApi.Children.toArray(Array.from(elems_10))])]))), createElement("p", {
        children: ["Effortlessly export payroll data to Smartly™ for easy processing."],
    })], ["children", reactApi.Children.toArray(Array.from(elems_11))])])))], ["children", reactApi.Children.toArray(Array.from(elems_12))])])))), createElement("div", createObj(Helpers_combineClasses("card", ofArray([["className", "m-4 w-80 bg-base-100 shadow-xl"], (elems_15 = [createElement("div", createObj(ofArray([["className", "card-body"], (elems_14 = [createElement("h3", createObj(ofArray([["className", "card-title flex items-center"], (elems_13 = [createElement("i", {
        className: "fas fa-project-diagram mr-2",
    }), createElement("span", {
        children: ["Cost Allocation with Simpro™"],
    })], ["children", reactApi.Children.toArray(Array.from(elems_13))])]))), createElement("p", {
        children: ["Allocate labor costs to specific projects or cost centers with ease."],
    })], ["children", reactApi.Children.toArray(Array.from(elems_14))])])))], ["children", reactApi.Children.toArray(Array.from(elems_15))])])))), createElement("div", createObj(Helpers_combineClasses("card", ofArray([["className", "m-4 w-80 bg-base-100 shadow-xl"], (elems_18 = [createElement("div", createObj(ofArray([["className", "card-body"], (elems_17 = [createElement("h3", createObj(ofArray([["className", "card-title flex items-center"], (elems_16 = [createElement("i", {
        className: "fas fa-receipt mr-2",
    }), createElement("span", {
        children: ["Expense Tracking"],
    })], ["children", reactApi.Children.toArray(Array.from(elems_16))])]))), (value_90 = "Capture and track expenses with receipt scanning to streamline payroll processing and employee reimbursements.", createElement("p", {
        children: [value_90],
    }))], ["children", reactApi.Children.toArray(Array.from(elems_17))])])))], ["children", reactApi.Children.toArray(Array.from(elems_18))])])))), createElement("div", createObj(Helpers_combineClasses("card", ofArray([["className", "m-4 w-80 bg-base-100 shadow-xl"], (elems_21 = [createElement("div", createObj(ofArray([["className", "card-body"], (elems_20 = [createElement("h3", createObj(ofArray([["className", "card-title flex items-center"], (elems_19 = [createElement("i", {
        className: "fas fa-chart-line mr-2",
    }), createElement("span", {
        children: ["Reporting"],
    })], ["children", reactApi.Children.toArray(Array.from(elems_19))])]))), createElement("p", {
        children: ["Get insights into your workforce with reports for informed decisions."],
    })], ["children", reactApi.Children.toArray(Array.from(elems_20))])])))], ["children", reactApi.Children.toArray(Array.from(elems_21))])]))))], ["children", reactApi.Children.toArray(Array.from(elems_22))])]))), createElement("div", createObj(ofArray([["className", "text-center mt-10"], (elems_23 = [createElement("a", {
        className: "btn btn-primary",
        href: "/signin",
        children: "Get Started with WorkMate",
    })], ["children", reactApi.Children.toArray(Array.from(elems_23))])])))];
    return ["children", reactApi.Children.toArray(Array.from(elems_24))];
})()])));

export const testimonialsSection = createElement("section", createObj(ofArray([["className", "py-20 bg-base-100"], (() => {
    let elems, elems_5, elems_2, elems_1, value_21, elems_4, elems_3, value_35;
    const elems_6 = [createElement("div", createObj(ofArray([["className", "text-center mb-10"], (elems = [createElement("h2", {
        className: "text-4xl font-bold",
        children: "What Our Clients Are Saying",
    }), createElement("p", {
        className: "text-lg max-w-xl mx-auto",
        children: "Discover how WorkMate has helped businesses streamline their operations and save time.",
    })], ["children", reactApi.Children.toArray(Array.from(elems))])]))), createElement("div", createObj(ofArray([["className", "flex flex-wrap justify-center"], (elems_5 = [createElement("div", createObj(Helpers_combineClasses("card", ofArray([["className", "m-4 w-80 bg-base-200 shadow-xl"], (elems_2 = [createElement("div", createObj(ofArray([["className", "card-body flex flex-col items-center"], (elems_1 = [createElement("p", createObj(ofArray([["className", "mt-4 text-center"], (value_21 = "“I absolutely love this app! It\'s incredibly user-friendly and has a sleek design that makes navigation a breeze. The features are well thought out and really enhance my experience. I appreciate the regular updates that keep improving functionality and adding new features. Overall, it\'s been a fantastic tool that I rely on daily. Highly recommend it to others!”", ["children", value_21])]))), createElement("p", {
        className: "text-right mt-4",
        children: "- Tatiana Pelser, Director at COB Limited",
    })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))), createElement("div", createObj(Helpers_combineClasses("card", ofArray([["className", "m-4 w-80 bg-base-200 shadow-xl"], (elems_4 = [createElement("div", createObj(ofArray([["className", "card-body flex flex-col items-center"], (elems_3 = [createElement("p", createObj(ofArray([["className", "mt-4 text-center"], (value_35 = "WorkMate has transformed how we handle timesheets, payroll, and expense tracking, with the added benefit of seamless cost allocation to our customers through Simpro. The mobile app is a game-changer for our field team, and integrations with Xero and Smartly save us hours each week. It’s a user-friendly, essential tool for any labor hire organization looking to streamline operations!”", ["children", value_35])]))), createElement("p", {
        className: "text-right mt-4",
        children: "- Manisha Dhiman, BDM at CarryOn Working Limited",
    })], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", reactApi.Children.toArray(Array.from(elems_4))])]))))], ["children", reactApi.Children.toArray(Array.from(elems_5))])])))];
    return ["children", reactApi.Children.toArray(Array.from(elems_6))];
})()])));

export const footerSection = createElement("footer", createObj(Helpers_combineClasses("footer", ofArray([["className", "p-10 bg-neutral text-neutral-content"], (() => {
    let elems_1, elems;
    const elems_2 = [createElement("div", createObj(ofArray([["className", "flex justify-between items-center"], (elems_1 = [createElement("div", createObj(singleton((elems = [createElement("p", {
        className: "font-bold",
        children: "10thDoor",
    }), createElement("p", {
        children: ["© 2024 10thDoor. All Rights Reserved."],
    }), createElement("a", {
        href: "mailto:info@10thdoor.com?subject=Web Inquiry",
        children: "Email Us",
    })], ["children", reactApi.Children.toArray(Array.from(elems))]))))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))];
    return ["children", reactApi.Children.toArray(Array.from(elems_2))];
})()]))));

export function IndexView() {
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init, update, (_arg, _arg_1) => {
    }), undefined, []);
    return createElement("div", {
        children: reactApi.Children.toArray([heroSection, featuresSection, testimonialsSection, footerSection]),
    });
}

