import { Record } from "../../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { int32_type, record_type, string_type, option_type, class_type } from "../../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { DistanceUnitModule_ofInt, DistanceUnitModule_toInt, DistanceUnit_$reflection } from "../../Entity/MeasurementUnits.fs.js";
import { predefinedVehicleLogTags, FuelUnitModule_ofInt, VehicleStatusModule_ofInt, FuelTypeModule_ofInt, FuelUnitModule_toInt, VehicleStatusModule_toInt, FuelTypeModule_toInt, Vehicle, Filename, Registration, Model, Make, Filename_value_5A6F1309, Registration_value_1CC5E859, Model_value_15C4E147, Make_value_7CC7BEEA, VehicleLogTag_$reflection, FuelUnit_$reflection, VehicleStatus_$reflection, FuelType_$reflection } from "../../Entity/LogMaster.fs.js";
import { create, value as value_6 } from "../../Entity/Year.fs.js";
import { FSharpResult$2 } from "../../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Result.js";
import { defaultArg } from "../../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Option.js";
import { defaultOf } from "../../../../Logos.Client/fable_modules/FsToolkit.ErrorHandling.4.16.0/../../../Logos.Shared/Domain/Dto/LogMaster/../../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Util.js";
import { find } from "../../../../Logos.Client/fable_modules/fable-library-js.4.19.3/List.js";

export class VehicleDto extends Record {
    constructor(Id, UserId, OrgId, Make, DistanceUnit, FuelType, Model, Year, Registration, Updated, Status, PhotoFilename, ServerUpdated, FuelUnit, DefaultTag) {
        super();
        this.Id = Id;
        this.UserId = UserId;
        this.OrgId = OrgId;
        this.Make = Make;
        this.DistanceUnit = DistanceUnit;
        this.FuelType = FuelType;
        this.Model = Model;
        this.Year = Year;
        this.Registration = Registration;
        this.Updated = Updated;
        this.Status = Status;
        this.PhotoFilename = PhotoFilename;
        this.ServerUpdated = ServerUpdated;
        this.FuelUnit = FuelUnit;
        this.DefaultTag = DefaultTag;
    }
}

export function VehicleDto_$reflection() {
    return record_type("Domain.Dto.LogMaster.VehicleDto", [], VehicleDto, () => [["Id", option_type(class_type("System.Guid"))], ["UserId", class_type("System.Guid")], ["OrgId", class_type("System.Guid")], ["Make", string_type], ["DistanceUnit", DistanceUnit_$reflection()], ["FuelType", FuelType_$reflection()], ["Model", option_type(string_type)], ["Year", option_type(string_type)], ["Registration", option_type(string_type)], ["Updated", class_type("System.DateTime")], ["Status", VehicleStatus_$reflection()], ["PhotoFilename", string_type], ["ServerUpdated", class_type("System.DateTime")], ["FuelUnit", FuelUnit_$reflection()], ["DefaultTag", VehicleLogTag_$reflection()]]);
}

export class VehicleSearchDetailDto extends Record {
    constructor(Id, Description) {
        super();
        this.Id = Id;
        this.Description = Description;
    }
}

export function VehicleSearchDetailDto_$reflection() {
    return record_type("Domain.Dto.LogMaster.VehicleSearchDetailDto", [], VehicleSearchDetailDto, () => [["Id", class_type("System.Guid")], ["Description", string_type]]);
}

export class LogPointTagDto extends Record {
    constructor(Id, Name) {
        super();
        this.Id = Id;
        this.Name = Name;
    }
}

export function LogPointTagDto_$reflection() {
    return record_type("Domain.Dto.LogMaster.LogPointTagDto", [], LogPointTagDto, () => [["Id", class_type("System.Guid")], ["Name", string_type]]);
}

export class VehicleRawDto extends Record {
    constructor(Id, Make, DistanceUnit, FuelType, Model, Year, Registration, Updated, Status, PhotoFilename, ServerUpdated, FuelUnit, DefaultTag) {
        super();
        this.Id = Id;
        this.Make = Make;
        this.DistanceUnit = (DistanceUnit | 0);
        this.FuelType = (FuelType | 0);
        this.Model = Model;
        this.Year = Year;
        this.Registration = Registration;
        this.Updated = Updated;
        this.Status = (Status | 0);
        this.PhotoFilename = PhotoFilename;
        this.ServerUpdated = ServerUpdated;
        this.FuelUnit = (FuelUnit | 0);
        this.DefaultTag = DefaultTag;
    }
}

export function VehicleRawDto_$reflection() {
    return record_type("Domain.Dto.LogMaster.VehicleRawDto", [], VehicleRawDto, () => [["Id", class_type("System.Guid")], ["Make", string_type], ["DistanceUnit", int32_type], ["FuelType", int32_type], ["Model", string_type], ["Year", string_type], ["Registration", string_type], ["Updated", class_type("System.DateTime")], ["Status", int32_type], ["PhotoFilename", string_type], ["ServerUpdated", class_type("System.DateTime")], ["FuelUnit", int32_type], ["DefaultTag", string_type]]);
}

export function VehicleDtoModule_ofVehicle(vehicle) {
    let input_1, input_3, input_5;
    return new FSharpResult$2(0, [new VehicleDto(vehicle.Id, vehicle.UserId, vehicle.OrgId, Make_value_7CC7BEEA(vehicle.Make), vehicle.DistanceUnit, vehicle.FuelType, (input_1 = vehicle.Model, (input_1 == null) ? undefined : Model_value_15C4E147(input_1)), (input_3 = vehicle.Year, (input_3 == null) ? undefined : value_6(input_3)), (input_5 = vehicle.Registration, (input_5 == null) ? undefined : Registration_value_1CC5E859(input_5)), vehicle.Updated, vehicle.Status, Filename_value_5A6F1309(vehicle.PhotoFilename), vehicle.ServerUpdated, vehicle.FuelUnit, vehicle.DefaultTag)]);
}

export function VehicleDtoModule_toVehicle(vehicle) {
    let input_6, input_8;
    let input_9;
    let input_3;
    const input_1 = vehicle.Year;
    input_3 = ((input_1 == null) ? undefined : create(input_1));
    if (input_3 != null) {
        const input_4 = input_3;
        input_9 = ((input_4.tag === 1) ? (new FSharpResult$2(1, [input_4.fields[0]])) : (new FSharpResult$2(0, [input_4.fields[0]])));
    }
    else {
        input_9 = (new FSharpResult$2(0, [undefined]));
    }
    if (input_9.tag === 1) {
        return new FSharpResult$2(1, [input_9.fields[0]]);
    }
    else {
        return new FSharpResult$2(0, [new Vehicle(defaultArg(vehicle.Id, "00000000-0000-0000-0000-000000000000"), vehicle.UserId, vehicle.OrgId, new Make(vehicle.Make), vehicle.DistanceUnit, vehicle.FuelType, (input_6 = vehicle.Model, (input_6 == null) ? undefined : (new Model(input_6))), input_9.fields[0], (input_8 = vehicle.Registration, (input_8 == null) ? undefined : (new Registration(input_8))), vehicle.Updated, vehicle.Status, new Filename(vehicle.PhotoFilename), vehicle.ServerUpdated, vehicle.FuelUnit, vehicle.DefaultTag)]);
    }
}

export function VehicleRawDtoModule_ofVehicle(vehicle) {
    let input_1, input_3, input_5;
    return new FSharpResult$2(0, [new VehicleRawDto(vehicle.Id, Make_value_7CC7BEEA(vehicle.Make), DistanceUnitModule_toInt(vehicle.DistanceUnit), FuelTypeModule_toInt(vehicle.FuelType), defaultArg((input_1 = vehicle.Model, (input_1 == null) ? undefined : Model_value_15C4E147(input_1)), ""), defaultArg((input_3 = vehicle.Year, (input_3 == null) ? undefined : value_6(input_3)), ""), defaultArg((input_5 = vehicle.Registration, (input_5 == null) ? undefined : Registration_value_1CC5E859(input_5)), ""), vehicle.Updated, VehicleStatusModule_toInt(vehicle.Status), Filename_value_5A6F1309(vehicle.PhotoFilename), vehicle.ServerUpdated, FuelUnitModule_toInt(vehicle.FuelUnit), vehicle.DefaultTag.Id)]);
}

export function VehicleRawDtoModule_toVehicle(vehicle, userId, orgId) {
    let input_1, value_1, value_3, input_3, value_5;
    const input_4 = create(vehicle.Year);
    if (input_4.tag === 1) {
        return new FSharpResult$2(1, [input_4.fields[0]]);
    }
    else {
        return new FSharpResult$2(0, [new Vehicle(vehicle.Id, userId, orgId, new Make(vehicle.Make), DistanceUnitModule_ofInt(vehicle.DistanceUnit), FuelTypeModule_ofInt(vehicle.FuelType), (input_1 = ((value_1 = vehicle.Model, (value_1 === defaultOf()) ? undefined : value_1)), (input_1 == null) ? undefined : (new Model(input_1))), (value_3 = input_4.fields[0], (value_3 === defaultOf()) ? undefined : value_3), (input_3 = ((value_5 = vehicle.Registration, (value_5 === defaultOf()) ? undefined : value_5)), (input_3 == null) ? undefined : (new Registration(input_3))), vehicle.Updated, VehicleStatusModule_ofInt(vehicle.Status), new Filename(vehicle.PhotoFilename), vehicle.ServerUpdated, FuelUnitModule_ofInt(vehicle.FuelUnit), find((x) => (x.Id === vehicle.DefaultTag), predefinedVehicleLogTags))]);
    }
}

