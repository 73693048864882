import { Union, Record } from "../../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { WorkforceSkill_$reflection } from "../../../../../Logos.Shared/Domain/Entity/Job.fs.js";
import { class_type, union_type, record_type, string_type, list_type } from "../../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Busy, Busy_$reflection } from "../../../SharedView.fs.js";
import { FSharpResult$2 } from "../../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { getWorkforceSkills } from "../../../../AppDomain/UseCase/Job.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../../Infrastructure/AppRoot.fs.js";
import { map, singleton as singleton_1, empty } from "../../../../fable_modules/fable-library-js.4.19.3/List.js";
import { Cmd_none } from "../../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { DataItem, IndexView as IndexView_1 } from "../../../Component/DoughnutViewBase.fs.js";

export class State extends Record {
    constructor(WorkforceSkills, IsBusy, Errors) {
        super();
        this.WorkforceSkills = WorkforceSkills;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.WorkMate.WorkForceSkills.State", [], State, () => [["WorkforceSkills", list_type(WorkforceSkill_$reflection())], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetWorkforceSkillsResult", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.WorkMate.WorkForceSkills.Msg", [], Msg, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(WorkforceSkill_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", list_type(WorkforceSkill_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Exception")]]]);
}

export function getWorkforceSkillsCmd() {
    return cmdOfAsync((Item) => (new Msg(0, [Item])), (Item_1) => (new Msg(1, [Item_1])), () => singleton.Delay(() => getWorkforceSkills(appRoot.JobService, AppRootModule_getSelectedOrgId())));
}

export function init() {
    return [new State(empty(), new Busy(1, []), empty()), getWorkforceSkillsCmd()];
}

export function update(msg, state) {
    if (msg.tag === 1) {
        return [new State(state.WorkforceSkills, new Busy(0, []), singleton_1(msg.fields[0].message)), Cmd_none()];
    }
    else if (msg.fields[0].tag === 1) {
        return [new State(state.WorkforceSkills, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
    }
    else {
        return [new State(msg.fields[0].fields[0], new Busy(0, []), state.Errors), Cmd_none()];
    }
}

export function IndexView() {
    const state_1 = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init, update, (_arg, _arg_1) => {
    }), undefined, [])[0];
    return createElement(IndexView_1, {
        title: "Workforce skills",
        errors: state_1.Errors,
        isBusy: state_1.IsBusy,
        dataItems: map((x) => (new DataItem(x.Skill, x.WorkerCount)), state_1.WorkforceSkills),
    });
}

