import { toString, Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type, class_type, record_type, list_type, string_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Page, Cmd_navigateToPage } from "../../Router.fs.js";
import { ofArray, empty, singleton } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createElement } from "react";
import React from "react";
import { createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";

export class State extends Record {
    constructor(Errors) {
        super();
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.Product.Products.State", [], State, () => [["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GotoStressMaster", "GotoRememberWhereInfo", "GotoLogMaster", "GotoWorkMate", "GotoLogMasterDashboard", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.Product.Products.Msg", [], Msg, () => [[], [], [], [], [], [["Item", class_type("System.Exception")]]]);
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [state, Cmd_navigateToPage(new Page(3, []))];
        case 2:
            return [state, Cmd_navigateToPage(new Page(4, []))];
        case 3:
            return [state, Cmd_navigateToPage(new Page(5, []))];
        case 4:
            return [state, Cmd_navigateToPage(new Page(15, []))];
        case 5:
            return [new State(singleton(toString(msg.fields[0]))), Cmd_none()];
        default:
            return [state, Cmd_navigateToPage(new Page(2, []))];
    }
}

export function init(userData) {
    return [new State(empty()), Cmd_none()];
}

export function messageCard(state, dispatch, title, description, link) {
    let elems_3, children_4, elems, children_2;
    return createElement("div", createObj(Helpers_combineClasses("card", ofArray([["className", "bordered"], ["className", "image-full"], (elems_3 = [(children_4 = ofArray([createElement("h2", createObj(Helpers_combineClasses("card-title", singleton((elems = [createElement("span", {
        children: [title],
    })], ["children", reactApi.Children.toArray(Array.from(elems))]))))), createElement("p", {
        children: description,
    }), (children_2 = singleton(createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-primary"], ["children", "Details"], ["onClick", (_arg) => {
        dispatch(link);
    }]]))))), createElement("div", {
        className: "card-actions",
        children: reactApi.Children.toArray(Array.from(children_2)),
    }))]), createElement("div", {
        className: "card-body",
        children: reactApi.Children.toArray(Array.from(children_4)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))));
}

export function IndexView(indexViewInputProps) {
    let elems_1, elems;
    const userData = indexViewInputProps.userData;
    let patternInput;
    const init_1 = init(userData);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return createElement("div", createObj(ofArray([["className", join(" ", ["py-12", "px-4", "sm:px-6", "lg:px-40"])], (elems_1 = [createElement("div", createObj(ofArray([["className", join(" ", ["max-w-md", "space-y-8"])], (elems = [messageCard(state_1, dispatch, "StressMaster", "App to help manage stress using breathing exercise.", new Msg(0, [])), messageCard(state_1, dispatch, "LogMaster", "Helps track time and distance.", new Msg(2, [])), messageCard(state_1, dispatch, "WorkMate", "Easily manage Timesheets with WorkMate.", new Msg(3, []))], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
}

