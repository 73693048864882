import { Union } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type, string_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { isNullOrEmpty } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/String.js";
import { singleton } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/List.js";
import { createInternal } from "./Util.fs.js";

export class Phone extends Union {
    constructor(Item) {
        super();
        this.tag = 0;
        this.fields = [Item];
    }
    cases() {
        return ["PhoneType"];
    }
}

export function Phone_$reflection() {
    return union_type("Domain.Entity.Phone.Phone", [], Phone, () => [[["Item", string_type]]]);
}

function validations() {
    return singleton([isNullOrEmpty, (_arg) => "Phone number must not be empty."]);
}

export function create(phone) {
    return createInternal(validations(), phone, new Phone(phone));
}

export function value(_arg) {
    return _arg.fields[0];
}

