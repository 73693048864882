import { toString, Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { CategoryModule_categories, CategoryModule_toString, Category, ScanResultStatementDetailCategorized_$reflection, Category_$reflection, Assessment_$reflection } from "../../../../Logos.Shared/Domain/Entity/Assessment.fs.js";
import { decimal_type, name as name_30, unit_type, class_type, union_type, record_type, anonRecord_type, string_type, option_type, list_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Busy, Busy_$reflection } from "../../SharedView.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { String_splitWord, Guid_parse } from "../../../../Logos.Shared/Util.fs.js";
import { saveAssessmentItem, loadAssessmentItems, loadAssessments } from "../../../AppDomain/UseCase/Customer.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../Infrastructure/AppRoot.fs.js";
import { concat, sumBy, filter, toArray, ofArray, singleton as singleton_1, map, sortBy, tryFind, empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { int32ToString, createObj, safeHash, comparePrimitives, equals } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { List_groupBy } from "../../../fable_modules/fable-library-js.4.19.3/Seq2.js";
import { createElement } from "react";
import React from "react";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { singleton as singleton_2, append, map as map_1, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { compare, op_Addition, toString as toString_1 } from "../../../fable_modules/fable-library-js.4.19.3/Decimal.js";
import Decimal from "../../../fable_modules/fable-library-js.4.19.3/Decimal.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { map as map_2 } from "../../../fable_modules/fable-library-js.4.19.3/Array.js";
import { reactApi as reactApi_1 } from "../../../fable_modules/Feliz.Recharts.4.2.0/../Feliz.2.8.0/Interop.fs.js";
import { ResponsiveContainer, BarChart, Bar, Legend, Tooltip, YAxis, XAxis, CartesianGrid } from "recharts";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { Daisy_h4, Daisy_h5 } from "../../Component/Component.fs.js";
import { DateTime_toString } from "../../../AppDomain/Util.fs.js";

export class State extends Record {
    constructor(Assessments, SelectedScan, StatementDetailsGrouped, IsBusy, Errors) {
        super();
        this.Assessments = Assessments;
        this.SelectedScan = SelectedScan;
        this.StatementDetailsGrouped = StatementDetailsGrouped;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.CustomerBankStatementAssessment.State", [], State, () => [["Assessments", list_type(Assessment_$reflection())], ["SelectedScan", option_type(Assessment_$reflection())], ["StatementDetailsGrouped", list_type(anonRecord_type(["Category", Category_$reflection()], ["CategoryName", string_type], ["Items", list_type(ScanResultStatementDetailCategorized_$reflection())]))], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["LoadAssessmentsResponse", "LoadAssessmentItems", "LoadAssessmentItemsResponse", "SaveCategory", "SaveCategoryResponse", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.CustomerBankStatementAssessment.Msg", [], Msg, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(Assessment_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", list_type(Assessment_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [["Item", string_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(ScanResultStatementDetailCategorized_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", list_type(ScanResultStatementDetailCategorized_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [["Item", anonRecord_type(["ItemId", class_type("System.Guid")], ["NewValue", string_type])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Exception")]]]);
}

export function cmdLoadAssessments(customerId) {
    return cmdOfAsync((Item) => (new Msg(0, [Item])), (Item_1) => (new Msg(5, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_1;
        const value_1 = Guid_parse(customerId);
        asyncResult_1 = singleton.Return(value_1);
        return singleton.Bind(asyncResult_1, (input_1) => {
            const input_2 = input_1;
            if (input_2.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
            }
            else {
                return loadAssessments(appRoot.CustomerService, AppRootModule_getSelectedOrgId(), input_2.fields[0]);
            }
        });
    }));
}

export function cmdLoadAssessmentItems(assessmentId) {
    return cmdOfAsync((Item) => (new Msg(2, [Item])), (Item_1) => (new Msg(5, [Item_1])), () => singleton.Delay(() => loadAssessmentItems(appRoot.CustomerService, AppRootModule_getSelectedOrgId(), assessmentId)));
}

export function cmdSaveCategory(itemId, newValue) {
    return cmdOfAsync((Item) => (new Msg(4, [Item])), (Item_1) => (new Msg(5, [Item_1])), () => singleton.Delay(() => saveAssessmentItem(appRoot.CustomerService, AppRootModule_getSelectedOrgId(), itemId, newValue)));
}

export function init(customerId) {
    return [new State(empty(), undefined, empty(), new Busy(1, []), empty()), cmdLoadAssessments(customerId)];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [new State(state.Assessments, tryFind((y) => (y.Id === msg.fields[0]), state.Assessments), state.StatementDetailsGrouped, state.IsBusy, state.Errors), cmdLoadAssessmentItems(msg.fields[0])];
        case 2:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Assessments, state.SelectedScan, state.StatementDetailsGrouped, state.IsBusy, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.Assessments, state.SelectedScan, sortBy((x_6) => x_6.CategoryName, map((tupledArg) => {
                    const category = tupledArg[0];
                    return {
                        Category: category,
                        CategoryName: equals(category, new Category(0, [])) ? "" : CategoryModule_toString(category),
                        Items: sortBy((y_2) => y_2.Description, tupledArg[1], {
                            Compare: comparePrimitives,
                        }),
                    };
                }, List_groupBy((x_3) => x_3.Category, msg.fields[0].fields[0], {
                    Equals: equals,
                    GetHashCode: safeHash,
                })), {
                    Compare: comparePrimitives,
                }), state.IsBusy, state.Errors), Cmd_none()];
            }
        case 3:
            return [state, cmdSaveCategory(msg.fields[0].ItemId, msg.fields[0].NewValue)];
        case 4:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Assessments, state.SelectedScan, state.StatementDetailsGrouped, state.IsBusy, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [state, Cmd_none()];
            }
        case 5:
            return [new State(state.Assessments, state.SelectedScan, state.StatementDetailsGrouped, state.IsBusy, singleton_1(toString(msg.fields[0]))), Cmd_none()];
        default:
            if (msg.fields[0].tag === 1) {
                return [new State(state.Assessments, state.SelectedScan, state.StatementDetailsGrouped, state.IsBusy, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(msg.fields[0].fields[0], state.SelectedScan, state.StatementDetailsGrouped, state.IsBusy, state.Errors), Cmd_none()];
            }
    }
}

export function categoryItems(statementDetails, dispatch) {
    let elems_3, children_2, children, children_5;
    return createElement("table", createObj(ofArray([["className", "table-fixed table-zebra table-compact"], (elems_3 = [(children_2 = singleton_1((children = ofArray([createElement("th", {
        width: 20 + "%",
        children: "Date",
    }), createElement("th", {
        width: 40 + "%",
        children: "Description",
    }), createElement("th", {
        width: 20 + "%",
        children: "Amount",
    }), createElement("th", {
        width: 20 + "%",
        children: "Category",
    })]), createElement("tr", {
        children: reactApi.Children.toArray(Array.from(children)),
    }))), createElement("thead", {
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), (children_5 = toList(delay(() => map_1((statementDetail) => {
        let elems_2, elems_1, elems;
        return createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top"])], (elems_2 = [createElement("td", {
            children: [statementDetail.TransactionDate],
        }), createElement("td", {
            children: [statementDetail.Description],
        }), createElement("td", {
            className: join(" ", ["lg:text-right"]),
            children: toString_1(statementDetail.Amount),
        }), createElement("td", createObj(ofArray([["className", join(" ", ["lg:text-right"])], (elems_1 = [createElement("select", createObj(Helpers_combineClasses("select", ofArray([["className", "select-sm"], ["defaultValue", CategoryModule_toString(statementDetail.Category)], ["onChange", (ev) => {
            dispatch(new Msg(3, [{
                ItemId: statementDetail.Id,
                NewValue: ev.target.value,
            }]));
        }], (elems = toList(delay(() => map_1((case$) => createElement("option", {
            value: name_30(case$),
            children: String_splitWord(name_30(case$)),
        }), CategoryModule_categories))), ["children", reactApi.Children.toArray(Array.from(elems))])]))))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
    }, statementDetails))), createElement("tbody", {
        children: reactApi.Children.toArray(Array.from(children_5)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])));
}

export class DataPoint extends Record {
    constructor(Category, Amount) {
        super();
        this.Category = Category;
        this.Amount = Amount;
    }
}

export function DataPoint_$reflection() {
    return record_type("Presentation.Pages.CustomerBankStatementAssessment.DataPoint", [], DataPoint, () => [["Category", Category_$reflection()], ["Amount", decimal_type]]);
}

export function assessmentChart(state) {
    let elements, properties;
    const properties_6 = ofArray([["data", toArray(sortBy((x_7) => x_7.Amount, filter((x_6) => !equals(x_6.Category, new Category(0, [])), map((tupledArg) => (new DataPoint(tupledArg[0], sumBy((x_4) => x_4.Amount, tupledArg[1], {
        GetZero: () => (new Decimal("0")),
        Add: op_Addition,
    }))), List_groupBy((x_2) => x_2.Category, map((x_1) => (new DataPoint(x_1.Category, x_1.Amount)), concat(map((x) => x.Items, state.StatementDetailsGrouped))), {
        Equals: equals,
        GetHashCode: safeHash,
    }))), {
        Compare: compare,
    }))], (elements = ofArray([(properties = singleton_1(["strokeDasharray", join(" ", map_2(int32ToString, new Int32Array([3, 3])))]), reactApi_1.createElement(CartesianGrid, createObj(properties))), reactApi_1.createElement(XAxis, {
        dataKey: (point) => String_splitWord(CategoryModule_toString(point.Category)),
    }), reactApi_1.createElement(YAxis, {}), reactApi_1.createElement(Tooltip, {}), reactApi_1.createElement(Legend, {}), reactApi_1.createElement(Bar, {
        dataKey: (point_1) => toString_1(point_1.Amount),
        fill: "#8884d8",
    })]), ["children", reactApi.Children.toArray(Array.from(elements))])]);
    return reactApi_1.createElement(BarChart, createObj(properties_6));
}

export function IndexView(indexViewInputProps) {
    let elems_7, elems_6, elems_1, elems, elems_5;
    const customerId = indexViewInputProps.customerId;
    let patternInput;
    const init_1 = init(customerId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return createElement("div", createObj(singleton_1((elems_7 = [Daisy_h5("Assessments - Expense categorization"), createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-12", "gap-x-6", "p-8", "rounded"])], (elems_6 = [createElement("div", createObj(ofArray([["className", join(" ", ["col-span-3"])], (elems_1 = [Daisy_h4("Assessments"), createElement("select", createObj(ofArray([["className", join(" ", ["w-full"])], ["size", 10], ["onChange", (ev) => {
        dispatch(new Msg(1, [ev.target.value]));
    }], (elems = toList(delay(() => map_1((assessment) => createElement("option", {
        value: assessment.Id,
        children: DateTime_toString(assessment.AssessmentDate),
    }), state_1.Assessments))), ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))), createElement("div", createObj(ofArray([["className", join(" ", ["col-span-9", "overflow-auto"])], (elems_5 = toList(delay(() => append(singleton_2(Daisy_h4("Items")), delay(() => {
        let properties_3;
        return append(singleton_2((properties_3 = ofArray([["height", 400], ["children", assessmentChart(state_1)]]), reactApi_1.createElement(ResponsiveContainer, createObj(properties_3)))), delay(() => map_1((statementDetail) => {
            let elems_4, elems_3, children, elems_2;
            return createElement("div", createObj(ofArray([["className", join(" ", ["box-content", "border-2", "px-8", "m-1", "rounded"])], (elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-2", "mb-2"])], (elems_3 = [(children = singleton_1(Daisy_h5(`Category: ${String_splitWord(CategoryModule_toString(statementDetail.Category))}`)), createElement("div", {
                children: reactApi.Children.toArray(Array.from(children)),
            })), createElement("div", createObj(ofArray([["className", join(" ", ["xl:text-right"])], (elems_2 = [Daisy_h5(`Total: ${sumBy((x) => x.Amount, statementDetail.Items, {
                GetZero: () => (new Decimal("0")),
                Add: op_Addition,
            })}`)], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))), categoryItems(statementDetail.Items, dispatch)], ["children", reactApi.Children.toArray(Array.from(elems_4))])])));
        }, state_1.StatementDetailsGrouped)));
    })))), ["children", reactApi.Children.toArray(Array.from(elems_5))])])))], ["children", reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", reactApi.Children.toArray(Array.from(elems_7))]))));
}

