import { appRoot, AppRootModule_getSelectedOrgId } from "../../../Infrastructure/AppRoot.fs.js";
import { searchLeaves } from "../../../AppDomain/UseCase/Leaves.fs.js";
import { map as map_2, empty, collect, singleton, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { cons, map, singleton as singleton_1, ofArray, tryFind } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { iconButton, showModalDialog } from "../../Component/ModalDialog.fs.js";
import { alignIconCenter, alignCellTextTop, Daisy_h5, Daisy_DialogWidth } from "../../Component/Component.fs.js";
import { IndexView as IndexView_1 } from "./LeaveEdit.fs.js";
import { map as map_1, defaultArg } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { IndexView as IndexView_2, Msg$2 } from "../BaseSearchAdvanced.fs.js";
import { uncurry2, createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { replace, join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { LeaveRequestType as LeaveRequestType_1, leaveRequestStatuses, LeaveRequestStatusModule_ofString, LeaveSearchDetails, LeaveRequestTypeModule_leaveRequestTypes, LeaveRequestStatusModule_toString, LeaveRequestTypeModule_toString } from "../../../../Logos.Shared/Domain/Entity/User.fs.js";
import { DateTime_dateToStringWithDayName, DateTime_dateTimeToStringWithDayName } from "../../../AppDomain/Util.fs.js";
import { name as name_78 } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { PropHelpers_createOnKey } from "../../../fable_modules/Feliz.2.8.0/./Properties.fs.js";
import { key_enter } from "../../../fable_modules/Feliz.2.8.0/Key.fs.js";
import { System_DateTime__DateTime_ToLocalKind, String_splitWord } from "../../../../Logos.Shared/Util.fs.js";
import { parse, toString } from "../../../fable_modules/fable-library-js.4.19.3/Date.js";
import { fromDate, toLocalTime } from "../../../fable_modules/fable-library-js.4.19.3/DateOffset.js";

export function searchFunc(leaveSearchDetails) {
    const selectedOrgId = AppRootModule_getSelectedOrgId();
    return (pagination) => searchLeaves(appRoot.LeavesService, selectedOrgId, leaveSearchDetails, pagination);
}

export function leavesTable(props) {
    const getUsername = (leaveRequest) => (((leaveRequest.FirstName + " ") + ((leaveRequest.MiddleName === "") ? "" : (`${leaveRequest.MiddleName} `))) + leaveRequest.LastName);
    const children_7 = toList(delay(() => {
        let matchValue_1, x_1, matchValue_2, leaveRequest_1;
        return append((matchValue_1 = props.state.EntityToEdit, (matchValue_1 == null) ? singleton(react.createElement(react.Fragment, {})) : ((x_1 = matchValue_1, (matchValue_2 = tryFind((y) => (y.Id === x_1), props.state.Entities), (matchValue_2 == null) ? singleton(react.createElement(react.Fragment, {})) : ((leaveRequest_1 = matchValue_2, singleton(createElement(showModalDialog, {
            dialogId: x_1,
            header: "",
            width: new Daisy_DialogWidth(1, []),
            heightPercent: "70",
            body: () => {
                const user = getUsername(leaveRequest_1);
                const matchValue = props.state.EntityToEdit;
                if (matchValue == null) {
                    const xs = [Daisy_h5("No Leave selected.")];
                    return react.createElement(react.Fragment, {}, ...xs);
                }
                else {
                    const x = matchValue;
                    return createElement(IndexView_1, {
                        ApprovedByUsername: defaultArg(leaveRequest_1.ApprovedByUsername, ""),
                        Created: leaveRequest_1.Created,
                        Id: x,
                        RejectedByUsername: defaultArg(leaveRequest_1.RejectedByUsername, ""),
                        User: user,
                    });
                }
            },
            onShow: () => {
                props.dispatch(new Msg$2(4, [x_1]));
            },
            onClose: () => {
                props.dispatch(new Msg$2(4, [undefined]));
            },
        })))))))), delay(() => {
            let elems_13, children_2, children, children_4;
            return singleton(createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["flex", "w-full"])], (elems_13 = [(children_2 = singleton_1((children = ofArray([createElement("th", {
                width: 15 + "%",
                children: "User",
            }), createElement("th", {
                width: 10 + "%",
                children: "Leave Type",
            }), createElement("th", {
                width: 15 + "%",
                children: "Created",
            }), createElement("th", {
                width: 20 + "%",
                children: "Date range",
            }), createElement("th", {
                width: 20 + "%",
                children: "Reason",
            }), createElement("th", {
                width: 15 + "%",
                children: "Status",
            }), createElement("th", {
                width: 5 + "%",
                children: "",
            })]), createElement("tr", {
                children: reactApi.Children.toArray(Array.from(children)),
            }))), createElement("thead", {
                children: reactApi.Children.toArray(Array.from(children_2)),
            })), (children_4 = toList(delay(() => collect((leaveRequest_2) => {
                let elems_12, elems_2, elems_1, elems, elems_4, elems_3, elems_7, elems_6, elems_9, elems_8, elems_11, elems_10;
                const username = getUsername(leaveRequest_2);
                return singleton(createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top", "hover"])], (elems_12 = [createElement("td", createObj(ofArray([alignCellTextTop, ["style", {
                    cursor: "pointer",
                }], ["onClick", (_arg_3) => {
                    props.dispatch(new Msg$2(4, [leaveRequest_2.Id]));
                }], (elems_2 = [createElement("div", createObj(singleton_1((elems_1 = [createElement("span", createObj(ofArray([["style", {
                    display: "flex",
                    alignItems: "center",
                }], (elems = [createElement("i", createObj(toList(delay(() => {
                    const matchValue_3 = leaveRequest_2.Status;
                    return (matchValue_3.tag === 1) ? append(singleton(["className", "fa fa-paper-plane"]), delay(() => singleton(["style", {
                        marginRight: "8px",
                        color: "blue",
                    }]))) : ((matchValue_3.tag === 2) ? append(singleton(["className", "fa fa-undo"]), delay(() => singleton(["style", {
                        marginRight: "8px",
                        color: "blue",
                    }]))) : ((matchValue_3.tag === 3) ? append(singleton(["className", "fa fa-hourglass"]), delay(() => singleton(["style", {
                        marginRight: "8px",
                        color: "orange",
                    }]))) : ((matchValue_3.tag === 4) ? append(singleton(["className", "fa fa-check"]), delay(() => singleton(["style", {
                        marginRight: "8px",
                        color: "green",
                    }]))) : ((matchValue_3.tag === 5) ? append(singleton(["className", "fa fa-times"]), delay(() => singleton(["style", {
                        marginRight: "8px",
                        color: "red",
                    }]))) : append(singleton(["className", "fa fa-book"]), delay(() => singleton(["style", {
                        marginRight: "8px",
                        color: "blue",
                    }])))))));
                })))), createElement("div", createObj(ofArray([alignCellTextTop, ["children", username]])))], ["children", reactApi.Children.toArray(Array.from(elems))])])))], ["children", reactApi.Children.toArray(Array.from(elems_1))]))))], ["children", reactApi.Children.toArray(Array.from(elems_2))])]))), createElement("td", createObj(ofArray([alignCellTextTop, ["style", {
                    cursor: "pointer",
                }], ["onClick", (_arg_4) => {
                    props.dispatch(new Msg$2(4, [leaveRequest_2.Id]));
                }], ["children", LeaveRequestTypeModule_toString(leaveRequest_2.LeaveRequestType)]]))), createElement("td", createObj(ofArray([alignCellTextTop, ["style", {
                    cursor: "pointer",
                }], ["onClick", (_arg_6) => {
                    props.dispatch(new Msg$2(4, [leaveRequest_2.Id]));
                }], ["children", DateTime_dateTimeToStringWithDayName(leaveRequest_2.Created)]]))), createElement("td", createObj(ofArray([alignCellTextTop, ["style", {
                    cursor: "pointer",
                }], ["onClick", (_arg_7) => {
                    props.dispatch(new Msg$2(4, [leaveRequest_2.Id]));
                }], (elems_4 = [createElement("div", createObj(ofArray([["className", "grid grid-rows-2"], (elems_3 = [createElement("div", {
                    children: DateTime_dateToStringWithDayName(leaveRequest_2.StartDate),
                }), createElement("div", {
                    children: DateTime_dateToStringWithDayName(leaveRequest_2.FinishDate),
                })], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", reactApi.Children.toArray(Array.from(elems_4))])]))), createElement("td", createObj(ofArray([alignCellTextTop, ["style", {
                    cursor: "pointer",
                }], ["onClick", (_arg_8) => {
                    props.dispatch(new Msg$2(4, [leaveRequest_2.Id]));
                }], ["children", leaveRequest_2.Reason]]))), createElement("td", createObj(ofArray([alignCellTextTop, ["style", {
                    cursor: "pointer",
                }], ["onClick", (_arg_9) => {
                    props.dispatch(new Msg$2(4, [leaveRequest_2.Id]));
                }], (elems_7 = [createElement("div", createObj(ofArray([["className", "grid grid-rows-2"], (elems_6 = toList(delay(() => append(singleton(createElement("div", {
                    children: LeaveRequestStatusModule_toString(leaveRequest_2.Status),
                })), delay(() => {
                    let matchValue_4, x_2;
                    return append((matchValue_4 = leaveRequest_2.Status, (matchValue_4.tag === 1) ? singleton(react.createElement(react.Fragment, {})) : ((matchValue_4.tag === 2) ? singleton(react.createElement(react.Fragment, {})) : ((matchValue_4.tag === 3) ? singleton(react.createElement(react.Fragment, {})) : ((matchValue_4.tag === 4) ? singleton(createElement("div", {
                        children: defaultArg(leaveRequest_2.ApprovedByUsername, ""),
                    })) : ((matchValue_4.tag === 5) ? ((x_2 = matchValue_4.fields[0], append(singleton(createElement("div", {
                        children: defaultArg(leaveRequest_2.RejectedByUsername, ""),
                    })), delay(() => ((x_2.RejectionReason !== "") ? singleton(createElement("div", {
                        className: "text-red-500",
                        children: x_2.RejectionReason,
                    })) : empty()))))) : singleton(react.createElement(react.Fragment, {}))))))), delay(() => {
                        let elems_5;
                        return (leaveRequest_2.PayrollAdminComment !== "") ? singleton(createElement("div", createObj(ofArray([["className", "text-purple-500 mt-2"], (elems_5 = [createElement("div", {
                            className: "font-bold",
                            children: "Payroll Admin: ",
                        }), createElement("span", {
                            children: leaveRequest_2.PayrollAdminComment,
                        })], ["children", reactApi.Children.toArray(Array.from(elems_5))])])))) : empty();
                    }));
                })))), ["children", reactApi.Children.toArray(Array.from(elems_6))])])))], ["children", reactApi.Children.toArray(Array.from(elems_7))])]))), createElement("td", createObj(ofArray([["style", {
                    cursor: "pointer",
                }], ["onClick", (_arg_11) => {
                    props.dispatch(new Msg$2(4, [leaveRequest_2.Id]));
                }], (elems_9 = [createElement("div", createObj(ofArray([alignCellTextTop, ["key", leaveRequest_2.Id], (elems_8 = [createElement("div", {
                    className: join(" ", ["font-bold"]),
                    children: "",
                })], ["children", reactApi.Children.toArray(Array.from(elems_8))])])))], ["children", reactApi.Children.toArray(Array.from(elems_9))])]))), createElement("td", createObj(singleton_1((elems_11 = [createElement("div", createObj(ofArray([alignIconCenter, (elems_10 = [iconButton("fas fa-edit", (_arg_12) => {
                    props.dispatch(new Msg$2(4, [leaveRequest_2.Id]));
                })], ["children", reactApi.Children.toArray(Array.from(elems_10))])])))], ["children", reactApi.Children.toArray(Array.from(elems_11))]))))], ["children", reactApi.Children.toArray(Array.from(elems_12))])]))));
            }, props.state.Entities))), createElement("tbody", {
                children: reactApi.Children.toArray(Array.from(children_4)),
            }))], ["children", reactApi.Children.toArray(Array.from(elems_13))])])))));
        }));
    }));
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_7)),
    });
}

export const leaveTypes = cons("All", map(name_78, ofArray(LeaveRequestTypeModule_leaveRequestTypes)));

export function searchPanel(props) {
    let children_2, elems, elems_14, children_8, children_5, elems_4, elems_3, children_14, children_11, elems_8, elems_7, children_19, children_17, children_24, children_22;
    const children_26 = ofArray([(children_2 = ofArray([createElement("label", createObj(Helpers_combineClasses("label", singleton_1(["children", "Search"])))), createElement("div", createObj(ofArray([["className", "flex space-x-2"], (elems = [createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "w-full"], ["className", "input-bordered"], ["className", "input-sm"], ["autoFocus", true], ["placeholder", "Search"], ["readOnly", false], ["onChange", (ev) => {
        let bind$0040;
        props.dispatch(new Msg$2(0, [(bind$0040 = props.state.SearchDetails, new LeaveSearchDetails(ev.target.value, bind$0040.StartDate, bind$0040.FinishDate, bind$0040.Status, bind$0040.LeaveRequestType))]));
    }], ["onKeyUp", (ev_1) => {
        PropHelpers_createOnKey(key_enter, (_arg) => {
            props.dispatch(new Msg$2(1, []));
        }, ev_1);
    }]])))), createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-primary"], ["className", "btn-sm"], ["children", "Search"], ["onClick", (_arg_1) => {
        props.dispatch(new Msg$2(1, []));
    }]]))))], ["children", reactApi.Children.toArray(Array.from(elems))])])))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), createElement("div", createObj(ofArray([["className", "flex space-x-2 mt-2"], (elems_14 = [(children_8 = ofArray([(children_5 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Status",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_5)),
    })), createElement("div", createObj(ofArray([["className", "flex space-x-2"], (elems_4 = [createElement("select", createObj(Helpers_combineClasses("select", ofArray([["className", "select-bordered"], ["className", "select-sm"], ["placeholder", "Status"], ["value", defaultArg(map_1(LeaveRequestStatusModule_toString, props.state.SearchDetails.Status), "All")], ["onChange", (ev_2) => {
        let bind$0040_1;
        const x_1 = ev_2.target.value;
        props.dispatch(new Msg$2(0, [(bind$0040_1 = props.state.SearchDetails, new LeaveSearchDetails(bind$0040_1.SearchText, bind$0040_1.StartDate, bind$0040_1.FinishDate, (x_1 === "All") ? undefined : LeaveRequestStatusModule_ofString(x_1), bind$0040_1.LeaveRequestType))]));
    }], (elems_3 = toList(delay(() => map_2((case$) => createElement("option", {
        value: case$,
        children: String_splitWord(case$),
    }), cons("All", map(name_78, ofArray(leaveRequestStatuses)))))), ["children", reactApi.Children.toArray(Array.from(elems_3))])]))))], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_8)),
    })), (children_14 = ofArray([(children_11 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Leave Type",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_11)),
    })), createElement("div", createObj(ofArray([["className", "flex space-x-2"], (elems_8 = [createElement("select", createObj(Helpers_combineClasses("select", ofArray([["className", "select-bordered"], ["className", "select-sm"], ["placeholder", "Leave Type"], ["value", defaultArg(map_1(LeaveRequestTypeModule_toString, props.state.SearchDetails.LeaveRequestType), "All")], ["onChange", (ev_3) => {
        let bind$0040_2;
        const x_4 = ev_3.target.value;
        props.dispatch(new Msg$2(0, [(bind$0040_2 = props.state.SearchDetails, new LeaveSearchDetails(bind$0040_2.SearchText, bind$0040_2.StartDate, bind$0040_2.FinishDate, bind$0040_2.Status, (x_4 === "All") ? undefined : ((x_4 === "Sick Leave") ? (new LeaveRequestType_1(0, [{
            MedicalCertificateSubmitted: false,
        }])) : ((x_4 === "Annual Leave") ? (new LeaveRequestType_1(1, [])) : ((x_4 === "Bereavement Leave") ? (new LeaveRequestType_1(2, [])) : ((x_4 === "Unpaid Leave") ? (new LeaveRequestType_1(3, [])) : (new LeaveRequestType_1(4, []))))))))]));
    }], (elems_7 = toList(delay(() => collect((case$_1) => {
        let value_82;
        const y = String_splitWord(case$_1);
        value_82 = replace(y, "  ", " ").trim();
        return singleton(createElement("option", {
            value: value_82,
            children: value_82,
        }));
    }, leaveTypes))), ["children", reactApi.Children.toArray(Array.from(elems_7))])]))))], ["children", reactApi.Children.toArray(Array.from(elems_8))])])))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_14)),
    })), (children_19 = ofArray([(children_17 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Start Date",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_17)),
    })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["type", "date"], ["className", "input-bordered"], ["className", "input-sm"], ["value", defaultArg(map_1((x_5) => toString(toLocalTime(x_5), "yyyy-MM-dd"), props.state.SearchDetails.StartDate), "")], ["onInput", (e) => {
        let bind$0040_3, bind$0040_4;
        if (e.target.value === "") {
            props.dispatch(new Msg$2(0, [(bind$0040_3 = props.state.SearchDetails, new LeaveSearchDetails(bind$0040_3.SearchText, undefined, bind$0040_3.FinishDate, bind$0040_3.Status, bind$0040_3.LeaveRequestType))]));
        }
        else {
            props.dispatch(new Msg$2(0, [(bind$0040_4 = props.state.SearchDetails, new LeaveSearchDetails(bind$0040_4.SearchText, fromDate(System_DateTime__DateTime_ToLocalKind(parse(e.target.value))), bind$0040_4.FinishDate, bind$0040_4.Status, bind$0040_4.LeaveRequestType))]));
        }
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_19)),
    })), (children_24 = ofArray([(children_22 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Finish Date",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_22)),
    })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["type", "date"], ["className", "input-bordered"], ["className", "input-sm"], ["value", defaultArg(map_1((x_6) => toString(toLocalTime(x_6), "yyyy-MM-dd"), props.state.SearchDetails.FinishDate), "")], ["onInput", (e_1) => {
        let bind$0040_5, bind$0040_6;
        if (e_1.target.value === "") {
            props.dispatch(new Msg$2(0, [(bind$0040_5 = props.state.SearchDetails, new LeaveSearchDetails(bind$0040_5.SearchText, bind$0040_5.StartDate, undefined, bind$0040_5.Status, bind$0040_5.LeaveRequestType))]));
        }
        else {
            props.dispatch(new Msg$2(0, [(bind$0040_6 = props.state.SearchDetails, new LeaveSearchDetails(bind$0040_6.SearchText, bind$0040_6.StartDate, fromDate(System_DateTime__DateTime_ToLocalKind(parse(e_1.target.value))), bind$0040_6.Status, bind$0040_6.LeaveRequestType))]));
        }
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_24)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_14))])])))]);
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_26)),
    });
}

export function IndexView() {
    return createElement(IndexView_2, {
        entityName: "Leave Requests",
        entityTable: leavesTable,
        searchDetails: new LeaveSearchDetails("", undefined, undefined, undefined, undefined),
        searchFunc: uncurry2(searchFunc),
        searchPanel: searchPanel,
        xPadding: "",
        yPadding: "",
    });
}

