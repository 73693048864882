import { Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { JobShiftSchedule, JobShiftScheduleOverride_$reflection, JobShiftSchedule_$reflection } from "../../../../Logos.Shared/Domain/Entity/Job.fs.js";
import { option_type, union_type, bool_type, class_type, record_type, string_type, lambda_type, unit_type, list_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Pagination_$reflection } from "../../../../Logos.Shared/Domain/Entity/Pagination.fs.js";
import { Busy, Busy_$reflection } from "../../SharedView.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { ofArray, map, tryHead, empty, singleton as singleton_1 } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { getJobShiftScheduleOverrides } from "../../../AppDomain/UseCase/Job.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../Infrastructure/AppRoot.fs.js";
import { paginationControl, firstPage, nextPage, previousPage, getDefaultPagination } from "../../Component/Pagination.fs.js";
import { createObj, equals } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { TitleSize, viewBox } from "../../Component/ViewBox.fs.js";
import { Daisy_DialogWidth, Daisy_progressState, Daisy_error } from "../../Component/Component.fs.js";
import { createElement } from "react";
import React from "react";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { ButtonType, showModalEx } from "../../Component/ModalDialog.fs.js";
import { IndexView as IndexView_1 } from "./JobShiftScheduleOverrideEdit.fs.js";
import { defaultArg } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { empty as empty_1, singleton as singleton_2, map as map_1, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { DayOfWeek_toString, DateTime_dateToStringWithDayName } from "../../../AppDomain/Util.fs.js";
import { parse, toString } from "../../../fable_modules/fable-library-js.4.19.3/TimeOnly.js";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";

export class State extends Record {
    constructor(JobShiftSchedules, OnJobShiftSchedulesChange, JobShiftScheduleOverrides, NextPagination, CurrentPagination, Errors, IsBusy) {
        super();
        this.JobShiftSchedules = JobShiftSchedules;
        this.OnJobShiftSchedulesChange = OnJobShiftSchedulesChange;
        this.JobShiftScheduleOverrides = JobShiftScheduleOverrides;
        this.NextPagination = NextPagination;
        this.CurrentPagination = CurrentPagination;
        this.Errors = Errors;
        this.IsBusy = IsBusy;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.JobShiftSchedule.State", [], State, () => [["JobShiftSchedules", list_type(JobShiftSchedule_$reflection())], ["OnJobShiftSchedulesChange", lambda_type(list_type(JobShiftSchedule_$reflection()), unit_type)], ["JobShiftScheduleOverrides", list_type(JobShiftScheduleOverride_$reflection())], ["NextPagination", Pagination_$reflection()], ["CurrentPagination", Pagination_$reflection()], ["Errors", list_type(string_type)], ["IsBusy", Busy_$reflection()]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetShiftStartChanged", "SetShiftEndChanged", "SetEnabled", "Save", "SaveResponse", "RefreshJobShiftScheduleOverrides", "GetJobShiftScheduleOverridesResponse", "GotoPreviousPage", "GotoNextPage", "GotoFirstPage", "NoAction", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.JobShiftSchedule.Msg", [], Msg, () => [[["Item1", JobShiftSchedule_$reflection()], ["Item2", class_type("System.TimeOnly")]], [["Item1", JobShiftSchedule_$reflection()], ["Item2", class_type("System.TimeOnly")]], [["Item1", JobShiftSchedule_$reflection()], ["Item2", bool_type]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(JobShiftScheduleOverride_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", list_type(JobShiftScheduleOverride_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [], [], [], [["Item", option_type(JobShiftScheduleOverride_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

export function getJobShiftScheduleOverridesCmd(jobId, pagination) {
    return cmdOfAsync((Item) => (new Msg(6, [Item])), (Item_1) => (new Msg(11, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_1;
        let value_1;
        const option_1 = jobId;
        value_1 = ((option_1 == null) ? (new FSharpResult$2(1, [singleton_1("Job Id is required.")])) : (new FSharpResult$2(0, [option_1])));
        asyncResult_1 = singleton.Return(value_1);
        return singleton.Bind(asyncResult_1, (input_1) => {
            const input_2 = input_1;
            if (input_2.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
            }
            else {
                return getJobShiftScheduleOverrides(appRoot.JobService, AppRootModule_getSelectedOrgId(), input_2.fields[0], pagination);
            }
        });
    }));
}

export function init(jobShiftSchedules, onJobShiftSchedulesChange) {
    let input_1;
    const pagination = getDefaultPagination();
    return [new State(jobShiftSchedules, onJobShiftSchedulesChange, empty(), pagination, pagination, empty(), new Busy(1, [])), getJobShiftScheduleOverridesCmd((input_1 = tryHead(jobShiftSchedules), (input_1 == null) ? undefined : input_1.JobId), pagination)];
}

function changePage(state, f) {
    let input_1;
    const newPagination = f(state.CurrentPagination);
    if (equals(state.NextPagination, newPagination)) {
        return [state, Cmd_none()];
    }
    else {
        return [new State(state.JobShiftSchedules, state.OnJobShiftSchedulesChange, state.JobShiftScheduleOverrides, newPagination, state.CurrentPagination, state.Errors, new Busy(1, [])), getJobShiftScheduleOverridesCmd((input_1 = tryHead(state.JobShiftSchedules), (input_1 == null) ? undefined : input_1.Id), newPagination)];
    }
}

export function update(msg, state) {
    let input_1;
    switch (msg.tag) {
        case 1: {
            const updatedJobShiftSchedule_1 = new JobShiftSchedule(msg.fields[0].Id, msg.fields[0].JobId, msg.fields[0].DayOfWeek, msg.fields[0].ShiftStart, msg.fields[1], msg.fields[0].IsEnabled);
            const updatedJobShiftSchedules_1 = map((x_1) => {
                if (equals(x_1, msg.fields[0])) {
                    return updatedJobShiftSchedule_1;
                }
                else {
                    return x_1;
                }
            }, state.JobShiftSchedules);
            state.OnJobShiftSchedulesChange(updatedJobShiftSchedules_1);
            return [new State(updatedJobShiftSchedules_1, state.OnJobShiftSchedulesChange, state.JobShiftScheduleOverrides, state.NextPagination, state.CurrentPagination, state.Errors, state.IsBusy), Cmd_none()];
        }
        case 2: {
            const updatedJobShiftSchedule_2 = new JobShiftSchedule(msg.fields[0].Id, msg.fields[0].JobId, msg.fields[0].DayOfWeek, msg.fields[0].ShiftStart, msg.fields[0].ShiftEnd, msg.fields[1]);
            const updatedJobShiftSchedules_2 = map((x_2) => {
                if (equals(x_2, msg.fields[0])) {
                    return updatedJobShiftSchedule_2;
                }
                else {
                    return x_2;
                }
            }, state.JobShiftSchedules);
            state.OnJobShiftSchedulesChange(updatedJobShiftSchedules_2);
            return [new State(updatedJobShiftSchedules_2, state.OnJobShiftSchedulesChange, state.JobShiftScheduleOverrides, state.NextPagination, state.CurrentPagination, state.Errors, state.IsBusy), Cmd_none()];
        }
        case 3:
            return [state, Cmd_none()];
        case 4:
            if (msg.fields[0].tag === 1) {
                return [new State(state.JobShiftSchedules, state.OnJobShiftSchedulesChange, state.JobShiftScheduleOverrides, state.NextPagination, state.CurrentPagination, msg.fields[0].fields[0], state.IsBusy), Cmd_none()];
            }
            else {
                return [state, Cmd_none()];
            }
        case 7:
            return changePage(state, previousPage);
        case 8:
            return changePage(state, nextPage);
        case 9:
            return changePage(state, firstPage);
        case 6:
            if (msg.fields[0].tag === 1) {
                return [new State(state.JobShiftSchedules, state.OnJobShiftSchedulesChange, state.JobShiftScheduleOverrides, state.NextPagination, state.CurrentPagination, msg.fields[0].fields[0], new Busy(0, [])), Cmd_none()];
            }
            else {
                return [new State(state.JobShiftSchedules, state.OnJobShiftSchedulesChange, msg.fields[0].fields[0], state.NextPagination, state.CurrentPagination, state.Errors, new Busy(0, [])), Cmd_none()];
            }
        case 5:
            return [new State(state.JobShiftSchedules, state.OnJobShiftSchedulesChange, state.JobShiftScheduleOverrides, state.NextPagination, state.CurrentPagination, state.Errors, new Busy(1, [])), getJobShiftScheduleOverridesCmd((input_1 = tryHead(state.JobShiftSchedules), (input_1 == null) ? undefined : input_1.JobId), state.CurrentPagination)];
        case 10:
            return [state, Cmd_none()];
        case 11:
            return [new State(state.JobShiftSchedules, state.OnJobShiftSchedulesChange, state.JobShiftScheduleOverrides, state.NextPagination, state.CurrentPagination, singleton_1(msg.fields[0].message), state.IsBusy), Cmd_none()];
        default: {
            const updatedJobShiftSchedule = new JobShiftSchedule(msg.fields[0].Id, msg.fields[0].JobId, msg.fields[0].DayOfWeek, msg.fields[1], msg.fields[0].ShiftEnd, msg.fields[0].IsEnabled);
            const updatedJobShiftSchedules = map((x) => {
                if (equals(x, msg.fields[0])) {
                    return updatedJobShiftSchedule;
                }
                else {
                    return x;
                }
            }, state.JobShiftSchedules);
            state.OnJobShiftSchedulesChange(updatedJobShiftSchedules);
            return [new State(updatedJobShiftSchedules, state.OnJobShiftSchedulesChange, state.JobShiftScheduleOverrides, state.NextPagination, state.CurrentPagination, state.Errors, state.IsBusy), Cmd_none()];
        }
    }
}

export function shiftScheduleOverrides(state, dispatch) {
    let children, elems_1, elems_3, children_6, children_4, children_14;
    return viewBox("Shift Schedule Overrides", new TitleSize(0, []), ofArray([Daisy_error(state.Errors), (children = singleton_1(createElement(Daisy_progressState, {
        busy: state.IsBusy,
    })), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children)),
    })), createElement("div", createObj(Helpers_combineClasses("navbar", singleton_1((elems_1 = [createElement(showModalEx, {
        dialogId: "id-job-shift-schedule-override-add",
        header: "",
        buttonType: new ButtonType(0, ["Add Shift Schedule Override"]),
        width: new Daisy_DialogWidth(0, []),
        body: () => {
            let input_1;
            return createElement(IndexView_1, {
                JobId: defaultArg((input_1 = tryHead(state.JobShiftSchedules), (input_1 == null) ? undefined : input_1.JobId), "00000000-0000-0000-0000-000000000000"),
                OnRefresh: () => {
                    dispatch(new Msg(5, []));
                },
            });
        },
        onShow: () => {
            dispatch(new Msg(10, [undefined]));
        },
        onClose: () => {
            dispatch(new Msg(10, [undefined]));
        },
    })], ["children", reactApi.Children.toArray(Array.from(elems_1))]))))), createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["flex", "w-full", "ml-2", "mr-2", "mb-4"])], (elems_3 = [(children_6 = singleton_1((children_4 = ofArray([createElement("th", {
        width: 20 + "%",
        children: "Date",
    }), createElement("th", {
        width: 5 + "%",
        children: "Enabled",
    }), createElement("th", {
        width: 5 + "%",
        children: "Holiday",
    }), createElement("th", {
        width: 10 + "%",
        children: "Start",
    }), createElement("th", {
        width: 10 + "%",
        children: "Finish",
    }), createElement("th", {
        width: 30 + "%",
        children: "Reason",
    }), createElement("th", {
        width: 10 + "%",
        children: "",
    })]), createElement("tr", {
        children: reactApi.Children.toArray(Array.from(children_4)),
    }))), createElement("thead", {
        children: reactApi.Children.toArray(Array.from(children_6)),
    })), (children_14 = toList(delay(() => map_1((jobScheduleOverride) => {
        let elems_2, children_8, children_10, children_12;
        return createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top"])], (elems_2 = [createElement("td", {
            children: [DateTime_dateToStringWithDayName(jobScheduleOverride.DateTime)],
        }), (children_8 = singleton_1(createElement("input", {
            type: "checkbox",
            readOnly: true,
            checked: jobScheduleOverride.IsEnabled,
        })), createElement("td", {
            children: reactApi.Children.toArray(Array.from(children_8)),
        })), (children_10 = singleton_1(createElement("input", {
            type: "checkbox",
            readOnly: true,
            checked: jobScheduleOverride.IsHoliday,
        })), createElement("td", {
            children: reactApi.Children.toArray(Array.from(children_10)),
        })), createElement("td", createObj(toList(delay(() => (jobScheduleOverride.IsEnabled ? singleton_2(["children", toString(jobScheduleOverride.ShiftStart)]) : empty_1()))))), createElement("td", createObj(toList(delay(() => (jobScheduleOverride.IsEnabled ? singleton_2(["children", toString(jobScheduleOverride.ShiftEnd)]) : empty_1()))))), createElement("td", {
            children: jobScheduleOverride.Reason,
        }), (children_12 = toList(delay(() => {
            let input_3;
            const jobId_1 = defaultArg((input_3 = tryHead(state.JobShiftSchedules), (input_3 == null) ? undefined : input_3.JobId), "00000000-0000-0000-0000-000000000000");
            return singleton_2(createElement(showModalEx, {
                dialogId: "id-job-shift-schedule-override-add",
                header: "",
                buttonType: new ButtonType(1, ["fa-edit"]),
                width: new Daisy_DialogWidth(0, []),
                body: () => createElement(IndexView_1, {
                    JobId: jobId_1,
                    JobShiftScheduleOverride: jobScheduleOverride,
                    OnRefresh: () => {
                        dispatch(new Msg(5, []));
                    },
                }),
                onShow: () => {
                    dispatch(new Msg(10, [undefined]));
                },
                onClose: () => {
                    dispatch(new Msg(10, [undefined]));
                },
            }));
        })), createElement("td", {
            children: reactApi.Children.toArray(Array.from(children_12)),
        }))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])));
    }, state.JobShiftScheduleOverrides))), createElement("tbody", {
        children: reactApi.Children.toArray(Array.from(children_14)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))), paginationControl(false, state.CurrentPagination.CurrentPage, (_arg_8) => {
        dispatch(new Msg(7, []));
    }, (_arg_9) => {
        dispatch(new Msg(8, []));
    }, (_arg_10) => {
        dispatch(new Msg(9, []));
    })]));
}

export function IndexView(props) {
    let elems_1, children_2, children, children_10;
    let patternInput;
    const init_1 = init(props.JobShiftSchedules, props.OnJobShiftSchedulesChange);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const children_13 = ofArray([Daisy_error(state_1.Errors), createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["flex", "w-full", "ml-2", "mr-2"])], (elems_1 = [(children_2 = singleton_1((children = ofArray([createElement("th", {
        width: 15 + "%",
        children: "Day of week",
    }), createElement("th", {
        width: 5 + "%",
        children: "Enabled",
    }), createElement("th", {
        width: 40 + "%",
        children: "Start",
    }), createElement("th", {
        width: 40 + "%",
        children: "Finish",
    })]), createElement("tr", {
        children: reactApi.Children.toArray(Array.from(children)),
    }))), createElement("thead", {
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), (children_10 = toList(delay(() => map_1((jobSchedule) => {
        let elems, children_4, children_6, children_8;
        return createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top"])], (elems = [createElement("td", {
            children: [DayOfWeek_toString(jobSchedule.DayOfWeek)],
        }), (children_4 = singleton_1(createElement("input", {
            type: "checkbox",
            checked: jobSchedule.IsEnabled,
            onChange: (ev) => {
                let tupledArg;
                dispatch((tupledArg = [jobSchedule, ev.target.checked], new Msg(2, [tupledArg[0], tupledArg[1]])));
            },
        })), createElement("td", {
            children: reactApi.Children.toArray(Array.from(children_4)),
        })), (children_6 = toList(delay(() => (jobSchedule.IsEnabled ? singleton_2(createElement("input", {
            type: "time",
            value: toString(jobSchedule.ShiftStart),
            disabled: !jobSchedule.IsEnabled,
            onInput: (e_1) => {
                dispatch(new Msg(0, [jobSchedule, parse(e_1.target.value)]));
            },
        })) : empty_1()))), createElement("td", {
            children: reactApi.Children.toArray(Array.from(children_6)),
        })), (children_8 = toList(delay(() => (jobSchedule.IsEnabled ? singleton_2(createElement("input", {
            type: "time",
            value: toString(jobSchedule.ShiftEnd),
            disabled: !jobSchedule.IsEnabled,
            onInput: (e_2) => {
                dispatch(new Msg(1, [jobSchedule, parse(e_2.target.value)]));
            },
        })) : empty_1()))), createElement("td", {
            children: reactApi.Children.toArray(Array.from(children_8)),
        }))], ["children", reactApi.Children.toArray(Array.from(elems))])])));
    }, state_1.JobShiftSchedules))), createElement("tbody", {
        children: reactApi.Children.toArray(Array.from(children_10)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))), shiftScheduleOverrides(state_1, dispatch)]);
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_13)),
    });
}

