import { singleton } from "../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library-js.4.19.3/Result.js";
import { map } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { XeroCustomerDtoModule_toXeroCustomer } from "../../../Logos.Shared/Domain/Dto/Customer.fs.js";

export function saveXeroCode(xeroService, selectedOrgId, code) {
    return singleton.Delay(() => xeroService.SaveXeroCode(selectedOrgId, code));
}

export function getXeroCustomers(xeroService, selectedOrgId) {
    return singleton.Delay(() => {
        const input = xeroService.GetXeroCustomers(selectedOrgId);
        return singleton.Bind(input, (x$0027) => {
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [map(XeroCustomerDtoModule_toXeroCustomer, input_2.fields[0])])));
            return singleton.Return(value);
        });
    });
}

export function saveXeroSettings(xeroService, selectedOrgId, invoiceItemAccountCode, invoiceItemTaxType) {
    return singleton.Delay(() => xeroService.SaveXeroSettings(selectedOrgId, invoiceItemAccountCode, invoiceItemTaxType));
}

