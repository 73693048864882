import { Union, Record } from "../../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { TimesheetReportByWorker, TimesheetReportByWorker_$reflection } from "../../../../../Logos.Shared/Domain/Entity/Shift.fs.js";
import { union_type, record_type, string_type, class_type, list_type } from "../../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Busy, Busy_$reflection } from "../../../SharedView.fs.js";
import { DataFormat, System_TimeSpan__TimeSpan_ToDisplayDurationInHoursAndMinutes, DataFormatPayload$1_$reflection, DataFormat_$reflection } from "../../../../../Logos.Shared/Util.fs.js";
import { TimesheetReportByWorkerDtoModule_toTimesheetReportByWorker, TimesheetReportByWorkerDto_$reflection } from "../../../../../Logos.Shared/Domain/Dto/Shift.fs.js";
import { FSharpResult$2 } from "../../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { getDefaultConfig, DateRange, DateRange_$reflection } from "../../../Component/ReactTailwindDatePicker.fs.js";
import { cmdOfAsync } from "../../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { parse, dayOfWeek, addDays, now as now_1, date, toUniversalTime } from "../../../../fable_modules/fable-library-js.4.19.3/Date.js";
import { getTimesheetReportByWorkers } from "../../../../AppDomain/UseCase/Timesheet.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../../Infrastructure/AppRoot.fs.js";
import { fold, mapIndexed, ofArray, singleton as singleton_1, map, empty } from "../../../../fable_modules/fable-library-js.4.19.3/List.js";
import { Cmd_none } from "../../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { HttpUtil_saveByteArray } from "../../../../AppDomain/Util.fs.js";
import { get_UTF8 } from "../../../../fable_modules/fable-library-js.4.19.3/Encoding.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { stringHash, createObj } from "../../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { join } from "../../../../fable_modules/fable-library-js.4.19.3/String.js";
import { Daisy_progressState, Daisy_error, Daisy_h4, alignCellTextTop } from "../../../Component/Component.fs.js";
import { reactApi } from "../../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { singleton as singleton_2, append, collect, delay, toList } from "../../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { List_groupBy } from "../../../../fable_modules/fable-library-js.4.19.3/Seq2.js";
import { React_useElmish_Z6C327F2E } from "../../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../../../Component/ViewContainer.fs.js";
import react_tailwindcss_datepicker from "react-tailwindcss-datepicker";

export class State extends Record {
    constructor(TimesheetsByWorker, DateFrom, DateTo, IsBusy, Errors) {
        super();
        this.TimesheetsByWorker = TimesheetsByWorker;
        this.DateFrom = DateFrom;
        this.DateTo = DateTo;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.WorkMate.Report.TimesheetReportByWorker.State", [], State, () => [["TimesheetsByWorker", list_type(TimesheetReportByWorker_$reflection())], ["DateFrom", class_type("System.DateTime")], ["DateTo", class_type("System.DateTime")], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetTimesheets", "GetTimesheetsResponse", "SetDateRange", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.WorkMate.Report.TimesheetReportByWorker.Msg", [], Msg, () => [[["Item", DataFormat_$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [DataFormatPayload$1_$reflection(list_type(TimesheetReportByWorkerDto_$reflection())), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", DataFormatPayload$1_$reflection(list_type(TimesheetReportByWorkerDto_$reflection()))]], [["ErrorValue", list_type(string_type)]]])]], [["Item", DateRange_$reflection()]], [["Item", class_type("System.Exception")]]]);
}

export function getTimesheetsCmd(responseDataFormat, dateFrom, dateTo) {
    return cmdOfAsync((Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(3, [Item_1])), () => singleton.Delay(() => {
        const dateFrom_1 = toUniversalTime(date(dateFrom));
        const dateTo_1 = toUniversalTime(date(dateTo));
        return getTimesheetReportByWorkers(appRoot.ShiftService, AppRootModule_getSelectedOrgId(), responseDataFormat, dateFrom_1, dateTo_1);
    }));
}

export function init() {
    const now = now_1();
    const weekStart = addDays(now, -dayOfWeek(now));
    return [new State(empty(), weekStart, addDays(weekStart, 6), new Busy(0, []), empty()), Cmd_none()];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            if (msg.fields[0].tag === 1) {
                return [new State(state.TimesheetsByWorker, state.DateFrom, state.DateTo, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                switch (msg.fields[0].fields[0].tag) {
                    case 1: {
                        HttpUtil_saveByteArray(get_UTF8().getBytes(msg.fields[0].fields[0].fields[0]), "JobDurationByWorkersReport.csv", "text/csv");
                        return [new State(state.TimesheetsByWorker, state.DateFrom, state.DateTo, new Busy(0, []), state.Errors), Cmd_none()];
                    }
                    case 2: {
                        HttpUtil_saveByteArray(msg.fields[0].fields[0].fields[0], "JobDurationByWorkersReport.pdf", "application/pdf");
                        return [new State(state.TimesheetsByWorker, state.DateFrom, state.DateTo, new Busy(0, []), state.Errors), Cmd_none()];
                    }
                    default:
                        return [new State(map(TimesheetReportByWorkerDtoModule_toTimesheetReportByWorker, msg.fields[0].fields[0].fields[0]), state.DateFrom, state.DateTo, new Busy(0, []), state.Errors), Cmd_none()];
                }
            }
        case 2:
            return [new State(state.TimesheetsByWorker, msg.fields[0].startDate, msg.fields[0].endDate, state.IsBusy, state.Errors), Cmd_none()];
        case 3:
            return [new State(state.TimesheetsByWorker, state.DateFrom, state.DateTo, new Busy(0, []), singleton_1(msg.fields[0].message)), Cmd_none()];
        default:
            return [new State(state.TimesheetsByWorker, state.DateFrom, state.DateTo, new Busy(1, []), state.Errors), getTimesheetsCmd(msg.fields[0], state.DateFrom, state.DateTo)];
    }
}

export function jobSummaryTable(state) {
    let elems_1, children_2, children, elems;
    return createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-xs"], ["className", "table-zebra"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["w-full"])], (elems_1 = [(children_2 = singleton_1((children = ofArray([createElement("th", createObj(ofArray([alignCellTextTop, ["children", "Job"]]))), createElement("th", createObj(ofArray([alignCellTextTop, ["children", "Worker"]]))), createElement("th", createObj(ofArray([alignCellTextTop, ["children", "Total work duration"]]))), createElement("th", createObj(ofArray([alignCellTextTop, ["children", "Total overtime duration"]])))]), createElement("tr", {
        children: reactApi.Children.toArray(Array.from(children)),
    }))), createElement("thead", {
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), createElement("tbody", createObj(singleton_1((elems = toList(delay(() => collect((matchValue) => {
        const jobTimesheets = matchValue[1];
        return append(collect((matchValue_1) => {
            let children_4;
            const timesheet = matchValue_1[1];
            return singleton_2((children_4 = ofArray([createElement("td", {
                className: join(" ", ["font-bold"]),
                children: (matchValue_1[0] === 0) ? matchValue[0] : "",
            }), createElement("td", {
                children: timesheet.WorkerName,
            }), createElement("td", {
                children: System_TimeSpan__TimeSpan_ToDisplayDurationInHoursAndMinutes(timesheet.TotalWorkDuration),
            }), createElement("td", {
                children: System_TimeSpan__TimeSpan_ToDisplayDurationInHoursAndMinutes(timesheet.TotalOvertimeDuration),
            })]), createElement("tr", {
                children: reactApi.Children.toArray(Array.from(children_4)),
            })));
        }, mapIndexed((i_1, x) => [i_1, new TimesheetReportByWorker("", x.WorkerName, x.TotalWorkDuration, x.TotalOvertimeDuration)], jobTimesheets)), delay(() => {
            let children_6;
            const workTotal = fold((x_2, y) => (x_2 + y), 0, map((x_1) => x_1.TotalWorkDuration, jobTimesheets));
            const overtimeTotal = fold((x_4, y_1) => (x_4 + y_1), 0, map((x_3) => x_3.TotalOvertimeDuration, jobTimesheets));
            return singleton_2((children_6 = ofArray([createElement("td", {}), createElement("td", {
                className: join(" ", ["font-bold"]),
                children: "Total",
            }), createElement("td", {
                className: join(" ", ["font-bold"]),
                children: System_TimeSpan__TimeSpan_ToDisplayDurationInHoursAndMinutes(workTotal),
            }), createElement("td", {
                className: join(" ", ["font-bold"]),
                children: System_TimeSpan__TimeSpan_ToDisplayDurationInHoursAndMinutes(overtimeTotal),
            })]), createElement("tr", {
                children: reactApi.Children.toArray(Array.from(children_6)),
            })));
        }));
    }, List_groupBy((x_5) => x_5.JobName, state.TimesheetsByWorker, {
        Equals: (x_6, y_2) => (x_6 === y_2),
        GetHashCode: stringHash,
    })))), ["children", reactApi.Children.toArray(Array.from(elems))]))))], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))));
}

export function IndexView() {
    let children, children_2, children_8, children_5, props_5, elems_3;
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return container([(children = singleton_1(Daisy_h4("Job duration by workers report")), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    })), Daisy_error(state_1.Errors), (children_2 = singleton_1(createElement(Daisy_progressState, {
        busy: state_1.IsBusy,
    })), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), (children_8 = ofArray([(children_5 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Timesheets run date range",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_5)),
    })), (props_5 = {
        value: new DateRange(state_1.DateFrom, state_1.DateTo),
        onChange: (x) => {
            dispatch(new Msg(2, [new DateRange(parse(x.startDate), parse(x.endDate))]));
        },
        showShortcuts: true,
        configs: getDefaultConfig(now_1()),
    }, react.createElement(react_tailwindcss_datepicker, props_5))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_8)),
    })), createElement("div", createObj(ofArray([["className", join(" ", ["flex", "flex-row", "flex-wrap", "gap-x-2"])], (elems_3 = [createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-sm"], ["className", "btn-primary"], ["children", "Refresh"], ["onClick", (_arg_2) => {
        dispatch(new Msg(0, [new DataFormat(0, [])]));
    }]])))), createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-sm"], ["className", "btn-primary"], ["children", "Export CSV"], ["onClick", (_arg_3) => {
        dispatch(new Msg(0, [new DataFormat(1, [])]));
    }]]))))], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))), jobSummaryTable(state_1)]);
}

