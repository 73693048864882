import { Record } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { anonRecord_type, record_type, bool_type, decimal_type, string_type, class_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";

export class Address extends Record {
    constructor(Id, ParentId, UnitNumber, Street, Suburb, City, Country, PostCode, Latitude, Longitude, IsActive) {
        super();
        this.Id = Id;
        this.ParentId = ParentId;
        this.UnitNumber = UnitNumber;
        this.Street = Street;
        this.Suburb = Suburb;
        this.City = City;
        this.Country = Country;
        this.PostCode = PostCode;
        this.Latitude = Latitude;
        this.Longitude = Longitude;
        this.IsActive = IsActive;
    }
}

export function Address_$reflection() {
    return record_type("Domain.Entity.Address.Address", [], Address, () => [["Id", class_type("System.Guid")], ["ParentId", class_type("System.Guid")], ["UnitNumber", string_type], ["Street", string_type], ["Suburb", string_type], ["City", string_type], ["Country", string_type], ["PostCode", string_type], ["Latitude", decimal_type], ["Longitude", decimal_type], ["IsActive", bool_type]]);
}

export class GeoCode extends Record {
    constructor(Latitude, Longitude, Address) {
        super();
        this.Latitude = Latitude;
        this.Longitude = Longitude;
        this.Address = Address;
    }
}

export function GeoCode_$reflection() {
    return record_type("Domain.Entity.Address.GeoCode", [], GeoCode, () => [["Latitude", decimal_type], ["Longitude", decimal_type], ["Address", anonRecord_type(["City", string_type], ["Country", string_type], ["PostCode", string_type], ["Street", string_type], ["Suburb", string_type], ["UnitNumber", string_type])]]);
}

export function AddressModule_toAddressString(address) {
    return `${address.UnitNumber} ${address.Street} ${address.Suburb} ${address.City} ${address.Country} ${address.PostCode} `;
}

export function AddressModule_ofGeoCode(id, parentId, geoCode) {
    const address = geoCode.Address;
    return new Address(id, parentId, address.UnitNumber, address.Street, address.Suburb, address.City, address.Country, address.PostCode, geoCode.Latitude, geoCode.Longitude, true);
}

