import { singleton } from "../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { SubscriptionDto } from "../../../Logos.Shared/Domain/Entity/Stripe.fs.js";
import { singleton as singleton_1, map } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { SubscriptionUserAllocationDtoModule_ofSubscriptionUserAllocation } from "../../../Logos.Shared/Domain/Dto/Product.fs.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library-js.4.19.3/Result.js";
import { parse } from "../../fable_modules/fable-library-js.4.19.3/Int32.js";

export function getStripeKey(stripeService) {
    return singleton.Delay(() => stripeService.GetStripeKey());
}

export function createInactiveSubscription(stripeService, selectedOrgId, productId, priceId, subscriptionCount) {
    return singleton.Delay(() => {
        const subscription_1 = new SubscriptionDto(productId, priceId, subscriptionCount);
        return stripeService.CreateInactiveSubscription(selectedOrgId, subscription_1);
    });
}

export function isProductSubscribed(stripeService, selectedOrgId, product) {
    return singleton.Delay(() => stripeService.GetProductSubscriptionDetails(selectedOrgId, product));
}

export function cancelSubscription(stripeService, selectedOrgId, product, subscriptionId) {
    return singleton.Delay(() => stripeService.CancelSubscription(selectedOrgId, product, subscriptionId));
}

export function getSubscriptionUserAllocations(stripeService, selectedOrgId, product, searchText, pagination) {
    return singleton.Delay(() => stripeService.GetSubscriptionUserAllocations(selectedOrgId, product, searchText, pagination));
}

export function allocateUserToSubscription(stripeService, selectedOrgId, subscriptionsToAllocate) {
    return singleton.Delay(() => {
        const subscriptionsToAllocate_2 = map(SubscriptionUserAllocationDtoModule_ofSubscriptionUserAllocation, subscriptionsToAllocate);
        return stripeService.AllocateUserToSubscription(selectedOrgId, subscriptionsToAllocate_2);
    });
}

export function removeUserSubscription(stripeService, selectedOrgId, subscriptionAllocationId, subscriptionId, userId) {
    return singleton.Delay(() => stripeService.RemoveUserSubscription(selectedOrgId, subscriptionAllocationId, subscriptionId, userId));
}

export function changeSubscriptionQty(stripeService, selectedOrgId, product, subscriptionId, oldSubscriptionCount, newSubscriptionCount) {
    return singleton.Delay(() => {
        let asyncResult_2;
        let value_1;
        const option_1 = subscriptionId;
        value_1 = ((option_1 == null) ? (new FSharpResult$2(1, [singleton_1("SubscriptionId is required.")])) : (new FSharpResult$2(0, [option_1])));
        asyncResult_2 = singleton.Return(value_1);
        return singleton.Bind(asyncResult_2, (input_1_1) => {
            const input_5 = input_1_1;
            if (input_5.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_5.fields[0]]));
            }
            else {
                let asyncResult_1;
                let value_1_1;
                const option_3 = newSubscriptionCount;
                value_1_1 = ((option_3 == null) ? (new FSharpResult$2(1, [singleton_1("NewSubscriptionCount is required.")])) : (new FSharpResult$2(0, [option_3])));
                asyncResult_1 = singleton.Return(value_1_1);
                return singleton.Bind(asyncResult_1, (input_1) => {
                    const input_2 = input_1;
                    if (input_2.tag === 1) {
                        return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                    }
                    else {
                        const newSubscriptionCount_3 = parse(input_2.fields[0], 511, false, 32) | 0;
                        return stripeService.ChangeSubscriptionQty(selectedOrgId, product, input_5.fields[0], oldSubscriptionCount, newSubscriptionCount_3);
                    }
                });
            }
        });
    });
}

