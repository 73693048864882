import { appRoot, AppRootModule_getSelectedOrgId } from "../../../Infrastructure/AppRoot.fs.js";
import { searchCustomer } from "../../../AppDomain/UseCase/Customer.fs.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { IndexView as IndexView_1 } from "./CustomerEdit.fs.js";
import { map, singleton, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { uncurry2, createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { iconButton, showModalDialog, ButtonType, showModalEx } from "../../Component/ModalDialog.fs.js";
import { alignIconCenter, Daisy_DialogWidth } from "../../Component/Component.fs.js";
import { IndexView as IndexView_2, Msg$1 } from "../BaseSearch.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { map as map_1, fold, ofArray, singleton as singleton_1 } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { Option_toString } from "../../../../Logos.Shared/Util.fs.js";
import { AddressDtoModule_toAddressString } from "../../../../Logos.Shared/Domain/Dto/Address.fs.js";
import { defaultArg } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { ContactDtoModule_toContactTypeAndValue } from "../../../../Logos.Shared/Domain/Dto/Customer.fs.js";

export function searchFunc() {
    const selectedOrgId = AppRootModule_getSelectedOrgId();
    return (searchText) => ((pagination) => searchCustomer(appRoot.CustomerService, selectedOrgId, searchText, pagination));
}

export function customerTable(props) {
    const body = () => {
        const matchValue = props.state.EntityToEdit;
        if (matchValue == null) {
            return createElement(IndexView_1, {
                customerId: "",
            });
        }
        else {
            const x = matchValue;
            return createElement(IndexView_1, {
                customerId: x,
            });
        }
    };
    const children_10 = toList(delay(() => {
        let elems;
        return append(singleton(createElement("div", createObj(Helpers_combineClasses("navbar", singleton_1((elems = [createElement(showModalEx, {
            dialogId: "id-customer-search-add-user",
            header: "",
            buttonType: new ButtonType(0, ["Add Customer"]),
            width: new Daisy_DialogWidth(3, []),
            body: body,
            onShow: () => {
                props.dispatch(new Msg$1(4, [undefined]));
            },
            onClose: () => {
                props.dispatch(new Msg$1(4, [undefined]));
            },
        })], ["children", reactApi.Children.toArray(Array.from(elems))])))))), delay(() => {
            let matchValue_1, x_1;
            const td = (text, id) => createElement("td", {
                children: text,
                style: {
                    cursor: "pointer",
                },
                onClick: (_arg_2) => {
                    props.dispatch(new Msg$1(4, [id]));
                },
            });
            return append((matchValue_1 = props.state.EntityToEdit, (matchValue_1 == null) ? singleton(react.createElement(react.Fragment, {})) : ((x_1 = matchValue_1, singleton(createElement(showModalDialog, {
                dialogId: x_1,
                header: "",
                width: new Daisy_DialogWidth(3, []),
                body: body,
                onShow: () => {
                    props.dispatch(new Msg$1(4, [x_1]));
                },
                onClose: () => {
                    props.dispatch(new Msg$1(4, [undefined]));
                },
            }))))), delay(() => {
                let elems_4, children_3, children_1, children_7;
                return singleton(createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["flex", "w-full"])], (elems_4 = [(children_3 = singleton_1((children_1 = ofArray([createElement("th", {
                    width: 30 + "%",
                    children: "Business Name",
                }), createElement("th", {
                    width: 30 + "%",
                    children: "Address",
                }), createElement("th", {
                    width: 10 + "%",
                    children: "First name",
                }), createElement("th", {
                    width: 10 + "%",
                    children: "Middle name",
                }), createElement("th", {
                    width: 10 + "%",
                    children: "Last name",
                }), createElement("th", {
                    width: 15 + "%",
                    children: "Contact",
                }), createElement("th", {
                    width: 30 + "%",
                    children: "",
                })]), createElement("tr", {
                    children: reactApi.Children.toArray(Array.from(children_1)),
                }))), createElement("thead", {
                    children: reactApi.Children.toArray(Array.from(children_3)),
                })), (children_7 = toList(delay(() => map((customer) => {
                    let elems_3, elems_1, children_5, elems_2;
                    return createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top", "hover"])], (elems_3 = [td(Option_toString(customer.BusinessName), customer.Id), td(fold((acc, x_2) => {
                        if (acc === "") {
                            return AddressDtoModule_toAddressString(x_2);
                        }
                        else {
                            return acc + AddressDtoModule_toAddressString(x_2);
                        }
                    }, "", customer.Addresses), customer.Id), td(customer.FirstName, customer.Id), td(defaultArg(customer.MiddleName, ""), customer.Id), td(customer.LastName, customer.Id), createElement("td", createObj(ofArray([["onClick", (_arg_5) => {
                        props.dispatch(new Msg$1(4, [customer.Id]));
                    }], (elems_1 = map_1((x_3) => createElement("p", {
                        children: ContactDtoModule_toContactTypeAndValue(x_3),
                    }), customer.Contacts), ["children", reactApi.Children.toArray(Array.from(elems_1))])]))), (children_5 = singleton_1(createElement("div", createObj(ofArray([alignIconCenter, (elems_2 = [iconButton("fas fa-edit", (_arg_6) => {
                        props.dispatch(new Msg$1(4, [customer.Id]));
                    })], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))), createElement("td", {
                        children: reactApi.Children.toArray(Array.from(children_5)),
                    }))], ["children", reactApi.Children.toArray(Array.from(elems_3))])])));
                }, props.state.Entities))), createElement("tbody", {
                    children: reactApi.Children.toArray(Array.from(children_7)),
                }))], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))));
            }));
        }));
    }));
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_10)),
    });
}

export function IndexView() {
    return createElement(IndexView_2, {
        entityName: "Customer",
        entityTable: customerTable,
        searchFunc: uncurry2(searchFunc()),
    });
}

