import { Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { class_type, union_type, unit_type, record_type, list_type, string_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { saveXeroCode } from "../../../AppDomain/UseCase/Xero.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../Infrastructure/AppRoot.fs.js";
import { ofArray, empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { Page, Cmd_navigateToPage } from "../../Router.fs.js";
import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../../Component/ViewContainer.fs.js";
import { singleton as singleton_1, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { Daisy_h5, Daisy_h4 } from "../../Component/Component.fs.js";
import { createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";

export class State extends Record {
    constructor(Errors) {
        super();
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.Xero.LinkToXero.State", [], State, () => [["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["LinkToXero", "SaveCode", "SaveCodeResult", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.Xero.LinkToXero.Msg", [], Msg, () => [[], [["Item", string_type]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Exception")]]]);
}

export function storeCodeCmd(code) {
    return cmdOfAsync((Item) => (new Msg(2, [Item])), (Item_1) => (new Msg(3, [Item_1])), () => singleton.Delay(() => saveXeroCode(appRoot.XeroService, AppRootModule_getSelectedOrgId(), code)));
}

export function init() {
    return [new State(empty()), Cmd_none()];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [state, storeCodeCmd(msg.fields[0])];
        case 2:
            if (msg.fields[0].tag === 1) {
                return [new State(msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [state, Cmd_navigateToPage(new Page(33, []))];
            }
        case 3:
            return [state, Cmd_none()];
        default:
            return [state, Cmd_none()];
    }
}

export function IndexView(props) {
    let children_1;
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init, update, (_arg, _arg_1) => {
    }), undefined, []);
    return container([(children_1 = toList(delay(() => append(singleton_1(Daisy_h4("Received linking details from Xero")), delay(() => ((props.State === AppRootModule_getSelectedOrgId()) ? singleton_1(createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-xs"], ["children", "Finish Linking"], ["onClick", (_arg_2) => {
        patternInput[1](new Msg(1, [props.Code]));
    }]]))))) : singleton_1(Daisy_h5("Failed to link to Xero"))))))), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_1)),
    }))]);
}

