import { Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type, bool_type, record_type, list_type, string_type, lambda_type, unit_type, class_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { JobShiftScheduleOverride as JobShiftScheduleOverride_7, JobShiftScheduleOverride_$reflection } from "../../../../Logos.Shared/Domain/Entity/Job.fs.js";
import { BusyModule_isSaving, Busy, Busy_$reflection } from "../../SharedView.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { ofArray, empty, singleton as singleton_1 } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { upsertJobShiftScheduleOverride } from "../../../AppDomain/UseCase/Job.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../Infrastructure/AppRoot.fs.js";
import { defaultArg } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { parse as parse_1, specifyKind, toString, now } from "../../../fable_modules/fable-library-js.4.19.3/Date.js";
import { toString as toString_1, parse, create } from "../../../fable_modules/fable-library-js.4.19.3/TimeOnly.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../../Component/ViewContainer.fs.js";
import { Daisy_labelWithTooltip, Daisy_progressState, Daisy_error, Daisy_h4 } from "../../Component/Component.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { empty as empty_1, singleton as singleton_2, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";

export class State extends Record {
    constructor(JobId, JobShiftScheduleOverride, OnRefresh, IsBusy, Errors) {
        super();
        this.JobId = JobId;
        this.JobShiftScheduleOverride = JobShiftScheduleOverride;
        this.OnRefresh = OnRefresh;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.JobShiftScheduleOverrideEdit.State", [], State, () => [["JobId", class_type("System.Guid")], ["JobShiftScheduleOverride", JobShiftScheduleOverride_$reflection()], ["OnRefresh", lambda_type(unit_type, unit_type)], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetDateTime", "SetShiftStart", "SetShiftEnd", "SetIsEnabled", "SetIsHoliday", "SetReason", "SaveShiftScheduleOverride", "SaveShiftScheduleOverrideResponse", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.JobShiftScheduleOverrideEdit.Msg", [], Msg, () => [[["Item", class_type("System.DateTime")]], [["Item", string_type]], [["Item", string_type]], [["Item", bool_type]], [["Item", bool_type]], [["Item", string_type]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [class_type("System.Guid"), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", class_type("System.Guid")]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Exception")]]]);
}

export function saveShiftScheduleOverride(jobShiftScheduleOverride) {
    return cmdOfAsync((Item) => (new Msg(7, [Item])), (Item_1) => (new Msg(8, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_1;
        const value_1_1 = !(jobShiftScheduleOverride.JobId === "00000000-0000-0000-0000-000000000000") ? (new FSharpResult$2(0, [undefined])) : (new FSharpResult$2(1, [singleton_1("JobId is required.")]));
        asyncResult_1 = singleton.Return(value_1_1);
        return singleton.Bind(asyncResult_1, (input_1) => {
            const input_2 = input_1;
            if (input_2.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
            }
            else {
                return upsertJobShiftScheduleOverride(appRoot.JobService, AppRootModule_getSelectedOrgId(), jobShiftScheduleOverride);
            }
        });
    }));
}

export function init(jobId, jobShiftScheduleOverride, onRefresh) {
    return [new State(jobId, defaultArg(jobShiftScheduleOverride, new JobShiftScheduleOverride_7("00000000-0000-0000-0000-000000000000", jobId, false, false, now(), create(8, 0), create(16, 30), "")), onRefresh, new Busy(0, []), empty()), Cmd_none()];
}

export function update(msg, state) {
    let bind$0040_1, bind$0040_2, bind$0040_3, bind$0040_4, bind$0040_5, bind$0040_6, bind$0040;
    switch (msg.tag) {
        case 1:
            return [new State(state.JobId, (bind$0040_1 = state.JobShiftScheduleOverride, new JobShiftScheduleOverride_7(bind$0040_1.Id, bind$0040_1.JobId, bind$0040_1.IsEnabled, bind$0040_1.IsHoliday, bind$0040_1.DateTime, parse(msg.fields[0]), bind$0040_1.ShiftEnd, bind$0040_1.Reason)), state.OnRefresh, state.IsBusy, state.Errors), Cmd_none()];
        case 2:
            return [new State(state.JobId, (bind$0040_2 = state.JobShiftScheduleOverride, new JobShiftScheduleOverride_7(bind$0040_2.Id, bind$0040_2.JobId, bind$0040_2.IsEnabled, bind$0040_2.IsHoliday, bind$0040_2.DateTime, bind$0040_2.ShiftStart, parse(msg.fields[0]), bind$0040_2.Reason)), state.OnRefresh, state.IsBusy, state.Errors), Cmd_none()];
        case 3:
            return [new State(state.JobId, (bind$0040_3 = state.JobShiftScheduleOverride, new JobShiftScheduleOverride_7(bind$0040_3.Id, bind$0040_3.JobId, msg.fields[0], bind$0040_3.IsHoliday, bind$0040_3.DateTime, bind$0040_3.ShiftStart, bind$0040_3.ShiftEnd, bind$0040_3.Reason)), state.OnRefresh, state.IsBusy, state.Errors), Cmd_none()];
        case 4:
            return [new State(state.JobId, (bind$0040_4 = state.JobShiftScheduleOverride, new JobShiftScheduleOverride_7(bind$0040_4.Id, bind$0040_4.JobId, bind$0040_4.IsEnabled, msg.fields[0], bind$0040_4.DateTime, bind$0040_4.ShiftStart, bind$0040_4.ShiftEnd, bind$0040_4.Reason)), state.OnRefresh, state.IsBusy, state.Errors), Cmd_none()];
        case 5:
            return [new State(state.JobId, (bind$0040_5 = state.JobShiftScheduleOverride, new JobShiftScheduleOverride_7(bind$0040_5.Id, bind$0040_5.JobId, bind$0040_5.IsEnabled, bind$0040_5.IsHoliday, bind$0040_5.DateTime, bind$0040_5.ShiftStart, bind$0040_5.ShiftEnd, msg.fields[0])), state.OnRefresh, state.IsBusy, state.Errors), Cmd_none()];
        case 6:
            return [state, saveShiftScheduleOverride(state.JobShiftScheduleOverride)];
        case 7:
            if (msg.fields[0].tag === 1) {
                return [new State(state.JobId, state.JobShiftScheduleOverride, state.OnRefresh, state.IsBusy, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                state.OnRefresh();
                return [new State(state.JobId, (bind$0040_6 = state.JobShiftScheduleOverride, new JobShiftScheduleOverride_7(msg.fields[0].fields[0], bind$0040_6.JobId, bind$0040_6.IsEnabled, bind$0040_6.IsHoliday, bind$0040_6.DateTime, bind$0040_6.ShiftStart, bind$0040_6.ShiftEnd, bind$0040_6.Reason)), state.OnRefresh, state.IsBusy, state.Errors), Cmd_none()];
            }
        case 8:
            return [new State(state.JobId, state.JobShiftScheduleOverride, state.OnRefresh, state.IsBusy, singleton_1(msg.fields[0].message)), Cmd_none()];
        default:
            return [new State(state.JobId, (bind$0040 = state.JobShiftScheduleOverride, new JobShiftScheduleOverride_7(bind$0040.Id, bind$0040.JobId, bind$0040.IsEnabled, bind$0040.IsHoliday, msg.fields[0], bind$0040.ShiftStart, bind$0040.ShiftEnd, bind$0040.Reason)), state.OnRefresh, state.IsBusy, state.Errors), Cmd_none()];
    }
}

export function IndexView(props) {
    let children, children_2, children_6, value_27, children_9, children_12, children_15, children_17, children_21;
    let patternInput;
    const init_1 = init(props.JobId, props.JobShiftScheduleOverride, props.OnRefresh);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const isNew = props.JobShiftScheduleOverride == null;
    return container([(children = singleton_1(Daisy_h4("Job Shift Schedule Override")), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    })), Daisy_error(state_1.Errors), (children_2 = singleton_1(createElement(Daisy_progressState, {
        busy: state_1.IsBusy,
    })), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), createElement("button", createObj(Helpers_combineClasses("btn", toList(delay(() => append(BusyModule_isSaving(state_1.IsBusy) ? singleton_2(["className", "loading"]) : empty_1(), delay(() => append(singleton_2(["className", join(" ", ["my-4"])]), delay(() => append(singleton_2(["className", "btn-primary"]), delay(() => append(singleton_2(["className", "btn-sm"]), delay(() => append(singleton_2(["className", join(" ", ["mr-4"])]), delay(() => append(singleton_2(["children", "Save"]), delay(() => singleton_2(["onClick", (_arg_3) => {
        dispatch(new Msg(6, []));
    }])))))))))))))))))), (children_6 = ofArray([createElement("label", createObj(Helpers_combineClasses("label", singleton_1(["children", "Date"])))), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["type", "date"], ["className", "input-bordered"], ["className", "input-sm"], ["min", toString(now(), "yyyy-MM-dd")], ["disabled", !isNew], (value_27 = state_1.JobShiftScheduleOverride.DateTime, ["value", toString(value_27, "yyyy-MM-dd")]), ["onInput", (e) => {
        dispatch(new Msg(0, [specifyKind(parse_1(e.target.value), 2)]));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_6)),
    })), (children_9 = ofArray([createElement("label", createObj(Helpers_combineClasses("label", singleton_1(["children", "Shift Start"])))), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["type", "time"], ["className", "input-bordered"], ["className", "input-sm"], ["disabled", state_1.JobShiftScheduleOverride.IsHoliday], ["value", toString_1(state_1.JobShiftScheduleOverride.ShiftStart)], ["onInput", (e_1) => {
        dispatch(new Msg(1, [e_1.target.value]));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_9)),
    })), (children_12 = ofArray([createElement("label", createObj(Helpers_combineClasses("label", singleton_1(["children", "Shift End"])))), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["type", "time"], ["className", "input-bordered"], ["className", "input-sm"], ["disabled", state_1.JobShiftScheduleOverride.IsHoliday], ["value", toString_1(state_1.JobShiftScheduleOverride.ShiftEnd)], ["onInput", (e_2) => {
        dispatch(new Msg(2, [e_2.target.value]));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_12)),
    })), (children_15 = ofArray([createElement("label", createObj(Helpers_combineClasses("label", singleton_1(["children", "Is Enabled"])))), createElement("input", createObj(Helpers_combineClasses("checkbox", ofArray([["type", "checkbox"], ["className", "input-bordered"], ["className", "input-xs"], ["checked", state_1.JobShiftScheduleOverride.IsEnabled], ["onChange", (ev) => {
        dispatch(new Msg(3, [ev.target.checked]));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_15)),
    })), (children_17 = ofArray([Daisy_labelWithTooltip("Is Holiday", "If checked then Notifications will not be sent for this shift.", "fa fa-solid fa-info-circle"), createElement("input", createObj(Helpers_combineClasses("checkbox", ofArray([["type", "checkbox"], ["className", "input-bordered"], ["className", "input-xs"], ["checked", state_1.JobShiftScheduleOverride.IsHoliday], ["onChange", (ev_1) => {
        dispatch(new Msg(4, [ev_1.target.checked]));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_17)),
    })), (children_21 = ofArray([createElement("label", createObj(Helpers_combineClasses("label", singleton_1(["children", "Reason"])))), createElement("textarea", createObj(Helpers_combineClasses("textarea", ofArray([["className", "input-bordered"], ["className", "input-sm"], ["value", state_1.JobShiftScheduleOverride.Reason], ["onInput", (e_3) => {
        dispatch(new Msg(5, [e_3.target.value]));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_21)),
    }))]);
}

