import { toString, Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { unit_type, union_type, tuple_type, string_type, list_type, record_type, option_type, enum_type, int32_type, class_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { SkillRatingModule_toString, User_$reflection, AllocatedJobSkillUser_$reflection, Skill_$reflection } from "../../../../Logos.Shared/Domain/Entity/User.fs.js";
import { JobUserSkill_$reflection } from "../../../../Logos.Shared/Domain/Entity/Job.fs.js";
import { Busy, Busy_$reflection } from "../../SharedView.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { appRoot, AppRootModule_getSelectedOrgId } from "../../../Infrastructure/AppRoot.fs.js";
import { getAllSkills } from "../../../AppDomain/UseCase/Skill.fs.js";
import { removeJobUserSkillAllocation, allocateSelectedUsers, getUsersToSelect, getAllJobUserSkillsAndAllocations } from "../../../AppDomain/UseCase/Job.fs.js";
import { where, tryHead, filter, sortBy, ofArray, isEmpty, empty, singleton as singleton_1, map } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { UserDtoModule_toUser, AllocatedJobSkillUserDtoModule_toAllocatedJobSkillUser, SkillDtoModule_toSkill } from "../../../../Logos.Shared/Domain/Dto/User.fs.js";
import { JobUserSkillDtoModule_toJobUserSkill } from "../../../../Logos.Shared/Domain/Dto/Job.fs.js";
import { Cmd_none } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { defaultArg } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { MultiListItem, MultiListSelector } from "../../Component/MultiListSelector.fs.js";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { TitleSize, viewBox } from "../../Component/ViewBox.fs.js";
import { Daisy_h6, Daisy_confirm, Daisy_DialogWidth, Daisy_progressState, Daisy_error } from "../../Component/Component.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { comparePrimitives, compareArrays, createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { map as map_1, collect, empty as empty_1, singleton as singleton_2, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { ButtonType, showModalEx } from "../../Component/ModalDialog.fs.js";

export class SelectUserDetail extends Record {
    constructor(SkillId, Rating) {
        super();
        this.SkillId = SkillId;
        this.Rating = Rating;
    }
}

export function SelectUserDetail_$reflection() {
    return record_type("Presentation.Pages.AllocateUsers.SelectUserDetail", [], SelectUserDetail, () => [["SkillId", class_type("System.Guid")], ["Rating", option_type(enum_type("Domain.Entity.User.SkillRating", int32_type, [["Beginner", 0], ["Intermediate", 1], ["Experienced", 2]]))]]);
}

export class State extends Record {
    constructor(JobId, Skills, JobUserSkills, JobUserSkillAllocations, SelectUserDetail, UsersToSelect, SelectedUsers, IsBusy, Errors) {
        super();
        this.JobId = JobId;
        this.Skills = Skills;
        this.JobUserSkills = JobUserSkills;
        this.JobUserSkillAllocations = JobUserSkillAllocations;
        this.SelectUserDetail = SelectUserDetail;
        this.UsersToSelect = UsersToSelect;
        this.SelectedUsers = SelectedUsers;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.AllocateUsers.State", [], State, () => [["JobId", option_type(class_type("System.Guid"))], ["Skills", list_type(Skill_$reflection())], ["JobUserSkills", list_type(JobUserSkill_$reflection())], ["JobUserSkillAllocations", list_type(AllocatedJobSkillUser_$reflection())], ["SelectUserDetail", option_type(SelectUserDetail_$reflection())], ["UsersToSelect", list_type(User_$reflection())], ["SelectedUsers", list_type(class_type("System.Guid"))], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["GetJobUserSkillsResponse", "GetUsersToSelectResponse", "SelectUsers", "UpdateSelectedUsers", "AllocateSelectedUsers", "AllocateSelectedUsersResponse", "RemoveJobUserSkillAllocation", "RemoveJobUserSkillAllocationResponse", "NoAction", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.AllocateUsers.Msg", [], Msg, () => [[["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(list_type(Skill_$reflection()), list_type(JobUserSkill_$reflection()), list_type(AllocatedJobSkillUser_$reflection())), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", tuple_type(list_type(Skill_$reflection()), list_type(JobUserSkill_$reflection()), list_type(AllocatedJobSkillUser_$reflection()))]], [["ErrorValue", list_type(string_type)]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(User_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", list_type(User_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [["Item", SelectUserDetail_$reflection()]], [["Item", list_type(class_type("System.Guid"))]], [["Item", JobUserSkill_$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Guid")]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [unit_type, list_type(string_type)], FSharpResult$2, () => [[["ResultValue", unit_type]], [["ErrorValue", list_type(string_type)]]])]], [], [["Item", class_type("System.Exception")]]]);
}

export function getJobUserSkillsCmd(jobId) {
    return cmdOfAsync((Item) => (new Msg(0, [Item])), (Item_1) => (new Msg(9, [Item_1])), () => singleton.Delay(() => {
        const selectedOrg = AppRootModule_getSelectedOrgId();
        const skillsJob = getAllSkills(appRoot.SkillService, selectedOrg);
        const jobUserSkillAllocationsJob = getAllJobUserSkillsAndAllocations(appRoot.JobService, selectedOrg, jobId);
        return singleton.Bind(skillsJob, (input_1_1) => {
            const input_6 = input_1_1;
            if (input_6.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_6.fields[0]]));
            }
            else {
                return singleton.Bind(jobUserSkillAllocationsJob, (x$0027) => {
                    let _arg_2;
                    let value;
                    const input_2 = x$0027;
                    value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [(_arg_2 = input_2.fields[0], [map(SkillDtoModule_toSkill, input_6.fields[0]), map(JobUserSkillDtoModule_toJobUserSkill, _arg_2[0]), map(AllocatedJobSkillUserDtoModule_toAllocatedJobSkillUser, _arg_2[1])])])));
                    return singleton.Return(value);
                });
            }
        });
    }));
}

export function getUsersToSelectCmd(state) {
    return cmdOfAsync((Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(9, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_2;
        let value_1;
        const option_1 = state.JobId;
        value_1 = ((option_1 == null) ? (new FSharpResult$2(1, [singleton_1("Invalid Job Id.")])) : (new FSharpResult$2(0, [option_1])));
        asyncResult_2 = singleton.Return(value_1);
        return singleton.Bind(asyncResult_2, (input_1_3) => {
            let x_1;
            const input_11 = input_1_3;
            if (input_11.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_11.fields[0]]));
            }
            else {
                let asyncResult_1;
                let value_1_1;
                let option_3;
                const input_1 = state.SelectUserDetail;
                option_3 = ((input_1 == null) ? undefined : ((x_1 = input_1, [x_1.SkillId, x_1.Rating])));
                value_1_1 = ((option_3 == null) ? (new FSharpResult$2(1, [singleton_1("User Skill detail not set.")])) : (new FSharpResult$2(0, [option_3])));
                asyncResult_1 = singleton.Return(value_1_1);
                return singleton.Bind(asyncResult_1, (input_1_2) => {
                    const input_8 = input_1_2;
                    if (input_8.tag === 1) {
                        return singleton.Return(new FSharpResult$2(1, [input_8.fields[0]]));
                    }
                    else {
                        const _arg_2 = input_8.fields[0];
                        const input_2 = getUsersToSelect(appRoot.JobService, AppRootModule_getSelectedOrgId(), input_11.fields[0], _arg_2[0], _arg_2[1]);
                        return singleton.Bind(input_2, (x$0027) => {
                            let value_3;
                            const input_4 = x$0027;
                            value_3 = ((input_4.tag === 1) ? (new FSharpResult$2(1, [input_4.fields[0]])) : (new FSharpResult$2(0, [map(UserDtoModule_toUser, input_4.fields[0])])));
                            return singleton.Return(value_3);
                        });
                    }
                });
            }
        });
    }));
}

export function allocateSelectedUsersCmd(jobId, jobUserSkillId, selectedUsers) {
    return cmdOfAsync((Item) => (new Msg(5, [Item])), (Item_1) => (new Msg(9, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_1;
        const value_1_1 = (jobId !== "00000000-0000-0000-0000-000000000000") ? (new FSharpResult$2(0, [undefined])) : (new FSharpResult$2(1, [singleton_1("Invalid Job Id.")]));
        asyncResult_1 = singleton.Return(value_1_1);
        return singleton.Bind(asyncResult_1, (input_1) => {
            const input_2 = input_1;
            if (input_2.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
            }
            else {
                return allocateSelectedUsers(appRoot.JobService, AppRootModule_getSelectedOrgId(), jobId, jobUserSkillId, selectedUsers);
            }
        });
    }));
}

export function removeJobUserSkillAllocationCmd(jobId, jobUserSkillAllocationId) {
    return cmdOfAsync((Item) => (new Msg(7, [Item])), (Item_1) => (new Msg(9, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_1;
        const value_1_1 = (jobId !== "00000000-0000-0000-0000-000000000000") ? (new FSharpResult$2(0, [undefined])) : (new FSharpResult$2(1, [singleton_1("Invalid Job Id.")]));
        asyncResult_1 = singleton.Return(value_1_1);
        return singleton.Bind(asyncResult_1, (input_1) => {
            const input_2 = input_1;
            if (input_2.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
            }
            else {
                return removeJobUserSkillAllocation(appRoot.JobService, AppRootModule_getSelectedOrgId(), jobId, jobUserSkillAllocationId);
            }
        });
    }));
}

export function init(jobId) {
    return [new State(jobId, empty(), empty(), empty(), undefined, empty(), empty(), new Busy(1, []), empty()), (jobId == null) ? Cmd_none() : getJobUserSkillsCmd(jobId)];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 2: {
            const updatedState = new State(state.JobId, state.Skills, state.JobUserSkills, state.JobUserSkillAllocations, msg.fields[0], state.UsersToSelect, state.SelectedUsers, state.IsBusy, state.Errors);
            return [updatedState, getUsersToSelectCmd(updatedState)];
        }
        case 1:
            if (msg.fields[0].tag === 1) {
                return [new State(state.JobId, state.Skills, state.JobUserSkills, state.JobUserSkillAllocations, state.SelectUserDetail, state.UsersToSelect, state.SelectedUsers, state.IsBusy, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.JobId, state.Skills, state.JobUserSkills, state.JobUserSkillAllocations, state.SelectUserDetail, msg.fields[0].fields[0], state.SelectedUsers, state.IsBusy, state.Errors), Cmd_none()];
            }
        case 3:
            return [new State(state.JobId, state.Skills, state.JobUserSkills, state.JobUserSkillAllocations, state.SelectUserDetail, state.UsersToSelect, msg.fields[0], state.IsBusy, state.Errors), Cmd_none()];
        case 4:
            return [state, allocateSelectedUsersCmd(defaultArg(state.JobId, "00000000-0000-0000-0000-000000000000"), msg.fields[0].Id, state.SelectedUsers)];
        case 5:
            if (msg.fields[0].tag === 1) {
                return [new State(state.JobId, state.Skills, state.JobUserSkills, state.JobUserSkillAllocations, state.SelectUserDetail, state.UsersToSelect, state.SelectedUsers, state.IsBusy, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.JobId, state.Skills, state.JobUserSkills, state.JobUserSkillAllocations, state.SelectUserDetail, empty(), empty(), state.IsBusy, state.Errors), getJobUserSkillsCmd(defaultArg(state.JobId, "00000000-0000-0000-0000-000000000000"))];
            }
        case 6:
            return [state, removeJobUserSkillAllocationCmd(defaultArg(state.JobId, "00000000-0000-0000-0000-000000000000"), msg.fields[0])];
        case 7:
            if (msg.fields[0].tag === 1) {
                return [new State(state.JobId, state.Skills, state.JobUserSkills, state.JobUserSkillAllocations, state.SelectUserDetail, state.UsersToSelect, state.SelectedUsers, state.IsBusy, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [state, getJobUserSkillsCmd(defaultArg(state.JobId, "00000000-0000-0000-0000-000000000000"))];
            }
        case 8:
            return [state, Cmd_none()];
        case 9:
            return [new State(state.JobId, state.Skills, state.JobUserSkills, state.JobUserSkillAllocations, state.SelectUserDetail, state.UsersToSelect, state.SelectedUsers, new Busy(0, []), singleton_1(toString(msg.fields[0]))), Cmd_none()];
        default:
            if (msg.fields[0].tag === 1) {
                return [new State(state.JobId, state.Skills, state.JobUserSkills, state.JobUserSkillAllocations, state.SelectUserDetail, state.UsersToSelect, state.SelectedUsers, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.JobId, msg.fields[0].fields[0][0], msg.fields[0].fields[0][1], msg.fields[0].fields[0][2], state.SelectUserDetail, state.UsersToSelect, state.SelectedUsers, new Busy(0, []), state.Errors), Cmd_none()];
            }
    }
}

export function selectUsers(usersToSelectFrom, userCount, skillName, dispatch) {
    return createElement(MultiListSelector, {
        LeftItems: map((x) => (new MultiListItem(x.Id, toString(x) + (`${x.IsActive ? "" : " (Inactive)"}`), false)), usersToSelectFrom),
        OnUpdate: (x_1) => {
            dispatch(new Msg(3, [map((x_2) => x_2.Id, x_1.RightItems)]));
        },
        RightItems: empty(),
        Title: `Select ${userCount} user(s) to allocate to ${skillName}`,
    });
}

export function IndexView(props) {
    let input_1, children, elems_3, children_5, children_3, children_13;
    let patternInput;
    const init_1 = init(props.jobId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, [props.jobId]);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    const useUserSkillRatingForJob = !defaultArg((input_1 = props.orgOptions, (input_1 == null) ? undefined : input_1.IgnoreUserSkillRatingForJob), false);
    return viewBox("Allocated Users", new TitleSize(0, []), ofArray([Daisy_error(state_1.Errors), (children = singleton_1(createElement(Daisy_progressState, {
        busy: state_1.IsBusy,
    })), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children)),
    })), createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["flex", "w-full", "ml-2", "mr-2"])], (elems_3 = [(children_5 = singleton_1((children_3 = toList(delay(() => append(singleton_2(createElement("th", {
        width: 20 + "%",
        children: "Skill",
    })), delay(() => append(singleton_2(createElement("th", {
        width: 20 + "%",
        children: "Count",
    })), delay(() => append(useUserSkillRatingForJob ? singleton_2(createElement("th", {
        width: 20 + "%",
        children: "Rating",
    })) : empty_1(), delay(() => append(singleton_2(createElement("th", {
        width: 10 + "%",
        children: "",
    })), delay(() => singleton_2(createElement("th", {
        width: 10 + "%",
        children: "",
    })))))))))))), createElement("tr", {
        children: reactApi.Children.toArray(Array.from(children_3)),
    }))), createElement("thead", {
        children: reactApi.Children.toArray(Array.from(children_5)),
    })), (children_13 = toList(delay(() => collect((matchValue_1) => {
        let elems_1;
        const userSkill = matchValue_1[1];
        const skillName_2 = matchValue_1[0];
        return append(singleton_2(createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top", "hover"])], (elems_1 = toList(delay(() => append(singleton_2(createElement("td", {
            children: [skillName_2],
        })), delay(() => append(singleton_2(createElement("td", {
            children: [userSkill.UserCount],
        })), delay(() => {
            let input_5;
            return append(useUserSkillRatingForJob ? singleton_2(createElement("td", {
                children: [defaultArg((input_5 = userSkill.Rating, (input_5 == null) ? undefined : SkillRatingModule_toString(input_5)), "")],
            })) : empty_1(), delay(() => append(singleton_2(createElement("td", {})), delay(() => {
                let children_7;
                return singleton_2((children_7 = singleton_1(createElement(showModalEx, {
                    dialogId: userSkill.Id,
                    header: "",
                    buttonType: new ButtonType(0, ["Select Users"]),
                    width: new Daisy_DialogWidth(3, []),
                    body: () => {
                        const matchValue = state_1.UsersToSelect;
                        return isEmpty(matchValue) ? react.createElement(react.Fragment, {}) : selectUsers(matchValue, userSkill.UserCount, skillName_2, dispatch);
                    },
                    onShow: () => {
                        dispatch(new Msg(2, [new SelectUserDetail(userSkill.SkillId, useUserSkillRatingForJob ? userSkill.Rating : undefined)]));
                    },
                    onClose: () => {
                        dispatch(new Msg(4, [userSkill]));
                    },
                })), createElement("td", {
                    children: reactApi.Children.toArray(Array.from(children_7)),
                })));
            }))));
        })))))), ["children", reactApi.Children.toArray(Array.from(elems_1))])])))), delay(() => map_1((allocation) => {
            let elems_2, children_9;
            const children_11 = ofArray([createElement("td", {}), createElement("td", createObj(singleton_1((elems_2 = [toString(allocation.User)], ["children", reactApi.Children.toArray(Array.from(elems_2))])))), (children_9 = singleton_1(createElement(Daisy_confirm, {
                buttonColor: ["className", "btn-error"],
                width: new Daisy_DialogWidth(1, []),
                buttonTitle: "Remove",
                body: Daisy_h6(`Would you like to remove ${allocation.User}?`),
                isEnabled: true,
                yesAction: new Msg(6, [allocation.Id]),
                noAction: new Msg(8, []),
                dispatch: dispatch,
            })), createElement("td", {
                children: reactApi.Children.toArray(Array.from(children_9)),
            })), createElement("td", {
                colSpan: 2,
            })]);
            return createElement("tr", {
                children: reactApi.Children.toArray(Array.from(children_11)),
            });
        }, sortBy((x_5) => [x_5.User.FirstName, x_5.User.MiddleName, x_5.User.LastName], filter((x_4) => (x_4.JobUserSkillId === userSkill.Id), state_1.JobUserSkillAllocations), {
            Compare: compareArrays,
        }))));
    }, sortBy((tuple) => tuple[0], map((x_1) => {
        let input_3;
        return [defaultArg((input_3 = tryHead(where((y) => (y.Id === x_1.SkillId), state_1.Skills)), (input_3 == null) ? undefined : input_3.Name), "Skill not found"), x_1];
    }, state_1.JobUserSkills), {
        Compare: comparePrimitives,
    })))), createElement("tbody", {
        children: reactApi.Children.toArray(Array.from(children_13)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_3))])]))))]));
}

