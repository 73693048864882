import { Record } from "../fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, class_type } from "../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { map, defaultArg } from "../fable_modules/fable-library-js.4.19.3/Option.js";
import { find } from "../fable_modules/fable-library-js.4.19.3/Array.js";
import { UserRole } from "../../Logos.Shared/Domain/Entity/User.fs.js";
import { SystemService_$ctor } from "./SystemService.fs.js";
import { ExpenseService_$ctor_Z749FCB85, SimproService_$ctor_Z749FCB85, HolidayService_$ctor_Z749FCB85, InvoiceService_$ctor_Z749FCB85, XeroService_$ctor_Z749FCB85, LeavesService_$ctor_Z749FCB85, ShiftService_$ctor_Z749FCB85, JobService_$ctor_Z749FCB85, SkillService_$ctor_Z749FCB85, LogMasterService_$ctor_Z749FCB85, UserService_$ctor_Z749FCB85, VehicleService_$ctor_Z749FCB85, StripeService_$ctor_Z749FCB85, CustomerService_$ctor_Z749FCB85, OrganisationService_$ctor_Z749FCB85, DocumentService_$ctor_Z749FCB85, Service_$ctor_Z749FCB85, AuthService_$ctor } from "./WebApiService/AuthService.fs.js";

export class AppRoot extends Record {
    constructor(SystemService, AuthService, Service, DocumentService, OrganisationService, CustomerService, StripeService, VehicleService, UserService, LogMasterService, SkillService, JobService, ShiftService, LeavesService, XeroService, InvoiceService, HolidayService, SimproService, ExpenseService) {
        super();
        this.SystemService = SystemService;
        this.AuthService = AuthService;
        this.Service = Service;
        this.DocumentService = DocumentService;
        this.OrganisationService = OrganisationService;
        this.CustomerService = CustomerService;
        this.StripeService = StripeService;
        this.VehicleService = VehicleService;
        this.UserService = UserService;
        this.LogMasterService = LogMasterService;
        this.SkillService = SkillService;
        this.JobService = JobService;
        this.ShiftService = ShiftService;
        this.LeavesService = LeavesService;
        this.XeroService = XeroService;
        this.InvoiceService = InvoiceService;
        this.HolidayService = HolidayService;
        this.SimproService = SimproService;
        this.ExpenseService = ExpenseService;
    }
}

export function AppRoot_$reflection() {
    return record_type("Infrastructure.AppRoot.AppRoot", [], AppRoot, () => [["SystemService", class_type("AppDomain.SystemService.ISystemService")], ["AuthService", class_type("AppDomain.WebApiService.AuthService.IAuthService")], ["Service", class_type("AppDomain.WebApiService.AuthService.IService")], ["DocumentService", class_type("AppDomain.WebApiService.AuthService.IDocumentService")], ["OrganisationService", class_type("AppDomain.WebApiService.AuthService.IOrganisationService")], ["CustomerService", class_type("AppDomain.WebApiService.AuthService.ICustomerService")], ["StripeService", class_type("AppDomain.WebApiService.AuthService.IStripeService")], ["VehicleService", class_type("AppDomain.WebApiService.AuthService.IVehicleService")], ["UserService", class_type("AppDomain.WebApiService.AuthService.IUserService")], ["LogMasterService", class_type("AppDomain.WebApiService.AuthService.ILogMasterService")], ["SkillService", class_type("AppDomain.WebApiService.AuthService.ISkillService")], ["JobService", class_type("AppDomain.WebApiService.AuthService.IJobService")], ["ShiftService", class_type("AppDomain.WebApiService.AuthService.IShiftService")], ["LeavesService", class_type("AppDomain.WebApiService.AuthService.ILeavesService")], ["XeroService", class_type("AppDomain.WebApiService.AuthService.IXeroService")], ["InvoiceService", class_type("AppDomain.WebApiService.AuthService.IInvoiceService")], ["HolidayService", class_type("AppDomain.WebApiService.AuthService.IHolidayService")], ["SimproService", class_type("AppDomain.WebApiService.AuthService.ISimproService")], ["ExpenseService", class_type("AppDomain.WebApiService.AuthService.IExpenseService")]]);
}

export function AppRoot__get_SignedInUserData(this$) {
    const matchValue = this$.SystemService.Load("UserData");
    if (matchValue.tag === 1) {
        return undefined;
    }
    else {
        return matchValue.fields[0];
    }
}

export function AppRoot__get_SignedInUserRole(this$) {
    return defaultArg(map((x) => defaultArg(map((y) => find((z) => (z.Id === y), x.UserOrganisations).UserRole, x.SelectedOrganisation), new UserRole(3, [])), AppRoot__get_SignedInUserData(this$)), new UserRole(3, []));
}

const systemService = SystemService_$ctor();

const authService = AuthService_$ctor();

export const appRoot = new AppRoot(systemService, authService, Service_$ctor_Z749FCB85(systemService, authService), DocumentService_$ctor_Z749FCB85(systemService, authService), OrganisationService_$ctor_Z749FCB85(systemService, authService), CustomerService_$ctor_Z749FCB85(systemService, authService), StripeService_$ctor_Z749FCB85(systemService, authService), VehicleService_$ctor_Z749FCB85(systemService, authService), UserService_$ctor_Z749FCB85(systemService, authService), LogMasterService_$ctor_Z749FCB85(systemService, authService), SkillService_$ctor_Z749FCB85(systemService, authService), JobService_$ctor_Z749FCB85(systemService, authService), ShiftService_$ctor_Z749FCB85(systemService, authService), LeavesService_$ctor_Z749FCB85(systemService, authService), XeroService_$ctor_Z749FCB85(systemService, authService), InvoiceService_$ctor_Z749FCB85(systemService, authService), HolidayService_$ctor_Z749FCB85(systemService, authService), SimproService_$ctor_Z749FCB85(systemService, authService), ExpenseService_$ctor_Z749FCB85(systemService, authService));

export function AppRootModule_getIsMember() {
    const matchValue = appRoot.SystemService.Load("UserData");
    if (matchValue.tag === 1) {
        return false;
    }
    else {
        return defaultArg(map((y) => y.IsMember, matchValue.fields[0]), false);
    }
}

export function AppRootModule_getSelectedOrgId() {
    const matchValue = appRoot.SystemService.Load("UserData");
    let matchResult;
    if (matchValue.tag === 0) {
        if (matchValue.fields[0] != null) {
            matchResult = 0;
        }
        else {
            matchResult = 1;
        }
    }
    else {
        matchResult = 1;
    }
    switch (matchResult) {
        case 0:
            return defaultArg(matchValue.fields[0].SelectedOrganisation, "00000000-0000-0000-0000-000000000000");
        default:
            return "00000000-0000-0000-0000-000000000000";
    }
}

