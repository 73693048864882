import { Record } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { list_type, option_type, record_type, bool_type, int32_type, decimal_type, string_type, class_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { FSharpResult$2 } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Result.js";
import { SubscriptionUserAllocation, Product_$reflection, ProductSubscription } from "../Entity/Product.fs.js";
import { singleton } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/List.js";

export class ProductSubscriptionDto extends Record {
    constructor(Id, OrgId, SubscriptionId, PurchaseDate, ExpiryDate, SubscriptionPrice, SubscriptionCount, IsActive) {
        super();
        this.Id = Id;
        this.OrgId = OrgId;
        this.SubscriptionId = SubscriptionId;
        this.PurchaseDate = PurchaseDate;
        this.ExpiryDate = ExpiryDate;
        this.SubscriptionPrice = SubscriptionPrice;
        this.SubscriptionCount = (SubscriptionCount | 0);
        this.IsActive = IsActive;
    }
}

export function ProductSubscriptionDto_$reflection() {
    return record_type("Domain.Dto.Product.ProductSubscriptionDto", [], ProductSubscriptionDto, () => [["Id", class_type("System.Guid")], ["OrgId", class_type("System.Guid")], ["SubscriptionId", string_type], ["PurchaseDate", class_type("System.DateTimeOffset")], ["ExpiryDate", class_type("System.DateTimeOffset")], ["SubscriptionPrice", decimal_type], ["SubscriptionCount", int32_type], ["IsActive", bool_type]]);
}

export function ProductSubscriptionDtoModule_ofProductSubscription(productSubscription) {
    return new FSharpResult$2(0, [new ProductSubscriptionDto(productSubscription.Id, productSubscription.OrgId, productSubscription.SubscriptionId, productSubscription.PurchaseDate, productSubscription.ExpiryDate, productSubscription.SubscriptionPrice, productSubscription.SubscriptionCount, productSubscription.IsActive)]);
}

export function ProductSubscriptionDtoModule_toProductSubscription(productSubscription) {
    return new FSharpResult$2(0, [new ProductSubscription(productSubscription.Id, productSubscription.OrgId, productSubscription.SubscriptionId, productSubscription.PurchaseDate, productSubscription.ExpiryDate, productSubscription.SubscriptionPrice, productSubscription.SubscriptionCount, productSubscription.IsActive)]);
}

export class SubscriptionUserAllocationDto extends Record {
    constructor(Id, SubscriptionId, UserId, UserFullName, Product, DateAllocated, DateDeallocated) {
        super();
        this.Id = Id;
        this.SubscriptionId = SubscriptionId;
        this.UserId = UserId;
        this.UserFullName = UserFullName;
        this.Product = Product;
        this.DateAllocated = DateAllocated;
        this.DateDeallocated = DateDeallocated;
    }
}

export function SubscriptionUserAllocationDto_$reflection() {
    return record_type("Domain.Dto.Product.SubscriptionUserAllocationDto", [], SubscriptionUserAllocationDto, () => [["Id", class_type("System.Guid")], ["SubscriptionId", string_type], ["UserId", option_type(class_type("System.Guid"))], ["UserFullName", string_type], ["Product", Product_$reflection()], ["DateAllocated", class_type("System.DateTimeOffset")], ["DateDeallocated", option_type(class_type("System.DateTimeOffset"))]]);
}

export function SubscriptionUserAllocationDtoModule_ofSubscriptionUserAllocation(subscriptionUserAllocation) {
    return new SubscriptionUserAllocationDto(subscriptionUserAllocation.Id, subscriptionUserAllocation.SubscriptionId, subscriptionUserAllocation.UserId, subscriptionUserAllocation.UserFullName, subscriptionUserAllocation.Product, subscriptionUserAllocation.DateAllocated, subscriptionUserAllocation.DateDeallocated);
}

export function SubscriptionUserAllocationDtoModule_toSubscriptionUserAllocation(subscriptionUserAllocation) {
    const matchValue = subscriptionUserAllocation.UserId;
    if (matchValue == null) {
        return new FSharpResult$2(1, [singleton("UserId is required.")]);
    }
    else {
        return new FSharpResult$2(0, [new SubscriptionUserAllocation(subscriptionUserAllocation.Id, subscriptionUserAllocation.SubscriptionId, matchValue, subscriptionUserAllocation.UserFullName, subscriptionUserAllocation.Product, subscriptionUserAllocation.DateAllocated, subscriptionUserAllocation.DateDeallocated)]);
    }
}

export class SubscriptionUserAllocationsDto extends Record {
    constructor(AllocatedSubscriptions, UnallocatedSubscriptions) {
        super();
        this.AllocatedSubscriptions = AllocatedSubscriptions;
        this.UnallocatedSubscriptions = UnallocatedSubscriptions;
    }
}

export function SubscriptionUserAllocationsDto_$reflection() {
    return record_type("Domain.Dto.Product.SubscriptionUserAllocationsDto", [], SubscriptionUserAllocationsDto, () => [["AllocatedSubscriptions", list_type(SubscriptionUserAllocationDto_$reflection())], ["UnallocatedSubscriptions", list_type(SubscriptionUserAllocationDto_$reflection())]]);
}

