import { toString, Record, Union } from "../../fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, list_type, string_type, union_type, lambda_type, unit_type, class_type } from "../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Customer_$reflection } from "../../../Logos.Shared/Domain/Entity/Customer.fs.js";
import { BusyModule_isLoading, Busy, Busy_$reflection } from "../SharedView.fs.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library-js.4.19.3/Result.js";
import { fold, ofArray, singleton as singleton_1, map, empty } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { Cmd_none } from "../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { cmdOfAsync } from "../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { searchCustomer } from "../../AppDomain/UseCase/Customer.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../Infrastructure/AppRoot.fs.js";
import { getDefaultPagination } from "./Pagination.fs.js";
import { ContactDtoModule_toContactTypeAndValue, CustomerDtoModule_toCustomer } from "../../../Logos.Shared/Domain/Dto/Customer.fs.js";
import { createElement } from "react";
import React from "react";
import { createObj } from "../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { join } from "../../fable_modules/fable-library-js.4.19.3/String.js";
import { reactApi } from "../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { empty as empty_1, append, singleton as singleton_2, map as map_1, delay, toList } from "../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { defaultArg } from "../../fable_modules/fable-library-js.4.19.3/Option.js";
import { AddressModule_toAddressString } from "../../../Logos.Shared/Domain/Entity/Address.fs.js";
import { React_useElmish_Z6C327F2E } from "../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { Daisy_error, Daisy_h4 } from "./Component.fs.js";
import { PropHelpers_createOnKey } from "../../fable_modules/Feliz.2.8.0/./Properties.fs.js";
import { key_enter } from "../../fable_modules/Feliz.2.8.0/Key.fs.js";

export class ActionControl$1 extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["NoControl", "CustomControl"];
    }
}

export function ActionControl$1_$reflection(gen0) {
    return union_type("Presentation.Component.CustomerPicker.ActionControl`1", [gen0], ActionControl$1, () => [[["Item", lambda_type(class_type("System.Guid"), unit_type)]], [["Item", lambda_type(gen0, class_type("Fable.React.ReactElement"))]]]);
}

export class State extends Record {
    constructor(SearchText, Customers, IsBusy, Errors) {
        super();
        this.SearchText = SearchText;
        this.Customers = Customers;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Component.CustomerPicker.State", [], State, () => [["SearchText", string_type], ["Customers", list_type(Customer_$reflection())], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetSearchText", "Search", "SearchResponse", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Component.CustomerPicker.Msg", [], Msg, () => [[["Item", string_type]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(Customer_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", list_type(Customer_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Exception")]]]);
}

export function init() {
    return [new State("", empty(), new Busy(0, []), empty()), Cmd_none()];
}

export function searchCustomerCmd(searchText) {
    return cmdOfAsync((Item) => (new Msg(2, [Item])), (Item_1) => (new Msg(3, [Item_1])), () => singleton.Delay(() => {
        const input = searchCustomer(appRoot.CustomerService, AppRootModule_getSelectedOrgId(), searchText, getDefaultPagination());
        return singleton.Bind(input, (x$0027) => {
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [map(CustomerDtoModule_toCustomer, input_2.fields[0])])));
            return singleton.Return(value);
        });
    }));
}

export function update(msg, state) {
    switch (msg.tag) {
        case 1:
            return [new State(state.SearchText, state.Customers, new Busy(1, []), empty()), searchCustomerCmd(state.SearchText)];
        case 2:
            if (msg.fields[0].tag === 1) {
                return [new State(state.SearchText, state.Customers, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.SearchText, msg.fields[0].fields[0], new Busy(0, []), state.Errors), Cmd_none()];
            }
        case 3:
            return [new State(state.SearchText, state.Customers, new Busy(0, []), singleton_1(toString(msg.fields[0]))), Cmd_none()];
        default:
            return [new State(msg.fields[0], state.Customers, state.IsBusy, state.Errors), Cmd_none()];
    }
}

export function customerTable(state, props) {
    let elems_2, children_2, children, children_7;
    const children_10 = singleton_1(createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["flex", "w-full"])], (elems_2 = [(children_2 = singleton_1((children = ofArray([createElement("th", {
        width: 15 + "%",
        children: "Business",
    }), createElement("th", {
        width: 30 + "%",
        children: "Address",
    }), createElement("th", {
        width: 15 + "%",
        children: "First name",
    }), createElement("th", {
        width: 15 + "%",
        children: "Middle name",
    }), createElement("th", {
        width: 15 + "%",
        children: "Last name",
    }), createElement("th", {
        width: 15 + "%",
        children: "Contact",
    }), createElement("th", {
        width: 30 + "%",
        children: "",
    })]), createElement("tr", {
        children: reactApi.Children.toArray(Array.from(children)),
    }))), createElement("thead", {
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), (children_7 = toList(delay(() => map_1((customer) => {
        let elems_1, elems, children_5;
        return createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top", "hover"])], (elems_1 = [createElement("td", {
            children: [defaultArg(customer.BusinessName, "")],
        }), createElement("td", {
            children: [fold((acc, x) => {
                if (acc === "") {
                    return AddressModule_toAddressString(x);
                }
                else {
                    return acc + AddressModule_toAddressString(x);
                }
            }, "", customer.Addresses)],
        }), createElement("td", {
            children: [customer.FirstName],
        }), createElement("td", {
            children: [defaultArg(customer.MiddleName, "")],
        }), createElement("td", {
            children: [customer.LastName],
        }), createElement("td", createObj(singleton_1((elems = map((x_1) => createElement("p", {
            children: ContactDtoModule_toContactTypeAndValue(x_1),
        }), customer.Contacts), ["children", reactApi.Children.toArray(Array.from(elems))])))), (children_5 = toList(delay(() => {
            const matchValue = props.ActionControl;
            return (matchValue.tag === 1) ? singleton_2(matchValue.fields[0](customer)) : singleton_2(createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-xs"], ["children", props.ActionText], ["onClick", (_arg) => {
                matchValue.fields[0](defaultArg(customer.Id, "00000000-0000-0000-0000-000000000000"));
            }]])))));
        })), createElement("td", {
            children: reactApi.Children.toArray(Array.from(children_5)),
        }))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])));
    }, state.Customers))), createElement("tbody", {
        children: reactApi.Children.toArray(Array.from(children_7)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))));
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_10)),
    });
}

export function IndexView(props) {
    let elems_4, elems_3, children, children_6, children_3, elems_1;
    const patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(init, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return createElement("div", createObj(ofArray([["className", join(" ", ["flex", "items-center", "justify-center", "py-12", "px-4", "sm:px-6", "lg:px-8"])], (elems_4 = [createElement("div", createObj(ofArray([["className", join(" ", ["container", "px-4", "sm:px-6", "lg:px-8", "space-y-8"])], (elems_3 = [(children = singleton_1(Daisy_h4("Customer search")), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    })), (children_6 = ofArray([(children_3 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Customer",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_3)),
    })), createElement("div", createObj(ofArray([["className", "flex space-x-2"], (elems_1 = [createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "w-full"], ["className", "input-bordered"], ["className", "input-sm"], ["autoFocus", true], ["value", state_1.SearchText], ["placeholder", "Customer"], ["readOnly", BusyModule_isLoading(state_1.IsBusy)], ["onChange", (ev) => {
        dispatch(new Msg(0, [ev.target.value]));
    }], ["onKeyUp", (ev_1) => {
        PropHelpers_createOnKey(key_enter, (_arg_3) => {
            dispatch(new Msg(1, []));
        }, ev_1);
    }]])))), createElement("button", createObj(Helpers_combineClasses("btn", toList(delay(() => append(BusyModule_isLoading(state_1.IsBusy) ? singleton_2(["className", "loading"]) : empty_1(), delay(() => append(singleton_2(["className", "btn-primary"]), delay(() => append(singleton_2(["className", "btn-sm"]), delay(() => append(singleton_2(["children", "Search"]), delay(() => singleton_2(["onClick", (_arg_5) => {
        dispatch(new Msg(1, []));
    }]))))))))))))))], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_6)),
    })), Daisy_error(state_1.Errors), customerTable(state_1, props)], ["children", reactApi.Children.toArray(Array.from(elems_3))])])))], ["children", reactApi.Children.toArray(Array.from(elems_4))])])));
}

