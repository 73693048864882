import { singleton } from "../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { FSharpResult$2 } from "../../fable_modules/fable-library-js.4.19.3/Result.js";
import { InvoiceRunTimesheetRunNumberDtoModule_toInvoiceRunTimesheetRunNumber, InvoiceRunDetailDtoModule_toInvoiceRunDetail, InvoiceRunItemDetailAggregateDtoModule_toInvoiceRunItemDetailAggregate, InvoiceRunItemDetailDtoModule_toInvoiceRunItemDetail, InvoiceRunItemDtoModule_toInvoiceRunItem, InvoiceRunDtoModule_toInvoiceRun } from "../../../Logos.Shared/Domain/Dto/Invoice.fs.js";
import { map } from "../../fable_modules/fable-library-js.4.19.3/List.js";
import { UserDetailDtoModule_toUserName } from "../../../Logos.Shared/Domain/Dto/User.fs.js";
import { JobDetailDtoModule_toJobDetail } from "../../../Logos.Shared/Domain/Dto/Job.fs.js";
import { CustomerDetailDtoModule_toCustomer } from "../../../Logos.Shared/Domain/Dto/Customer.fs.js";

export function searchInvoiceRuns(invoiceService, selectedOrgId, searchText, pagination) {
    return singleton.Delay(() => invoiceService.SearchInvoiceRuns(selectedOrgId, searchText, pagination));
}

export function generateInvoiceRun(invoiceService, selectedOrgId, invoiceGenerationMethod, selectedJobs) {
    return singleton.Delay(() => invoiceService.GenerateInvoiceRun(selectedOrgId, invoiceGenerationMethod, selectedJobs));
}

export function getInvoiceRunData(invoiceService, selectedOrgId, invoiceRunId) {
    return singleton.Delay(() => {
        const input = invoiceService.GetInvoiceRunData(selectedOrgId, invoiceRunId);
        return singleton.Bind(input, (x$0027) => {
            let tupledArg;
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [(tupledArg = input_2.fields[0], [InvoiceRunDtoModule_toInvoiceRun(tupledArg[0]), map(InvoiceRunItemDtoModule_toInvoiceRunItem, tupledArg[1]), map(InvoiceRunItemDetailDtoModule_toInvoiceRunItemDetail, tupledArg[2]), map(InvoiceRunItemDetailAggregateDtoModule_toInvoiceRunItemDetailAggregate, tupledArg[3]), map(UserDetailDtoModule_toUserName, tupledArg[4]), map(JobDetailDtoModule_toJobDetail, tupledArg[5]), map(CustomerDetailDtoModule_toCustomer, tupledArg[6]), tupledArg[7]])])));
            return singleton.Return(value);
        });
    });
}

export function approveInvoiceRun(invoiceService, selectedOrgId, invoiceRunId) {
    return singleton.Delay(() => invoiceService.ApproveInvoiceRun(selectedOrgId, invoiceRunId));
}

export function cancelInvoiceRun(invoiceService, selectedOrgId, invoiceRunId) {
    return singleton.Delay(() => invoiceService.CancelInvoiceRun(selectedOrgId, invoiceRunId));
}

export function getInvoiceRunItemsForJob(invoiceService, selectedOrgId, jobId, pagination) {
    return singleton.Delay(() => {
        const x = invoiceService.GetInvoiceRunItemsForJob(selectedOrgId, jobId, pagination);
        return singleton.Bind(x, (x$0027) => {
            let _arg;
            let value;
            const input_2 = x$0027;
            value = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [(_arg = input_2.fields[0], [map(InvoiceRunItemDtoModule_toInvoiceRunItem, _arg[0]), map(InvoiceRunDetailDtoModule_toInvoiceRunDetail, _arg[1]), map(InvoiceRunTimesheetRunNumberDtoModule_toInvoiceRunTimesheetRunNumber, _arg[2])])])));
            return singleton.Return(value);
        });
    });
}

