import { appRoot, AppRootModule_getSelectedOrgId } from "../../../Infrastructure/AppRoot.fs.js";
import { searchUsers } from "../../../AppDomain/UseCase/User.fs.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { uncurry2, createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { singleton, ofArray } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { map, empty, collect, singleton as singleton_1, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { ButtonType, showModalEx, iconButton, showModalDialog } from "../../Component/ModalDialog.fs.js";
import { alignIconCenter, Daisy_DialogWidth } from "../../Component/Component.fs.js";
import { IndexView as IndexView_3, Msg$2 } from "../BaseSearchAdvanced.fs.js";
import { value as value_71 } from "../../../../Logos.Shared/Domain/Entity/Email.fs.js";
import { toString } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { IndexView as IndexView_1 } from "./UserEdit.fs.js";
import { IndexView as IndexView_2 } from "./UserInvitePage.fs.js";
import { UserDtoModule_toUser } from "../../../../Logos.Shared/Domain/Dto/User.fs.js";
import { unwrap } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { UserSearchDetails } from "../../../../Logos.Shared/Domain/Entity/User.fs.js";
import { PropHelpers_createOnKey } from "../../../fable_modules/Feliz.2.8.0/./Properties.fs.js";
import { key_enter } from "../../../fable_modules/Feliz.2.8.0/Key.fs.js";
import { Status as Status_1, StatusModule_allStatuses, StatusModule_ofString } from "../../../../Logos.Shared/Domain/Entity/Common.fs.js";

export function searchFunc(userSearchDetails) {
    const selectedOrgId = AppRootModule_getSelectedOrgId();
    return (pagination) => searchUsers(appRoot.UserService, selectedOrgId, userSearchDetails, pagination);
}

function userTableView(props) {
    let elems_5, children_2, children, children_5;
    return createElement("table", createObj(Helpers_combineClasses("table", ofArray([["className", "table-zebra"], ["className", "table-xs"], ["className", "table-pin-rows"], ["className", "table-pin-cols"], ["className", join(" ", ["flex", "w-full"])], (elems_5 = [(children_2 = singleton((children = ofArray([createElement("th", {
        width: 10 + "%",
        children: "First-name",
    }), createElement("th", {
        width: 10 + "%",
        children: "Middle-name",
    }), createElement("th", {
        width: 10 + "%",
        children: "Last-name",
    }), createElement("th", {
        width: 40 + "%",
        children: "Username",
    }), createElement("th", {
        width: 10 + "%",
        children: "Mobile",
    }), createElement("th", {
        width: 10 + "%",
        children: "Is Active",
    }), createElement("th", {
        width: 10 + "%",
        children: "",
    })]), createElement("tr", {
        children: reactApi.Children.toArray(Array.from(children)),
    }))), createElement("thead", {
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), (children_5 = toList(delay(() => {
        let matchValue, x;
        return append((matchValue = props.state.EntityToEdit, (matchValue == null) ? singleton_1(react.createElement(react.Fragment, {})) : ((x = matchValue, singleton_1(createElement(showModalDialog, {
            dialogId: x,
            header: "",
            width: new Daisy_DialogWidth(3, []),
            body: props.actionBody,
            onShow: () => {
                props.dispatch(new Msg$2(4, [x]));
            },
            onClose: () => {
                props.dispatch(new Msg$2(4, [undefined]));
            },
        }))))), delay(() => collect((user) => {
            let elems_4;
            let disableRow;
            const matchValue_1 = props.onDisableRow;
            disableRow = ((matchValue_1 == null) ? false : matchValue_1(user));
            let tdColored_1;
            const color = disableRow ? "text-gray-500" : (user.IsActive ? "text-black" : "text-red-500");
            tdColored_1 = ((text) => ((id) => createElement("td", {
                className: color,
                children: text,
                style: {
                    cursor: "pointer",
                },
                onClick: (_arg) => {
                    props.dispatch(new Msg$2(4, [id]));
                },
            })));
            return singleton_1(createElement("tr", createObj(ofArray([["className", join(" ", ["align-text-top", "hover"])], (elems_4 = toList(delay(() => {
                let elems_2, elems_1, elems;
                return append(user.IsAdmin ? singleton_1(createElement("td", createObj(ofArray([["style", {
                    cursor: "pointer",
                }], ["onClick", (_arg_3) => {
                    props.dispatch(new Msg$2(4, [user.Id]));
                }], (elems_2 = [createElement("div", createObj(ofArray([["className", "mr-2 flex items-center"], (elems_1 = [createElement("div", createObj(Helpers_combineClasses("tooltip", ofArray([["data-tip", "Admin"], (elems = [createElement("div", {
                    className: "fa fa-user-shield mr-1",
                })], ["children", reactApi.Children.toArray(Array.from(elems))])])))), createElement("div", {
                    children: user.FirstName,
                })], ["children", reactApi.Children.toArray(Array.from(elems_1))])])))], ["children", reactApi.Children.toArray(Array.from(elems_2))])])))) : singleton_1(tdColored_1(user.FirstName)(user.Id)), delay(() => append(singleton_1(tdColored_1(user.MiddleName)(user.Id)), delay(() => append(singleton_1(tdColored_1(user.LastName)(user.Id)), delay(() => append(singleton_1(tdColored_1(value_71(user.Username))(user.Id)), delay(() => append(singleton_1(tdColored_1(user.Mobile)(user.Id)), delay(() => append(singleton_1(tdColored_1(toString(user.IsActive))(user.Id)), delay(() => {
                    let elems_3;
                    return singleton_1(createElement("td", createObj(ofArray([alignIconCenter, (elems_3 = toList(delay(() => {
                        const matchValue_2 = props.actionControl;
                        return (matchValue_2 == null) ? singleton_1(iconButton("fas fa-edit", (_arg_4) => {
                            props.dispatch(new Msg$2(4, [user.Id]));
                        })) : (!disableRow ? singleton_1(matchValue_2(user)) : empty());
                    })), ["children", reactApi.Children.toArray(Array.from(elems_3))])]))));
                }))))))))))));
            })), ["children", reactApi.Children.toArray(Array.from(elems_4))])]))));
        }, props.state.Entities)));
    })), createElement("tbody", {
        children: reactApi.Children.toArray(Array.from(children_5)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_5))])]))));
}

function userTableSearchView(props) {
    let elems;
    const body = () => {
        const matchValue = props.state.EntityToEdit;
        if (matchValue == null) {
            return createElement(IndexView_1, {
                userId: "",
            });
        }
        else {
            return createElement(IndexView_1, {
                userId: matchValue,
            });
        }
    };
    const children_1 = ofArray([createElement("div", createObj(Helpers_combineClasses("navbar", singleton((elems = [createElement(IndexView_2, {
        username: "",
    }), createElement("div", {
        className: join(" ", ["mr-4"]),
    }), createElement(showModalEx, {
        dialogId: "id-user-search-add-user",
        header: "",
        buttonType: new ButtonType(0, ["Add User"]),
        width: new Daisy_DialogWidth(3, []),
        body: body,
        onShow: () => {
            props.dispatch(new Msg$2(4, [undefined]));
        },
        onClose: () => {
            props.dispatch(new Msg$2(4, [undefined]));
        },
    })], ["children", reactApi.Children.toArray(Array.from(elems))]))))), createElement(userTableView, {
        actionBody: body,
        dispatch: props.dispatch,
        state: props.state,
    })]);
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_1)),
    });
}

function userTablePickerView(userTablePickerViewInputProps) {
    const props = userTablePickerViewInputProps.props;
    const onDisableRow = userTablePickerViewInputProps.onDisableRow;
    const onSetUser = userTablePickerViewInputProps.onSetUser;
    const children_2 = singleton(createElement(userTableView, {
        actionBody: () => react.createElement(react.Fragment, {}),
        actionControl: (x) => {
            const children = singleton(createElement("input", createObj(Helpers_combineClasses("radio", ofArray([["type", "radio"], ["className", "checkbox-sm"], ["name", "customers"], ["className", join(" ", ["align-middle"])], ["onClick", (_arg) => {
                onSetUser(UserDtoModule_toUser(x));
            }]])))));
            return createElement("div", {
                children: reactApi.Children.toArray(Array.from(children)),
            });
        },
        dispatch: props.dispatch,
        onDisableRow: unwrap(onDisableRow),
        state: props.state,
    }));
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_2)),
    });
}

export function searchPanel(props) {
    let children_2, elems, elems_6, children_8, children_5, elems_4, elems_3;
    const children_10 = ofArray([(children_2 = ofArray([createElement("label", createObj(Helpers_combineClasses("label", singleton(["children", "Search"])))), createElement("div", createObj(ofArray([["className", "flex space-x-2"], (elems = [createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "w-full"], ["className", "input-bordered"], ["className", "input-sm"], ["autoFocus", true], ["placeholder", "Search"], ["readOnly", false], ["onChange", (ev) => {
        props.dispatch(new Msg$2(0, [new UserSearchDetails(ev.target.value, props.state.SearchDetails.Status)]));
    }], ["onKeyUp", (ev_1) => {
        PropHelpers_createOnKey(key_enter, (_arg) => {
            props.dispatch(new Msg$2(1, []));
        }, ev_1);
    }]])))), createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-primary"], ["className", "btn-sm"], ["children", "Search"], ["onClick", (_arg_1) => {
        props.dispatch(new Msg$2(1, []));
    }]]))))], ["children", reactApi.Children.toArray(Array.from(elems))])])))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), createElement("div", createObj(ofArray([["className", "flex space-x-2 mt-2"], (elems_6 = [(children_8 = ofArray([(children_5 = singleton(createElement("span", {
        className: "label-text",
        children: "Status",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_5)),
    })), createElement("div", createObj(ofArray([["className", "flex space-x-2"], (elems_4 = [createElement("select", createObj(Helpers_combineClasses("select", ofArray([["className", "select-bordered"], ["className", "select-sm"], ["placeholder", "Status"], ["value", toString(props.state.SearchDetails.Status)], ["onChange", (ev_2) => {
        props.dispatch(new Msg$2(0, [new UserSearchDetails(props.state.SearchDetails.SearchText, StatusModule_ofString(ev_2.target.value))]));
    }], (elems_3 = toList(delay(() => map((case$) => createElement("option", {
        value: case$,
        children: case$,
    }), StatusModule_allStatuses))), ["children", reactApi.Children.toArray(Array.from(elems_3))])]))))], ["children", reactApi.Children.toArray(Array.from(elems_4))])])))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_8)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_6))])])))]);
    return createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_10)),
    });
}

export function IndexView() {
    return createElement(IndexView_3, {
        entityName: "User",
        entityTable: (props) => createElement(userTableSearchView, props),
        searchDetails: new UserSearchDetails("", new Status_1(1, [])),
        searchFunc: uncurry2(searchFunc),
        searchPanel: searchPanel,
        xPadding: "",
        yPadding: "",
    });
}

export function IndexViewPicker(props) {
    return createElement(IndexView_3, {
        entityName: "User",
        entityTable: (props_1) => createElement(userTablePickerView, {
            onSetUser: props.OnSetUser,
            onDisableRow: unwrap(props.OnDisableRow),
            props: props_1,
        }),
        searchDetails: new UserSearchDetails("", new Status_1(1, [])),
        searchFunc: uncurry2(searchFunc),
        searchPanel: searchPanel,
        xPadding: "",
        yPadding: "",
    });
}

