import { Record } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, tuple_type, bool_type, lambda_type, union_type, list_type, string_type, class_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { VehicleDto_$reflection } from "../Dto/LogMaster/Vehicle.fs.js";
import { FSharpResult$2 } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Result.js";
import { printf, toText } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/String.js";

export class LogMasterService extends Record {
    constructor(insertVehicle, getVehicles) {
        super();
        this.insertVehicle = insertVehicle;
        this.getVehicles = getVehicles;
    }
}

export function LogMasterService_$reflection() {
    return record_type("Logos.Shared.Domain.Remoting.LogMasterService", [], LogMasterService, () => [["insertVehicle", lambda_type(class_type("System.Guid"), lambda_type(VehicleDto_$reflection(), class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [class_type("System.DateTime"), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", class_type("System.DateTime")]], [["ErrorValue", list_type(string_type)]]])])))], ["getVehicles", lambda_type(class_type("System.Guid"), lambda_type(string_type, class_type("Microsoft.FSharp.Control.FSharpAsync`1", [union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(list_type(VehicleDto_$reflection()), bool_type), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", tuple_type(list_type(VehicleDto_$reflection()), bool_type)]], [["ErrorValue", list_type(string_type)]]])])))]]);
}

export function LogMasterService_RouteBuilder(s, m) {
    return toText(printf("/api/%s/%s"))(s)(m);
}

