import { Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { union_type, tuple_type, record_type, array_type, uint8_type, list_type, option_type, string_type, class_type, bool_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { Document$ as Document$_2, DocumentType$ as DocumentType$_1, DocumentTag_$reflection, Document$_$reflection, DocumentType$_$reflection } from "../../../../Logos.Shared/Domain/Entity/Document.fs.js";
import { BusyModule_isSaving, Busy, Busy_$reflection } from "../../SharedView.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { Option_ofString, Guid_parse } from "../../../../Logos.Shared/Util.fs.js";
import { updateDocument, addDocument, getDocumentFile, getDocumentTags, getDocument as getDocument_1 } from "../../../AppDomain/UseCase/Document.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../Infrastructure/AppRoot.fs.js";
import { join, isNullOrEmpty } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import { head, exists, map, cons, ofArray, empty, singleton as singleton_1 } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { parse, minValue } from "../../../fable_modules/fable-library-js.4.19.3/DateOffset.js";
import { map as map_1, empty as empty_1, singleton as singleton_2, append, delay, toList, isEmpty } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { defaultArg } from "../../../fable_modules/fable-library-js.4.19.3/Option.js";
import { Cmd_none, Cmd_batch } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { HttpUtil_saveByteArray, ByteArray_ofArrayBuffer, FileUtil_getFileExtension } from "../../../AppDomain/Util.fs.js";
import { createElement } from "react";
import React from "react";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../../Component/ViewContainer.fs.js";
import { Daisy_progressState, Daisy_error, Daisy_h4 } from "../../Component/Component.fs.js";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { createObj } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { toString } from "../../../fable_modules/fable-library-js.4.19.3/Date.js";
import { rangeDouble } from "../../../fable_modules/fable-library-js.4.19.3/Range.js";

export class State extends Record {
    constructor(IsNewDocument, OwnerId, Name, FileExtension, DocumentType$, Document$, DocumentTags, Tags, DocumentFile, Expiry, IsBusy, Errors) {
        super();
        this.IsNewDocument = IsNewDocument;
        this.OwnerId = OwnerId;
        this.Name = Name;
        this.FileExtension = FileExtension;
        this.DocumentType = DocumentType$;
        this.Document = Document$;
        this.DocumentTags = DocumentTags;
        this.Tags = Tags;
        this.DocumentFile = DocumentFile;
        this.Expiry = Expiry;
        this.IsBusy = IsBusy;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.DocumentEdit.State", [], State, () => [["IsNewDocument", bool_type], ["OwnerId", class_type("System.Guid")], ["Name", string_type], ["FileExtension", option_type(string_type)], ["DocumentType", DocumentType$_$reflection()], ["Document", option_type(Document$_$reflection())], ["DocumentTags", list_type(DocumentTag_$reflection())], ["Tags", list_type(DocumentTag_$reflection())], ["DocumentFile", option_type(array_type(uint8_type))], ["Expiry", option_type(class_type("System.DateTimeOffset"))], ["IsBusy", Busy_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["SetName", "SetExpiry", "SetDocumentFile", "SetDocumentTag", "GetDocumentResponse", "GetDocumentFile", "GetDocumentFileResponse", "SaveDocument", "SaveDocumentResponse", "GetDocumentTagsResponse", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.DocumentEdit.Msg", [], Msg, () => [[["Item", string_type]], [["Item", option_type(class_type("System.DateTimeOffset"))]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [tuple_type(string_type, class_type("Fable.Core.JS.ArrayBuffer")), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", tuple_type(string_type, class_type("Fable.Core.JS.ArrayBuffer"))]], [["ErrorValue", list_type(string_type)]]])]], [["Item", DocumentTag_$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [option_type(Document$_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", option_type(Document$_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [array_type(uint8_type), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", array_type(uint8_type)]], [["ErrorValue", list_type(string_type)]]])]], [], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [option_type(class_type("System.Guid")), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", option_type(class_type("System.Guid"))]], [["ErrorValue", list_type(string_type)]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(DocumentTag_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", list_type(DocumentTag_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [["Item", class_type("System.Exception")]]]);
}

export function getDocumentCmd(documentId) {
    return cmdOfAsync((Item) => (new Msg(4, [Item])), (Item_1) => (new Msg(10, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_1;
        const value_1 = Guid_parse(documentId);
        asyncResult_1 = singleton.Return(value_1);
        return singleton.Bind(asyncResult_1, (input_1) => {
            const input_2 = input_1;
            if (input_2.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
            }
            else {
                return getDocument_1(appRoot.DocumentService, AppRootModule_getSelectedOrgId(), input_2.fields[0]);
            }
        });
    }));
}

export function getDocumentTagsCmd() {
    return cmdOfAsync((Item) => (new Msg(9, [Item])), (Item_1) => (new Msg(10, [Item_1])), () => singleton.Delay(() => getDocumentTags(appRoot.DocumentService, AppRootModule_getSelectedOrgId())));
}

export function getDocumentFileCmd(documentId) {
    return cmdOfAsync((Item) => (new Msg(6, [Item])), (Item_1) => (new Msg(10, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_2;
        const value_1_1 = !isNullOrEmpty(documentId) ? (new FSharpResult$2(0, [undefined])) : (new FSharpResult$2(1, [singleton_1("Document id is required.")]));
        asyncResult_2 = singleton.Return(value_1_1);
        return singleton.Bind(asyncResult_2, (input_1_1) => {
            const input_5 = input_1_1;
            if (input_5.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_5.fields[0]]));
            }
            else {
                let asyncResult_1;
                const value_1_2 = Guid_parse(documentId);
                asyncResult_1 = singleton.Return(value_1_2);
                return singleton.Bind(asyncResult_1, (input_1) => {
                    const input_2 = input_1;
                    if (input_2.tag === 1) {
                        return singleton.Return(new FSharpResult$2(1, [input_2.fields[0]]));
                    }
                    else {
                        return getDocumentFile(appRoot.DocumentService, AppRootModule_getSelectedOrgId(), input_2.fields[0]);
                    }
                });
            }
        });
    }));
}

export function saveDocumentCmd(state) {
    return cmdOfAsync((Item) => (new Msg(8, [Item])), (Item_1) => (new Msg(10, [Item_1])), () => singleton.Delay(() => {
        let asyncResult_4;
        let value_1_1;
        if (state.IsNewDocument) {
            value_1_1 = (new FSharpResult$2(0, [new Document$_2("00000000-0000-0000-0000-000000000000", AppRootModule_getSelectedOrgId(), state.OwnerId, state.Name, state.FileExtension, undefined, new DocumentType$_1(0, []), empty(), "00000000-0000-0000-0000-000000000000", minValue())]));
        }
        else {
            const option_1 = state.Document;
            value_1_1 = ((option_1 == null) ? (new FSharpResult$2(1, [singleton_1("Document data not found.")])) : (new FSharpResult$2(0, [option_1])));
        }
        asyncResult_4 = singleton.Return(value_1_1);
        return singleton.Bind(asyncResult_4, (input_1_5) => {
            const input_17 = input_1_5;
            if (input_17.tag === 1) {
                return singleton.Return(new FSharpResult$2(1, [input_17.fields[0]]));
            }
            else {
                const document$ = input_17.fields[0];
                const updatedDocument = new Document$_2(document$.Id, document$.OrganisationId, document$.OwnerId, state.Name, state.FileExtension, state.Expiry, state.DocumentType, state.DocumentTags, document$.ModifiedBy, document$.Modified);
                let asyncResult_3;
                const value_1_2 = isEmpty(updatedDocument.Name.split("")) ? (new FSharpResult$2(1, [singleton_1("Document name is required.")])) : (new FSharpResult$2(0, [undefined]));
                asyncResult_3 = singleton.Return(value_1_2);
                return singleton.Bind(asyncResult_3, (input_1_4) => {
                    const input_14 = input_1_4;
                    if (input_14.tag === 1) {
                        return singleton.Return(new FSharpResult$2(1, [input_14.fields[0]]));
                    }
                    else if (state.IsNewDocument) {
                        let asyncResult_1;
                        let value_1_3;
                        const option_3 = state.DocumentFile;
                        value_1_3 = ((option_3 == null) ? (new FSharpResult$2(1, [singleton_1("Document file must be attached.")])) : (new FSharpResult$2(0, [option_3])));
                        asyncResult_1 = singleton.Return(value_1_3);
                        return singleton.Bind(asyncResult_1, (input_1_2) => {
                            const input_6 = input_1_2;
                            if (input_6.tag === 1) {
                                return singleton.Return(new FSharpResult$2(1, [input_6.fields[0]]));
                            }
                            else {
                                const input = addDocument(appRoot.DocumentService, AppRootModule_getSelectedOrgId(), updatedDocument, input_6.fields[0]);
                                return singleton.Bind(input, (x$0027) => {
                                    let value_4;
                                    const input_2 = x$0027;
                                    value_4 = ((input_2.tag === 1) ? (new FSharpResult$2(1, [input_2.fields[0]])) : (new FSharpResult$2(0, [input_2.fields[0]])));
                                    return singleton.Return(value_4);
                                });
                            }
                        });
                    }
                    else {
                        const documentFile_1 = defaultArg(state.DocumentFile, new Uint8Array([]));
                        const input_8 = updateDocument(appRoot.DocumentService, AppRootModule_getSelectedOrgId(), updatedDocument, documentFile_1);
                        return singleton.Bind(input_8, (x$0027_1) => {
                            let value_9;
                            const input_10 = x$0027_1;
                            value_9 = ((input_10.tag === 1) ? (new FSharpResult$2(1, [input_10.fields[0]])) : (new FSharpResult$2(0, [undefined])));
                            return singleton.Return(value_9);
                        });
                    }
                });
            }
        });
    }));
}

export function init(documentType, documentId, userId) {
    const isNewDocument = isNullOrEmpty(documentId);
    return [new State(isNewDocument, userId, "", undefined, documentType, undefined, empty(), empty(), undefined, undefined, isNewDocument ? (new Busy(0, [])) : (new Busy(1, [])), empty()), isNewDocument ? getDocumentTagsCmd() : Cmd_batch(ofArray([getDocumentCmd(documentId), getDocumentTagsCmd()]))];
}

export function update(msg, state) {
    let input_1;
    switch (msg.tag) {
        case 1:
            return [new State(state.IsNewDocument, state.OwnerId, state.Name, state.FileExtension, state.DocumentType, state.Document, state.DocumentTags, state.Tags, state.DocumentFile, msg.fields[0], state.IsBusy, state.Errors), Cmd_none()];
        case 2:
            if (msg.fields[0].tag === 1) {
                return [new State(state.IsNewDocument, state.OwnerId, state.Name, state.FileExtension, state.DocumentType, state.Document, state.DocumentTags, state.Tags, state.DocumentFile, state.Expiry, state.IsBusy, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.IsNewDocument, state.OwnerId, state.Name, Option_ofString(FileUtil_getFileExtension(msg.fields[0].fields[0][0])), state.DocumentType, state.Document, state.DocumentTags, state.Tags, ByteArray_ofArrayBuffer(msg.fields[0].fields[0][1]), state.Expiry, state.IsBusy, state.Errors), Cmd_none()];
            }
        case 3:
            return [new State(state.IsNewDocument, state.OwnerId, state.Name, state.FileExtension, state.DocumentType, state.Document, cons(msg.fields[0], state.DocumentTags), state.Tags, state.DocumentFile, state.Expiry, state.IsBusy, state.Errors), Cmd_none()];
        case 4:
            if (msg.fields[0].tag === 1) {
                return [new State(state.IsNewDocument, state.OwnerId, state.Name, state.FileExtension, state.DocumentType, state.Document, state.DocumentTags, state.Tags, state.DocumentFile, state.Expiry, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else if (msg.fields[0].fields[0] == null) {
                return [new State(state.IsNewDocument, state.OwnerId, state.Name, state.FileExtension, state.DocumentType, undefined, state.DocumentTags, state.Tags, state.DocumentFile, state.Expiry, new Busy(0, []), singleton_1("Document not found.")), Cmd_none()];
            }
            else {
                const document$ = msg.fields[0].fields[0];
                return [new State(state.IsNewDocument, document$.OwnerId, document$.Name, document$.FileExtension, state.DocumentType, document$, document$.Tags, state.Tags, state.DocumentFile, document$.Expiry, new Busy(0, []), state.Errors), Cmd_none()];
            }
        case 9:
            if (msg.fields[0].tag === 1) {
                return [new State(state.IsNewDocument, state.OwnerId, state.Name, state.FileExtension, state.DocumentType, state.Document, state.DocumentTags, state.Tags, state.DocumentFile, state.Expiry, state.IsBusy, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.IsNewDocument, state.OwnerId, state.Name, state.FileExtension, state.DocumentType, state.Document, state.DocumentTags, msg.fields[0].fields[0], state.DocumentFile, state.Expiry, state.IsBusy, state.Errors), Cmd_none()];
            }
        case 7:
            return [new State(state.IsNewDocument, state.OwnerId, state.Name, state.FileExtension, state.DocumentType, state.Document, state.DocumentTags, state.Tags, state.DocumentFile, state.Expiry, new Busy(1, []), state.Errors), saveDocumentCmd(state)];
        case 8:
            if (msg.fields[0].tag === 1) {
                return [new State(state.IsNewDocument, state.OwnerId, state.Name, state.FileExtension, state.DocumentType, state.Document, state.DocumentTags, state.Tags, state.DocumentFile, state.Expiry, new Busy(0, []), msg.fields[0].fields[0]), Cmd_none()];
            }
            else if (msg.fields[0].fields[0] == null) {
                return [new State(state.IsNewDocument, state.OwnerId, state.Name, state.FileExtension, state.DocumentType, state.Document, state.DocumentTags, state.Tags, state.DocumentFile, state.Expiry, new Busy(0, []), state.Errors), Cmd_none()];
            }
            else {
                const documentId = msg.fields[0].fields[0];
                return [new State(state.IsNewDocument, state.OwnerId, state.Name, state.FileExtension, state.DocumentType, state.Document, state.DocumentTags, state.Tags, state.DocumentFile, state.Expiry, new Busy(0, []), empty()), state.IsNewDocument ? getDocumentCmd(documentId) : Cmd_none()];
            }
        case 5:
            return [state, getDocumentFileCmd(defaultArg((input_1 = state.Document, (input_1 == null) ? undefined : input_1.Id), ""))];
        case 6:
            if (msg.fields[0].tag === 1) {
                return [new State(state.IsNewDocument, state.OwnerId, state.Name, state.FileExtension, state.DocumentType, state.Document, state.DocumentTags, state.Tags, state.DocumentFile, state.Expiry, state.IsBusy, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                HttpUtil_saveByteArray(msg.fields[0].fields[0], `Document-${state.Name}.${defaultArg(state.FileExtension, "")}`, "application/octet-stream");
                return [new State(state.IsNewDocument, state.OwnerId, state.Name, state.FileExtension, state.DocumentType, state.Document, state.DocumentTags, state.Tags, msg.fields[0].fields[0], state.Expiry, state.IsBusy, state.Errors), Cmd_none()];
            }
        case 10:
            return [new State(state.IsNewDocument, state.OwnerId, state.Name, state.FileExtension, state.DocumentType, state.Document, state.DocumentTags, state.Tags, state.DocumentFile, state.Expiry, new Busy(0, []), singleton_1(msg.fields[0].message)), Cmd_none()];
        default:
            return [new State(state.IsNewDocument, state.OwnerId, msg.fields[0], state.FileExtension, state.DocumentType, state.Document, state.DocumentTags, state.Tags, state.DocumentFile, state.Expiry, state.IsBusy, state.Errors), Cmd_none()];
    }
}

export function IndexView(props) {
    let children, children_2, elems_14, children_22, children_9, children_7, children_15, children_12, elems_5, children_20, children_18, input_1, elems_13, children_27, children_25, children_35, children_33, children_30;
    let patternInput;
    const init_1 = init(props.DocumentType, props.DocumentId, props.UserId);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return container([(children = singleton_1(Daisy_h4((`Document - ${state_1.Name}`).trim())), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children)),
    })), Daisy_error(state_1.Errors), (children_2 = singleton_1(createElement(Daisy_progressState, {
        busy: state_1.IsBusy,
    })), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_2)),
    })), createElement("button", createObj(Helpers_combineClasses("btn", toList(delay(() => append(BusyModule_isSaving(state_1.IsBusy) ? singleton_2(["className", "loading"]) : empty_1(), delay(() => append(singleton_2(["className", join(" ", ["my-4"])]), delay(() => append(singleton_2(["className", "btn-primary"]), delay(() => append(singleton_2(["className", "btn-sm"]), delay(() => append(singleton_2(["className", join(" ", ["mr-4"])]), delay(() => append(singleton_2(["children", "Save"]), delay(() => singleton_2(["onClick", (_arg_3) => {
        dispatch(new Msg(7, []));
    }])))))))))))))))))), createElement("div", createObj(Helpers_combineClasses("divider", empty()))), createElement("div", createObj(singleton_1((elems_14 = [(children_22 = ofArray([(children_9 = ofArray([(children_7 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Document name",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_7)),
    })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["value", state_1.Name], ["placeholder", "Document name"], ["onChange", (ev) => {
        dispatch(new Msg(0, [ev.target.value]));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_9)),
    })), (children_15 = ofArray([(children_12 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Document tags",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_12)),
    })), createElement("div", createObj(ofArray([["className", join(" ", ["w-full", "h-32", "overflow-auto", "box-content", "border-2", "rounded"])], (elems_5 = map((tag) => {
        let elems_4;
        return createElement("div", createObj(ofArray([["className", join(" ", ["m-2", "flex", "items-center", "space-x-2"])], (elems_4 = [createElement("input", createObj(Helpers_combineClasses("checkbox", ofArray([["type", "checkbox"], ["className", "checkbox-xs"], ["checked", exists((x) => (x.Id === tag.Id), state_1.DocumentTags)], ["onChange", (ev_1) => {
            const _arg_4 = ev_1.target.checked;
            dispatch(new Msg(3, [tag]));
        }]])))), createElement("span", {
            className: "label-text",
            children: tag.Name,
        })], ["children", reactApi.Children.toArray(Array.from(elems_4))])])));
    }, state_1.Tags), ["children", reactApi.Children.toArray(Array.from(elems_5))])])))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_15)),
    })), (children_20 = ofArray([(children_18 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Expiry date",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_18)),
    })), createElement("input", createObj(Helpers_combineClasses("input", ofArray([["type", "text"], ["className", "input-bordered"], ["className", "input-sm"], ["type", "date"], ["value", defaultArg((input_1 = state_1.Expiry, (input_1 == null) ? undefined : toString(input_1, "yyyy-MM-dd")), "")], ["onInput", (e) => {
        const expiry = e.target.value;
        dispatch(new Msg(1, [(expiry === "") ? undefined : parse(expiry)]));
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_20)),
    }))]), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_22)),
    })), createElement("div", createObj(ofArray([["className", "grid grid-cols-2 gap-4"], (elems_13 = [(children_27 = ofArray([(children_25 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Document file",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_25)),
    })), createElement("input", {
        type: "file",
        className: "file-input file-input-sm file-input-bordered file-input-primary",
        multiple: false,
        onChange: (ev_2) => {
            const fileList = ev_2.target.files;
            if (!(fileList == null)) {
                const files = toList(delay(() => map_1((i) => fileList.item(i), rangeDouble(0, 1, fileList.length - 1))));
                const reader = new FileReader();
                let filename;
                const y = head(files);
                filename = y.name;
                reader.onload = ((_arg_5) => {
                    dispatch(new Msg(2, [new FSharpResult$2(0, [[filename, reader.result]])]));
                });
                reader.onerror = ((_arg_6) => {
                    dispatch(new Msg(2, [new FSharpResult$2(1, [singleton_1("Error getting document file.")])]));
                });
                reader.readAsArrayBuffer(head(files));
            }
        },
    })]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_27)),
    })), (children_35 = singleton_1((children_33 = ofArray([(children_30 = singleton_1(createElement("span", {
        className: "label-text",
        children: "Download document file",
    })), createElement("label", {
        className: "label",
        children: reactApi.Children.toArray(Array.from(children_30)),
    })), createElement("button", createObj(Helpers_combineClasses("btn", ofArray([["className", "btn-primary"], ["className", "btn-sm"], ["children", "Download"], ["disabled", state_1.Document == null], ["onClick", (_arg_7) => {
        if (state_1.Document == null) {
        }
        else {
            dispatch(new Msg(5, []));
        }
    }]]))))]), createElement("div", {
        className: "form-control",
        children: reactApi.Children.toArray(Array.from(children_33)),
    }))), createElement("div", {
        children: reactApi.Children.toArray(Array.from(children_35)),
    }))], ["children", reactApi.Children.toArray(Array.from(elems_13))])])))], ["children", reactApi.Children.toArray(Array.from(elems_14))]))))]);
}

