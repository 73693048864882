import { Record, Union } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { record_type, bool_type, list_type, enum_type, int32_type, string_type, class_type, union_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { newGuid } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Guid.js";
import { fromMinutes, fromHours } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/TimeSpan.js";
import { ofArray } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/List.js";
import { utcNow } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Date.js";

export class ShiftRules extends Union {
    constructor() {
        super();
        this.tag = 0;
        this.fields = [];
    }
    cases() {
        return ["Min30MinBreakAfter5HoursWork"];
    }
}

export function ShiftRules_$reflection() {
    return union_type("Domain.Entity.ShiftRule.ShiftRules", [], ShiftRules, () => [[]]);
}

export function ShiftRulesModule_toString(_arg) {
    return "Minimum 30 minutes break after 5 hours of work.";
}

export function ShiftRulesModule_toShiftRule(_arg) {
    if (_arg === "Minimum 30 minutes break after 5 hours of work.") {
        return new ShiftRules();
    }
    else {
        throw new Error("Invalid ShiftRule.");
    }
}

export class ShiftRule extends Record {
    constructor(Id, OrgId, Name, ApplicableAfter, Tolerance, RuleDuration, EnforceAt, IsActive, Created, Updated) {
        super();
        this.Id = Id;
        this.OrgId = OrgId;
        this.Name = Name;
        this.ApplicableAfter = ApplicableAfter;
        this.Tolerance = Tolerance;
        this.RuleDuration = RuleDuration;
        this.EnforceAt = EnforceAt;
        this.IsActive = IsActive;
        this.Created = Created;
        this.Updated = Updated;
    }
}

export function ShiftRule_$reflection() {
    return record_type("Domain.Entity.ShiftRule.ShiftRule", [], ShiftRule, () => [["Id", class_type("System.Guid")], ["OrgId", class_type("System.Guid")], ["Name", string_type], ["ApplicableAfter", class_type("System.TimeSpan")], ["Tolerance", class_type("System.TimeSpan")], ["RuleDuration", class_type("System.TimeSpan")], ["EnforceAt", list_type(enum_type("Domain.Entity.Shift.ShiftLogTypeEnum", int32_type, [["NoState", 0], ["WorkStarted", 1], ["BreakStarted", 2], ["BreakFinished", 3], ["WorkFinished", 4]]))], ["IsActive", bool_type], ["Created", class_type("System.DateTime")], ["Updated", class_type("System.DateTime")]]);
}

export const min30MinBreak = new ShiftRule(newGuid(), newGuid(), "Minimum 30 minutes break after 5 hours of work.", fromHours(5), fromMinutes(10), fromMinutes(30), ofArray([3, 4]), true, utcNow(), utcNow());

