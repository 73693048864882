import { Union } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Types.js";
import { getUnionCases, name, union_type } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Reflection.js";
import { sortBy } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Array.js";
import { comparePrimitives } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Util.js";
import { FSharpResult$2 } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Result.js";
import { singleton } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/List.js";
import { op_Multiply, op_Division, fromParts } from "../../../Logos.Client/fable_modules/fable-library-js.4.19.3/Decimal.js";

export class MeasurementSystem extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Metric", "Imperial"];
    }
}

export function MeasurementSystem_$reflection() {
    return union_type("Domain.Entity.MeasurementUnits.MeasurementSystem", [], MeasurementSystem, () => [[], []]);
}

export const MeasurementSystemModule_measurementSystems = sortBy(name, getUnionCases(MeasurementSystem_$reflection(), 0), {
    Compare: comparePrimitives,
});

export function MeasurementSystemModule_toInt(_arg) {
    if (_arg.tag === 1) {
        return 1;
    }
    else {
        return 0;
    }
}

export function MeasurementSystemModule_ofInt(_arg) {
    switch (_arg) {
        case 0:
            return new MeasurementSystem(0, []);
        case 1:
            return new MeasurementSystem(1, []);
        default:
            throw new Error(`Invalid MeasurementSystem ${_arg}.`);
    }
}

export function MeasurementSystemModule_toString(_arg) {
    if (_arg.tag === 1) {
        return "Imperial";
    }
    else {
        return "Metric";
    }
}

export function MeasurementSystemModule_ofString(x) {
    switch (x) {
        case "Metric":
            return new FSharpResult$2(0, [new MeasurementSystem(0, [])]);
        case "Imperial":
            return new FSharpResult$2(0, [new MeasurementSystem(1, [])]);
        default:
            return new FSharpResult$2(1, [singleton(`Invalid MeasurementSystem ${x}.`)]);
    }
}

export class DistanceUnit extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["Km", "Mile"];
    }
}

export function DistanceUnit_$reflection() {
    return union_type("Domain.Entity.MeasurementUnits.DistanceUnit", [], DistanceUnit, () => [[], []]);
}

export const DistanceUnitModule_distanceUnits = sortBy(name, getUnionCases(DistanceUnit_$reflection(), 0), {
    Compare: comparePrimitives,
});

export function DistanceUnitModule_toInt(_arg) {
    if (_arg.tag === 1) {
        return 1;
    }
    else {
        return 0;
    }
}

export function DistanceUnitModule_ofInt(_arg) {
    switch (_arg) {
        case 0:
            return new DistanceUnit(0, []);
        case 1:
            return new DistanceUnit(1, []);
        default:
            throw new Error(`Invalid DistanceUnit ${_arg}.`);
    }
}

export function DistanceUnitModule_toString(_arg) {
    if (_arg.tag === 1) {
        return "Mile";
    }
    else {
        return "Km";
    }
}

export function DistanceUnitModule_ofString(value) {
    switch (value) {
        case "Km":
            return new FSharpResult$2(0, [new DistanceUnit(0, [])]);
        case "Mile":
            return new FSharpResult$2(0, [new DistanceUnit(1, [])]);
        default:
            return new FSharpResult$2(1, [singleton("Invalid DistanceUnit string.")]);
    }
}

export const DistanceUnitModule_kmPerMile = fromParts(160934, 0, 0, false, 5);

export function DistanceUnitModule_kmsToMiles(kmValue) {
    return op_Division(kmValue, DistanceUnitModule_kmPerMile);
}

export function DistanceUnitModule_milesToKms(mileValue) {
    return op_Multiply(mileValue, DistanceUnitModule_kmPerMile);
}

