import { Union, Record } from "../../../fable_modules/fable-library-js.4.19.3/Types.js";
import { getDefaultConfig, DateRange, DateRange_$reflection } from "../../Component/ReactTailwindDatePicker.fs.js";
import { MenuPlacement, Choice, Choice_$reflection } from "../../Component/MultiSelect.fs.js";
import { class_type, union_type, record_type, list_type, string_type, array_type } from "../../../fable_modules/fable-library-js.4.19.3/Reflection.js";
import { MeasurementSystem, MeasurementSystem_$reflection } from "../../../../Logos.Shared/Domain/Entity/MeasurementUnits.fs.js";
import { LogPointTagDto_$reflection, VehicleSearchDetailDto_$reflection } from "../../../../Logos.Shared/Domain/Dto/LogMaster/Vehicle.fs.js";
import { FSharpResult$2 } from "../../../fable_modules/fable-library-js.4.19.3/Result.js";
import { cmdOfAsync } from "../../../Infrastructure/Util/Elmish.fs.js";
import { singleton } from "../../../fable_modules/fable-library-js.4.19.3/AsyncBuilder.js";
import { getVehicleLogTags, getVehicles } from "../../../AppDomain/UseCase/Vehicle.fs.js";
import { AppRootModule_getSelectedOrgId, appRoot } from "../../../Infrastructure/AppRoot.fs.js";
import { getUserMeasurementSystem } from "../../../AppDomain/UseCase/User.fs.js";
import { parse, now, addDays } from "../../../fable_modules/fable-library-js.4.19.3/Date.js";
import { singleton as singleton_1, sortBy, map, toArray, ofArray, empty } from "../../../fable_modules/fable-library-js.4.19.3/List.js";
import { Cmd_none, Cmd_batch } from "../../../fable_modules/Fable.Elmish.4.2.0/cmd.fs.js";
import { createObj, comparePrimitives } from "../../../fable_modules/fable-library-js.4.19.3/Util.js";
import { createElement } from "react";
import React from "react";
import * as react from "react";
import { React_useElmish_Z6C327F2E } from "../../../fable_modules/Feliz.UseElmish.2.5.0/./UseElmish.fs.js";
import { ProgramModule_mkProgram } from "../../../fable_modules/Feliz.UseElmish.2.5.0/../Fable.Elmish.4.2.0/program.fs.js";
import { container } from "../../Component/ViewContainer.fs.js";
import { singleton as singleton_2, append, delay, toList } from "../../../fable_modules/fable-library-js.4.19.3/Seq.js";
import { Daisy_error, Daisy_h2 } from "../../Component/Component.fs.js";
import { join } from "../../../fable_modules/fable-library-js.4.19.3/String.js";
import react_tailwindcss_datepicker from "react-tailwindcss-datepicker";
import { reactApi } from "../../../fable_modules/Feliz.2.8.0/./Interop.fs.js";
import { select, ChoiceTSModule_toChoice, MenuPlacement__get_Value, lowerFieldNames, op_EqualsGreater } from "../../Component/./MultiSelect.fs.js";
import { map as map_1 } from "../../../fable_modules/fable-library-js.4.19.3/Array.js";
import { reactApi as reactApi_1 } from "../../Component/../../fable_modules/Feliz.2.8.0/Interop.fs.js";
import { Helpers_combineClasses } from "../../../fable_modules/Feliz.DaisyUI.4.2.1/./DaisyUI.fs.js";
import { indexLogReportView } from "./Component/VehicleLogAggregate.fs.js";
import { parse as parse_1 } from "../../../fable_modules/fable-library-js.4.19.3/Guid.js";

export class State extends Record {
    constructor(DateRange, VehiclesSelected, AllVehicles, VehicleLogTagsSelected, AllVehicleLogTags, UserMeasurementSystem, Errors) {
        super();
        this.DateRange = DateRange;
        this.VehiclesSelected = VehiclesSelected;
        this.AllVehicles = AllVehicles;
        this.VehicleLogTagsSelected = VehicleLogTagsSelected;
        this.AllVehicleLogTags = AllVehicleLogTags;
        this.UserMeasurementSystem = UserMeasurementSystem;
        this.Errors = Errors;
    }
}

export function State_$reflection() {
    return record_type("Presentation.Pages.LogMaster.LogReport.State", [], State, () => [["DateRange", DateRange_$reflection()], ["VehiclesSelected", array_type(Choice_$reflection())], ["AllVehicles", array_type(Choice_$reflection())], ["VehicleLogTagsSelected", array_type(Choice_$reflection())], ["AllVehicleLogTags", array_type(Choice_$reflection())], ["UserMeasurementSystem", MeasurementSystem_$reflection()], ["Errors", list_type(string_type)]]);
}

export class Msg extends Union {
    constructor(tag, fields) {
        super();
        this.tag = tag;
        this.fields = fields;
    }
    cases() {
        return ["DateRangeSelected", "GetVehiclesResponse", "GetVehicleLogTagsResponse", "GetUserMeasurementSystemResponse", "VehiclesSelected", "VehicleLogTagsSelected", "ApiError"];
    }
}

export function Msg_$reflection() {
    return union_type("Presentation.Pages.LogMaster.LogReport.Msg", [], Msg, () => [[["Item", DateRange_$reflection()]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(VehicleSearchDetailDto_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", list_type(VehicleSearchDetailDto_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [list_type(LogPointTagDto_$reflection()), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", list_type(LogPointTagDto_$reflection())]], [["ErrorValue", list_type(string_type)]]])]], [["Item", union_type("Microsoft.FSharp.Core.FSharpResult`2", [MeasurementSystem_$reflection(), list_type(string_type)], FSharpResult$2, () => [[["ResultValue", MeasurementSystem_$reflection()]], [["ErrorValue", list_type(string_type)]]])]], [["Item", array_type(Choice_$reflection())]], [["Item", array_type(Choice_$reflection())]], [["Item", class_type("System.Exception")]]]);
}

export function getVehiclesCmd() {
    return cmdOfAsync((Item) => (new Msg(1, [Item])), (Item_1) => (new Msg(6, [Item_1])), () => singleton.Delay(() => getVehicles(appRoot.VehicleService, AppRootModule_getSelectedOrgId())));
}

export function getVehicleLogTagsCmd() {
    return cmdOfAsync((Item) => (new Msg(2, [Item])), (Item_1) => (new Msg(6, [Item_1])), () => singleton.Delay(() => getVehicleLogTags(appRoot.VehicleService, AppRootModule_getSelectedOrgId())));
}

export function getUserMeasurementSystemCmd() {
    return cmdOfAsync((Item) => (new Msg(3, [Item])), (Item_1) => (new Msg(6, [Item_1])), () => singleton.Delay(() => getUserMeasurementSystem(appRoot.UserService, AppRootModule_getSelectedOrgId())));
}

export function init(systemService) {
    return [new State(new DateRange(addDays(now(), -7), now()), [], [], [], [], new MeasurementSystem(0, []), empty()), Cmd_batch(ofArray([getVehiclesCmd(), getVehicleLogTagsCmd(), getUserMeasurementSystemCmd()]))];
}

export function update(msg, state) {
    switch (msg.tag) {
        case 4:
            return [new State(state.DateRange, msg.fields[0], state.AllVehicles, state.VehicleLogTagsSelected, state.AllVehicleLogTags, state.UserMeasurementSystem, state.Errors), Cmd_none()];
        case 5:
            return [new State(state.DateRange, state.VehiclesSelected, state.AllVehicles, msg.fields[0], state.AllVehicleLogTags, state.UserMeasurementSystem, state.Errors), Cmd_none()];
        case 1:
            if (msg.fields[0].tag === 1) {
                return [new State(state.DateRange, state.VehiclesSelected, [], state.VehicleLogTagsSelected, state.AllVehicleLogTags, state.UserMeasurementSystem, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                const allVehicles = toArray(map((y) => (new Choice(y.Id, y.Description)), msg.fields[0].fields[0]));
                return [new State(state.DateRange, allVehicles, allVehicles, state.VehicleLogTagsSelected, state.AllVehicleLogTags, state.UserMeasurementSystem, state.Errors), Cmd_none()];
            }
        case 2:
            if (msg.fields[0].tag === 1) {
                return [new State(state.DateRange, state.VehiclesSelected, state.AllVehicles, state.VehicleLogTagsSelected, [], state.UserMeasurementSystem, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                const allTags = toArray(map((y_3) => (new Choice(y_3.Id, y_3.Name)), sortBy((y_1) => y_1.Name, msg.fields[0].fields[0], {
                    Compare: comparePrimitives,
                })));
                return [new State(state.DateRange, state.VehiclesSelected, state.AllVehicles, allTags, allTags, state.UserMeasurementSystem, state.Errors), Cmd_none()];
            }
        case 3:
            if (msg.fields[0].tag === 1) {
                return [new State(state.DateRange, state.VehiclesSelected, state.AllVehicles, state.VehicleLogTagsSelected, state.AllVehicleLogTags, state.UserMeasurementSystem, msg.fields[0].fields[0]), Cmd_none()];
            }
            else {
                return [new State(state.DateRange, state.VehiclesSelected, state.AllVehicles, state.VehicleLogTagsSelected, state.AllVehicleLogTags, msg.fields[0].fields[0], state.Errors), Cmd_none()];
            }
        case 6:
            return [new State(state.DateRange, state.VehiclesSelected, state.AllVehicles, state.VehicleLogTagsSelected, state.AllVehicleLogTags, state.UserMeasurementSystem, singleton_1(msg.fields[0].message)), Cmd_none()];
        default:
            return [new State(msg.fields[0], state.VehiclesSelected, state.AllVehicles, state.VehicleLogTagsSelected, state.AllVehicleLogTags, state.UserMeasurementSystem, state.Errors), Cmd_none()];
    }
}

export function IndexView() {
    let elems_2;
    let patternInput;
    const init_1 = init(appRoot.SystemService);
    patternInput = React_useElmish_Z6C327F2E(() => ProgramModule_mkProgram(() => init_1, update, (_arg, _arg_1) => {
    }), undefined, []);
    const state_1 = patternInput[0];
    const dispatch = patternInput[1];
    return container([createElement("div", createObj(singleton_1((elems_2 = toList(delay(() => append(singleton_2(Daisy_h2("LogMaster Logs")), delay(() => append(singleton_2(Daisy_error(state_1.Errors)), delay(() => {
        let elems, props_1;
        return append(singleton_2(createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-3", "gap-4"])], (elems = [(props_1 = {
            value: state_1.DateRange,
            onChange: (x) => {
                dispatch(new Msg(0, [new DateRange(parse(x.startDate), parse(x.endDate))]));
            },
            showShortcuts: true,
            configs: getDefaultConfig(now()),
        }, react.createElement(react_tailwindcss_datepicker, props_1))], ["children", reactApi.Children.toArray(Array.from(elems))])])))), delay(() => append(!(state_1.AllVehicles.length === 0) ? append(singleton_2(createElement("br", {})), delay(() => {
            let props_4;
            return singleton_2((props_4 = ofArray([op_EqualsGreater("id", "vehicle-selector"), op_EqualsGreater("name", "vehicle-selector"), op_EqualsGreater("placeholder", "Vehicles"), op_EqualsGreater("isSearchable", true), op_EqualsGreater("isMulti", true), op_EqualsGreater("options", lowerFieldNames(state_1.AllVehicles)), op_EqualsGreater("menuPlacement", MenuPlacement__get_Value(new MenuPlacement(1, []))), op_EqualsGreater("defaultValue", lowerFieldNames(state_1.AllVehicles)), op_EqualsGreater("onChange", (x_2) => {
                dispatch(new Msg(4, [map_1(ChoiceTSModule_toChoice, x_2)]));
            })]), reactApi_1.createElement(select, createObj(props_4))));
        })) : singleton_2(createElement("div", {})), delay(() => append(!(state_1.AllVehicleLogTags.length === 0) ? append(singleton_2(createElement("br", {})), delay(() => {
            let props_7;
            return singleton_2((props_7 = ofArray([op_EqualsGreater("id", "tag-selector"), op_EqualsGreater("name", "tag-selector"), op_EqualsGreater("placeholder", "Tags"), op_EqualsGreater("isSearchable", true), op_EqualsGreater("isMulti", true), op_EqualsGreater("options", lowerFieldNames(state_1.AllVehicleLogTags)), op_EqualsGreater("menuPlacement", MenuPlacement__get_Value(new MenuPlacement(1, []))), op_EqualsGreater("defaultValue", lowerFieldNames(state_1.AllVehicleLogTags)), op_EqualsGreater("onChange", (x_4) => {
                dispatch(new Msg(5, [map_1(ChoiceTSModule_toChoice, x_4)]));
            })]), reactApi_1.createElement(select, createObj(props_7))));
        })) : singleton_2(createElement("div", {})), delay(() => append(singleton_2(createElement("div", createObj(Helpers_combineClasses("divider", empty())))), delay(() => {
            let elems_1;
            return singleton_2(createElement("div", createObj(ofArray([["className", join(" ", ["grid", "grid-cols-1", "lg:grid-cols-1", "gap-4", "m-4"])], (elems_1 = [createElement(indexLogReportView, {
                dateRange: state_1.DateRange,
                vehiclesToSearchFor: ofArray(map_1((x_5) => parse_1(x_5.Value), state_1.VehiclesSelected)),
                vehicleLogTags: ofArray(map_1((x_6) => parse_1(x_6.Value), state_1.VehicleLogTagsSelected)),
                allTags: ofArray(map_1((x_7) => ({
                    Id: x_7.Value,
                    Name: x_7.Label,
                }), state_1.AllVehicleLogTags)),
                userMeasurementSystem: state_1.UserMeasurementSystem,
            })], ["children", reactApi.Children.toArray(Array.from(elems_1))])]))));
        }))))))));
    })))))), ["children", reactApi.Children.toArray(Array.from(elems_2))]))))]);
}

